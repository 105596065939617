import React, { FC, ReactNode, memo } from 'react';
import { TableCell } from '@material-ui/core';

interface Props {
  isVisible?: boolean;
  RenderItem?: ReactNode;
}

const component: FC<Props> = ({ isVisible = true, RenderItem }) => {
  return (
    <>
      {isVisible && (
        <TableCell style={{ padding: '1rem' }}>{RenderItem}</TableCell>
      )}
    </>
  );
};

export const ConditionalVisibleCell = memo(component);
