import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';

import { LoaderBar, Page } from 'src/components';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import Options from './Options';
import Toolbar from './Toolbar';
import { Option } from 'src/types/option';

const { actions: optionActions, selectors: optionSelectors } = slices.options;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const OptionListView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const options = useAppSelector(optionSelectors.selectOptionList);
  const optionPageMeta = useAppSelector(
    optionSelectors.selectOptionPaginationMeta
  );

  const [page, setPage] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getOptions = async (keyword?: string, page?: string) => {
    setIsLoading(true);
    dispatch(optionActions.getOptionsThunk({ keyword, page })).finally(() =>
      setIsLoading(false)
    );
  };

  const searchOptions = (keyword: string = '') => {
    getOptions(keyword);
  };

  const handleChange = (_: any, value: number) => {
    setPage(value);
  };

  const onOptionPress = (option: Option) => {
    navigate(`/app/options/${option.id}`, { state: option });
  };

  useEffect(() => {
    getOptions('', String(page));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <Page className={classes.root} title="Options">
      <Container maxWidth={false}>
        <Toolbar searchOptions={searchOptions} />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <Options options={options} onOptionPress={onOptionPress} />
        </Box>
      </Container>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Pagination
          count={optionPageMeta.last_page}
          page={page}
          onChange={handleChange}
        />
      </Box>
    </Page>
  );
};

export default OptionListView;
