import { PartFields } from 'src/types';
import { HddPartDetailsFields } from 'src/types';

// capacity	form_factor	 rpm	      interface	 cache
// 1TB	    SATA 3.5		 7200	   SATA 6.0Gb/s      512MB

export const hddPartsFields: PartFields<HddPartDetailsFields>[] = [
  {
    label: 'Capacity',
    part_property: 'capacity',
    hint: '128GB, 500GB, 1TB, 2TB',
    transformRule: ['all-caps', 'no-spaces'],
    options: [
      '120GB',
      '128GB',
      '240GB',
      '250GB',
      '256GB',
      '480GB',
      '500GB',
      '512GB',
      '1TB',
      '2TB',
      '6TB'
    ],
    type: 'string',
    required: true
  },
  {
    label: 'Form Factor',
    part_property: 'form_factor',
    hint: 'SATA 3.5, SATA 2.5',
    options: ['SATA 3.5', 'SATA 2.5'],
    transformRule: ['all-caps'],
    type: 'string',
    required: true
  },
  {
    label: 'RPM',
    part_property: 'rpm',
    type: 'number',
    options: ['5400', '7200'],
    transformRule: ['all-caps', 'no-spaces'],
    required: true
  },
  {
    label: 'Interface',
    part_property: 'interface',
    hint: 'SATA 6.0Gb/s',
    options: ['SATA 6.0Gb/s'],
    type: 'string',
    required: true
  },
  {
    label: 'Cache',
    part_property: 'cache',
    type: 'string',
    required: true,
    hint: '64MB, 256MB, 512MB',
    transformRule: ['all-caps', 'no-spaces'],
    options: ['64MB', '256MB', '512MB']
  },
  {
    label: 'Image',
    part_property: 'image_upload',
    hint: 'Select Product Image. Only one is allowed for now',
    type: 'img',
    required: false
  }
];
