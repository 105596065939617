import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonListingResponse,
  CommonListingsResponse,
  CreateListingRequest,
  CreateListingsResponse,
  DeleteListingResponse,
  GetListingsViaIDResponse,
  GetListingViaSerialRequest,
  Listing,
  ListingIsSerialNoExistResponse,
  ListingsThunkRequest,
  UpdateListingResponse
} from 'src/types/listing';
import { CommonAxiosResponse } from 'src/types';
import { GetRecentOptionResponse } from 'src/types/option';

export const getConsumableListingsViaProductId = createAsyncThunk<
  CommonAxiosResponse<CommonListingsResponse>,
  ListingsThunkRequest,
  { state: RootState }
>('listing/getConsumableListingsViaProductIdThunk', async (data) => {
  const response = await Api.get(
    '/listings/getConsumableListingsViaProductId',
    data
  );
  if (!response.success) {
    throw new Error('Failed getConsumableListingsViaProductIdThunk');
  }
  return response;
});

export const getListingsThunk = createAsyncThunk<
  CommonAxiosResponse<CommonListingsResponse>,
  ListingsThunkRequest,
  { state: RootState }
>('listing/getListingsThunk', async (data) => {
  const response = await Api.get('/listings', data);
  if (!response?.success) {
    throw new Error('Failed getListingsThunk');
  }
  return response;
});

export const getListingsViaIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetListingsViaIDResponse>,
  number,
  { state: RootState }
>('listing/getListingsViaIdThunk', async (id) => {
  const response = await Api.get(`/listings/${id}`);
  if (!response.success) {
    throw new Error('Failed getListingsViaIdThunk');
  }
  return response;
});

export const updateRecentSupplierThunk = createAsyncThunk<
  CommonAxiosResponse<GetRecentOptionResponse>,
  undefined,
  { state: RootState }
>('option/updateRecentSupplierThunk', async (data, apiThunk) => {
  const currentRecentSuppliers = apiThunk.getState().listing.recentSuppliers;

  const response = await Api.patch(
    `/option/update/value/recently-picked-supplier`,
    { value: JSON.stringify(currentRecentSuppliers) }
  );

  if (!response.success) {
    throw new Error('Failed at updateRecentSupplierThunk.');
  }
  return response;
});

export const updateRecentDrNoThunk = createAsyncThunk<
  CommonAxiosResponse<GetRecentOptionResponse>,
  undefined,
  { state: RootState }
>('option/updateRecentDrNoThunk', async (data, apiThunk) => {
  const currentRecentDrNos = apiThunk.getState().listing.recentDRNos;

  const response = await Api.patch(
    `/option/update/value/recently-picked-dr-no`,
    { value: JSON.stringify(currentRecentDrNos) }
  );

  if (!response.success) {
    throw new Error('Failed at updateRecentDrNoThunk.');
  }
  return response;
});

export const updateRecentPurchasedDateThunk = createAsyncThunk<
  CommonAxiosResponse<GetRecentOptionResponse>,
  undefined,
  { state: RootState }
>('option/updateRecentPurchasedDateThunk', async (data, apiThunk) => {
  const currentRecentPurchasedDate = apiThunk.getState().listing
    .recentPurchaseDates;

  const response = await Api.patch(
    `/option/update/value/recently-picked-purchased-date`,
    { value: JSON.stringify(currentRecentPurchasedDate) }
  );

  if (!response.success) {
    throw new Error('Failed at updateRecentPurchasedDateThunk.');
  }
  return response;
});

export const getRecentSupplierThunk = createAsyncThunk<
  CommonAxiosResponse<GetRecentOptionResponse>,
  undefined,
  { state: RootState }
>('option/getRecentSupplierThunk', async () => {
  const response = await Api.get(`/option/get/recently-picked-supplier`);
  if (!response.success) {
    throw new Error('Failed at getRecentSupplierThunk.');
  }
  return response;
});

export const getRecentPurchaseDateThunk = createAsyncThunk<
  CommonAxiosResponse<GetRecentOptionResponse>,
  undefined,
  { state: RootState }
>('option/getRecentPurchaseDateThunk', async () => {
  const response = await Api.get(`/option/get/recently-picked-purchased-date`);
  if (!response.success) {
    throw new Error('Failed at getRecentPurchaseDateThunk.');
  }
  return response;
});

export const getRecentDrNoThunk = createAsyncThunk<
  CommonAxiosResponse<GetRecentOptionResponse>,
  undefined,
  { state: RootState }
>('option/getRecentDrNoThunk', async () => {
  const response = await Api.get(`/option/get/recently-picked-dr-no`);
  if (!response.success) {
    throw new Error('Failed at getRecentDrNoThunk.');
  }
  return response;
});

export const getListingViaSNThunk = createAsyncThunk<
  CommonAxiosResponse<CommonListingResponse>,
  GetListingViaSerialRequest,
  { state: RootState }
>('listing/getListingViaSNThunk', async (data) => {
  const response = await Api.get('/listings/getListingInfoViaSN', data);
  return response;
});

export const isSerialNoExist = createAsyncThunk<
  CommonAxiosResponse<ListingIsSerialNoExistResponse>,
  string,
  { state: RootState }
>('listing/isSerialNoExist', async (serial_no) => {
  const response = await Api.get('/listings/isSerialNoExist', {
    serial_no
  });
  return response;
});

export const updateListingsThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateListingResponse>,
  Listing,
  { state: RootState }
>('listing/updateListingsThunk', async (data) => {
  const response = await Api.patch(`/listings/${data.id}`, data);
  // if (!response.success) {
  //   throw new Error('Failed at updateListingsThunk.');
  // }
  return response;
});

export const deleteListingThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteListingResponse>,
  number,
  { state: RootState }
>('listing/deleteListingThunk', async (id) => {
  const response = await Api.delete(`/listings/${id}`);
  if (!response.success) {
    throw new Error('Failed deleteListingThunk');
  }
  return response;
});

export const createListingThunk = createAsyncThunk<
  CommonAxiosResponse<CreateListingsResponse>,
  CreateListingRequest,
  { state: RootState }
>('listing/createListingThunk', async (data) => {
  const response = await Api.post(`/listings`, data);
  let productId = 0;

  if (data?.multipleListings && data?.multipleListings?.length > 0) {
    productId = data?.multipleListings[0].product_id || 0;
  }

  if (response?.success && productId) {
    await Api.patch(`/gallery/update/amount/${productId}`);
  }
  return response;
});
