// TODO: Fix eslint-disable no-unused-vars
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import { FlashDealsListToolbar } from './Toolbar';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { FlashDealsListTable } from './FlashDealsListTable';
import { useIsMount } from 'src/hooks';
import { Pagination } from '@material-ui/lab';
import { FlashDealsGetRequest } from 'src/types';
// import { FlashDealsGetRequest } from 'src/types';

const { selectors: flashDealsSelectors, actions: flashDealsActions } = slices.flashDeals;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  flashDealsCard: {
    height: '100%'
  }
}));

export const FlashDealsListView = () => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const dispatch = useAppDispatch();
  const isFirstMount = useIsMount();

  const isLoading = useAppSelector(flashDealsSelectors.selectIsListLoading);
  const paginationMeta = useAppSelector(
    flashDealsSelectors.selectFlashDealsPaginationMeta
  );

  const getFlashDeals = useCallback(
    async (request?: FlashDealsGetRequest) =>
      dispatch(flashDealsActions.getFlashDealsListThunk(request || {})),
    [dispatch]
  );

  useEffect(() => {
    if (isFirstMount) {
      getFlashDeals();
    }
    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Flash Deals">
      <Container maxWidth={false}>
        <FlashDealsListToolbar />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <FlashDealsListTable />
        </Box>
      </Container>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Pagination
          count={paginationMeta?.last_page || 1}
          page={paginationMeta?.current_page || 1}
          onChange={(e, p) => getFlashDeals({ page: p })}
        />
      </Box>
    </Page>
  );
};
