import React, { CSSProperties, useCallback, useMemo, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Box,
  Button,
  InputAdornment,
  List,
  ListItem,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import fuzzysort from 'fuzzysort';
import ClearIcon from '@material-ui/icons/Clear';
import { CheckBoxLabel } from 'src/components';
import {
  CustomInputEvent,
  InventoryColumn,
  InventoryLocalColumnFilter,
  InventoryLocalFilter
} from 'src/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { cloneDeep, debounce, uniq } from 'lodash';
import { useToggleArrayState } from 'src/hooks';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const {
  actions: inventoryActions,
  selectors: inventorySelectors
} = slices.inventory;

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'absolute',
    width: 800,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8
  },
  filter: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2)
  },
  footer: {
    marginTop: theme.spacing(4)
  },
  searchBar: {
    marginTop: theme.spacing(1)
  },
  closeBtn: {
    marginTop: theme.spacing(1)
  },
  searchResult: {
    minHeight: 200,
    maxHeight: 500,
    overflow: 'auto'
  }
}));

const bodyStyle: CSSProperties = {
  top: '20%'
};

const modalStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

interface Props {
  visible: boolean;
  columnKey: InventoryColumn;
  onHandleClose: () => void;
}

const LocalFilterModal = ({ visible, onHandleClose, columnKey }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const inventoryLocalFilter = useAppSelector(
    inventorySelectors.selectInventoryLocalFilter
  );
  const inventoryItems = useAppSelector(
    inventorySelectors.selectInventoryResponseItems
  );
  const [localFilterState, setLocalFilterState] = useState<
    InventoryLocalFilter
  >(inventoryLocalFilter);

  const [searchInputText, setSearchInputText] = useState('');
  const [searchResult, setSearchResult] = useState<string[]>(() => {
    const columnVals = inventoryItems?.map(
      (x) => x[columnKey]?.toString() || ''
    );
    const uniqColumnVals = uniq(columnVals).sort();
    return uniqColumnVals;
  });

  const [
    selectedListItem,
    toggleSelectedListItem,
    resetAllListItem,
    toggleCustomItems
  ] = useToggleArrayState(inventoryLocalFilter[columnKey]?.text || []);

  const origSortedUniqResult = useMemo(() => {
    const columnVals = inventoryItems?.map(
      (x) => x[columnKey]?.toString() || ''
    );
    const uniqColumnVals = uniq(columnVals).sort();
    return uniqColumnVals;
  }, [columnKey, inventoryItems]);

  const isListResultChecked = useCallback(
    (value: string) => selectedListItem.includes(value),
    [selectedListItem]
  );

  const isAllSelected = useMemo(() => {
    const allExist = origSortedUniqResult.every(isListResultChecked);
    return allExist;
  }, [isListResultChecked, origSortedUniqResult]);

  const onToggleAll = () => {
    if (isAllSelected) {
      resetAllListItem();
    } else {
      toggleCustomItems(origSortedUniqResult);
    }
  };

  const onClose = () => {
    resetStates();
    onHandleClose();
  };

  const resetStates = () => {
    resetAllListItem();
    setSearchResult([]);
    setSearchInputText('');
  };

  const onApply = () => {
    const newColumnFilter: InventoryLocalColumnFilter = {
      columnName: columnKey,
      sortBy: localFilterState[columnKey]?.sortBy,
      text: selectedListItem
    };
    const newFilter = {
      ...localFilterState,
      [columnKey]: newColumnFilter
    };
    dispatch(
      inventoryActions.onUpdateLocalFilter({ newFilter, column: columnKey })
    );
    onClose();
  };

  const onToggleSort = (sortBy: 'ASC' | 'DESC') => {
    setLocalFilterState((prev) => ({
      ...prev,
      [columnKey]: { ...prev[columnKey], sortBy }
    }));
  };

  const onToggleListItem = useCallback(
    (item: string) => {
      toggleSelectedListItem(item);
    },
    [toggleSelectedListItem]
  );

  const debouncedSearch = useCallback(
    debounce((keyword: string) => {
      // Cleared
      if (!keyword) {
        setSearchResult(origSortedUniqResult || []);
        return;
      }
      const clonedUniqChoices = cloneDeep(origSortedUniqResult || []);
      if (!Array.isArray(clonedUniqChoices)) {
        return;
      }

      const options = {
        keys: null,
        limit: 50, // don't return more results than you need!
        allowTypo: true, // if you don't care about allowing typos
        threshold: -500, // don't return bad results
        scoreFn: (a: any) =>
          // eslint-disable-next-line implicit-arrow-linebreak
          Math.max(a[0] ? a[0].score : -1000, a[1] ? a[1].score - 100 : -1000)
      };

      const results = fuzzysort.go(keyword, clonedUniqChoices, options);
      console.log(results);

      if (results && results?.length === 0) {
        setSearchResult([]);
        return;
      }
      if (results && results.length > 0) {
        const mappedResults: string[] = results.map((x) => x.target);
        const uniqResult: string[] = uniq(mappedResults);
        setSearchResult(uniqResult);
        return;
      }
      setSearchResult([]);
    }, 500),
    [origSortedUniqResult]
  );

  const handleInputChange = (e: CustomInputEvent) => {
    const { value } = e.target;
    setSearchInputText(value);
    debouncedSearch(value);
  };

  const onClearInput = () => {
    setSearchInputText('');
    debouncedSearch('');
  };

  const getProductLength = useCallback((value: string) => {
    const length = inventoryItems?.filter((x) => x[columnKey] === value )?.length;
    return length || 0;
  }, [columnKey, inventoryItems]);

  return (
    <Modal
      disableBackdropClick
      open={visible}
      onClose={onHandleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={modalStyle}
    >
      <div style={bodyStyle} className={classes.body}>
        <div className={classes.titleContainer}>
          <Typography variant="h4" color="textPrimary">
            {columnKey} - Inventory Filter
          </Typography>
        </div>
        <div className={classes.filter}>
          <Typography variant="h5" color="textPrimary">
            Sort:
          </Typography>
          <CheckBoxLabel
            label="Ascending (0 to Z)"
            onChange={() => onToggleSort('ASC')}
            checked={localFilterState[columnKey]?.sortBy === 'ASC'}
          />
          <CheckBoxLabel
            label="Descending (Z to 0)"
            onChange={() => onToggleSort('DESC')}
            checked={localFilterState[columnKey]?.sortBy === 'DESC'}
          />
        </div>
        <div className={classes.filter}>
          <Typography variant="h5" color="textPrimary">
            Text Filter:
          </Typography>
          <TextField
            helperText={`${columnKey} that are already selected will still be selected even if not shown in search result`}
            fullWidth
            label={`Search text in ${columnKey} column`}
            name={columnKey}
            onChange={handleInputChange}
            className={classes.searchBar}
            value={searchInputText ?? ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <ClearIcon onClick={onClearInput} />
                </InputAdornment>
              )
            }}
            variant="outlined"
          />
        </div>
        <div className={classes.filter}>
          <Typography variant="h6" color="textPrimary">
            Selected Count: {`${selectedListItem?.length || 0}`}
          </Typography>
          <Button endIcon={<DoneAllIcon />} onClick={onToggleAll}>
            {isAllSelected ? 'UNSELECT ALL' : 'SELECT ALL'}
          </Button>
          <div className={classes.searchResult}>
            <List>
              {searchResult?.map((value) => {
                return (
                  <ListItem key={value} role={undefined} dense>
                    <CheckBoxLabel
                      label={`${value} - (${getProductLength(value)})` || ''}
                      checked={isListResultChecked(value)}
                      onChange={() => onToggleListItem(value)}
                    />
                  </ListItem>
                );
              })}
            </List>
          </div>
        </div>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="space-evenly"
          className={classes.footer}
        >
          <Button
            fullWidth
            onClick={onApply}
            color="primary"
            variant="contained"
          >
            Apply
          </Button>
          <Button
            className={classes.closeBtn}
            fullWidth
            onClick={onClose}
            variant="outlined"
          >
            Close
          </Button>
        </Box>
      </div>
    </Modal>
  );
};

export const InventoryLocalFilterModal = React.memo(LocalFilterModal);
