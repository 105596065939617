import React, { ChangeEvent, FC, memo, useEffect, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  searchLabel?: string;
  isClearEvent?: boolean;
  isDisable?: boolean;
  onHandleSearchKeyword: (searchKeyword: string) => void;
}

const component: FC<Props> = ({
  searchLabel = 'Search Products',
  isClearEvent,
  isDisable = false,
  onHandleSearchKeyword
}) => {
  const [searchInput, setSearchInput] = useState<string>('');

  const onChangeKeyword = (event: ChangeEvent<HTMLInputElement>) => {
    const keywordValue = event.target.value;
    setSearchInput(keywordValue);
  };

  const onHandleClearKeyword = () => {
    setSearchInput('');
  };

  useEffect(() => {
    onHandleSearchKeyword(searchInput);
  }, [onHandleSearchKeyword, searchInput]);

  //use for external event to clear search inputs
  useEffect(() => {
    if (isClearEvent) {
      setSearchInput('');
    }
  }, [isClearEvent]);

  return (
    <TextField
      value={searchInput}
      label={searchLabel}
      autoComplete="on"
      fullWidth
      name="Search"
      onChange={onChangeKeyword}
      variant="outlined"
      disabled={isDisable}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        endAdornment: (
          <IconButton onClick={onHandleClearKeyword}>
            {searchInput && <CloseIcon />}
          </IconButton>
        )
      }}
    />
  );
};

export const SearchKeyword = memo(component);
