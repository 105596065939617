import { createSlice } from '@reduxjs/toolkit';
import { GetTransactionPaymentsResponse } from 'src/types';
import * as thunks from './thunks';

type State = {
  transactionPaymentsResponse: GetTransactionPaymentsResponse | undefined;
};

const initialState: State = {
  transactionPaymentsResponse: undefined
};

const slice = createSlice({
  name: 'transaction-payments',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getTransactionPaymentsThunk.fulfilled,
      (state, { payload }) => {
        state.transactionPaymentsResponse = payload.originalData;
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
