import React, { useCallback, useMemo } from 'react';
import { Typography } from '@material-ui/core';

import { useProductPartDetailsLogic } from 'src/hooks';
import { PartFields, ProductPartDetailsCategory } from 'src/types';
import { CheckBoxLabel } from 'src/components';

interface Props {
  productCategory: ProductPartDetailsCategory;
  field: PartFields<any>;
}

const component = ({ productCategory, field }: Props) => {
  const {
    fieldError,
    getProductPartDetailsViaKey,
    setProductPartDetailsPropertyViaKey
  } = useProductPartDetailsLogic();

  const detailsValue = useMemo(
    () => getProductPartDetailsViaKey(productCategory) || {},
    [getProductPartDetailsViaKey, productCategory]
  );

  const onFieldChange = async (value: boolean) => {
    setProductPartDetailsPropertyViaKey(
      productCategory,
      field?.part_property,
      value
    );
  };

  const fieldErr = useCallback(
    (fieldArg) => {
      return fieldError(productCategory, fieldArg) ? true : false;
    },
    [fieldError, productCategory]
  );

  return (
    <div>
      <CheckBoxLabel
        label={field?.label}
        checked={detailsValue[field?.part_property] || false}
        onChange={(v) => onFieldChange(v)}
      />
      {fieldErr(field?.part_property) ? (
        <Typography color="secondary" variant="caption">
          Error
        </Typography>
      ) : null}
    </div>
  );
};

export const ProductPartFieldBoolean = React.memo(component);
