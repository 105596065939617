import React, { useRef, useState } from 'react';
import Papa from 'papaparse';
import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Chip,
  Divider,
  Grid,
  TextField,
  Tooltip
} from '@material-ui/core';
import {
  Alert,
  Autocomplete,
  AutocompleteChangeReason
} from '@material-ui/lab';
import { cloneDeep } from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';
import PublishIcon from '@material-ui/icons/Publish';
import GetAppIcon from '@material-ui/icons/GetApp';

import { slices } from 'src/redux';
import { addArrayInArrayUnique, downloadCSV } from 'src/utils';
import { colors } from 'src/constants';
import { useSnackBar } from 'src/hooks';

const { utils: smsUtils } = slices.sms;

interface Props {
  title?: string;
  subtitle?: string;
  value?: string[];
  onChange: (val: string[]) => void;
  disabled?: boolean;
}

export const MobileNosInput: React.FC<Props> = ({
  title,
  subtitle,
  value = [],
  onChange,
  disabled
}) => {
  const snackbar = useSnackBar();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [warnMsg, setWarnMsg] = useState<string>('');

  const onChangeViaMultiple = (inputVal: string) => {
    const clonedValues = cloneDeep(value);
    const multipleMobileNosOneLinerArr = inputVal?.split(',');

    if (multipleMobileNosOneLinerArr?.length > 0) {
      const invalidNos: string[] = [];
      const validNos: string[] = [];

      multipleMobileNosOneLinerArr?.forEach((mobileNo) => {
        const standardMobileNo = smsUtils.standardizeMobileNumber(mobileNo);
        if (!standardMobileNo) {
          invalidNos.push(mobileNo);
          return;
        }
        validNos.push(standardMobileNo);
      });

      if (invalidNos?.length > 0) {
        setWarnMsg(`${invalidNos?.toString()} is/are invalid`);
      }
      const result = addArrayInArrayUnique(clonedValues, validNos);
      onChange(result);
      return;
    }
  };

  const onChangeInputValues = (
    inputVal: string,
    val: any,
    reason: AutocompleteChangeReason
  ) => {
    setWarnMsg('');

    // A chip is being deleted
    if (reason === 'remove-option') {
      onChange(val);
      return;
    }

    const multipleMobileNosOneLinerArr = inputVal?.split(',');
    if (multipleMobileNosOneLinerArr?.length > 0) {
      onChangeViaMultiple(inputVal);
      return;
    }

    // A single mobile no is being inputted
    const clonedValues = cloneDeep(value);
    const standardMobileNo = smsUtils.standardizeMobileNumber(inputVal);
    if (!standardMobileNo) {
      setWarnMsg(`${inputVal} number is invalid`);
      return;
    }
    const result = addArrayInArrayUnique(clonedValues, [standardMobileNo]);
    onChange(result);
  };

  const onDownloadSampleCSVFile = () => {
    const mobileNosCsvSample = [
      { MobileNumber: '639673979777' },
      { MobileNumber: '639123456789' }
    ];
    const csvName = 'mobile_nos.csv';

    downloadCSV(mobileNosCsvSample, csvName);
  };

  const onImportCsvFile = (file?: File) => {
    if (!file) {
      snackbar.show({ message: 'File is undefined', severity: 'error' });
      return;
    }
    // const file: File = e.target.files?.[0];
    Papa.parse(file, {
      complete: (result) => {
        const extractedNumbers = result.data.map(
          (row: any) => row['MobileNumber']
        );

        //if the csv column header is not equal to "MobileNumber" or no numbers are valid
        if (extractedNumbers[0] === undefined) {
          snackbar.show({
            message:
              'CSV column header is not equal to "MobileNumber" or no numbers are valid',
            severity: 'error'
          });
          return;
        }
        if (extractedNumbers?.length <= 0) {
          snackbar.show({ severity: 'error', message: 'Empty mobile no(s)' });
          return;
        }
        const mobileNosFromCSVString = extractedNumbers?.toString();
        onChangeViaMultiple(mobileNosFromCSVString);
        return;
      },
      header: true
    });
  };

  return (
    <Card style={{ marginTop: 20 }}>
      <input
        type="file"
        accept=".csv"
        onChange={(e) => onImportCsvFile(e.target.files?.[0])}
        style={{ display: 'none' }}
        id="csvInput"
        ref={fileInputRef}
      />

      <CardHeader title={title} subheader={subtitle} />
      <Divider />

      {warnMsg ? <Alert severity="warning">{warnMsg}</Alert> : null}

      <CardContent>
        <Grid container>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <Autocomplete
              options={[]}
              value={value}
              disabled={disabled}
              limitTags={10}
              onChange={(e: any, val, reason) => {
                onChangeInputValues(e.target?.value, val, reason);
              }}
              freeSolo
              multiple
              renderTags={(value, props) =>
                value.map((option, index) => (
                  <Chip
                    key={index}
                    label={option}
                    {...props({ index })}
                    style={{
                      backgroundColor:
                        index % 2 === 0 ? colors.grey[300] : colors.grey[100]
                    }}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  variant="outlined"
                  label="Mobile No(s)."
                  {...params}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} style={{ marginTop: 20 }}>
            <Button
              startIcon={<ClearIcon />}
              onClick={() => onChange([])}
              color="secondary"
              variant="outlined"
            >
              Clear All
            </Button>
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              marginTop: 20,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <ButtonGroup
              // className={classes.customSeparator}
              variant="outlined"
              color="primary"
            >
              {/*  IF THERE IS A SET DATE CHANGE LABEL TO "SEND AT NOVEMBER 30, 2023 8:50 PM", SOMETHING LIKE THAT */}
              <Button
                fullWidth
                color="primary"
                startIcon={<PublishIcon />}
                onClick={() => fileInputRef?.current?.click()}
              >
                Upload CSV file
              </Button>
              <Tooltip title="Download Sample CSV File">
                <Button
                  onClick={onDownloadSampleCSVFile}
                  size="small"
                  color="primary"
                >
                  <GetAppIcon />
                </Button>
              </Tooltip>
            </ButtonGroup>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
