import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { slices, useAppDispatch } from 'src/redux';
import { UpdateProductDescriptionPayload } from 'src/types';
import { useSnackBar } from '../use-snackbar';

const { actions: productAction } = slices.product;

export const useProductDescription = () => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [updateProdIsLoading, setUpdateProdIsLoading] = useState<boolean>(
    false
  );

  const updateProductDescription = useCallback(
    async (params: UpdateProductDescriptionPayload) => {
      try {
        setUpdateProdIsLoading(true);
        const response = unwrapResult(
          await dispatch(productAction?.updateProductDescriptionThunk(params))
        );

        if (response?.success) {
          setUpdateProdIsLoading(false);
          snackBar.show({ severity: 'success', message: response?.message });
        } else {
          snackBar.show({ severity: 'error', message: response?.message });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setUpdateProdIsLoading(false);
      }
    },
    [dispatch, snackBar]
  );

  return { updateProdIsLoading, updateProductDescription };
};
