import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectListing = (state: RootState) => state.listing;

const selectListings = createSelector(selectListing, (state) => state.listings);
const selectListingsRecentDRNos = createSelector(selectListing, (state) => state.recentDRNos);
const selectPurchaseListingDate = createSelector(selectListing, (state) => state.recentPurchaseDates);
const selectRecentSuppliers = createSelector(selectListing, (state) => state.recentSuppliers);
const selectRecentBranches = createSelector(selectListing, (state) => state.recentBranches);

export const selectors = {
  selectListings,
  selectListingsRecentDRNos,
  selectPurchaseListingDate,
  selectRecentSuppliers,
  selectRecentBranches
};
