/* eslint-disable no-unused-vars */
import React, { useCallback } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { EcomOrderConcern, EcomOrderProduct } from 'src/types';
import { Alert } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
  concerns?: EcomOrderConcern[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  alert: {
    margin: theme.spacing(1)
  }
}));

const component = ({ concerns }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <Card className={classes.root} style={{ border: 1 }}>
      <CardHeader
        title="!!! CONCERNS !!! !!! CONCERNS !!! !!! CONCERNS !!! !!! CONCERNS !!!"
        titleTypographyProps={{ color: 'error' }}
      />
      <Divider />

      <Alert className={classes.alert} severity="error">
        <Typography variant="h5">
          SOME PRODUCT(S) HAVE ISSUES ON THIS ORDER!!! THIS HAPPENS SOMETIMES IF
          CUSTOMER PAID BUT IT WENT OUT OF STOCK
        </Typography>
      </Alert>

      <CardContent>
        <Grid container spacing={3}>
          {concerns && concerns?.length > 0
            ? concerns?.map((issue, i) => (
                <Grid key={`${issue?.id}${i}}`} item xs={12}>
                  <Typography display="inline" variant="h5">
                    - {issue?.concern}{' '}
                  </Typography>

                  {/* <LinkComponent
                    openInNewTab
                    href={`/app/products/${productData(issue?.concern)}`}
                    title={transactionNo}
                    onClick={() =>
                      navigate(`/app/transaction/${transactionNo}`)
                    }
                  /> */}
                </Grid>
              ))
            : null}
        </Grid>
      </CardContent>
    </Card>
  );
};

export const EcomOrderConcerns = React.memo(component);
