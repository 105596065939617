import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import QRCode from 'qrcode';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard } from 'src/utils';

interface Props {
  isOpen: boolean;
  paymentLink?: string;
  handleClose: () => void;
}

const component: FC<Props> = ({ isOpen = false, paymentLink, handleClose }) => {
  const [paymentSuccessQr, setPaymentSuccessQr] = useState<string>('');

  const generateQRCode = useCallback(() => {
    try {
      if (paymentLink) {
        QRCode.toDataURL(paymentLink, { errorCorrectionLevel: 'H' })
          .then((url) => setPaymentSuccessQr(url))
          .catch((err) => console.error(err));
      }
    } catch (error) {
      console.error(error);
    }
  }, [paymentLink]);

  const onTryCloseDialog = (reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (reason === 'backdropClick') {
      return;
    }
    handleClose();
  };

  useEffect(generateQRCode, [generateQRCode]);

  return (
    <Dialog
      disableEscapeKeyDown
      style={{ margin: 100 }}
      open={isOpen}
      onClose={(_, reason) => onTryCloseDialog(reason)}
    >
      <DialogTitle style={{ textAlign: 'center' }} id="product-description">
        <Typography color="error" variant="h2">
          NOTES:
        </Typography>
        <Typography color="error" variant="h4">
          CUSTOMER NEEDS TO BE LOGGED IN ON ECOMM
        </Typography>
        <Typography color="error" variant="h4">
          CUSTOMER NEEDS TO GO TO THIS URL FOR THEIR PURCHASE TO BE VALID
        </Typography>
      </DialogTitle>
      <DialogContent
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }}
      >
        {paymentSuccessQr ? (
          <img src={paymentSuccessQr} alt="WALK IN QR CODE" />
        ) : null}

        <Button
          style={{ marginTop: 10 }}
          startIcon={<FileCopyIcon />}
          onClick={() => copyToClipboard(paymentLink || '')}
          variant="outlined"
        >
          <Typography variant="h4">copy payment success link</Typography>
        </Button>

        <Button
          onClick={() => onTryCloseDialog()}
          style={{ marginTop: 50 }}
          variant="text"
        >
          <Typography color="secondary" variant="caption">
            Link sent to customer - Close now
          </Typography>
        </Button>

        <Button
          onClick={() => onTryCloseDialog()}
          style={{ marginTop: 10 }}
          variant="text"
        >
          <Typography color="secondary" variant="caption">
            Customer does not need this - Close now
          </Typography>
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export const PaymentSuccessLinkDialog = memo(component);
