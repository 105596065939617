import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { LaunchOutlined, RemoveCircleOutline } from '@material-ui/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { AlertDialog, AlternatingColorTableRow } from 'src/components';
import { usePermissions, useSnackBar } from 'src/hooks';
import { slices, useAppDispatch } from 'src/redux';
import { BuildDetailsWithProducts, BuildProducts } from 'src/types/user-builds';
import { formatCurrency } from 'src/utils';

const { actions: userBuildsActions } = slices.userBuilds;

export const BuildDetailsPage: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    canDeleteEcommSavedBuilds,
    canUpdateEcommSavedBuilds
  } = usePermissions();
  const snackBar = useSnackBar();

  const savedBuild: any = location.state;

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [components, setComponents] = useState<BuildProducts[]>([]);
  const [buildName, setBuildName] = useState<string>('');
  const [buildDetails, setBuildDetails] = useState<BuildDetailsWithProducts>(
    {}
  );

  const disableSave = useMemo(() => {
    const conditions = [!components[0], isLoading, !buildName];

    return conditions.some((i) => i === true);
  }, [components, isLoading, buildName]);

  const handleSaveChanges = async () => {
    if (buildName.length <= 0 || components.length <= 0) {
      return;
    }
    const products = components.map((i) => ({
      product_slug: i.product_slug,
      quantity: i.quantity
    }));

    const response = unwrapResult(
      await dispatch(
        userBuildsActions.updateUserBuildThunk({
          id: buildDetails.id,
          name: buildName,
          products: products
        })
      )
    );

    if (response.success) {
      snackBar.show({
        severity: 'success',
        message: response.message,
        useSound: true
      });
      handleGetBuildDetails();
    } else {
      snackBar.show({
        severity: 'error',
        message: response.message,
        useSound: true
      });
    }
  };

  const handleRemoveComponent = (product: string) => {
    if (components[0]) {
      const updatedProducts = components?.filter(
        (p) => p.product_name !== product
      );

      setComponents(updatedProducts);
    }
  };

  const handleChangeInput = (val: string) => {
    setBuildName(val);
  };

  const handleOpenDeleteDialog = () => setIsDeleteDialogOpen(true);

  const handleCloseDialog = () => setIsDeleteDialogOpen(false);

  const handleConfirmDeleteBuild = async () => {
    const response = unwrapResult(
      await dispatch(
        userBuildsActions.deleteUserBuildThunk({
          id: buildDetails.id?.toString()
        })
      )
    );

    // May replace with snackbar
    response?.success
      ? navigate('/app/saved-builds')
      : console.log('Failed to delete this build');
  };

  const handleGetBuildDetails = async () => {
    setIsLoading(true);
    const newID = savedBuild?.id?.toString();

    const response = unwrapResult(
      await dispatch(userBuildsActions.getUserBuildDetailsThunk({ id: newID }))
    );

    if (response.success) {
      setBuildDetails(response?.originalData?.data ?? {});
      setComponents(response?.originalData?.data?.build_products ?? []);
      setBuildName(response?.originalData?.data?.build_name ?? '');
    }

    setTimeout(() => setIsLoading(false), 500);
  };

  const onProductPress = (item: BuildProducts) => {
    navigate(`/app/products/${item.product_id}`);
  };

  useEffect(() => {
    handleGetBuildDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card style={{ marginTop: '10px', padding: '.5em' }}>
      <CardContent>
        <Grid container spacing={2}>
          <Typography
            style={{ marginTop: 10 }}
            color="textPrimary"
            gutterBottom
            variant="h3"
          >
            {buildDetails.build_name}
          </Typography>
        </Grid>
        <Box>
          <Card style={{ marginTop: '1em', padding: '1em' }}>
            <div style={{ width: 'inherit' }}>
              <Typography color="textPrimary" variant="h5" display="inline">
                Created On:{' '}
              </Typography>
              <Typography display="inline">{buildDetails.built_at}</Typography>
            </div>
            {canUpdateEcommSavedBuilds ? (
              <div style={{ marginTop: '1em' }}>
                <TextField
                  error={!buildName}
                  helperText={!buildName ? 'Build Name Required' : ''}
                  fullWidth
                  required
                  name="branchName"
                  variant="outlined"
                  label="Build Name"
                  onChange={(e) => handleChangeInput(e.target.value)}
                  value={buildName}
                  style={{ marginBottom: '15px' }}
                  inputProps={{ maxLength: 75 }}
                  disabled={isLoading}
                />
              </div>
            ) : null}
            <Grid
              container
              style={{
                padding: '.5em',
                justifyContent: 'space-between'
              }}
            >
              <Grid item>
                {canDeleteEcommSavedBuilds ? (
                  <Button
                    onClick={handleOpenDeleteDialog}
                    variant="contained"
                    color="secondary"
                    disabled={isLoading}
                  >
                    Delete Build
                  </Button>
                ) : null}
              </Grid>
              <Grid item>
                {/* TODO: Replace with edit build permissions */}
                {canUpdateEcommSavedBuilds ? (
                  <Button
                    disabled={disableSave}
                    onClick={handleSaveChanges}
                    variant="contained"
                    color="primary"
                  >
                    Save Details
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          </Card>
          <Card
            style={{
              padding: '1em 0 0 0',
              marginTop: '1em'
            }}
          >
            <Typography variant="h5" style={{ marginLeft: '1em' }}>
              Components:
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Retail Price</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell>Stocks Left</TableCell>
                </TableRow>
              </TableHead>
              {components?.map((p, ind) => (
                <AlternatingColorTableRow key={ind}>
                  <TableCell onClick={() => onProductPress(p)}>
                    <Button
                      endIcon={<LaunchOutlined />}
                      style={{
                        fontSize: '.8em',
                        textAlign: 'left'
                      }}
                    >
                      {p.product_name}
                    </Button>
                  </TableCell>
                  <TableCell>{p.category_name}</TableCell>
                  <TableCell>{formatCurrency(p.retail_price)}</TableCell>
                  <TableCell>{p.quantity}</TableCell>
                  <TableCell>
                    <Grid
                      container
                      style={
                        {
                          // border: "1px solid black",
                        }
                      }
                    >
                      <Grid
                        item
                        md={8}
                        style={{
                          display: 'flex',
                          alignItems: 'center'
                        }}
                      >
                        {p.stocks_left}
                      </Grid>
                      <Grid item md={1}>
                        {canUpdateEcommSavedBuilds ? (
                          <Button
                            color="secondary"
                            onClick={() =>
                              handleRemoveComponent(p?.product_name ?? '')
                            }
                          >
                            <RemoveCircleOutline />
                          </Button>
                        ) : null}
                      </Grid>
                    </Grid>
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </Table>
            <div
              style={{
                width: 'inherit',
                margin: '1em 3em .5em 0',
                textAlign: 'right'
              }}
            >
              <Typography color="textPrimary" variant="h4" display="inline">
                Amount:{' '}
              </Typography>
              <Typography
                display="inline"
                style={{
                  fontSize: '19px'
                }}
              >
                {formatCurrency(buildDetails.build_amount)}
              </Typography>
            </div>
          </Card>
        </Box>
      </CardContent>
      <AlertDialog
        isVisible={isDeleteDialogOpen}
        title="Warning!"
        subTitle="You are about to delete this build"
        customButtons={
          <>
            <Button color="secondary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleConfirmDeleteBuild}>
              Confirm
            </Button>
          </>
        }
      />
    </Card>
  );
};
