import { createSlice } from '@reduxjs/toolkit';
import { QuotationCategory, TemplateList } from 'src/types/quotation';
import * as thunks from './thunks';

type State = {
  quotationCategories: QuotationCategory[];
  templateList: TemplateList[]
};

const initialState: State = {
  quotationCategories: [],
  templateList: []
};

const slice = createSlice({
  name: 'quotations',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getQuotationsCategoriesThunk.fulfilled,
      (state, { payload }) => {
        state.quotationCategories = payload.originalData.categories || [];
      }
    );

    builder.addCase(
      thunks.getTemplateListThunk.fulfilled,
      (state, { payload }) => {
        state.templateList = payload.originalData.data || [];
      }
    );
  }

});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
