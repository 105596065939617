import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  CreateFlashDealsResponse,
  FlashDealsCreateRequest
} from 'src/types';

export const flashDealsCreateThunk = createAsyncThunk<
  CommonAxiosResponse<CreateFlashDealsResponse>,
  FlashDealsCreateRequest,
  { state: RootState }
>('flashDeals/createFlashDealsThunk', async (data) => {
  const response = await Api.post(`/ecomm/internal/flash_deal/create`, data);
  return response;
});
