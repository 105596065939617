import { createSlice } from '@reduxjs/toolkit';
import { Supplier } from 'src/types/supplier';
import * as thunks from './thunks';

type State = {
  suppliers: Supplier[];
};

const initialState: State = {
  suppliers: []
};

const slice = createSlice({
  name: 'suppliers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getSuppliersThunk.fulfilled, (state, { payload }) => {
      state.suppliers = payload.originalData.suppliers;
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
