import { TableCell } from '@material-ui/core';
import React from 'react';
import { AlternatingColorTableRow } from 'src/components/table';
import { Customer, UserData } from 'src/types';
import { getUserStatusColor } from 'src/utils';

interface Props {
  customer: UserData;
  onCustomerPress: (customer: Customer) => void;
}

export const PcwUserListItem = ({ customer, onCustomerPress }: Props) => {
  return (
    <AlternatingColorTableRow
      onClick={() => onCustomerPress(customer)}
      hover
      key={customer.id}
    >
      <TableCell>{customer?.id}</TableCell>
      <TableCell>{customer?.uuid}</TableCell>
      <TableCell>{customer?.full_name}</TableCell>
      <TableCell
        style={{
          color: getUserStatusColor(customer?.status)
        }}
      >
        {customer?.status}
      </TableCell>
      <TableCell>{customer?.active}</TableCell>
      <TableCell>{customer?.created_at}</TableCell>
      <TableCell>{customer?.updated_at}</TableCell>
    </AlternatingColorTableRow>
  );
};
