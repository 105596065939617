import React, { FC, memo, useCallback, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Page,
  View,
  Text,
  Image,
  PDFViewer,
  Document,
  StyleSheet
} from '@react-pdf/renderer';
import { Listing } from 'src/types';
import { formatDate } from 'src/utils';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: '11.11%'
  },
  tableRow: {
    flexDirection: 'row'
  },
  table: {
    display: 'flex',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 5,
    marginBottom: 15
  },
  renderPageNum: {
    marginTop: 'auto',
    flexDirection: 'row',
    width: '100%',
    border: 1,
    padding: 5,
    marginBottom: 15
  },
  tableCell: {
    padding: 5,
    margin: 'auto'
  },
  textHeader: {
    padding: 3,
    fontSize: 10,
    fontFamily: 'Helvetica-Bold'
  },
  textBold: {
    fontSize: 8,
    fontFamily: 'Helvetica-Bold',
    overflow: 'hidden'
  },
  parentView: {
    flexDirection: 'column',
    padding: 5,
    margin: 5,
    width: '100%'
  },
  logo: {
    height: 'auto',
    width: 150
  },
  header: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    marginBottom: '10px'
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5
  },
  nameAndDate: {
    marginTop: 5,
    marginBottom: 5,
    display: 'flex',
    justifyContent: 'space-between'
  }
});

interface DataRowProps {
  item?: Listing;
}

interface HeaderRowProps {
  value: string;
}

interface Props {
  isOpen?: boolean;
  listings?: Listing[];
  toggleDialogOpen?: () => void;
}

const component: FC<Props> = ({
  isOpen = false,
  listings,
  toggleDialogOpen
}) => {
  const pageSize = 19; // Optimal number of items to display per page for a balanced layout
  const ColsHeader = [
    'DR No.',
    'Serial No.',
    'Supplier',
    'Branch',
    'Dealers Price',
    'Price',
    'Date Purchased (Supplier)',
    'Date Encoded',
    'Status'
  ];

  // intended to refresh if isOpen is change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const date = useMemo(() => new Date(), [isOpen]);

  const productName = useMemo(() => {
    const maxLength = 72;
    if (listings) {
      let str = listings[0]?.product_name ?? '';
      if (str.length > maxLength) {
        str = str.substring(0, maxLength) + '...';
      }
      return str;
    } else {
      return '';
    }
  }, [listings]);

  const listingPerPage = useMemo(() => {
    if (!listings?.length) {
      return [];
    }

    let dataPerPage: Listing[] = [];
    let allData: Listing[][] = [];
    const listingsLength = listings.length;

    listings.forEach((listing, i) => {
      dataPerPage.push(listing);

      if (dataPerPage.length === pageSize || i === listingsLength - 1) {
        allData.push(dataPerPage);
        dataPerPage = [];
      }
    });

    return allData;
  }, [listings]);

  const responsiveStyles = useCallback((val: string) => {
    switch (val) {
      case 'dr_no':
        return [styles.tableCol, { width: '12.11%' }];
      case 'serial_no':
        return [styles.tableCol, { width: '22.11%' }];
      case 'dealers_price':
        return [styles.tableCol, { width: '8.11%' }];
      case 'retail_price':
        return [styles.tableCol, { width: '8.11%' }];
      case 'branch':
        return [styles.tableCol, { width: '9.11%' }];
      case 'status':
        return [styles.tableCol, { width: '8.11%' }];
      default:
        return [styles.tableCol];
    }
  }, []);

  const ListingHeaderRow: FC<HeaderRowProps> = ({ value }) => {
    const header = useMemo(() => {
      const commonStyles = [styles.tableCol];
      const columnWidths: { [key: string]: string } = {
        'DR No.': '12.11%',
        'Serial No.': '22.11%',
        'Dealers Price': '8.11%',
        Price: '8.11%',
        Branch: '9.11%',
        Status: '8.11%'
      };

      return {
        styles: columnWidths[value]
          ? [...commonStyles, { width: columnWidths[value] }]
          : commonStyles,
        text: value
      };
    }, [value]);

    return (
      <View style={header.styles}>
        <Text style={[styles.tableCell, styles.textBold]}>{header.text}</Text>
      </View>
    );
  };

  const ListingDataRow: FC<DataRowProps> = ({ item }) => {
    const hasItem = !!item?.serial_no;

    // any intended
    const rowData: { [key: string]: any } = useMemo(() => {
      return hasItem
        ? {
            dr_no: item.dr_no,
            serial_no: item.serial_no,
            supplier: item.supplier?.name ?? '',
            branch: item.branch,
            dealers_price: `${item.dealers_price}.00`,
            retail_price: `${item.retail_price}.00`,
            purchase_date: formatDate(item.purchase_date),
            created_at: formatDate(item.created_at),
            status: item.status
          }
        : {};
    }, [item, hasItem]);

    const cols = Object.keys(rowData);

    return (
      <View style={styles.tableRow}>
        {hasItem &&
          cols.map((i: string, ind) => (
            <View key={ind} style={responsiveStyles(i)}>
              <Text style={[styles.tableCell, styles.textBold]}>
                {rowData[i]}
              </Text>
            </View>
          ))}
      </View>
    );
  };

  return (
    <Dialog maxWidth={'md'} fullWidth open={isOpen} onClose={toggleDialogOpen}>
      <DialogTitle>
        <Typography variant="h4">Product Listing</Typography>
        <IconButton
          aria-label="close"
          style={styles.closeButton}
          color="secondary"
          onClick={toggleDialogOpen}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PDFViewer
          width="100%"
          height="100%"
          style={{ height: '80vh', width: '100%' }}
        >
          <Document title={'Product Listing'}>
            {listingPerPage?.[0] &&
              listingPerPage?.map((i, ind) => (
                <Page key={ind} size={[842, 595]} style={styles.page}>
                  <View style={styles.parentView}>
                    <View style={styles.header}>
                      <Image
                        style={styles.logo}
                        src="/static/new_drj_logo.png"
                      />
                    </View>
                    <View style={[styles.tableRow, styles.nameAndDate]}>
                      <Text style={styles.textHeader}>{productName}</Text>
                      <Text
                        style={styles.textHeader}
                      >{`${date.toLocaleDateString()} ${date.toLocaleTimeString(
                        [],
                        {
                          hour: '2-digit',
                          minute: '2-digit'
                        }
                      )}`}</Text>
                    </View>
                    <View style={styles.table} wrap>
                      <View style={styles.tableRow}>
                        {ColsHeader.map((i) => (
                          <ListingHeaderRow key={i} value={i} />
                        ))}
                      </View>

                      {i?.[0] &&
                        i.map((j: Listing) => (
                          <ListingDataRow item={j} key={j.serial_no} />
                        ))}
                    </View>
                    <View style={styles.renderPageNum} break>
                      <Text style={styles.textBold}>{`Page ${ind + 1} of ${
                        listingPerPage.length
                      } `}</Text>
                    </View>
                  </View>
                </Page>
              ))}
          </Document>
        </PDFViewer>
      </DialogContent>
    </Dialog>
  );
};

export const ListingPrintModal = memo(component);
