import React from 'react';
import { Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const AlertInfo = () => {
  return (
    <Alert severity="info">
      <Typography variant="subtitle2">RMA - Set item for RMA.</Typography>
      <Typography variant="subtitle2">
        REPLACE - When customer/sales wanted to replace an item. e.g. 500 GB SSD
        to 1TB SSD. This will make the item available again
      </Typography>
      <Typography variant="subtitle2">
        VOID - Remove item in transaction. This will make the item available
        again
      </Typography>
    </Alert>
  );
};

export const TransactionDetailsAlertInfo = React.memo(AlertInfo);
