import React from 'react';
import {
  Box,
  Card,
  Divider,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { Listing } from 'src/types';
import { formatCurrency, formatDate } from 'src/utils';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LinkComponent, LoaderBar } from 'src/components';
import { useNavigate } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import { usePermissions } from 'src/hooks';

interface Props {
  isLoading?: boolean;
  listings: Listing[];
  onDeleteItem: (listingId: number) => void;
}

const useStyles = makeStyles((theme) => ({
  consumableAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  statusContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const Listings = ({ listings, isLoading, onDeleteItem }: Props) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const navigate = useNavigate();
  const { canDeleteListing } = usePermissions();

  return (
    <Box marginY={2}>
      <Card>
        <Divider />
        <PerfectScrollbar>
          <Box>
            <LoaderBar isLoading={isLoading ? isLoading : false} />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Qty</TableCell>
                  <TableCell>Identification</TableCell>
                  <TableCell>Supplier</TableCell>
                  <TableCell>Dealers Price</TableCell>
                  <TableCell>Price</TableCell>
                  <TableCell>Date Purchased (Supplier)</TableCell>
                  <TableCell>Date Encoded</TableCell>
                  {canDeleteListing ? <TableCell>Delete</TableCell> : null}
                </TableRow>
              </TableHead>
              <TableBody>
                {listings.map((item: Listing) => (
                  <TableRow hover key={item.id}>
                    <TableCell>{item?.consumable_qty ?? '--'}</TableCell>
                    <TableCell>
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/products-listings/${item.id}`)
                        }
                        href={`/app/products-listings/${item.id}`}
                        title={`${item?.serial_no ?? '--'}`}
                      />
                    </TableCell>
                    <TableCell>{item?.supplier_name ?? '--'}</TableCell>
                    <TableCell>
                      {`DP ${formatCurrency(item?.dealers_price) ?? '--'}`}
                    </TableCell>
                    <TableCell>
                      {`SRP ${formatCurrency(item?.retail_price) ?? '--'}`}
                    </TableCell>
                    <TableCell>{formatDate(item?.purchase_date)}</TableCell>
                    <TableCell>{formatDate(item?.created_at)}</TableCell>
                    {canDeleteListing ? (
                      <TableCell>
                        <IconButton onClick={() => onDeleteItem(item.id)}>
                          <DeleteIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                    ) : null}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </Box>
  );
};

export const ConsumableListingsProductTable = React.memo(Listings);
