import React, { useMemo } from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import { TextBoldWarranty, TextRegularWarranty } from './TextWarranty';
import { ViewWarrantyRow } from './ViewWarranty';
import { Customer } from 'src/types';
import { colors } from 'src/constants';

interface Props {
  customerInfo?: Customer;
}

const styles = StyleSheet.create({
  root: {
    minHeight: 30,
    marginTop: 4
  },
  note: {
    flex: 2,
    padding: 4
  },
  noteTxt: {
    textAlign: 'justify',
    fontSize: 5,
    color: colors.grey[800]
  },
  customerContainer: {
    flex: 1,
    padding: 4,
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  customerNameTxt: {
    fontSize: 6,
    color: colors.grey[800]
  },
  underline: {
    width: 100,
    height: 0.5,
    backgroundColor: colors.grey[900],
  }
});

export const RenderFooter = ({ customerInfo }: Props) => {
  const customerName = useMemo(() => {
    const firstName = customerInfo?.first_name ?? '';
    const lastName = customerInfo?.last_name ?? '';
    return `${firstName} ${lastName}`;
  }, [customerInfo]);

  return (
    <ViewWarrantyRow style={styles.root}>
      <View style={styles.note}>
        <TextRegularWarranty style={styles.noteTxt}>
          {`I, CUSTOMER OF PC WORTH, RECEIVED THE ABOVE ITEM(S) IN GOOD CONDITION. ALSO, I AGREE TO THE WARRANTY TERMS & CONDITIONS WRITTEN ON THE BACK OF THIS WARRANTY SLIP`}
        </TextRegularWarranty>
      </View>

      <View style={styles.customerContainer}>
        <TextRegularWarranty style={styles.customerNameTxt}>
          {customerName}
        </TextRegularWarranty>
        <View style={styles.underline} />
        <TextBoldWarranty>Customer Signature</TextBoldWarranty>
      </View>
    </ViewWarrantyRow>
  );
};
