import React from 'react';
import {
  Button,
  Card,
  CardContent,
  Chip,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { useNavigate } from 'react-router';
import { EcomOrderProduct } from 'src/types';
import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import { formatCurrency } from 'src/utils';
import { colors } from 'src/constants';

interface Props {
  className?: string;
  isOrderLoading?: boolean;
  disableQuoteBtn?: boolean;
  products?: EcomOrderProduct[];
  onCreateQuotation: () => void;
  onUpdateProduct?: (product: EcomOrderProduct[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  card: {
    marginTop: theme.spacing(2)
  },
  addProductInput: {
    marginTop: theme.spacing(2)
  },
  createQuotationBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2)
  }
}));

const component = ({
  products = [],
  // onUpdateProduct,
  isOrderLoading,
  disableQuoteBtn = false,
  onCreateQuotation
}: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const onProductPress = (id?: number) => {
    if (id) {
      navigate(`/app/products/${id}`);
    }
  };

  return (
    <div className={classes.root}>
      <Card className={classes.card}>
        <CardContent>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Quantity</TableCell>
                <TableCell>Product Info</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {products?.map((item: EcomOrderProduct, i: number) => (
                <AlternatingColorTableRow
                  hover
                  key={i}
                  style={{
                    backgroundColor: item?.unavailable
                      ? colors.error
                      : undefined
                  }}
                >
                  <TableCell>{item?.quantity || 1}</TableCell>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onProductPress(item?.product_id)}
                      href={`/app/products/${item?.product_id}`}
                      title={`${item?.product_name}`}
                    />
                    {item?.unavailable ? (
                      <Chip
                        label="Product Deleted"
                        style={{ height: '100%', marginLeft: 10 }}
                        size="small"
                        variant="outlined"
                        color="primary"
                      />
                    ) : null}
                  </TableCell>

                  <TableCell>
                    {item?.amount_fluctuation !== 'none' ? (
                      <Typography>
                        current unit price:{' '}
                        {formatCurrency(item?.current_unit_price)}
                      </Typography>
                    ) : null}
                    <Typography>
                      checkout unit price:{' '}
                      {formatCurrency(item?.checkout_unit_price)}
                    </Typography>
                  </TableCell>

                  <TableCell>
                    {item?.checkout_amount === item?.current_amount ? null : (
                      <Typography>
                        current total amount:{' '}
                        {formatCurrency(item?.current_amount)}
                      </Typography>
                    )}

                    <Typography>
                      checkout amount: {formatCurrency(item?.checkout_amount)}
                    </Typography>

                    {item?.amount_fluctuation === 'none' ? null : (
                      <Typography
                        style={{
                          color:
                            item?.amount_fluctuation === 'decrease'
                              ? colors.success
                              : colors.warning
                        }}
                      >
                        order amount has: {item?.amount_fluctuation}
                      </Typography>
                    )}

                    {item?.with_additional_price ? (
                      <Chip
                        size="small"
                        color="primary"
                        variant="outlined"
                        label="Plus Non Bundle Fee"
                      />
                    ) : null}
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>
          <div className={classes.createQuotationBtn}>
            <Button
              disabled={
                products?.length <= 0 || isOrderLoading || disableQuoteBtn
              }
              onClick={onCreateQuotation}
              color="primary"
              variant="outlined"
            >
              Create / Customize Quotation
            </Button>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export const ProductsOrderTable = React.memo(component);
