import React, { FC, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { AddKachiPointsFormDialog } from './AddKachiPointsFormDialog';
import { useNavigate } from 'react-router-dom';
import { ForfeitKachiPoints } from './ForfeitKachiPointsFormDialog';
import { KachiList } from 'src/types/kachi-points';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  button: {
    marginLeft: '10px',
    minWidth: '120px'
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  image: {
    width: '150px',
    maxHeight: '150px'
  }
}));

type Props = {
  kachiList?: KachiList;
};

export const KachiPointsList: FC<Props> = ({ kachiList }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [isAddFormShow, setIsAddFormShow] = useState<boolean>(false);
  const [isForfeitFormShow, setIsForfeitFormShow] = useState<boolean>(false);

  const navigateToHistory = () => {
    const stateHistory = {
      userFullname: kachiList?.full_name,
      totalPoints: kachiList?.total_points
    };
    navigate(`/app/kachi-points-history/${kachiList?.user_id}`, {
      state: stateHistory
    });
  };

  return (
    <Card style={{ padding: '5px', borderRadius: '5px', marginBottom: '15px' }}>
      <CardContent>
        <Grid container spacing={1}>
          <Grid item md={10}>
            <Box display={'flex'} flexDirection={'column'} padding={'5px'}>
              <Typography variant="h5">{`User: ${kachiList?.full_name}`}</Typography>
              <Typography>{`Points total: ${kachiList?.total_points}`}</Typography>
              <Typography>{`Last Transaction: ${kachiList?.latest_points_activity}`}</Typography>
              <Typography>{`Description: ${kachiList?.description}`}</Typography>
            </Box>
          </Grid>

          <Grid item md={2} className={classes.flexCenter}>
            <Box className={classes.flexCenter}>
              <Button
                onClick={navigateToHistory}
                color="primary"
                variant="contained"
              >
                {'History'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
      <AddKachiPointsFormDialog
        isOpen={isAddFormShow}
        userFullName={kachiList?.full_name}
        onClose={() => setIsAddFormShow(false)}
        userId={kachiList?.user_id}
      />
      <ForfeitKachiPoints
        isOpen={isForfeitFormShow}
        userFullName={kachiList?.full_name}
        onClose={() => setIsForfeitFormShow(false)}
        userId={kachiList?.user_id}
      />
    </Card>
  );
};
