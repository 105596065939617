import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, makeStyles } from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import Results from './Results';
import Toolbar from './Toolbar';
import { Customer, CustomInputEvent } from 'src/types';
import { Pagination } from '@material-ui/lab';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const { actions: getCustomerActions } = slices.customerService;

const pageLimit = 100;

const CustomerListView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [customers, setCustomers] = useState([{}]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState<number>();
  const [total, setTotal] = useState<number>(0);
  const [input, setInput] = useState<string>('');

  const onAddCustomerPress = () => {
    navigate('/app/customers/add');
  };

  const getCustomers = useCallback(
    async (page_number = 1) => {
      setIsLoading(true);

      const payload = {
        keyword: input,
        page_size: pageLimit,
        page_number
      };
      const response = unwrapResult(
        await dispatch(getCustomerActions.getCustomerServiceListThunk(payload))
      );

      if (response?.success && response?.originalData?.customers) {
        setIsLoading(false);
        setCustomers(response?.originalData?.customers);
        setLastPage(response.originalData?.last_page);
        setTotal(response.originalData.current_page);
      }
    },

    [dispatch, input]
  );

  const onSearchCustomer = () => {
    getCustomers();
  };

  const onToolbarInputChange = (e: CustomInputEvent) => {
    const { value } = e.target;
    setInput(value);
  };

  const onCustomerPress = (item: Customer) => {
    navigate(`/app/customers/${item.id}`, { state: item });
  };

  const handlePageChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
    getCustomers(value);
  };

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Toolbar
          input={input}
          onToolbarInputChange={onToolbarInputChange}
          onClick={onAddCustomerPress}
          onSearchCustomer={onSearchCustomer}
        />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <Results onCustomerPress={onCustomerPress} customers={customers} />
        </Box>
      </Container>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Pagination
          disabled={total <= 100}
          count={lastPage}
          page={page}
          onChange={handlePageChange}
        />
      </Box>
    </Page>
  );
};

export default CustomerListView;
