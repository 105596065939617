import React, { useCallback, useEffect, useMemo, useState } from 'react';

import SaveIcon from '@material-ui/icons/Save';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  makeStyles,
  Typography,
  TextField,
  CardMedia,
  CardActions
} from '@material-ui/core';
import {
  CheckBoxLabel,
  DeletableImg,
  DragAndDropImgUpload,
  LoaderBar,
  Page
} from 'src/components';
import { cloneDeep } from 'lodash';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Banner } from 'src/types/ecomm/banner';
import {
  convertImageFileToBase64,
  forEachPromise,
  timeStampNow
} from 'src/utils';
import { usePermissions, useSnackBar } from 'src/hooks';
import { colors } from 'src/constants';

const { actions: ecomBannerActions } = slices.ecommBanner;

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  previewDiv: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  publishBtn: {
    padding: 3,
    marginLeft: 4,
    backgroundColor: colors.common.white,
    cursor: 'pointer'
  },
  saveBtn: {
    marginTop: theme.spacing(2)
  }
}));

const component = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const { canViewEcomBanners, canEditEcomBanners } = usePermissions();

  const [eBanners, setEbanners] = useState<Banner[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const hasBannersToBeUploaded = useMemo(() => {
    const allBannersWithoutId = eBanners?.filter((x) => !x?.id);
    return allBannersWithoutId?.length > 0;
  }, [eBanners]);

  const onPhotosChangeInternal = async (imges: File[]) => {
    if (!canEditEcomBanners) {
      snackBar.show({ severity: 'error', message: 'Permission Denied' });
      return;
    }
    if (imges && imges?.length > 0) {
      // eslint-disable-next-line no-unused-vars
      var imgsArr: any[] = await Promise.all(
        imges?.map(
          async (item): Promise<any> => {
            const newImges = {
              id: 0,
              img_url: await convertImageFileToBase64(item)
            };
            return newImges;
          }
        )
      );
      const clonedDataImgs = cloneDeep(eBanners) || [];
      const mergedImgs = [...imgsArr, ...clonedDataImgs];
      setEbanners(mergedImgs);
    }
  };

  const onDeleteImage = async (index: number) => {
    if (!canEditEcomBanners) {
      snackBar.show({ severity: 'error', message: 'Permission Denied' });
      return;
    }
    const clonedData = cloneDeep(eBanners);
    const itemImg = clonedData[index];

    if (itemImg?.id) {
      setIsLoading(true);
      const resp = unwrapResult(
        await dispatch(
          ecomBannerActions.deleteEcomBannerThunk(itemImg?.id)
        ).finally(() => setIsLoading(false))
      );
      if (resp?.success === true) {
        snackBar.show({
          severity: 'success',
          message: 'Successfully deleted banner'
        });
        clonedData?.splice(index, 1);
      } else {
        snackBar.show({ severity: 'error', message: 'Something went wrong' });
      }
    } else {
      clonedData?.splice(index, 1);
    }
    setEbanners(clonedData);
  };

  const getBannerImages = useCallback(async () => {
    if (!canViewEcomBanners) {
      return;
    }
    setIsLoading(true);
    const resp = unwrapResult(
      await dispatch(ecomBannerActions.getEcommBannersThunk()).finally(() =>
        setIsLoading(false)
      )
    );
    if (resp?.originalData?.data) {
      setEbanners(resp?.originalData?.data);
    }
  }, [canViewEcomBanners, dispatch]);

  const onUploadBanner = async () => {
    if (!canEditEcomBanners) {
      snackBar.show({ severity: 'error', message: 'Permission Denied' });
      return;
    }

    const clonedImgsToBeUploaded = cloneDeep(eBanners);
    const filteredImgsToBeUploaded = clonedImgsToBeUploaded?.filter(
      (x) => !x?.id
    );
    filteredImgsToBeUploaded.map((toBeUploaded) => {
      toBeUploaded.description =
        toBeUploaded?.description || `${timeStampNow()}`; // If empty generate timeStamp
    });
    setIsLoading(true);
    forEachPromise(filteredImgsToBeUploaded, (item) => {
      return new Promise((resolve) => {
        process.nextTick(() => {
          dispatch(ecomBannerActions.uploadEcommBannersThunk(item))
            .then(() => resolve(true))
            .catch(() => resolve(true));
        });
      });
    }).finally(() => {
      setIsLoading(false);
      snackBar.show({
        severity: 'success',
        message: 'Successfully uploaded item'
      });
      getBannerImages();
    });
  };

  const onPublishBanner = async (bannerData?: Banner, published?: boolean) => {
    if (!canEditEcomBanners) {
      snackBar.show({ severity: 'error', message: 'Permission Denied' });
      return;
    }
    if (!bannerData?.id) {
      return;
    }
    // set on state
    const index = eBanners?.findIndex((x) => x?.id === bannerData?.id);
    setEbanners((prev) =>
      prev?.map((item, i) => {
        if (index !== i) {
          return item;
        }
        return { ...item, published: published ? 1 : 0 };
      })
    );
    // set on API
    setIsLoading(true);
    unwrapResult(
      await dispatch(
        ecomBannerActions.updateBannerThunk({
          ...bannerData,
          published: published,
          id: bannerData?.id
        })
      ).finally(() => setIsLoading(false))
    );
    getBannerImages();
  };

  const onUpdateBanner = async (bannerData?: Banner) => {
    if (!bannerData?.id) {
      return;
    }
    setIsLoading(true);
    unwrapResult(
      await dispatch(
        ecomBannerActions.updateBannerThunk({
          ...bannerData,
          published: bannerData?.published ? true : false
        })
      ).finally(() => setIsLoading(false))
    );
    getBannerImages();
  };

  const onEditTextField = useCallback(
    (index: number, newValue: string, field: 'description' | 'link') => {
      setEbanners((prev) =>
        prev?.map((item, i) => {
          if (index !== i) {
            return item;
          }
          return { ...item, [field]: newValue };
        })
      );
    },
    []
  );

  useEffect(() => {
    getBannerImages();
  }, [getBannerImages]);

  return (
    <Page className={classes.root} title="Ecomm Banner">
      <Container maxWidth={false} style={{ marginBottom: 50 }}>
        {canEditEcomBanners ? (
          <Box mt={2}>
            <Card>
              <CardHeader title="Ecommerce Banners" />
              <Divider />
              <CardContent>
                <Typography className={classes.subHeader} variant="h6">
                  Upload Image(s)
                </Typography>
                <DragAndDropImgUpload
                  title="Drag or select photos"
                  onImageSelected={onPhotosChangeInternal}
                />
              </CardContent>
              <Divider />
            </Card>
          </Box>
        ) : null}

        {hasBannersToBeUploaded && canEditEcomBanners ? (
          <Box mt={2}>
            <Card>
              <LoaderBar isLoading={isLoading} />
              <CardHeader title="To Be Uploaded Banners" />
              <Divider />
              <CardContent>
                <Grid container spacing={2} className={classes.previewDiv}>
                  {eBanners?.map((banner, i) =>
                    !banner?.id ? (
                      <Grid key={`${banner?.id}${i}`} item>
                        <Card elevation={5}>
                          <CardContent>
                            <DeletableImg
                              index={i}
                              hideImageBorder
                              imgSrc={banner?.img_url}
                              onDelete={onDeleteImage}
                            />
                            {!banner?.id ? (
                              <Typography variant="caption" color="error">
                                This image is not yet uploaded
                              </Typography>
                            ) : null}

                            <TextField
                              value={banner?.description}
                              onChange={(e) =>
                                onEditTextField(
                                  i,
                                  e?.target?.value,
                                  'description'
                                )
                              }
                              style={{ marginTop: 10 }}
                              size="small"
                              variant="outlined"
                              fullWidth
                              label="Description"
                            />
                            <TextField
                              onChange={(e) =>
                                onEditTextField(i, e?.target?.value, 'link')
                              }
                              style={{ marginTop: 10 }}
                              size="small"
                              variant="outlined"
                              fullWidth
                              label="href/link"
                            />
                          </CardContent>
                        </Card>
                      </Grid>
                    ) : null
                  )}
                </Grid>

                <Button
                  fullWidth
                  startIcon={<SaveIcon />}
                  onClick={onUploadBanner}
                  color="primary"
                  className={classes.saveBtn}
                  variant="contained"
                >
                  Upload Banners
                </Button>
              </CardContent>
            </Card>
          </Box>
        ) : null}
        {canViewEcomBanners ? (
          <Box mt={2}>
            <Card>
              <LoaderBar isLoading={isLoading} />
              <CardHeader title="Uploaded Banners" />
              <Divider />
              <CardContent>
                <Grid container spacing={2} className={classes.previewDiv}>
                  {eBanners?.map((banner, i) =>
                    banner?.id ? (
                      <Grid key={`${banner?.id}${i}`} item>
                        <Card elevation={5}>
                          <CardMedia>
                            <DeletableImg
                              index={i}
                              hideImageBorder
                              imgSrc={banner?.img_url}
                              onDelete={onDeleteImage}
                            />
                          </CardMedia>
                          <CardContent>
                            <TextField
                              disabled={!canEditEcomBanners}
                              value={banner?.description || ''}
                              style={{ marginTop: 10 }}
                              size="small"
                              variant="outlined"
                              fullWidth
                              label="Label"
                              onChange={(e) =>
                                onEditTextField(
                                  i,
                                  e?.target?.value,
                                  'description'
                                )
                              }
                            />
                            <TextField
                              disabled={!canEditEcomBanners}
                              style={{ marginTop: 10 }}
                              value={banner?.link || ''}
                              size="small"
                              variant="outlined"
                              fullWidth
                              label="href/link"
                              onChange={(e) =>
                                onEditTextField(i, e?.target?.value, 'link')
                              }
                            />
                          </CardContent>
                          <Divider />
                          {canEditEcomBanners ? (
                            <CardActions
                              style={{ justifyContent: 'space-between' }}
                            >
                              <CheckBoxLabel
                                label="Published"
                                checked={banner?.published ? true : false}
                                onChange={(v) => onPublishBanner(banner, v)}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => onUpdateBanner(banner)}
                              >
                                Update
                              </Button>
                            </CardActions>
                          ) : null}
                        </Card>
                      </Grid>
                    ) : null
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>
        ) : null}
      </Container>
    </Page>
  );
};

export const EcommBannerView = React.memo(component);
