import React, { FC, memo } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import CachedIcon from '@material-ui/icons/Cached';

interface Props {
  title?: string;
  onHandleRefresh: () => void;
}

const component: FC<Props> = ({
  title = 'Search not found',
  onHandleRefresh
}) => {
  return (
    <Box
      sx={{
        height: '80vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      <img
        src={'/static/images/kachi/kachi-crying.png'}
        alt="crying-kachi"
        width={200}
        height={200}
      />
      <Typography
        variant="h1"
        style={{ fontSize: '30px', margin: '1rem', textAlign: 'center' }}
      >
        {title}
      </Typography>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        onClick={onHandleRefresh}
        endIcon={<CachedIcon />}
      >
        Reload page
      </Button>
    </Box>
  );
};

export const SearchResultNotFound = memo(component);
