import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { formatCurrency, marginColorViaGross } from 'src/utils';
import { colors } from 'src/constants';
import { slices, useAppSelector } from 'src/redux';
import { LoaderBar } from 'src/components';

const { selectors: salesSelectors } = slices.sales;

interface Props {
  className?: string;
  isLoading?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {},
  cardContent: {
    flexDirection: 'row'
  },
  totalContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  gross: {
    color: colors.success
  }
}));

const SalesToolbar = ({ className, isLoading = false, ...rest }: Props) => {
  const classes = useStyles();
  const salesComputation = useAppSelector(
    salesSelectors.selectSalesResponseComputation
  );

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <LoaderBar isLoading={isLoading} />
        <Card>
          <CardContent>
            <Grid container spacing={8} alignItems="center" justify="center">
              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Retail Price</Typography>
                  <Typography variant="h4" style={{ color: colors.blue[600] }}>
                    {formatCurrency(salesComputation?.total_retail_price)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Gross Price</Typography>
                  <Typography variant="h4" style={{ color: colors.green[600] }}>
                    {formatCurrency(salesComputation?.total_gross)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Dealers Price</Typography>
                  <Typography
                    variant="h4"
                    style={{ color: colors.orange[600] }}
                  >
                    {formatCurrency(salesComputation?.total_dealers_price)}
                  </Typography>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={8} alignItems="center" justify="center">
              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Customer No.</Typography>
                  <Typography variant="h4">
                    {salesComputation?.customer}
                  </Typography>
                </div>
              </Grid>
              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Balance</Typography>
                  <Typography variant="h4" style={{ color: colors.red[600] }}>
                    {formatCurrency(salesComputation?.total_balance)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Spend Kachi Points</Typography>
                  <Typography
                    variant="h4"
                    style={{ color: colors.yellow[700] }}
                  >
                    {formatCurrency(salesComputation?.total_spend_kachi_points)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Paid</Typography>
                  <Typography
                    variant="h4"
                    style={{ color: colors.purple[600] }}
                  >
                    {formatCurrency(salesComputation?.total_paid)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Margin Percentage</Typography>
                  <Typography
                    variant="h4"
                    style={{
                      color: marginColorViaGross(
                        salesComputation?.margin_percentage
                      )
                    }}
                  >
                    {salesComputation?.margin_percentage || '0%'}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default SalesToolbar;
