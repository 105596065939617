import { ConsumableUnitEnum } from 'src/enums';
import { ConsumableUnit } from 'src/types';

export const consumableUnitOptions: ConsumableUnit[] = [
  {
    id: ConsumableUnitEnum.Piece,
    value: ConsumableUnitEnum.Piece,
    name: 'Piece'
  },
  {
    id: ConsumableUnitEnum.Meter,
    value: ConsumableUnitEnum.Meter,
    name: 'Meter'
  },
];
