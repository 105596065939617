import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

const useStyles = makeStyles((theme) => ({
  printButton: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  }
}));

interface Props {
  isLoading?: boolean;
  onPrintPress: () => void;
}

const Header = ({ isLoading, onPrintPress }: Props) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Typography color="textPrimary" variant="h3">
        Transaction Details
      </Typography>
      <div className={classes.printButton}>
        <Button
          disabled={isLoading}
          startIcon={<PrintIcon />}
          onClick={onPrintPress}
          color="primary"
          variant="contained"
        >
          Print Transaction Details
        </Button>
      </div>
    </Box>
  );
};

export const TransactionDetailsHeader = React.memo(Header);
