import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectQuotationState = (state: RootState) => state.quotation;

const selectQuotationCategories = createSelector(
  selectQuotationState,
  (state) => state.quotationCategories
);

const selectTemplateList = createSelector(
  selectQuotationState,
  (state) => state.templateList
);

export const selectors = {
  selectQuotationCategories,
  selectTemplateList
};
