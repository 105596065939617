import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import React, { FC, memo, useState } from 'react';

interface Props {
  filterOptions: any[];
  onHandleFilterSearhBy: (filter?: string) => void;
}

const component: FC<Props> = ({ filterOptions, onHandleFilterSearhBy }) => {
  const [filterByValue, setFilterByValue] = useState<string>(
    filterOptions?.length > 0 ? filterOptions[0] : undefined
  );

  const onFilterChange = (filter: string) => {
    onHandleFilterSearhBy(filter);
    setFilterByValue(filter);
  };

  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">Search Filter by</FormLabel>
      <RadioGroup
        row
        aria-label="search filter"
        name="filter by"
        value={filterByValue}
        onChange={(event) => onFilterChange(event.target.value)}
      >
        {filterOptions?.map((options, index) => (
          <FormControlLabel
            key={index}
            value={options}
            control={<Radio />}
            label={`${options}`}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export const SearchKeywordFilterGroup = memo(component);
