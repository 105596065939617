import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  // Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  makeStyles
} from '@material-ui/core';
import * as yup from 'yup';
import { Formik, useFormik } from 'formik';
import { AddressDetailData } from 'src/types/pcw-user-address';
import CurrentAddressCard from './components/CurrentAddress';
import GoogleMapFrame from './components/GoogleMapIFrame';
import { usePermissions } from 'src/hooks';

const useStyles = makeStyles(() => ({
  root: {},
  uploadImageButton: {
    marginRight: 20
  }
}));

interface Props {
  className?: string;
  addressDetail: AddressDetailData;
}

const ProfileAddressDetails = ({ className, addressDetail }: Props) => {
  const classes = useStyles();
  const temporaryDisabled = true;
  const { canViewUserEcommAddressDetail } = usePermissions();

  const [address, setAddressDetail] = useState<AddressDetailData>(
    addressDetail || {}
  );
  const [addressType, setAddressType] = useState<any>(
    address?.address_type || 'Address Type'
  );
  const [isLoading, setIsLoading] = useState<boolean>();

  const handleFormSubmit = (values: any) => {
    console.log('pcw values', values);
  };

  const initialValues = {
    contact_person: address?.contact_person,
    contact_number: address?.contact_number,
    street_details: address?.street_details,
    delivery_address: address?.delivery_address
  };

  useEffect(() => {
    setIsLoading(true);
    if (addressDetail) {
      setAddressDetail(addressDetail);
      setIsLoading(false);
      // getUserAddress(addressDetail);
    }
  }, [addressDetail]);

  const {
    values,
    // errors,
    // touched,
    handleBlur,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema
  });

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      {canViewUserEcommAddressDetail ? (
        <Card>
          <CardHeader title="User Address Details" />
          <Divider />
          <CurrentAddressCard addressDetail={address} />
          <CardContent>
            <Formik
              initialValues={initialValues}
              validationSchema={{}}
              onSubmit={() => {}}
            >
              <Grid container spacing={3}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    // helperText="Please specify the first name"
                    label="Contact Person"
                    name="contact_person"
                    onChange={handleChange}
                    required
                    value={values.contact_person}
                    onBlur={handleBlur}
                    variant="outlined"
                    error={undefined}
                    helperText={''}
                    disabled={temporaryDisabled}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    // helperText="Please specify the first name"
                    label="Contact Number"
                    name="contact_number"
                    onChange={handleChange}
                    required
                    value={values?.contact_number}
                    onBlur={handleBlur}
                    variant="outlined"
                    error={undefined}
                    helperText={''}
                    disabled={temporaryDisabled}
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <InputLabel htmlFor="address-type-select">
                    Address Type
                  </InputLabel>
                  <Select
                    value={addressType}
                    onChange={(e: any) => setAddressType(e.target.value)}
                    label="Address Type"
                    inputProps={{
                      id: 'address-type-select'
                    }}
                    variant="outlined"
                    style={{ width: 300 }}
                    disabled={temporaryDisabled}
                  >
                    <MenuItem value="Home">Home</MenuItem>
                    <MenuItem value="Work/Office">Work/Office</MenuItem>
                  </Select>
                </Grid>
                <Grid item md={12}>
                  {isLoading ? (
                    <LinearProgress />
                  ) : (
                    <GoogleMapFrame
                      long={address?.longitude}
                      lat={address?.latitude}
                    />
                  )}
                </Grid>
                {/* TODO: add this when google map implemented */}
                {/* <Grid item xs={12}>
                <Button
                  color="secondary"
                  variant="outlined"
                  type="submit"
                  fullWidth
                >
                  Update Location
                </Button>
              </Grid> */}

                {/* TODO: add this when google map implemented */}
                {/* <Grid item xs={12}>
                <Button color="primary" variant="contained" type="submit">
                  Save Address
                </Button>
              </Grid> */}
              </Grid>
            </Formik>
          </CardContent>
        </Card>
      ) : (
        'You are not allowed to view this page'
      )}
    </form>
  );
};

const formSchema = yup.object().shape({
  contact_person: yup
    .string()
    .nullable()
    .required('contact person is required'),
  contact_number: yup.string().required('contact number is required'),
  street_details: yup.string().required('street details is required'),
  delivery_address: yup.string().required('street details is required'),
  mobile_no: yup
    .string()
    .nullable()
    // .required('mobile number is required')
    .test(
      'mobile_no',
      'Invalid phone number, contact number must be 11 digits',
      (value) => {
        const phoneRegex = /^\d{11}$/;
        if (!value) {
          return true;
        }
        return phoneRegex.test(value);
      }
    )
});

export default ProfileAddressDetails;
