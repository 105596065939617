import React, { useState } from 'react';
import {
  Button,
  CardContent,
  Divider,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import {
  CustomKeyboardEvent,
  ProductCustomSpecification,
  ProductCustomSpecifications
} from 'src/types';
import DeleteIcon from '@material-ui/icons/Delete';

import { colors } from 'src/constants';
import { cloneDeep } from 'lodash';
import { useSnackBar } from 'src/hooks';

interface Props {
  customSpecs?: ProductCustomSpecifications;
  onChangeCustomSpecs?: (newSpecs: ProductCustomSpecifications) => void;
  hasDivider?: boolean;
}

const useStyles = makeStyles((theme) => ({
  fieldsContainer: {
    flexDirection: 'row',
    display: 'flex'
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  valueInput: {
    marginLeft: theme.spacing(1)
  },
  addBtn: {
    marginLeft: theme.spacing(2),
    height: 50,
    width: 200
  },
  customSpecsContainer: {
    marginTop: theme.spacing(3)
  },
  customSpecsValue: {
    marginBottom: theme.spacing(1),
    '&:hover': {
      opacity: 0.7
    }
  },
  btnIcon: {
    padding: 0,
    zIndex: 99,
    marginRight: theme.spacing(1),
    backgroundColor: colors.common.white
  }
}));

const component = ({
  customSpecs = [],
  onChangeCustomSpecs,
  hasDivider = true
}: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();

  const [titleInput, setTitleInput] = useState('');
  const [valueInput, setValueInput] = useState('');

  const onChangeCustomSpecsInternal = (
    newSpecsArr: ProductCustomSpecifications
  ) => {
    if (onChangeCustomSpecs) {
      onChangeCustomSpecs(newSpecsArr || []);
      setTitleInput('');
      setValueInput('');
    }
  };

  const onAddNewRow = () => {
    if (!titleInput || !valueInput) {
      snackBar.show({
        severity: 'error',
        message: 'title and value of custom spec is required'
      });
      return;
    }
    let newRow: ProductCustomSpecification = {
      title: titleInput,
      value: valueInput
    };
    let currentSpecs = cloneDeep(customSpecs) || [];

    // If title already exist. Lets just edit it. else push new row.
    const rowExistIndex = currentSpecs?.findIndex(
      (x) => x?.title === titleInput
    );
    if (rowExistIndex > -1) {
      currentSpecs[rowExistIndex] = newRow;
    } else {
      currentSpecs.push(newRow);
    }
    onChangeCustomSpecsInternal(currentSpecs);
  };

  const onDeleteRow = (index: number) => {
    let currentSpecs = cloneDeep(customSpecs) || [];
    currentSpecs.splice(index, 1);
    onChangeCustomSpecsInternal(currentSpecs || []);
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onAddNewRow();
    }
  };

  return (
    <div>
      <CardContent>
        <Typography className={classes.subHeader} variant="h6">
          Product Custom Specification
        </Typography>
        <div className={classes.fieldsContainer}>
          <TextField
            required
            fullWidth
            label="Title"
            name="title"
            variant="outlined"
            value={titleInput || ''}
            onChange={(e) => setTitleInput(e.target.value)}
            inputProps={{ maxLength: 50 }}
          />
          <TextField
            required
            fullWidth
            label="Value"
            name="value"
            variant="outlined"
            value={valueInput || ''}
            onChange={(e) => setValueInput(e.target.value)}
            onKeyPress={onKeyPress}
            className={classes.valueInput}
          />
          <Button
            className={classes.addBtn}
            onClick={onAddNewRow}
            color="primary"
            variant="contained"
          >
            Add
          </Button>
        </div>

        <div className={classes.customSpecsContainer}>
          {customSpecs?.map((x, i) => (
            <div key={`${x?.title}${i}`} className={classes.customSpecsValue}>
              <IconButton
                className={classes.btnIcon}
                onClick={() => onDeleteRow(i)}
              >
                <DeleteIcon fontSize="medium" color="secondary" />
              </IconButton>
              <Typography variant="h5" display="inline">
                {x?.title}:
              </Typography>
              <Typography
                variant="body1"
                display="inline"
                className={classes.valueInput}
              >
                {x?.value}
              </Typography>
            </div>
          ))}
        </div>
      </CardContent>
      {hasDivider ? <Divider /> : null}
    </div>
  );
};

export const AddProductInfoCustomSpecs = React.memo(component);
