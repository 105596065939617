import React, { FC, memo, useState } from 'react';
import {
  Box,
  Chip,
  colors,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles,
  TextField,
  Tooltip,
  Button
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { useInventoryAudit } from 'src/hooks/inventory-audit';
import { Alert } from '@material-ui/lab';
import { usePermissions } from 'src/hooks';
import { DecisionDialog } from 'src/components';

const useStyles = makeStyles({
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '8px'
  },
  tableHeader: {
    border: '1px solid #ddd',
    padding: '1rem',
    backgroundColor: '#f4f4f4',
    fontWeight: 'bold'
  },
  statusIcon: {
    marginRight: '8px'
  }
});

interface ScanSnProps {
  prodName?: string;
  scannedSn?: string[];
}

export const ScanSerialNoTable: FC<ScanSnProps> = ({
  prodName,
  scannedSn = []
}) => {
  return (
    <Box>
      <Box>
        <Typography>{prodName}</Typography>
      </Box>
      <Box sx={{ border: '0.5px solid #f4f4f4', p: '1rem' }}>
        {scannedSn?.map((sn, index) => (
          <Chip
            key={index}
            style={{
              padding: '1rem',
              border: '1px solid gray',
              background: 'white',
              margin: 3
            }}
            label={sn}
          />
        ))}
      </Box>
    </Box>
  );
};

interface Props {}

const component: FC<Props> = () => {
  const {
    inventoryAuditProductListing,
    hasAuditCountMismatch,
    isLoading,
    handleNonSnCountAudit,
    submitInventoryAuditLogs
  } = useInventoryAudit();
  const classes = useStyles();
  const {
    canCreateInventoryLog,
    canSeeActualAvailableProductCount
  } = usePermissions();

  const [isAuditSubmit, setIsAuditSubmit] = useState<boolean>(false);

  const handleAuditCountChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    product_id?: number
  ) => {
    const newValue = event.target.value;
    // Validate input to ensure it's a positive number
    if (/^\d*\.?\d*$/.test(newValue)) {
      handleNonSnCountAudit(product_id, parseInt(newValue));
    }
  };

  const handleSubmitAudit = () => {
    setIsAuditSubmit(true);
  };

  return (
    <Box>
      {inventoryAuditProductListing &&
      inventoryAuditProductListing?.length > 0 ? (
        <>
          <Box
            sx={{
              mt: '1rem',
              display: 'flex',
              alignItems: 'flex-end',
              flexDirection: 'column'
            }}
          >
            {hasAuditCountMismatch && (
              <Alert severity="error" style={{ width: '100%' }}>
                {'There are some missing items...'}
              </Alert>
            )}
            {canCreateInventoryLog && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                style={{ width: '20%', marginTop: '1rem' }}
                onClick={handleSubmitAudit}
                disabled={isLoading}
              >
                Submit Audit
              </Button>
            )}
          </Box>
          <PerfectScrollbar style={{ marginTop: '1rem' }}>
            <TableContainer
              component={Paper}
              style={{
                minHeight: '50vh',
                maxHeight: '60vh',
                overflow: 'auto',
                marginBottom: '3rem'
              }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                className={classes.table}
              >
                <TableHead>
                  <TableRow>
                    <TableCell
                      className={classes.tableHeader}
                      style={{ width: '5%' }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      className={classes.tableHeader}
                      style={{ width: '65%' }}
                    >
                      Product Name
                    </TableCell>
                    <TableCell
                      className={classes.tableHeader}
                      style={{ width: '15%' }}
                    >
                      Audited Count
                    </TableCell>
                    {canSeeActualAvailableProductCount && (
                      <TableCell
                        className={classes.tableHeader}
                        style={{ width: '15%' }}
                      >
                        Available Items Count
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inventoryAuditProductListing?.map((product, index) => (
                    <TableRow key={product?.id || index}>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: 'center' }}
                      >
                        {product?.audited_count !==
                        product?.available_listing_count ? (
                          <WarningIcon
                            className={classes.statusIcon}
                            style={{ color: colors.yellow[800] }}
                          />
                        ) : (
                          <CheckCircleIcon
                            className={classes.statusIcon}
                            style={{ color: colors.green[800] }}
                          />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {product?.is_serialized ? (
                          <Tooltip
                            title={`Kindly scan the serial no for ${product?.product_name} to update the audit count`}
                          >
                            <ScanSerialNoTable
                              prodName={product?.product_name}
                              scannedSn={product?.scanned_serial_nos}
                            />
                          </Tooltip>
                        ) : (
                          product?.product_name
                        )}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: 'center' }}
                      >
                        {!product?.is_serialized ? (
                          <TextField
                            variant="outlined"
                            id="audited-count"
                            type="number"
                            defaultValue={product?.audited_count || 0}
                            value={product?.audited_count}
                            onChange={(e: any) =>
                              handleAuditCountChange(e, product?.id)
                            }
                            InputLabelProps={{
                              shrink: true
                            }}
                            inputProps={{
                              step: 1,
                              min: 0,
                              max: product?.available_listing_count
                            }}
                          />
                        ) : (
                          JSON.stringify(product?.audited_count)
                        )}
                      </TableCell>
                      {canSeeActualAvailableProductCount && (
                        <TableCell
                          className={classes.tableCell}
                          style={{ textAlign: 'center' }}
                        >
                          {product?.available_listing_count}
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </PerfectScrollbar>
        </>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
            width: '100%',
            padding: '1rem'
          }}
        >
          <Typography
            variant="h5"
            style={{ color: 'gray', textAlign: 'center' }}
          >
            {`Set the branch and category filters to narrow down the products you want to audit.
             Then, click 'Select Products' to open a modal and choose items based on these filters.`}
          </Typography>
        </Box>
      )}
      <DecisionDialog
        isOpen={isAuditSubmit}
        title="Create Audit Logs"
        subTitle={
          hasAuditCountMismatch
            ? 'There is still a discrepancy in audited listings, are you sure you want to submit this?'
            : `You are about to submit audit logs confirm action`
        }
        onHandleConfirmAction={submitInventoryAuditLogs}
        onHandleClose={() => setIsAuditSubmit(false)}
      />
    </Box>
  );
};

export const InventoryAuditListing = memo(component);
