import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';

import { FixMeLaterApi } from 'src/types';
import { Supplier } from 'src/types/supplier';

interface Props {
  className?: string;
  suppliers: Supplier[];
  onSupplierPress: (data: Supplier) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Suppliers = ({
  className,
  suppliers,
  onSupplierPress,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Supplier Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {suppliers.map((item: FixMeLaterApi) => (
                <TableRow
                  onClick={() => onSupplierPress(item)}
                  hover
                  key={item.id}
                >
                  <TableCell>{item.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default Suppliers;
