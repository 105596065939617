import Api from 'src/services/ApiService';
import {
  KachiHistoryRequest,
  KachiPointsAddRequest,
  KachiPointsForfeitRequest,
  KachiPointsUserListRequest
} from 'src/types/kachi-points';

const getKachiPointsListApi = (data?: KachiPointsUserListRequest): any => {
  return Api.get(`/ecomm/internal/user/kachi_points/get`, data);
};

const getKachiPointsUserHistoryApi = (data?: KachiHistoryRequest): any => {
  return Api.get(
    `/ecomm/internal/user/kachi_points/get/${data?.user_id}`,
    data
  );
};

const addKachiPointsApi = (data?: KachiPointsAddRequest): any => {
  return Api.post(
    `/ecomm/internal/user/kachi_points/add/${data?.user_id}`,
    data
  );
};

const deleteKachiPointsUserApi = (data?: KachiPointsForfeitRequest): any => {
  return Api.delete(
    `/ecomm/internal/user/kachi_points/forfeit/${data?.kachi_points_id}`,
    data
  );
};

export const KachiPointsApi = {
  getKachiPointsListApi,
  getKachiPointsUserHistoryApi,
  addKachiPointsApi,
  deleteKachiPointsUserApi
};
