import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  CommonRmasResponse,
  CreateRmaRequest,
  CreateRmasResponse,
  DeleteRmaResponse,
  GetRmasViaTransactionNoResponse,
  GetRmaViaIdResponse,
  RmasThunkRequest,
  UpdateRmaResponse,
  UpdateRmaThunkRequest
} from 'src/types/rma';

export const getRmasThunk = createAsyncThunk<
  CommonAxiosResponse<CommonRmasResponse>,
  RmasThunkRequest,
  { state: RootState }
>('rma/getRmas', async (data) => {
  const response = await Api.get('/rma', data);
  if (!response.success) {
    throw new Error('Failed at getRmasThunk.');
  }
  return response;
});

export const getRmaViaIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetRmaViaIdResponse>,
  number,
  { state: RootState }
>('rma/getRmaViaIdThunk', async (id) => {
  const response = await Api.get(`/rma/${id}`);
  if (!response.success) {
    throw new Error('Failed getRmaViaIdThunk');
  }
  return response;
});

export const getRmasViaTransactionNoThunk = createAsyncThunk<
  CommonAxiosResponse<GetRmasViaTransactionNoResponse>,
  string | undefined,
  { state: RootState }
>('rma/getRmasViaTransactionNoThunk', async (transaction_no) => {
  const response = await Api.get(`/rma/getRmasViaTransactionNo`, {
    transaction_no
  });
  return response;
});

export const createRmaThunk = createAsyncThunk<
  CommonAxiosResponse<CreateRmasResponse>,
  CreateRmaRequest,
  { state: RootState }
>('rma/createRma', async (data) => {
  const response = await Api.post(`/rma`, data);
  return response;
});

export const updateRmaThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateRmaResponse>,
  UpdateRmaThunkRequest,
  { state: RootState }
>('rma/updateRmaThunk', async (data) => {
  const response = await Api.patch(`/rma/${data.id}`, data);
  return response;
});

export const rmaDeleteThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteRmaResponse>,
  number,
  { state: RootState }
>('rmas/rmaDeleteThunk', async (id) => {
  const response = await Api.delete(`/rma/${id}`);
  if (!response.success) {
    throw new Error('Failed at rmaDeleteThunk.');
  }
  return response;
});
