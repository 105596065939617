import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  GalleryGetRequest,
  GalleryGetViaIdResponse
} from 'src/types';

export const galleryGetViaIdThunk = createAsyncThunk<
  CommonAxiosResponse<GalleryGetViaIdResponse>,
  GalleryGetRequest,
  { state: RootState }
>('gallery/galleryGetViaIdThunk', async (data) => {
  const response = await Api.post(`/gallery/get/${data?.id}`, data);
  return response;
});
