import React, { useRef } from 'react';
import {
  IconButton,
  makeStyles,
  TableCell,
  TextField
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { Autocomplete } from '@material-ui/lab';

import { CustomInputEvent, Quotation, QuotationCategory } from 'src/types';

interface QuotationCategoryCellProps {
  index: number;
  item: Quotation;
  hasCompatibility?: boolean;
  isCategoryLoading?: boolean;
  categoryOptions: QuotationCategory[];
  onChangeCategory: (val: QuotationCategory | null, index: number) => void;
  customInput: string;
  onCustomCategInputChange: (e: CustomInputEvent, index: number) => void;
}

const width = 170;

const useStyles = makeStyles(
  () => ({
    root: {
      width: width
    },
    autoComplete: {
      maxWidth: width,
      borderBottomWidth: 1
    }
  }),
  { index: 999 }
);

const CategoryCell = ({
  index,
  item,
  customInput,
  categoryOptions,
  // eslint-disable-next-line no-unused-vars
  isCategoryLoading,
  // eslint-disable-next-line no-unused-vars
  hasCompatibility,
  onChangeCategory,
  onCustomCategInputChange
}: QuotationCategoryCellProps) => {
  const inputRef = useRef([]);
  const classes = useStyles();

  // On Blur event of category will search if `customInput` exist on origcategoryOptions
  const onBlur = (e: CustomInputEvent) => {
    const { value } = e.target;
    const customInputMatch = categoryOptions?.filter(
      (x) => x.category_name?.toUpperCase() === value?.toUpperCase()
    );
    if (customInputMatch?.length > 0) {
      onChangeCategory(customInputMatch[0], index);
    }
  };

  return (
    <TableCell onClick={(e) => e.stopPropagation()} className={classes.root}>
      {item?.custom ? (
        <TextField
          onBlur={onBlur}
          value={customInput}
          onChange={(e) => onCustomCategInputChange(e, index)}
          label=""
          margin="none"
          variant="standard"
          InputProps={{
            endAdornment: (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeCategory(null, index);
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />
      ) : (
        <Autocomplete
          fullWidth
          size="small"
          id="category"
          ref={inputRef.current[index]}
          value={{
            category_id: item?.product?.category_id,
            category_name: item?.product?.category_name
          }}
          getOptionSelected={(option, value) =>
            option?.category_id === value?.category_id
          }
          className={classes.autoComplete}
          options={categoryOptions}
          onChange={(_, newValue) => onChangeCategory(newValue, index)}
          getOptionLabel={(option) => option.category_name ?? ''}
          renderInput={(params) => (
            <TextField {...params} label="" margin="none" variant="standard" />
          )}
          // dont disable the category input whenever loading
          // disabled={isCategoryLoading}
        />
      )}
    </TableCell>
  );
};

export const QuotationCategoryCell = React.memo(CategoryCell);
