export const warranties = [
  {
    name: '7 days',
    value: 7
  },
  {
    name: '1 month - (31 days)',
    value: 31
  },
  {
    name: '3 months - (93 days)',
    value: 93
  },
  {
    name: '6 months - (186 days)',
    value: 186
  },
  {
    name: '1 year - (365 days)',
    value: 365
  }
];
