import React, { useCallback } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
  makeStyles
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DatePickerRangeComponent } from 'src/components';
import { formatDate } from 'src/utils';
import { BranchMeta } from 'src/types';
import { multiBranchFeat } from 'src/constants/feature-toggle';

interface Props {
  fromDate?: string;
  toDate?: string;
  onChangeFromDate?: (date?: string) => void;
  onChangeToDate?: (date?: string) => void;
  onApplyFilterPress: () => void;
  className?: string;
  setSelectedBranchIDs: (v: number[]) => void;
  isLoading: boolean;
  userBranchOptions: BranchMeta[];
  selectedBranchIDs: number[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  filterTitle: {
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  },
  datePickersContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dashSeparator: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  buttonsContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5)
  },
  applyBtn: {
    marginLeft: theme.spacing(5)
  }
}));

const AvailableInventoryFilter = ({
  className,
  onApplyFilterPress,
  fromDate,
  toDate,
  onChangeFromDate,
  onChangeToDate,
  setSelectedBranchIDs,
  userBranchOptions,
  isLoading,
  selectedBranchIDs,
  ...rest
}: Props) => {
  const classes = useStyles();

  const onInternalApply = useCallback(() => {
    onApplyFilterPress();
  }, [onApplyFilterPress]);

  const transformerDateField = (date: MaterialUiPickersDate) => {
    const dateUtc = formatDate(date || '', 'yyyy-MM-dd');
    return dateUtc;
  };

  const onChangedDate = useCallback(
    (date: MaterialUiPickersDate, field: 'from' | 'to') => {
      const transformedDate = transformerDateField(date);

      if (onChangeToDate && field === 'to') {
        onChangeToDate(transformedDate);
      }
      if (onChangeFromDate && field === 'from') {
        onChangeFromDate(transformedDate);
      }
    },
    [onChangeFromDate, onChangeToDate]
  );

  const RenderButtonsGroup = useCallback(() => {
    return (
      <Grid container spacing={5} alignItems="center" justify="center">
        <Grid item spacing={5} className={classes.buttonsContainer}>
          <Grid item className={classes.applyBtn}>
            <Button
              endIcon={<SearchIcon />}
              onClick={onInternalApply}
              color="primary"
              variant="contained"
            >
              Apply Filter
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [classes.applyBtn, classes.buttonsContainer, onInternalApply]);

  const RenderDatePickersFilter = useCallback(() => {
    return (
      <Grid container spacing={4} alignItems="center" justify="center">
        <Grid item>
          <DatePickerRangeComponent
            title="Date Availability"
            fromDateValue={fromDate || null}
            toDateValue={toDate || null}
            dateToLabel="End Date"
            dateFromLabel="Start Date"
            toDateMin={fromDate}
            onChangeToDate={(date) => onChangedDate(date, 'to')}
            onChangeFromDate={(date) => onChangedDate(date, 'from')}
          />
        </Grid>
      </Grid>
    );
  }, [fromDate, toDate, onChangedDate]);

  const RenderBranchSelection = useCallback(() => {
    return (
      <>
        {multiBranchFeat ? (
          <Grid container spacing={4} alignItems="center" justify="center">
            <Grid item md={2} style={{ marginRight: '10px' }}>
              <Typography align="center" variant="h5">
                Branch
              </Typography>
              <FormControl style={{ width: 200 }}>
                <Select
                  disabled={isLoading}
                  labelId="branches"
                  id="branches-name"
                  multiple
                  value={selectedBranchIDs}
                  onChange={(e) => {
                    // Prevent ts error by ensuring type
                    if (Array.isArray(e.target.value)) {
                      setSelectedBranchIDs(e.target.value);
                    }
                  }}
                  input={<OutlinedInput />}
                >
                  {userBranchOptions.map((b) => (
                    <MenuItem key={b.value} value={b.value}>
                      {b.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        ) : null}
      </>
    );
  }, [isLoading, selectedBranchIDs, setSelectedBranchIDs, userBranchOptions]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography color="textPrimary" gutterBottom variant="h3">
          Available Inventory
        </Typography>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            {RenderDatePickersFilter()}
            {RenderBranchSelection()}
            {RenderButtonsGroup()}
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default AvailableInventoryFilter;
