import { createSlice } from '@reduxjs/toolkit';
import { Manufacturer } from 'src/constants/inventory';
import * as thunks from './thunks';

type State = {
  manufacturers: Manufacturer[];
  manufacturersOnFilter: Manufacturer[]
};

const initialState: State = {
  manufacturers: [],
  manufacturersOnFilter: []
};

const slice = createSlice({
  name: 'manufacturers',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getManufacturersThunk.fulfilled,
      (state, { payload }) => {
        state.manufacturers = payload.originalData.manufacturers;
      }
    );
    builder.addCase(
      thunks.getManufacturersFilterThunk.fulfilled,
      (state, { payload }) => {
        state.manufacturersOnFilter = payload.originalData.manufacturers;
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
