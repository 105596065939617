import React, { FC, memo } from 'react';
import { BubbleMenu, Editor } from '@tiptap/react';
import { TextStyleOptions } from './TextStyleOptions';
import { Paper } from '@material-ui/core';

interface Props {
  editor?: Editor | null;
}

const component: FC<Props> = ({ editor }) => {
  return (
    <>
      {editor && (
        <BubbleMenu tippyOptions={{ duration: 100 }} editor={editor}>
          <Paper
            elevation={5}
            style={{
              background: 'white',
              padding: '0.5rem'
            }}
          >
            <TextStyleOptions editor={editor} />
          </Paper>
        </BubbleMenu>
      )}
    </>
  );
};

export const RichTextBoxBubbleMenu = memo(component);
