import React, { useCallback, useRef } from 'react';
import {
  IconButton,
  makeStyles,
  TableCell,
  TextField
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';

import {
  CustomInputEvent,
  CustomKeyboardEvent,
  TransactionPayment
} from 'src/types';

interface Props {
  data: TransactionPayment;
  index: number;
  onSave: (e: any, item: TransactionPayment) => void;
  onChangeText: (e: CustomInputEvent, index: number) => void;
}

const useStyles = makeStyles(
  {
    saveIcon: {
      padding: 0
    },
    input: {
      paddingTop: 0,
      height: 25
    }
  },
  { index: 999 }
);

const ARCell = ({ data, index, onSave, onChangeText }: Props) => {
  const inputRef = useRef([]);
  const classes = useStyles();

  const onKeyDown = useCallback(
    (e: CustomKeyboardEvent, item: TransactionPayment) => {
      if (e.key === 'Enter') {
        onSave(e, item);
      }
    },
    [onSave]
  );

  return (
    <TableCell onClick={(e) => e.stopPropagation()}>
      <TextField
        ref={inputRef.current[index]}
        className={classes.input}
        onFocus={(e) => {
          e.stopPropagation();
        }}
        size="small"
        variant="outlined"
        onKeyDown={(e) => onKeyDown(e, data)}
        name="acknowledgement_receipt_no"
        onChange={(e) => onChangeText(e, index)}
        value={data?.acknowledgement_receipt_no ?? ''}
        InputProps={{
          className: classes.input,
          endAdornment: (
            <IconButton
              className={classes.saveIcon}
              onClick={(e) => onSave(e, data)}
            >
              <SaveIcon />
            </IconButton>
          )
        }}
      />
    </TableCell>
  );
};

export const TransactionDetailsARCell = React.memo(ARCell);
