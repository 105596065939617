import React, { FC, memo, useMemo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import {
  warrantyTextStyles,
  warrantyViewStyles
} from 'src/views/transactions/TransactionPDF/styles';
import { TemplateProductDetails } from 'src/types';
import { RenderCurrency } from 'src/views/transactions/TransactionPDF/components';
import { promoPriceToSRP } from 'src/utils';

type ItemProducts = {
  quantity?: string;
  productName?: string;
  categoryName?: string;
  unitPrice?: string;
  amount?: string;
};

const styles = StyleSheet.create({
  main: {
    marginLeft: 5,
    marginRight: 5
  },
  header: {
    marginTop: 10,
    height: 20
  },
  headerColumn: {
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },

  itemColumn: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  centerColumn: {
    alignItems: 'center',
    justifyContent: 'center',
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  descriptionOrSnText: {
    maxLines: 1,
    paddingBottom: 2
  }
});

const flexSizeOfColumns = {
  qty: 0.5,
  item: 3,
  unit_price: 1,
  amount: 1
};

const headerColumns = [
  { flex: flexSizeOfColumns.qty, title: 'Qty' },
  {
    flex: flexSizeOfColumns.item,
    title: 'Product Details'
  },
  { flex: flexSizeOfColumns.unit_price, title: 'Unit Price' },
  { flex: flexSizeOfColumns.amount, title: 'Amount' }
];

interface Props {
  templateProducts?: TemplateProductDetails[];
  showSRP?: boolean;
}

const component: FC<Props> = ({ templateProducts, showSRP = false }) => {
  const itemsPerLine = useMemo(() => {
    const defaultLinesForItem = 17;
    let newItems = Array.from({ length: defaultLinesForItem }).map(
      (_, index) => {
        if (templateProducts && templateProducts?.length > 0) {
          const item = templateProducts[index];
          if (item) {
            return {
              quantity: item?.quantity.toString(),
              productName: item?.product?.product_name,
              categoryName: item?.product?.category_name,
              unitPrice: showSRP
                ? promoPriceToSRP(item?.product?.retail_price || 0).toString()
                : item?.product?.retail_price?.toString(),
              amount: showSRP
                ? promoPriceToSRP(
                    (item?.product?.retail_price || 1) * item?.quantity
                  ).toString()
                : (
                    (item?.product?.retail_price || 1) * item?.quantity
                  ).toString()
            };
          }
        }
        // undefined field object for empty rows
        return {
          quantity: '',
          productName: '',
          categoryName: '',
          unitPrice: '',
          amount: ''
        };
      }
    );

    return newItems;
  }, [showSRP, templateProducts]);

  const HeaderColumn = () => {
    return (
      <View style={[styles.header, warrantyViewStyles?.row]}>
        {headerColumns.map((header, index) => (
          <View
            key={header.title}
            style={[
              styles.headerColumn,
              { flex: header.flex },
              { borderRightWidth: index === headerColumns.length - 1 ? 1 : 0 }
            ]}
          >
            <Text style={warrantyTextStyles.textBold}>{header.title}</Text>
          </View>
        ))}
      </View>
    );
  };

  const ItemColumn = ({ itemProd }: { itemProd?: ItemProducts }) => {
    return (
      <View
        style={[
          warrantyViewStyles?.row,
          //we are sure that the product name that has 90 char is exceed 2 lines
          {
            height:
              itemProd?.productName && itemProd?.productName.length > 90
                ? 'auto'
                : 23
          }
        ]}
      >
        <View style={[styles.centerColumn, { flex: 0.5 }]}>
          <Text style={warrantyTextStyles.text}>{itemProd?.quantity}</Text>
        </View>
        <View style={[styles.itemColumn, { flex: 3 }]}>
          {itemProd?.productName === undefined ||
          itemProd?.productName === '' ? null : (
            <Text
              style={[warrantyTextStyles.text, { paddingHorizontal: 5 }]}
            >{`(${itemProd?.categoryName}) ${itemProd?.productName}`}</Text>
          )}
        </View>
        <View style={[styles.itemColumn, { flex: 1 }]}>
          {itemProd?.unitPrice === undefined ||
          itemProd?.unitPrice === '' ? null : (
            <RenderCurrency amount={+itemProd?.unitPrice || 0} />
          )}
        </View>
        {/* This is last column */}
        <View style={[styles.itemColumn, { flex: 1 }, { borderRightWidth: 1 }]}>
          {itemProd?.amount === undefined || itemProd?.amount === '' ? null : (
            <RenderCurrency amount={+itemProd?.amount || 0} />
          )}
        </View>
      </View>
    );
  };

  return (
    <View style={styles.main}>
      <HeaderColumn />
      {itemsPerLine?.map((item, index) => (
        <ItemColumn key={index} itemProd={item} />
      ))}
    </View>
  );
};

export const ViewQuotationItems = memo(component);
