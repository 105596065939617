import React, { useMemo } from 'react';
import { Container, Grid, Typography, makeStyles } from '@material-ui/core';
import { Page } from 'src/components';
import { appInformation } from 'src/constants';
import { usePermissions } from 'src/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const { viewSalesDashboard } = usePermissions();

  const dashboardSource = useMemo(() => {
    if (appInformation.branch_cicd === 'master' && viewSalesDashboard) {
      return 'https://lookerstudio.google.com/embed/reporting/af25c921-eba2-48e2-8e45-ad59a5755f43/page/get2D';
    }
    return '';
  }, [viewSalesDashboard]);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <Typography style={{ marginTop: 10 }} color="textPrimary" variant="h3">
          Dashboard (BETA)
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {dashboardSource ? (
              <iframe
                width="100%"
                height="1000"
                src={dashboardSource}
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen
                sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
              ></iframe>
            ) : (
              <div
                style={{
                  display: 'flex',
                  height: '90vh',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                <Typography variant="h4" color="textPrimary">
                  Permission needed to view dashboard {`:(`}
                </Typography>
              </div>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
