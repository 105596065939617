import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Container,
  makeStyles,
  TextField,
  Grid,
  CardHeader,
  Card,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  colors,
  Tooltip
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { DragAndDropImgUpload, Page } from 'src/components';
import {
  allowStringOnlyInput,
  convertImageFileToBase64,
  yearOptions
} from 'src/utils';
import { Alert, Autocomplete } from '@material-ui/lab';
import { contentType } from '../constant';
import { useBlog } from 'src/hooks/blogs/use-blog';
import { BlogData } from 'src/redux/slices/blogs/types';
import { BlogContentDialog } from '../component';
import { isEqual } from 'lodash';
import { useSnackBar } from 'src/hooks';

import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import { useBlogCategory } from 'src/hooks/blog-category';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  imgPreview: {
    display: 'grid',
    placeContent: 'center',
    padding: '1rem',
    width: '100%',
    height: 'auto'
  }
}));

interface Props {}

export const BlogDetailsView: FC<Props> = () => {
  const snackBar = useSnackBar();
  const {
    blogDetails,
    isPublish,
    updateDetailLoading,
    getBlogDetail,
    updateBlogDetails,
    setIsPublish
  } = useBlog();
  const { blogCategoryOptions, getBlogCategoryListOption } = useBlogCategory();
  const classes = useStyles();

  const [thumbnail, setThumbnail] = useState<any>();
  const [isBlogContentOpen, setIsBlogContentOpen] = useState<boolean>(false);

  const [contentTypeVal, setContentTypeVal] = useState<any>(null);
  const [inputContentValue, setInputContentValue] = useState<string>('');

  //===getting for initial state===
  useEffect(() => {
    getBlogDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBlogDetail]);

  useEffect(() => {
    if (blogDetails?.thumbnail) {
      setThumbnail(blogDetails?.thumbnail);
    }
    if (blogDetails?.is_published) {
      setIsPublish(blogDetails?.is_published === 1 ? true : false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blogDetails?.thumbnail]);

  const decodedHtmlDescription = useMemo(() => {
    if (blogDetails?.content_html && blogDetails) {
      const decodeBase64 = decodeURIComponent(
        escape(window.atob(blogDetails?.content_html))
      );
      return decodeBase64;
    }
    return '';
  }, [blogDetails]);

  const formik = useFormik({
    initialValues: {
      title: blogDetails?.title || '',
      brief_intro: blogDetails?.brief_intro || '',
      author: blogDetails?.author || '',
      content_type: blogDetails?.content_type || '',
      blog_category_id: blogDetails?.blog_category_id || 0,
      thumbnail_url: blogDetails?.thumbnail || '',
      year_published: blogDetails?.year_published || 2024
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (currentChange: BlogData) => {
      if (isEqual(formik?.initialValues, currentChange)) {
        snackBar.show({
          severity: 'info',
          message: 'No changes detected. There is nothing to save.'
        });
      } else {
        const currentChangeKeys = Object.keys(currentChange);
        const currentChangesValue = Object.values(currentChange);
        const initialFormikValues = Object.values(formik?.initialValues);

        const changedParams = currentChangeKeys.map((currentKey, index) => {
          return {
            [currentKey]: isEqual(
              initialFormikValues[index],
              currentChangesValue[index]
            )
              ? null
              : currentChangesValue[index]
          };
        });

        //only changed/values from field props will be here
        const filteredParams = Object.fromEntries(
          changedParams
            .filter((obj) => Object.values(obj)[0] !== null)
            .map((obj) => [Object.keys(obj)[0], Object.values(obj)[0]])
        );

        updateBlogDetails(false, filteredParams);
      }
    }
  });

  const onChangeThumbnail = useCallback(
    async (img: File[]) => {
      if (img && img.length > 0) {
        const firstFile = img[0];
        const imgInTxt: any = await convertImageFileToBase64(firstFile);
        setThumbnail(imgInTxt);
        formik.setFieldValue('thumbnail_url', imgInTxt ? imgInTxt : '');
      }
    },
    [formik]
  );

  const onHandleBlogPublish = useCallback(() => {
    //if not publish make it publish
    updateBlogDetails(true, { is_published: !isPublish ? 1 : 0 });
  }, [isPublish, updateBlogDetails]);

  const handleInputContentChange = (_: any, newInputValue: string) => {
    if (allowStringOnlyInput(newInputValue) || newInputValue === '') {
      setInputContentValue(newInputValue);
      formik.setFieldValue('content_type', newInputValue);
    }
  };

  const handleContentChange = (_: any, newValue: string | null) => {
    setContentTypeVal(newValue);
    setInputContentValue(newValue || '');
    formik.setFieldValue('content_type', newValue);
  };

  useEffect(() => {
    if (formik.values.content_type) {
      setContentTypeVal(formik.values.content_type);
      setInputContentValue(formik.values.content_type);
    }
  }, [formik.values.content_type]);

  useEffect(() => {
    //list is empty to  get all categories is hidden or not
    getBlogCategoryListOption({ list: '', page: 1 });
  }, [getBlogCategoryListOption]);

  return (
    <Page title={`Blog Details`} className={classes.root}>
      <Container maxWidth={false}>
        <Card style={{ padding: '1rem' }}>
          <CardHeader
            title="Blog Details"
            subheader="The current blog information."
          />
          <Divider />
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={2}
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              <Grid item xs={12} md={12}>
                {thumbnail && (
                  <Box className={classes.imgPreview}>
                    <img
                      alt="Cover Image"
                      src={thumbnail}
                      style={{ height: 300, width: undefined }}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <DragAndDropImgUpload
                  title="Drag or select photos for blog thumbnail"
                  onImageSelected={onChangeThumbnail}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                {blogDetails?.content_html && (
                  <Tooltip title="Toggle to publish or unpublish this blog">
                    <Button
                      variant="outlined"
                      startIcon={isPublish ? <DoneOutlineIcon /> : null}
                      color="inherit"
                      style={{
                        color: isPublish ? colors.green[700] : colors.red[700]
                      }}
                      onClick={() => onHandleBlogPublish()}
                      disabled={updateDetailLoading}
                    >
                      {'Publish'}
                    </Button>
                  </Tooltip>
                )}
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="title"
                  name="title"
                  label="Blog Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="author"
                  name="author"
                  label="Author"
                  value={formik.values.author}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.author && Boolean(formik.errors.author)}
                  helperText={formik.touched.author && formik.errors.author}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="content-type-list"
                  value={contentTypeVal}
                  options={contentType}
                  freeSolo
                  inputValue={inputContentValue}
                  onInputChange={handleInputContentChange}
                  onChange={handleContentChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Content Type"
                      name="content_type"
                      variant="outlined"
                      error={
                        formik.touched.content_type &&
                        Boolean(formik.errors.content_type)
                      }
                      helperText={
                        formik.touched.content_type &&
                        formik.errors.content_type
                      }
                      onBlur={formik.handleBlur}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="category-list"
                  value={
                    blogCategoryOptions.find(
                      (blog) => blog.id === formik?.values?.blog_category_id
                    ) || null
                  }
                  options={blogCategoryOptions}
                  fullWidth
                  getOptionLabel={(option) => option?.name || ''}
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      'blog_category_id',
                      newValue ? newValue.id : ''
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Blog Category"
                      variant="outlined"
                      error={
                        formik.touched.blog_category_id &&
                        Boolean(formik.errors.blog_category_id)
                      }
                      helperText={
                        formik.touched.blog_category_id &&
                        formik.errors.blog_category_id
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  id="brief_intro"
                  name="brief_intro"
                  label="Brief Intro"
                  value={formik.values.brief_intro}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.brief_intro &&
                    Boolean(formik.errors.brief_intro)
                  }
                  helperText={
                    formik.touched.brief_intro && formik.errors.brief_intro
                  }
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel id="year-published-label">
                    Year Published
                  </InputLabel>
                  <Select
                    labelId="year-published-label"
                    id="year_published"
                    name="year_published"
                    value={formik.values.year_published}
                    onChange={formik.handleChange}
                    label="Year Published"
                    error={
                      formik.touched.year_published &&
                      Boolean(formik.errors.year_published)
                    }
                  >
                    {yearOptions.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            {!blogDetails?.content_html && (
              <Alert severity="warning" style={{ marginBottom: '1rem' }}>
                {`Currently, this blog does not have content. Set the content now by clicking the button below.`}
              </Alert>
            )}

            <Button
              style={{ padding: '1rem' }}
              color="primary"
              variant="outlined"
              fullWidth
              onClick={() => setIsBlogContentOpen(true)}
            >
              Create/Update Blog Content
            </Button>
            <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
            <Button
              color="primary"
              variant="contained"
              fullWidth
              type="submit"
              disabled={updateDetailLoading}
            >
              Update
            </Button>
          </form>
        </Card>

        <BlogContentDialog
          isOpen={isBlogContentOpen}
          description={decodedHtmlDescription}
          handleClose={() => setIsBlogContentOpen(false)}
          reloadProductDetails={function(): void {
            throw new Error('Function not implemented.');
          }}
        />
      </Container>
    </Page>
  );
};

const validationSchema = Yup.object({
  title: Yup.string()
    .required('Title is required')
    .min(2, 'Title is too short - should be 2 chars minimum.')
    .max(100, 'Title cannot exceed 100 characters'),
  brief_intro: Yup.string()
    .required('Brief intro is required')
    .min(10, 'Brief Intro is too short - should be 10 chars minimum.')
    .max(500, 'Brief intro cannot exceed 500 characters'),
  author: Yup.string()
    .required('Author is required')
    .min(2, 'Author name is too short - should be 2 chars minimum.')
    .max(50, 'Author cannot exceed 50 characters'),
  content_type: Yup.string()
    .required('Content type is required')
    .min(2, 'Content type is too short - should be 2 chars minimum.')
    .max(100, 'Content type cannot exceed 100 characters'),
  blog_category_id: Yup.number().required('Blog category is required'),
  thumbnail_url: Yup.string().required('Thumbnail is required'),
  year_published: Yup.number()
    .required('Year published is required')
    .min(1900, 'Year must be later than 1900')
    .max(new Date().getFullYear(), 'Year cannot be in the future')
});
