import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { TemplateDetails } from 'src/types';
import { isEmpty } from 'lodash';

// Used for adding items in database with name field only. (Supplier, Manufacturer, Roles, Permission)

interface Props {
    template: TemplateDetails | undefined,
    isVisible: boolean,
    handleClose: () => void,
    onProceed: (action: string, name: string) => void
}

const TemplateForm = ({
    template,
    isVisible,
    handleClose,
    onProceed
}: Props) => {
    const [name, setName] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');

    useEffect(() => {
        setName(template?.raw_name || '');
    }, [template])

    const resetState = () => {
        setName('');
        setErrorMessage('');
    };

    const onProcessDialog = (action: string) => {
        if (isEmpty(name)) {
            setErrorMessage('Please provide template name');
            return;
        }

        onProceed(action, name)
        resetState();
        handleClose();
    };

    return (
        <Dialog
            open={isVisible}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Update/Create Template</DialogTitle>
            <DialogContent>
                <TextField
                    error={errorMessage === '' ? false : true}
                    helperText={errorMessage}
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Template Name"
                    fullWidth
                    required
                    variant="outlined"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onProcessDialog('create')}>Create as new template</Button>
                {template && <Button onClick={() => onProcessDialog('update')}>Save Changes</Button>}
            </DialogActions>
        </Dialog>
    );
};

export default TemplateForm;
