import { useEffect, useState } from 'react';
import { slices, useAppSelector } from 'src/redux';
import { Permission, Role } from 'src/types';

const { selectors: roleSelectors } = slices.roles;
const { selectors: permissionSelectors } = slices.permissions;

const usePermsAndRolesSearch = () => {
  const roles = useAppSelector(roleSelectors.selectRoleList);
  const permissions = useAppSelector(permissionSelectors.selectPermissionList);

  const [filteredRoles, setFilteredRoles] = useState<Role[]>([]);
  const [filteredPerms, setFilteredPerms] = useState<Permission[]>([]);

  const [roleInputVal, setRoleInputVal] = useState<string>('');
  const [permsInputVal, setPermsInputVal] = useState<string>('');

  const onChangeRoleInput = (val: string) => {
    setRoleInputVal(val);
  };

  const onSearchRole = () => {
    // reset if no input
    if (!roleInputVal) {
      setFilteredRoles(roles);
    } else {
      const searchWords = roleInputVal.toLowerCase().split(' ');
      const result = roles.filter((role) =>
        searchWords.every((word) => {
          return role?.name?.toLowerCase().includes(word);
        })
      );
      setFilteredRoles(result);
    }
  };

  const onChangePermsInput = (val: string) => {
    setPermsInputVal(val);
  };

  const onSearchPerms = () => {
    // reset if no input
    if (!permsInputVal) {
      setFilteredPerms(permissions);
      return;
    } else {
      const searchWords = permsInputVal.toLowerCase().split(' ');
      const result = permissions.filter((permission) =>
        searchWords.every((word) => {
          return permission?.name?.toLowerCase().includes(word);
        })
      );
      setFilteredPerms(result);
      return;
    }
  };

  // coded this way too avoid perms and roles not rendering bug
  useEffect(() => {
    permissions[0] && setFilteredPerms(permissions);
    roles[0] && setFilteredRoles(roles);
  }, [permissions, roles]);

  return {
    roles,
    permissions,
    roleInputVal,
    permsInputVal,
    filteredPerms,
    filteredRoles,
    setRoleInputVal,
    setPermsInputVal,
    setFilteredPerms,
    setFilteredRoles,
    onChangePermsInput,
    onChangeRoleInput,
    onSearchPerms,
    onSearchRole
  };
};

export default usePermsAndRolesSearch;
