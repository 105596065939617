import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Gallery } from 'src/types';
import { slices, useAppSelector } from 'src/redux';
import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import { formatCurrency } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/constants';
import { Alert } from '@material-ui/lab';

const { selectors: gallerySelectors } = slices.gallery;

interface Props {
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  imgPreview: {
    width: undefined,
    height: 50
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  }
}));

const Component = ({ className, ...rest }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const galleryList = useAppSelector(gallerySelectors.selectGalleryList);

  const onGalleryPress = (item: Gallery) => {
    navigate(`/app/gallery/view/${item?.id}`, { state: item });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Alert severity="info">
            <Typography variant="subtitle2">
              Will only show the latest 50 for now
            </Typography>
          </Alert>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Last UUID Digits</TableCell>
                <TableCell>Title</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Last Updated By</TableCell>
                <TableCell>Created By</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {galleryList?.map((item: Gallery, i: number) => (
                <AlternatingColorTableRow hover key={i}>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onGalleryPress(item)}
                      href={`/app/gallery/view/${item?.id}`}
                      title={`${item?.id}`}
                    />
                  </TableCell>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onGalleryPress(item)}
                      href={`/app/gallery/view/${item?.id}`}
                      title={`${item?.uuid?.split('-')?.[4]}`}
                    />
                  </TableCell>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onGalleryPress(item)}
                      href={`/app/gallery/view/${item?.id}`}
                      title={`${item?.title ?? '--'}`}
                    />
                  </TableCell>
                  <TableCell>{formatCurrency(item?.amount)}</TableCell>
                  <TableCell>{item?.updated_by_name}</TableCell>
                  <TableCell>{item?.created_by_name}</TableCell>
                  <TableCell>{item?.created_date}</TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        color: item?.published ? colors.success : colors.warning
                      }}
                    >
                      {`${item?.published ? 'published' : 'not published'}`}
                    </Typography>
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const GalleyListTable = React.memo(Component);
