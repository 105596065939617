/* eslint-disable no-unused-vars */
import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { useEffect, useState } from 'react';
import { Button, ThemeProvider } from '@material-ui/core';
import { useRoutes } from 'react-router-dom';
import Pusher from 'pusher-js';
import routes from 'src/routes';
import { GlobalStyles, theme } from 'src/theme';
import {
  AlertGlobal,
  FormPasscodeDialog,
  SnackBar,
  SomeonePaidDialog,
  WarningOverlay
} from './components';
import { slices, useAppSelector } from './redux';
import { useNotifSound } from './hooks';
import useSound from 'use-sound';
import { ecommPusher, pusherCredentials } from './constants';
import { EcommOrderPaidPusherData } from './types';
import { EcomPaidParams } from './types/params';

const { selectors: userSelectors } = slices.user;

const App = () => {
  const userInfo = useAppSelector(userSelectors.selectUserInfo);
  const routing = useRoutes(routes(userInfo));
  const { sucessEcommPaid, sucessEcommCOD } = useNotifSound();
  const [pusherState, setPusherState] = useState<Pusher | undefined>(undefined);
  const [triggerSound, setTriggerSound] = useState<boolean>(false);
  const [triggerSoundCOD, setTriggerSoundCOD] = useState<boolean>(false);
  const [ecommPaidParams, setEcommPaidParams] = useState<EcomPaidParams>({
    isOpenAlert: false,
    message: '',
    order_no: ''
  });

  const onHandleClose = () => {
    setEcommPaidParams((prev) => ({ ...prev, isOpenAlert: false }));
  };

  // THIS useEffect IS CRUCIAL FOR PLAYING SOUNDS WHILE LISTENING TO CHANNEL
  // calling success() or using sounds in useEffect in the same function as
  // bind of notifications will result in a race condition..
  // Will result a memory leak and sound wont be triggered.
  useEffect(() => {
    if (triggerSound) {
      sucessEcommPaid();
    }
    setTriggerSound(false);
  }, [sucessEcommPaid, triggerSound]);

  useEffect(() => {
    if (triggerSoundCOD) {
      sucessEcommCOD();
    }
    setTriggerSoundCOD(false);
  }, [sucessEcommCOD, triggerSoundCOD]);

  useEffect(() => {
    // TODO: Only listen if proper permission exist.
    let pusher: Pusher;
    if (userInfo) {
      // has_ecomm_paid_notif
      const hasNotifPermission = userInfo?.all_permissions?.find(
        (x) => x?.name === 'has_ecomm_paid_notif'
      );
      if (!hasNotifPermission) {
        return;
      }
      pusher = new Pusher(pusherCredentials.pusherAppKey, {
        cluster: pusherCredentials?.pusherAppCluster
      });
      const ecommPaidChannel = pusher.subscribe(ecommPusher?.ecommPaidChannel);
      const ecommCODChannel = pusher.subscribe(ecommPusher?.ecommCODChannel);

      ecommPaidChannel.bind(ecommPusher?.ecommPaidEvent, function(
        data: EcommOrderPaidPusherData
      ) {
        if (!hasNotifPermission) {
          return;
        }
        setTriggerSound(true);
        const text = `Someone paid on e-commerce. Order Ref No.: ${data?.order_no}`;

        setEcommPaidParams({
          isOpenAlert: true,
          message: text,
          order_no: data?.order_no
        });
      });

      ecommCODChannel.bind(ecommPusher?.ecommCODEvent, function(
        data: EcommOrderPaidPusherData
      ) {
        if (!hasNotifPermission) {
          return;
        }
        setTriggerSoundCOD(true);
        const text = `Someone ordered using COD on e-commerce. Order Ref No.: ${data?.order_no}`;

        setEcommPaidParams({
          isOpenAlert: true,
          message: text,
          order_no: data?.order_no
        });
      });

      setPusherState(pusher);
    }

    return () => {
      if (pusher) {
        pusher.unsubscribe(ecommPusher?.ecommPaidChannel);
        pusher.unsubscribe(ecommPusher?.ecommCODChannel);
      }
      setPusherState(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <ThemeProvider theme={theme}>
      <WarningOverlay />
      <GlobalStyles />
      {routing}
      <SnackBar />
      <SomeonePaidDialog
        isOpen={ecommPaidParams.isOpenAlert}
        notificationMessage={ecommPaidParams?.message}
        redirectLink={`/app/ecom-order/${ecommPaidParams?.order_no}`}
        onHandleClose={onHandleClose}
      />
      <AlertGlobal />
      <FormPasscodeDialog />
    </ThemeProvider>
  );
};

export default App;
