import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CreateProductsResponse,
  CommonProductsResponse,
  ProductsThunkRequest,
  UpdateProductResponse,
  GetProductViaIdResponse,
  DeleteProductResponse,
  GetProductsWithSerialResponse,
  GetProductsWithSerialRequest,
  UpdateProductThunkRequest,
  GetProductConsumablesResponse,
  UploadProductImageRequest,
  UploadProductImageResponse,
  GetProductImageResponse,
  DeleteProductImageResponse,
  DeleteProductImageRequest,
  GetProductRequest,
  PriceListResponse,
  PatchChronologicalOrderImageResponse,
  PatchChronologicalOrderImageRequest,
  UpdateProductDescriptionResponse,
  UpdateProductDescriptionPayload,
  CommonProductResponse,
  GetProductListingViaSerialNoResponse,
  GetProductListingViaSerialNoPayload,
  GetProductWithTotalAvailableItemsResponse,
  GetProductWithTotalAvailableItemsPayload,
  PriceListPayload
} from 'src/types/product';
import {
  CommonAxiosResponse,
  GetProductListingHistoryResponse
} from 'src/types';

export const getProductsThunk = createAsyncThunk<
  CommonAxiosResponse<CommonProductsResponse>,
  ProductsThunkRequest,
  { state: RootState }
>('product/getProducts', async (data) => {
  const response = await Api.get('/products', data);
  if (!response.success) {
    throw new Error('Failed to get products.');
  }
  return response;
});

export const getProductsForGalleryThunk = createAsyncThunk<
  CommonAxiosResponse<CommonProductsResponse>,
  ProductsThunkRequest,
  { state: RootState }
>('product/getProductsForGalleryThunk', async (data) => {
  const newPayload = { ...data, page_size: 48 };
  const response = await Api.get('/products', newPayload);
  if (!response.success) {
    throw new Error('Failed to get products.');
  }
  return response;
});

export const getPriceListThunk = createAsyncThunk<
  CommonAxiosResponse<PriceListResponse>,
  PriceListPayload,
  { state: RootState }
>('product/getPriceListThunk', async (data) => {
  const response = await Api.get('/product/pricelist/v2/get', data);
  if (!response.success) {
    throw new Error('Failed to get activation status.');
  }
  return response;
});

export const getProductsViaIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductViaIdResponse>,
  GetProductRequest,
  { state: RootState }
>('product/getProductsViaId', async (data) => {
  const response = await Api.get(`/products/${data?.id}`, data);
  // if (!response.success) {
  //   throw new Error('Failed to get activation status.');
  // }
  return response;
});

export const getProductViaSerialNo = createAsyncThunk<
  CommonAxiosResponse<CommonProductsResponse>,
  string,
  { state: RootState }
>('product/getProductViaSerialNo', async (serial_no) => {
  const response = await Api.get(`/products/getProductViaSerialNo`, {
    serial_no: serial_no
  });
  if (!response.success) {
    throw new Error('Failed at getProductViaSerialNo');
  }
  return response;
});

export const getProductsWithFilterThunk = createAsyncThunk<
  CommonAxiosResponse<CommonProductsResponse>,
  ProductsThunkRequest,
  { state: RootState }
>('product/getProductsWithFilter', async (filter) => {
  const response = await Api.get(`/products`, filter);
  if (!response.success) {
    throw new Error('Failed to get activation status.');
  }
  return response;
});

/** Usually for autocomplete */
/** Get available listings under a product with serial no */
export const getProductsWithSerialThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductsWithSerialResponse>,
  GetProductsWithSerialRequest,
  { state: RootState }
>('product/getProductsWithSerialThunk', async (data) => {
  const response = await Api.get('/products/getProductsWithSerialNo', data);
  if (!response.success) {
    throw new Error('Failed at getProductsWithSerialThunk.');
  }
  return response;
});

/** Usually for autocomplete of consumables*/
export const getProductConsumablesThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductConsumablesResponse>,
  { keyword?: string; branch_id?: number[] },
  { state: RootState }
>('product/getProductConsumablesThunk', async (data) => {
  const response = await Api.get('/products/getProductConsumables', data);
  if (!response.success) {
    throw new Error('Failed at getProductConsumablesThunk.');
  }
  return response;
});

export const updateProductsThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateProductResponse>,
  UpdateProductThunkRequest,
  { state: RootState }
>('product/updateProductsThunk', async (data) => {
  const response = await Api.patch(`/products/${data.id}`, data);
  if (response?.success) {
    await Api.patch(`/gallery/update/amount/${data.id}`);
  }
  return response;
});

export const createProductsThunk = createAsyncThunk<
  CommonAxiosResponse<CreateProductsResponse>,
  ProductsThunkRequest,
  { state: RootState }
>('product/createProductsThunk', async (data) => {
  const response = await Api.post(`/products`, data);
  return response;
});

export const deleteProductThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteProductResponse>,
  number,
  { state: RootState }
>('categories/deleteProductsThunk', async (id) => {
  const response = await Api.delete(`/products/${id}`);
  if (!response.success) {
    throw new Error('Failed deleteProductsThunk');
  }
  return response;
});

export const createProductImagesThunk = createAsyncThunk<
  CommonAxiosResponse<UploadProductImageResponse>,
  UploadProductImageRequest,
  { state: RootState }
>('product/createProductImagesThunk', async (data) => {
  const response = await Api.post(`/product/images/upload`, data);
  return response;
});

export const getProductImagesThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductImageResponse>,
  number,
  { state: RootState }
>('product/getProductImagesThunk', async (data) => {
  const response = await Api.get(`/product/images/get/${data}`);
  return response;
});

export const deleteProductImageThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteProductImageResponse>,
  DeleteProductImageRequest,
  { state: RootState }
>('categories/deleteProductsThunk', async (data) => {
  const response = await Api.delete(
    `/product/images/delete/${data?.product_id}`,
    { image_filepath: data?.image_filepath }
  );
  if (!response.success) {
    throw new Error('Failed deleteProductImageThunk');
  }
  return response;
});

export const patchChronologicalOrderImageThunk = createAsyncThunk<
  CommonAxiosResponse<PatchChronologicalOrderImageResponse>,
  PatchChronologicalOrderImageRequest,
  { state: RootState }
>('categories/patchChronologicalOrderImageThunk', async (data) => {
  const response = await Api.patch(
    `/product/images/chronological_order/update/${data?.product_id}`,
    { images: data?.images || [] }
  );
  if (!response.success) {
    throw new Error('Failed patchChronologicalOrderImageThunk');
  }
  return response;
});

export const getProductListingUpdateHistoryThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductListingHistoryResponse>,
  string | undefined,
  { state: RootState }
>('product/getProductListingUpdateHistoryThunk', async (data) => {
  const response = await Api.get(`listings/serial_no_history/get/${data}`);
  return response;
});

//for product description
export const updateProductDescriptionThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateProductDescriptionResponse>,
  UpdateProductDescriptionPayload,
  { state: RootState }
>('product/updateProductDescriptionThunk', async (data) => {
  const response = await Api.patch(`/products/description/update`, data);
  return response;
});

export const updateProductImageFileName = createAsyncThunk<
  CommonAxiosResponse<CommonProductResponse>,
  number | undefined,
  { state: RootState }
>('product/updateProductImageFileName', async (productId) => {
  const response = await Api.patch(`/products/image_filename/update`, {
    product_id: productId
  });
  return response;
});

//New endpoints commonly use for inventory audits
export const getProductListingViaSerialNoThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductListingViaSerialNoResponse>,
  GetProductListingViaSerialNoPayload,
  { state: RootState }
>('product/getProductListingViaSerialNoThunk', async (data) => {
  const response = await Api.get(`products/listing_via_serial_no/get`, data);
  return response;
});

export const getProductWithTotalAvailableItemThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductWithTotalAvailableItemsResponse>,
  GetProductWithTotalAvailableItemsPayload | undefined,
  { state: RootState }
>('product/getProductWithTotalAvailableItemThunk', async (data) => {
  const response = await Api.get(
    `products/with_total_available_items/get`,
    data
  );
  return response;
});
