import React, { useMemo } from 'react';
import { makeStyles, TableCell } from '@material-ui/core';

import { Quotation } from 'src/types';
import { formatCurrency, promoPriceToSRP } from 'src/utils';
import { colors } from 'src/constants';

interface QuotationPromoPriceCellProps {
  item: Quotation;
}

const width = 170;

const useStyles = makeStyles(
  () => ({
    root: {
      maxWidth: width,
      minWidth: width
    },
    pesoSign: {
      padding: 0,
      color: colors.black,
      fontWeight: 400,
      fontSize: 14,
      paddingTop: 1
    },
    input: {
      fontSize: 14,
      verticalAlign: 'text-top'
    },
    amount: {
      fontWeight: 500
    },
    showSRPInput: {
      color: colors.warning
    }
  }),
  { index: 999 }
);

const StandarRetailPrice = ({ item }: QuotationPromoPriceCellProps) => {
  const classes = useStyles();

  const srpNotCustom = useMemo(() => {
    const retailPrice = item?.product?.retail_price || 0;
    const standardPrice = promoPriceToSRP(retailPrice);
    const formattedPrice = formatCurrency(standardPrice);
    return formattedPrice;
  }, [item]);

  return (
    /* Do not show promo price input if showSRP is enabled. */
    <TableCell className={classes.root} onClick={(e) => e.stopPropagation()}>
      {srpNotCustom}
    </TableCell>
  );
};

export const QuotationSRPCell = React.memo(StandarRetailPrice);
