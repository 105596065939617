import React, { FC, memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { transferLogsText } from './styles';

const styles = StyleSheet.create({
  parent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '100%',
    marginTop: -15
  },
  head: {
    padding: 3,
    marginBottom: 10,
    minWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  detailRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
});

interface Props {
  title?: string;
  stNo?: string;
  date?: string;
  origin?: string;
  receiving?: string;
}

const component: FC<Props> = ({
  title = 'Stock Transfer',
  stNo,
  date,
  origin,
  receiving
}) => {
  return (
    <View style={styles.parent}>
      <View style={styles.head}>
        <Text style={[transferLogsText.textBold, { fontSize: '12px' }]}>
          {title}
        </Text>
      </View>

      <View style={{ minWidth: '100%', flexDirection: 'row' }}>
        <View style={{ flexDirection: 'column', width: '75%' }}>
          <View style={{ flexDirection: 'row' }}>
            <Text style={transferLogsText.textBold}>{`ST NO: `}</Text>
            <Text style={transferLogsText.text}>{stNo}</Text>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <Text style={transferLogsText.textBold}>{`DATE: `}</Text>
            <Text style={transferLogsText.text}>{date}</Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: 'column',
            width: '25%'
          }}
        >
          <View style={{ flexDirection: 'row' }}>
            <Text style={transferLogsText.textBold}>{`FROM: `}</Text>
            <Text style={transferLogsText.text}>{origin}</Text>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <Text style={transferLogsText.textBold}>{`TO: `}</Text>
            <Text style={transferLogsText.text}>{receiving}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export const ViewStDetailsLogs = memo(component);
