import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { IconButtonComponent } from 'src/components';
import { useSnackBar } from 'src/hooks';

interface Props {
  max?: number;
  min?: number;
  value: number;
  disabled?: boolean;
  onChangeValue: (num: number) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      display: 'flex',
      flexDirection: 'row'
    },
    value: {
      margin: theme.spacing(2)
    }
  }),
  { index: 999 }
);

export const CounterButtons = ({
  max = 99,
  min = 1,
  value,
  disabled,
  onChangeValue
}: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();

  const onIncreasePress = () => {
    if (value === max) {
      snackBar.show({ severity: 'error', message: `Max quantity is ${max}` });
      return;
    }
    onChangeValue(value + 1);
  };

  const onDecreasePress = () => {
    if (value === max) {
      snackBar.show({
        severity: 'error',
        message: `Minimum quantity is ${min}`
      });
      return;
    }
    onChangeValue(value - 1);
  };

  return (
    <div className={classes.root}>
      <IconButtonComponent
        onClick={onDecreasePress}
        disabled={value === min || disabled}
        customIcon={<RemoveIcon fontSize="small" color="primary" />}
      />
      <Typography className={classes.value}>{value}</Typography>
      <IconButtonComponent
        onClick={onIncreasePress}
        disabled={value === max || disabled}
        customIcon={<AddIcon fontSize="small" color="primary" />}
      />
    </div>
  );
};
