import React, { FC, memo } from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useStockTransfer } from 'src/hooks/stock-transfer';
import { NonSerializeProductPayload } from 'src/redux/slices/stock-transfer';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  isOpen: boolean;
  onHandleClose: () => void;
}

const component: FC<Props> = ({ isOpen, onHandleClose }) => {
  const {
    isLoading,
    selectedBranch,
    getNonSerializeProductCount
  } = useStockTransfer();

  const formik = useFormik({
    initialValues: {
      product: '',
      count: 1
    },
    validationSchema: Yup.object({
      product: Yup.string().required('Product SKU is required'),
      count: Yup.number()
        .min(1, 'Quantity must be at least 1')
        .required('Quantity is required')
    }),
    onSubmit: (values: NonSerializeProductPayload, { resetForm }) => {
      if (values?.count) {
        getNonSerializeProductCount({
          ...values,
          product: values.product?.trim(),
          count: values?.count,
          branch_id: selectedBranch?.branch_id
        });
      }
      resetForm();
    }
  });

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'sm'} onClose={onHandleClose}>
      <IconButton
        style={{ position: 'absolute', top: 0, right: 0 }}
        aria-label="close"
        color="secondary"
        onClick={onHandleClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle id="form-dialog-title">
        {'ADD NON SERIALIZE PRODUCT'}
      </DialogTitle>

      <DialogContent>
        <Typography variant="subtitle1">{`Get non serialize product from ${selectedBranch?.value}`}</Typography>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                variant="outlined"
                id="product"
                name="product"
                label="Product name/SKU"
                value={formik.values.product}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.product && Boolean(formik.errors.product)}
                helperText={formik.touched.product && formik.errors.product}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <TextField
                fullWidth
                label="Quantity"
                name="count"
                required
                type="number"
                variant="outlined"
                value={formik.values.count}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.count && Boolean(formik.errors.count)}
                helperText={formik.touched.count && formik.errors.count}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Button
                variant="contained"
                fullWidth
                color="primary"
                type="submit"
                style={{ marginBottom: '1rem' }}
              >
                {isLoading ? <CircularProgress /> : 'ADD'}
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export const TransferNonSnDialog = memo(component);
