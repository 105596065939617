import { PartFields, RamPartDetailsFields } from 'src/types';

export const ramPartsFields: PartFields<RamPartDetailsFields>[] = [
  {
    label: 'Ram Type',
    part_property: 'ram_type',
    hint: 'DDR4, DDR5',
    options: ['DDR3', 'DDR4', 'DDR5', 'DDR6'],
    transformRule: ['all-caps', 'no-spaces'],
    type: 'string',
    required: true
  },
  {
    label: 'CAS Latency',
    part_property: 'latency',
    type: 'number',
    required: false
  },
  {
    label: 'Frequency',
    hint: '3600MHZ,3200MHZ,4000MHZ,5200MHZ,1600MHZ',
    part_property: 'frequency',
    type: 'number',
    unit: 'MHZ',
    required: true,
    options: ['3600', '3200', '4000', '5200', '1600'],
    transformRule: ['all-caps', 'no-spaces']
  },
  {
    label: 'Capacity',
    part_property: 'capacity',
    type: 'number',
    hint: '4GB, 8GB, 16GB, 32GB, 64GB, 128GB',
    options: ['4', '8', '16', '32', '64', '128'],
    unit: 'GB',
    transformRule: ['all-caps', 'no-spaces'],
    required: true
  },
  {
    label: 'Kit',
    part_property: 'kit',
    hint: 'Single, Dual, Quad',
    options: ['Single', 'Dual', 'Quad'],
    transformRule: ['all-caps'],
    strictOption: true,
    type: 'string',
    required: true
  },
  {
    label: 'Image',
    part_property: 'image_upload',
    hint: 'Select Product Image. Only one is allowed for now',
    type: 'img',
    required: false
  }
];
