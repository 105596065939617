import React, { useCallback, useEffect, useMemo } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { DatePickerRangeComponent, SelectionChip } from 'src/components';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { DateFieldsV2, DateRangeSpecific } from 'src/types';
import { usePermissions, useSnackBar } from 'src/hooks';
import { map } from 'lodash';
import { dateToday, toUtcEquivalentDate } from 'src/utils';
import useUserInfo from 'src/hooks/user/use-user-info';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import { BranchListDropDown } from 'src/components/dropdown';

interface Props {
  onApplyFilterPress: () => void;
  className?: string;
}

const { actions: salesActions, selectors: salesSelectors } = slices.sales;

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  },
  datePickersContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dashSeparator: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  buttonsContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  chip: {
    marginRight: theme.spacing(0.5),
    marginLeft: theme.spacing(0.5)
  },
  applyBtn: {
    marginLeft: theme.spacing(1)
  }
}));

const Toolbar = ({ className, onApplyFilterPress, ...rest }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const salesFilter = useAppSelector(salesSelectors.selectAllSalesFilter);
  const permissions = usePermissions();
  const { getUserDetails } = useUserInfo();

  const areDateRangeFieldEmpty = useMemo(() => {
    // Check for Date Range Fields if its empty.
    const arr = [
      salesFilter?.encoded_date_from,
      salesFilter?.encoded_date_to,
      salesFilter?.purchased_date_from,
      salesFilter?.purchased_date_to,
      salesFilter?.sold_date_from,
      salesFilter?.sold_date_from
    ];
    const mapped = map(arr, (x) => !x);
    return mapped.every((v) => v === true);
  }, [salesFilter]);

  const handleBranchChange = useCallback(
    (v: number[]) => {
      dispatch(salesActions.updateFilter({ branch_ids: v }));
    },
    [dispatch]
  );

  const onInternalApply = useCallback(() => {
    // If no quick filter is not applied and no Date Sold (Customer), Date Purchased (Supplier), Date Encoded is inputted default to today.
    if (salesFilter.sold_date_to && !salesFilter.sold_date_from) {
      snackBar.show({
        severity: 'error',
        message: 'Please provide sold date from'
      });
      return;
    }

    if (salesFilter.purchased_date_to && !salesFilter.purchased_date_from) {
      snackBar.show({
        severity: 'error',
        message: 'Please provide purchased date from'
      });
      return;
    }

    if (salesFilter.encoded_date_to && !salesFilter.encoded_date_from) {
      snackBar.show({
        severity: 'error',
        message: 'Please provide encoded date from'
      });
      return;
    }

    if (
      (areDateRangeFieldEmpty && !salesFilter?.preset_dates) ||
      !salesFilter
    ) {
      dispatch(salesActions.setFilterToday());
    }
    // Date Range are prioritized. If DateRange is Selected it would remove QuickFilter
    if (!areDateRangeFieldEmpty && salesFilter?.preset_dates) {
      dispatch(salesActions.removeQuickFilter());
    }

    onApplyFilterPress();
  }, [areDateRangeFieldEmpty, dispatch, onApplyFilterPress, salesFilter]);

  const onChangedDate = useCallback(
    (date: MaterialUiPickersDate, field: DateFieldsV2) => {
      let dateOnStr = toUtcEquivalentDate(date)?.toISOString() || null;

      dispatch(salesActions.updateFilterDate({ date: dateOnStr, field }));
    },
    [dispatch]
  );

  const onPressClear = useCallback(() => {
    dispatch(salesActions.resetFilter());
  }, [dispatch]);

  const onQuickFilterChange = useCallback(
    (field: DateRangeSpecific) => {
      if (field === salesFilter?.preset_dates) {
        dispatch(salesActions.updateQuickFilter(undefined));
        return;
      }
      dispatch(salesActions.updateQuickFilter(field));
    },
    [dispatch, salesFilter]
  );

  const isChipSelected = useCallback(
    (rangeField: DateRangeSpecific) => rangeField === salesFilter?.preset_dates,
    [salesFilter]
  );

  const RenderQuickFilters = useCallback(() => {
    return (
      <Grid item className={classes.buttonsContainer}>
        <Grid item>
          <SelectionChip
            label="Transactions Today"
            className={classes.chip}
            isSelected={isChipSelected('today')}
            onClick={() => onQuickFilterChange('today')}
          />
        </Grid>
        <Grid item>
          <SelectionChip
            label="Transactions This Week"
            className={classes.chip}
            isSelected={isChipSelected('week')}
            onClick={() => onQuickFilterChange('week')}
          />
        </Grid>
        <Grid item>
          <SelectionChip
            label="Transactions This Month"
            className={classes.chip}
            isSelected={isChipSelected('month')}
            onClick={() => onQuickFilterChange('month')}
          />
        </Grid>
      </Grid>
    );
  }, [
    classes.buttonsContainer,
    classes.chip,
    isChipSelected,
    onQuickFilterChange
  ]);

  const RenderButtonsGroup = useCallback(() => {
    return (
      <Grid item spacing={5} className={classes.buttonsContainer}>
        <Grid item>
          <Button
            endIcon={<ClearIcon />}
            onClick={onPressClear}
            color="secondary"
          >
            Set To Default
          </Button>
        </Grid>
        <Grid item className={classes.applyBtn}>
          <Button
            disabled={!permissions?.canViewSales}
            endIcon={<SearchIcon />}
            onClick={onInternalApply}
            color="primary"
            variant="contained"
          >
            Apply Filter
          </Button>
        </Grid>
      </Grid>
    );
  }, [
    classes.applyBtn,
    classes.buttonsContainer,
    onInternalApply,
    onPressClear,
    permissions.canViewSales
  ]);

  const RenderFilters = useCallback(() => {
    return (
      <Grid container spacing={4} alignItems="center" justify="center">
        <Grid item>
          <DatePickerRangeComponent
            title="Date Sold (Customer)"
            fromDateMax={dateToday()}
            fromDateValue={salesFilter?.sold_date_from}
            toDateValue={salesFilter?.sold_date_to}
            toDateMin={salesFilter?.sold_date_from}
            dateToLabel="Date Sold (To)"
            dateFromLabel="Date Sold (From)"
            onChangeToDate={(date) => onChangedDate(date, 'sold_date_to')}
            onChangeFromDate={(date) => onChangedDate(date, 'sold_date_from')}
          />
        </Grid>
        <Grid item>
          <DatePickerRangeComponent
            title="Date Purchased  (Supplier)"
            fromDateMax={dateToday()}
            fromDateValue={salesFilter?.purchased_date_from}
            toDateValue={salesFilter?.purchased_date_to}
            toDateMin={salesFilter?.purchased_date_from}
            dateToLabel="Date Purchased (To)"
            dateFromLabel="Date Purchased (From)"
            onChangeToDate={(date) => onChangedDate(date, 'purchased_date_to')}
            onChangeFromDate={(date) =>
              onChangedDate(date, 'purchased_date_from')
            }
          />
        </Grid>
        <Grid item>
          <DatePickerRangeComponent
            title="Date Encoded"
            toDateValue={salesFilter?.encoded_date_to}
            toDateMin={salesFilter?.encoded_date_from}
            fromDateMax={dateToday()}
            fromDateValue={salesFilter?.encoded_date_from}
            dateToLabel="Date Encoded (To)"
            dateFromLabel="Date Encoded (From)"
            onChangeToDate={(date) => onChangedDate(date, 'encoded_date_to')}
            onChangeFromDate={(date) =>
              onChangedDate(date, 'encoded_date_from')
            }
          />
        </Grid>
        {multiBranchFeat ? (
          <Grid item md={3}>
            <BranchListDropDown
              multiple
              onHandleBranchChange={(branchIds?: number[]) => {
                handleBranchChange(branchIds || []);
              }}
            />
          </Grid>
        ) : null}
      </Grid>
    );
  }, [salesFilter, onChangedDate, handleBranchChange]);

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography color="textPrimary" gutterBottom variant="h3">
          Sales
        </Typography>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            {RenderFilters()}
            <Grid
              container
              spacing={5}
              alignItems="center"
              justify="space-evenly"
            >
              {RenderQuickFilters()}
              {RenderButtonsGroup()}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default Toolbar;
