import React from 'react';
import { Box, Button } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';

interface Props {
  onClearFields: () => void;
  onCreateProduct: () => void;
}

const component = ({ onClearFields, onCreateProduct }: Props) => {
  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      <Button
        startIcon={<ClearIcon />}
        onClick={onClearFields}
        color="secondary"
        variant="contained"
      >
        Clear
      </Button>
      <Button
        startIcon={<SaveIcon />}
        style={{ marginLeft: 18 }}
        onClick={onCreateProduct}
        color="primary"
        variant="contained"
      >
        Save details
      </Button>
    </Box>
  );
};

export const AddProductFooterButtons = React.memo(component);
