import React, {
  ChangeEvent,
  FC,
  memo,
  useEffect,
  useMemo,
  useState
} from 'react';
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  CheckBoxLabel,
  LoadingButton,
  SelectImageButton
} from 'src/components';
import {
  containsAlphabetsWithSpacesAndDashes,
  convertImageFileToBase64
} from 'src/utils';
import { ErrorUpdateMainCategory, GetCategoryMainData } from 'src/types';
import { targetDataCategories } from 'src/constants';
import { useMainCategory } from 'src/hooks/category';
import { isEqual, pick } from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: { padding: '0.5rem', width: 800, height: 'auto', overflow: 'scroll' },
  avatar: {
    marginRight: theme.spacing(2)
  },
  paperStyle: {
    margin: '0.5rem',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column'
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: 0
  }
}));

interface Props {
  isOpenUpdateDialog: boolean;
  mainCategoryDetails: GetCategoryMainData;
  onToggleDialog: () => void;
  onCategoryRefetch: () => void;
}

const component: FC<Props> = ({
  isOpenUpdateDialog,
  mainCategoryDetails,
  onToggleDialog,
  onCategoryRefetch
}) => {
  const classes = useStyles();
  const { isLoading, updateMainCategories } = useMainCategory();
  const [mainCategoryPayload, setMainCategoryPayload] = useState<
    GetCategoryMainData
  >(mainCategoryDetails);

  const [errorState, setErrorState] = useState<ErrorUpdateMainCategory>({
    errMainCategoryName: false,
    errSideDrawerIcon: false
  });

  //check if anny of the field change
  const isNoCategoryDetailChanges = useMemo(() => {
    return isEqual(
      pick(mainCategoryPayload, [
        'name',
        'img_url',
        'target_data_id',
        'icon_sidedrawer',
        'is_visible_sidedrawer'
      ]),
      pick(mainCategoryDetails, [
        'name',
        'img_url',
        'target_data_id',
        'icon_sidedrawer',
        'is_visible_sidedrawer'
      ])
    );
  }, [mainCategoryDetails, mainCategoryPayload]);

  const handleIsVisibleChange = () => {
    const updatedPayload: GetCategoryMainData = {
      ...mainCategoryDetails,
      is_visible_sidedrawer: !mainCategoryDetails.is_visible_sidedrawer
    };

    setMainCategoryPayload(updatedPayload);
  };

  const onImageSelected = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      setMainCategoryPayload((prev) => ({ ...prev, img_url: imgInBase64 }));
    }
  };

  const onIconSelected = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);

      setErrorState((prev) => ({ ...prev, errSideDrawerIcon: false }));
      setMainCategoryPayload((prev) => ({
        ...prev,
        icon_sidedrawer: imgInBase64
      }));
    }
  };

  const onHandleMainCategoryName = (event: ChangeEvent<HTMLInputElement>) => {
    const newMainCategory = event?.target?.value;
    if (
      !newMainCategory ||
      !containsAlphabetsWithSpacesAndDashes(newMainCategory)
    ) {
      setErrorState((prev) => ({ ...prev, errMainCategoryName: true }));
    } else {
      setErrorState((prev) => ({ ...prev, errMainCategoryName: false }));
    }

    setMainCategoryPayload((prev) => ({ ...prev, name: newMainCategory }));
  };

  const onHandleTargetData = (event: ChangeEvent<{ value?: any }>) => {
    setMainCategoryPayload((prev) => ({
      ...prev,
      target_data_id: event?.target.value
    }));
  };

  const onHandleUpdateMainCategory = async (payload: GetCategoryMainData) => {
    if (
      errorState.errIconUrl ||
      errorState.errMainCategoryName ||
      errorState.errTargetData ||
      errorState.errSideDrawerIcon
    ) {
      return;
    }

    const responseSuccess = await updateMainCategories(payload);
    if (responseSuccess) {
      onCategoryRefetch();
      onToggleDialog();
    }
  };

  const onHandleClose = () => {
    onToggleDialog();
    setMainCategoryPayload(mainCategoryDetails);
  };

  useEffect(() => {
    setMainCategoryPayload(mainCategoryDetails);
  }, [mainCategoryDetails]);

  useEffect(() => {
    mainCategoryDetails?.icon_sidedrawer || mainCategoryPayload?.icon_sidedrawer
      ? setErrorState((prev) => ({ ...prev, errSideDrawerIcon: false }))
      : setErrorState((prev) => ({ ...prev, errSideDrawerIcon: true }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mainCategoryPayload]);

  return (
    <Dialog open={isOpenUpdateDialog} onClose={onHandleClose}>
      <Card elevation={0} style={{ minWidth: '500px', maxWidth: '500px' }}>
        <Box sx={{ padding: '1rem' }}>
          <Typography color="textPrimary" gutterBottom variant="h3">
            {`Update ${mainCategoryDetails.name} Category Information`}
          </Typography>
        </Box>
        <Paper className={classes.paperStyle} elevation={0}>
          <TextField
            fullWidth
            error={errorState.errMainCategoryName}
            helperText={
              errorState.errMainCategoryName &&
              'Please provide proper main category name'
            }
            autoFocus
            margin="dense"
            id="name"
            label={'Main Category Name'}
            required
            variant="outlined"
            value={mainCategoryPayload?.name}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onHandleMainCategoryName(e)
            }
          />
          <div className={classes.flexRow}>
            <FormControl
              fullWidth
              variant="outlined"
              style={{ minWidth: 300, marginTop: '0.7rem' }}
            >
              <InputLabel id="target-data-label">Set target data</InputLabel>
              <Select
                labelId="target-data-label"
                id="target-data"
                value={
                  targetDataCategories.find(
                    (item) => item.value === mainCategoryPayload?.target_data_id
                  )?.value || ''
                }
                onChange={(event) => onHandleTargetData(event)}
                label="Set target data"
              >
                {targetDataCategories.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <CheckBoxLabel
            onChange={handleIsVisibleChange}
            label="Visible on Side Drawer?"
            checked={mainCategoryPayload.is_visible_sidedrawer}
          />
          <Paper
            elevation={1}
            style={{
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'row',
              padding: '1rem',
              width: '100%'
            }}
          >
            <img
              alt=""
              style={{
                width: undefined,
                height: 70,
                marginRight: '1rem'
              }}
              src={mainCategoryPayload?.icon_sidedrawer}
            />
            <SelectImageButton
              containerStyle={{ width: '100%' }}
              title="Select/ Update Side Drawer Icon"
              multiple={false}
              onImageSelected={onIconSelected}
            />
          </Paper>
          {errorState?.errSideDrawerIcon ? (
            <Typography color="secondary">
              Add side drawer icon for visible categories.
            </Typography>
          ) : null}
          <Paper
            elevation={1}
            style={{
              marginTop: '1rem',
              display: 'flex',
              flexDirection: 'row',
              padding: '1rem',
              width: '100%'
            }}
          >
            <img
              alt=""
              style={{
                width: undefined,
                height: 70,
                marginRight: '1rem'
              }}
              //   src={categoryPayload?.img_url}
              src={mainCategoryPayload.img_url}
            />
            <SelectImageButton
              containerStyle={{ width: '100%' }}
              title="Select/ Update Category Icon"
              multiple={false}
              onImageSelected={onImageSelected}
            />
          </Paper>
        </Paper>
      </Card>
      <DialogActions style={{ marginRight: '1rem' }}>
        <LoadingButton
          title="Save Details"
          loading={isLoading}
          onClick={() => onHandleUpdateMainCategory(mainCategoryPayload)}
          color="primary"
          disabled={isNoCategoryDetailChanges}
        />
        <Button color="secondary" onClick={onHandleClose}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export const UpdateMainCategoryModal = memo(component);
