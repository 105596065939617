export * from './payment';
export * from './transaction-print';

export const transactionDetails = {
  success: true,
  items: [
    {
      id: 2,
      customer_id: 7,
      product_id: 2,
      serial_no: '2a4e7dcb-7f16-37be-8483-67fe1c204e43',
      created_at: '2021-10-06T13:29:54.000000Z',
      updated_at: '2021-10-06T13:29:54.000000Z',
      transaction_no: 'PCW-16335269944881',
      product_name: 'Re-contextualized heuristic database',
      warranty_duration: 0,
      retail_price: 333.88,
      quantity: 1,
      category_name: 'GPU'
    },
    {
      id: 3,
      customer_id: 4,
      product_id: 6,
      serial_no: '4fbac2d7-c63c-3c6d-b938-3228b8037d78',
      created_at: '2021-10-06T13:29:54.000000Z',
      updated_at: '2021-10-06T13:29:54.000000Z',
      transaction_no: 'PCW-16335269944881',
      product_name: 'Centralized client-driven solution',
      warranty_duration: 0,
      retail_price: 111.22,
      quantity: 1,
      category_name: 'GPU'
    },
    {
      id: 4,
      customer_id: 4,
      product_id: 5,
      serial_no: '2e367008-12c3-3022-8b05-f4ca69d43647',
      created_at: '2021-10-06T13:29:54.000000Z',
      updated_at: '2021-10-06T13:29:54.000000Z',
      transaction_no: 'PCW-16335269944881',
      product_name: 'Innovative non-volatile parallelism',
      warranty_duration: 0,
      retail_price: 555.55,
      quantity: 1,
      category_name: 'GPU'
    }
  ],
  total_price: 0,
  total_dealers_price: 0,
  message: 'Transactions via transaction no. successfully fetched.'
};

export const transactionDetailsOptionsSlug = {
  type_of_shipping: 'type-of-shipping',
  purchase_category: 'purchase-category',
  build_type: 'build-type',
  type_of_sales_transaction: 'type-of-sales-transaction'
};
