import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { useInventoryAuditLogs } from 'src/hooks/inventory-audit';
import { UpdateInventoryAuditsRemarksPayload } from 'src/redux/slices/inventory-audit-logs';
import { usePermissions } from 'src/hooks';

const useStyles = makeStyles((theme) => ({
  customerInfoBox: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: '1.5rem'
  }
}));

interface Props {
  ialNo?: string;
  historyRemarks?: string[];
  onSubmitRemarks: (params: UpdateInventoryAuditsRemarksPayload) => void;
}

const component: FC<Props> = ({ ialNo, historyRemarks, onSubmitRemarks }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { canViewAuditLogs } = usePermissions();
  const { isLoading } = useInventoryAuditLogs();

  const [remarks, setRemarks] = useState<string | undefined>(undefined);
  const [error, setError] = useState('');

  const onHandleRemarksChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    setRemarks(value);

    if (value.length < 3) {
      setError('Minimum 3 characters required.');
    } else if (value.length > 200) {
      setError('Maximum 200 characters allowed.');
    } else {
      setError('');
    }
  };

  const onHandleIalLink = (ial_no?: string) => {
    setRemarks('');
    navigate(`/app/inventory-audit-logs/${ial_no}`);
  };

  const onHandleSubmitRemarks = useCallback(() => {
    onSubmitRemarks({ ial_no: ialNo, remarks });
  }, [ialNo, onSubmitRemarks, remarks]);

  useEffect(() => {
    //if there is an update on history remarks
    if (historyRemarks) {
      setRemarks('');
    }
  }, [historyRemarks]);

  return (
    <Card className={classes?.customerInfoBox}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Typography
              display="inline"
              variant="h5"
              style={{ marginBottom: '1rem' }}
            >
              Remarks:
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              multiline
              id="brief_intro"
              name="brief_intro"
              label="Audit Logs Remarks"
              value={remarks || ''}
              onChange={onHandleRemarksChange}
              inputProps={{
                minLength: 3,
                maxLength: 200
              }}
              error={Boolean(error)}
              helperText={error}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '1rem', width: '30%' }}
                disabled={
                  Boolean(error) || !remarks || isLoading || !canViewAuditLogs
                }
                onClick={onHandleSubmitRemarks}
              >
                Submit Remarks
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12} md={12}>
          <Divider />
          <Box mt={'1rem'}>
            <Typography
              display="inline"
              variant="h5"
              style={{ marginBottom: '1rem' }}
            >
              Remarks History:
            </Typography>
            {historyRemarks && historyRemarks.length > 0 ? (
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  padding: '1rem',
                  backgroundColor: '#e8f4fd'
                }}
              >
                {historyRemarks?.map((remark, index) => {
                  // Define a regular expression to match strings like "IAL20240829-29"
                  const regex = /(IAL\d{8}-\d+)/g;

                  // Split the remark into parts based on the regex
                  const parts = remark.split(regex);

                  return (
                    <Typography
                      key={index}
                      display="inline"
                      style={{ marginLeft: 3, overflowWrap: 'break-word' }}
                    >
                      {parts.map((part, idx) =>
                        regex.test(part) ? (
                          <a
                            key={idx}
                            onClick={() => onHandleIalLink(part)}
                            style={{
                              textDecoration: 'underline',
                              color: 'blue',
                              cursor: 'pointer'
                            }}
                          >
                            {`- ${part}`}
                          </a>
                        ) : (
                          // Render the normal text
                          <span key={idx}>{`- ${part}`}</span>
                        )
                      )}
                    </Typography>
                  );
                })}
              </Box>
            ) : (
              <Typography display="inline">
                {`  No remarks history added on this audit log`}
              </Typography>
            )}
          </Box>
        </Grid>
      </Grid>
    </Card>
  );
};

export const AuditRemarks = memo(component);
