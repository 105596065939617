/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { useCallback, useMemo } from 'react';
import { colors } from 'src/constants';
import { dateTodayVanilla } from 'src/utils';

const moment = require('moment');
require('moment-precise-range-plugin');

interface Props {
  startDate?: string;
  endDate?: string;
}

type TimeCircle = {
  unit?: string;
  value?: string | number;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

export const TimeLeftCircles = ({
  startDate,
  endDate
}: Props) => {
  const classes = useStyles();

  const anchorStartDate = useMemo(() => {
    if (startDate) {
      if (new Date(startDate) > dateTodayVanilla()) {
        return startDate;
      } else {
        return dateTodayVanilla();
      }
    }
    return dateTodayVanilla();
  }, [startDate]);

  const anchorEndDate = useMemo(() => {
    return endDate;
  }, [endDate]);

  const preciseTimeDifferenceBetweenAnchors = useMemo(() => {
    var diffHuman = moment.preciseDiff(anchorStartDate, anchorEndDate);
    return diffHuman;
  }, [anchorEndDate, anchorStartDate]);

  // const TimeCircle = useCallback(({ unit, value }: TimeCircle) => {
  //   return (
  //     <Box
  //       sx={{
  //         bgcolor: colors.grey[200],
  //         height: 50,
  //         width: 50,
  //         borderRadius: 25,
  //         overflow: 'hidden',
  //         display: 'flex',
  //         flexDirection: 'column',
  //         alignItems: 'center',
  //         justifyContent: 'center'
  //       }}
  //     >
  //       <Typography variant="h5">{value}</Typography>
  //       <Typography style={{ fontSize: 10, marginTop: -3 }} variant="subtitle2">
  //         {unit}
  //       </Typography>
  //     </Box>
  //   );
  // }, []);

  return (
    <Box className={classes.root}>
      <Typography>{preciseTimeDifferenceBetweenAnchors}</Typography>
    </Box>
  );
};
