import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography
} from '@material-ui/core';
import React, { FC, memo } from 'react';

interface DecisionDialogProps {
  isOpen: boolean;
  title?: string;
  subTitle?: string;
  okayButtonName?: string;
  onHandleConfirmAction: () => void;
  onHandleClose: () => void;
}

const component: FC<DecisionDialogProps> = ({
  isOpen,
  title,
  subTitle,
  okayButtonName = 'Confirm',
  onHandleConfirmAction,
  onHandleClose
}) => {
  const onHandleConfirm = () => {
    onHandleConfirmAction();
    onHandleClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography style={{ fontWeight: 700, fontSize: '20px' }}>
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography style={{ fontSize: '16px' }}>{subTitle}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onHandleConfirm} color="primary" autoFocus>
            {okayButtonName}
          </Button>
          <Button onClick={onHandleClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export const DecisionDialog = memo(component);
