import React, { FC, useCallback, useState, KeyboardEvent } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  SvgIcon,
  TextField
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';

interface Props {
  onHandleSearchUser: (keyword?: string) => void;
}

const KachiPointsToolbar: FC<Props> = ({ onHandleSearchUser }) => {
  const [keyword, setKeyword] = useState<string>('');

  const onClickSearch = useCallback(() => {
    onHandleSearchUser(keyword);
  }, [keyword, onHandleSearchUser]);

  const handleKeyPress = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === 'Enter') {
        onHandleSearchUser(keyword);
      }
    },
    [keyword, onHandleSearchUser]
  );

  return (
    <Paper elevation={1} style={{ padding: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center'
        }}
      >
        <Box
          maxWidth={500}
          display={'flex'}
          flexDirection={'row'}
          alignItems={'center'}
        >
          <TextField
            style={{ marginRight: 10 }}
            onChange={(e) => setKeyword(e?.target?.value || '')}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Search User"
            variant="outlined"
            onKeyPress={handleKeyPress}
          />
          <Button onClick={onClickSearch} color="primary" variant="contained">
            Search
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export const KachiPointToolBar = React.memo(KachiPointsToolbar);
