import { PriceListItemStatusEnum } from 'src/enums';

// TODO: For deletion if confirmed not gonna be used in the future
export const mockPriceListResponse: any = {
  success: true,
  products: [
    {
      id: 987,
      name: 'GEFORCE GTX 1660 SUPER GIGABYTE 6GB GDDR6 DUAL FAN',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 15000,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 15750
    },
    {
      id: 963,
      name: 'GEFORCE GTX 1660 SUPER MSI VENTUS XS 6GB OC DUAL FAN',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 14650,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 15383
    },
    {
      id: 960,
      name: 'GEFORCE GTX 1660 SUPER PALIT GAMING PRO 6GB DUAL FAN',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 13795,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 14485
    },
    {
      id: 906,
      name: 'GEFORCE RTX 3050 GIGABYTE EAGLE 8GB DUAL FAN LHR',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 18832,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 19774
    },
    {
      id: 784,
      name: 'GEFORCE RTX 3060 GALAX 1 CLICK OC 12GB DUAL FAN LHR',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 23950,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 5,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 25148
    },
    {
      id: 394,
      name: 'GEFORCE RTX 3060 MSI VENTUS 2X 12G OC LHR',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 26255,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 27568
    },
    {
      id: 423,
      name: 'GEFORCE RTX 3060 ZOTAC TWIN EDGE OC 12GB DUAL FAN LHR',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 22995,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 6,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 24145
    },
    {
      id: 386,
      name: 'GEFORCE RTX 3060TI IGAME ULTRA W OC 8GB TRIPLE FAN LHR WHITE',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 29750,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 31238
    },
    {
      id: 408,
      name: 'GEFORCE RTX 3060TI PALIT 8GB DUAL FAN RGB LHR',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 28050,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 29453
    },
    {
      id: 510,
      name: 'GEFORCE RTX 3070 PALIT GAMING PRO 8GB TRIPLE FAN RGB LHR',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 33870,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 35564
    },
    {
      id: 340,
      name: 'GEFORCE RTX 3080 ZOTAC TRINITY OC 10GB TRIPLE FAN RGB LHR',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 43995,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 46195
    },
    {
      id: 965,
      name: 'GEFORCE RTX 4090 SG GALAX 24GB TRIPLE FAN RGB',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 112300,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 117915
    },
    {
      id: 990,
      name: 'RADEON AXRX 6600 POWERCOLOR FIGHTER 8GB GDDR6 DUAL FAN',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 13795,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 21,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 14485
    },
    {
      id: 984,
      name: 'RADEON AXRX 6700XT POWERCOLOR RED DEVIL 12GB GDDR6 OC TRIPLE FAN',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 23950,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 15,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 25148
    },
    {
      id: 993,
      name: 'RADEON RX 6650XT GIGABYTE GAMING OC 8GB TRIPLE FAN',
      ram_kit: undefined,
      category_id: 21,
      promo_price: 19990,
      is_hidden_via_product: 0,
      category_name: 'GPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 20990
    },
    {
      id: 935,
      name:
        'AMD RYZEN 5 4600G MPK (TRAY TYPE) W/ PC COOLER E126PRO CPU COOLER HALO SRGB (1YR WARRANTY)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 7025,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 7376
    },
    {
      id: 613,
      name: 'AMD RYZEN 5 5500 (AM4)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 7495,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 9,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 7870
    },
    {
      id: 620,
      name: 'AMD RYZEN 5 5600 (AM4)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 9495,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 7,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 9970
    },
    {
      id: 35,
      name: 'AMD RYZEN 5 5600G (AM4)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 9450,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 9923
    },
    {
      id: 936,
      name:
        'AMD RYZEN 5 5600X MPK (TRAY TYPE) W/ PC COOLER E126PRO CPU COOLER HALO SRGB (1YR WARRANTY)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 10350,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 10868
    },
    {
      id: 989,
      name: 'AMD RYZEN 5 7600X (AM5) W/O COOLER',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 18400,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 19320
    },
    {
      id: 191,
      name: 'AMD RYZEN 7 5700G (AM4)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 15045,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 15797
    },
    {
      id: 601,
      name: 'AMD RYZEN 7 5700X (AM4)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 14745,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 15482
    },
    {
      id: 730,
      name: 'AMD RYZEN 7 5800X3D (AM4)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 20495,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 21520
    },
    {
      id: 951,
      name: 'AMD RYZEN 7 7700X (AM5) W/O COOLER',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 24850,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 26093
    },
    {
      id: 605,
      name: 'AMD RYZEN 9 5900X (AM4)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 25245,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 26507
    },
    {
      id: 940,
      name: 'AMD RYZEN 9 7900X (AM5) W/O COOLER',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 33750,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 35438
    },
    {
      id: 939,
      name: 'AMD RYZEN 9 7950X (AM5) W/O COOLER',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 42650,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 44783
    },
    {
      id: 538,
      name: 'INTEL CORE I5 10400F',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 8575,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 9004
    },
    {
      id: 249,
      name: 'INTEL CORE I5 11400F (LGA1200)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 9795,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 10285
    },
    {
      id: 414,
      name: 'INTEL CORE I5 12400 (LGA1700)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 12695,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 13330
    },
    {
      id: 341,
      name: 'INTEL CORE I5 12600KF',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 16150,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 16958
    },
    {
      id: 1003,
      name: 'INTEL CORE I5 13600KF (LGA1700)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 18595,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 19525
    },
    {
      id: 890,
      name: 'INTEL CORE I7 12700 (LGA1700)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 22045,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 23147
    },
    {
      id: 415,
      name: 'INTEL CORE I7 12700F (LGA1700)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 20945,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 21992
    },
    {
      id: 229,
      name: 'INTEL CORE I7 12700K (LGA1700)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 26195,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 27505
    },
    {
      id: 246,
      name: 'INTEL CORE I7 12700KF (LGA1700)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 24495,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 25720
    },
    {
      id: 1008,
      name: 'INTEL CORE I7 13700K (LGA1700)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 26980,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 28329
    },
    {
      id: 995,
      name: 'INTEL CORE I7 13700KF (LGA1700)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 24995,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 26245
    },
    {
      id: 358,
      name: 'INTEL CORE I9 12900K (LGA1700)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 35695,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 37480
    },
    {
      id: 992,
      name: 'INTEL CORE I9 13900K (LGA1700)',
      ram_kit: undefined,
      category_id: 16,
      promo_price: 37490,
      is_hidden_via_product: 0,
      category_name: 'CPU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 39365
    },
    {
      id: 532,
      name: 'ASROCK B450M STEEL LEGEND DDR4 (MATX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 5595,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5875
    },
    {
      id: 743,
      name: 'ASROCK B550 PG RIPTIDE (ATX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 8045,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8447
    },
    {
      id: 261,
      name: 'ASROCK B550M PHANTOM GAMING 4 (MATX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 5400,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5670
    },
    {
      id: 260,
      name: 'ASROCK B550M PRO4 DDR4 (MATX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 5700,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 14,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 5985
    },
    {
      id: 51,
      name: 'ASROCK B550M STEEL LEGEND (MATX)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 7600,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 7980
    },
    {
      id: 929,
      name: 'ASROCK B660M STEEL LEGEND DDR4 (MATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 9000,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 9450
    },
    {
      id: 1014,
      name: 'ASUS ROG MAXIMUS Z790 HERO DDR5 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 41825,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 43916
    },
    {
      id: 363,
      name: 'ASUS ROG STRIX B550-A GAMING (ATX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 11295,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 11860
    },
    {
      id: 726,
      name: 'ASUS ROG STRIX B550-I GAMING DDR4 (ITX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 12495,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 13120
    },
    {
      id: 593,
      name: 'ASUS ROG STRIX B660 F GAMING WIFI DDR5 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 14095,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 14800
    },
    {
      id: 715,
      name: 'ASUS ROG STRIX Z690-G GAMING WIFI DDR5 (MATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 18300,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 19215
    },
    {
      id: 1015,
      name: 'ASUS ROG STRIX Z790-E GAMING WIFI DDR5 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 33225,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 34886
    },
    {
      id: 1013,
      name: 'ASUS TUF GAMING Z790-PLUS WIFI DDR4 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 19825,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 20816
    },
    {
      id: 996,
      name: 'GIGABYTE B450M DS3H WIFI DDR4 (MATX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 5125,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 9,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 5381
    },
    {
      id: 619,
      name: 'GIGABYTE B550I AORUS PRO AX (ITX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 10265,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 10778
    },
    {
      id: 982,
      name: 'GIGABYTE B650 AERO G DDR5 (ATX/AM5)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 20680,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 21714
    },
    {
      id: 374,
      name: 'GIGABYTE B660M DS3H AX DDR4 (MATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 7695,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8080
    },
    {
      id: 557,
      name: 'GIGABYTE H510M H (M-ATX)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 4195,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 5,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 4405
    },
    {
      id: 416,
      name: 'GIGABYTE H610M H DDR4 (MATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 5295,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5560
    },
    {
      id: 942,
      name: 'GIGABYTE X670E AORUS MASTER (ATX/AM5)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 28495,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 29920
    },
    {
      id: 997,
      name: 'GIGABYTE Z490I AORUS ULTRA W/ WIFI (ITX/LGA1200)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 8195,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8605
    },
    {
      id: 375,
      name: 'GIGABYTE Z690 AORUS ELITE AX DDR4 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 15245,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 16007
    },
    {
      id: 393,
      name: 'GIGABYTE Z690 UD AX DDR4 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 12945,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 13592
    },
    {
      id: 1004,
      name: 'GIGABYTE Z790 AORUS ELITE AX DDR5 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 18975,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 19924
    },
    {
      id: 923,
      name: 'MSI B550M MORTAR MAX WIFI (MATX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 9375,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 9844
    },
    {
      id: 314,
      name: 'MSI B550M PRO VDH WIFI (MATX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 6695,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 8,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 7030
    },
    {
      id: 968,
      name: 'MSI MAG B550 TOMAHAWK MAX WIFI DDR4 (ATX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 10745,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 11282
    },
    {
      id: 988,
      name: 'MSI MAG B650 TOMAHAWK WIFI (ATX/AM5)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 16285,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 17099
    },
    {
      id: 547,
      name: 'MSI MAG X570S TOMAHAWK MAX WIFI (ATX/AM4)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 14095,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 14800
    },
    {
      id: 1000,
      name: 'MSI MAG Z790 TOMAHAWK WIFI DDR4 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 19838,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 20830
    },
    {
      id: 999,
      name: 'MSI MPG Z790 CARBON WIFI DDR5 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 29595,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 31075
    },
    {
      id: 1009,
      name: 'MSI MPG Z790 EDGE WIFI DDR4 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 22890,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 24035
    },
    {
      id: 952,
      name: 'MSI PRO X670-P WIFI (AM5/DDR5)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 18700,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 19635
    },
    {
      id: 1010,
      name: 'MSI PRO Z790-P WIFI DDR4 (ATX/LGA1700)',
      ram_kit: undefined,
      category_id: 17,
      promo_price: 14610,
      is_hidden_via_product: 0,
      category_name: 'MOTHERBOARD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 15341
    },
    {
      id: 584,
      name: 'ADATA XPG SPECTRIX D50 8X2 16GB 3200MHZ DDR4 WHITE',
      ram_kit: 2,
      category_id: 13,
      promo_price: 4000,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 5,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 4200
    },
    {
      id: 961,
      name: 'ADATA XPG SPECTRIX D50 8X2 16GB 3600MHZ DDR4 WHITE',
      ram_kit: 2,
      category_id: 13,
      promo_price: 4300,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 16,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 4515
    },
    {
      id: 835,
      name: 'CORSAIR DOMINATOR PLATINUM RGB 16X2=32GB 5200MHZ DDR5 BLACK',
      ram_kit: 2,
      category_id: 13,
      promo_price: 15050,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 15803
    },
    {
      id: 836,
      name: 'CORSAIR DOMINATOR PLATINUM RGB 16X2=32GB 5200MHZ DDR5 WHITE',
      ram_kit: 2,
      category_id: 13,
      promo_price: 15050,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 15803
    },
    {
      id: 756,
      name: 'CORSAIR DOMINATOR PLATINUM RGB 16X2=32GB 5600MHZ DDR5 BLACK',
      ram_kit: 2,
      category_id: 13,
      promo_price: 16240,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 17052
    },
    {
      id: 755,
      name: 'CORSAIR DOMINATOR PLATINUM RGB 16X2=32GB 5600MHZ DDR5 WHITE',
      ram_kit: 2,
      category_id: 13,
      promo_price: 16240,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 17052
    },
    {
      id: 111,
      name: 'G-SKILL TRIDENT Z NEO 8X2=16GB 3600MHZ  RGB DDR4',
      ram_kit: 2,
      category_id: 13,
      promo_price: 5350,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 8,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 5618
    },
    {
      id: 758,
      name: 'G-SKILL TRIDENT Z ROYAL GOLD RGB 16X2= 32GB 3600MHZ DDR4',
      ram_kit: 2,
      category_id: 13,
      promo_price: 10496,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 11021
    },
    {
      id: 750,
      name: 'G-SKILL TRIDENT Z ROYAL GOLD RGB 8X2= 16GB 3600MHZ DDR4',
      ram_kit: 2,
      category_id: 13,
      promo_price: 6900,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 7245
    },
    {
      id: 664,
      name: 'G-SKILL TRIDENT Z ROYAL SILVER RGB 16X2= 32GB  3600MHZ DDR4',
      ram_kit: 2,
      category_id: 13,
      promo_price: 9744,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 10231
    },
    {
      id: 917,
      name: 'G-SKILL TRIDENT Z5 16X2= 32GB 5600MHZ DDR5 SILVER',
      ram_kit: 2,
      category_id: 13,
      promo_price: 13550,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 14228
    },
    {
      id: 915,
      name: 'G-SKILL TRIDENT Z5 RGB 16X2= 32GB 6000MHZ DDR5 SILVER',
      ram_kit: 2,
      category_id: 13,
      promo_price: 15450,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 16223
    },
    {
      id: 981,
      name: 'GALAX HOF (HALL OF FAME) 8X2 = 16GB 3600MHZ DDR4 WHITE',
      ram_kit: 2,
      category_id: 13,
      promo_price: 4996,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5246
    },
    {
      id: 19,
      name: 'KINGSTON FURY 2X8GB = 16GB 3200MHZ RGB DDR4',
      ram_kit: 2,
      category_id: 13,
      promo_price: 4300,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 18,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 4515
    },
    {
      id: 567,
      name: 'RAMSTA 4GB 2666MHZ DDR4',
      ram_kit: 1,
      category_id: 13,
      promo_price: 770,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 6,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 809
    },
    {
      id: 389,
      name: 'TEAM GROUP ZEUS 8GB 3200MHZ DDR4',
      ram_kit: 1,
      category_id: 13,
      promo_price: 1795,
      is_hidden_via_product: 0,
      category_name: 'RAM',
      is_hidden_via_category: 0,
      quantity: 16,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1885
    },
    {
      id: 924,
      name: 'CRUCIAL CT1000P3PSSD8 P3 PLUS 1TB NVME GEN 4',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 5550,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5828
    },
    {
      id: 1001,
      name: 'CRUCIAL CT1000P3SSD8 P3 1TB NVME GEN 3',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 4745,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 12,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 4982
    },
    {
      id: 139,
      name: 'CRUCIAL CT250P2SSD8 \t250GB NVME GEN 3',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 1895,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 6,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1990
    },
    {
      id: 1002,
      name: 'CRUCIAL CT500P3SSD8 P3 500GB NVME GEN 3',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 2415,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 31,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2536
    },
    {
      id: 660,
      name: 'KINGSTON NV1 NVME 1TB GEN 3',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 4860,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5103
    },
    {
      id: 760,
      name: 'KINGSTON NV1 NVME 2TB GEN 3',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 8375,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 8,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 8794
    },
    {
      id: 210,
      name: 'KINGSTON NV1 NVME 500GB GEN 3',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 2775,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2914
    },
    {
      id: 823,
      name: 'MSI SPATIUM M450 NVME 500GB GEN4',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 3775,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 3964
    },
    {
      id: 933,
      name: 'RAMSTA 128GB SATA SSD 2.5"',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 795,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 835
    },
    {
      id: 934,
      name: 'RAMSTA S800 512GB SSD 2.5"',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 1895,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1990
    },
    {
      id: 143,
      name: 'SAMSUNG 970 EVO PLUS 1TB NVME GEN 3',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 7980,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8379
    },
    {
      id: 468,
      name: 'WESTERN DIGITAL BLACK SN850 500GB NVME GEN 4',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 6250,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 6563
    },
    {
      id: 852,
      name: 'WESTERN DIGITAL WD GREEN  NVME SSD 240GB',
      ram_kit: undefined,
      category_id: 14,
      promo_price: 1995,
      is_hidden_via_product: 0,
      category_name: 'SSD',
      is_hidden_via_category: 0,
      quantity: 10,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2095
    },
    {
      id: 943,
      name: 'ASUS ROG THOR 1200P2 80+ PLATINUM FULL MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 20400,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 21420
    },
    {
      id: 477,
      name: 'COOLER MASTER MWE 750W 80+ BRONZE',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 4000,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 9,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 4200
    },
    {
      id: 669,
      name: 'COOLER MASTER MWE V2 850W 80+ GOLD FULLY MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 6800,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 7140
    },
    {
      id: 499,
      name: 'COOLER MASTER V SFX  750W 80+ GOLD FULL MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 8000,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8400
    },
    {
      id: 500,
      name: 'COOLER MASTER V SFX  850W 80+ GOLD FULL MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 8495,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8920
    },
    {
      id: 667,
      name: 'COOLERMASTER MWE V2 650W 80+ GOLD FULLY MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 5600,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 6,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 5880
    },
    {
      id: 969,
      name: 'COOLERMASTER V750 V2 750W 80+ GOLD FULL MODULAR WHITE',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 7625,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8006
    },
    {
      id: 970,
      name: 'COOLERMASTER V850 V2 850W 80+ GOLD FULL MODULAR WHITE',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 8185,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8594
    },
    {
      id: 884,
      name: 'CORSAIR CP-9020139-NA HX1000W 80+ PLATINUM FULL MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 13295,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 13960
    },
    {
      id: 912,
      name: 'CORSAIR CP-9020182-NA SF600 80+ PLATINUM FULL MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 7995,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8395
    },
    {
      id: 913,
      name: 'CORSAIR CP-9020186-NA SF750 80+ PLATINUM FULL MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 8895,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 9340
    },
    {
      id: 883,
      name: 'GIGABYTE AORUS GP-AP1200PM 1200W 80+ PLATINUM FULL MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 18590,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 19520
    },
    {
      id: 882,
      name: 'GIGABYTE UD850GM 850WATTS 80 PLUS GOLD FULLY MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 6463,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 6786
    },
    {
      id: 848,
      name: 'MSI MAG A550BN 550 WATTS 80+ BRONZE',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 2645,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 2777
    },
    {
      id: 64,
      name: 'SEASONIC SSR-650GB3 S12 III 650W 80+ BRONZE',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 3500,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 23,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 3675
    },
    {
      id: 66,
      name: 'SEASONIC SSR-750 FX FOCUS PLUS 750W 80+ GOLD MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 6350,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 6668
    },
    {
      id: 68,
      name: 'SEASONIC SSR-850FX FOCUS PLUS 850W 80+ GOLD MODULAR',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 7400,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 7770
    },
    {
      id: 878,
      name: 'XIGMATEK X-POWER 500W 120MM FAN, 80 PLUS',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 1895,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1990
    },
    {
      id: 839,
      name: 'XIGMATEK X-POWER 600W 120MM FAN, 80 PLUS',
      ram_kit: undefined,
      category_id: 18,
      promo_price: 2050,
      is_hidden_via_product: 0,
      category_name: 'PSU',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2153
    },
    {
      id: 268,
      name: 'ASUS TUF GAMING CASING GT 501 WHITE EDITION 3X RGB FAN/1 FAN',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 8415,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8836
    },
    {
      id: 692,
      name: 'COOLERMASTER HAF 500 WHITE (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 6200,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 6510
    },
    {
      id: 768,
      name: 'COOLERMASTER MASTERBOX NR200P BLACK (ITX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 5300,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5565
    },
    {
      id: 769,
      name: 'COOLERMASTER MASTERBOX NR200P WHITE (ITX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 5880,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 6174
    },
    {
      id: 971,
      name: 'COOLERMASTER MASTERBOX TD300 MESH BLACK 2PCS ARGB FANS (MATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 4195,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 4405
    },
    {
      id: 972,
      name: 'COOLERMASTER MASTERBOX TD300 MESH WHITE 2PCS ARGB FANS (MATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 4255,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 4468
    },
    {
      id: 967,
      name: 'COOLERMASTER MASTERBOX TD500 MESH BLACK 3PCS ARGB FANS (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 5600,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5880
    },
    {
      id: 661,
      name: 'COOLERMASTER MASTERBOX TD500 MESH WHITE 3PCS ARGB FANS (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 5800,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 6090
    },
    {
      id: 528,
      name:
        'CORSAIR 5000D AIRFLOW TEMPERED GLASS CASE 2PCS REGULAR FANS (BLACK) (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 9930,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 10427
    },
    {
      id: 802,
      name:
        'CORSAIR 5000D TEMPERED GLASS MID TOWER (ATX) (WHITE) 2PCS REGULAR FANS',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 10428,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 10949
    },
    {
      id: 858,
      name:
        'CORSAIR ICUE 4000X RGB C5-CC-9011205-WW TEMPERED GLASS MID TOWER ATX CASE WHITE',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 8615,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 9046
    },
    {
      id: 857,
      name: 'CORSAIR ICUE 4000X RGB TEMPERED GLASS MID TOWER ATX CASE BLACK',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 8220,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8631
    },
    {
      id: 525,
      name: 'DARK FLASH DK351 BLACK 4PCS ARGB FANS (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 2330,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 9,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2447
    },
    {
      id: 526,
      name: 'DARK FLASH DK351 WHITE 4PCS ARGB FANS (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 2495,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 18,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2620
    },
    {
      id: 896,
      name: 'DARK FLASH DK352 MESH BLACK 4PCS ARGB FANS (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 2400,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 2520
    },
    {
      id: 897,
      name: 'DARK FLASH DK352 MESH WHITE 4PCS ARGB FANS (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 2580,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 2709
    },
    {
      id: 488,
      name: 'DARK FLASH DLH21 WHITE (ITX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 1495,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1570
    },
    {
      id: 485,
      name: 'DARK FLASH DLM21 BLACK (M-ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 2160,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 7,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2268
    },
    {
      id: 486,
      name: 'DARK FLASH DLM21 WHITE (M-ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 2250,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 8,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2363
    },
    {
      id: 855,
      name: 'DEEPCOOL MATREXX30',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 1620,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1701
    },
    {
      id: 708,
      name: 'DEEPCOOL MATREXX50 MESH 4FS BLACK W/ 4PCS STATIC RGB FANS (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 3300,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 3465
    },
    {
      id: 977,
      name: 'GALAX REVOLUTION 05 REV-05W WHITE W/ 4PCS FAN',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 3050,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 3203
    },
    {
      id: 476,
      name: 'GALAX REVOLUTION-03 WHITE ITX (FOR MINI BUILD)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 3500,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 3675
    },
    {
      id: 764,
      name: 'HYTE Y60 BLACK (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 10250,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 10763
    },
    {
      id: 762,
      name: 'HYTE Y60 BW BLACK/WHITE W/ 3PCS REGULAR FANS (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 11424,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 11995
    },
    {
      id: 950,
      name: 'INPLAY LITE X9 W/ GP200B PSU (M-ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 995,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 16,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1045
    },
    {
      id: 901,
      name: 'LIAN LI O11 DYNAMIC MINI SNOW WHITE (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 7440,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 7812
    },
    {
      id: 904,
      name: 'LIAN LI O11DXL-W O11 DYNAMIC XL (ROG) WHITE (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 12745,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 13382
    },
    {
      id: 930,
      name: 'MECH ARMOR GT-I IRON BLACK (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 3350,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 7,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 3518
    },
    {
      id: 931,
      name: 'MECH ARMOR GT-I IRON WHITE (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 3450,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 7,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 3623
    },
    {
      id: 705,
      name: 'MSI MAG FORGE M100R W/ 4 ARGB FANS (M-ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 2980,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 3129
    },
    {
      id: 654,
      name: 'MSI MPG GUNGNIR 110R WHITE W/ 4PCS ARGB FANS (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 5760,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 6048
    },
    {
      id: 777,
      name: 'NZXT H510 ELITE BLACK/BLACK W/ 2PCS ARGB AND 2PCS REGULAR (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 8820,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 9261
    },
    {
      id: 927,
      name: 'NZXT H7 ELITE BLACK W/ 3PCS RGB AND 1PC REGULAR FAN (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 11760,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 12348
    },
    {
      id: 925,
      name: 'NZXT H7 FLOW BLACK/BLACK W/ 2PCS REGULAR FAN (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 7800,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8190
    },
    {
      id: 926,
      name: 'NZXT H7 FLOW WHITE/WHITE W/ 2PCS REGULAR FAN (ATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 7800,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 8190
    },
    {
      id: 920,
      name: 'POWERLOGIC MA06 W/ P700W PSU (MATX)',
      ram_kit: undefined,
      category_id: 20,
      promo_price: 1495,
      is_hidden_via_product: 0,
      category_name: 'CASING',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1570
    },
    {
      id: 838,
      name: 'AOC 24G2SE 23.8" GAMING MONITOR 165HZ / 1MS FHD VA',
      ram_kit: undefined,
      category_id: 25,
      promo_price: 8495,
      is_hidden_via_product: 0,
      category_name: 'MONITOR',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 8920
    },
    {
      id: 914,
      name: 'ASUS VP249QGR 23.8" FHD, 144HZ FREE-SYNC GAMING MONITOR',
      ram_kit: undefined,
      category_id: 25,
      promo_price: 9495,
      is_hidden_via_product: 0,
      category_name: 'MONITOR',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 9970
    },
    {
      id: 918,
      name: 'FUZION FN-22EL 21.5" LED MONITOR 75HZ 1680X1050 16:10 RATIO',
      ram_kit: undefined,
      category_id: 25,
      promo_price: 4195,
      is_hidden_via_product: 0,
      category_name: 'MONITOR',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 4405
    },
    {
      id: 975,
      name:
        'GALAX PRISMA PR-01 24" FHD LED FRAMELESS 75HZ USB TYPE-C 1080P MONITOR G-SYNC',
      ram_kit: undefined,
      category_id: 25,
      promo_price: 6930,
      is_hidden_via_product: 0,
      category_name: 'MONITOR',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 7277
    },
    {
      id: 976,
      name: 'GALAX VIVANCE VI-01 27" 165HZ QHD IPS FRAMELESS G-SYNC',
      ram_kit: undefined,
      category_id: 25,
      promo_price: 18590,
      is_hidden_via_product: 0,
      category_name: 'MONITOR',
      is_hidden_via_category: 0,
      quantity: 6,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 19520
    },
    {
      id: 974,
      name: 'GALAX VIVANCE VI-02 24" IPS FHD FRAMELESS GSYNC 165HZ SRGB',
      ram_kit: undefined,
      category_id: 25,
      promo_price: 9999,
      is_hidden_via_product: 0,
      category_name: 'MONITOR',
      is_hidden_via_category: 0,
      quantity: 9,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 10499
    },
    {
      id: 986,
      name:
        'GAMDIAS ATLAS HD238F 23.8" 165HZ FRAMELESS LED GAMING MONITOR BLACK',
      ram_kit: undefined,
      category_id: 25,
      promo_price: 5495,
      is_hidden_via_product: 0,
      category_name: 'MONITOR',
      is_hidden_via_category: 0,
      quantity: 33,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 5770
    },
    {
      id: 937,
      name:
        'GAMDIAS ATLAS VHW 215F 21.5 75HZ FRAMELESS LED GAMING MONITOR WHITE',
      ram_kit: undefined,
      category_id: 25,
      promo_price: 4350,
      is_hidden_via_product: 0,
      category_name: 'MONITOR',
      is_hidden_via_category: 0,
      quantity: 21,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 4568
    },
    {
      id: 465,
      name: 'GIGABYTE GP-G27F-AP 27" 144HZ FHD IPS FRAMELESS FREE-SYNC',
      ram_kit: undefined,
      category_id: 25,
      promo_price: 13850,
      is_hidden_via_product: 0,
      category_name: 'MONITOR',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 14543
    },
    {
      id: 451,
      name: 'AORUS WATERFORCE X360 DIGITAL SCREEN 360MM LIQUID COOLER',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 13740,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 14427
    },
    {
      id: 843,
      name: 'ARCTIC FREEZER 34 ESPORTS DUO BLACK/WHITE',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 2690,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 2825
    },
    {
      id: 844,
      name: 'ARCTIC FREEZER 34 ESPORTS DUO GREY/WHITE',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 2990,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 3140
    },
    {
      id: 846,
      name:
        'ARCTIC FREEZER 34 ESPORTS DUO LGA 1700 UPGRADE KIT (FREE FOR ARCTIC FREEZER 34) (HIDDEN VIA PRODUCT)',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 999999,
      is_hidden_via_product: 1,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 21,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1049999
    },
    {
      id: 957,
      name: 'ARCTIC LIQUID FREEZER II 360 A-RGB (BLACK)',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 6995,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 7345
    },
    {
      id: 958,
      name:
        'ARCTIC LIQUID FREEZER II INTEL LGA 1700 UPGRADE KIT (FREE FOR ARTIC LIQUID FREEZER II ONLY) (HIDDEN VIA PRODUCT)',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 999999,
      is_hidden_via_product: 1,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1049999
    },
    {
      id: 803,
      name: 'ASUS OP FAN ROG RYUJIN II 240 ARGB',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 16320,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 17136
    },
    {
      id: 607,
      name:
        'COOLER MASTER INTEL LGA 1700 MOUNTING KIT FOR LIQUID COOLER (FREE FOR COOLER MASTER ML240) (HIDDEN VIA PRODUCT)',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 999999,
      is_hidden_via_product: 1,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 26,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1049999
    },
    {
      id: 105,
      name: 'COOLER MASTER ML240L ARGB V2 BLACK',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 4800,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5040
    },
    {
      id: 185,
      name: 'COOLER MASTER ML240L ARGB V2 WHITE',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 5520,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 7,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 5796
    },
    {
      id: 626,
      name:
        'CORSAIR ICUE H100I ELITE LCD DISPLAY LIQUID CPU COOLER (240MM, BLACK )',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 14736,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 15473
    },
    {
      id: 899,
      name: 'CORSAIR ICUE H150I ELITE CAPELLIX LIQUID CPU COOLER (BLACK)',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 10752,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 11290
    },
    {
      id: 627,
      name:
        'CORSAIR ICUE H150I ELITE LCD DISPLAY LIQUID CPU COOLER (360MM, BLACK)',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 16260,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 17073
    },
    {
      id: 548,
      name: 'DARK FLASH DX240V2 BLACK LIQUID COOLER',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 3600,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 3780
    },
    {
      id: 648,
      name: 'DARK FLASH DX240V2 WHITE LIQUID COOLER',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 3840,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 4032
    },
    {
      id: 894,
      name: 'DEEPCOOL R-AK620-BKNNMT AK620 BLACK',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 3575,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 3754
    },
    {
      id: 810,
      name: 'MSI MPG CORELIQUID K240',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 10800,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 11340
    },
    {
      id: 811,
      name: 'MSI MPG CORELIQUID K360',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 13200,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 13860
    },
    {
      id: 520,
      name: 'NZXT KRAKEN Z53 RGB DIGITAL SCREEN 240MM BLACK LIQUID COOLER',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 11995,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 12595
    },
    {
      id: 578,
      name: 'NZXT KRAKEN Z73 DIGITAL SCREEN 360MM BLACK LIQUID COOLER',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 14700,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 15435
    },
    {
      id: 558,
      name: 'NZXT KRAKEN Z73 DIGITAL SCREEN 360MM WHITE LIQUID COOLER',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 14700,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 15435
    },
    {
      id: 640,
      name: 'XIGMATEK AIR-KILLER PRO ARCTIC',
      ram_kit: undefined,
      category_id: 26,
      promo_price: 2160,
      is_hidden_via_product: 0,
      category_name: 'CPU COOLER',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 2268
    },
    {
      id: 888,
      name: 'ARCTIC P12 PWM PST A-RGB 0DB 120MM 3PCS VALUE PACK (BLACK)',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 2345,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2462
    },
    {
      id: 889,
      name: 'ARCTIC P12 PWM PST A-RGB 0DB 120MM 3PCS VALUE PACK (WHITE)',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 2395,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 6,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2515
    },
    {
      id: 886,
      name: 'ARCTIC P12 PWM PST A-RGB 0DB ARGB 120MM SINGLE FAN (BLACK)',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 850,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 10,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 893
    },
    {
      id: 887,
      name: 'ARCTIC P12 PWM PST A-RGB 0DB ARGB 120MM SINGLE FAN (WHITE)',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 900,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 9,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 945
    },
    {
      id: 681,
      name:
        'CORSAIR LL120 RGB 120MM DUAL LIGHT LOOP WHITE RGB LED PWM SINGLE FAN',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 1860,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 5,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1953
    },
    {
      id: 683,
      name:
        'CORSAIR LL120 RGB 120MM DUAL LIGHT LOOP WHITE RGB LED PWM TRIPLE PACK',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 6540,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 6867
    },
    {
      id: 682,
      name:
        'CORSAIR ML120 RGB ELITE PREMIUM 120MM PWM MAGNETIC LEVITATION SINGLE FAN BLACK',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 1960,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 12,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2058
    },
    {
      id: 825,
      name:
        'CORSAIR ML120 RGB ELITE PREMIUM 120MM PWM MAGNETIC LEVITATION SINGLE FAN WHITE',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 2060,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 8,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 2163
    },
    {
      id: 684,
      name:
        'CORSAIR ML120 RGB ELITE PREMIUM 120MM PWM TRIPLE FAN KIT W/ LIGHTING NODE CORE BLACK',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 6984,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 7333
    },
    {
      id: 826,
      name:
        'CORSAIR ML120 RGB ELITE PREMIUM 120MM PWM TRIPLE FAN KIT W/ LIGHTING NODE CORE WHITE',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 6660,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 6993
    },
    {
      id: 490,
      name: 'DARK FLASH C6 3 IN 1 FAN KIT BLACK',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 830,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 16,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 872
    },
    {
      id: 521,
      name: 'DARK FLASH C6 3 IN 1 FAN KIT PINK',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 830,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 872
    },
    {
      id: 491,
      name: 'DARK FLASH C6 3 IN 1 FAN KIT WHITE',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 830,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 6,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 872
    },
    {
      id: 492,
      name: 'DARK FLASH C6 SINGLE FAN BLACK',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 260,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 53,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 273
    },
    {
      id: 522,
      name: 'DARK FLASH C6 SINGLE FAN PINK',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 260,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 273
    },
    {
      id: 493,
      name: 'DARK FLASH C6 SINGLE FAN WHITE',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 260,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 36,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 273
    },
    {
      id: 71,
      name: 'INPLAY ICE ONE RGB FAN  (EXTRA FAN FOR ICE TOWER) 120MM',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 150,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 83,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 158
    },
    {
      id: 72,
      name: 'INPLAY ICE TOWER V2 3 IN 1 FAN KIT',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 700,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 34,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 735
    },
    {
      id: 862,
      name: 'INPLAY LED FAN COLOR BLUE',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 100,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 42,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 105
    },
    {
      id: 237,
      name: 'INPLAY LED FAN COLOR RED',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 100,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 105
    },
    {
      id: 651,
      name: 'LIAN LI UNI FAN AL 120 SINGLE FAN BLACK',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 1380,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 9,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1449
    },
    {
      id: 517,
      name: 'LIAN LI UNI FAN AL 120 SINGLE FAN WHITE',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 1380,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 8,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1449
    },
    {
      id: 579,
      name: 'LIAN LI UNI FAN AL 120 TRIPLE FAN BLACK WITH CONTROLLER',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 5052,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5305
    },
    {
      id: 516,
      name: 'LIAN LI UNI FAN AL 120 TRIPLE FAN WHITE WITH CONTROLLER',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 5052,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 5305
    },
    {
      id: 738,
      name: 'NZXT AER RGB 2 - 120MM SINGLE FAN BLACK',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 1512,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1588
    },
    {
      id: 796,
      name: 'NZXT AER RGB 2 - 140MM SINGLE FAN BLACK',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 1656,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1739
    },
    {
      id: 240,
      name: 'PC COOLER HALO FAN FRGB 3 IN 1 KIT RGB BLACK 120MM',
      ram_kit: undefined,
      category_id: 19,
      promo_price: 1095,
      is_hidden_via_product: 0,
      category_name: 'FAN',
      is_hidden_via_category: 0,
      quantity: 11,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1150
    },
    {
      id: 983,
      name: 'PROLINK DL-7203E 4G LTE MOBILE WI-FI',
      ram_kit: undefined,
      category_id: 46,
      promo_price: 3060,
      is_hidden_via_product: 0,
      category_name: 'MOBILE WIFI',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 3213
    },
    {
      id: 873,
      name: 'KINGSTON DTX 128GB USB 3.2',
      ram_kit: undefined,
      category_id: 41,
      promo_price: 950,
      is_hidden_via_product: 0,
      category_name: 'FLASH DRIVE',
      is_hidden_via_category: 0,
      quantity: 11,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 998
    },
    {
      id: 871,
      name: 'KINGSTON DTX 32GB USB 3.2',
      ram_kit: undefined,
      category_id: 41,
      promo_price: 320,
      is_hidden_via_product: 0,
      category_name: 'FLASH DRIVE',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 336
    },
    {
      id: 872,
      name: 'KINGSTON DTX 64GB USB 3.2',
      ram_kit: undefined,
      category_id: 41,
      promo_price: 650,
      is_hidden_via_product: 0,
      category_name: 'FLASH DRIVE',
      is_hidden_via_category: 0,
      quantity: 9,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 683
    },
    {
      id: 847,
      name:
        'BOYA BY WM3D 2.4GHZ WIRELESS MICROPHONE (HIDDEN VIA PRODUCT & CATEGORY)',
      ram_kit: undefined,
      category_id: 40,
      promo_price: 4193,
      is_hidden_via_product: 1,
      category_name: 'MICROPHONE (HIDDEN CATEGORY)',
      is_hidden_via_category: 1,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 4403
    },
    {
      id: 973,
      name: 'SERVICE CHARGE 50 (HIDDEN VIA CATEGORY)',
      ram_kit: undefined,
      category_id: 35,
      promo_price: 50,
      is_hidden_via_product: 0,
      category_name: 'SERVICE (HIDDEN CATEGORY)',
      is_hidden_via_category: 1,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 53
    },
    {
      id: 653,
      name: 'SERVICE CHARGE 500 (HIDDEN VIA PRODUCT & CATEGORY)',
      ram_kit: undefined,
      category_id: 35,
      promo_price: 500,
      is_hidden_via_product: 1,
      category_name: 'SERVICE (HIDDEN CATEGORY)',
      is_hidden_via_category: 1,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 525
    },
    {
      id: 721,
      name: 'KINGSTON FURY KEYBOARD BRUSH (FREEBIES) (HIDDEN VIA CATEGORY)',
      ram_kit: undefined,
      category_id: 32,
      promo_price: 999999,
      is_hidden_via_product: 0,
      category_name: 'MARKETING MATERIALS (HIDDEN CATEGORY)',
      is_hidden_via_category: 1,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1049999
    },
    {
      id: 439,
      name: 'PROLINK WIRELESS PRESENTER PWP102G (HIDDEN VIA CATEGORY)',
      ram_kit: undefined,
      category_id: 32,
      promo_price: 999999,
      is_hidden_via_product: 0,
      category_name: 'MARKETING MATERIALS (HIDDEN CATEGORY)',
      is_hidden_via_category: 1,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1049999
    },
    {
      id: 749,
      name: '4 IN 1 FAN SPLITTER TO MOLEX',
      ram_kit: undefined,
      category_id: 30,
      promo_price: 150,
      is_hidden_via_product: 0,
      category_name: 'SPLITTER',
      is_hidden_via_category: 0,
      quantity: 5,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 158
    },
    {
      id: 279,
      name: 'COOLER MASTER ARGB SPLITTER 1 IN 3',
      ram_kit: undefined,
      category_id: 30,
      promo_price: 500,
      is_hidden_via_product: 0,
      category_name: 'SPLITTER',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 525
    },
    {
      id: 280,
      name: 'COOLER MASTER ARGB SPLITTER 1 IN 5',
      ram_kit: undefined,
      category_id: 30,
      promo_price: 720,
      is_hidden_via_product: 0,
      category_name: 'SPLITTER',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 756
    },
    {
      id: 301,
      name: 'FAN HEADER SPLITTER',
      ram_kit: undefined,
      category_id: 30,
      promo_price: 100,
      is_hidden_via_product: 0,
      category_name: 'SPLITTER',
      is_hidden_via_category: 0,
      quantity: 9,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 105
    },
    {
      id: 759,
      name: 'USB SPLITTER 1 TO 2',
      ram_kit: undefined,
      category_id: 30,
      promo_price: 350,
      is_hidden_via_product: 0,
      category_name: 'SPLITTER',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 368
    },
    {
      id: 673,
      name: 'PROLINK 40W BLACK USB TO TYPE C 480MBPS DATA FAST CHARGER',
      ram_kit: undefined,
      category_id: 27,
      promo_price: 50,
      is_hidden_via_product: 0,
      category_name: 'ACCESSORIES',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 53
    },
    {
      id: 690,
      name: 'COOLER MASTER CABLE EXTENSION KIT (BLACK/RED)',
      ram_kit: undefined,
      category_id: 23,
      promo_price: 1595,
      is_hidden_via_product: 0,
      category_name: 'CABLE WIRE',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1675
    },
    {
      id: 689,
      name: 'COOLER MASTER CABLE EXTENSION KIT (BLACK/WHITE)',
      ram_kit: undefined,
      category_id: 23,
      promo_price: 1595,
      is_hidden_via_product: 0,
      category_name: 'CABLE WIRE',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1675
    },
    {
      id: 533,
      name: 'LIAN LI STRIMER TRIPLE 8PINS (6+2)X3 (300MM)',
      ram_kit: undefined,
      category_id: 23,
      promo_price: 2800,
      is_hidden_via_product: 0,
      category_name: 'CABLE WIRE',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 2940
    },
    {
      id: 962,
      name: 'HIKVISION UTP CABLE CAT6 WHITE',
      ram_kit: undefined,
      category_id: 10,
      promo_price: 30,
      is_hidden_via_product: 0,
      category_name: 'PATCH CORD / LAN CABLE',
      is_hidden_via_category: 0,
      quantity: 272,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 32
    },
    {
      id: 955,
      name: 'ARCTIC MX-4 ACTCP00002B 4G HIGH THERMAL COMPOUND',
      ram_kit: undefined,
      category_id: 9,
      promo_price: 395,
      is_hidden_via_product: 0,
      category_name: 'THERMAL PASTE',
      is_hidden_via_category: 0,
      quantity: 3,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 415
    },
    {
      id: 1012,
      name: 'COOLER MASTER MASTER GEL PRO GRAY',
      ram_kit: undefined,
      category_id: 9,
      promo_price: 500,
      is_hidden_via_product: 0,
      category_name: 'THERMAL PASTE',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 525
    },
    {
      id: 677,
      name: 'COOLER MASTER MP511 - L',
      ram_kit: undefined,
      category_id: 5,
      promo_price: 1200,
      is_hidden_via_product: 0,
      category_name: 'MOUSE PAD',
      is_hidden_via_category: 0,
      quantity: 4,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1260
    },
    {
      id: 238,
      name: 'INPLAY REGULAR MOUSE PAD',
      ram_kit: undefined,
      category_id: 5,
      promo_price: 50,
      is_hidden_via_product: 0,
      category_name: 'MOUSE PAD',
      is_hidden_via_category: 0,
      quantity: 20,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 53
    },
    {
      id: 287,
      name: 'INPLAY STX 240 4IN1 COMBO (KEYBOARD,MOUSE,M.PAD,HEADSET)',
      ram_kit: undefined,
      category_id: 4,
      promo_price: 650,
      is_hidden_via_product: 0,
      category_name: 'KEYBOARD AND MOUSE',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 683
    },
    {
      id: 473,
      name: 'PROLINK KEYBOARD AND MOUSE PCCM2003',
      ram_kit: undefined,
      category_id: 4,
      promo_price: 550,
      is_hidden_via_product: 0,
      category_name: 'KEYBOARD AND MOUSE',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 578
    },
    {
      id: 980,
      name: 'REDRAGON S113-KN 2 IN 1 SET KEYBOARD AND MOUSE (BROWN SWITCH)',
      ram_kit: undefined,
      category_id: 4,
      promo_price: 1800,
      is_hidden_via_product: 0,
      category_name: 'KEYBOARD AND MOUSE',
      is_hidden_via_category: 0,
      quantity: 5,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 1890
    },
    {
      id: 1005,
      name: 'REDRAGON K552RGB-2 KUMARA BLACK (BLUE SWITCH)',
      ram_kit: undefined,
      category_id: 3,
      promo_price: 1860,
      is_hidden_via_product: 0,
      category_name: 'KEYBOARD',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 1953
    },
    {
      id: 1007,
      name: 'ROYAL KLUDGE RK71 WHITE (BLUE SWITCH)',
      ram_kit: undefined,
      category_id: 3,
      promo_price: 2175,
      is_hidden_via_product: 0,
      category_name: 'KEYBOARD',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 2284
    },
    {
      id: 1006,
      name: 'ROYAL KLUDGE RK71 WHITE (BROWN SWITCH)',
      ram_kit: undefined,
      category_id: 3,
      promo_price: 2175,
      is_hidden_via_product: 0,
      category_name: 'KEYBOARD',
      is_hidden_via_category: 0,
      quantity: 2,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 2284
    },
    {
      id: 875,
      name: 'COOLER MASTER MM710 OPTICAL MOUSE MATTE WHITE',
      ram_kit: undefined,
      category_id: 2,
      promo_price: 2095,
      is_hidden_via_product: 0,
      category_name: 'MOUSE',
      is_hidden_via_category: 0,
      quantity: 1,
      status: PriceListItemStatusEnum.LowStock,
      standard_price: 2200
    },
    {
      id: 2,
      name: 'COMFAST USB WIFI DONGLE 5G',
      ram_kit: undefined,
      category_id: 1,
      promo_price: 580,
      is_hidden_via_product: 0,
      category_name: 'WIFI DONGLE',
      is_hidden_via_category: 0,
      quantity: 7,
      status: PriceListItemStatusEnum.InStock,
      standard_price: 609
    }
  ],
  pricelist_date: 'PRICELIST AS OF SUNDAY, NOVEMBER 27, 2022 1:26 PM',
  message: 'Products successfully fetched.'
};
