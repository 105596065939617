import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, PackageUpdatePayload, PackageUpdateResponse, } from 'src/types';

export const pcPackageUpdateThunk = createAsyncThunk<
    CommonAxiosResponse<PackageUpdateResponse>,
    PackageUpdatePayload,
    { state: RootState }
>('pc-package/pcPackageUpdateThunk', async (data) => {
    const updateDateRes = await Api.patch(`/ecomm/internal/pc_package/details/update/${data?.uuid}`, data);
    return updateDateRes;
});
