/* eslint-disable no-unused-vars */
import React, { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { EcomOrderDetails, EcomOrderStatusType } from 'src/types';
import { ecomOrderStatus } from 'src/constants';
import { usePermissions } from 'src/hooks';
import { DecisionDialog } from 'src/components';

interface Props {
  orderDetails?: EcomOrderDetails;
  ecommerceOrderId?: string;
  orderStatus?: EcomOrderStatusType;
  customerRemarks?: string;
  internalRemarks?: string;
  onHandleStatusChange: (newStatus: EcomOrderStatusType) => void;
  onHandleRemarksChange: (
    targetName: 'customer_notes' | 'internal_remarks',
    value: string
  ) => void;
  onGeneratePaymentSuccessLink: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  remarks: {
    marginTop: theme.spacing(1)
  }
}));

const component = ({
  customerRemarks = '',
  internalRemarks = '',
  orderStatus,
  orderDetails,
  onHandleRemarksChange,
  onHandleStatusChange,
  onGeneratePaymentSuccessLink
}: Props) => {
  const classes = useStyles();
  const {
    permissionChecker,
    canGenerateEcommOrderPaymentSuccessLink
  } = usePermissions();
  const canEditEcomOrder = permissionChecker('can_edit_ecom_order');
  const [
    isGeneratePaymentSuccessLinkDecisionOpen,
    setIsGeneratePaymentSuccessLinkDecisionOpen
  ] = useState<boolean>(false);

  const onHandleCustomerRemarksChange = (str: string) => {
    onHandleRemarksChange('customer_notes', str);
  };

  const onHandleInternalRemarksChange = (str: string) => {
    onHandleRemarksChange('internal_remarks', str);
  };

  // const paymentSuccessUrl = useMemo(() => {
  //   const link =
  //     `https://pcw-ecom-git-develop-pcworthdev.vercel.app/payment-success?id=${order}&ts=1720165171&wi=1`;
  //   return link;
  // }, []);

  return (
    <Card className={classes.root}>
      <CardHeader title="Remarks" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography display="inline" variant="h5">
              Customer Order Notes:
            </Typography>
            <TextField
              fullWidth
              disabled={!canEditEcomOrder}
              multiline
              className={classes.remarks}
              rows={4}
              name="customer_remarks"
              onChange={(e) => onHandleCustomerRemarksChange(e.target.value)}
              value={customerRemarks}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <Typography display="inline" variant="h5">
              Internal Remarks:
            </Typography>
            <TextField
              fullWidth
              multiline
              className={classes.remarks}
              rows={4}
              name="internal_remarks"
              onChange={(e) => onHandleInternalRemarksChange(e.target.value)}
              value={internalRemarks}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel required>Order Status</InputLabel>
              <Select
                name="status"
                value={orderStatus || ''}
                onChange={(e: any) => onHandleStatusChange(e.target.value)}
                label="Order Status"
              >
                {ecomOrderStatus?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {orderDetails?.mode_of_payment !== 'COD' &&
          canGenerateEcommOrderPaymentSuccessLink ? (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => setIsGeneratePaymentSuccessLinkDecisionOpen(true)}
              style={{ marginTop: 10 }}
              startIcon={<FileCopyIcon />}
            >
              <Typography variant="h5">
                Generate Payment Success Link
              </Typography>
            </Button>
          ) : null}
        </Grid>
      </CardContent>
      <DecisionDialog
        isOpen={isGeneratePaymentSuccessLinkDecisionOpen}
        title="Generate Payment Success Link?"
        subTitle={`This will mark this ecommerce order as paid. Are you sure you want to proceed?`}
        onHandleConfirmAction={onGeneratePaymentSuccessLink}
        onHandleClose={() => setIsGeneratePaymentSuccessLinkDecisionOpen(false)}
      />
    </Card>
  );
};

export const RemarksAndStatusSection = React.memo(component);
