import React, { useMemo } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';

import { Customer } from 'src/types';
import { TextBoldWarranty, TextRegularWarranty } from './TextWarranty';
import { ViewWarrantyRow } from './ViewWarranty';
import { warrantyViewStyles } from '../styles';
interface Props {
  customerInfo?: Customer;
  dateSold?: string;
  paymentMode?: string;
  transaction_no?: string;
}

const styles = StyleSheet.create({
  root: {
    marginTop: 10
  },
  firstColumn: {
    ...warrantyViewStyles.flex2,
    alignItems: 'center'
  },
  firstColumnValue: {
    ...warrantyViewStyles.flex1,
    borderBottomWidth: 0.5
  },
  secondColumn: {
    ...warrantyViewStyles.flex1,
    marginLeft: 6
  },
  secondColumnValue: {
    ...warrantyViewStyles.flex1,
    borderBottomWidth: 0.5
  },
  secondRow: {
    marginTop: 4
  }
});

export const RenderCustomerInfo = ({
  customerInfo,
  dateSold,
  paymentMode
}: Props) => {
  const customerName = useMemo(() => {
    const firstName = customerInfo?.first_name ?? '';
    const lastName = customerInfo?.last_name ?? '';
    return `${firstName} ${lastName}`;
  }, [customerInfo]);

  return (
    <View style={styles.root}>
      <ViewWarrantyRow>
        <ViewWarrantyRow style={styles.firstColumn}>
          <TextBoldWarranty>{`Sold To: `}</TextBoldWarranty>
          <TextRegularWarranty style={styles.firstColumnValue}>
            {customerName}
          </TextRegularWarranty>
        </ViewWarrantyRow>

        <ViewWarrantyRow style={styles.secondColumn}>
          <TextBoldWarranty>{`Date: `}</TextBoldWarranty>
          <TextRegularWarranty style={styles.secondColumnValue}>
            {dateSold}
          </TextRegularWarranty>
        </ViewWarrantyRow>
      </ViewWarrantyRow>

      <ViewWarrantyRow style={styles.secondRow}>
        <ViewWarrantyRow style={styles.firstColumn}>
          <TextBoldWarranty>{`Contact No: `}</TextBoldWarranty>
          <TextRegularWarranty style={styles.firstColumnValue}>
            {customerInfo?.contact_no || ''}
          </TextRegularWarranty>
        </ViewWarrantyRow>

        <ViewWarrantyRow style={styles.secondColumn}>
          <TextBoldWarranty>{`Terms: `}</TextBoldWarranty>
          <TextRegularWarranty style={styles.secondColumnValue}>
            {paymentMode?.toUpperCase() || ''}
          </TextRegularWarranty>
        </ViewWarrantyRow>
      </ViewWarrantyRow>

      {/* <ViewWarrantyRow style={styles.secondRow}>
        <TextBoldWarranty style={warrantyViewStyles.flex2}>
          {`Contact No: `}
          <TextRegularWarranty>
            {customerInfo?.contact_no || ''}
          </TextRegularWarranty>
        </TextBoldWarranty>

        <TextBoldWarranty style={warrantyViewStyles.flex1}>
          {`Terms: `}
          <TextRegularWarranty>
            {paymentMode?.toUpperCase() || ''}
          </TextRegularWarranty>
        </TextBoldWarranty>
      </ViewWarrantyRow> */}
    </View>
  );
};
