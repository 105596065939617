import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, DeleteFlashDealsResponse } from 'src/types';

export const flashDealsDeleteThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteFlashDealsResponse>,
  number,
  { state: RootState }
>('flashDeals/flashDealsDeleteThunk', async (id) => {
  const response = await Api.delete(`/ecomm/internal/flash_deal/delete/${id}`);
  return response;
});
