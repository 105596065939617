import React from 'react';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { formatCurrency } from 'src/utils';
import { Product } from 'src/types';

interface Props {
  className?: string;
  recentProducts?: Product[];
  onProductPress: (item: Product) => void;
}

const component = ({
  recentProducts = [],
  onProductPress
}: Props) => {
  return (
    <Box marginY={2}>
      <Card>
        <CardHeader title="Added Products" />
        <Divider />
        <PerfectScrollbar>
          <Box>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Category</TableCell>
                  <TableCell>Manufacturer</TableCell>
                  <TableCell>Price</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {recentProducts?.map((item: Product) => (
                  <TableRow
                    hover
                    key={item.id}
                    onClick={() => onProductPress(item)}
                  >
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.category}</TableCell>
                    <TableCell>{item.manufacturer}</TableCell>
                    <TableCell>
                      {formatCurrency(item.retail_price) ?? 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </Box>
  );
};

export const RecentAddedProductsTable = React.memo(component);

