/* eslint-disable no-unused-vars */
import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { CustomInputEvent, CustomKeyboardEvent } from 'src/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';

const {
  actions: flashDealsActions,
  selectors: flashDealsSelectors
} = slices.flashDeals;

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  }
}));

const Component = ({ className, ...rest }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const getFlashDealsListRequest = useAppSelector(
    flashDealsSelectors.selectGetFlashDealsListRequest
  );

  const onSearchFlashDeals = () => {
    dispatch(flashDealsActions.getFlashDealsListThunk({}));
  };

  const handleChange = (event: CustomInputEvent) => {
    dispatch(
      flashDealsActions.updateFlashDealsKeywordRequest(event?.target?.value)
    );
  };

  const onSearchClick = () => {
    onSearchFlashDeals();
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchFlashDeals();
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography color="textPrimary" gutterBottom variant="h3">
          Flash Deals
        </Typography>
        <Button
          onClick={() => navigate('/app/flash-deals/add')}
          color="primary"
          variant="contained"
        >
          Add Flash Deal Item
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={4} md={3} xs={4}>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    value={getFlashDealsListRequest?.keyword || ''}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search Flash Deals"
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>
              <Grid alignItems="center" item xs={1} md={1}>
                <Button
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export const FlashDealsListToolbar = React.memo(Component);
