export const contentType = [
  'Interview',
  'How To Guide',
  'Buying Guides',
  'Product Review',
  'Previews',
  'Photo Gallery',
  'Labs',
  'Product Feature',
  'Survey & Research',
  'Branding',
  'Opinion Article',
  'Application & Tool',
  'AI & IoT',
  'Uncategorized',
  'Press Release'
];

//===for now===
// 1	Laptops
// 2	Desktops
// 3	Monitors
// 4	Graphics Cards
// 5	Motherboards
// 6	Peripherals
// 7	Case & Components
// 8	AIoT Solutions
// 9	Networking
// 10	All-in-One PCs
// 11	Apparel & Gear
// 12	Server

export const blogCategory = [
  { category: 'Laptops', id: 1 },
  { category: 'Desktops', id: 2 },
  { category: 'Monitors', id: 3 },
  { category: 'Graphic Cards', id: 4 },
  { category: 'Motherboards', id: 5 },
  { category: 'Peripherals', id: 6 },
  { category: 'Case & Components', id: 7 },
  { category: 'AIoT Solution', id: 8 },
  { category: 'Networking', id: 9 },
  { category: 'All-in-One PCs', id: 10 },
  { category: 'Apparel & Gear', id: 11 },
  { category: 'Server', id: 12 }
];
