import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LoadingButton } from '..';
import { CustomKeyboardEvent } from 'src/types';

// Used for adding items in database with name field only. (Supplier, Manufacturer, Roles, Permission)

interface Props {
  title?: string;
  subTitle?: string;
  isVisible: boolean;
  handleClose: () => void;
  fieldName?: string;
  onAddPress: (name: string) => void;
  customButtons?: JSX.Element;
  loading?: boolean;

  errMsg?: string;
  nameValue?: string;
}

const FormAddDialog = ({
  title = 'Title',
  subTitle = 'Subtitle',
  fieldName = 'Email',
  isVisible = false,
  handleClose,
  onAddPress,
  customButtons,
  errMsg,
  nameValue,
  loading
}: Props) => {
  const [name, setName] = useState<string>(nameValue || '');
  const [errName, setErrName] = useState<string>(errMsg || '');

  const resetState = () => {
    setName('');
    setErrName('');
  };

  const onAddBtnPress = () => {
    // Check if name is valid.
    if (loading) {
      return;
    }
    if (!name) {
      setErrName('Invalid Value');
      return;
    }
    if (onAddPress) {
      onAddPress(name);
    }
    resetState();
  };

  const onCancelBtnPress = () => {
    resetState();
    handleClose();
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onAddBtnPress();
    }
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{subTitle}</DialogContentText>
        <TextField
          error={errName === '' ? false : true}
          helperText={errName}
          autoFocus
          margin="dense"
          id="name"
          label={fieldName}
          fullWidth
          required
          variant="outlined"
          value={name}
          onKeyPress={onKeyPress}
          onChange={(e) => {
            setErrName('');
            setName(e.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        {customButtons ? (
          customButtons
        ) : (
          <>
            <LoadingButton
              title="Add"
              loading={loading ? loading : false}
              onClick={onAddBtnPress}
              color="primary"
            />
            <Button onClick={onCancelBtnPress}>Cancel</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default FormAddDialog;
