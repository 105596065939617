import { CategoriesEnum } from 'src/enums';
import { colors } from 'src/constants';

export const defaultRowColors = {
  gpu: colors.green,
  cpu: colors.amber,
  motherboard: colors.blue,
  ram: colors.blueGrey,
  hdd: colors.cyan,
  ssd: colors.lightGreen,
  psu: colors.lightBlue,
  casing: colors.deepPurple,
  monitor: colors.yellow,
  cpuCooler: colors.brown,
  fan: colors.teal,
  accessories: colors.indigo
};

export const getCategoryColor = (category_id?: number) => {
  switch (category_id) {
    case CategoriesEnum.GPU: // gpu.
      return defaultRowColors.gpu;
    case CategoriesEnum.CPU: // cpu.
      return defaultRowColors.cpu;
    case CategoriesEnum.Motherboard: // motherboard.
      return defaultRowColors.motherboard;
    case CategoriesEnum.RAM: // RAM.
      return defaultRowColors.ram;
    case CategoriesEnum.SSD: // SSD.
      return defaultRowColors.ssd;
    case CategoriesEnum.HDD: // HDD.
      return defaultRowColors.hdd;
    case CategoriesEnum.PSU: // PSU.
      return defaultRowColors.psu;
    case CategoriesEnum.Casing: // casing.
      return defaultRowColors.casing;
    case CategoriesEnum.Monitor: // monitor.
      return defaultRowColors.monitor;
    case CategoriesEnum.CpuCooler:
      return defaultRowColors.cpuCooler;
    case CategoriesEnum.Fan:
      return defaultRowColors.fan;
    default:
      return defaultRowColors.accessories;
  }
};
