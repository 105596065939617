import React, { FC, useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Tooltip
} from '@material-ui/core';

import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { KachiHistory } from 'src/types/kachi-points';
import { usePermissions } from 'src/hooks';
import { ForfeitKachiPoints } from './ForfeitKachiPointsFormDialog';

type Props = {
  kachiHistoryList?: KachiHistory[];
  userId?: number;
  isLoading?: boolean;
};

export const KachiHistoryTable: FC<Props> = ({
  kachiHistoryList,
  userId,
  isLoading
}) => {
  const { canForfeitKachiPoints } = usePermissions();
  const [isForfeitFormShow, setIsForfeitFormShow] = useState<boolean>(false);
  const [kachiPointsId, setKachiPointsId] = useState<number>();
  const tableRow = [
    'id',
    'points earned',
    'reward type',
    'details',
    'date earned'
  ];

  const onHandleClickForfeit = (kachiPointsId?: number) => {
    setIsForfeitFormShow(!isForfeitFormShow);
    setKachiPointsId(kachiPointsId);
  };

  if (isLoading) {
    return (
      <Box>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Card style={{ padding: '5px', borderRadius: '5px', marginBottom: '15px' }}>
      <CardContent>
        <CardHeader title={'Kachi Points History'} />
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableRow.map((rowItem, index) => (
                  <TableCell key={index}>{rowItem}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {kachiHistoryList?.map((kachiHistory, index) => (
                <TableRow key={index}>
                  <TableCell>{kachiHistory?.id}</TableCell>
                  <TableCell>{kachiHistory?.points_earned}</TableCell>
                  <TableCell>{kachiHistory?.reward_type}</TableCell>
                  <TableCell>{kachiHistory?.details}</TableCell>
                  <TableCell>{kachiHistory?.created_at}</TableCell>
                  <TableCell>
                    <IconButton
                      color="secondary"
                      onClick={() => onHandleClickForfeit(kachiHistory?.id)}
                      disabled={!canForfeitKachiPoints}
                    >
                      <Tooltip title={'Forfeit kachi points'}>
                        <HighlightOffIcon />
                      </Tooltip>
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      <ForfeitKachiPoints
        pointsId={kachiPointsId}
        userId={userId}
        isOpen={isForfeitFormShow}
        onClose={() => setIsForfeitFormShow(false)}
      />
    </Card>
  );
};
