import { createStyles, TableRow, withStyles } from "@material-ui/core";

export const AlternatingColorTableRow = withStyles((theme) =>
createStyles({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.disabledBackground
    }
  }
})
)(TableRow);