import {
  casingPartsFields,
  cpuPartsFields,
  gpuPartsFields,
  hddPartsFields,
  motherboardPartsFields,
  psuPartsFields,
  ramPartsFields,
  ssdPartsFields
} from 'src/constants';
import { ProductPartDetailsCategory } from 'src/types';

export const getCategoryFieldsViaName = (
  categoryName?: ProductPartDetailsCategory
) => {
  switch (categoryName) {
    case 'casing':
      return casingPartsFields;
    case 'cpu':
      return cpuPartsFields;
    case 'gpu':
      return gpuPartsFields;
    case 'hdd':
      return hddPartsFields;
    case 'motherboard':
      return motherboardPartsFields;
    case 'psu':
      return psuPartsFields;
    case 'ram':
      return ramPartsFields;
    case 'ssd':
      return ssdPartsFields;
    default:
      return [];
  }
};
