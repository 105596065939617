import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';

import { AlertDialog, LoaderBar } from 'src/components';
import { useSnackBar } from 'src/hooks';
import { localize } from 'src/constants';

const { actions: optionActions } = slices.options;

interface Props {
  option: any;
  className?: string;
  isLoading: boolean;
  renderOptionDetails: (id: number) => void;
  setIsLoading: (loading: boolean) => void
}

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  }
}));

const OptionDetailView = ({
  option,
  className,
  isLoading,
  renderOptionDetails,
  setIsLoading,
  ...rest
}: Props) => {
  const classes = useStyles();
  const { id } = useParams();
  const idNum = id ? Number(id) : undefined;
  const snackBar = useSnackBar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  const [optionName, setOptionName] = useState<string>('');
  const [value, setValue] = useState<string>('');
  const [deleteOptionDialog, setDeleteOptionDialog] = useState<boolean>(false);

  const handleClickOpen = () => {
    setDeleteOptionDialog(true);
  };

  const handleClose = () => {
    setDeleteOptionDialog(false);
  };

  const onSaveDetailPress = async () => {
    if (idNum) {
      const response = unwrapResult(
        await dispatch(
          optionActions.updateOptionThunk({
            id: idNum,
            name: optionName,
            value: value
          })
        )
      );

      if (response?.success && response?.message) {
        snackBar.show({ severity: 'success', message: response.message });
        renderOptionDetails(idNum);
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message
            ? response.message
            : localize.ERR_API_UNKNOWN
        });
      }
    } else {
      return;
    }
  };

  const deleteOption = async () => {
    if (idNum) {
      setIsLoading(true);
      const response = unwrapResult(
        await dispatch(optionActions.deleteOptionThunk(idNum))
      );
      if (response?.success && response?.message) {
        setIsLoading(false);
        navigate(-1);
      }
    } else {
      return;
    }
  };

  useEffect(() => {
    if (option) {
      setOptionName(option?.name);
      setValue(option?.value);
    }
  }, [option]);

  return (
    <Box mt={2}>
      <Card className={clsx(classes.root, className)} {...rest}>
        <LoaderBar isLoading={isLoading} />
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={8} md={8} xs={8}>
              <Box>
                <Typography
                  style={{ marginTop: 10 }}
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                >
                  {option?.name}
                </Typography>
                <Typography color="textSecondary" variant="h6">
                  {option?.name}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box mt={2}>
            <Card elevation={0}>
              <CardContent>
                <TextField
                  fullWidth
                  required
                  name="supplierName"
                  variant="outlined"
                  label="Option Name"
                  onChange={(e) => setOptionName(e.target.value)}
                  value={optionName}
                  style={{
                    marginBottom: '10px'
                  }}
                />
                <TextField
                  fullWidth
                  required
                  name="supplierName"
                  variant="outlined"
                  label="Option Value"
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                  style={{
                    marginBottom: '10px'
                  }}
                />
              </CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                paddingX={2}
                mt={2}
              >
                <Button
                  onClick={handleClickOpen}
                  color="secondary"
                  variant="contained"
                >
                  Delete Option
                </Button>
                <AlertDialog
                  title={`Delete ${option?.name}`}
                  customButtons={
                    <>
                      <Button
                        onClick={deleteOption}
                        color="secondary"
                        autoFocus
                      >
                        Delete
                      </Button>
                      <Button onClick={handleClose} color="primary">
                        Cancel
                      </Button>
                    </>
                  }
                  subTitle={`Are you sure you want to delete ${option?.name}`}
                  isVisible={deleteOptionDialog}
                />
                <Button
                  onClick={onSaveDetailPress}
                  color="primary"
                  variant="contained"
                >
                  Save details
                </Button>
              </Box>
            </Card>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default OptionDetailView;
