export const localize = {
  // Error in API service //
  ERR_API_NETWORK: 'Cannot connect to the internet.',
  ERR_API_SERVER: 'Server error. Please try again later.',
  ERR_API_TIMEOUT: "Server didn't respond in time.",
  ERR_API_SERVER_NA: 'Server not available.',
  ERR_API_UNKNOWN: 'Unknown error, Please contact administrator',

  // Error Messages //
  ERR_FIELD_NAME: 'Invalid name',
  ERR_FIELDS: 'Please fill up all required fields.',
  ERR_FIELD_WARRANTY: 'Invalid warranty duration in days',
  ERR_FIELD_PURCHASE_DATE: 'Invalid supplier purchase date',
  ERR_FIELD_DR_NO: 'DR No. is required',
  ERR_ADD_TEMP_LISTING_LIMIT: 'Temporary listing limit exceeded.',
  ERR_ADD_TEMP_LISTING_SUPPLIER: 'Please add a supplier first.',
  ERR_ADD_TEMP_LISTING_SERIAL:
    'This serial number does not exist or not available.',
  ERR_ADD_TEMP_TRANSACT_LIMIT: 'Temporary transactions limit exceeded.',
  ERR_ADD_TEMP_TRANSACT_ZERO: 'Temporary transactions is empty.',
  ERR_ADD_TEMP_LISTING_EXIST:
    'Serial number already exists. Please input a different serial number.',
  ERR_INVALID_QUANTITY: 'Sorry, Please enter a valid quantity.',
  ERR_INVALID_SERIAL_NO_API: 'Serial No. already exists or invalid serial no.',
  ERR_INVALID_RETAIL_PRICE: 'Sorry, Please enter a valid retail price.',
  ERR_INVALID_PRICE: 'Sorry, Please enter a valid price.',
  ERR_REQUIRED_UNIT: 'Consumable Unit Required',
  ERR_INVALID_DEALERS_PRICE: 'Sorry, Please enter a valid dealers price.',
  ERR_INVALID_SERIAL_NO: 'Please enter a valid serial no.',
  ERR_LOGIN: 'Login failed.',
  ERR_RECENT_LISTING_EMPTY: 'Please add a listing first.',
  ERR_LIMIT_TRANSACTION:
    'You can only put 10 item(s) in this transaction. Please make another transaction',
  ERR_NO_TRANSACTION: 'Please select customer to make a transaction',
  ERR_PERMISSION: 'You do not have the proper permission.',
  ERR_ADD_TEMP_LISTING_BRANCH: 'Please add a branch first.',
  ERR_DUPLICATE_TEMP_LISTING_SN:
    'The serial number for this product is already added in temporary listing.',

  // Success Messages //
  SUC_ADD_TEMP_LISTING: 'Listing temporarily added',
  SUC_ADD_TEMP_TRANSACT: 'Transaction temporarily added',
  SUC_IMG_UPLOAD: 'Image(s) uploaded',

  // Confirm Messages //
  CONF_LOGOUT: 'Are you sure you want to logout?',

  // Info Messages.
  IMG_UPLOADING: 'Image(s) uploading... Please wait for a while.'
};
