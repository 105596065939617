import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonCategoryResponse,
  CategoryThunkRequest,
  GetCategoryViaIDResponse,
  DeleteCategoryResponse,
  CreateCategoryRequest,
  CreateCategoryResponse,
  UpdateCategoryResponse,
  UpdateCategoryThunkRequest,
  UpdateCategoryImageResponse,
  UpdateCategoryImageRequest,
  GetMainCategoryResponse,
  GetMainCategoryRequest
} from 'src/types/category';
import { CommonAxiosResponse } from 'src/types';

export const getCategoriesThunk = createAsyncThunk<
  CommonAxiosResponse<CommonCategoryResponse>,
  CategoryThunkRequest,
  { state: RootState }
>('categories/getCategoriesThunk', async (data) => {
  const response = await Api.get('/categories', {
    ...data,
    limit: data.limit || 999
  }); // hotfix: categories not populating properly on filters
  if (!response.success) {
    throw new Error('Failed getCategoriesThunk');
  }
  return response;
});

export const getCategoriesFilterThunk = createAsyncThunk<
  CommonAxiosResponse<CommonCategoryResponse>,
  CategoryThunkRequest,
  { state: RootState }
>('categories/getCategoriesFilterThunk', async (data) => {
  const response = await Api.get('/categories', { ...data, limit: 999 }); // hotfix: categories not populating properly on filters
  if (!response.success) {
    throw new Error('Failed getCategoriesFilterThunk');
  }
  return response;
});

export const getCategoryViaIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetCategoryViaIDResponse>,
  number,
  { state: RootState }
>('categories/getCategoryViaIdThunk', async (id) => {
  const response = await Api.get(`/categories/${id}`);
  if (!response.success) {
    throw new Error('Failed getCategoryViaIdThunk');
  }
  return response;
});

export const deleteCategoryThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteCategoryResponse>,
  number,
  { state: RootState }
>('categories/deleteCategoryThunk', async (id) => {
  const response = await Api.delete(`/categories/${id}`);
  if (!response.success) {
    throw new Error('Failed deleteCategoryThunk');
  }
  return response;
});

export const updateCategoryThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateCategoryResponse>,
  UpdateCategoryThunkRequest,
  { state: RootState }
>('categories/updateCategoryThunk', async (data) => {
  const response = await Api.patch(`/categories/${data.id}`, data);
  if (!response.success) {
    throw new Error('Failed updateCategoryThunk');
  }
  return response;
});

export const createCategoryThunk = createAsyncThunk<
  CommonAxiosResponse<CreateCategoryResponse>,
  CreateCategoryRequest,
  { state: RootState }
>('categories/createCategoryThunk', async (data) => {
  const response = await Api.post('/categories', data);
  // if (!response.success) {
  //   throw new Error('Failed createCategoryThunk');
  // }
  return response;
});

export const getMainCategoryThunk = createAsyncThunk<
  CommonAxiosResponse<GetMainCategoryResponse>,
  GetMainCategoryRequest,
  { state: RootState }
>('categories/getMainCategoryThunk', async (data) => {
  const response = await Api.get('/categories/main/get', data);
  if (!response) {
    throw new Error('Failed get Main Category');
  }
  return response;
});

//image update thunk
export const updateCategoryImageThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateCategoryImageResponse>,
  UpdateCategoryImageRequest,
  { state: RootState }
>('categories/updateCategoryImageThunk', async (data) => {
  const response = await Api.patch(`/categories/${data.id}`, data);
  if (!response.success) {
    throw new Error('Failed updateCategoryImageThunk');
  }
  return response;
});

export const unAssignSubCategoryToMainCategoryThunk = createAsyncThunk<
  CommonAxiosResponse<null>,
  number | undefined,
  { state: RootState }
>(
  'categories/unAssignSubCategoryToMainCategoryThunk',
  async (subCategoryId) => {
    const response = await Api.patch(
      `/category/unassign_from_main_category/update/${subCategoryId}`
    );
    if (!response.success) {
      throw new Error('Failed unAssignSubCategoryToMainCategoryThunk');
    }
    return response;
  }
);
