import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectEcommBanner = (state: RootState) => state.banner;

const selectEcommBanners = createSelector(
  selectEcommBanner,
  (state) => state.banners
);

export const selectors = {
  selectEcommBanners
};
