import React, { FC, memo } from 'react';
import {
  Box,
  Card,
  colors,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  InventoryAuditProductsData,
  InventoryAuditProductWithIssue
} from 'src/redux/slices/inventory-audit-logs';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  customerInfoBox: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: '1.5rem'
  },
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  tableCell: {
    border: '1px solid #ddd',
    padding: '8px',
    flexBasis: 1
  },
  tableHeader: {
    border: '1px solid #ddd',
    padding: '1rem',
    backgroundColor: '#f4f4f4',
    fontWeight: 'bold'
  },
  statusIcon: {
    marginRight: '8px'
  }
}));

interface Props {
  withIssue?: boolean;
  inventoryAuditProducts?: InventoryAuditProductsData[];
  inventoryAuditProductsWithIssue?: InventoryAuditProductWithIssue[];
}

const component: FC<Props> = ({
  withIssue = false,
  inventoryAuditProducts,
  inventoryAuditProductsWithIssue
}) => {
  const classes = useStyles();

  return (
    <Card className={classes.customerInfoBox}>
      <Box>
        <PerfectScrollbar style={{ marginTop: '1rem', overflow: 'scrollX' }}>
          <TableContainer
            component={Paper}
            style={{
              maxHeight: '50vh',
              overflow: 'auto',
              marginBottom: '3rem'
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              className={classes.table}
            >
              <TableHead>
                <TableRow>
                  {!withIssue && (
                    <TableCell
                      className={classes.tableHeader}
                      style={{ width: '5%' }}
                    >
                      Status
                    </TableCell>
                  )}
                  <TableCell className={classes.tableHeader}>
                    Product Name
                  </TableCell>
                  {withIssue && (
                    <TableCell className={classes.tableHeader}>
                      Missing Serial No(s)
                    </TableCell>
                  )}
                  <TableCell
                    className={classes.tableHeader}
                    style={{ width: '10%' }}
                  >
                    Audited Quantity
                  </TableCell>
                  <TableCell
                    className={classes.tableHeader}
                    style={{ width: '10%' }}
                  >
                    Available Items Count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!withIssue &&
                  inventoryAuditProducts?.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: 'center' }}
                      >
                        {!product?.status ? (
                          <Tooltip title="This product has issue">
                            <ErrorOutlineIcon color="secondary" />
                          </Tooltip>
                        ) : (
                          <CheckCircleIcon
                            style={{ color: colors.green[500] }}
                          />
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCell}>
                        {product?.product_name}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: 'center' }}
                      >
                        {product?.audited_quantity}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: 'center' }}
                      >
                        {product?.available_items_count}
                      </TableCell>
                    </TableRow>
                  ))}

                {withIssue &&
                  inventoryAuditProductsWithIssue?.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.tableCell}>
                        {product?.product_name}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ width: '40%' }}
                      >
                        {product?.missing_serial_nos}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: 'center' }}
                      >
                        {product?.audited_quantity}
                      </TableCell>
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: 'center' }}
                      >
                        {product?.available_items_count}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>
      </Box>
    </Card>
  );
};

export const AuditDetailListing = memo(component);
