import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React from 'react';

import { Branch } from 'src/types/branch';

interface BranchProps {
  branches: Branch[];
  onBranchPress: (data: Branch) => void;
}

export const BranchesTable = ({ branches = [], onBranchPress }: BranchProps) => {
  return (
    <Card>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Branch Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {branches?.map((item: Branch) => (
                <TableRow
                  onClick={() => onBranchPress(item)}
                  hover
                  key={item.id}
                  style={{
                    cursor: 'pointer'
                  }}
                >
                  <TableCell>{item.branch_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};
