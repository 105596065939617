import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Page } from 'src/components';
import Toolbar from './Toolbar';
import Manufacturers from './Manufacturers';
import { LoaderBar } from 'src/components';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import { Manufacturer } from 'src/constants/inventory';

const { actions: manufacturerActions } = slices.manufacturer;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ManufacturesView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getManufacturers = async (keyword?: string) => {
    setIsLoading(true);
    const getManufacturersResponse = unwrapResult(
      await dispatch(
        manufacturerActions.getManufacturersThunk({ keyword })
      ).finally(() => setIsLoading(false))
    );
    if (getManufacturersResponse?.originalData?.manufacturers) {
      setManufacturers(getManufacturersResponse?.originalData?.manufacturers);
    }
  };

  const onSearchManufacturer = (keyword: string) => {
    getManufacturers(keyword);
  };

  useEffect(() => {
    getManufacturers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Manufacturers">
      <Container maxWidth={false}>
        <Toolbar
          getManufacturers={getManufacturers}
          onSearchManufacturer={onSearchManufacturer}
        />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <Manufacturers manufacturers={manufacturers} />
        </Box>
      </Container>
    </Page>
  );
};

export default ManufacturesView;
