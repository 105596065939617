import { DeliveryOptionsEnum } from 'src/enums';

export * from './order';
export * from './map';

export const deliveryOptions = [
  {
    label: 'Tech Out - Free Shipping',
    value: DeliveryOptionsEnum.TechOutFree
  },
  {
    label: 'Tech Out Express',
    value: DeliveryOptionsEnum.TechOut
  },
  {
    label: 'In Store PickUp',
    value: DeliveryOptionsEnum.PickUp
  },
  {
    label: 'Lalamove', // Deprecated
    value: 'Lalamove'
  },
  {
    label: 'LBC', // Deprecated
    value: 'LBC'
  },
  {
    label: 'J&T Express',
    value: DeliveryOptionsEnum.Jnt,
    img: '/assets/images/logos/j&t.png',
    prefHeight: 20,
    notes: [
      'Shipping To Luzon	1-5 days',
      'Shipping To Visayas	3-7 days',
      'Shipping To Mindanao	3-7 days',
      'Shipping To Island	5-6 days'
    ]
  }
];

export const paymentOptions = [
  {
    label: '2c2p',
    value: '2c2p'
  },
  {
    label: 'Gcash',
    value: 'Gcash'
  },
  {
    label: 'Online Bank Transfer (BDO)',
    value: 'Online Bank Transfer'
  },
  {
    label: 'Paymongo',
    value: 'Paymongo'
  },
  {
    label: 'Cash on Delivery',
    value: 'COD'
  }
];
