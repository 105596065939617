import React, { FC, memo, useEffect, useState } from 'react';
import { Button, Card, makeStyles } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { BlogCategoryForm } from './BlogCategoryForm';
import { useBlogCategory } from 'src/hooks/blog-category';
import { CreateBlogCategoryPayload } from 'src/redux/slices/blog-category';
import { usePermissions } from 'src/hooks';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: '1.5rem'
  }
}));

interface Props {}

const component: FC<Props> = () => {
  const classes = useStyles();
  const { canCreateBlogCategory } = usePermissions();
  const { isCreateLoading, isSuccess, createBlogCategory } = useBlogCategory();
  const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      setIsOpenForm(false);
    }
  }, [isSuccess]);

  return (
    <Card elevation={1} className={classes.toolbar}>
      {canCreateBlogCategory && (
        <Button
          endIcon={<CreateIcon />}
          variant="outlined"
          onClick={() => setIsOpenForm(true)}
          disabled={isCreateLoading}
        >
          Create Blog Category
        </Button>
      )}

      <BlogCategoryForm
        isOpen={isOpenForm}
        action="create"
        handleClose={() => setIsOpenForm(false)}
        onHandleSubmit={(values: CreateBlogCategoryPayload) => {
          createBlogCategory(values);
        }}
      />
    </Card>
  );
};

export const ToolBar = memo(component);
