import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { useSnackBar } from '../use-snackbar';
import {
  BlogCategoryData,
  CreateBlogCategoryPayload,
  GetBlogCategoryListData,
  GetBlogCategoryListOptionsPayload,
  GetBlogCategoryPayload,
  UpdateBlogCategoryPayload
} from 'src/redux/slices/blog-category';

const {
  selectors: blogCategorySelectors,
  actions: blogCategoryActions
} = slices.blogCategory;

export const useBlogCategory = () => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [blogCategoryOptions, setBlogCategoryOptions] = useState<
    BlogCategoryData[]
  >([]);
  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);
  //for create and update, closing of forms
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const [blogCategoryDetails, setBlogCategoryDetails] = useState<
    GetBlogCategoryListData
  >();

  const blogCategorytListLoading = useAppSelector(
    blogCategorySelectors?.selectBlogCategoryLoading
  );

  const blogCategoryList = useAppSelector(
    blogCategorySelectors?.selectBlogCategoryList
  );

  const blogCategoryListMeta = useAppSelector(
    blogCategorySelectors?.selectBlogCategoryMeta
  );

  const getBlogCategoryList = useCallback(
    async (params?: GetBlogCategoryPayload) => {
      try {
        await dispatch(blogCategoryActions?.getBlogCategoryThunk(params || {}));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const getBlogCategoryListOption = useCallback(
    async (params?: GetBlogCategoryListOptionsPayload) => {
      try {
        const response = unwrapResult(
          await dispatch(
            blogCategoryActions?.getBlogCategoryOptionListThunk(params || {})
          )
        );
        if (response?.success) {
          const { data } = response?.originalData;
          const filterBlogCategoryOptions = data?.filter(
            (item) => item.is_hidden !== '1'
          );
          setBlogCategoryOptions(filterBlogCategoryOptions || []);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const getBlogCategoryDetail = useCallback(
    async (blog_id: number) => {
      try {
        //if has id it will become details else it is category list
        const response = unwrapResult(
          await dispatch(
            blogCategoryActions?.getBlogCategoryDetailThunk(blog_id)
          )
        );

        if (response?.success) {
          setBlogCategoryDetails(response?.originalData?.data);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const createBlogCategory = useCallback(
    async (params: CreateBlogCategoryPayload) => {
      try {
        setIsSuccess(false);
        setIsCreateLoading(true);
        const response = unwrapResult(
          await dispatch(blogCategoryActions?.createBlogCategoryThunk(params))
        );

        if (response?.success) {
          const { message } = response;
          setIsCreateLoading(false);
          setIsSuccess(true);
          snackBar.show({ severity: 'success', message });
          getBlogCategoryList({ page: blogCategoryListMeta?.current_page });
        } else {
          const { errors } = response;
          const errorMessages = Object.values(errors)
            .flat()
            .join('|');
          setIsSuccess(false);
          snackBar?.show({ severity: 'error', message: errorMessages });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsCreateLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, snackBar]
  );

  const updateBlogCategory = useCallback(
    async (params: UpdateBlogCategoryPayload) => {
      try {
        setIsSuccess(false);
        setIsUpdateLoading(true);
        const response = unwrapResult(
          await dispatch(
            blogCategoryActions?.updateBlogCategoryThunk({
              name: params?.name,
              blog_id: params?.blog_id,
              is_hidden: params?.is_hidden
            })
          )
        );

        if (response?.success) {
          const { message } = response;
          setIsSuccess(true);
          getBlogCategoryList({ page: blogCategoryListMeta?.current_page });
          snackBar.show({ severity: 'success', message });
        } else {
          const { errors } = response;
          const errorMessages = Object.values(errors)
            .flat()
            .join('|');
          setIsUpdateLoading(false);
          setIsSuccess(false);
          snackBar.show({ severity: 'error', message: errorMessages });
        }

        return response;
      } catch (error) {
        console.error(error);
      } finally {
        setIsUpdateLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, getBlogCategoryList, snackBar]
  );

  const deleteBlogCategory = useCallback(
    async (blog_id?: number) => {
      try {
        setIsDeleteLoading(true);
        const response = unwrapResult(
          await dispatch(
            blogCategoryActions?.deleteBlogCategoryThunk(blog_id || 0)
          )
        );

        if (response?.success) {
          const { message } = response;
          setIsDeleteLoading(false);
          snackBar.show({ severity: 'success', message });
          getBlogCategoryList({ page: blogCategoryListMeta?.current_page });
        } else {
          const { message } = response;
          snackBar?.show({ severity: 'error', message });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsDeleteLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, snackBar]
  );

  return {
    blogCategoryOptions,
    blogCategoryList,
    blogCategoryListMeta,
    blogCategoryDetails,

    blogCategorytListLoading,
    isUpdateLoading,
    isCreateLoading,
    isDeleteLoading,
    isSuccess,

    getBlogCategoryList,
    createBlogCategory,
    updateBlogCategory,
    deleteBlogCategory,
    getBlogCategoryDetail,
    getBlogCategoryListOption
  };
};
