import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { useAppDispatch } from 'src/redux';
import {
  DecayingStockData,
  DecayingStockPayload,
  getDecayingStockProductsThunk
} from 'src/redux/slices/decaying-stock';
import { PaginationV2 } from 'src/types';

export const useDecayingStock = () => {
  const dispatch = useAppDispatch();
  const [decayingProductList, setDecayingProductList] = useState<
    DecayingStockData[]
  >([]);
  const [params, setParams] = useState<DecayingStockPayload>({
    page: 1,
    limit: 25,
    sort_direction: 'asc',
    sort_field: 'purchase_date'
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [pageMeta, setPageMeta] = useState<PaginationV2>();

  const getDecayingStockProducts = useCallback(
    async (params: DecayingStockPayload) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getDecayingStockProductsThunk(params))
        );
        if (response?.success) {
          const { data, meta } = response.originalData;
          setDecayingProductList(data || []);
          setPageMeta(meta);
          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const getStocksForCSV = useCallback(
    async (params: DecayingStockPayload) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getDecayingStockProductsThunk(params))
        );
        if (response?.success) {
          const { data } = response.originalData;
          return data;
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  return {
    isLoading,
    decayingProductList,
    pageMeta,
    params,

    setDecayingProductList,
    getDecayingStockProducts,
    getStocksForCSV,
    setParams
  };
};
