import React, { useState } from 'react';
import { addMinutes, isPast } from 'date-fns';
import { Button, ButtonGroup, Typography, makeStyles } from '@material-ui/core';
import { AccessTime, LocalPostOffice } from '@material-ui/icons';
import { DateTimePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { usePermissions, useSnackBar } from 'src/hooks';
import { formatDate } from 'src/utils';

const { selectors: smsSelectors, actions: smsActions } = slices.sms;

interface Props {
  onSend: () => void;
}

const useStyles = makeStyles((theme) => ({
  customSeparator: {
    '& .MuiButtonGroup-grouped:not(:last-child)': {
      borderColor: theme.palette.common.white
    }
  }
}));

export const SendSmsButton: React.FC<Props> = ({ onSend }) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackbar = useSnackBar();

  const anchorRef = React.useRef(null);
  const { canSMSBlast } = usePermissions();

  const isLoading = useAppSelector(smsSelectors.selectSendSMSIsLoading);
  const deliveryDate = useAppSelector(smsSelectors.selectDeliveryDate);

  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);

  const onChangeDeliveryDate = (date?: MaterialUiPickersDate) => {
    let adjustedDate = date;
    if (!date) {
      dispatch(smsActions.onChangeDeliveryDate(null));
      return;
    }

    if (date && isPast(date)) {
      snackbar.show({
        message:
          'Past date and time is not allowed, We added 10 minutes from time now instead',
        severity: 'warning'
      });
      const now = new Date();
      const newTime = addMinutes(now, 10);
      adjustedDate = newTime;
    }

    // const formattedDate = formatDate(adjustedDate, 'MMMM d, yyyy h:mm a');
    const formattedDate = formatDate(adjustedDate, 'yyyy-MM-dd HH:mm:ss');
    dispatch(smsActions.onChangeDeliveryDate(formattedDate));
  };

  return (
    <div>
      <div
        style={{ justifyContent: 'flex-end', display: 'flex', marginTop: 20 }}
      >
        <ButtonGroup
          disabled={!canSMSBlast || isLoading}
          ref={anchorRef}
          className={classes.customSeparator}
          variant="contained"
          color="primary"
        >
          <Button
            style={{ paddingLeft: 50, paddingRight: 50 }}
            fullWidth
            color="primary"
            size="small"
            startIcon={<LocalPostOffice />}
            onClick={onSend}
          >
            {deliveryDate
              ? `Send SMS on ${formatDate(deliveryDate, 'MMMM d, yyyy h:mm a')}`
              : `Send SMS`}
          </Button>

          <Button
            size="small"
            color="primary"
            onClick={() => setIsDatePickerOpen(true)}
          >
            <AccessTime />
          </Button>
        </ButtonGroup>

        {deliveryDate ? (
          <Button
            color="secondary"
            size="small"
            variant="text"
            onClick={() => onChangeDeliveryDate(null)}
          >
            <Typography variant="subtitle1">Reset Date</Typography>
          </Button>
        ) : null}

        <DateTimePicker
          autoOk
          minutesStep={5}
          PopoverProps={{
            anchorEl: anchorRef?.current
          }}
          open={isDatePickerOpen}
          disablePast
          format="PPp"
          name="from"
          variant="inline"
          clearable
          animateYearScrolling
          minDate={Date.now()}
          value={deliveryDate}
          onClose={() => setIsDatePickerOpen(false)}
          onChange={onChangeDeliveryDate}
          InputProps={{ style: { display: 'none' } }}
        />
      </div>
    </div>
  );
};
