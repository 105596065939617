// @ts-nocheck INTENTED FOR AxiosRequestConfig

import {
  CLIENT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  SERVER_ERROR,
  TIMEOUT_ERROR,
  create,
  ApiResponse
} from 'apisauce';
import { AxiosRequestConfig } from 'axios';
import { appInformation, appUrls, localize } from 'src/constants';
import LocalStorageService from 'src/services/LocalStorageService';
import { CommonAxiosResponse } from 'src/types';

const api = create({
  baseURL: appUrls.baseUrl || '',
  timeout: 30000
});

const getToken = () => {
  const userInfo = LocalStorageService.getItem('userInfo', false);

  if (userInfo) {
    const token = LocalStorageService.getSecureItem({
      key: 'auth-token',
      fallbackValue: '',
      password: 'malformedUTF8 if not string'
    });
    if (token) {
      return `Bearer ${token}`;
    }
  }
  return '';
};

const responseHandler = async (response: ApiResponse<any>) => {
  let handledResponse: CommonAxiosResponse<any> = {
    success: false,
    message: '',
    errors: null,
    originalData: null
  };

  // Check if unauthenticated on others endpoints except login.
  if (!response?.ok && response?.status === 401) {
    alert('Unauthenticated or session expired, Please login again.');
    window.location.href = '/login';
    LocalStorageService.localStorageClear();
  }

  if (!response.ok && response.problem) {
    handledResponse.success = false;
    handledResponse.originalData = null;

    if (response.problem === NETWORK_ERROR) {
      handledResponse.message = localize.ERR_API_NETWORK;
    }
    if (response.problem === SERVER_ERROR) {
      handledResponse.message = localize.ERR_API_SERVER;
    }
    if (response.problem === TIMEOUT_ERROR) {
      handledResponse.message = localize.ERR_API_TIMEOUT;
    }
    if (response.problem === CONNECTION_ERROR) {
      handledResponse.message = localize.ERR_API_SERVER_NA;
    }
    if (response.problem === CLIENT_ERROR) {
      handledResponse.message =
        response.data.message ||
        response.data.error ||
        localize.ERR_API_UNKNOWN;
    }
    return handledResponse;
  }

  if (response.ok && !response?.data?.success) {
    handledResponse = {
      success: false,
      errors: response?.data?.errors || null,
      message: response?.data?.message ? response?.data?.message : '',
      originalData: response?.data ? response.data : null
    };
    return handledResponse;
  }

  handledResponse = {
    success: true,
    message: response?.data?.message ? response?.data?.message : '',
    originalData: response?.data ? response.data : null
  };

  return handledResponse;
};

export const defaultHeaders = {
  Accept: 'application/json',
  AppName: appInformation?.name || '',
  AppVersion: appInformation?.version || ''
};

const apiService = {
  get: async (path: string, params = {}, axiosConfig?: AxiosRequestConfig) => {
    const getResponse = await api.get(path, params, {
      ...axiosConfig,
      headers: {
        ...defaultHeaders,
        Authorization: await getToken()
      }
    });
    const responseHandlerResponse = await responseHandler(getResponse);
    return responseHandlerResponse;
  },

  patch: async (
    path: string,
    params = {},
    axiosConfig?: AxiosRequestConfig
  ) => {
    const patchResponse = await api.patch(path, params, {
      ...axiosConfig,
      headers: {
        ...defaultHeaders,
        Authorization: await getToken()
      }
    });
    const responseHandlerResponse = await responseHandler(patchResponse);
    return responseHandlerResponse;
  },

  post: async (path: string, params = {}, axiosConfig?: AxiosRequestConfig) => {
    // ARRANGEMENT HERE OF axiosConfig IS IMPORTANT (SPECIFICALLY IN LOGIN API)
    const postResponse = await api.post(path, params, {
      headers: {
        ...defaultHeaders,
        Authorization: await getToken()
      },
      ...axiosConfig,
    });
    const responseHandlerResponse = await responseHandler(postResponse);
    return responseHandlerResponse;
  },

  delete: async (
    path: string,
    params = {},
    axiosConfig?: AxiosRequestConfig
  ) => {
    const deleteResponse = await api.delete(path, params, {
      ...axiosConfig,
      headers: {
        ...defaultHeaders,
        Authorization: await getToken()
      }
    });
    const responseHandlerResponse = await responseHandler(deleteResponse);
    return responseHandlerResponse;
  }
};

export default apiService;
