import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import { RenderCurrency } from './RenderCurrency';
import { TextBoldWarranty } from './TextWarranty';
import { ViewWarrantyRow } from './ViewWarranty';

interface Props {
  pageNo?: number;
  pageTotal?: number;
  totalAmount?: number;
  isSIPricing?: boolean;
  isBlankPage?: boolean;
}

const styles = StyleSheet.create({
  root: {
    borderWidth: 1
  },
  pageNoContainer: {
    flex: 3.5,
    paddingVertical: 4,
    borderRightWidth: 1
  },
  totalContainer: {
    flex: 2,
    paddingVertical: 4
  },
  paddingLeft4: {
    paddingLeft: 4
  }
});

export const RenderPageNoAndTotal = ({
  pageNo = 0,
  pageTotal = 0,
  totalAmount = 0,
  isSIPricing = false,
  isBlankPage = false
}: Props) => {
  return (
    <ViewWarrantyRow style={styles.root}>
      <View style={styles.pageNoContainer}>
        {isBlankPage ? null : (
          <TextBoldWarranty style={styles.paddingLeft4}>
            {`PAGE ${pageNo} OF ${pageTotal}`}
          </TextBoldWarranty>
        )}
      </View>

      <ViewWarrantyRow style={styles.totalContainer}>
        <TextBoldWarranty style={styles.paddingLeft4}>TOTAL: </TextBoldWarranty>
        {isBlankPage ? null : (
          <RenderCurrency isSIPricing={isSIPricing} amount={totalAmount || 0} />
        )}
      </ViewWarrantyRow>
    </ViewWarrantyRow>
  );
};
