import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, PackageDeleteResponse } from 'src/types';

export const packageDeleteThunk = createAsyncThunk<
    CommonAxiosResponse<PackageDeleteResponse>,
    string,
    { state: RootState }
>('pc-package/packageDeleteThunk', async (id) => {
    const response = await Api.delete(`/ecomm/internal/pc_package/delete/${id}`);
    return response;
});
