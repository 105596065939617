import React from 'react';
import { makeStyles, TableBody, TableCell } from '@material-ui/core';

import { Sale } from 'src/types';
import { slices, useAppSelector } from 'src/redux';
import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import { SalesProductsTableCell } from './SalesProductsTableCell';
import { SalesPaymentsTableCell } from './SalesPaymentsTableCell';
import { SalesAmountTableCell } from './SalesAmountTableCell';
import { useNavigate } from 'react-router-dom';

const { selectors: salesSelectors } = slices.sales;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  salesCard: {
    height: '100%'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  priceInput: {
    height: 30
  }
}));

const TableSalesBody = () => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const navigate = useNavigate();

  const sales = useAppSelector(salesSelectors.selectAllSales);
  const salesColumns = useAppSelector(salesSelectors.selectSalesColumns);

  // const getItemSerialNo = useCallback((item: Sales) => {
  //   if (item?.product_type === ProductTypeEnum.Consumable) {
  //     return '--';
  //   }
  //   if (item?.serial_no) {
  //     return item?.serial_no;
  //   }
  //   return '--';
  // }, []);

  return (
    <TableBody>
      {sales?.map((item: Sale, i: number) => (
        <AlternatingColorTableRow hover key={`${item?.id}-${i}`}>
          {salesColumns?.id && <TableCell>{item?.id || '--'}</TableCell>}

          {salesColumns?.date_sold && (
            <TableCell style={{ verticalAlign: 'top' }}>
              {item?.date_sold}
            </TableCell>
          )}

          {salesColumns?.date_purchased && (
            <TableCell style={{ verticalAlign: 'top' }}>
              {item?.date_purchased}
            </TableCell>
          )}

          {salesColumns?.branch && (
            <TableCell style={{ verticalAlign: 'top' }}>
              {item?.branch}
            </TableCell>
          )}

          {salesColumns?.customer_name && (
            <TableCell style={{ verticalAlign: 'top' }}>
              {item?.customer_name || '--'}
            </TableCell>
          )}

          {salesColumns?.order_no && (
            <TableCell style={{ verticalAlign: 'top' }}>
              {item?.order_no || '--'}
            </TableCell>
          )}

          {salesColumns?.transaction_no && (
            <TableCell style={{ verticalAlign: 'top' }}>
              <LinkComponent
                onClick={() =>
                  navigate(`/app/transaction/${item?.transaction_no}`)
                }
                href={`/app/transaction/${item?.transaction_no}`}
                title={`${item?.transaction_no ?? '--'}`}
              />
            </TableCell>
          )}

          {salesColumns?.warranty_slip_no && (
            <TableCell style={{ verticalAlign: 'top' }}>
              {item?.warranty_slip_no || '--'}
            </TableCell>
          )}

          {salesColumns?.acknowledgement_receipt_no && (
            <TableCell style={{ verticalAlign: 'top' }}>
              {item?.acknowledgement_receipt_no || '--'}
            </TableCell>
          )}

          {salesColumns?.products && (
            <SalesProductsTableCell products={item?.items} />
          )}

          {salesColumns?.payments && (
            <SalesPaymentsTableCell payments={item?.payments} />
          )}

          {salesColumns?.amounts && <SalesAmountTableCell sale={item} />}
        </AlternatingColorTableRow>
      ))}
    </TableBody>
  );
};

export const SalesTableBody = React.memo(TableSalesBody);
