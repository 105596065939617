import { PartFields } from 'src/types';
import { CasingPartDetailsFields } from 'src/types';

export const casingPartsFields: PartFields<CasingPartDetailsFields>[] = [
  {
    label: 'Form Factor',
    part_property: 'form_factor',
    type: 'string',
    options: ['M-ATX', 'ATX', 'ITX', 'EATX'],
    transformRule: ['all-caps', 'no-spaces'],
    required: true
  },
  {
    label: 'Material',
    part_property: 'material',
    hint: 'Steel, Titanium, Plastic',
    options: [
      'Stainless Steel',
      'Duplex Alloys',
      'Nickel',
      'Nickel-Base Alloys',
      'Titanium ',
      'Titanium Alloys',
      'Plastics',
      'Ceramic Lining'
    ],
    type: 'string',
    required: false
  },
  {
    label: 'Color',
    part_property: 'color',
    type: 'string',
    required: false,
    options: ['Black', 'White', 'Pink']
  },
  // {
  //   label: 'ARGB',
  //   part_property: 'argb',
  //   type: 'string',
  //   required: true
  // },
  {
    label: 'Image',
    part_property: 'image_upload',
    hint: 'Select Product Image. Only one is allowed for now',
    type: 'img',
    required: false
  },
  {
    label: 'GPU Length (mm)',
    part_property: 'gpu_length',
    hint: 'enter GPU length',
    type: 'number',
    required: true
  }
];
