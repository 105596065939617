// used for parts details
// will return category name in lower case via category iD
// 'casing' | 'cpu' | 'gpu' | 'hdd' | 'motherboard' | 'psu' | 'ram' | 'ssd';
export const getCategoryNameViaId = (categoryId?: number) => {
  switch (categoryId) {
    case 20:
      return 'casing';
    case 16:
      return 'cpu';
    case 21:
      return 'gpu';
    case 15:
      return 'hdd';
    case 17:
      return 'motherboard';
    case 18:
      return 'psu';
    case 13:
      return 'ram';
    case 14:
      return 'ssd';
    default:
      return '';
  }
};
