import { CategoriesEnum } from 'src/enums';
import { QuotationCategory } from 'src/types';

export const mockQuotationCategory: QuotationCategory[] = [
  {
    category_id: CategoriesEnum.GPU,
    category_name: 'GPU'
  },
  {
    category_id: CategoriesEnum.CPU,
    category_name: 'CPU'
  },
  {
    category_id: CategoriesEnum.Monitor,
    category_name: 'Monitor'
  },
];
