import { Box, Container, makeStyles } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { LoaderBar, Page } from 'src/components';
import Results from './Results';
import Toolbar from './Toolbar';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { AccountProfileData, CustomInputEvent } from 'src/types';
import { Pagination } from '@material-ui/lab';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const { actions: getPcwAccountActions } = slices.pcwUserAccounts;

// const pageLimit = 100;

const PcwUserList = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [pcwUserAccount, setPcwUserAccount] = useState<any>([{}]);
  const [input, setInput] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState<number>();

  const getUserAccountList = useCallback(
    async (page_number = 1) => {
      setIsLoading(true);
      const payload = {
        keyword: input,
        sort: 'asc',
        sort_field: 'first_name',
        limit: 15,
        page: page_number
      };

      const response = unwrapResult(
        await dispatch(getPcwAccountActions.getPcwUserAccount(payload))
      );
      if (response.success) {
        setPcwUserAccount(response?.originalData?.data);
        setIsLoading(false);
        setLastPage(response?.originalData?.meta?.last_page);
      }
    },
    [dispatch, input]
  );

  const onSearchCustomer = () => {
    getUserAccountList();
  };

  const onToolbarInputChange = (e: CustomInputEvent) => {
    const { value } = e.target;
    setInput(value);
  };

  const handlePageChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
    getUserAccountList(value);
  };

  const onAccountProfilePress = (item: AccountProfileData) => {
    navigate(`/app/pcw-user/user-profile/${item.uuid}`);
  };

  useEffect(() => {
    getUserAccountList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Page className={classes.root} title="PCW Users">
      <Container maxWidth={false}>
        <Toolbar
          input={input}
          onToolbarInputChange={onToolbarInputChange}
          onClick={() => {}}
          onSearchCustomer={onSearchCustomer}
        />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          {/* <Results onCustomerPress={onCustomerPress} customers={customers} /> */}
          <Results
            onCustomerPress={onAccountProfilePress}
            pcwUser={pcwUserAccount}
          />
        </Box>
      </Container>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Pagination count={lastPage} page={page} onChange={handlePageChange} />
      </Box>
    </Page>
  );
};

export default PcwUserList;
