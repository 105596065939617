import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useMemo, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { useAlertGlobal } from '../use-global-alert';
import { useSnackBar } from '../use-snackbar';
import { cloneDeep } from 'lodash';
import {
  GetNonSnProductPayload,
  ReceivedNonSnPayload,
  ReceivedSerializedPayload
} from 'src/redux/slices/stock-transfer';

const {
  selectors: stockTransferSelector,
  actions: stockTransferAction
} = slices.stockTransfer;

export const useStockTransferReceiver = () => {
  const dispatch = useAppDispatch();
  const alertGlobal = useAlertGlobal();
  const snackBar = useSnackBar();

  const [isNonSnModal, setIsNonSnModal] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const isTransferStockRecordLoading = useAppSelector(
    stockTransferSelector?.selectTransferStockRecordsLoading
  );

  const transferStockReceivingRecordProducts = useAppSelector(
    stockTransferSelector?.selectTransferStockReceivingProducts
  );

  const transferStockCurrentNumber = useAppSelector(
    stockTransferSelector?.selectCurrentTransferStockNumber
  );

  const receivedStatus = useAppSelector(
    stockTransferSelector?.selectReceivedStatus
  );

  const transferStockProductsReceivedStatus = useAppSelector(
    stockTransferSelector?.transferStockStatusProductReceived
  );

  const transferStockNonSnStNos = useAppSelector(
    stockTransferSelector?.transferStockNonSnStNos
  );

  const transferReceivingSnErrorList = useAppSelector(
    stockTransferSelector?.transferReceivingSnErrors
  );

  const receivedStatusInfo: any = useMemo(() => {
    switch (receivedStatus) {
      case 'Received':
        return {
          severity: 'success',
          message: 'Status: All item is received'
        };
      case 'Received with issue':
        return {
          severity: 'warning',
          message: 'Status: Some of the item is not yet receive'
        };
      case 'Failed':
        return {
          severity: 'info',
          message: 'Status: To be receive'
        };
      default:
        return {
          severity: 'error',
          message: 'Unknown status'
        };
    }
  }, [receivedStatus]);

  const hasTransferReceiverItems = useMemo(() => {
    return transferStockReceivingRecordProducts?.length > 0 || false;
  }, [transferStockReceivingRecordProducts.length]);

  const loopErrorMessages = useCallback(
    (errorMessage: any[], message?: string) => {
      errorMessage.forEach((item: any, index: number) => {
        setTimeout(() => {
          snackBar.show({
            severity: 'error',
            message: item.join(',') || message,
            useSound: true
          });
        }, index * 1500);
      });
    },
    [snackBar]
  );

  //get record of Stock Transferred items
  const getStockTransferRecords = useCallback(
    async (st_no?: string) => {
      dispatch(stockTransferAction?.actions?.setCurrentSTNumber(st_no || ''));

      try {
        const response = unwrapResult(
          await dispatch(
            stockTransferAction?.getTransferStockRecordThunk({ st_no })
          )
        );

        if (response?.errors) {
          const { st_no } = response?.errors;
          const stErrors = st_no.join('\n');

          alertGlobal.show({
            title: 'Error Scanning Stock Transfer Bar Code',
            subtitle: `${stErrors}`
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [alertGlobal, dispatch]
  );

  const checkTransferReceivingItemStatus = useCallback(
    async (serial_no?: string) => {
      const transferrStockRecordProdClone = cloneDeep(
        transferStockReceivingRecordProducts
      );

      //we sure to return single object in array
      const [
        filteredObjectProductRecord
      ] = transferrStockRecordProdClone.filter(
        (item) => item?.serial_no === serial_no
      );

      try {
        const response = unwrapResult(
          await dispatch(
            stockTransferAction?.updateTransferStockReceivingStatusThunk({
              st_no: transferStockCurrentNumber,
              listing_id: filteredObjectProductRecord?.listing_id || 0 //0 means not found
            })
          )
        );
        if (response?.success) {
          const { message } = response;
          snackBar.show({
            severity: 'success',
            message: message,
            useSound: true
          });
          //update the mark received status
          dispatch(
            stockTransferAction?.actions?.updateProductStatus(serial_no || '')
          );
        }
        if (response?.errors) {
          const { listing_id } = response?.errors;
          const updateError = listing_id?.join('\n');

          snackBar.show({
            severity: 'error',
            message: `${updateError}`,
            useSound: true
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [
      dispatch,
      snackBar,
      transferStockCurrentNumber,
      transferStockReceivingRecordProducts
    ]
  );

  //transfer receiving revamp
  const updateReceiveSerializeProduct = useCallback(
    async (params: ReceivedSerializedPayload) => {
      try {
        const response = unwrapResult(
          await dispatch(
            stockTransferAction?.updateReceiveSerializedItemsThunk(params)
          )
        );

        if (response?.success) {
          // Remove from error list
          const newTransferListError = transferReceivingSnErrorList.filter(
            (errSn) => errSn?.serial_no !== params?.serial_no
          );

          dispatch(
            stockTransferAction?.actions?.removeErrorReceivingAction(
              newTransferListError
            )
          );

          snackBar.show({
            severity: 'success',
            message: response?.message,
            useSound: true
          });
        } else {
          if (
            params?.serial_no &&
            !transferReceivingSnErrorList.find(
              (item) => item.serial_no === params.serial_no
            )
          ) {
            dispatch(
              stockTransferAction?.actions?.addErrorReceivingSnAction([params])
            );
          }

          const { message, errors } = response;
          const errorMessages = Object.values(errors);
          loopErrorMessages(errorMessages, message);
        }
      } catch (error) {
        console.error(error);

        snackBar.show({
          severity: 'error',
          message: 'Something went wrong',
          useSound: true
        });
      }
    },
    [dispatch, loopErrorMessages, snackBar, transferReceivingSnErrorList]
  );

  const updateReceiveNonSerializedItems = useCallback(
    async (params: ReceivedNonSnPayload) => {
      try {
        const response = unwrapResult(
          await dispatch(
            stockTransferAction?.updateReceiveNonSerializedItemsThunk(params)
          )
        );

        if (response?.success) {
          setIsNonSnModal(false);
          setIsSuccess(true);
          snackBar.show({
            severity: 'success',
            message: response?.message,
            useSound: true
          });
        }
        if (!response?.success) {
          setIsSuccess(false);
          const { message, errors } = response;
          const errorMessages = Object.values(errors);
          loopErrorMessages(errorMessages, message);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, loopErrorMessages, snackBar]
  );

  const getNonSnProductsReceiving = useCallback(
    async (params: GetNonSnProductPayload) => {
      if (!params?.product_id) {
        return;
      }
      try {
        const response = unwrapResult(
          await dispatch(
            stockTransferAction?.getStNosNonSnProductsThunk(params)
          )
        );

        if (response?.success) {
          setIsNonSnModal(true);
          snackBar.show({
            severity: 'success',
            message: 'Stock transfer number(s) found',
            useSound: true
          });
        }
        if (!response?.success) {
          setIsNonSnModal(false);
          const { message, errors } = response;
          const errorMessages = Object.values(errors);
          loopErrorMessages(errorMessages, message);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, loopErrorMessages, snackBar]
  );

  const clearReceivingProductList = () => {
    dispatch(stockTransferAction?.actions?.clearCurrentReceivingProductList());
  };

  const removeTransferReceivingSnErrors = (serial_no?: string) => {
    const filterRcceivingSnErrors = transferReceivingSnErrorList?.filter(
      (errSn) => errSn.serial_no !== serial_no
    );

    dispatch(
      stockTransferAction?.actions?.removeErrorReceivingAction(
        filterRcceivingSnErrors
      )
    );
  };

  const clearAllTransferReceivingSnErrors = () => {
    dispatch(stockTransferAction?.actions?.removeErrorReceivingAction([]));
  };

  return {
    isTransferStockRecordLoading,
    transferReceivingSnErrorList,

    hasTransferReceiverItems,
    transferStockReceivingRecordProducts,
    transferStockCurrentNumber,
    receivedStatusInfo,

    isNonSnModal,
    isSuccess,
    transferStockProductsReceivedStatus,
    transferStockNonSnStNos,
    updateReceiveSerializeProduct,
    updateReceiveNonSerializedItems,
    getNonSnProductsReceiving,
    setIsNonSnModal,
    setIsSuccess,

    getStockTransferRecords,
    checkTransferReceivingItemStatus,
    clearReceivingProductList,
    removeTransferReceivingSnErrors,
    clearAllTransferReceivingSnErrors
  };
};
