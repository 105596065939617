import { CPUPartDetailsFields, PartFields } from 'src/types';

export const cpuPartsFields: PartFields<CPUPartDetailsFields>[] = [
  {
    label: 'Socket',
    hint:
      'Be careful on spaces and CAPITAL LETTERS only. Eg: LGA1200, AM4, FM2',
    part_property: 'socket',
    type: 'string',
    required: true,
    transformRule: ['all-caps', 'no-spaces']
  },
  {
    label: 'No of Cores',
    part_property: 'no_of_cores',
    type: 'number',
    required: true
  },
  {
    label: 'No of Threads',
    part_property: 'no_of_threads',
    type: 'number',
    required: true
  },
  {
    label: 'Core Clock',
    part_property: 'core_clock',
    unit: 'Ghz',
    hint: '3.5Ghz, 3.7Ghz, 3.6Ghz',
    type: 'number',
    required: true
  },
  {
    label: 'Core Boost',
    part_property: 'core_boost',
    unit: 'Ghz',
    hint: '3.5Ghz, 3.7Ghz, 3.6Ghz',
    type: 'number',
    required: false
  },
  {
    label: 'TDP',
    part_property: 'tdp',
    hint: '125W, 75W',
    unit: 'W',
    type: 'number',
    required: true
  },
  {
    label: 'Chipset',
    part_property: 'chipset',
    type: 'multiple',
    required: true,
    transformRule: ['all-caps', 'no-spaces'],
    hint: 'Has to be multiple! eg: A300, X300, A320'
  },
  {
    label: 'Supported RAM(s)',
    part_property: 'ram_support',
    type: 'multiple',
    hint: 'Has to be multiple!',
    transformRule: ['all-caps', 'no-spaces'],
    options: ['DDR6', 'DDR4', 'DDR5', 'DDR3'],
    required: true
  },
  {
    label: 'Integrated Graphics',
    part_property: 'integrated_graphics',
    type: 'string',
    required: false
  },
  {
    label: 'Image',
    part_property: 'image_upload',
    hint: 'Select Product Image. Only one is allowed for now',
    type: 'img',
    required: false
  }
];
