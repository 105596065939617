import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import { BlogCategoryState } from './types';

const initialState: BlogCategoryState = {
  blogCategoryListLoading: false,
  getBlogCategory: {}
};

const slice = createSlice({
  name: 'blog-category',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getBlogCategoryThunk.pending, (state) => {
      state.blogCategoryListLoading = true;
    });
    builder.addCase(thunks.getBlogCategoryThunk.rejected, (state) => {
      state.blogCategoryListLoading = false;
    });
    builder.addCase(
      thunks.getBlogCategoryThunk.fulfilled,
      (state, { payload }) => {
        state.blogCategoryListLoading = false;
        state.getBlogCategory = payload?.originalData || {};
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
