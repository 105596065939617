import React, { FC, memo } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Typography,
  colors
} from '@material-ui/core';
import { BlogData } from 'src/redux/slices/blogs/types';
import { useNavigate } from 'react-router';

interface Props {
  blogList?: BlogData;
  uuid?: string;
}

const component: FC<Props> = ({ blogList, uuid }) => {
  const navigate = useNavigate();

  const onHandleNavigate = () => {
    navigate(`/app/blog/details/${uuid}`);
  };

  return (
    <Card style={{ marginTop: '0.5rem', marginBottom: '0.5rem' }}>
      <CardActionArea onClick={onHandleNavigate}>
        <CardContent>
          <Box sx={{ p: 0 }}>
            <Typography variant="h5">{blogList?.title}</Typography>
            <span style={{ display: 'flex', flexDirection: 'row' }}>
              <Typography variant="h6">{`Published: `}</Typography>
              <Typography
                variant="h6"
                style={{
                  color:
                    blogList?.published === 'Active'
                      ? colors.green[900]
                      : colors.red[900]
                }}
              >
                {`(${blogList?.published})`}
              </Typography>
            </span>
            <Typography variant="subtitle1">{`Content Type: ${blogList?.content_type}`}</Typography>
            <Typography variant="subtitle1">{`Category: ${blogList?.category}`}</Typography>
            <Typography variant="subtitle1">{`Date Created: ${blogList?.created}`}</Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export const BlogListCard = memo(component);
