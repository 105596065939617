import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { CheckBoxLabel, LinkComponent, LoaderBar, Page } from 'src/components';

import {
  AssignPermissionToUserRequest,
  AssignRoleToUserRequest,
  Branch,
  Permission,
  Role
} from 'src/types';
import FormAddDialog from 'src/components/dialogs/FormDialog';
import { useNavigate, useParams } from 'react-router-dom';
import { UserBasicInfo } from 'src/types/user';
import UserDetailCard from './components/UserDetailCard';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackBar } from 'src/hooks';
import { cloneDeep } from 'lodash';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';
import usePermsAndRolesSearch from 'src/hooks/use-perms-and-roles-search';
import { SearchBox } from 'src/components/SearchBox';

const { actions: userActions, selectors: userSelectors } = slices.user;
const { actions: roleActions } = slices.roles;
const { actions: branchActions } = slices.branch;

const { actions: permissionActions } = slices.permissions;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  tableRow: {
    height: 35,
    padding: 10
  },
  tableTitle: {
    marginTop: '2em'
  },
  permissionBox: {
    padding: theme.spacing(3)
  },
  searchText: {
    width: '200px',
    marginRight: '1em'
  }
}));

const UserDetailView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const {
    branches,
    getBranches,
    selectedBranches,
    setSelectedBranches
  } = useBranchInfo();
  const {
    roles,
    permissions,
    filteredPerms,
    filteredRoles,
    roleInputVal,
    permsInputVal,
    onChangePermsInput,
    onChangeRoleInput,
    onSearchPerms,
    onSearchRole
  } = usePermsAndRolesSearch();

  const { id } = useParams();

  // Account that is used on login.
  const accountNow = useAppSelector(userSelectors.selectUserInfo);

  const [branchesLoading, setBranchesLoading] = useState<boolean>(false);
  const [branchItemLoading, setBranchItemLoading] = useState<number>();

  const [permissionsLoading, setPermissionsLoading] = useState<boolean>(false);
  const [permissionItemLoading, setPermissionItemLoading] = useState<number>();
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    []
  );
  const [addPermissionLoading, setAddPermissionLoading] = useState(false);
  const [isCreatePermissionVisible, setIsCreatePermissionVisible] = useState<
    boolean
  >(false);

  const [rolesLoading, setRolesLoading] = useState<boolean>(false);
  const [roleItemLoading, setRoleItemLoading] = useState<number>();
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [addRoleLoading, setAddRoleLoading] = useState(false);

  const [userLoading, setUserLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<UserBasicInfo>();
  const [isCreateRoleVisible, setIsCreateRoleVisible] = useState<boolean>(
    false
  );

  // Checks if trying to edit the account that is logged in
  const isOwnAccount = useMemo(() => userDetails?.id === accountNow?.id, [
    accountNow,
    userDetails
  ]);

  const renderBranches = async () => {
    setBranchesLoading(true);
    await getBranches();
    setBranchesLoading(false);
  };

  const isBranchSelected = useCallback(
    (item: Branch) => {
      const index = selectedBranches?.findIndex((x) => x.id === item?.id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    [selectedBranches]
  );

  const isBranchItemLoading = useCallback(
    (item: Branch) => item?.id === branchItemLoading,
    [branchItemLoading]
  );

  const onToggleBranch = async (item: Branch, newValue: boolean) => {
    if (!id || isNaN(+id)) {
      // Invalid `id` on URL params
      return;
    }

    const clonedSelectedBranches = cloneDeep(selectedBranches);
    const userId = +id;
    const branchId = item?.id;

    if (!branchId) {
      return;
    }

    setBranchItemLoading(branchId);

    if (newValue) {
      // Assign Branch Item
      dispatch(
        branchActions.assignBranchToUserThunk({
          data: [{ user_id: userId, branch_id: branchId }]
        })
      ).finally(() => setBranchItemLoading(undefined));

      // Reflect assigned branch visually
      clonedSelectedBranches?.push(item);
      setSelectedBranches(clonedSelectedBranches);
    } else {
      // Unassign Branch Item
      const branchPivotIDs = selectedBranches
        .filter((b) => b.id === branchId)
        .map((b) => b?.pivot?.id);

      dispatch(
        branchActions.removeBranchesFromUserThunk({
          user_branch_ids: branchPivotIDs
        })
      ).finally(() => setBranchItemLoading(undefined));

      // Reflect removed branch visually
      const filtered = clonedSelectedBranches?.filter((x) => x.id !== branchId);
      setSelectedBranches(filtered);
    }
  };

  const getUserDetails = useCallback(async () => {
    if (!id) {
      return;
    }
    const userId = +id;
    if (!userId || isNaN(userId)) {
      return;
    }
    setUserLoading(true);
    const response = unwrapResult(
      await dispatch(userActions.getUserThunk(userId)).finally(() =>
        setUserLoading(false)
      )
    );
    if (response?.success && response?.originalData?.user) {
      setUserDetails(response?.originalData?.user);
      setSelectedRoles(response.originalData?.user?.all_roles || []);
      setSelectedPermissions(
        response.originalData?.user?.permissions_via_direct || []
      );
      setSelectedBranches(response.originalData?.user?.branches || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id]);

  const getAllPermissions = useCallback(async () => {
    setPermissionsLoading(true);
    await dispatch(permissionActions.getPermissionsThunk()).finally(() =>
      setPermissionsLoading(false)
    );
  }, [dispatch]);

  const onOpenCreatePermissionDialog = () => {
    setIsCreatePermissionVisible(true);
  };

  const isPermissionSelected = useCallback(
    (item: Permission) => {
      const index = selectedPermissions?.findIndex((x) => x.id === item?.id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    [selectedPermissions]
  );

  const onTogglePermissionApi = async (
    permission_id: number,
    user_id: number,
    newValue: boolean
  ) => {
    const data: AssignPermissionToUserRequest = {
      permission_id,
      user_id
    };
    setPermissionItemLoading(permission_id);
    if (newValue) {
      // If adding permission
      return dispatch(
        permissionActions.assignPermissionToUserThunk(data)
      ).finally(() => setPermissionItemLoading(undefined));
    }
    // If revoking permission
    return dispatch(
      permissionActions.revokePermissionToUserThunk(data)
    ).finally(() => setPermissionItemLoading(undefined));
  };

  const isRoleItemLoading = useCallback(
    (item: Role) => item?.id === roleItemLoading,
    [roleItemLoading]
  );

  const onTogglePermission = async (item: Permission, newValue: boolean) => {
    if (!id) {
      return;
    }
    const clonedSelectedPermissions = cloneDeep(selectedPermissions);
    const userId = +id;
    // Disabling role 'owner' is not allowed on currently logged in account.
    if (isOwnAccount && item?.name === 'edit_permissions_roles' && !newValue) {
      snackBar.show({
        severity: 'error',
        message:
          "Disabling permissions 'edit_permissions_roles' is not allowed on currently logged in account."
      });
      return;
    }

    if (isNaN(userId)) {
      // Invalid `id` on url params
      return;
    }
    if (item?.id) {
      onTogglePermissionApi(item?.id, userId, newValue);
      if (newValue) {
        // If toggle to be checked
        clonedSelectedPermissions?.push(item);
        setSelectedPermissions(clonedSelectedPermissions);
        return;
      }
      // If toggle to be unchecked
      const filtered = clonedSelectedPermissions?.filter(
        (x) => x.id !== item?.id
      );
      setSelectedPermissions(filtered);
    }
  };

  const getAllRoles = useCallback(async () => {
    setRolesLoading(true);
    await dispatch(roleActions.getRolesThunk()).finally(() =>
      setRolesLoading(false)
    );
  }, [dispatch]);

  const onOpenCreateRoleDialog = () => {
    setIsCreateRoleVisible(true);
  };

  const isRoleSelected = useCallback(
    (item: Role) => {
      const index = selectedRoles?.findIndex((x) => x.id === item?.id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    [selectedRoles]
  );

  const onToggleRoleApi = async (
    role_id: number,
    user_id: number,
    newValue: boolean
  ) => {
    const data: AssignRoleToUserRequest = {
      role_id,
      user_id
    };
    setRoleItemLoading(role_id);
    if (newValue) {
      // If adding permission
      return dispatch(roleActions.assignRoleToUserThunk(data)).finally(() =>
        setRoleItemLoading(undefined)
      );
    }
    // If revoking permission
    return dispatch(roleActions.revokeRoleToUserThunk(data)).finally(() =>
      setRoleItemLoading(undefined)
    );
  };

  const isPermissionItemLoading = useCallback(
    (item: Permission) => item?.id === permissionItemLoading,
    [permissionItemLoading]
  );

  const onCloseCreatePermissionDialog = () => {
    setIsCreatePermissionVisible(false);
  };

  const onAddPermissionOnModalPress = async (name: string) => {
    if (!id) {
      return;
    }
    const userId = +id;

    if (isNaN(userId)) {
      // Invalid `id` on url params
      return;
    }
    if (userId) {
      setAddPermissionLoading(true);

      const response = unwrapResult(
        await dispatch(
          permissionActions.createPermissionThenAddToUserThunk({
            name,
            user_id: userId
          })
        )
      );
      setAddPermissionLoading(false);
      if (response?.success) {
        await getAllPermissions();
        await getUserDetails();
        snackBar.show({
          severity: 'success',
          message: response?.message || 'Permission creation success.'
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || 'Permission creation failed.'
        });
      }
    }

    onCloseCreatePermissionDialog();
  };

  const onToggleRole = async (item: Role, newValue: boolean) => {
    if (!id) {
      return;
    }
    const clonedSelectedRoles = cloneDeep(selectedRoles);
    const userId = +id;

    // Disabling role 'owner' is not allowed on currently logged in account.
    if (isOwnAccount && item?.name === 'owner' && !newValue) {
      snackBar.show({
        severity: 'error',
        message:
          "Disabling role 'owner' is not allowed on currently logged in account."
      });
      return;
    }

    if (isNaN(userId)) {
      // Invalid `id` on url params
      return;
    }
    if (item?.id) {
      onToggleRoleApi(item?.id, userId, newValue);
      if (newValue) {
        // If toggle to be checked
        clonedSelectedRoles?.push(item);
        setSelectedRoles(clonedSelectedRoles);
        return;
      }
      // If toggle to be unchecked
      const filtered = clonedSelectedRoles?.filter((x) => x.id !== item?.id);
      setSelectedRoles(filtered);
    }
  };

  const onCloseCreateRoleDialog = () => {
    setIsCreateRoleVisible(false);
  };

  const onAddRoleOnModalPress = async (name: string) => {
    if (!id) {
      return;
    }
    const userId = +id;

    if (isNaN(userId)) {
      // Invalid `id` on url params
      return;
    }
    if (userId) {
      setAddRoleLoading(true);

      const response = unwrapResult(
        await dispatch(
          roleActions.createRoleThenAddUserThunk({
            name,
            user_id: userId
          })
        )
      );
      setAddRoleLoading(false);
      if (response?.success) {
        await getAllRoles();
        await getUserDetails();
        snackBar.show({
          severity: 'success',
          message: response?.message || 'Role creation success.'
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || 'Role creation failed.'
        });
      }
    }

    onCloseCreateRoleDialog();
  };

  const onDeleteUser = async (user: UserBasicInfo) => {
    if (user?.id) {
      setUserLoading(true);
      const response = unwrapResult(
        await dispatch(userActions.deleteUserThunk(user?.id)).finally(() =>
          setUserLoading(false)
        )
      );
      if (response?.success) {
        snackBar.show({
          severity: 'success',
          message: response?.message || 'User delete success'
        });
        navigate(-1);
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || 'User delete Failed'
        });
      }
    }
  };

  const onSaveDetailPress = async (user: UserBasicInfo) => {
    if (user?.id) {
      setUserLoading(true);
      const response = unwrapResult(
        await dispatch(userActions.updateUserThunk(user))
      );
      if (response?.success) {
        snackBar.show({
          severity: 'success',
          message: response?.message || 'User update success'
        });
        setUserLoading(false);
        getUserDetails();
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || 'User Update Failed'
        });
      }
    }
  };

  const userFullName = useMemo(
    () => `${userDetails?.first_name || ''} ${userDetails?.last_name || ''}`,
    [userDetails]
  );

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  useEffect(() => {
    getAllRoles();
  }, [getAllRoles]);

  useEffect(() => {
    getAllPermissions();
  }, [getAllPermissions]);

  useEffect(() => {
    renderBranches();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title={userFullName}>
      <Container maxWidth={false}>
        <UserDetailCard
          loading={userLoading}
          user={userDetails}
          onDeleteUser={onDeleteUser}
          onSaveDetailPress={onSaveDetailPress}
        />
        {multiBranchFeat ? (
          <Box
            paddingTop={10}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="textPrimary" gutterBottom variant="h4">
              Branches Assigned to {userFullName}
            </Typography>
          </Box>
        ) : null}
        {multiBranchFeat ? (
          <Box mt={2}>
            <LoaderBar isLoading={branchesLoading} />
            <Card className={classes.permissionBox}>
              <PerfectScrollbar>
                <Box minWidth={1050}>
                  <Grid container spacing={2}>
                    {branches?.map((item: Branch, i: number) => (
                      <Grid
                        key={item?.id || i}
                        item
                        xl={2}
                        lg={3}
                        md={6}
                        xs={12}
                      >
                        <CheckBoxLabel
                          label={item?.branch_name}
                          checked={isBranchSelected(item)}
                          disabled={isBranchItemLoading(item)}
                          onChange={(val) => onToggleBranch(item, val)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        ) : null}

        <Typography
          className={classes.tableTitle}
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          Roles Assigned to {userFullName}
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <SearchBox
            options={roles}
            inputValue={roleInputVal}
            onInputChange={onChangeRoleInput}
            onSearchKeyWord={onSearchRole}
            placeholder={'Search Role'}
          />
          <Button
            onClick={onOpenCreateRoleDialog}
            color="primary"
            variant="contained"
          >
            Create Role and assign to user
          </Button>
        </Box>

        <Box mt={2}>
          <LoaderBar isLoading={rolesLoading} />
          <Card className={classes.permissionBox}>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Grid container spacing={2}>
                  {filteredRoles?.map((item: Role, i: number) => (
                    <Grid key={item?.id || i} item xl={2} lg={3} md={6} xs={12}>
                      <CheckBoxLabel
                        label={item?.name}
                        checked={isRoleSelected(item)}
                        disabled={isRoleItemLoading(item)}
                        onChange={(val) => onToggleRole(item, val)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>

        <Box
          paddingTop={5}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="textPrimary" gutterBottom variant="h4">
            Permissions
          </Typography>
        </Box>
        <Typography color="textPrimary" gutterBottom variant="subtitle1">
          Permissions via role
        </Typography>
        <Box mt={2}>
          <Card>
            <LoaderBar isLoading={userLoading} />
            <PerfectScrollbar style={{ height: '30vh' }}>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Permission Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userDetails?.permissions_via_roles?.map(
                      (item: Permission) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <LinkComponent
                              onClick={() =>
                                navigate(`/app/permission/${item.id}`)
                              }
                              href={`/app/permission/${item.id}`}
                              title={`${item?.name || '--'}`}
                            />
                          </TableCell>
                        </TableRow>
                      )
                    )}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>

        <Typography
          className={classes.tableTitle}
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          Direct Permissions Assigned to {userFullName}
        </Typography>
        <Typography color="textPrimary" gutterBottom>
          via direct permissions
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <SearchBox
            options={permissions}
            inputValue={permsInputVal}
            onInputChange={onChangePermsInput}
            onSearchKeyWord={onSearchPerms}
            placeholder={'Search Perms'}
          />
          <Button
            onClick={onOpenCreatePermissionDialog}
            color="primary"
            variant="contained"
          >
            Create Permission and assign to user
          </Button>
        </Box>
        <LoaderBar isLoading={permissionsLoading} />
        <Box mt={2}>
          <Card className={classes.permissionBox}>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Grid container spacing={2}>
                  {filteredPerms?.map((item: Permission, i: number) => (
                    <Grid key={item?.id || i} item xl={2} lg={3} md={6} xs={12}>
                      <CheckBoxLabel
                        disabled={isPermissionItemLoading(item)}
                        label={item?.name}
                        checked={isPermissionSelected(item)}
                        onChange={(val) => onTogglePermission(item, val)}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>

      <FormAddDialog
        fieldName="Role Name"
        title="Create a role"
        loading={addRoleLoading}
        isVisible={isCreateRoleVisible}
        subTitle="Input name for this new Role"
        onAddPress={onAddRoleOnModalPress}
        handleClose={onCloseCreateRoleDialog}
      />

      <FormAddDialog
        fieldName="Permission Name"
        title="Create a permission"
        loading={addPermissionLoading}
        isVisible={isCreatePermissionVisible}
        subTitle="Input name for this new Permission"
        onAddPress={onAddPermissionOnModalPress}
        handleClose={onCloseCreatePermissionDialog}
      />
    </Page>
  );
};

export default UserDetailView;
