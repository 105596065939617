import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectBlogCategory = (state: RootState) => state.blogCategory;

const selectBlogCategoryLoading = createSelector(
  selectBlogCategory,
  (state) => state.blogCategoryListLoading
);

const selectBlogCategoryList = createSelector(
  selectBlogCategory,
  (state) => state.getBlogCategory?.data
);

const selectBlogCategoryMeta = createSelector(
  selectBlogCategory,
  (state) => state.getBlogCategory?.meta
);

export const selectors = {
  selectBlogCategoryLoading,
  selectBlogCategoryList,
  selectBlogCategoryMeta
};
