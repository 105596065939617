import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import {
  CreateCategoryRequest,
  CustomInputEvent,
  CustomKeyboardEvent,
  GetCategoryMainData
} from 'src/types';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import { useSnackBar } from 'src/hooks';
import {
  containsAlphabetsWithSpacesAndDashes,
  isEmptyOrSpaces
} from 'src/utils';
import CategoryAddDialog from 'src/components/dialogs/AddCategoryDialog';
import { isEmpty } from 'lodash';

const { actions: categoryActions } = slices.category;

interface Props {
  className?: string;
  action: 'main' | 'sub';
  onSearchCategory: (text?: string) => void;
  onSearchMainCategory: (text?: string) => void;
  onCategoryRefetch: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  }
}));

const Toolbar = ({
  className,
  action = 'main',
  onCategoryRefetch,
  onSearchCategory,
  onSearchMainCategory,
  ...rest
}: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const dispatch = useAppDispatch();

  const [isCreateCategoryVisible, setIsCreateCategoryVisible] = useState<
    boolean
  >(false);
  const [addCategoryLoading, setAddCategoryLoading] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>();
  const [mainCategoryOption, setMainCategoryOption] = useState<
    GetCategoryMainData[]
  >([]);

  const onOpenCreateCategoryDialog = async () => {
    setIsCreateCategoryVisible(true);
    const response = unwrapResult(
      await dispatch(categoryActions?.getMainCategoryThunk({}))
    );

    if (response?.originalData?.data) {
      setMainCategoryOption(response?.originalData?.data || []);
    }
  };

  const onCloseCreateCategoryDialog = () => {
    setIsCreateCategoryVisible(false);
    setAddCategoryLoading(false);
  };

  const onAddCategoryOnModalPress = async (
    createCategoryParams?: CreateCategoryRequest
  ) => {
    setAddCategoryLoading(true);
    if (
      !createCategoryParams?.name ||
      isEmptyOrSpaces(createCategoryParams?.name) ||
      !containsAlphabetsWithSpacesAndDashes(createCategoryParams?.name)
    ) {
      snackBar.show({
        severity: 'error',
        message: 'Please provide proper category name'
      });
      setAddCategoryLoading(false);
      return;
    }
    if (createCategoryParams?.name) {
      // const newCategoryData = { name };
      const response = unwrapResult(
        await dispatch(
          categoryActions.createCategoryThunk(createCategoryParams)
        )
      );

      if (response.success) {
        snackBar.show({ severity: 'success', message: response.message });
        onCloseCreateCategoryDialog();
        //refetch category list
        onCategoryRefetch();
      } else {
        snackBar.show({
          severity: 'error',
          message: response.errors.name || response.message
        });
        setAddCategoryLoading(false);
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.'
      });
    }
  };

  const handleChange = (event: CustomInputEvent) => {
    const newValue = event.target.value;

    if (newValue.trim().length > 0) {
      setSearchText(newValue);
    } else {
      const newValueWithoutSpaces = newValue.replace(/\s/g, '');
      setSearchText(newValueWithoutSpaces);
    }
  };

  const onHandleSearchClick = () => {
    switch (action) {
      case 'main':
        onSearchMainCategory(searchText);
        break;
      case 'sub':
        onSearchCategory(searchText);
        break;
      default:
        onSearchMainCategory(searchText);
    }
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter' && !isEmpty(searchText)) {
      onHandleSearchClick();
    }
  };

  //call sub category or main category again if keyword is emptied on change
  useEffect(() => {
    if (isEmpty(searchText)) {
      onHandleSearchClick();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  //clear search bar text when changing category tabs
  useEffect(() => {
    setSearchText('');
  }, [action]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography color="textPrimary" gutterBottom variant="h3">
          {'Categories'}
        </Typography>
        <Button
          onClick={onOpenCreateCategoryDialog}
          color="primary"
          variant="contained"
        >
          add category
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={4} md={3} xs={4}>
                <Box maxWidth={500}>
                  <TextField
                    onChange={handleChange}
                    fullWidth
                    value={searchText}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder={`Search ${
                      action === 'main' ? 'Main' : ''
                    } Category`}
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>
              <Grid alignItems="center" item xs={1}>
                <Button
                  onClick={onHandleSearchClick}
                  color="primary"
                  variant="contained"
                  disabled={!searchText}
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <CategoryAddDialog
          loading={addCategoryLoading}
          fieldName="Category Name"
          mainCategOptions={mainCategoryOption || []}
          title="Create Category"
          isVisible={isCreateCategoryVisible}
          subTitle="Input Category"
          onAddPress={(requestParams?: CreateCategoryRequest) =>
            onAddCategoryOnModalPress(requestParams)
          }
          handleClose={onCloseCreateCategoryDialog}
        />
      </Box>
    </div>
  );
};

export default Toolbar;
