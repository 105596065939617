import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import PrintIcon from '@material-ui/icons/Print';
import { CustomKeyboardEvent, Product } from 'src/types';
import { ProductStatusEnum } from 'src/enums/product/status';
import { BranchListDropDown } from 'src/components/dropdown';

interface Props {
  product: Product;
  className?: string;
  sortDir?: string;
  statusFilter?: string | null;
  isLoading?: boolean;
  hasListing?: boolean;
  branchFromPriceList?: number[];
  onSearchListing: () => void;
  onHandleStatusChangeFilter: (status: string) => void;
  onHandleBranchChange: (branchIds?: number[]) => void;
  handleSetSortDir: (e: any) => void;
  handleChangeKeyword: (e: any) => void;
  onPrintPress: () => void;
}

const Toolbar = ({
  className,
  statusFilter,
  isLoading,
  hasListing = false,
  sortDir,
  onSearchListing,
  onHandleStatusChangeFilter,
  handleSetSortDir,
  onHandleBranchChange,
  handleChangeKeyword,
  onPrintPress,
  ...rest
}: Props) => {
  const onSearchClick = () => {
    onSearchListing();
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchListing();
    }
  };

  return (
    <div className={clsx(className)} {...rest}>
      <Box mt={2}>
        <Card style={{ margin: 1 }}>
          <CardContent>
            <Grid
              justifyContent="flex-start"
              alignItems="center"
              container
              spacing={1}
            >
              <Grid item md={3} xs>
                <TextField
                  onChange={handleChangeKeyword}
                  style={{ width: '100%' }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="Search serial no."
                  variant="outlined"
                  onKeyPress={onKeyPress}
                />
              </Grid>
              <Grid item md xs>
                <FormControl fullWidth>
                  <InputLabel id="product-status" variant="outlined">
                    Status Filter
                  </InputLabel>
                  <Select
                    disabled={isLoading}
                    labelId="status-filter"
                    value={statusFilter}
                    onChange={(e: any) => {
                      onHandleStatusChangeFilter(e.target.value);
                    }}
                    input={<OutlinedInput label="product-status" />}
                  >
                    <MenuItem value={'all'}>All Status</MenuItem>
                    {Object.values(ProductStatusEnum).map((status, index) => (
                      <MenuItem key={index} value={status}>
                        {status}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md xs>
                <FormControl fullWidth>
                  <InputLabel variant="outlined">
                    Date Encoded Sort By
                  </InputLabel>
                  <Select
                    disabled={isLoading}
                    value={sortDir}
                    label="Date Encoded Sort By"
                    input={<OutlinedInput label="Date Encoded Sort By" />}
                    onChange={handleSetSortDir}
                  >
                    <MenuItem value={'asc'}>Oldest to Latest</MenuItem>
                    <MenuItem value={'desc'}>Latest to Oldest</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md xs>
                <Box style={{ width: '100%', margin: 0 }}>
                  <BranchListDropDown
                    allowNoSelectedBranch={false}
                    disabled={isLoading}
                    onHandleBranchChange={(branchIds?: number[]) =>
                      onHandleBranchChange(branchIds || [])
                    }
                    isElevated={false}
                    multiple={false}
                  />
                </Box>
              </Grid>
              <Grid item md xs>
                <Button
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                  style={{
                    minWidth: '100px'
                  }}
                >
                  Search
                </Button>
              </Grid>
              <Grid
                item
                md
                xs
                style={{
                  display: hasListing ? 'flex' : 'none',
                  justifyContent: 'flex-end'
                }}
              >
                <Button
                  disabled={isLoading}
                  startIcon={<PrintIcon />}
                  onClick={onPrintPress}
                  color="primary"
                  variant="contained"
                >
                  Print
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default Toolbar;
