import React, { FC, memo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Slide,
  Typography
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import { Tiptap } from 'src/components/rich-text-box';

interface Props {
  isOpen: boolean;
  description?: string;
  productId?: number;
  handleClose: () => void;
  reloadProductDetails: () => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<any>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const component: FC<Props> = ({
  isOpen = false,
  description,
  productId,
  handleClose,
  reloadProductDetails
}) => {
  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <DialogTitle id="blog-content">
        <Typography variant="h2">Blog Content</Typography>
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', right: 5, top: 5 }}
          color="secondary"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Paper elevation={3}>
          <Tiptap
            description={description}
            productId={productId}
            reloadProductDetails={reloadProductDetails}
            variant={'blog'}
          />
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export const BlogContentDialog = memo(component);
