import { createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  CreateGalleryResponse,
  GalleryCreateRequest
} from 'src/types';

export const galleryCreateThunk = createAsyncThunk<
  CommonAxiosResponse<CreateGalleryResponse>,
  GalleryCreateRequest,
  { state: RootState }
>('gallery/createGalleryThunk', async (data) => {
  const clonedDataWithoutPhotos = cloneDeep(data);
  clonedDataWithoutPhotos.photos = undefined;

  const response = await Api.post(`/gallery/create`, clonedDataWithoutPhotos);
  return response;
});
