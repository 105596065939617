import React, { useMemo, useState } from 'react';
import {
  CardContent,
  Divider,
  Grid,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import { DeletableImg, DragAndDropImgUpload } from 'src/components';
import { convertImageFileToBase64 } from 'src/utils';
import { cloneDeep } from 'lodash';
import { ProductImage } from 'src/types';

interface Props {
  data?: ProductImage[];
  isImageUploading?: boolean;
  imgUploadProgress?: number;
  hasDivider?: boolean;

  onPhotosChange: (imgs: ProductImage[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cpuField: {
    borderWidth: 5
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  previewDiv: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  previewImg: {
    height: 150,
    width: undefined
  }
}));

const component = ({
  data = [],
  onPhotosChange,
  isImageUploading,
  imgUploadProgress,
  hasDivider = true
}: Props) => {
  const classes = useStyles();

  const [isInternalLoading, setIsInternalLoading] = useState<boolean>(false);

  const onPhotosChangeInternal = async (img: File[]) => {
    if (img && img?.length > 0) {
      var imgsArr: any[] = await Promise.all(
        img?.map(
          async (item): Promise<any> => {
            const newImg = {
              image_filepath: '',
              url: await convertImageFileToBase64(item)
            };
            return newImg;
          }
        )
      );
      const clonedDataImgs = cloneDeep(data) || [];
      const mergedImgs = [...clonedDataImgs, ...imgsArr];
      onPhotosChange(mergedImgs);
    }
  };

  const onDeleteImage = async (index: number) => {
    const clonedData = cloneDeep(data);
    if (clonedData[index]?.image_filepath) {
      setIsInternalLoading(true);
    }
    clonedData?.splice(index, 1);
    onPhotosChange(clonedData);
  };

  const isOpacityLower = useMemo(
    () => isInternalLoading || imgUploadProgress || isImageUploading,
    [imgUploadProgress, isInternalLoading, isImageUploading]
  );

  return (
    <div>
      {isImageUploading ? (
        <LinearProgress variant="determinate" value={imgUploadProgress} />
      ) : (
        false
      )}
      <CardContent
        style={{
          opacity: isOpacityLower ? 0.3 : 1
        }}
      >
        <Typography className={classes.subHeader} variant="h6">
          Product Images
        </Typography>
        <Grid container spacing={2} className={classes.previewDiv}>
          {data?.map((x, i) => (
            <Grid key={`${x}${i}`} item>
              <DeletableImg
                index={i}
                imgSrc={x?.url}
                onDelete={onDeleteImage}
              />
            </Grid>
          ))}
        </Grid>
        <DragAndDropImgUpload
          title="Drag or select photos"
          onImageSelected={onPhotosChangeInternal}
        />
      </CardContent>
      {hasDivider ? <Divider /> : null}
    </div>
  );
};

export const AddProductImagesInfo = React.memo(component);
