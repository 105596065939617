export enum PriceListItemStatusEnum {
  InStock = 'IN STOCK',
  SoldOut = 'SOLD OUT',
  LowStock = 'LOW STOCK',
  Upcoming = 'UPCOMING'
}

export enum PriceListSortEnum {
  Alphabet = 1,
  StockNo = 2
}
