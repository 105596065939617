import { FormControl, InputLabel, Select } from '@material-ui/core';
import React, { FC, memo, useState } from 'react';

interface Props {
  sortLabel?: string;
  onHandleChangeSortDirection: (sortDirection: 'asc' | 'desc') => void;
}

const sortDirectionVar = { ascending: 'asc', descending: 'desc' };

const component: FC<Props> = ({ sortLabel, onHandleChangeSortDirection }) => {
  const [currentSortDirection, setCurrentSortDirection] = useState<string>(
    sortDirectionVar.ascending
  );

  const onChangeSortDirection = (
    event: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    setCurrentSortDirection(event.target.value);
    onHandleChangeSortDirection(event.target.value);
  };

  return (
    <FormControl variant="outlined" fullWidth>
      <InputLabel htmlFor="sort">{sortLabel}</InputLabel>
      <Select
        native
        fullWidth
        value={currentSortDirection}
        onChange={onChangeSortDirection}
        label={sortLabel}
        inputProps={{
          name: sortLabel,
          id: 'sort'
        }}
      >
        <option value={sortDirectionVar.ascending}>Ascending</option>
        <option value={sortDirectionVar.descending}>Descending</option>
      </Select>
    </FormControl>
  );
};

export const SortDirection = memo(component);
