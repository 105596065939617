import React, { useState } from 'react';
import clsx from 'clsx';

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CustomInputEvent, CustomKeyboardEvent } from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { useSnackBar } from 'src/hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import OptionFormAddDialog from './OptionFormDialog';

const { actions: optionsActions } = slices.options;

interface Props {
  className?: string;
  searchOptions: (text: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  }
}));

const Toolbar = ({
  className,
  searchOptions,
  ...rest
}: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState('');

  const [isCreateOptionsVisible, setIsCreateOptionsVisible] = useState<boolean>(
    false
  );
  const [addOptionsLoading, setAddOptionsLoading] = useState<boolean>(false);

  const onOpenCreateOptionsDialog = () => {
    setIsCreateOptionsVisible(true);
  };

  const onCloseCreateOptionsDialog = () => {
    setIsCreateOptionsVisible(false);
    setAddOptionsLoading(false);
  };

  const addOption = async (name: string, value: string) => {
    setAddOptionsLoading(true);
    if (name && value) {
      const response = unwrapResult(
        await dispatch(optionsActions.createOptionThunk({ name, value }))
      );
      if (response.success) {
        snackBar.show({ severity: 'success', message: response.message });
        onCloseCreateOptionsDialog();
        refreshPage()
      } else {
        snackBar.show({ severity: 'error', message: response.message });
        setAddOptionsLoading(false);
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.'
      });
    }
  };

  const handleChange = (event: CustomInputEvent) => {
    setSearchText(event.target.value);
  };

  const onSearchClick = () => {
    searchOptions(searchText);
  };

  const refreshPage = () => {
    searchOptions('');
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      searchOptions(searchText);
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography color="textPrimary" gutterBottom variant="h3">
          Options
        </Typography>
        <Button
          onClick={onOpenCreateOptionsDialog}
          color="primary"
          variant="contained"
        >
          add option
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid alignItems="center" container spacing={2}>
              <Grid item lg={4} md={6} xs={12}>
                <Box maxWidth={500}>
                  <TextField
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search option"
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>
              <Grid alignItems="center" item xs={1}>
                <Button
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <OptionFormAddDialog
          loading={addOptionsLoading}
          nameField="Option"
          valueField="Value"
          title="Create Option"
          isVisible={isCreateOptionsVisible}
          subTitle="Input Option"
          onAddPress={addOption}
          handleClose={onCloseCreateOptionsDialog}
        />
      </Box>
    </div>
  );
};

export default Toolbar;
