import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {
  Box,
  //   Button,
  CardContent,
  Grid,
  TextField,
  makeStyles,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography
} from '@material-ui/core';
import { ramKitTypeOptions } from 'src/constants';
import { Category, CustomInputEvent, Manufacturer } from 'src/types';
import { normalizeFieldText } from 'src/utils';

// TODO: add proper types.
interface Props {
  isSelectedCategoryRAM: boolean;
  selectedManufacturer: Manufacturer | null;
  setSelectedManufacturer: (v: Manufacturer | null) => void;
  manufacturerInputValue: string;
  setManufacturerInputValue: (v: string) => void;
  manufacturers: Manufacturer[];
  categories: Category[];
  inputValue: string;
  setInputValue: (v: string) => void;
  selectedName: string;
  setSelectedName: (v: string) => void;
  selectedCategory: Category | null;
  setSelectedCategory: (v: any) => void;
  categoryFieldRef: React.Ref<null>;
  selectedRamKitType?: number;
  handleRamKitTypeChange: (v: any) => void;
  manufacturerFieldRef: React.Ref<null>;
  //   onOpenCreateManufacturerDialog: () => void;
  selectedSKU: string;
  setSelectedSKU: (v: string) => void;
  canCheckValidation?: boolean;
  selectedEcDisplayName: string;
  setSelectedEcDisplayName: (v: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  }
}));

const component = ({
  isSelectedCategoryRAM,
  selectedManufacturer,
  setSelectedManufacturer,
  manufacturerInputValue,
  setManufacturerInputValue,
  manufacturers,
  categories,
  inputValue,
  setInputValue,
  selectedName,
  setSelectedName,
  selectedCategory,
  setSelectedCategory,
  categoryFieldRef,
  selectedRamKitType,
  handleRamKitTypeChange,
  manufacturerFieldRef,
  //   onOpenCreateManufacturerDialog,
  selectedSKU,
  setSelectedSKU,
  canCheckValidation,
  selectedEcDisplayName,
  setSelectedEcDisplayName
}: Props) => {
  const classes = useStyles();

  const cleanKeyword = (
    event: CustomInputEvent,
    action?: 'name' | 'ec_name'
  ) => {
    const { value } = event.target;
    if (action === 'name') {
      const cleanKeyword = normalizeFieldText(value);
      setSelectedName(cleanKeyword);
    }
    if (action === 'ec_name') {
      const cleanKeyword = normalizeFieldText(value);
      setSelectedEcDisplayName(cleanKeyword);
    }
  };

  const [errFields, setErrFields] = useState({
    ec_display_name: '',
    name: '',
    category: '',
    manufacturer: ''
  });

  const fieldValidations = [
    {
      field: 'name',
      value: selectedName,
      errorMessage: 'NAME IS REQUIRED'
    },
    {
      field: 'ec_display_name',
      value: selectedEcDisplayName,
      errorMessage: 'ECOMM PRODUCT DISPLAY NAME IS REQUIRED'
    },
    {
      field: 'category',
      value: selectedCategory,
      errorMessage: 'CATEGORY IS REQUIRED'
    },
    {
      field: 'manufacturer',
      value: selectedManufacturer,
      errorMessage: 'MANUFACTURER IS REQUIRED'
    }
  ];

  const areFieldsValid = () => {
    fieldValidations.forEach((validation) => {
      const { field, value, errorMessage } = validation;
      if (!value) {
        setErrFields((prev) => ({ ...prev, [field]: errorMessage }));
      } else {
        setErrFields((prev) => ({ ...prev, [field]: '' }));
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  const validateField = (name: string, value: any) => {
    const targetValidation = fieldValidations.find(
      (validation) => validation.field === name
    );
    if (targetValidation) {
      const { field, errorMessage } = targetValidation;
      if (!value) {
        setErrFields((prev) => ({ ...prev, [field]: errorMessage }));
      } else {
        setErrFields((prev) => ({ ...prev, [field]: '' }));
      }
    }
  };

  useEffect(() => {
    if (canCheckValidation === true) {
      areFieldsValid();
    }
  }, [
    selectedName,
    selectedCategory,
    selectedManufacturer,
    selectedEcDisplayName
  ]);

  useEffect(() => {
    if (canCheckValidation === true) {
      areFieldsValid();
    } else {
      errFields.ec_display_name = '';
      errFields.name = '';
      errFields.category = '';
      errFields.manufacturer = '';
    }
  }, [canCheckValidation]);

  return (
    <CardContent>
      <Typography className={classes.subHeader} variant="h6">
        General Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            error={errFields?.ec_display_name ? true : false}
            helperText={errFields?.ec_display_name}
            fullWidth
            label="Ecomm product display name"
            name="ec_display_name"
            required
            variant="outlined"
            value={selectedEcDisplayName}
            onChange={(e) => {
              cleanKeyword(e, 'ec_name');
              validateField(e?.target?.name, e?.target?.value);
            }}
            inputProps={{ maxLength: 75 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            error={errFields?.name ? true : false}
            helperText={errFields?.name}
            fullWidth
            label="Name"
            name="name"
            required
            variant="outlined"
            value={selectedName}
            onChange={(e) => {
              cleanKeyword(e, 'name');
              validateField(e?.target?.name, e?.target?.value);
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="SKU"
            name="SKU"
            variant="outlined"
            value={selectedSKU}
            onChange={(e) => setSelectedSKU(e.target.value)}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Box>
            <Autocomplete
              key={'categoryField'}
              ref={categoryFieldRef}
              value={selectedCategory}
              onChange={(_, newValue) => {
                setSelectedCategory(newValue);
                validateField('category', newValue);
              }}
              inputValue={inputValue}
              onInputChange={(_, newInputValue) => {
                setInputValue(newInputValue);
              }}
              noOptionsText={`"${inputValue}" has not been found in categories. Please add it first`}
              id="category"
              options={categories}
              getOptionSelected={(option, value) => option?.id === value?.id}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errFields?.category ? true : false}
                  helperText={errFields?.category}
                  required
                  label="Category"
                  variant="outlined"
                  name="category"
                />
              )}
            />
          </Box>
        </Grid>
        {isSelectedCategoryRAM ? (
          <Grid item md={6} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel>Ram Kit Type</InputLabel>
              <Select
                value={selectedRamKitType ?? ''}
                onChange={handleRamKitTypeChange}
                label="RAM kit type"
              >
                {ramKitTypeOptions?.map((item) => (
                  <MenuItem key={item?.value} value={item?.value}>
                    {item?.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        ) : null}
        <Grid item md={6} xs={12}>
          <Box>
            <Autocomplete
              key={'manufacturerField'}
              ref={manufacturerFieldRef}
              value={selectedManufacturer}
              onChange={(event, newValue) => {
                setSelectedManufacturer(newValue);
                validateField('manufacturer', newValue);
              }}
              getOptionSelected={(option, value) => option?.id === value?.id}
              inputValue={manufacturerInputValue}
              onInputChange={(event, newInputValue) => {
                setManufacturerInputValue(newInputValue);
              }}
              noOptionsText={`"${manufacturerInputValue}" has not been found in manufacturers. Please add it first`}
              id="manufacturer"
              options={manufacturers}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  error={errFields?.manufacturer ? true : false}
                  helperText={errFields?.manufacturer}
                  required
                  label="Manufacturer"
                  variant="outlined"
                  name="manufacturer"
                />
              )}
            />
            {/* <Button
              style={{ padding: 0, paddingTop: 5, paddingLeft: 5 }}
              onClick={onOpenCreateManufacturerDialog}
              color="primary"
              variant="text"
            >
              add manufacturer
            </Button> */}
          </Box>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export const AddProductGeneralInfoCardContent = React.memo(component);
