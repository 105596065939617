import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  AddressDetailResponse,
  AddressResponse,
  AddressPayload,
  AddressDetailPayload,
  UpdateAddressDetailResponse,
  UpdateAddressDetailPayload,
  deletePayload
} from 'src/types/pcw-user-address';

export const getUserAddressThunk = createAsyncThunk<
  CommonAxiosResponse<AddressResponse>,
  AddressPayload,
  { state: RootState }
>('userAddress/getUserAddressThunk', async (data) => {
  const response = await Api.get(
    `/ecomm/internal/user/address/get/${data}`,
    data
  );
  return response;
});

export const getUserAddressDetailThunk = createAsyncThunk<
  CommonAxiosResponse<AddressDetailResponse>,
  AddressDetailPayload,
  { state: RootState }
>('userAddress/getUserAddressDetailThunk', async (data) => {
  const response = await Api.get(
    `/ecomm/internal/user/address/details/${data?.user_id}/${data?.address_id}`,
    data
  );
  return response;
});

export const updateUserAddressDetailThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateAddressDetailResponse>,
  UpdateAddressDetailPayload,
  { state: RootState }
>('userAddress/updateUserAddressDetailThunk', async (data) => {
  const response = await Api.patch(
    `/ecomm/internal/user/address/update/${data?.id}`,
    data
  );
  return response;
});

export const deleteUserAddressDetailThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateAddressDetailResponse>,
  deletePayload,
  { state: RootState }
>('userAddress/deleteUserAddressDetailThunk', async (data) => {
  const response = await Api.delete(
    `/ecomm/internal/user/address/delete/${data?.user_id}/${data?.address_id}`
  );
  return response;
});
