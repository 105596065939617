import { createSlice } from '@reduxjs/toolkit';
import { Transaction } from 'src/types';
import * as thunks from './thunks';

type State = {
  transactions: Transaction[];
  isCreateLoading: boolean;
};

const initialState: State = {
  transactions: [],
  isCreateLoading: false
};

const slice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getTransactionsThunk.fulfilled,
      (state, { payload }) => {
        state.transactions = payload.originalData.transactions || [];
      }
    );

    builder.addCase(thunks.createTransactionThunk.pending, (state) => {
      state.isCreateLoading = true;
    });
    builder.addCase(thunks.createTransactionThunk.rejected, (state) => {
      state.isCreateLoading = false;
    });
    builder.addCase(thunks.createTransactionThunk.fulfilled, (state) => {
      state.isCreateLoading = false;
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
