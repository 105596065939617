import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { Role } from 'src/types';
import * as thunks from './thunks';

type State = {
  roles: Role[];
};

const initialState: State = {
  roles: []
};

const slice = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getRolesThunk.fulfilled, (state, { payload }) => {
      state.roles = payload.originalData.roles;
    });
    builder.addCase(thunks.roleDeleteThunk.fulfilled, (state, actions) => {
      if (actions.payload?.success) {
        const clonedRoles = cloneDeep(state.roles);
        const filtered = clonedRoles?.filter((x) => x.id !== actions.meta.arg);
        state.roles = filtered;
      }
    });
    builder.addCase(thunks.createRoleThunk.fulfilled, (state, actions) => {
      if (actions.payload?.success) {
        const clonedRoles = cloneDeep(state.roles);
        const index = clonedRoles?.findIndex(
          (x) => x.name === actions.meta.arg.name
        );
        if (index === -1) {
          clonedRoles.push(actions.payload?.originalData.role);
        }
        state.roles = clonedRoles;
      }
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
