import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  makeStyles
} from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Page } from 'src/components';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { RedSquare } from './components';
import Winners from './components/Winners';
import { usePermissions } from 'src/hooks';

const { actions: raffleActions, selectors: raffleSelectors } = slices.raffle;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  goodluck: {
    marginTop: '10px',
    fontSize: '60px'
  },
  raffleTitle: {
    fontSize: '40px',
    marginTop: '10px'
  },
  winnerText: {
    fontSize: '25px',
    marginBottom: '10px'
  },
  redSquare: {
    border: '5px solid red',
    borderRadius: '20px',
    marginBottom: '7px',
    padding: '20px',
    display: 'inline-block'
  },
  minigameButtons: {
    width: '400px',
    background: '#2F80ED',
    textAlign: 'center',
    margin: '10px'
  }
}));

const Minigame = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const availableRaffleEntries = useAppSelector(
    raffleSelectors.selectAvailableRaffleEntries
  );
  const raffleWinner = useAppSelector(raffleSelectors.selectRaffleWinner);
  const { canViewMiniGame } = usePermissions();

  const [isLoading, setIsLoading] = useState(false);

  const getRaffleEntries = useCallback(async () => {
    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(raffleActions.getRaffleEntries())
    );
    if (response?.originalData?.data) {
      setIsLoading(false);
      dispatch(
        raffleActions.setAvailableRaffleEntries(response.originalData.data)
      );
    }
  }, [dispatch]);

  useEffect(() => {
    getRaffleEntries();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chooseWinner = () => {
    const randomIndex = Math.floor(
      Math.random() * availableRaffleEntries.length
    );
    const selectedWinner = availableRaffleEntries[randomIndex];

    dispatch(raffleActions.setRaffleWinner(selectedWinner));
    dispatch(raffleActions.addRaffleWinners(selectedWinner));

    const raffleCopy = cloneDeep(availableRaffleEntries);

    raffleCopy.splice(randomIndex, 1);
    dispatch(raffleActions.setAvailableRaffleEntries(raffleCopy));
  };

  if (!canViewMiniGame) {
    return <div>You do not have permission to access this page</div>;
  }

  return (
    <Page className={classes.root} title="raffle">
      <Container>
        <Typography color="textPrimary" gutterBottom variant="h2">
          PCWorth Roleta ng kapalaran
        </Typography>
        <Card
          style={{
            background: `url("/static/images/raffle_decor.png") center center no-repeat, #003E6B left top repeat`,
            backgroundSize: 'auto, cover',
            position: 'relative',
            marginBottom: '40px'
          }}
        >
          <CardContent>
            <Box
              height="70vh"
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              padding="20px"
            >
              <RedSquare />
              <Box display="flex" flexDirection="column" alignItems="center">
                <Button
                  onClick={() => {
                    if (raffleWinner) {
                      dispatch(raffleActions.clearRaffleWinner());
                    } else {
                      chooseWinner();
                    }
                  }}
                  color="primary"
                  variant="contained"
                  disabled={raffleWinner === null && isLoading}
                  className={classes.minigameButtons}
                >
                  {raffleWinner
                    ? 'PICK ANOTHER BOSSING'
                    : 'GET A LUCKY BOSSING'}
                </Button>
                <Button
                  onClick={getRaffleEntries}
                  color="primary"
                  variant="contained"
                  disabled={raffleWinner === null && isLoading}
                  className={classes.minigameButtons}
                >
                  SHUFFLE
                </Button>
              </Box>
            </Box>
          </CardContent>
        </Card>
        <Winners />
      </Container>
    </Page>
  );
};

export default Minigame;
