import React, { useMemo } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { Transaction } from 'src/types';
import { RenderCurrency } from './RenderCurrency';
import { ViewWarrantyRow } from './ViewWarranty';
import { TextBoldWarranty, TextRegularWarranty } from './TextWarranty';
import { cloneDeep } from 'lodash';
import { ProductTypeEnum } from 'src/enums';

interface Props {
  items?: Transaction[];
  isBlankPage?: boolean;
  kachiPoints?: number; //this is negative number
}

const styles = StyleSheet.create({
  main: {
    marginTop: 10,
    borderRightWidth: 1
  },
  header: {
    height: 20
  },
  headerColumn: {
    alignItems: 'center',
    justifyContent: 'center',
    borderTopWidth: 1,
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  itemColumnContainer: {
    minHeight: 15
  },
  itemColumn: {
    // Adding padding of x-axis (horizontal) here would mis align borders. Do not waste time
    paddingVertical: 2,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  descriptionOrSnText: {
    paddingHorizontal: 5
  }
});

const flexSizeOfColumns = {
  qty: 0.5,
  description: 3,
  unitPrice: 1,
  amount: 1
};

const headerColumns = [
  { flex: flexSizeOfColumns.qty, title: 'Qty' },
  {
    flex: flexSizeOfColumns.description,
    title: 'Description / Serial No.'
  },
  {
    flex: flexSizeOfColumns.unitPrice,
    title: 'Unit Price'
  },
  { flex: flexSizeOfColumns.amount, title: 'Amount' }
];

//We need this string to display pts on receipt
const kachiPointsTitle = 'KACHI POINTS USED:';

type ItemPerLineData = {
  product_id?: number;
  quantity?: number;
  title?: string;
  retail_price?: number;
  amount?: number;
  hidden?: boolean;
  spend_kachi_points?: number;
};

export const RenderItemsTable = ({
  items,
  isBlankPage,
  kachiPoints
}: Props) => {
  const numberOfLinesOfItems = useMemo(() => {
    const arr: { type: 'name' | 'sn'; value: string }[] = [];
    const clonedTransactionItems = cloneDeep(items || []);
    clonedTransactionItems?.forEach((item) => {
      if (item.product_name) {
        arr.push({ type: 'name', value: item.product_name });
      }
      if (
        item?.product_type !== ProductTypeEnum.Consumable &&
        item?.serial_nos &&
        item?.serial_nos?.length > 0
      ) {
        item?.serial_nos?.forEach((x) => {
          if (x) {
            arr.push({ type: 'sn', value: x });
          }
        });
      }
    });
    return arr;
  }, [items]);

  const itemsWithNothingToFollow = useMemo(() => {
    const limitOfLinePerPage = 16;
    // lines is less than 19 add dummy data on items
    if (numberOfLinesOfItems?.length < limitOfLinePerPage) {
      const numOfDummyLinesToAdd =
        limitOfLinePerPage - numberOfLinesOfItems?.length;
      // const numOfDummyLinesToAdd = 1;
      const clonedTransactionItems = cloneDeep(items);

      for (let i = 0; i < numOfDummyLinesToAdd; i++) {
        if (i === 0) {
          clonedTransactionItems?.push({
            product_name:
              kachiPoints && kachiPoints < 0 ? kachiPointsTitle : '',
            retail_price: undefined,
            amount: Math.abs(kachiPoints || 0)
          });
          clonedTransactionItems?.push({
            product_name: isBlankPage
              ? ' '
              : '-------- Nothing Follows --------'
          });
        } else {
          clonedTransactionItems?.push({
            product_name: '   '
          });
        }
      }
      return clonedTransactionItems;
    }
    return items;
  }, [isBlankPage, items, kachiPoints, numberOfLinesOfItems.length]);

  const itemsArrayOnPerLine = useMemo(() => {
    let newItemsArr: ItemPerLineData[] = [];
    const clonedItemsWithNothingFollows = cloneDeep(itemsWithNothingToFollow);

    clonedItemsWithNothingFollows?.forEach((x) => {
      if (x?.product_name) {
        newItemsArr?.push({
          product_id: x?.product_id,
          quantity: x?.quantity,
          title: x?.product_name,
          retail_price: x?.retail_price,
          amount: x?.amount,
          spend_kachi_points: x?.spend_kachi_points
        });
      }
      if (x?.serial_nos && x?.serial_nos?.length > 0) {
        x?.serial_nos?.forEach((sn) => newItemsArr?.push({ title: sn }));
      }
    });

    return newItemsArr;
  }, [itemsWithNothingToFollow]);

  const ItemRow = ({ item }: { item: ItemPerLineData }) => {
    return (
      <ViewWarrantyRow
        style={{
          height: item?.title && item?.title?.length >= 40 ? 'auto' : 17
        }}
      >
        <View style={[styles.itemColumn, { flex: flexSizeOfColumns.qty }]}>
          <TextRegularWarranty>{item.quantity || ''}</TextRegularWarranty>
        </View>
        <View
          style={[
            styles.itemColumn,
            { flex: flexSizeOfColumns.description, alignItems: 'flex-start' }
          ]}
        >
          <TextRegularWarranty style={styles.descriptionOrSnText}>
            {item?.title?.toUpperCase()}
          </TextRegularWarranty>
        </View>
        <View
          style={[styles.itemColumn, { flex: flexSizeOfColumns.unitPrice }]}
        >
          <TextRegularWarranty>
            {item?.title === kachiPointsTitle ? (
              item?.retail_price === undefined ? (
                '---'
              ) : (
                <>
                  {'-'}
                  <RenderCurrency amount={item?.retail_price || 0} />
                  {''}
                </>
              )
            ) : item?.retail_price === undefined ? null : (
              <RenderCurrency amount={item?.retail_price || 0} />
            )}
          </TextRegularWarranty>
        </View>
        <View style={[styles.itemColumn, { flex: flexSizeOfColumns.amount }]}>
          <TextRegularWarranty>
            {item?.title === kachiPointsTitle ? (
              item?.amount === undefined ? null : (
                <>
                  {'-'}
                  <RenderCurrency amount={item?.amount || 0} />
                  {''}
                </>
              )
            ) : item?.amount === undefined ? null : (
              <RenderCurrency amount={item?.amount || 0} />
            )}
          </TextRegularWarranty>
        </View>
      </ViewWarrantyRow>
    );
  };

  return (
    <View style={styles.main}>
      <ViewWarrantyRow style={styles.header}>
        {headerColumns.map((x) => (
          <View key={x.title} style={[styles.headerColumn, { flex: x.flex }]}>
            <TextBoldWarranty>{x.title}</TextBoldWarranty>
          </View>
        ))}
      </ViewWarrantyRow>
      {itemsArrayOnPerLine?.map((item, i) => (
        <ItemRow key={i} item={item} />
      ))}
    </View>
  );
};
