import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  CreateBlogPayload,
  CreateBlogResponse,
  GetBlogDetailsResponse,
  GetBlogListPayload,
  GetBlogListResponse,
  UpdateBlogContentPayload,
  UpdateDetailBlogsPayload
} from './types';

export const createBlogThunk = createAsyncThunk<
  CommonAxiosResponse<CreateBlogResponse>,
  CreateBlogPayload,
  { state: RootState }
>('blogs/CreateBlogThunk', async (data) => {
  const response = await Api.post('/ecomm/internal/blog/create', data);
  return response;
});

export const getBlogListThunk = createAsyncThunk<
  CommonAxiosResponse<GetBlogListResponse>,
  GetBlogListPayload | undefined,
  { state: RootState }
>('blogs/GetBlogListThunk', async (data) => {
  const response = await Api.get('/ecomm/internal/blog/get', data);
  return response;
});

export const getBlogDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetBlogDetailsResponse>,
  string,
  { state: RootState }
>('blogs/GetBlogDetailsThunk', async (uuid) => {
  const response = await Api.get(`/ecomm/internal/blog/get/${uuid}`);
  return response;
});

export const updateBlogContentThunk = createAsyncThunk<
  CommonAxiosResponse<undefined>,
  UpdateBlogContentPayload,
  { state: RootState }
>('blogs/UpdateBlgContentThunk', async (data) => {
  const response = await Api.patch(
    `/ecomm/internal/blog/content/update/${data.uuid}`,
    data
  );
  return response;
});

export const updateBlogDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<undefined>,
  UpdateDetailBlogsPayload,
  { state: RootState }
>('blogs/UpdateBlogDetailsThunk', async (data) => {
  const response = await Api.patch(
    `/ecomm/internal/blog/details/update/${data?.uuid}`,
    data?.data
  );
  return response;
});
