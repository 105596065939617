import React, { FC, memo, useEffect, useMemo, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { DeletableImg } from 'src/components';
import { ProductImage } from 'src/types';
import { isEqual } from 'lodash';

interface Props {
  imgUrl: ProductImage[];
  isDragDisable?: boolean;
  onDeleteImg: (id: number) => void;
  onHandleGetImagesInOrder: (imgOrderList: ProductImage[]) => void;
  onHandleChangeImageOrder: (imgList: ProductImage[]) => void;
}

const component: FC<Props> = ({
  imgUrl,
  isDragDisable,
  onDeleteImg,
  onHandleGetImagesInOrder,
  onHandleChangeImageOrder
}) => {
  const [imageList, setImageList] = useState<ProductImage[]>(imgUrl);

  //if imagelist is change use this as payload
  const newImageOrderList = useMemo(() => imageList, [imageList]);

  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }

    const newImgList = Array.from(imageList);
    const [removed] = newImgList.splice(result.source.index, 1);
    newImgList.splice(result.destination.index, 0, removed);

    setImageList(newImgList);
    //check if the drag action cause change of image order
    if (!isEqual(imageList, newImgList)) {
      onHandleChangeImageOrder(newImgList);
    }
  };

  useEffect(() => {
    onHandleGetImagesInOrder(newImageOrderList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newImageOrderList]);

  //need to initialize the undefined img url
  useEffect(() => {
    setImageList(imgUrl);
  }, [imgUrl]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction="horizontal">
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              display: 'flex',
              padding: '1rem',
              marginBottom: '1rem',
              overflow: 'scroll'
            }}
          >
            {imageList.map((item, index) => (
              <Draggable
                key={index}
                draggableId={index.toString()}
                index={index}
                isDragDisabled={isDragDisable}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      margin: '0 4px' // Adding some spacing between items
                    }}
                  >
                    <DeletableImg
                      index={index}
                      imgSrc={item.url}
                      onDelete={() => onDeleteImg(index)}
                      height={120}
                      width={120}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export const ProductDraggableImageList = memo(component);
