import { Paper } from '@material-ui/core';
import React, { FC, memo } from 'react';
import { Toolbar } from './Toolbar';
import { ReceivingTable } from './ReceivingTable';

interface Props {}

const component: FC<Props> = () => {
  return (
    <Paper elevation={1} style={{ padding: '3rem' }}>
      <Toolbar />
      <ReceivingTable />
    </Paper>
  );
};

export const ReceivingView = memo(component);
