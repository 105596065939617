import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectCategory = (state: RootState) => state.category;

const selectCategories = createSelector(
  selectCategory,
  (state) => state.categories
);

const selectCategoriesOnFilter = createSelector(
  selectCategory,
  (state) => state.categoriesOnFilter
);

export const selectors = {
  selectCategories,
  selectCategoriesOnFilter
};
