import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  Chip,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import BackspaceOutlinedIcon from '@material-ui/icons/BackspaceOutlined';
// import CachedIcon from '@material-ui/icons/Cached';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import { LinkComponent, LoaderBar } from 'src/components';
import { ProductTypeEnum } from 'src/enums';
import {
  GetTransactionsViaTransactionNoResponse,
  Transaction
} from 'src/types';
import { formatCurrency } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { usePermissions } from 'src/hooks';
import { colors } from 'src/constants';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import { cloneDeep } from 'lodash';
import { sortProductsByCategory } from 'src/utils/categories/sortByCategory';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  addBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  extraInfo: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
}));

interface Props {
  isLoading?: boolean;
  onAddItemPress: () => void;
  transactionInfo?: GetTransactionsViaTransactionNoResponse;
  onVoidItem: (data: Transaction) => void;
  onReplaceItemPress: (data: Transaction) => void;
  // setTransactionToRma: (data: Transaction) => void;
  getDeleteTransactionInfo: () => void;
}

const TransactionsCardWithTable = ({
  isLoading,
  // setTransactionToRma,
  transactionInfo,
  onAddItemPress,
  onVoidItem,
  getDeleteTransactionInfo,
  // eslint-disable-next-line no-unused-vars
  onReplaceItemPress
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const permissions = usePermissions();

  //sorted values
  const sortedTransactionItems = useMemo(() => {
    if (transactionInfo?.items) {
      const sortedTransactionItems = cloneDeep(transactionInfo?.items) || [];
      return sortProductsByCategory(sortedTransactionItems);
    }
    return [];
  }, [transactionInfo]);

  const quantityStr = useCallback((item: Transaction) => {
    const quantity = item?.quantity || 1;
    if (
      item?.product_type === ProductTypeEnum.Consumable &&
      item?.consumable_unit
    ) {
      return `${quantity} ${item?.consumable_unit}(s)`;
    }
    return `${quantity}`;
  }, []);

  const itemSerialNo = useCallback((item: Transaction) => {
    if (item?.product_type === ProductTypeEnum.Consumable) {
      return '--';
    }
    if (item?.serial_no) {
      return item?.serial_no;
    }
    return '--';
  }, []);

  return (
    <div>
      <LoaderBar isLoading={isLoading || false} />
      <Card className={classes.cardContainer}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Quantity</TableCell>
                  {multiBranchFeat ? <TableCell>Branch name</TableCell> : null}
                  <TableCell>Category</TableCell>
                  <TableCell>Product Name</TableCell>
                  <TableCell>Serial No.</TableCell>
                  <TableCell>Unit Price</TableCell>
                  <TableCell>Amount</TableCell>
                  {/* TODO: RMA is now deprecated */}
                  {/* <TableCell>RMA</TableCell> */}
                  <TableCell>Voided By</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedTransactionItems?.map((item: Transaction) => (
                  <TableRow
                    hover
                    key={item.id}
                    style={
                      item?.isDeleted ? { backgroundColor: colors.error } : {}
                    }
                  >
                    <TableCell>{quantityStr(item)}</TableCell>
                    {multiBranchFeat ? (
                      <TableCell>
                        <LinkComponent title={`${item?.branch_name ?? '--'}`} />
                      </TableCell>
                    ) : null}
                    <TableCell>
                      <LinkComponent title={`${item?.category_name ?? '--'}`} />
                    </TableCell>
                    <TableCell>
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/products/${item?.product_id}`)
                        }
                        href={`/app/products/${item?.product_id}`}
                        title={`${item?.product_name ?? '--'}`}
                      />
                      {item.isDeleted ? (
                        <Tooltip title={`Void reason: ${item?.void_reason}`}>
                          <Chip
                            label="Deleted"
                            style={{ height: '100%', marginLeft: 10 }}
                            size="small"
                            variant="outlined"
                            color="primary"
                          />
                        </Tooltip>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {item?.isDeleted ? (
                        itemSerialNo(item)
                      ) : (
                        <LinkComponent
                          onClick={() =>
                            navigate(
                              `/app/products-listings/${item.listing_id}`
                            )
                          }
                          href={`/app/products-listings/${item.listing_id}`}
                          title={itemSerialNo(item)}
                        />
                      )}
                    </TableCell>
                    <TableCell>{formatCurrency(item?.retail_price)}</TableCell>
                    <TableCell>{formatCurrency(item?.amount)}</TableCell>
                    {/* TODO: RMA is deprecated, remove later */}
                    {/* <TableCell onClick={(e) => e.stopPropagation()}>
                      {item?.product_type === ProductTypeEnum.Consumable ? (
                        'N/A'
                      ) : item?.rma ? (
                        <RmaChip rma={item?.rma} />
                      ) : (
                        <Tooltip title={`Set ${item?.product_name} as RMA`}>
                          <IconButton
                            size="small"
                            disabled={item?.isDeleted ? true : false}
                            onClick={(e) => {
                              e.stopPropagation();
                              setTransactionToRma(item);
                            }}
                          >
                            <SubdirectoryArrowLeftIcon color="secondary" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell> */}

                    <TableCell>
                      {item?.deleted_by_name && (
                        <Tooltip title={item?.deleted_by_name}>
                          <Chip
                            label={item?.deleted_by_name}
                            variant="outlined"
                            style={{ overflow: 'hidden', maxWidth: '120px' }}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell onClick={(e) => e.stopPropagation()}>
                      <Tooltip title={`Void ${item?.product_name}`}>
                        <IconButton
                          disabled={item?.isDeleted ? true : false}
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            onVoidItem(item);
                            getDeleteTransactionInfo();
                          }}
                        >
                          <BackspaceOutlinedIcon color="secondary" />
                        </IconButton>
                      </Tooltip>
                      {/* <Tooltip
                        title={`Under Investigation. For now, void and add again`}
                      >
                        <IconButton
                          disabled={true}
                          size="small"
                          // eslint-disable-next-line no-unused-vars
                          onClick={(e) => {
                            // e.stopPropagation();
                            // onReplaceItemPress(item);
                          }}
                        >
                          <CachedIcon color="secondary" />
                        </IconButton>
                      </Tooltip> */}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <div className={classes.extraInfo}>
              <Button
                disabled={isLoading}
                startIcon={<PlusOneIcon />}
                onClick={() => onAddItemPress()}
                variant="outlined"
                color="primary"
                className={classes.addBtn}
              >
                Add item in transaction
              </Button>
              {permissions?.canViewSales ? (
                <Typography color="textSecondary" variant="subtitle2">
                  sub total:{' '}
                  {`${formatCurrency(transactionInfo?.sub_total_price) ??
                    `0.00`}`}
                </Typography>
              ) : null}
              {permissions?.canViewSales ? (
                <Typography color="textSecondary" variant="subtitle2">
                  deducted kachi points:{' '}
                  {`-${formatCurrency(
                    Math.abs(transactionInfo?.kachi_points || 0)
                  )}`}
                </Typography>
              ) : null}
              <Typography variant="h4">
                TOTAL PRICE:{' '}
                {`${formatCurrency(transactionInfo?.total_price) ?? `0.00`}`}
              </Typography>
              {permissions?.canViewSales ? (
                <Typography color="textSecondary" variant="subtitle2">
                  total dealers price:{' '}
                  {`${formatCurrency(transactionInfo?.total_dealers_price) ??
                    `0.00`}`}
                </Typography>
              ) : null}
              {permissions?.canViewSales ? (
                <Typography color="textSecondary" variant="subtitle2">
                  total gross:{' '}
                  {`${formatCurrency(transactionInfo?.total_gross) ?? `0.00`}`}
                </Typography>
              ) : null}
            </div>
          </Box>
        </PerfectScrollbar>
      </Card>
    </div>
  );
};

export const TransactionsCard = React.memo(TransactionsCardWithTable);
