import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ListingStatusEnum } from 'src/enums';
import {
  GetInventoryRequest,
  GetInventoryResponse,
  InventoryColumn,
  InventoryColumns,
  InventoryKeywordInputField,
  InventoryLocalFilter,
  Inventory,
  InventoryTotals
} from 'src/types';
import { addItemInArrayUnique, removeItemInArrayUnique } from 'src/utils';
import * as thunks from './thunks';
import {
  filterInventoryLocal,
  removeSortByOnOtherColumns,
  sortInventory
} from './util';
import { multiBranchFeat } from 'src/constants/feature-toggle';

type State = {
  inventory: Inventory[];
  inventoryLocalFilter: InventoryLocalFilter;
  isSummaryFetched: boolean;
  inventoryFilter: GetInventoryRequest | undefined;
  inventoryTotals: InventoryTotals;
  inventoryResponseOrig: GetInventoryResponse | undefined;
  inventoryResponse: GetInventoryResponse | undefined;
  inventoryColumns: InventoryColumns;
};

const initialState: State = {
  inventory: [],
  inventoryResponseOrig: undefined,
  isSummaryFetched: false,
  inventoryLocalFilter: {},
  inventoryTotals: {
    total_dealers_price: null,
    margin_percentage: null,
    total_retail_price: null,
    total_gross_price: null
  },
  inventoryFilter: {
    status: [
      ListingStatusEnum.Available,
      ListingStatusEnum.Transfer,
      ListingStatusEnum.Defective,
      ListingStatusEnum.OnHold,
      ListingStatusEnum.Purchased
    ],
    branch_ids: [],
    category_id: null,
    page: 1,
    manufacturer_id: null,
    limit: 10
  },
  inventoryResponse: undefined,
  inventoryColumns: {
    id: false,
    transaction_no: true,
    quantity: true,
    product_name: true,
    serial_no: true,
    dr_no: true,
    encoded_by: true,
    customer_name: true,
    dealers_price: true,
    retail_price: true,
    supplier_name: true,
    branch_name: multiBranchFeat,
    date_sold: true,
    date_purchased: true,
    date_encoded: true,
    released_by: true,
    status: true
  }
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    clearLocalFilter: (state) => {
      state.inventoryLocalFilter = {};
      state.inventoryResponse = state.inventoryResponseOrig;
    },
    onUpdateLocalFilter: (
      state,
      {
        payload
      }: PayloadAction<{
        newFilter: InventoryLocalFilter;
        column: InventoryColumn;
      }>
    ) => {
      const { newFilter, column } = payload;
      let filteredInventory = filterInventoryLocal(
        state.inventoryResponse || {},
        newFilter
      );
      let toBeNewFilter = newFilter;

      if (payload.column && newFilter[column]?.sortBy) {
        const order = newFilter[column]?.sortBy || 'ASC';
        const uniqueSortByCol = removeSortByOnOtherColumns(
          toBeNewFilter,
          column
        );
        filteredInventory = sortInventory(filteredInventory, column, order);
        toBeNewFilter = uniqueSortByCol;
      }

      state.inventoryLocalFilter = toBeNewFilter;
      state.inventoryResponse = filteredInventory;
    },
    onChangeInput: (
      state,
      {
        payload
      }: PayloadAction<{ keyword: string; field: InventoryKeywordInputField }>
    ) => {
      state.inventoryFilter = {
        ...state.inventoryFilter,
        [payload.field]: payload.keyword
      };
    },
    onToggleStatusAllV2: (
      state,
      { payload }: PayloadAction<{ status: string[] }>
    ) => {
      if (payload) {
        state.inventoryFilter = {
          ...state.inventoryFilter,
          status: payload.status
        };
      }
    },
    onToggleStatusAll: (state, { payload }: PayloadAction<boolean>) => {
      if (payload) {
        state.inventoryFilter = {
          ...state.inventoryFilter,
          status: [
            ListingStatusEnum.Available,
            ListingStatusEnum.Transfer,
            ListingStatusEnum.Defective,
            ListingStatusEnum.OnHold,
            ListingStatusEnum.Purchased
          ]
        };
      } else {
        state.inventoryFilter = {
          ...state.inventoryFilter,
          status: []
        };
      }
    },
    onToggleStatus: (
      state,
      { payload }: PayloadAction<{ value: string; isCheck: boolean }>
    ) => {
      if (payload.isCheck) {
        state.inventoryFilter = {
          ...state.inventoryFilter,
          status: addItemInArrayUnique(
            state.inventoryFilter?.status || [],
            payload.value
          )
        };
      } else {
        state.inventoryFilter = {
          ...state.inventoryFilter,
          status: removeItemInArrayUnique(
            state.inventoryFilter?.status || [],
            payload.value
          )
        };
      }
    },
    updateCategoryFilter: (
      state,
      { payload }: PayloadAction<{ category_id: number | null }>
    ) => {
      state.inventoryFilter = {
        ...state.inventoryFilter,
        category_id: payload.category_id
      };
    },
    updateManufacturerFilter: (
      state,
      { payload }: PayloadAction<{ manufacturer_id: number | null }>
    ) => {
      state.inventoryFilter = {
        ...state.inventoryFilter,
        manufacturer_id: payload.manufacturer_id
      };
    },
    updatePageFilter: (state, { payload }: PayloadAction<{ page: number }>) => {
      state.inventoryFilter = {
        ...state.inventoryFilter,
        page: payload.page
      };
    },
    updateActionFilter: (
      state,
      { payload }: PayloadAction<{ action: string }>
    ) => {
      state.inventoryFilter = {
        ...state.inventoryFilter,
        action: payload.action
      };
    },
    updateBranchFilter: (
      state,
      { payload }: PayloadAction<{ branch_ids: number[] }>
    ) => {
      state.inventoryFilter = {
        ...state.inventoryFilter,
        branch_ids: payload.branch_ids
      };
    },
    updateFilter: (
      state,
      { payload }: PayloadAction<GetInventoryRequest | undefined>
    ) => {
      if (!payload) {
        state.inventoryFilter = initialState.inventoryFilter;
        return;
      }
      state.inventoryFilter = {
        ...state.inventoryFilter,
        ...payload
      };
    },
    resetFilter: (state) => {
      state.inventoryFilter = initialState.inventoryFilter;
    },

    showAllColumns: (state) => {
      state.inventoryColumns = initialState.inventoryColumns;
    },
    toggleInventoryColumn: (
      state,
      { payload }: PayloadAction<InventoryColumn>
    ) => {
      const key = payload;
      state.inventoryColumns = {
        ...state.inventoryColumns,
        [key]: !state.inventoryColumns[key]
      };
    }
  },
  extraReducers(builder) {
    builder.addCase(thunks.getInventoryThunk.pending, (state) => {
      state.inventoryLocalFilter = {};
      state.inventoryResponse = undefined;
      state.inventoryResponseOrig = undefined;
    });
    builder.addCase(
      thunks.getInventoryThunk.fulfilled,
      (state, { payload }) => {
        const action = state.inventoryFilter?.action;
        if (action === 'computation') {
          let res: GetInventoryResponse = payload?.originalData;
          state.inventoryTotals.margin_percentage =
            res.margin_percentage ?? null;
          state.inventoryTotals.total_dealers_price =
            res.total_dealers_price ?? null;
          state.inventoryTotals.total_gross_price =
            res.total_gross_price ?? null;
          state.inventoryTotals.total_retail_price =
            res.total_retail_price ?? null;
          state.inventoryResponse = payload.originalData || [];
          state.isSummaryFetched = true;
        } else if (action === 'list') {
          state.inventory = payload.originalData.items || [];
          state.inventoryResponse = payload.originalData || [];
          state.inventoryResponseOrig = payload.originalData || [];
        }
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
