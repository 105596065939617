import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import * as productThunks from '../product/thunks';
import { CommonAuditParams, InventoryAuditState } from './types';
import {
  GetBranchCategoryData,
  GetProductWithTotalAvailableItemsData
} from 'src/types';

const initialState: InventoryAuditState = {
  inventoryAuditProductListing: [],
  isLoading: false,
  commonParams: {},
  selectedAuditProductList: []
};

const scannedProductSnAction = (
  state: InventoryAuditState,
  { payload }: PayloadAction<GetProductWithTotalAvailableItemsData[]>
) => {
  state.inventoryAuditProductListing = payload;
};

const commonAuditParamsAction = (
  state: InventoryAuditState,
  { payload }: PayloadAction<CommonAuditParams>
) => {
  state.commonParams = payload;
};

const productToBeAuditAction = (
  state: InventoryAuditState,
  { payload }: PayloadAction<GetBranchCategoryData>
) => {
  state.selectedAuditProductList = [...state.selectedAuditProductList, payload];
};

const newProductToBeAuditAction = (
  state: InventoryAuditState,
  { payload }: PayloadAction<GetBranchCategoryData[]>
) => {
  state.selectedAuditProductList = payload;
};

const resetAuditStateAction = (state: InventoryAuditState) => {
  state.inventoryAuditProductListing = [];
  state.selectedAuditProductList = [];
  state.commonParams = {};
};

const resetInventoryAuditListing = (state: InventoryAuditState) => {
  state.inventoryAuditProductListing = [];
  state.selectedAuditProductList = [];
};

const slice = createSlice({
  name: 'inventory-audit',
  initialState,
  reducers: {
    scannedProductSnAction,
    commonAuditParamsAction,
    productToBeAuditAction,
    newProductToBeAuditAction,
    resetAuditStateAction,
    resetInventoryAuditListing
  },
  extraReducers(builder) {
    builder.addCase(
      productThunks.getProductWithTotalAvailableItemThunk.pending,
      (state) => {
        state.isLoading = true;
      }
    );
    builder.addCase(
      productThunks.getProductWithTotalAvailableItemThunk.rejected,
      (state) => {
        state.isLoading = false;
      }
    );
    builder.addCase(
      productThunks.getProductWithTotalAvailableItemThunk.fulfilled,
      (state, { payload }) => {
        state.isLoading = false;

        // Preserve the previous state and add new items from the payload
        const existingItems = state.inventoryAuditProductListing || [];
        const newItems = (payload?.originalData?.data || []).map((item) => ({
          ...item,
          audited_count: 0
        }));

        state.inventoryAuditProductListing = [...existingItems, ...newItems];
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
