import React from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import { warrantyTextStyles } from '../styles';
import { drjInfo } from 'src/constants';
import { TextBoldWarranty, TextRegularWarranty } from './TextWarranty';
import { ViewWarrantyRow } from './ViewWarranty';

const styles = StyleSheet.create({
  root: {
    flexDirection: 'row',
    alignItems: 'center'
  },
  logoContainer: {
    flex: 1,
    alignItems: 'center'
  },
  logo: {
    height: 50,
    width: 60
  },
  infoContainer: {
    flex: 4,
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  locationText: {
    textAlign: 'center'
  }
});

interface Props {
  storeLocation?: string;
}

export const component = ({ storeLocation }: Props) => {
  return (
    <ViewWarrantyRow style={styles.root}>
      <View style={styles.logoContainer}>
        <Image
          style={styles.logo}
          src="/static/images/avatars/pcworth_logo.png"
        />
      </View>
      <View style={styles.infoContainer}>
        <TextRegularWarranty>
          Owned and Operated by:
          <TextBoldWarranty> {drjInfo.name}</TextBoldWarranty>
        </TextRegularWarranty>
        <TextRegularWarranty style={styles.locationText}>
          Location: {storeLocation || drjInfo.location}
        </TextRegularWarranty>
        <ViewWarrantyRow>
          <ViewWarrantyRow>
            <TextBoldWarranty>Website:</TextBoldWarranty>
            <TextRegularWarranty style={warrantyTextStyles.url}>
              {drjInfo.website}
            </TextRegularWarranty>
          </ViewWarrantyRow>
          <ViewWarrantyRow style={{ marginLeft: 4 }}>
            <TextBoldWarranty>Facebook:</TextBoldWarranty>
            <TextRegularWarranty style={warrantyTextStyles.url}>
              {drjInfo.facebook}
            </TextRegularWarranty>
          </ViewWarrantyRow>
        </ViewWarrantyRow>
        <ViewWarrantyRow>
          <TextRegularWarranty>
            <TextBoldWarranty>Email:</TextBoldWarranty> {drjInfo.email}
          </TextRegularWarranty>
          <TextRegularWarranty style={{ marginLeft: 4 }}>
            <TextBoldWarranty>Viber:</TextBoldWarranty>
            {drjInfo.cellNo2}
          </TextRegularWarranty>
        </ViewWarrantyRow>
        <ViewWarrantyRow>
          <TextRegularWarranty>
            <TextBoldWarranty>Tel No:</TextBoldWarranty> {drjInfo.telNo}
          </TextRegularWarranty>
          <TextRegularWarranty style={{ marginLeft: 4 }}>
            <TextBoldWarranty>Cell No:</TextBoldWarranty>
            {drjInfo.cellNo}
          </TextRegularWarranty>
        </ViewWarrantyRow>
      </View>
    </ViewWarrantyRow>
  );
};

export const RenderCompanyInfo = React.memo(component);
