import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectPcPackageState = (state: RootState) => state.pcPackage;

const selectPcPackageList = createSelector(
    selectPcPackageState,
    (state) => state.pcPackageListItems
);

const selectPackageResponse = createSelector(selectPcPackageState, (state) => state.packagePayload);

const selectIsListLoading = createSelector(
    selectPcPackageState,
    (state) => state.isListLoading
);

const selectPcPackageDetails = createSelector(selectPcPackageState, (state) => state.pcPackageDetails);

const selectPcPackagePagination = createSelector(selectPcPackageState, (state) => state.pcPackagePagination);

export const selectors = {
    selectPcPackageList,
    selectIsListLoading,
    selectPackageResponse,
    selectPcPackageDetails,
    selectPcPackagePagination
};
