import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { CheckBoxLabel, LinkComponent, LoaderBar, Page } from 'src/components';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { AssignPermissionToRoleRequest, Permission, Role } from 'src/types';
import FormAddDialog from 'src/components/dialogs/FormDialog';
import { useSnackBar } from 'src/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { UserBasicInfo } from 'src/types/user';
import { useAppDispatch } from 'src/redux';
import { slices } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import usePermsAndRolesSearch from 'src/hooks/use-perms-and-roles-search';
import { SearchBox } from 'src/components/SearchBox';

const { actions: roleActions } = slices.roles;
const { actions: permissionActions } = slices.permissions;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tableTitle: {
    marginTop: '2em'
  },
  productCard: {
    height: '100%'
  },
  tableRow: {
    height: 35,
    padding: 10
  },
  permissionBox: {
    padding: theme.spacing(3)
  }
}));

const RoleDetailView = () => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    permissions,
    filteredPerms,
    permsInputVal,
    onChangePermsInput,
    onSearchPerms
  } = usePermsAndRolesSearch();

  const { id } = useParams();

  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [addPermissionLoading, setAddPermissionLoading] = useState<boolean>(
    false
  );
  const [permissionsLoading, setPermissionsLoading] = useState<boolean>(false);
  const [permissionItemLoading, setPermissionItemLoading] = useState<number>(); // Id Only
  const [isCreatePermissionVisible, setIsPermissionRoleVisible] = useState<
    boolean
  >(false);

  const [roleDetail, setRoleDetail] = useState<Role>();
  const [selectedPermissions, setSelectedPermissions] = useState<Permission[]>(
    []
  );
  const [users, setUsers] = useState<UserBasicInfo[]>([]);

  const getRoleDetail = useCallback(async () => {
    if (!id) {
      return;
    }
    const roleId = +id;
    if (!isNaN(roleId)) {
      setPermissionsLoading(true);
      await dispatch(permissionActions.getPermissionsThunk());
      const roleDetailRes = unwrapResult(
        await dispatch(roleActions.getRoleViaIdThunk(roleId)).finally(() =>
          setPermissionsLoading(false)
        )
      );
      if (roleDetailRes?.success && roleDetailRes?.originalData?.role) {
        setRoleDetail(roleDetailRes.originalData.role);
        setSelectedPermissions(
          roleDetailRes.originalData.role?.permissions || []
        );
      }
    }
  }, [dispatch, id]);

  const getUsersWithThisRole = useCallback(async () => {
    if (!id) {
      return;
    }
    const roleId = +id;
    if (!isNaN(roleId)) {
      setUsersLoading(true);
      const roleDetailRes = unwrapResult(
        await dispatch(roleActions.getUsersViaRoleIdThunk(roleId)).finally(() =>
          setUsersLoading(false)
        )
      );
      if (roleDetailRes?.success && roleDetailRes?.originalData?.users) {
        setUsers(roleDetailRes?.originalData?.users);
      }
    }
  }, [dispatch, id]);

  const onOpenCreateRoleDialog = () => {
    setIsPermissionRoleVisible(true);
  };

  const onCloseCreatePermissionDialog = () => {
    setIsPermissionRoleVisible(false);
    setAddPermissionLoading(false);
  };

  const onAddPermissionOnModalPress = async (name: string) => {
    if (!id) {
      return;
    }
    const roleId = +id;

    if (isNaN(roleId)) {
      // Invalid `id` on url params
      return;
    }
    if (roleId) {
      setAddPermissionLoading(true);

      const response = unwrapResult(
        await dispatch(
          permissionActions.createPermissionThenAddToRoleThunk({
            name,
            role_id: roleId
          })
        )
      );
      setAddPermissionLoading(false);
      if (response?.success) {
        getRoleDetail();
        snackBar.show({
          severity: 'success',
          message: response?.message || 'Permission creation success.'
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || 'Permission creation failed.'
        });
      }
    }

    onCloseCreatePermissionDialog();
  };

  const onTogglePermissionApi = async (
    role_id: number,
    permission_id: number,
    newValue: boolean
  ) => {
    const data: AssignPermissionToRoleRequest = {
      role_id,
      permission_id
    };
    setPermissionItemLoading(permission_id);
    if (newValue) {
      // If adding permission
      return dispatch(
        roleActions.assignPermissionToRoleThunk(data)
      ).finally(() => setPermissionItemLoading(undefined));
    }
    // If revoking permission
    return dispatch(roleActions.revokePermissionToRoleThunk(data)).finally(() =>
      setPermissionItemLoading(undefined)
    );
  };

  const onTogglePermission = async (item: Permission, newValue: boolean) => {
    if (!id) {
      return;
    }
    const clonedSelectedPermissions = cloneDeep(selectedPermissions);
    const roleId = +id;

    // Removing 'edit_permissions_roles' on 'owner' Role is not allowed
    if (
      roleDetail?.name === 'owner' &&
      item?.name === 'edit_permissions_roles' &&
      !newValue
    ) {
      snackBar.show({
        severity: 'error',
        message:
          'Removing Permission(edit_permissions_roles) on Role(owner) is not allowed'
      });
      return;
    }
    if (isNaN(roleId)) {
      // Invalid `id` on url params
      return;
    }
    if (item?.id) {
      onTogglePermissionApi(roleId, item?.id, newValue);
      if (newValue) {
        // If toggle to be checked
        clonedSelectedPermissions?.push(item);
        setSelectedPermissions(clonedSelectedPermissions);
        return;
      }
      // If toggle to be unchecked
      const filtered = clonedSelectedPermissions?.filter(
        (x) => x.id !== item?.id
      );
      setSelectedPermissions(filtered);
    }
  };

  const isPermissionItemLoading = useCallback(
    (item: Permission) => item?.id === permissionItemLoading,
    [permissionItemLoading]
  );

  const isPermissionSelected = useCallback(
    (item: Permission) => {
      const index = selectedPermissions?.findIndex((x) => x.id === item?.id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    [selectedPermissions]
  );

  useEffect(() => {
    getRoleDetail();
    getUsersWithThisRole();
  }, [getRoleDetail, getUsersWithThisRole]);

  return (
    <Page className={classes.root} title={`Role - ${roleDetail?.name || ''}`}>
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="textPrimary" gutterBottom variant="h3">
            {`Role - ${roleDetail?.name || ''}`}
          </Typography>
        </Box>
        <Typography
          className={classes.tableTitle}
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          Permissions inside this Role
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <SearchBox
            options={permissions}
            inputValue={permsInputVal}
            onInputChange={onChangePermsInput}
            onSearchKeyWord={onSearchPerms}
            placeholder={'Search Perms'}
          />
          <Button
            onClick={onOpenCreateRoleDialog}
            color="primary"
            variant="contained"
          >
            Create Permission then add to role
          </Button>
        </Box>
        <Box mt={2}>
          <LoaderBar isLoading={permissionsLoading} />
          <Card className={classes.permissionBox}>
            <Box minWidth={1050}>
              <Grid container spacing={2}>
                {filteredPerms?.map((item: Permission, i: number) => (
                  <Grid key={item?.id || i} item xl={2} lg={3} md={6} xs={12}>
                    <CheckBoxLabel
                      label={item?.name}
                      checked={isPermissionSelected(item)}
                      disabled={isPermissionItemLoading(item)}
                      onChange={(value) => onTogglePermission(item, value)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Card>
        </Box>

        <Box
          paddingTop={6}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="textPrimary" gutterBottom variant="h4">
            Users with this role
          </Typography>
        </Box>
        <LoaderBar isLoading={usersLoading} />
        <Box mt={2}>
          <Card>
            <PerfectScrollbar style={{ height: '30vh' }}>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Full Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item?.id}</TableCell>
                        <TableCell>
                          <LinkComponent
                            onClick={() => navigate(`/app/user/${item.id}`)}
                            href={`/app/user/${item.id}`}
                            title={`${item?.first_name ||
                              '--'} ${item?.last_name || '--'}`}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>

      <FormAddDialog
        loading={addPermissionLoading}
        fieldName="Permission Name"
        title="Create a permission"
        isVisible={isCreatePermissionVisible}
        subTitle="Input name for this new permission and add it to this role"
        onAddPress={onAddPermissionOnModalPress}
        handleClose={onCloseCreatePermissionDialog}
      />
    </Page>
  );
};

export default RoleDetailView;
