import { cloneDeep, filter, union } from 'lodash';

export const addItemInArrayUnique = <T>(arr: T[], val: T) => {
  return union(arr, [val]);
};

export const addArrayInArrayUnique = <T>(arr: T[], val: T[]) => {
  return union(arr, val);
};

export const removeItemInArrayUnique = <T>(arr: T[], val: T) => {
  return filter(arr, (x) => x !== val);
};

export const chunkArrayInGroups = (arr: any[], size: number) => {
  const clonedArr = cloneDeep(arr);
  var result = [];
  var j = 0;

  for (var i = 0; i < Math.ceil(clonedArr?.length / size); i++) {
    result[i] = clonedArr?.slice(j, j + size);
    j = j + size;
  }
  return result;
};
