import { parse } from 'src/utils';

const CryptoJS = require('crypto-js');

type SetSecureItemProp = {
  key: string;
  value: string;
  password: string;
};

type GetSecureItemProp = {
  key: string;
  fallbackValue: any;
  password: string;
};

const isBrowser = typeof window !== 'undefined';
const isCookieEnabled = isBrowser && navigator && navigator.cookieEnabled;
const localStorage = isCookieEnabled ? window.localStorage : undefined;

const setItem = (key: string, value: any, stringify = true) => {
  const item = stringify ? JSON.stringify(value) : value;
  localStorage?.setItem(key, item);
};

const getItem = (key: string, parsed = true, fallbackValue = null) => {
  let result = null;
  result = localStorage?.getItem(key);
  const item = parsed ? parse(result) : result;

  if (
    (typeof item === 'undefined' || item === null) &&
    typeof fallbackValue !== 'undefined'
  ) {
    return fallbackValue;
  }
  return item;
};

const setSecureItem = (props: SetSecureItemProp) => {
  const encryptedItem = CryptoJS.AES.encrypt(
    JSON.stringify({ value: props.value }),
    props.password
  ).toString();
  localStorage?.setItem(props.key, encryptedItem);
};

const getSecureItem = (props: GetSecureItemProp) => {
  // localStorageClear();
  // return null;
  const encryptedItem = localStorage?.getItem(props.key);

  if (!encryptedItem && typeof props.fallbackValue !== 'undefined') {
    return props.fallbackValue;
  }
  const decryptedItem = CryptoJS.AES.decrypt(
    encryptedItem,
    props.password
  ).toString(CryptoJS.enc.Utf8);
  const parsed = JSON.parse(decryptedItem);
  return parsed.value;
};

const removeItem = (key: string) => {
  localStorage?.removeItem(key);
};

const localStorageClear = () => {
  localStorage?.clear();
};

export default {
  setItem,
  setSecureItem,
  getItem,
  getSecureItem,
  removeItem,
  localStorageClear
};
