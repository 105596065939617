import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Container, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

import { LoaderBar, Page } from 'src/components';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import { AccountProfileData, UserUpdatePayload } from 'src/types';
import { usePermissions, useSnackBar } from 'src/hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import ProfileAddress from '../user-address/ProfileAddress';
import { AddressDetails } from 'src/types/pcw-user-address';

const { actions: PcwAccountActions } = slices.pcwUserAccounts;
const { actions: PcwAddressActions } = slices.pcwUserAddress;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export default function UserAccountProfile() {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const { id } = useParams();
  const navigate = useNavigate();

  const { canViewUserEcommAddress } = usePermissions();

  const [customer, setAccountProfile] = useState<
    AccountProfileData | undefined
  >();
  const [addressList, getAddressList] = useState<AddressDetails[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAddresLoading, setAddressLoading] = useState<boolean>(false);
  const [updateErr, setUpdateErr] = useState<any>({});

  const getAccountProfile = async () => {
    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(PcwAccountActions.getPcwAccountProfileThunk(id))
    );
    if (response?.success && response?.originalData?.data) {
      setAccountProfile(response?.originalData?.data);
      setIsLoading(false);
    }
  };

  const getAccountAddresses = useCallback(
    async (accountId: number) => {
      setAddressLoading(true);
      const response = unwrapResult(
        await dispatch(PcwAddressActions.getUserAddressThunk(accountId))
      );
      if (response?.success && response?.originalData?.data) {
        getAddressList(response?.originalData?.data);
        setAddressLoading(false);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [dispatch]
  );

  const onCustomerUpdate = async (data: UserUpdatePayload) => {
    const updatePayload: UserUpdatePayload = {
      id: data?.id,
      email: data?.email,
      mobile_no: data?.mobile_no,
      username: data?.username,
      first_name: data?.first_name,
      middle_name: data?.middle_name,
      last_name: data?.last_name,
      birthdate: data?.birthdate,
      status: data?.status,
      active: data?.active
    };
    setIsLoading(true);
    if (data) {
      const response = unwrapResult(
        await dispatch(PcwAccountActions.updatePcwAccountProfile(updatePayload))
      );
      if (response?.success && response?.message) {
        snackBar.show({ severity: 'success', message: response.message });
        setIsLoading(false);
        navigate(`/app/pcw-user`);
      } else {
        snackBar.hide();
        setIsLoading(false);
        setUpdateErr(response?.errors);
      }
    }
  };
  const onAddressProfilePress = (address_id: any) => {
    navigate(`/app/pcw-user/user-address/${customer?.id}/${address_id}`);
  };

  const onDeleteAddressPress = useCallback(
    async (user_id: any, address_id: any) => {
      const response = unwrapResult(
        await dispatch(
          PcwAddressActions?.deleteUserAddressDetailThunk({
            user_id,
            address_id
          })
        )
      );
      if (response?.success) {
        getAccountAddresses(user_id);
        snackBar.show({
          severity: 'error',
          message: response?.message
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(() => {
    getAccountProfile();
    if (customer?.id) {
      getAccountAddresses(customer?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id]);

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Fragment>
          <LoaderBar isLoading={isLoading} />
          <Grid container spacing={3}>
            {customer ? <Profile customer={customer} /> : null}
            <Grid item xs={12}>
              {customer ? (
                <ProfileDetails
                  customer={customer}
                  onSaveDetails={(data: UserUpdatePayload) =>
                    onCustomerUpdate(data)
                  }
                  isLoading={isLoading}
                  updateErr={updateErr}
                />
              ) : null}
            </Grid>
             
            <Grid item xs={12}>
              {isAddresLoading ? (
                <LinearProgress />
              ) : (
                <div>
                  {canViewUserEcommAddress ? (
                    <ProfileAddress
                      onAddressPressProfile={onAddressProfilePress}
                      userAddress={addressList}
                      onDeleteClick={(userID, addressId) =>
                        onDeleteAddressPress(userID, addressId)
                      }
                      userID={customer ? customer?.id : ''}
                    />
                  ) : null}
                </div>
              )}
            </Grid>
          </Grid>
        </Fragment>
      </Container>
    </Page>
  );
}
