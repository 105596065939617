import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  CreateProductDetailResponse,
  CreateProductDetailsThunkRequest,
  DeleteProductDetailResponse,
  DeleteProductDetailsThunkRequest,
  GetProductDetailsResponse,
  GetProductDetailsThunkRequest,
  UpdateProductDetailResponse,
  UpdateProductDetailsThunkRequest
} from 'src/types';
import { transformToValidDataForUpdateUtil } from 'src/utils';
import { allowedCategoriesForProductPartDetails } from 'src/utils/categories/allowedCategoriesForProductPartDetails';

export const createProductDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<CreateProductDetailResponse>,
  CreateProductDetailsThunkRequest,
  { state: RootState }
>('product/createProductDetailsThunk', async (data) => {
  // Reminder. we are not using api.getState() here because we are
  // transforming the data first before giving it to API

  const response = await Api.post(
    `/products/details/${data?.category_name}/create`,
    data
  );
  return response;
});

export const getProductDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetProductDetailsResponse>,
  GetProductDetailsThunkRequest,
  { state: RootState }
>('product/getProductDetailsThunk', async (data) => {
  if (!allowedCategoriesForProductPartDetails.includes(data.category_name)) {
    throw new Error('Failed at non existing category on product part details.');
  } else {
    const response = await Api.get(
      `/products/details/${data?.category_name}/${data?.product_id}/get`,
      data
    );
    return response;
  }
});

export const updateProductDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateProductDetailResponse>,
  UpdateProductDetailsThunkRequest,
  { state: RootState }
>('product/updateProductDetailsThunk', async (data) => {
  const newDataForUpdate = transformToValidDataForUpdateUtil(data);
  const response = await Api.patch(
    `/products/details/${data?.category_name}/update`,
    newDataForUpdate
  );
  return response;
});

export const deleteProductDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteProductDetailResponse>,
  DeleteProductDetailsThunkRequest,
  { state: RootState }
>('categories/deleteProductDetailsThunk', async (data) => {
  const response = await Api.delete(
    `products/details/${data?.category_name}/${data?.product_id}/delete`
  );
  return response;
});
