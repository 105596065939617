import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  makeStyles
} from '@material-ui/core';
import { AccountProfileData, UserUpdatePayload } from 'src/types';
// import { useSnackBar } from 'src/hooks';
import { DatePicker } from '@material-ui/pickers';
import { Formik, useFormik } from 'formik';
import * as yup from 'yup';
import { usePermissions } from 'src/hooks';

interface Props {
  className?: string;
  customer: AccountProfileData;
  onSaveDetails: (data: UserUpdatePayload) => void;
  isLoading?: boolean;
  updateErr?: any;
}

const useStyles = makeStyles(() => ({
  root: {},
  uploadImageButton: {
    marginRight: 20
  }
}));

const ProfileDetails = ({
  className,
  customer,
  onSaveDetails,
  isLoading,
  updateErr
}: Props) => {
  const classes = useStyles();
  const [customerDetail, setCustomerDetail] = useState<AccountProfileData>(
    customer
  );
  const [birthday, setBirthday] = useState<string | undefined>(
    customerDetail?.birthdate
  );
  const [selectedStatus, setSelectedStatus] = useState<any>(
    customerDetail?.status
  );
  // const [isBdayValid, setBdayValid] = useState<boolean>(false);
  const [statusAcc, setStatusAcc] = useState<any>(customerDetail?.active);
  // const snackBar = useSnackBar();
  //TODO: dito ung may permission reference moto
  const { canEditEcomUserAccount } = usePermissions();

  const initialValues = {
    username: customerDetail?.username,
    first_name: customerDetail?.first_name,
    last_name: customerDetail?.last_name,
    email: customerDetail?.email,
    mobile_no: customerDetail?.mobile_no,
    birthdate: customerDetail?.birthdate
  };

  const handleFormSubmit = (values: any) => {
    //the formik handle other error handling we are specific here in birthdate picker

    onSaveDetails({
      id: customer?.id,
      username: values?.username,
      first_name: values?.first_name,
      last_name: values?.last_name,
      mobile_no: values?.mobile_no,
      email: values?.email,
      birthdate: birthday,
      status: selectedStatus,
      active: statusAcc
    });
  };

  const handleDateChange = (date: Date | null) => {
    const simplifiedDate = dayjs(date).format('YYYY-MM-DD') || '';
    setBirthday(simplifiedDate);
  };

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit
  } = useFormik({
    initialValues,
    onSubmit: handleFormSubmit,
    validationSchema: formSchema
  });

  useEffect(() => {
    setCustomerDetail(customer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit}
    >
      <Card>
        <CardHeader title="User Account Information" />
        <Divider />
        <CardContent>
          <Formik
            initialValues={initialValues}
            validationSchema={formSchema}
            onSubmit={() => {}}
          >
            <Grid container spacing={3}>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  // helperText="Please specify the first name"
                  label="Username"
                  name="username"
                  onChange={handleChange}
                  required
                  value={values.username}
                  onBlur={handleBlur}
                  variant="outlined"
                  error={!!touched.username && !!errors.username}
                  helperText={touched.username && errors.username}
                  disabled={!canEditEcomUserAccount}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="First name"
                  name="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  value={values.first_name}
                  variant="outlined"
                  error={!!touched.first_name && !!errors.first_name}
                  helperText={touched.first_name && errors.first_name}
                  disabled={!canEditEcomUserAccount}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Last name"
                  name="last_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  value={values.last_name}
                  variant="outlined"
                  error={!!touched.last_name && !!errors.last_name}
                  helperText={touched.last_name && errors.last_name}
                  disabled={!canEditEcomUserAccount}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="email"
                  required
                  value={values.email}
                  variant="outlined"
                  error={(!!touched.email && !!errors.email) || updateErr.email}
                  helperText={
                    (touched.email && errors.email) || updateErr.email
                  }
                  disabled={!canEditEcomUserAccount}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextField
                  fullWidth
                  label="Mobile No."
                  name="mobile_no"
                  inputProps={{ maxLength: 11 }}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                  value={values.mobile_no}
                  variant="outlined"
                  error={!!touched.mobile_no && !!errors.mobile_no}
                  helperText={touched.mobile_no && errors.mobile_no}
                  disabled={!canEditEcomUserAccount}
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <DatePicker
                  label="Birthdate"
                  value={birthday}
                  name="birthdate"
                  onChange={handleDateChange}
                  animateYearScrolling
                  format="dd/MM/yyyy"
                  views={['year', 'month', 'date']}
                  autoOk
                  inputVariant="outlined"
                  variant="inline"
                  maxDate={new Date()}
                  error={
                    (!!touched.mobile_no && !!errors.mobile_no) ||
                    updateErr.birthdate
                  }
                  helperText={
                    (touched.birthdate && errors.birthdate) ||
                    updateErr.birthdate
                  }
                  disabled={!canEditEcomUserAccount}
                />
              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel>Account Active</InputLabel>
                <Select
                  value={statusAcc}
                  onChange={(e: any) => setStatusAcc(e.target.value || '')}
                  label="Account Active"
                  variant="outlined"
                  style={{ width: 300 }}
                  disabled={!canEditEcomUserAccount}
                >
                  <MenuItem value="1">Active</MenuItem>
                  <MenuItem value="0">Deactivated</MenuItem>
                </Select>
              </Grid>
              <Grid item md={12} xs={12}>
                <InputLabel>Account Status</InputLabel>
                <Select
                  value={selectedStatus}
                  onChange={(e: any) => setSelectedStatus(e.target.value || '')}
                  label="Account Status"
                  variant="outlined"
                  style={{ width: 300 }}
                  disabled={!canEditEcomUserAccount}
                >
                  <MenuItem value="confirmed">Confirmed</MenuItem>
                  <MenuItem value="pending">Pending</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Formik>
        </CardContent>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={!canEditEcomUserAccount}
          >
            {isLoading ? (
              <CircularProgress color="secondary" size={20} />
            ) : (
              'Save Details'
            )}
          </Button>
        </Box>
      </Card>
    </form>
  );
};

const formSchema = yup.object().shape({
  username: yup.string().nullable(),
  // .required('username is required'),
  first_name: yup.string().required('firstname is required'),
  last_name: yup.string().required('lastname is required'),
  email: yup
    .string()
    .email('Invalid email')
    .nullable(),
  // .required('Email is required'),
  mobile_no: yup
    .string()
    .nullable()
    // .required('mobile number is required')
    .test(
      'mobile_no',
      'Invalid phone number, contact number must be 11 digits',
      (value) => {
        const phoneRegex = /^\d{11}$/;
        if (!value) {
          return true;
        }
        return phoneRegex.test(value);
      }
    ),
  birthdate: yup //birthdate is required as payload
    .date()
    .nullable()
    .max(new Date(), 'Date must be in the past')
  // .required('Date is required')
});

export default ProfileDetails;
