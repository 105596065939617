import { Grid, IconButton, TableCell } from '@material-ui/core';
import React from 'react';
import { AlternatingColorTableRow } from 'src/components/table';
import { RaffleEntry } from 'src/types';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { slices, useAppDispatch } from 'src/redux';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

interface Props {
  winner: RaffleEntry;
  index: number;
}

const { actions: raffleActions } = slices.raffle;

export const WinnerListItem = ({ winner, index }: Props) => {
  const dispatch = useAppDispatch();

  const displayContact = (winner: RaffleEntry) => {
    if (winner.mobile_no) {
      if (winner.isContactDetailVisible) {
        return winner.mobile_no;
      }
      return formatMobileNumber(winner.mobile_no);
    }
    if (winner.isContactDetailVisible) {
      return winner.email;
    }
    return formatEmail(winner.email);
  };

  const formatMobileNumber = (mobileNumber: string | undefined) => {
    const visiblePart = '****-***-';
    const hiddenPart = mobileNumber?.slice(-4);
    return visiblePart + hiddenPart;
  };

  const formatEmail = (email: string | undefined) => {
    if (!email) {
      return '';
    }

    const parts = email.split('@');
    if (parts.length !== 2) {
      return email;
    }

    const username = parts[0];
    const domain = parts[1];
    const visibleUsername = username.slice(-3);
    const formattedText = '*'.repeat(username.length - 3);
    return `${formattedText}${visibleUsername}@${domain}`;
  };

  return (
    <AlternatingColorTableRow key={winner.id}>
      <TableCell>{winner.full_name}</TableCell>
      <TableCell>{displayContact(winner)}</TableCell>
      <TableCell>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <IconButton
              onClick={() =>
                dispatch(raffleActions?.setDisplayedContactVisibility(index))
              }
            >
              {winner.isContactDetailVisible ? (
                <VisibilityIcon />
              ) : (
                <VisibilityOffIcon />
              )}
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => {
                dispatch(raffleActions.removeRaffleWinner(winner));
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Grid>
        </Grid>
      </TableCell>
    </AlternatingColorTableRow>
  );
};
