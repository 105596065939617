import React, { FC, memo, useMemo } from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { limitPerPage, transferLogPdfHeader } from '../../constant';
import { transferLogsText } from './styles';
import {
  TransferLogsSerialData,
  FormattedTransferLogsData
} from 'src/redux/slices/transfer-stock-logs';

const styles = StyleSheet.create({
  //==========
  table: {
    display: 'flex',
    width: 'auto',
    borderStyle: 'solid',
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 5
  },
  tableRow: {
    flexDirection: 'row'
  },
  tableCol: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: {
    padding: 5,
    margin: 'auto'
  },
  tableColChild: {
    borderStyle: 'solid',
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    height: 'auto',
    flexGrow: 1
  },
  tableImageRow: { flexDirection: 'row' },
  imageStyle: {
    height: 160,
    width: 200,
    position: 'absolute', //important to control the size of image
    padding: 5
  }
});

interface Props {
  encrpytedFile: boolean;
  transferLogsItem?: TransferLogsSerialData[];
}

const component: FC<Props> = ({ encrpytedFile, transferLogsItem }) => {
  const logsItemPerLine = useMemo(() => {
    let newItems = Array.from({
      length: limitPerPage
    }).map((_, index) => {
      if (transferLogsItem && transferLogsItem?.length > 0) {
        const item = transferLogsItem[index];
        if (item) {
          return {
            listing_id: item?.listing_id || '',
            category_name: item?.category_name || '',
            product_name: item?.product_name || '',
            serial_no: encrpytedFile
              ? item?.encrypted_serial_no || ''
              : item?.serial_no || '',
            encrypted_serial_no: item?.encrypted_serial_no || '',
            product_type: item?.product_type || '',
            dr_no: item?.dr_no || ''
          };
        }
      }
      // undefined field object for empty rows
      return {
        listing_id: '',
        category_name: '',
        product_name: '',
        serial_no: '',
        dr_no: '',
        encrypted_serial_no: '',
        product_type: ''
      };
    });

    return newItems;
  }, [encrpytedFile, transferLogsItem]);

  //this logic is to group the product by name
  const formattedLogsItem: FormattedTransferLogsData[] = useMemo(() => {
    const result = logsItemPerLine.reduce((accumulator: any[], value: any) => {
      const existingProductIndex = accumulator.findIndex(
        (item) => item.product_name === value.product_name
      );
      //We push empty data to occupy rows in our table
      //the value comes from logsItemPerLine
      if (value?.product_name === '' || !value?.product_name) {
        accumulator.push({
          product_name: '',
          data: [
            {
              listing_id: value?.listing_id || '',
              product_name: '',
              serial_no: value?.serial_no || '',
              dr_no: value?.dr_no || '',
              product_type: value?.product_type || ''
            }
          ]
        });
        return accumulator;
      }
      //if not exist in our accumulator push new product
      if (existingProductIndex === -1) {
        // Product does not exist in accumulator, add a new entry
        accumulator.push({
          product_name: value?.product_name,
          category_name: value?.category_name,
          data: [
            {
              listing_id: value?.listing_id || '',
              product_name: value?.product_name || '',
              category_name: value?.category_name || '',
              serial_no: value?.serial_no || '',
              product_type: value?.product_type || '',
              dr_no: value?.dr_no || ''
            }
          ]
        });
      } else {
        // Product already exists in accumulator, push the serial_no to existing data array
        accumulator[existingProductIndex].data.push({
          listing_id: value?.listing_id || '',
          product_name: value?.product_name || '',
          category_name: value?.category_name || '',
          serial_no: value?.serial_no || '',
          product_type: value?.product_type || '',
          dr_no: value?.dr_no || ''
        });
      }

      return accumulator;
    }, []);

    return result;
  }, [logsItemPerLine]);

  return (
    <View style={styles.table}>
      <View style={styles.tableRow}>
        {transferLogPdfHeader.map((item, index) => (
          <View style={[styles.tableCol, { width: item.width }]} key={index}>
            <Text style={[styles.tableCell, transferLogsText.textBold]}>
              {item.name}
            </Text>
          </View>
        ))}
      </View>

      {formattedLogsItem?.map((logs, index) => (
        <View style={[styles.tableRow, { height: 'auto' }]} key={index}>
          <View
            style={[
              {
                height: 'auto',
                flexDirection: 'column',
                width: '45%'
              }
            ]}
          >
            {logs?.data?.map((item, index) => (
              <View
                style={[
                  {
                    width: '100%',
                    flexDirection: 'column'
                  }
                ]}
                key={index}
              >
                <View style={[{ flexDirection: 'row', width: '100%' }]}>
                  <View style={[styles.tableColChild, { width: '45%' }]}>
                    <Text style={[styles.tableCell, transferLogsText.text]}>
                      {item?.dr_no}
                    </Text>
                  </View>
                  <View
                    style={[
                      styles.tableColChild,
                      {
                        width: '56%',
                        height:
                          item?.product_type === 'non-serialized' &&
                          encrpytedFile
                            ? '40pt'
                            : 'auto'
                      }
                    ]}
                  >
                    {item?.product_type === 'non-serialized' &&
                    encrpytedFile ? (
                      <Image
                        style={styles.imageStyle}
                        src={item?.serial_no || ''}
                      />
                    ) : (
                      <Text style={[styles.tableCell, transferLogsText.text]}>
                        {item?.serial_no}
                      </Text>
                    )}
                  </View>
                </View>
              </View>
            ))}
          </View>

          <View style={[styles.tableCol, { width: '10%' }]}>
            <Text style={[styles.tableCell, transferLogsText.text]}>
              {logs?.category_name}
            </Text>
          </View>

          <View style={[styles.tableCol, { width: '45%' }]}>
            <Text style={[styles.tableCell, transferLogsText.text]}>
              {logs?.product_name}
            </Text>
          </View>
        </View>
      ))}
    </View>
  );
};

export const ViewTableItemLogs = memo(component);
