export enum CategoriesEnum {
  GPU = 21,
  CPU = 16,
  Motherboard = 17,
  RAM = 13,
  SSD = 14,
  HDD = 15,
  PSU = 18,
  Casing = 20,
  Monitor = 25,
  CpuCooler = 26,
  Fan = 19
}
