import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectTransaction = (state: RootState) => state.transaction;

const selectTransactions = createSelector(selectTransaction, (state) => state.transactions);
const isCreateTransactionLoading = createSelector(selectTransaction, (state) => state.isCreateLoading);

export const selectors = {
  selectTransactions,
  isCreateTransactionLoading
};
