import Cookies from 'js-cookie';

const set = (key: string, data: string) => {
  Cookies.set(key, data);
};

const get = (key: string) => {
  return Cookies.getJSON(key);
};

const remove = (key: string) => {
  return Cookies.remove(key);
};

export default {
  set,
  get,
  remove
};
