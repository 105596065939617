import React, { useMemo } from 'react';
import { DateTimePicker } from '@material-ui/pickers';
import {
  IconButton,
  makeStyles,
  Typography,
  TypographyProps
} from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { FixMeLater } from 'src/types';
import ClearIcon from '@material-ui/icons/Clear';
import { dateToday } from 'src/utils';

interface Props {
  title?: string;
  titleProps?: TypographyProps;
  dateTimeFromLabel?: string;
  dateTimeToLabel?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  fromDateTimeValue?: ParsableDate;
  toDateTimeValue?: ParsableDate;
  fromDateMin?: ParsableDate;
  toDateMin?: ParsableDate;
  onChangeFromDateTime: (date: MaterialUiPickersDate) => void;
  onChangeToDateTime: (date: MaterialUiPickersDate) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  datePickersContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dashSeparator: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}));

export const DateTimePickerRange = ({
  title,
  disabled = false,
  dateTimeToLabel = 'To',
  dateTimeFromLabel = 'From',
  fromDateTimeValue,
  toDateTimeValue,
  onChangeFromDateTime,
  onChangeToDateTime,
  fullWidth = true,
  titleProps,
  fromDateMin = dateToday(),
  toDateMin =  dateToday()
}: Props) => {
  const classes = useStyles();

  const onClearPress = (e: FixMeLater, item: 'to' | 'from') => {
    e.stopPropagation();
    if (item === 'from') {
      onChangeFromDateTime(null);
    }
    if (item === 'to') {
      onChangeToDateTime(null);
    }
  };

  const defaultMinimumDateOfToDate = useMemo(() => {
    if (toDateMin) {
      return toDateMin;
    }
    if (fromDateTimeValue) {
      return fromDateTimeValue;
    }
    return dateToday()
  }, [fromDateTimeValue, toDateMin]);

  return (
    <div>
      {title ? (
        <Typography align="center" variant="h5" {...titleProps}>
          {title}
        </Typography>
      ) : null}
      <div className={classes.datePickersContainer}>
        <DateTimePicker
          autoOk
          disabled={disabled}
          fullWidth={fullWidth}
          format="PPp"
          inputVariant="outlined"
          name="from"
          minDate={fromDateMin}
          animateYearScrolling
          value={fromDateTimeValue}
          variant="inline"
          label={dateTimeFromLabel}
          onChange={onChangeFromDateTime}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={(e) => onClearPress(e, 'from')}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />
        <Typography
          align="center"
          variant="h1"
          className={classes.dashSeparator}
        >
          -
        </Typography>
        <DateTimePicker
          autoOk
          disabled={disabled}
          fullWidth={fullWidth}
          format="PPp"
          minDate={defaultMinimumDateOfToDate}
          inputVariant="outlined"
          name="to"
          animateYearScrolling
          value={toDateTimeValue}
          variant="inline"
          label={dateTimeToLabel}
          onChange={onChangeToDateTime}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={(e) => onClearPress(e, 'to')}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />
      </div>
    </div>
  );
};
