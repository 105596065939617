import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import { InitialBlogState } from './types';

const initialState: InitialBlogState = {
  getBlogListLoading: false,
  getBlogDetailsLoading: false,
  getBlogDetails: {},
  getBlogListResponse: {
    meta: {
      last_page: 1,
      current_page: 1
    }
  }
};

const slice = createSlice({
  name: 'blog',
  initialState,
  //TODO: add reducer forr update blogs later
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getBlogListThunk.pending, (state) => {
      state.getBlogListLoading = true;
    });
    builder.addCase(thunks.getBlogListThunk.rejected, (state) => {
      state.getBlogListLoading = false;
    });
    builder.addCase(thunks.getBlogListThunk.fulfilled, (state, { payload }) => {
      state.getBlogListLoading = false;
      state.getBlogListResponse = payload?.originalData;
    });

    //===blog details===
    builder.addCase(thunks.getBlogDetailsThunk.pending, (state) => {
      state.getBlogDetailsLoading = true;
    });
    builder.addCase(thunks.getBlogDetailsThunk.rejected, (state) => {
      state.getBlogDetailsLoading = false;
    });
    builder.addCase(
      thunks.getBlogDetailsThunk.fulfilled,
      (state, { payload }) => {
        state.getBlogDetailsLoading = false;
        state.getBlogDetails = payload?.originalData?.data || {};
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = { ...slice.actions, ...thunks };
