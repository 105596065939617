import React, { FC, memo } from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import { ViewPrintCompanyLogo } from './ViewLogoLogs';
import { ViewStDetailsLogs } from './ViewStDetailLogs';

const styles = StyleSheet.create({
  parent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column'
  }
});

interface Props {
  title?: string;
  stNo?: string;
  dateTransfer?: string;
  origin?: string;
  receiving?: string;
  st_no_barcode?: string;
}

const component: FC<Props> = ({
  title = 'Stock Transfer',
  stNo,
  dateTransfer,
  origin,
  receiving,
  st_no_barcode
}) => {
  return (
    <View style={styles.parent}>
      <ViewPrintCompanyLogo stBarcode={st_no_barcode} />
      <ViewStDetailsLogs
        title={title}
        stNo={stNo}
        date={dateTransfer}
        origin={origin}
        receiving={receiving}
      />
    </View>
  );
};

export const ViewHeaderLogs = memo(component);
