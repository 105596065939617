import React, { useMemo } from 'react';
import { Divider, makeStyles, TableCell, Typography } from '@material-ui/core';

import { SaleItem } from 'src/types';
import { colors } from 'src/constants';
import { formatCurrency } from 'src/utils';

interface Props {
  products?: SaleItem[];
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  itemContainer: {
    marginBottom: theme.spacing(1)
  },
  serialNo: {
    color: colors.grey[600]
  },
  itemFooter: {
    display: 'flex'
  },
  quantity: {
    color: colors.purple[300],
    paddingRight: theme.spacing(1),
    borderRight: '1px solid'
  },
  dealersPrice: {
    color: colors.orange[600],
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    borderRight: '1px solid'
  },
  retailPrice: {
    color: colors.blue[600],
    paddingLeft: theme.spacing(1)
  }
}));

const TableCellProducts = ({ products = [] }: Props) => {
  const classes = useStyles();

  const filteredProducts = useMemo(() => {
    const filtered = products?.filter((x) => !x?.isDeleted);
    return filtered || [];
  }, [products]);

  return (
    <TableCell style={{ verticalAlign: 'top' }}>
      {filteredProducts?.map((product, index) => {
        return (
          <div
            key={`${product?.serial_no}-${index}`}
            className={classes.itemContainer}
          >
            <Typography variant="h6">{product?.product_name}</Typography>
            <Typography variant="subtitle2" className={classes.serialNo}>
              {product?.serial_no}
            </Typography>
            <div className={classes.itemFooter}>
              <Typography variant="subtitle2" className={classes.quantity}>
                QTY: {`${product?.quantity}`}
              </Typography>
              <Typography variant="subtitle2" className={classes.dealersPrice}>
                DP: {formatCurrency(product?.dealers_price || 0)}
              </Typography>
              <Typography variant="subtitle2" className={classes.retailPrice}>
                RP: {formatCurrency(product?.retail_price || 0)}
              </Typography>
            </div>
            <Divider />
          </div>
        );
      })}
    </TableCell>
  );
};

export const SalesProductsTableCell = React.memo(TableCellProducts);
