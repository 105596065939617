import React, { FC, memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { AuditListingPdfData } from 'src/redux/slices/inventory-audit-logs';
import { transferLogsText } from 'src/views/stock-transfer/transfer-stocks-log/TransferLogPDF/styles';

const styles = StyleSheet.create({
  table: {
    display: 'flex',
    width: 'auto',
    marginTop: 3,
    border: 'collapse'
  },
  tableHead: {
    marginTop: 3,
    flexDirection: 'row',
    backgroundColor: '#f0f0f0',
    borderLeftWidth: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    textAlign: 'center'
  },
  tableRow: {
    flexDirection: 'row',
    borderLeftWidth: 1,
    borderBottomWidth: 1
  },
  tableCol: {
    width: '43%',
    borderStyle: 'solid',
    borderRightWidth: 1
  },
  tableCell: {
    margin: 5,
    fontSize: 10
  },
  tableHeaderCell: {
    margin: 5,
    fontSize: 12,
    fontWeight: 'bold'
  }
});

const dateHeader = [
  { name: 'Product Name', width: '43%' },
  { name: 'Missing Serial Number', width: '43%' },
  { name: 'Audited Count', width: '7%' },
  { name: 'Available Audit Count', width: '7%' }
];

interface Props {
  auditListing?: AuditListingPdfData[];
}

const component: FC<Props> = ({ auditListing }) => {
  return (
    <View style={styles.table}>
      <View style={styles.tableHead}>
        {dateHeader.map((header, index) => (
          <View key={index} style={[styles.tableCol, { width: header?.width }]}>
            <Text style={[styles.tableHeaderCell, transferLogsText?.textBold]}>
              {header?.name}
            </Text>
          </View>
        ))}
      </View>

      {auditListing?.map((row, rowIndex) => (
        <View key={rowIndex} style={styles.tableRow}>
          <View key={rowIndex} style={styles.tableCol}>
            <Text style={[styles.tableCell, transferLogsText?.text]}>
              {row?.product_name}
            </Text>
          </View>
          <View key={rowIndex} style={styles.tableCol}>
            <Text style={[styles.tableCell, transferLogsText?.text]}>
              {row?.missing_serial_nos}
            </Text>
          </View>
          <View
            key={rowIndex}
            style={[styles.tableCol, { width: '7%', textAlign: 'center' }]}
          >
            <Text style={[styles.tableCell, transferLogsText?.text]}>
              {row?.audited_quantity}
            </Text>
          </View>
          <View
            key={rowIndex}
            style={[styles.tableCol, { width: '7%', textAlign: 'center' }]}
          >
            <Text style={[styles.tableCell, transferLogsText?.text]}>
              {row?.available_items_count}
            </Text>
          </View>
        </View>
      ))}
    </View>
  );
};

export const ViewTableAuditLogs = memo(component);
