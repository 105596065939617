import { cloneDeep } from 'lodash';
import { Gallery } from 'src/types';
import { galleryKeywordGenerator } from './galleryDetailsToCreateTransformer';

export const galleryDetailsToUpdateTransformer = (galleryDetails: Gallery) => {
  const clonedData = cloneDeep(galleryDetails);
  clonedData.keyword = galleryKeywordGenerator(galleryDetails);

  // if clonedData?.badge is not base64 then add the raw, if it exist. else undefined.
  if (!clonedData?.badge?.includes('data:')) {
    clonedData.badge = undefined;
  }
  // if clonedData?.cover is not base64 then add the raw, if it exist. else undefined.
  if (!clonedData?.cover?.includes('data:')) {
    clonedData.cover = undefined;
  }

  // TODO: Uncomment if not fixed on api
  // const mappedBuildTags: any = (clonedData?.build_tags || [])
  //   ?.filter((y) => y !== '')
  //   ?.join(',');
  // clonedData.build_tags = mappedBuildTags;

  // const mappedProductIds: number[] = (clonedData?.components || [])
  //   ?.map((x) => x?.product_id || 0)
  //   ?.filter((y) => y !== 0);
  // const mappedPhotos: string[] = (clonedData?.photos || [])
  //   ?.map((x) => x?.url || '')
  //   ?.filter((y) => y !== '');

  // const newData: GalleryCreateRequest = {
  //   title: clonedData?.title,
  //   cover: clonedData?.cover,
  //   badge: clonedData?.badge,
  //   build_tier: clonedData?.build_tier,
  //   client_id: 0,
  //   quote: clonedData?.quote,
  //   description: clonedData?.description,
  //   cpu: clonedData?.cpu,
  //   keyword: galleryKeywordGenerator(clonedData),
  //   build_tags: buildTagsToString(clonedData?.build_tags),
  //   references: mappedReferences,
  //   product_ids: mappedProductIds,
  //   photos: mappedPhotos
  // };

  return clonedData;
};
