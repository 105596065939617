import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectPermissionState = (state: RootState) => state.permission;

const selectPermissionList = createSelector(
  selectPermissionState,
  (state) => state.permissions
);

export const selectors = {
  selectPermissionList
};
