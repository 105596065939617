import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectInventoryAudit = (state: RootState) => state.inventoryAudit;

const selectInventoryAuditProductListing = createSelector(
  selectInventoryAudit,
  (state) => state.inventoryAuditProductListing
);

const selectCommonAuditParams = createSelector(
  selectInventoryAudit,
  (state) => state.commonParams
);

const selectToBeAuditProductList = createSelector(
  selectInventoryAudit,
  (state) => state.selectedAuditProductList
);

export const selectors = {
  selectInventoryAuditProductListing,
  selectCommonAuditParams,
  selectToBeAuditProductList
};
