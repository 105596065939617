import React, { useEffect, useState } from 'react';
import {
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';

import { Product } from 'src/types';
import { Autocomplete } from '@material-ui/lab';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { debounce } from 'lodash';
import { DateTimePickerRange } from 'src/components';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { useLocation } from 'react-router-dom';
import { useIsMount } from 'src/hooks';

const { actions: productActions } = slices.product;
interface Props {
  dateRangeDisabled?: boolean;
  productSelectDisabled?: boolean;

  selectedProduct?: Product;
  onProductChange?: (newProduct: Product) => void;

  fromDateTime?: ParsableDate;
  onFromDateTimeChange?: (d?: MaterialUiPickersDate) => void;

  toDateTime?: ParsableDate;
  onToDateTimeChange?: (d?: MaterialUiPickersDate) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  dateSection: {
    marginTop: theme.spacing(2)
  }
}));

const component = ({
  productSelectDisabled = false,
  dateRangeDisabled = false,
  selectedProduct,
  onProductChange,
  fromDateTime,
  onFromDateTimeChange = () => {},
  toDateTime,
  onToDateTimeChange = () => {}
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const productFromStateLocation: Product = location.state;
  const isFirstMount = useIsMount();

  //TODO: by default branch id is equal to 1
  const branch_ids = [1];

  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);

  const getProducts = debounce(async (keyword: string) => {
    setLoading(true);
    const response = unwrapResult(
      await dispatch(
        productActions.getProductsForGalleryThunk({ keyword, branch_ids })
      ).finally(() => setLoading(false))
    );
    if (response?.success && response?.originalData?.data?.length > 0) {
      setProducts(response?.originalData?.data || []);
    }
  }, 500);

  const onAddComponentInternal = (newProduct: Product | null) => {
    if (onProductChange && newProduct) {
      onProductChange(newProduct);
    }
  };

  useEffect(() => {
    if (isFirstMount && productFromStateLocation && onProductChange) {
      setProducts((prev) => [...prev, productFromStateLocation]);
    }
  }, [isFirstMount, onProductChange, productFromStateLocation]);

  useEffect(() => {
    if (isFirstMount && productFromStateLocation && onProductChange) {
      onProductChange(productFromStateLocation);
    }
  }, [isFirstMount, onProductChange, productFromStateLocation]);

  return (
    <CardContent>
      <Typography className={classes.subHeader} variant="h6">
        Product Name
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Autocomplete
            fullWidth
            disabled={productSelectDisabled}
            loading={loading}
            // onFocus={onFocusProductField}
            value={selectedProduct}
            onChange={(_, newValue) => onAddComponentInternal(newValue)}
            // inputValue={input || ''}
            onInputChange={(_, newInputValue) => getProducts(newInputValue)}
            id="product_name"
            options={products}
            getOptionLabel={(option) => `${option?.name}`}
            renderInput={(params) => (
              <TextField
                {...params}
                name="product_name"
                label="Product Name"
                variant="outlined"
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid container spacing={3} className={classes.dateSection}>
        <Grid item xs={12}>
          <DateTimePickerRange
            disabled={dateRangeDisabled}
            title="Deal Start and End"
            titleProps={{ align: 'left' }}
            dateTimeFromLabel="Deal Start"
            dateTimeToLabel="Deal End"
            fromDateTimeValue={fromDateTime}
            toDateTimeValue={toDateTime}
            onChangeFromDateTime={(d) => onFromDateTimeChange(d)}
            onChangeToDateTime={(d) => onToDateTimeChange(d)}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export const FlashDealsProductsCardContent = React.memo(component);
