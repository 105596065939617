import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  CreateGalleryResponse,
  GalleryPhotosCreateRequest
} from 'src/types';

export const galleryPhotosCreateThunk = createAsyncThunk<
  CommonAxiosResponse<CreateGalleryResponse>,
  GalleryPhotosCreateRequest,
  { state: RootState }
>('gallery/galleryPhotosCreateThunk', async (data) => {
  const response = await Api.post(`/gallery/photo/upload/create`, data);
  return response;
});
