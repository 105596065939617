import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectBuildsState = (state: RootState) => state.userBuilds;

const selectBuildList = createSelector(
  selectBuildsState,
  (state) => state.buildList
);

const selectBuildListLinks = createSelector(
  selectBuildsState,
  (state) => state.links
);

const selectBuildListMeta = createSelector(
  selectBuildsState,
  (state) => state.meta
);

export const selectors = {
  selectBuildList,
  selectBuildListLinks,
  selectBuildListMeta
};
