import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, EcomOrderGetAvailableProductsResponse } from 'src/types';

export const ecomOrderGetAvailableProductsThunk = createAsyncThunk<
  CommonAxiosResponse<EcomOrderGetAvailableProductsResponse>,
  string,
  { state: RootState }
>('ecom/ecomOrderGetAvailableProductsThunk', async (keyword) => {
  const response = await Api.get('/ecomm/products/available/get', {
    category: null,
    keyword
  });
  return response;
});
