import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RaffleEntry } from 'src/types';
import * as thunks from './thunks';

type State = {
  raffleWinner: RaffleEntry | null;
  raffleWinners: RaffleEntry[];
  availableRaffleEntries: RaffleEntry[];
  removedRaffleWinners: RaffleEntry[];
};

const initialState: State = {
  raffleWinner: null,
  raffleWinners: [],
  availableRaffleEntries: [],
  removedRaffleWinners: []
};

const slice = createSlice({
  name: 'raffle',
  initialState,
  reducers: {
    setRaffleWinner: (state, action: PayloadAction<RaffleEntry>) => {
      state.raffleWinner = action.payload;
    },
    addRaffleWinners: (state, action: PayloadAction<RaffleEntry>) => {
      const raffleEntry: RaffleEntry = {
        ...action.payload,
        isContactDetailVisible: false
      };
      state.raffleWinners.push(raffleEntry);
    },

    removeRaffleWinner: (state, action: PayloadAction<RaffleEntry>) => {
      const targetId = action.payload.id;
      const targetIndex = state.raffleWinners.findIndex(
        (item) => item.id === targetId
      );
      let removedWinner = null;

      if (targetIndex !== -1) {
        removedWinner = state.raffleWinners.splice(targetIndex, 1);
        state.removedRaffleWinners.push(removedWinner[0]);
      }
    },

    setAvailableRaffleEntries: (
      state,
      action: PayloadAction<RaffleEntry[]>
    ) => {
      state.availableRaffleEntries = action.payload;
    },

    clearRaffleWinner: (state) => {
      state.raffleWinner = null;
    },

    setDisplayedContactVisibility: (state, action: PayloadAction<number>) => {
      state.raffleWinners[action.payload].isContactDetailVisible = !state
        .raffleWinners[action.payload].isContactDetailVisible;
    }
  },
  extraReducers(builder) {
    builder.addCase(thunks.getRaffleEntries.fulfilled, (state, { payload }) => {
      state.availableRaffleEntries = payload?.originalData?.data || [];
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
