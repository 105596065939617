import React from 'react';
import { Button, Link, makeStyles, Typography } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { colors } from 'src/constants';
import { useNavigate } from 'react-router-dom';

interface TypographyPriceListProps {
  text?: string;
  color?: string;
  link?: string;
  fontSize?: number;
  branchIds?: number[];
  onCopy?: () => void;
  extraTextStyle?: React.CSSProperties;
}

const useStyles = makeStyles(
  {
    row: {
      flexDirection: 'row',
      display: 'flex'
    }
  },
  { index: 999 }
);

export const PriceList = ({
  text,
  onCopy,
  link,
  color = colors.black,
  fontSize = 14,
  extraTextStyle,
  branchIds
}: TypographyPriceListProps) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.row}>
      {onCopy ? (
        <Button
          style={{ padding: 0, margin: 0 }}
          startIcon={<FileCopyIcon />}
          onClick={onCopy}
        />
      ) : null}

      {link ? (
        <Link
          href={link}
          onClick={() => navigate(link, { state: branchIds || [] })}
        >
          <Typography
            style={{
              letterSpacing: 0.5,
              fontWeight: 600,
              color,
              fontSize,
              ...extraTextStyle
            }}
          >
            {text}
          </Typography>
        </Link>
      ) : (
        <Typography
          style={{
            letterSpacing: 0.5,
            fontWeight: 600,
            color,
            fontSize,
            ...extraTextStyle
          }}
        >
          {text}
        </Typography>
      )}
    </div>
  );
};

export const TypographyPriceList = React.memo(PriceList);
