import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';

//TODO: for improvements in future
const slice = createSlice({
  name: 'outOfStocksProduct',
  initialState: {},
  reducers: {}
});

export const actions = { ...slice.actions, ...thunks };
export const reducer = slice.reducer;
