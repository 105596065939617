import React, { FC, memo, useMemo } from 'react';
import { View, StyleSheet, Text } from '@react-pdf/renderer';
import { ViewPrintCompanyLogo } from 'src/views/stock-transfer/transfer-stocks-log/TransferLogPDF/ViewLogoLogs';
import { transferLogsText } from 'src/views/stock-transfer/transfer-stocks-log/TransferLogPDF/styles';

const styles = StyleSheet.create({
  parent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'column'
  },
  head: {
    padding: 3,
    marginBottom: 10,
    minWidth: '100%',
    alignItems: 'center',
    justifyContent: 'center'
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%'
  },
  detailRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end'
  }
});

interface Props {
  title?: string;
  ial_no?: string;
}

const component: FC<Props> = ({ title = 'Stock Transfer', ial_no }) => {
  const formattedDate = useMemo(() => {
    const date = new Date(Date.now() || '');
    const dateResult = date.toLocaleDateString();
    return dateResult;
  }, []);

  return (
    <View style={styles.parent}>
      <ViewPrintCompanyLogo />
      <View style={styles.parent}>
        <View style={styles.head}>
          <Text style={[transferLogsText.textBold, { fontSize: '12px' }]}>
            {title}
          </Text>
        </View>

        <View style={{ minWidth: '100%', flexDirection: 'row' }}>
          <View style={{ flexDirection: 'column', width: '75%' }}>
            <View style={{ flexDirection: 'row' }}>
              <Text style={transferLogsText.textBold}>{`IAL NO.: `}</Text>
              <Text style={transferLogsText.text}>{ial_no}</Text>
            </View>

            <View style={{ flexDirection: 'row' }}>
              <Text style={transferLogsText.textBold}>{`DATE: `}</Text>
              <Text style={transferLogsText.text}>{formattedDate}</Text>
            </View>
          </View>
        </View>
      </View>
    </View>
  );
};

export const AuditHeader = memo(component);
