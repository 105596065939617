import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  FlashDealsUpdateRequest,
  UpdateFlashDealsResponse
} from 'src/types';

export const flashDealsUpdateThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateFlashDealsResponse>,
  FlashDealsUpdateRequest,
  { state: RootState }
>('flashDeals/flashDealsUpdateThunk', async (data) => {
  const updateDateRes = await Api.patch(
    `/ecomm/internal/flash_deal/details/update/${data?.id}`,
    data
  );
  return updateDateRes;
});
