import React, { useMemo } from 'react';
import { TextField, makeStyles, Chip } from '@material-ui/core';

import { colors } from 'src/constants';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  value?: string;
  onChange?: (str: string | null) => void;
  label?: string;
  name?: string;
  required?: boolean;
  error?: boolean;
  helperText?: string;
}

const buildTagsSuggestions: string[] = [
  'Streaming',
  'Gaming',
  'Video Editing',
  'Mid',
  'Entry'
];

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  uploadImageField: {
    borderWidth: 1,
    borderColor: colors.grey[800]
  }
}));

const component = ({
  onChange,
  value,
  label,
  name,
  required,
  error,
  helperText
}: Props) => {
  const classes = useStyles();

  const fieldValue: string[] = useMemo(() => {
    let valueInArr = value?.split(',');
    valueInArr = valueInArr?.map((x) => x?.trim());
    return valueInArr || [];
  }, [value]);

  const onChangeInternal = (newValue: string[]) => {
    if (onChange) {
      let newValuesArr = newValue || [];
      newValuesArr = newValuesArr?.map((x) => x?.trim());
      const newValuesStr = newValuesArr?.join() || null;
      onChange(newValuesStr);
    }
  };

  return (
    <div className={classes.uploadImageField}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={buildTagsSuggestions}
        freeSolo
        value={fieldValue || []}
        // Intended any
        renderTags={(value: any, getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              key={`${option}${index}`}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        // Intended any
        onChange={(_, newValue: any) => onChangeInternal(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            name={name}
            error={error}
            label={label}
            variant="outlined"
            required={required}
            helperText={helperText}
          />
        )}
      />
    </div>
  );
};

export const MultipleChipInput = React.memo(component);
