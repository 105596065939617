import { slices, useAppDispatch } from 'src/redux';
import { AlertGlobal } from 'src/types';

const { actions } = slices.app;

export const useAlertGlobal = () => {
  const dispatch = useAppDispatch();

  const show = (data: AlertGlobal) => {
    dispatch(actions.showAlertGlobal(data));
  };

  const hide = async () => {
    dispatch(actions.hideAlertGlobal());
  };

  return {
    show,
    hide
  };
};
