import { useCallback, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import {
  KachiPointsAddRequest,
  KachiPointsForfeitRequest,
  KachiPointsUserListRequest
} from 'src/types/kachi-points';

const {
  actions: kachiPointsActions,
  selectors: kachiPointsSelectors
} = slices.kachiPoints;

export const useKachiPoints = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>();

  const kachiPointsUserList = useAppSelector(
    kachiPointsSelectors?.selectKachiPointsUserList
  );
  const kachiPointsUserHistory = useAppSelector(
    kachiPointsSelectors?.selectKachiPointsUserHistory
  );
  const kachiPointsUserListLoading = useAppSelector(
    kachiPointsSelectors?.selectUserListLoading
  );
  const kachiPointsUserHistoryLoading = useAppSelector(
    kachiPointsSelectors?.selectUserHistoryLoading
  );
  const showAddKachiPointsForm = useAppSelector(
    kachiPointsSelectors?.selectShowAddKachiPointForm
  );

  const kachiListMeta = useAppSelector(
    kachiPointsSelectors?.selectKachiUserListMeta
  );

  const kachiHistoryMeta = useAppSelector(
    kachiPointsSelectors?.selectKachiUserHistorytMeta
  );

  //=================

  const getKachiPointsUserList = useCallback(
    async (data?: KachiPointsUserListRequest) => {
      await dispatch(kachiPointsActions?.getKachiPointsUserListThunk(data));
    },
    [dispatch]
  );

  const getKachiPointsUserHistory = useCallback(
    async (user_id?: number, pageNum?: number) => {
      return await dispatch(
        kachiPointsActions?.getKachiUserHistoryThunk({ user_id, page: pageNum })
      );
    },
    [dispatch]
  );

  const addKachiPoints = useCallback(
    async (data: KachiPointsAddRequest) => {
      return await dispatch(kachiPointsActions?.addKachiPointsThunk(data));
    },
    [dispatch]
  );

  const forfeitUserKachiPoints = useCallback(
    async (data: KachiPointsForfeitRequest) => {
      return await dispatch(kachiPointsActions?.deleteKachiPointsThunk(data));
    },
    [dispatch]
  );

  const setShowAddKachiPointsForm = useCallback(
    async (isOpen: boolean) => {
      await dispatch(kachiPointsActions?.showAddKachiForm(isOpen));
    },
    [dispatch]
  );

  return {
    kachiPointsUserListLoading,
    kachiPointsUserHistoryLoading,
    kachiPointsUserList,
    kachiPointsUserHistory,
    showAddKachiPointsForm,
    isLoading,

    kachiListMeta,
    kachiHistoryMeta,

    getKachiPointsUserList,
    getKachiPointsUserHistory,
    setShowAddKachiPointsForm,
    addKachiPoints,
    setIsLoading,
    forfeitUserKachiPoints
  };
};
