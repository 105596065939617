import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectPcPackageState = (state: RootState) => state.pcwUser;

const selectUserAccountProfileData = createSelector(
  selectPcPackageState,
  (state) => state.userAccountProfileData || {}
);

export const selectors = {
  selectUserAccountProfileData
};
