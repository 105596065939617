import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Branch, Listing } from 'src/types';
import * as thunks from './thunks';
import { fifoOnRecents } from './util';
import { Supplier } from 'src/types/supplier';

type State = {
  listings: Listing[];
  recentDRNos: string[];
  recentPurchaseDates: string[];
  recentSuppliers: string[];
  recentBranches: string[];
};

const initialState: State = {
  listings: [],
  recentDRNos: [],
  recentPurchaseDates: [],
  recentSuppliers: [],
  recentBranches: []
};

const slice = createSlice({
  name: 'listings',
  initialState,
  reducers: {
    addRecentDRNos: (state, action: PayloadAction<string>) => {
      fifoOnRecents(state.recentDRNos, action.payload);
    },
    addRecentPurchaseDates: (state, action: PayloadAction<string>) => {
      fifoOnRecents(state.recentPurchaseDates, action.payload);
    },
    addRecentSuppliers: (state, action: PayloadAction<Supplier>) => {
      if (action?.payload) {
        const payloadData = JSON.stringify(action?.payload);
        fifoOnRecents(state.recentSuppliers, payloadData);
      }
    },
    addRecentBranches: (state, action: PayloadAction<Branch>) => {
      if (action?.payload) {
        const payloadData = JSON.stringify(action?.payload);
        fifoOnRecents(state.recentBranches, payloadData);
      }
    }
  },
  extraReducers(builder) {
    builder.addCase(thunks.getListingsThunk.fulfilled, (state, { payload }) => {
      state.listings = payload.originalData.listings ?? [];
    });

    builder.addCase(
      thunks.getRecentSupplierThunk.fulfilled,
      (state, { payload }) => {
        let newRecentSuppliers = state.recentSuppliers;
        if (payload?.originalData?.data) {
          newRecentSuppliers = JSON.parse(payload?.originalData?.data);
        }
        state.recentSuppliers = newRecentSuppliers;
      }
    );

    builder.addCase(
      thunks.getRecentDrNoThunk.fulfilled,
      (state, { payload }) => {
        let newRecentDrNos = state.recentDRNos;
        if (payload?.originalData?.data) {
          newRecentDrNos = JSON.parse(payload?.originalData?.data);
        }
        state.recentDRNos = newRecentDrNos;
      }
    );

    builder.addCase(
      thunks.getRecentPurchaseDateThunk.fulfilled,
      (state, { payload }) => {
        let newRecentPurchasedDate = state.recentPurchaseDates;
        if (payload?.originalData?.data) {
          newRecentPurchasedDate = JSON.parse(payload?.originalData?.data);
        }
        state.recentPurchaseDates = newRecentPurchasedDate;
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
