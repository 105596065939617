import React, { FC, memo } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Menu,
  Paper,
  Typography
} from '@material-ui/core';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';

interface Props {
  isElevated?: boolean;
  label?: string;
  subLabel?: string;
  columnHeaderOptions?: string[];
  //get the name of select column in options
  selectedColumnToHide: (columnToHide: number[]) => void;
}

const component: FC<Props> = ({
  isElevated = false,
  label = 'Columns',
  subLabel = 'Columns Filter',
  columnHeaderOptions = [],
  selectedColumnToHide
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [selectedColumn, setSelectedColumn] = React.useState<string[]>([]);
  const [selectedAllColumn, setSelectedAllColumn] = React.useState<boolean>(
    false
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value: string) => {
    const updatedSelectedColumns = selectedColumn.includes(value)
      ? selectedColumn.filter((column) => column !== value)
      : [...selectedColumn, value];
    setSelectedColumn(updatedSelectedColumns);
    selectedColumnToHide(
      updatedSelectedColumns.map((col) => columnHeaderOptions.indexOf(col))
    );
  };

  const handleSelectAllColumns = () => {
    setSelectedAllColumn(!selectedAllColumn);
    if (!selectedAllColumn) {
      setSelectedColumn(columnHeaderOptions);
      selectedColumnToHide(
        columnHeaderOptions.map((col) => columnHeaderOptions.indexOf(col))
      );
    } else {
      setSelectedColumn([]);
      selectedColumnToHide([]);
    }
  };

  return (
    <Paper elevation={isElevated ? 3 : 0}>
      <FormControl variant="outlined" fullWidth>
        <Button
          aria-controls="hide-column-menu"
          aria-haspopup="true"
          onClick={handleClick}
          variant="outlined"
          fullWidth
          startIcon={<ViewWeekIcon />}
        >
          {label}
        </Button>
        <Menu
          id="hide-column-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <MenuItem disabled>
            <Typography variant="h5">{subLabel}</Typography>
          </MenuItem>
          <MenuItem onClick={() => handleSelectAllColumns()}>
            <Checkbox checked={selectedAllColumn} />
            {selectedAllColumn ? 'Unhide All Columns' : 'Hide All Columns'}
          </MenuItem>

          {columnHeaderOptions?.map((headerCol, index) => (
            <MenuItem
              key={index}
              style={{ minWidth: '350px' }}
              onClick={() => handleMenuItemClick(headerCol)}
            >
              {/* If the column is not included, this is checked */}
              <Checkbox checked={!selectedColumn.includes(headerCol)} />
              {headerCol}
            </MenuItem>
          ))}
        </Menu>
      </FormControl>
    </Paper>
  );
};

export const HideTableRowSelector = memo(component);
