import React, { FC, memo } from 'react';
import { View, Image, StyleSheet, Text } from '@react-pdf/renderer';
import { transferLogsText } from './styles';

const styles = StyleSheet.create({
  root: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between'
  },
  logo: {
    height: 'auto',
    width: 150
  },
  barcodeContainer: { display: 'flex', flexDirection: 'column' },
  barCode: {
    height: 180,
    width: 220,
    position: 'absolute',
    right: 5,
    top: 10
  }
});

interface Props {
  stBarcode?: string;
}

const component: FC<Props> = ({ stBarcode }) => {
  return (
    <View style={styles.root}>
      <View>
        <Image style={styles.logo} src="/static/new_drj_logo.png" />
      </View>
      {stBarcode && (
        <View style={styles.barCode}>
          <Text style={transferLogsText.text}>Barcode:</Text>
          <Image style={styles.barCode} src={stBarcode || ''} />
        </View>
      )}
    </View>
  );
};

export const ViewPrintCompanyLogo = memo(component);
