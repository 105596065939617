import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { Passcode } from 'src/types';

const { actions: usersActions, selectors: userSelectors } = slices.user;

export const useFormPasscodeDialog = () => {
  const dispatch = useAppDispatch();

  const validatePasscodeSuccess = useAppSelector(
    userSelectors.selectValidatePasscodeSuccess
  );

  const validatePasscodeMessage = useAppSelector(
    userSelectors.selectValidatePasscodeMessage
  );

  const validatePasscodeError = useAppSelector(
    userSelectors.selectValidatePasscodeError
  );

  const showFormPasscodeDialog = () => {
    dispatch(usersActions.showFormPasscodeDialog());
  };

  const hideFormPasscodeDialog = async () => {
    dispatch(usersActions.hideFormPasscodeDialog());
  };

  const validatePasswordRequest = (payload: Passcode) => {
    dispatch(usersActions.validatePasscode(payload));
  };

  return {
    validatePasscodeSuccess,
    validatePasscodeMessage,
    validatePasscodeError,
    showFormPasscodeDialog,
    hideFormPasscodeDialog,
    validatePasswordRequest
  };
};
