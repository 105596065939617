import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PartDetailErrField,
  ProductPartDetails,
  ProductPartDetailsCategory
} from 'src/types';
import {
  getCategoryNameViaId,
  transformToValidDataForReadUtil
} from 'src/utils';
import * as thunks from './thunks';

type ProductPartDetailsState = {
  productPartDetails: ProductPartDetails;
  errFields: PartDetailErrField[];
};

const initialState: ProductPartDetailsState = {
  productPartDetails: {},
  errFields: []
};

const slice = createSlice({
  name: 'productPartDetails',
  initialState,
  reducers: {
    updateProductPartDetailsViaKey: (
      state,
      {
        payload
      }: PayloadAction<{
        key: ProductPartDetailsCategory;
        newData: ProductPartDetails;
      }>
    ) => {
      if (payload?.key) {
        // const index = state.productPartDetailss.findIndex((x) => x.id);
        // if (index > -1) {
        //   state.productPartDetailss[index] = payload;
        // }
      }
    },
    updateProductPartDetailsPropertyViaKey: (
      state,
      {
        payload
      }: PayloadAction<{
        key: ProductPartDetailsCategory;
        property: string;
        newData: string | null | undefined | number | any;
      }>
    ) => {
      const { key, property, newData } = payload;
      if (key && property) {
        state.productPartDetails = {
          ...state.productPartDetails,
          [key]: {
            ...state.productPartDetails[key],
            [property]: newData
          }
        };
      }
    },
    resetProductPartDetails: (state) => {
      state.productPartDetails = initialState?.productPartDetails;
      state.errFields = initialState?.errFields;
    },
    setErrFields: (state, { payload }: PayloadAction<PartDetailErrField[]>) => {
      state.errFields = payload || [];
    },
    resetErrFields: (state) => {
      state.errFields = [];
    }
    // updatePriceListPriceViaIndex: (
    //   state,
    //   { payload }: PayloadAction<{ promo_price: number; index: number }>
    // ) => {
    //   state.priceList[payload.index].promo_price = payload.promo_price;
    // },
    // removeProductPartDetails: (state, { payload }: PayloadAction<number>) => {
    //   const clonedProductPartDetailss = cloneDeep(state.productPartDetailss);
    //   const index = state?.productPartDetailss?.findIndex((x) => x?.id === payload);
    //   if (index > -1) {
    //     clonedProductPartDetailss.splice(index, 1);
    //   }
    //   state.productPartDetailss = clonedProductPartDetailss;
    // }
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.getProductDetailsThunk.fulfilled,
      (state, { payload }) => {
        const productPartDetailsData = payload?.originalData?.data || {};
        const categoryName = getCategoryNameViaId(
          productPartDetailsData?.category?.id
        );

        if (categoryName) {
          const newData = transformToValidDataForReadUtil(
            categoryName,
            productPartDetailsData
          );

          state.productPartDetails = {
            ...state.productPartDetails,
            [categoryName]: newData
          };
        }
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
