import React from 'react';
import {
  makeStyles,
  TableBody,
  TableCell,
  Typography
} from '@material-ui/core';

import { formatCurrency, formatDate, getStatusColor } from 'src/utils';

import { AlternatingColorTableRow } from 'src/components';
import { AvailableInventoryItemData } from 'src/types/available-inventory';
import { multiBranchFeat } from 'src/constants/feature-toggle';

interface Props {
  inventoryItem?: AvailableInventoryItemData[];
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inventoryCard: {
    height: '100%'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 10
  },
  priceInput: {
    height: 30
  }
}));

const TableInventoryBody = ({ inventoryItem }: Props) => {
  const classes = useStyles();

  return (
    <TableBody>
      {inventoryItem?.map((item: AvailableInventoryItemData, index: number) => (
        <AlternatingColorTableRow
          className={classes.tableRow}
          onClick={() => {}}
          hover
          key={`${index}`}
        >
          <TableCell className={classes.tableRow}>
            {item?.product_name || '--'}
          </TableCell>
          <TableCell className={classes.tableRow}>{item?.serial_no}</TableCell>
          <TableCell className={classes.tableRow}>
            {item?.category_name || '--'}
          </TableCell>
          {multiBranchFeat ? (
            <TableCell className={classes.tableRow}>
              {item?.branch_name || '--'}
            </TableCell>
          ) : null}
          <TableCell className={classes.tableRow}>
            {item?.created_at || '--'}
          </TableCell>
          <TableCell className={classes.tableRow}>
            {formatCurrency(item?.dealers_price) || '--'}
          </TableCell>
          <TableCell className={classes.tableRow}>
            {formatCurrency(item?.retail_price) || '--'}
          </TableCell>
          <TableCell className={classes.tableRow}>
            <Typography style={{ color: getStatusColor(item?.status) }}>
              {item?.status || '--'}
            </Typography>
          </TableCell>
          <TableCell className={classes.tableRow}>
            {item?.purchased_date ? formatDate(item?.purchased_date) : '--'}
          </TableCell>
          <TableCell className={classes.tableRow}>
            {item?.is_purchased ? 'YES' : 'NO'}
          </TableCell>
        </AlternatingColorTableRow>
      ))}
    </TableBody>
  );
};

export const InventoryTableBody = React.memo(TableInventoryBody);
