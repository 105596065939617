import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  PcwUserAccountResponse,
  PcwUserAccountPayload,
  PcwAccountProfileResponse
} from 'src/types';

export const getPcwUserAccount = createAsyncThunk<
  CommonAxiosResponse<PcwUserAccountResponse>,
  PcwUserAccountPayload,
  { state: RootState }
>('customer/getPcwUserAccount', async (data) => {
  const response = await Api.get('/ecomm/internal/user/list/get', data);
  return response;
});

export const getPcwAccountProfileThunk = createAsyncThunk<
  CommonAxiosResponse<PcwAccountProfileResponse>,
  any,
  { state: RootState }
>('customer/getPcwAccountProfileThunk', async (data) => {
  const response = await Api.get(`/ecomm/internal/user/details/${data}`);
  return response;
});
