import { CategoriesEnum } from 'src/enums';
import { Quotation } from 'src/types';


export const componentToQuotation = (mappedProductsQuotation: Quotation[]) => {
    const quotationComponentsOrder = [
        CategoriesEnum.GPU,
        CategoriesEnum.CPU,
        CategoriesEnum.Motherboard,
        CategoriesEnum.RAM,
        CategoriesEnum.SSD,
        CategoriesEnum.HDD,
        CategoriesEnum.PSU,
        CategoriesEnum.Casing,
        CategoriesEnum.Monitor
    ];

    const orderedResult = mappedProductsQuotation.sort((a, b) => {
        if (!a || !b) {
            return 1;
        }
        if (!a?.product?.category_id || !b?.product?.category_id) {
            return 1;
        }
        if (!a?.product?.category_id || !b?.product?.category_id) {
            return 1;
        }
        if (
            quotationComponentsOrder.indexOf(a?.product?.category_id) < 0 ||
            quotationComponentsOrder.indexOf(b?.product?.category_id) < 0
        ) {
            return 1;
        }
        return (
            quotationComponentsOrder.indexOf(a?.product?.category_id) -
            quotationComponentsOrder.indexOf(b?.product?.category_id)
        );
    });

    const twoEmptyComponent = [
        {
            quantity: 1,
            product: {}
        },
        {
            quantity: 1,
            product: {},
            is_custom: true
        }
    ];

    const orderedWithEmptyRows = orderedResult.concat(twoEmptyComponent);
    return orderedWithEmptyRows;
}