import React, { useCallback, useState } from 'react';
import {
  Button,
  Collapse,
  List,
  ListItem,
  makeStyles
} from '@material-ui/core';
import { DrawerItem as DrawerItemType } from 'src/types/layout/drawer';
import clsx from 'clsx';
import { DrawerItemLabelEnum } from 'src/layouts/DashboardLayout/types';
import { useNavigate } from 'react-router';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import ArrowRightOutlinedIcon from '@material-ui/icons/ArrowRightOutlined';
import { useDrawerItemsWithPerms } from 'src/hooks/use-drawer-items-with-perms';

const { actions: appActions, selectors: appSelectors } = slices.app;

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    // fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(4)
  }
}));

export const DrawerSection = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { drawerItemsWithPermissions } = useDrawerItemsWithPerms();

  const openDrawerSectionIndicesArr = useAppSelector(
    appSelectors.selectOpenDrawerSectionIndices
  );

  const [activeDrawerItem, setActiveDrawerItem] = useState<
    DrawerItemLabelEnum
  >();

  const onClickItem = (item: DrawerItemType) => {
    setActiveDrawerItem(item.title);
    navigate(item.href);
  };

  const isSectionOpen = useCallback(
    (index: number) => {
      const isExist = openDrawerSectionIndicesArr?.findIndex(
        (x) => x === index
      );
      return isExist > -1;
    },
    [openDrawerSectionIndicesArr]
  );

  const onToggleSection = (index: number) => {
    dispatch(appActions.toggleDrawerSection(index));
  };

  return (
    <List style={{ display: 'flex', flexDirection: 'column' }}>
      {drawerItemsWithPermissions?.map((x, i) => (
        <div key={x?.sectionTitle}>
          <ListItem className={classes.item} disableGutters>
            <Button
              color="primary"
              className={classes.button}
              onClick={() => onToggleSection(i)}
            >
              <ArrowRightOutlinedIcon
                className={classes.icon}
                fontSize="small"
              />
              <span className={classes.title}>{x?.sectionTitle}</span>
            </Button>
          </ListItem>
          <Collapse in={isSectionOpen(i)} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {x?.sectionItems?.map((y) => (
                <ListItem
                  key={y?.title}
                  disableGutters
                  className={clsx(classes.item, classes.nested)}
                >
                  <Button
                    href={
                      y?.title === DrawerItemLabelEnum.Logout
                        ? undefined
                        : y?.href
                    }
                    onClick={() => onClickItem(y)}
                    className={clsx(
                      classes.button,
                      activeDrawerItem === y?.title ? classes.active : undefined
                    )}
                    color="primary"
                  >
                    {y.icon ? (
                      <y.icon className={classes.icon} fontSize="small" />
                    ) : null}
                    <span className={classes.title}>{y.title}</span>
                  </Button>
                </ListItem>
              ))}
            </List>
          </Collapse>
        </div>
      ))}
    </List>
  );
};
