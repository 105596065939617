import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Divider,
  LinearProgress,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Page } from 'src/components';
import Toolbar from './Toolbar';
import SupplierDetail from './SupplierDetail';
import { Listing } from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { isNil } from 'lodash';
import { unwrapResult } from '@reduxjs/toolkit';
import { getStatusColor } from 'src/utils';
import { Pagination } from '@material-ui/lab';

const { actions: listingActions } = slices.listing;

const useStyles = makeStyles(() => ({
  root: {}
}));

const SupplierView = () => {
  const classes = useStyles();
  const location = useLocation();
  const state: any = location.state;
  const dispatch = useAppDispatch();

  const [listings, setListings] = useState<Listing[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [lastPage, getLastPage] = useState<number>();
  const [page, setPage] = useState(1);

  const getProductsViaSupplier = async (keyword?: string, pageNum = 1) => {
    if (state?.id) {
      setIsLoading(true);
      const listingPayload = {
        supplier_id: state?.id || '',
        keyword,
        limit: 10,
        branch_id: 1,
        page: pageNum,
        status: null
      };
      const response = unwrapResult(
        await dispatch(listingActions?.getListingsThunk(listingPayload))
      );

      if (response?.success && response?.originalData?.data) {
        setIsLoading(false);
        setListings(response?.originalData?.data);
        getLastPage(response?.originalData?.meta?.last_page);
      }
    }
  };

  const onSearchProduct = (keyword: string | undefined) => {
    if (!isNil(keyword)) {
      getProductsViaSupplier(keyword);
    }
  };

  const handlePageChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
    getProductsViaSupplier('', value);
  };

  useEffect(() => {
    getProductsViaSupplier();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.id]);

  return (
    <Page className={classes.root} title={`Supplier: ${state.name}`}>
      <Container maxWidth={false}>
        <Toolbar onSearchProduct={onSearchProduct} />
        <SupplierDetail supplier={state} />
        <Box marginY={2}>
          <Card>
            <Divider />
            <PerfectScrollbar>
              <Box>
                {isLoading ? <LinearProgress /> : null}
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Serial No.</TableCell>
                      <TableCell>Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {listings.map((item: Listing) => (
                      <TableRow hover key={item.id}>
                        <TableCell>{item?.product?.name ?? '--'}</TableCell>
                        <TableCell>{item.serial_no}</TableCell>
                        <TableCell>
                          <Typography
                            style={{ color: getStatusColor(item.status) }}
                          >
                            {item.status}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Pagination
            count={lastPage}
            page={page}
            onChange={handlePageChange}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default SupplierView;
