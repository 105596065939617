import React from 'react';
import { Box, Button } from '@material-ui/core';

interface Props {
  onDeleteProduct?: () => void;
  onSaveDetail?: () => void;
}

export const ProductInfoFooter = ({
  onDeleteProduct,
  onSaveDetail
}: Props) => {
  return (
    <Box my={2} paddingX={2} display="flex" justifyContent="space-between">
      <Button onClick={onDeleteProduct} color="secondary" variant="contained">
        Delete Product
      </Button>
      <Button
        onClick={onSaveDetail}
        color="primary"
        variant="contained"
      >
        Save details
      </Button>
    </Box>
  );
};
