import React, { useCallback, useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TextField,
  InputAdornment,
  IconButton,
  colors
} from '@material-ui/core';
import { Product } from 'src/types/product';
import { CustomInputEvent, CustomKeyboardEvent, FixMeLater } from 'src/types';
import SaveIcon from '@material-ui/icons/Save';
import { cloneDeep } from 'lodash';
import { slices, useAppDispatch } from 'src/redux';
import {
  AlternatingColorTableRow,
  LinkComponent,
  ListingFreebieChip,
  OutOfStockChip
} from 'src/components';
import { usePermissions } from 'src/hooks';
import { formatCurrency } from 'src/utils';

const { actions: productActions } = slices.product;

type ProductVoidFunction = (item: Product) => void;

interface Props {
  className?: string;
  products: Product[];
  onProductPress: ProductVoidFunction;
}

const useStyles = makeStyles((theme) => ({
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 10
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  },
  retailPriceCell: {
    width: 200
  }
}));

const component = ({
  onProductPress,
  className,
  products = [],
  ...rest
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const inputRef = useRef([]);
  const permissions = usePermissions();

  const [productsState, setProductsState] = useState<Product[]>(products);

  const handleChange = useCallback(
    (event: CustomInputEvent, index: number) => {
      const clonedProducts = cloneDeep(productsState);
      clonedProducts[index].retail_price = +event.target.value;
      setProductsState(clonedProducts);
    },
    [productsState]
  );

  // NOTE: For some reason bringing this to the parent would loop the function.
  const handleSave = useCallback(
    (e: FixMeLater, item: Product) => {
      e.stopPropagation();
      dispatch(productActions.updateProductsThunk(item));
    },
    [dispatch]
  );

  const onKeyDown = useCallback(
    (e: CustomKeyboardEvent, item: Product) => {
      if (e.key === 'Enter') {
        handleSave(e, item);
      }
    },
    [handleSave]
  );

  useEffect(() => {
    setProductsState(products);
  }, [products]);

  return (
    <Card className={clsx(className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell>ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>SKU</TableCell>
                <TableCell className={classes.retailPriceCell}>
                  Retail Price
                </TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Available Item(s)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {productsState.map((item: Product, i: number) => (
                <AlternatingColorTableRow hover key={item.id}>
                  <TableCell className={classes.tableRow}>
                    {item.id ?? '--'}
                  </TableCell>
                  <TableCell className={classes.tableRow}>
                    <LinkComponent
                      onClick={() => onProductPress(item)}
                      href={`/app/products/${item.id}`}
                      title={`${item.name ?? '--'}`}
                    />
                    {!item?.count ? <OutOfStockChip /> : null}
                    {item?.has_freebie ? (
                      <ListingFreebieChip
                        chipStyle={{ marginLeft: 10 }}
                        title="has freebie"
                      />
                    ) : null}
                  </TableCell>
                  <TableCell className={classes.tableRow}>
                    {item.sku ?? '--'}
                  </TableCell>
                  <TableCell
                    className={classes.tableRow}
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {permissions?.canEditPriceListPrice ? (
                      <TextField
                        ref={inputRef.current[i]}
                        fullWidth
                        className={classes.priceInput}
                        onFocus={(e) => {
                          e.stopPropagation();
                        }}
                        onKeyDown={(e) => onKeyDown(e, item)}
                        type="number"
                        name="retail_price"
                        onChange={(e) => handleChange(e, i)}
                        value={item?.retail_price ?? ''}
                        InputProps={{
                          className: classes.priceInput,
                          endAdornment: (
                            <IconButton onClick={(e) => handleSave(e, item)}>
                              <SaveIcon />
                            </IconButton>
                          ),
                          startAdornment: (
                            <InputAdornment position="start">₱</InputAdornment>
                          )
                        }}
                      />
                    ) : (
                      formatCurrency(item.retail_price)
                    )}
                  </TableCell>

                  <TableCell className={classes.tableRow}>
                    {item.category ?? '--'}
                  </TableCell>
                  <TableCell className={classes.tableRow}>
                    {item.count}
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const Products = React.memo(component);
