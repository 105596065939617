import LinearProgress from '@material-ui/core/LinearProgress';
import React from 'react';

interface Props {
  isLoading: boolean;
}

export const component = ({ isLoading }: Props) =>
  isLoading ? <LinearProgress /> : null;

export const LoaderBar = React.memo(component);
