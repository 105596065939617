import React from 'react';
import { Card, Grid, makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  customerInfoBox: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
}));

export const CustomerInfo = () => {
  const classes = useStyles();

  return (
    <Card className={classes.customerInfoBox}>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Sold To:
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Date Sold:
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Contact No:
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Terms:
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export const QuotationCustomerInfo = React.memo(CustomerInfo);
