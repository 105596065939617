import { makeStyles } from '@material-ui/core';
import React from 'react';

interface LogoProps {
  size?: number
}

const useStyles = makeStyles(() => ({
  avatar: {
    cursor: 'pointer',
    width: undefined,
    // height: 40
  }
}));

export const Logo = ({ size = 40, ...props}: LogoProps) => {
  const classes = useStyles();
  return (
    <img
      alt="PC Worth"
      className={classes.avatar}
      src="/static/images/avatars/pcworth_logo.png"
      height={size}
      {...props}
    />
  );
};
