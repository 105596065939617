import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Container,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { LoaderBar, LoadingButton, Page } from 'src/components';
import { LocalPostOffice } from '@material-ui/icons';
import { usePermissions } from 'src/hooks';
import { slices, useAppDispatch } from 'src/redux';

const { actions: smsActions } = slices.sms;

const CHARACTER_LIMIT = 255;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '91vh',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const component = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const [message, setMessage] = useState('');
  const [fetching, setFetching] = useState(false);
  const { canSMSBlast } = usePermissions();

  const handleSend = async () => {
    setFetching(true);
    await dispatch(smsActions.sendSMSBlastThunk({ content: message }));
    setFetching(false);
  };

  return (
    <Page className={classes.root} title="SMS Blast">
      <Container>
        <Typography color="textPrimary" gutterBottom variant="h2">
          SMS Blast
        </Typography>
        <Card>
          <LoaderBar isLoading={fetching} />
          <CardContent>
            <TextField
              fullWidth
              multiline
              label="Enter Message"
              minRows={4}
              variant="outlined"
              onChange={(e) => setMessage(e.target.value)}
              helperText={`${message.length}/${CHARACTER_LIMIT}`}
              inputProps={{ maxLength: CHARACTER_LIMIT }}
            />
            <Box display="flex" justifyContent="flex-end">
              <LoadingButton
                loading={fetching}
                title="send"
                onClick={handleSend}
                startIcon={<LocalPostOffice />}
                disabled={!canSMSBlast}
              />
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export const SMSBlast = React.memo(component);
