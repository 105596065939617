import React, { FC, Fragment, memo } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { capitalize } from 'lodash';
import { ProductListingHistoryData } from 'src/types';
import { formatCurrency, formatDate, getStatusColor } from 'src/utils';
import { listingPriceTypeOptions } from 'src/constants';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(() => ({
  flexBox: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  tableRow: {
    width: '100px',
    background: 'red'
  }
}));

interface Props {
  isOpen: boolean;
  expandFirstCard?: boolean; //intended feature
  serialNo?: string;
  dialogViews?: boolean;
  productListingHistory?: ProductListingHistoryData[];
  isLoadingData?: boolean;
  handleClose: () => void;
}

const component: FC<Props> = ({
  isOpen,
  serialNo,
  expandFirstCard = false,
  dialogViews = true,
  productListingHistory,
  isLoadingData,
  handleClose
}) => {
  const classes = useStyles();

  const getPriceType = (priceTypeId?: number) => {
    const priceTypeName = listingPriceTypeOptions.find(
      (price) => price.value === priceTypeId
    );
    return priceTypeName?.name || '--';
  };

  const RenderUpdateHistoryList = () => {
    return (
      <Fragment>
        <Paper style={{ width: '100%', padding: 0 }} elevation={0}>
          {isLoadingData ? (
            <LinearProgress />
          ) : (
            productListingHistory?.map((item, index) => (
              <Accordion
                defaultExpanded={(index === 0 && expandFirstCard) || false}
                key={index}
                style={{ marginBottom: '0.5rem' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Box
                    className={classes.flexBox}
                    sx={{
                      justifyContent: 'flexStart',
                      flexDirection: 'column'
                    }}
                  >
                    <Typography>{`${capitalize(item?.event)} by ${
                      item?.performed_by
                    }`}</Typography>
                    <Typography variant="subtitle1">{`${item?.updated_at}`}</Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <TableContainer component={Paper}>
                    <Table aria-label="update-changes">
                      <TableHead>
                        <TableRow>
                          {item?.event === 'updated' && (
                            <TableCell style={{ width: '50%' }}>
                              Previous Changes
                            </TableCell>
                          )}
                          <TableCell style={{ width: '50%' }}>
                            New Changes
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {item?.old_values?.category_name && (
                            <TableCell>{`From Category of: ${item?.old_values?.category_name} `}</TableCell>
                          )}
                          {item?.new_values?.category_name && (
                            <TableCell>{`To Category of: ${item?.new_values?.category_name} `}</TableCell>
                          )}
                        </TableRow>

                        <TableRow>
                          {item?.old_values?.serial_no && (
                            <TableCell>{`From Serial No: ${item?.old_values?.serial_no}`}</TableCell>
                          )}
                          {item?.new_values?.serial_no && (
                            <TableCell>{`To Serial No: ${item?.new_values?.serial_no}`}</TableCell>
                          )}
                        </TableRow>

                        <TableRow>
                          {item?.old_values?.status && (
                            <TableCell>
                              {`From status:`}
                              <Typography
                                style={{
                                  color: getStatusColor(
                                    item?.old_values?.status
                                  )
                                }}
                              >
                                {item?.old_values?.status}
                              </Typography>
                            </TableCell>
                          )}
                          {item?.new_values?.status && (
                            <TableCell>
                              {`To status:`}
                              <Typography
                                style={{
                                  color: getStatusColor(
                                    item?.new_values?.status
                                  )
                                }}
                              >
                                {item?.new_values?.status}
                              </Typography>
                            </TableCell>
                          )}
                        </TableRow>

                        <TableRow>
                          {item?.old_values?.price_type && (
                            <TableCell>{`From Pricing: ${getPriceType(
                              item?.old_values?.price_type
                            )}`}</TableCell>
                          )}
                          {item?.new_values?.price_type && (
                            <TableCell>{`To Pricing: ${getPriceType(
                              item?.new_values?.price_type
                            )}`}</TableCell>
                          )}
                        </TableRow>

                        <TableRow>
                          {item?.old_values?.dealers_price !== undefined && (
                            <TableCell>{`From Dealer's Price: ${formatCurrency(
                              item?.old_values?.dealers_price
                            )}`}</TableCell>
                          )}

                          {item?.new_values?.dealers_price !== undefined && (
                            <TableCell>{`To Dealer's Price: ${formatCurrency(
                              item?.new_values?.dealers_price
                            )}`}</TableCell>
                          )}
                        </TableRow>

                        <TableRow>
                          {item?.old_values?.retail_price !== undefined && (
                            <TableCell>{`From Retail Price: ${formatCurrency(
                              item?.old_values?.retail_price
                            )}`}</TableCell>
                          )}
                          {item?.new_values?.retail_price !== undefined && (
                            <TableCell>{`To Retail Price: ${formatCurrency(
                              item?.new_values?.retail_price
                            )}`}</TableCell>
                          )}
                        </TableRow>

                        <TableRow>
                          {item?.old_values?.supplier_name && (
                            <TableCell>{`From Supplier of: ${item?.old_values?.supplier_name} `}</TableCell>
                          )}
                          {item?.new_values?.supplier_name && (
                            <TableCell>{`To Supplier of: ${item?.new_values?.supplier_name} `}</TableCell>
                          )}
                        </TableRow>

                        <TableRow>
                          {item?.old_values?.branch_name && (
                            <TableCell>
                              {`From branch: ${item?.old_values?.branch_name}`}
                            </TableCell>
                          )}
                          {item?.new_values?.branch_name && (
                            <TableCell>
                              {`To branch: ${item?.new_values?.branch_name}`}
                            </TableCell>
                          )}
                        </TableRow>

                        <TableRow>
                          {item?.old_values?.warranty_duration && (
                            <TableCell>{`From Warranty Duration of: ${item?.old_values?.warranty_duration} Days`}</TableCell>
                          )}
                          {item?.new_values?.warranty_duration && (
                            <TableCell>{`To Warranty Duration of: ${item?.new_values?.warranty_duration} Days`}</TableCell>
                          )}
                        </TableRow>

                        <TableRow>
                          {item?.old_values?.purchase_date && (
                            <TableCell>{`From Purchased Date: ${formatDate(
                              item?.old_values?.purchase_date
                            )}`}</TableCell>
                          )}
                          {item?.new_values?.purchase_date && (
                            <TableCell>{`To Purchased Date: ${formatDate(
                              item?.new_values?.purchase_date
                            )}`}</TableCell>
                          )}
                        </TableRow>

                        <TableRow>
                          {item?.old_values?.dr_no && (
                            <TableCell>{`From Dr No: ${item?.old_values?.dr_no}`}</TableCell>
                          )}
                          {item?.new_values?.dr_no && (
                            <TableCell>{`To Dr No: ${item?.new_values?.dr_no}`}</TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </AccordionDetails>
              </Accordion>
            ))
          )}
          {productListingHistory &&
            productListingHistory?.length <= 0 &&
            !isLoadingData && (
              <Typography>No Update/Edit History for this product</Typography>
            )}
        </Paper>
      </Fragment>
    );
  };

  return (
    <Fragment>
      {dialogViews ? (
        <Dialog
          onClose={handleClose}
          aria-labelledby="update-listing-history"
          open={isOpen}
          fullScreen
        >
          <DialogTitle
            id="update-history-title"
            style={{
              paddingBottom: '0.5rem',
              boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
              zIndex: 1,
              marginBottom: '0.5rem'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography style={{ fontSize: '18px', fontWeight: 700 }}>
                {`Product Update/Edit History Details for Serial Number: ${serialNo}`}
              </Typography>
              <IconButton color="secondary" onClick={handleClose}>
                <CloseIcon style={{ fontSize: '30px' }} />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <RenderUpdateHistoryList />
          </DialogContent>
        </Dialog>
      ) : (
        <RenderUpdateHistoryList />
      )}
    </Fragment>
  );
};

export const ProductUpdateHistory = memo(component);
