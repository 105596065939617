import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, DeleteGalleryResponse } from 'src/types';

export const galleryDeleteThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteGalleryResponse>,
  number,
  { state: RootState }
>('gallery/galleryDeleteThunk', async (id) => {
  const response = await Api.delete(`/gallery/delete/${id}`);
  return response;
});
