import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import { unwrapResult } from '@reduxjs/toolkit';

import { Page } from 'src/components';
import { CustomKeyboardEvent } from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { useSnackBar } from 'src/hooks';

const { actions: supplierActions } = slices.supplier;

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddSupplierView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [supplierName, setSupplierName] = useState('');

  const onClearNameField = () => {
    setSupplierName('');
  };

  const onCreateSupplier = async () => {
    if (supplierName) {
      const response = unwrapResult(
        await dispatch(
          supplierActions.createSupplierThunk({ name: supplierName })
        )
      );
      if (response.success) {
        snackBar.show({ severity: 'success', message: response.message });
        onClearNameField();
      } else {
        snackBar.show({ severity: 'error', message: response.message });
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.'
      });
    }
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      setSupplierName('');
    }
  };

  return (
    <Page className={classes.root} title="New supplier">
      <Container maxWidth={false}>
        <Box mt={2}>
          <Card>
            <CardHeader
              title="New Supplier"
              subheader="The information of new supplier."
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={supplierName}
                    required
                    onKeyPress={(e) => onKeyPress(e)}
                    onChange={(e) => setSupplierName(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="space-between" p={2}>
              <Button
                startIcon={<ClearIcon />}
                onClick={onClearNameField}
                color="secondary"
                variant="contained"
              >
                Clear
              </Button>
              <Button
                startIcon={<SaveIcon />}
                style={{ marginLeft: 18 }}
                onClick={onCreateSupplier}
                color="primary"
                variant="contained"
              >
                Add new supplier
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default AddSupplierView;
