/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import { RandomReveal } from 'react-random-reveal';
import { slices, useAppSelector } from 'src/redux';
import Lottie from 'lottie-react';
import confettiAnimation from './confetti.json';

const { selectors: raffleSelectors } = slices.raffle;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  congrats: {
    marginTop: '10px',
    fontSize: '40px',
    height: '50px',
    color: 'white',
    textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)'
  },
  lottieContainer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%'
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    background: `url("/static/images/luckybossingdrawv2.png") center center no-repeat`,
    backgroundSize: '100% 100%, cover',
    position: 'relative',
    width: '550px',
    height: '200px',
    marginTop: '0'
  },
  nameText: {
    fontSize: '36px',
    color: 'white',
    marginTop: '50px',
    textAlign: 'center'
  },
  leftLottie: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '50%',
    height: '100%'
  },
  rightLottie: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '50%',
    height: '100%',
    transform: 'scaleX(-1)'
  }
}));

export const RedSquare = () => {
  const classes = useStyles();
  const raffleWinner = useAppSelector(raffleSelectors.selectRaffleWinner);

  const animationDuration = 10;
  const [countdown, setCountdown] = useState(animationDuration);

  useEffect(() => {
    if (raffleWinner) {
      let timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  }, [raffleWinner]);

  useEffect(() => {
    setCountdown(animationDuration);
  }, [raffleWinner]);

  return (
    <div className={classes.root}>
      <div className={classes.nameContainer}>
        <Typography variant="h1" className={classes.nameText}>
          {raffleWinner ? (
            <RandomReveal
              isPlaying
              duration={animationDuration} // NOTE: 10 yung actual. for testing lang kaya 2
              revealDuration={0.4}
              characters={`${raffleWinner?.full_name}`}
            />
          ) : (
            <span>GOODLUCK MGA BOSSING!</span>
          )}
        </Typography>
      </div>
      <Typography variant="h1" className={classes.congrats}>
        {raffleWinner && countdown <= 0 ? (
          <>
            CONGRATULATIONS!
            <div className={classes.lottieContainer}>
              <Lottie
                animationData={confettiAnimation}
                loop={true}
                className={classes.leftLottie}
              />
              <Lottie
                animationData={confettiAnimation}
                loop={true}
                style={{ transform: 'scaleX(-1)' }}
                className={classes.rightLottie}
              />
            </div>
          </>
        ) : (
          ' '
        )}
      </Typography>
    </div>
  );
};
