import { Chip } from '@material-ui/core';
import React from 'react';

interface Props {
  className?: string;
  label?: string;
  isSelected: boolean;
  onClick: () => void;
}

export const SelectionChip = ({
  className,
  label = 'label',
  isSelected = false,
  onClick
}: Props) => {
  return (
    <Chip
      className={className}
      label={label}
      color="primary"
      variant={isSelected ? undefined : 'outlined'}
      onClick={onClick}
    />
  );
};
