export const convertImageFileToBase64 = (file?: File) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      return reject;
    }
    var fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.onerror = reject;
    fr.readAsDataURL(file)
  });
};

/**
 * Checks if base64 file is jpeg or jpg
 * @returns boolean
 */
// eslint-disable-next-line no-unused-vars
export const isImageStrJpgJpeg = (str: string) => {
  // TODO: Add logic
  return true;
};
