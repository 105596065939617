import { PriceListItemStatusEnum } from 'src/enums';
import { PriceList } from 'src/types';

export const mockPriceListProducts: PriceList[] = [
  {
    id: 1,
    category_id: 21,
    category_name: 'GPU',
    name: 'GEFORCE RTX 3080 ZOTAC TRINITY OC WHITE 10GB TRIPLE FAN RGB LHR',
    promo_price: 65950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 2,
    category_id: 21,
    category_name: 'GPU',
    name: 'GEFORCE RTX 3080 MSI GAMING Z TRIO 10GB TRIPLE FAN RGB LHR',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 3,
    category_id: 21,
    category_name: 'GPU',
    name: 'GEFORCE RTX 3070TI PALIT GAMING PRO 8GB TRIPLE FAN RGB LHR',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 4,
    category_id: 21,
    category_name: 'GPU',
    name: 'GEFORCE RTX 3070TI GIGABYTE GAMING OC 8GB TRIPLE FAN RGB LHR',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 5,
    category_id: 21,
    category_name: 'GPU',
    name: 'GEFORCE RTX 3060TI PALIT 8GB DUAL FAN RGB LHR',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 6,
    category_id: 21,
    category_name: 'GPU',
    name: 'GEFORCE RTX 3070 PALIT GAMING PRO OC 8GB TRIPLE FAN RGB LHR',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 7,
    category_id: 21,
    category_name: 'GPU',
    name: 'GEFORCE GT 1030 GIGABYTE OC 2GB SINGLE FAN',
    promo_price: 54950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 8,
    category_id: 21,
    category_name: 'GPU',
    name: 'GEFORCE GT 1030 GIGABYTE OC 2GB SINGLE FAN',
    promo_price: 54950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.Upcoming
  },

  {
    id: 8,
    category_id: 16,
    category_name: 'CPU',
    name: 'AMD RYZEN 7 5700G',
    promo_price: 65950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 9,
    category_id: 16,
    category_name: 'CPU',
    name: 'AMD RYZEN 5 5600G',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 10,
    category_id: 16,
    category_name: 'CPU',
    name: 'AMD RYZEN 7 5800X',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 11,
    category_id: 16,
    category_name: 'CPU',
    name: 'AMD RYZEN 5 5600X',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 12,
    category_id: 16,
    category_name: 'CPU',
    name: 'INTEL CORE I9 12900K',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 13,
    category_id: 16,
    category_name: 'CPU',
    name: 'INTEL CORE I7 12700KF',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 13,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 13,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 13,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 13,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 13,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 13,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 14,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 14,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 14,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 14,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 14,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 14,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 10,
    category_id: 15,
    category_name: 'CPU',
    name: 'AMD RYZEN 7 5800X',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 11,
    category_id: 15,
    category_name: 'CPU',
    name: 'AMD RYZEN 5 5600X',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 12,
    category_id: 15,
    category_name: 'CPU',
    name: 'INTEL CORE I9 12900K',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 13,
    category_id: 15,
    category_name: 'CPU',
    name: 'INTEL CORE I7 12700KF',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 15,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 15,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 15,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 15,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 15,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 15,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 15,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 15,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 15,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 15,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 15,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 15,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 17,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 17,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 17,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 17,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 17,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 17,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 17,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 17,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 17,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 10,
    category_id: 17,
    category_name: 'CPU',
    name: 'AMD RYZEN 7 5800X',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 11,
    category_id: 17,
    category_name: 'CPU',
    name: 'AMD RYZEN 5 5600X',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 12,
    category_id: 17,
    category_name: 'CPU',
    name: 'INTEL CORE I9 12900K',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 13,
    category_id: 17,
    category_name: 'CPU',
    name: 'INTEL CORE I7 12700KF',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 17,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 17,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 17,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 17,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 17,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 17,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 17,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 17,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 17,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 17,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 17,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 17,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 14,
    category_id: 21,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 21,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 21,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 21,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 21,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 21,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 10,
    category_id: 21,
    category_name: 'CPU',
    name: 'AMD RYZEN 7 5800X',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 11,
    category_id: 21,
    category_name: 'CPU',
    name: 'AMD RYZEN 5 5600X',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 12,
    category_id: 21,
    category_name: 'CPU',
    name: 'INTEL CORE I9 12900K',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 13,
    category_id: 21,
    category_name: 'CPU',
    name: 'INTEL CORE I7 12700KF',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 21,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 21,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 21,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 21,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 21,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 21,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 21,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 21,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 21,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 21,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 21,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 21,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 15,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 23,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 23,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 23,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 23,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 23,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 23,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 14,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 23,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 23,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 23,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 10,
    category_id: 23,
    category_name: 'CPU',
    name: 'AMD RYZEN 7 5800X',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 11,
    category_id: 23,
    category_name: 'CPU',
    name: 'AMD RYZEN 5 5600X',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 12,
    category_id: 23,
    category_name: 'CPU',
    name: 'INTEL CORE I9 12900K',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 13,
    category_id: 23,
    category_name: 'CPU',
    name: 'INTEL CORE I7 12700KF',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 23,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 23,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 23,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  },

  {
    id: 14,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB RS 8X2=16GB 3200MHZ DDR4',
    promo_price: 7098,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.LowStock
  },
  {
    id: 15,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE RGB PRO SL 8X2=16GB 3200MHZ WHITE DDR4',
    promo_price: 75950,
    standard_price: 69248,
    quantity: 0,
    status: PriceListItemStatusEnum.SoldOut
  },
  {
    id: 16,
    category_id: 23,
    category_name: 'RAM',
    name: 'CORSAIR VENGEANCE LPX 8GB 3200MHZ BLACK DDR4',
    promo_price: 67950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 17,
    category_id: 23,
    category_name: 'RAM',
    name: 'G-SKILL TRIDENT Z RGB 16X2= 32GB 3600MHZ DDR4',
    promo_price: 64950,
    standard_price: 69248,
    quantity: 1,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 18,
    category_id: 23,
    category_name: 'RAM',
    name: 'TEAM GROUP VULCAN TUF 8X2=16GB 3200MHZ DDR4',
    promo_price: 45950,
    standard_price: 69248,
    quantity: 5,
    status: PriceListItemStatusEnum.InStock
  },
  {
    id: 19,
    category_id: 23,
    category_name: 'RAM',
    name: 'APACER NOX RGB AURA II 8X2=16GB 3200MHZ DDR4',
    promo_price: 76950,
    standard_price: 69248,
    quantity: 2,
    status: PriceListItemStatusEnum.InStock
  }
];

