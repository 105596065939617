import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    opacity: 0.035,
    top: 0,
    position: 'absolute',
    height: '90vh',
    width: '70vw',
    backgroundSize: '100px 90px',
    backgroundRepeat: 'repeat',
    backgroundImage: `url(${'/static/images/avatars/pcworth_logo.png'})`,
  }
}));

export const BackgroundLogo = () => {
  const classes = useStyles();
  return <div className={classes.root} />;
};

export const QuotationBackgroundLogo = React.memo(BackgroundLogo);
