import React from 'react';
import {
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography,
  InputAdornment
} from '@material-ui/core';

// TODO: add proper types.
interface Props {
  dealersPrice: string;
  setDealersPrice: (v: string) => void;
  retailPrice: string;
  setRetailPrice: (v: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  }
}));

const component = ({
  dealersPrice,
  setDealersPrice,
  retailPrice,
  setRetailPrice
}: Props) => {
  const classes = useStyles();

  return (
    <CardContent>
      <Typography className={classes.subHeader} variant="h6">
        Pricing
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            helperText="If product is consumable, this price should be per piece/meter/unit"
            label="Dealers Price"
            name="dealers_price"
            required
            type="number"
            variant="outlined"
            value={dealersPrice}
            onChange={(e) => setDealersPrice(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₱</InputAdornment>
              )
            }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Retail Price"
            name="retail_price"
            required
            helperText="If product is consumable, this price should be per piece/meter/unit"
            type="number"
            variant="outlined"
            value={retailPrice}
            onChange={(e) => setRetailPrice(e.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">₱</InputAdornment>
              )
            }}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export const AddProductPricingInfo = React.memo(component);
