import { colors as MUIColors } from '@material-ui/core';
import { appInformation } from './app';

export const colors = {
  primary: appInformation?.store_branch === 'alabang' ? '#0c4a1b' : '#0C304A',
  warning: 'orange',
  error: '#f44336',
  success: 'green',
  skyBlue: '#87ceeb',
  normal: '#263238',
  black: '#000000',
  link: MUIColors.blue[700],
  ...MUIColors
};
