import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  SvgIcon,
  TextField,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CustomInputEvent, CustomKeyboardEvent } from 'src/types';

const useStyle = makeStyles({
  flexBoxStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row'
  },
  flexBoxEnd: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    flexDirection: 'row'
  }
});

interface Props {
  isCheckedUser?: boolean;
  onHandleRemoveUser?: () => void;
  onHandleUnCheck?: () => void;
  onSearchUser: (keywordParam?: string) => void;
  onToggleOpenFormUserList: () => void;
}

const component = ({
  isCheckedUser,
  onHandleUnCheck,
  onSearchUser,
  onHandleRemoveUser,
  onToggleOpenFormUserList
}: Props) => {
  const classes = useStyle();
  const [searchInput, setSearchInput] = useState<string>('');

  const handleChange = (event: CustomInputEvent) => {
    setSearchInput(event.target.value);
  };

  const onSearchClick = () => {
    onSearchUser(searchInput);
  };

  const onClickShowAddUserForm = () => {
    onToggleOpenFormUserList();
    onSearchUser('');
    setSearchInput('');
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchUser(searchInput);
    }
  };

  return (
    <Paper style={{ marginBottom: '1rem' }}>
      <Grid container>
        <Grid item md={4}>
          <Box className={classes.flexBoxStart} p={2}>
            <TextField
              fullWidth
              value={searchInput}
              onChange={handleChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Search User"
              variant="outlined"
              onKeyPress={onKeyPress}
              style={{ marginRight: '0.5rem' }}
            />
            <Button variant="contained" color="primary" onClick={onSearchClick}>
              Search
            </Button>
          </Box>
        </Grid>
        <Grid item md={8}>
          <Box className={classes.flexBoxEnd} p={3}>
            {isCheckedUser ? (
              <div>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={onHandleRemoveUser}
                  style={{ marginRight: '5px' }}
                >
                  Remove User(s)
                </Button>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={onHandleUnCheck}
                  style={{ marginRight: '5px' }}
                >
                  Uncheck All
                </Button>
              </div>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={onClickShowAddUserForm}
                style={{ marginRight: '5px' }}
              >
                Assign New User
              </Button>
            )}
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

export const UserListToolbar = React.memo(component);
