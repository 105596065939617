import { intersection } from 'lodash';
import some from 'lodash/some';
import { useCallback, useMemo } from 'react';
import { slices, useAppSelector } from 'src/redux';
import { Role } from 'src/types';

const { selectors: userSelector } = slices.user;

export const useRoles = () => {
  const allRoles = useAppSelector(userSelector.selectRoles);

  const isOwner = useMemo(() => some(allRoles, ['name', 'owner']), [allRoles]);

  const isSuperAdmin = useMemo(() => some(allRoles, ['name', 'super-admin']), [
    allRoles
  ]);

  const isSupervisor = useMemo(() => some(allRoles, ['name', 'supervisor']), [
    allRoles
  ]);

  const isEmployee = useMemo(() => some(allRoles, ['name', 'employee']), [
    allRoles
  ]);

  const isPurchaser = useMemo(() => some(allRoles, ['name', 'purchaser']), [
    allRoles
  ]);

  const hasRoles = useCallback(
    (...userRoles: Role[]): boolean => {
      return intersection(userRoles, allRoles).length > 0;
    },
    [allRoles]
  );

  const hasAllRoles = useCallback(
    (...userRoles: Role[]): boolean => {
      return intersection(userRoles, allRoles).length === allRoles?.length;
    },
    [allRoles]
  );

  return {
    isOwner,
    isSuperAdmin,
    isEmployee,
    isPurchaser,
    isSupervisor,
    hasRoles,
    hasAllRoles
  };
};
