import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField
} from '@material-ui/core';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';

const {
  actions: smsActions,
  selectors: smsSelectors,
  constants: smsConstants
} = slices.sms;

export const SMSContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const messageLength = useAppSelector(smsSelectors.selectMessageLength);
  const message = useAppSelector(smsSelectors.selectMessage);

  return (
    <Card>
      <CardHeader title="SMS Message Content" />
      <Divider />
      <CardContent>
        <Grid container>
          <Grid item xs={12} style={{ marginTop: '10px' }}>
            <TextField
              fullWidth
              required
              multiline
              label="Enter Message"
              minRows={4}
              variant="outlined"
              onChange={(e) =>
                dispatch(smsActions.onChangeMessage(e.target.value))
              }
              helperText={
                message
                  ? `${messageLength}/${smsConstants.CHARACTER_LIMIT}`
                  : 'Message Content is Required'
              }
              error={!message}
              inputProps={{ maxLength: smsConstants.CHARACTER_LIMIT }}
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
