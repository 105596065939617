import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FlashDeal, FlashDealsGetRequest, PaginationV2 } from 'src/types';
import * as thunks from './thunks';

type FlashDealsState = {
  flashDealsList: FlashDeal[];
  isListLoading: boolean;
  getFlashDealsListRequest: FlashDealsGetRequest;
  flashDealsDetails: FlashDeal;
  flashDealsPaginationMeta: PaginationV2;
};

const initialState: FlashDealsState = {
  flashDealsList: [],
  isListLoading: false,
  getFlashDealsListRequest: {},
  flashDealsDetails: {},
  flashDealsPaginationMeta: {}
};

const slice = createSlice({
  name: 'flashDeals',
  initialState,
  reducers: {
    updateFlashDealsKeywordRequest: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.getFlashDealsListRequest = {
        ...state.getFlashDealsListRequest,
        page: 1,
        keyword: payload || ''
      };
    },
    updateFlashDealsRequest: (
      state,
      { payload }: PayloadAction<FlashDealsGetRequest>
    ) => {
      state.getFlashDealsListRequest = {
        ...state.getFlashDealsListRequest,
        ...payload
      };
    }
  },
  extraReducers(builder) {
    builder.addCase(thunks.getFlashDealsListThunk.pending, (state) => {
      state.isListLoading = true;
    });
    builder.addCase(thunks.getFlashDealsListThunk.rejected, (state) => {
      state.isListLoading = false;
    });
    builder.addCase(
      thunks.getFlashDealsListThunk.fulfilled,
      (state, { payload }) => {
        state.flashDealsList = payload?.originalData?.data?.data || [];
        state.flashDealsPaginationMeta = payload?.originalData?.data || {};
        state.isListLoading = false;
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
