import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { slices, useAppDispatch } from 'src/redux';
import {
  GetOutOfStocksProdPayload,
  OutOfStockProducts
} from 'src/redux/slices/out-of-stocks-product/types';
import { PaginationV2 } from 'src/types';

const { actions: recentOutOfStocksAction } = slices.recentOutOfStocks;

export const useRecentOutOfStocks = () => {
  const dispatch = useAppDispatch();
  const [recentOutOfStockProduct, setRecentOutOfStockProduct] = useState<
    OutOfStockProducts[]
  >([]);
  const [metaOutOfStockProd, setMetaOutOfStockProd] = useState<PaginationV2>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getRecentOutOfStockProductList = useCallback(
    async (requestParams?: GetOutOfStocksProdPayload) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(
            recentOutOfStocksAction?.getRecentOutOfStockProducts(
              { limit: 30, ...requestParams } || {}
            )
          )
        );
        if (response?.success) {
          setIsLoading(false);
          const { data, meta } = response?.originalData;
          setRecentOutOfStockProduct(data || []);
          if (meta) {
            setMetaOutOfStockProd(meta);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  return {
    recentOutOfStockProduct,
    metaOutOfStockProd,
    isLoading,
    getRecentOutOfStockProductList
  };
};
