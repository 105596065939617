import React, { useState } from 'react';
import clsx from 'clsx';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  LinearProgress,
  makeStyles
} from '@material-ui/core';
import { LoaderBar, LoadingButton, Page } from 'src/components';
import { slices, useAppDispatch } from 'src/redux';
import { useSnackBar } from 'src/hooks';

import { CustomInputEvent, Gallery } from 'src/types';
import {
  GalleryCoverImageCardContent,
  GalleryGeneralInfoCardContent,
  GalleryImagesCardContent,
  GalleryProductsCardContent
} from '../component';
import {
  chunkArrayInGroups,
  forEachPromise,
  galleryDetailsToCreateTransformer,
  galleryPhotosArrToStrArr
} from 'src/utils';
import { unwrapResult } from '@reduxjs/toolkit';
import { isGalleryCreateParamsValid } from 'src/utils/gallery/galleryParamsValidation';
import { useNavigate } from 'react-router-dom';
import usePrompt from 'src/utils/navigation-prompt';
import { CategoriesEnum } from 'src/enums';
import { isEmpty } from 'lodash';

const { actions: galleryActions } = slices.gallery;

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  createBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 60
  }
}));

const component = ({ className, ...rest }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const snackBar = useSnackBar();
  const navigate = useNavigate();

  const [galleryDetails, setGalleryDetails] = useState<Gallery>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [imgUploadProgress, setImgUploadProgress] = useState<number>(0);

  const navigateAwayToGalleryDetails = (gallery_id: number) => {
    navigate(`/app/gallery/view/${gallery_id}`, { replace: true });
  };

  const updateUploadProgress = (newNum: number = 0) => {
    setImgUploadProgress(newNum);
  };

  const uploadPhotos = async (gallery_id: number) => {
    setIsImageUploading(true);
    const photos = galleryDetails?.photos || [];
    const photosStrArr: any[] = galleryPhotosArrToStrArr(photos);

    if (photosStrArr && photosStrArr?.length > 0) {
      const groupedByTwo = chunkArrayInGroups(photosStrArr, 2);
      const groupLength = groupedByTwo?.length;
      forEachPromise(groupedByTwo, (item, uploadedIndex) => {
        return new Promise((resolve) => {
          process.nextTick(() => {
            dispatch(
              galleryActions.galleryPhotosCreateThunk({
                gallery_id,
                photos: item
              })
            )
              .then(() => {
                resolve(true);
                updateUploadProgress(((uploadedIndex + 1) / groupLength) * 100);
              })
              .catch(() => {
                resolve(true);
                updateUploadProgress(((uploadedIndex + 1) / groupLength) * 100);
              });
          });
        });
      }).then(() => {
        setIsImageUploading(false);
        navigateAwayToGalleryDetails(gallery_id);
      });
    }
  };

  const onCreateGallery = async () => {
    const transformedData = galleryDetailsToCreateTransformer(
      galleryDetails || {}
    );
    const errMsg = isGalleryCreateParamsValid(transformedData);
    if (errMsg) {
      snackBar.show({
        severity: 'error',
        message: errMsg || 'Somethings Wrong'
      });
      return;
    }
    setLoading(true);
    const response = unwrapResult(
      await dispatch(
        galleryActions.galleryCreateThunk(transformedData)
      ).finally(() => setLoading(false))
    );
    if (response.success) {
      snackBar.show({
        severity: 'success',
        message: 'Gallery created successfuly.. Uploading photos now...'
      });
      if (transformedData?.photos && transformedData?.photos?.length > 0) {
        if (response?.originalData?.data?.gallery_id) {
          const newGalleryId = response?.originalData?.data?.gallery_id;
          uploadPhotos(newGalleryId);
          setGalleryDetails(undefined);
        }
      } else if (response?.originalData?.data?.gallery_id) {
        setGalleryDetails(undefined);
        navigateAwayToGalleryDetails(response?.originalData?.data?.gallery_id);
      }
    } else {
      snackBar.show({
        severity: 'error',
        message:
          response?.message || response?.errors
            ? JSON.stringify(response?.errors)
            : 'Something went wrong' || 'Something went wrong'
      });
    }
  };

  const onChangeGeneralInfoInput = (e: CustomInputEvent) => {
    const { value, name } = e.target;
    setGalleryDetails((prev) => ({ ...prev, [name]: value }));
  };

  const onChangePropertyViaName = (value: any, name: string) => {
    const inputEvent: any = { target: { name, value } };

    // If changed property is 'components' and cpu is not yet selected.
    if (inputEvent?.target?.name === 'components' && !galleryDetails?.cpu) {
      let newCpu: '' | 'AMD' | 'Intel' = '';
      const filteredCpu = inputEvent.target.value.filter(
        (x: any) => x.category.id === CategoriesEnum.CPU
      );
      if (!isEmpty(filteredCpu)) {
        if (filteredCpu[0].product_name.includes('AMD')) {
          newCpu = 'AMD';
        }
        if (filteredCpu[0].product_name.includes('INTEL')) {
          newCpu = 'Intel';
        }
        setGalleryDetails((prev) => ({ ...prev, cpu: newCpu }));
      }
    }

    onChangeGeneralInfoInput(inputEvent);
  };

  usePrompt(
    `There are some unsaved changes. Or maybe some image(s) are still uploading. Are you sure you want to leave?`,
    galleryDetails !== undefined || isImageUploading
  );

  return (
    <Page className={classes.root} title="New Gallery">
      <LoaderBar isLoading={loading || isImageUploading} />
      <Container maxWidth={false}>
        <Box mt={2}>
          <form
            autoComplete="off"
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <LoaderBar isLoading={false} />
              <CardHeader
                title="New Gallery"
                subheader="The information of new gallery."
              />

              <Divider />
              <GalleryGeneralInfoCardContent
                data={galleryDetails}
                onChangeInput={onChangeGeneralInfoInput}
              />

              <Divider />
              <GalleryProductsCardContent
                data={galleryDetails?.components}
                onComponentsChange={(products) =>
                  onChangePropertyViaName(products, 'components')
                }
              />

              <Divider />
              <GalleryCoverImageCardContent
                data={galleryDetails?.cover}
                onCoverImageChange={(imgStr) =>
                  onChangePropertyViaName(imgStr, 'cover')
                }
              />

              <Divider />
              <GalleryImagesCardContent
                data={galleryDetails?.photos}
                onPhotosChange={(imgs) =>
                  onChangePropertyViaName(imgs, 'photos')
                }
              />
              {isImageUploading ? (
                <LinearProgress
                  variant="determinate"
                  value={imgUploadProgress}
                />
              ) : (
                false
              )}

              <Divider />
            </Card>
          </form>
        </Box>
        <LoadingButton
          startIcon={<PlusOneIcon />}
          color="primary"
          height={100}
          loading={loading || isImageUploading}
          title="Create Gallery"
          fullWidth
          variant="contained"
          onClick={onCreateGallery}
        />
      </Container>
    </Page>
  );
};

export const AddGalleryView = React.memo(component);
