import {
  Box,
  Button,
  Card,
  CardHeader,
  Divider,
  Table,
  TableRow,
  Grid,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import React, { useState } from 'react';
import { UserAddressList } from './components/UserAddressList';
import DeleteIcon from '@material-ui/icons/Delete';

import { AddressDetails } from 'src/types/pcw-user-address';
import { AlertDialog } from 'src/components';
import { usePermissions } from 'src/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

interface Props {
  className?: string;
  userAddress?: AddressDetails[];
  onAddressPressProfile: (address_id: any) => void;
  onDeleteClick: (userId: any, addressId: any) => void;
  userID: any;
}

const ProfileAddress = ({
  className,
  userAddress,
  userID,
  onAddressPressProfile,
  onDeleteClick
}: Props) => {
  const classes = useStyles();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const { canDeleteUserEcommAddress } = usePermissions();

  const onPressDeleteDialog = () => {
    setOpenDialog(!openDialog);
  };
  return (
    <Card className={clsx(classes.root, className)}>
      <Box
        my={2}
        display={'flex'}
        flexDirection={'row'}
        style={{ marginRight: '15px' }}
      >
        <CardHeader title="User Addresses" />
        {/* TODO: add after workaround with google map */}
        {/* <Button
          color="primary"
          variant="outlined"
          style={{
            marginLeft: 'auto',
            cursor: 'pointer'
          }}
        >
          {'Create Address'}
        </Button> */}
      </Box>

      <Divider />
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            {userAddress?.map((addressItem) => (
              <TableRow key={addressItem?.id}>
                <Grid container>
                  <Grid item md={10}>
                    <UserAddressList
                      onAddressPress={onAddressPressProfile}
                      address={addressItem}
                      listDisplay={true}
                    />
                  </Grid>
                  <Grid
                    item
                    md={2}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <Button
                      startIcon={<DeleteIcon />}
                      color="secondary"
                      variant="outlined"
                      style={{
                        cursor: 'pointer',
                        fontSize: '12px'
                      }}
                      onClick={onPressDeleteDialog}
                      disabled={!canDeleteUserEcommAddress}
                    >
                      {'Delete Address'}
                    </Button>
                    <AlertDialog
                      title={`Delete Address`}
                      customButtons={
                        <>
                          <Button
                            onClick={() =>
                              onDeleteClick(userID, addressItem?.id)
                            }
                            color="secondary"
                            autoFocus
                          >
                            Delete
                          </Button>
                          <Button onClick={onPressDeleteDialog} color="primary">
                            Cancel
                          </Button>
                        </>
                      }
                      subTitle={`Are you sure you want to delete this address?`}
                      isVisible={openDialog}
                    />
                  </Grid>
                </Grid>
              </TableRow>
            ))}
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default ProfileAddress;
