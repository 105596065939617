import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Card,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import {
  CustomInputEvent,
  GetTransactionDetailResponse,
  GetTransactionsViaTransactionNoResponse,
  TransactionDetailOptions,
  TransactionFieldOptions,
  TransactionPayment
} from 'src/types';
import { formatDate } from 'src/utils';
import { isEqual, uniq } from 'lodash';
import {
  allowedPaymentOnTermsField,
  transactionDetailsOptionsSlug
} from 'src/constants';
import { SelectOptions } from 'src/components/selections';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { TransactionTypeKeyEnum } from 'src/enums';

const { actions: optionActions } = slices.options;

const useStyles = makeStyles((theme) => ({
  customerInfoBox: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  remarks: {
    marginTop: theme.spacing(1)
  },
  rowWithField: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row'
  },
  inputField: {
    marginLeft: theme.spacing(1),
    width: 300
  },
  btnContainer: {
    margin: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  updateBtn: {
    marginTop: theme.spacing(1)
  }
}));

interface Props {
  isLoading?: boolean;
  onUpdate?: (
    transactionInfoNew: GetTransactionsViaTransactionNoResponse,
    transactionDetailsNew: GetTransactionDetailResponse,
    transactionOptionsField?: TransactionFieldOptions
  ) => void;
  transactionInfo?: GetTransactionsViaTransactionNoResponse;
  transactionDetails?: GetTransactionDetailResponse;
  transactionPayments?: TransactionPayment[];
}

const Info = ({
  onUpdate,
  transactionInfo,
  transactionPayments,
  isLoading,
  transactionDetails
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [transactionInfoState, setTransactionInfoState] = useState<
    GetTransactionsViaTransactionNoResponse | undefined
  >();
  const [transactionDetailsState, setTransactionDetailsState] = useState<
    GetTransactionDetailResponse | undefined
  >();

  //contains array of options
  const [transactionOptions, setTransactionOptions] = useState<
    TransactionDetailOptions
  >();

  const [transactionSelectedOption, setTransactionSelectedOption] = useState<
    TransactionFieldOptions
  >({});

  const getOptionsTransactionDetails = async () => {
    const optionIds = Object.values(transactionDetailsOptionsSlug);
    const promises = optionIds.map(async (options) => {
      const response = unwrapResult(
        await dispatch(optionActions?.getOptionsByIdSlug(options))
      );

      let key: string;
      switch (options) {
        case transactionDetailsOptionsSlug.type_of_shipping:
          key = TransactionTypeKeyEnum.TypeOfShipping;
          break;
        case transactionDetailsOptionsSlug.purchase_category:
          key = TransactionTypeKeyEnum.PurchaseCategory;
          break;
        case transactionDetailsOptionsSlug.build_type:
          key = TransactionTypeKeyEnum.BuildType;
          break;
        case transactionDetailsOptionsSlug.type_of_sales_transaction:
          key = TransactionTypeKeyEnum.TypeOfSalesTransaction;
          break;
        default:
          return;
      }

      const valueArray = response?.originalData?.data?.split(',');

      setTransactionOptions((prevState: any) => ({
        ...prevState,
        [key]: valueArray
      }));
    });

    await Promise.all(promises);
  };

  const isFieldChanged = useMemo(() => {
    const prevTransactionOptions = {
      ...transactionDetailsState,
      ...transactionSelectedOption
    };
    const isInfoEqual = isEqual(transactionInfo, transactionInfoState);
    const isDetailsEqual = isEqual(transactionDetails, prevTransactionOptions);

    return isInfoEqual && isDetailsEqual;
  }, [
    transactionDetails,
    transactionDetailsState,
    transactionInfo,
    transactionInfoState,
    transactionSelectedOption
  ]);

  const paymentTerms = useMemo(() => {
    const mappedTerms = transactionPayments?.map((x) => {
      if (allowedPaymentOnTermsField?.includes(x.payment_type || '')) {
        return x.payment_type || '';
      }
      return '';
    });
    const uniqTerms: string[] = uniq(mappedTerms);
    const joinedStr: string = uniqTerms?.join(', ');
    return joinedStr || '';
  }, [transactionPayments]);

  const dateSold = useMemo(() => {
    if (
      transactionInfoState?.items &&
      transactionInfoState?.items?.length > 0
    ) {
      if (transactionInfoState?.items[0]?.created_at) {
        return formatDate(transactionInfoState?.items[0].created_at);
      }
      return '--';
    }
    return '--';
  }, [transactionInfoState]);

  const onHandleRemarksChange = (e: CustomInputEvent) => {
    const { value } = e.target;
    setTransactionInfoState((prev) => {
      return {
        ...prev,
        transaction_remarks: value
      };
    });
  };

  const onHandleDetailsChange = (e: CustomInputEvent) => {
    const { name, value } = e.target;
    setTransactionDetailsState((prev) => ({
      ...prev,
      data: {
        ...prev?.data,
        [name]: value
      }
    }));
  };

  const onUpdateInternal = () => {
    if (onUpdate) {
      onUpdate(
        transactionInfoState || {},
        transactionDetailsState || {},
        transactionSelectedOption || {}
      );
    }
  };

  const isUpdateBtnDisabled = useMemo(() => {
    if (isLoading || isFieldChanged) {
      return true;
    }
    return false;
  }, [isFieldChanged, isLoading]);

  useEffect(() => {
    setTransactionInfoState(transactionInfo);
    setTransactionDetailsState(transactionDetails);
  }, [transactionDetails, transactionInfo]);

  useEffect(() => {
    getOptionsTransactionDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className={classes.customerInfoBox}>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Sold To:
            <Typography display="inline" variant="body1">
              {` ${transactionInfoState?.customer?.first_name ??
                ''} ${transactionInfoState?.customer?.last_name ?? ''}`}
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Date Sold:
            <Typography display="inline" variant="body1">
              {dateSold}
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Contact No:
            <Typography display="inline" variant="body1">
              {` ${transactionInfoState?.customer?.contact_no ?? ''}`}
            </Typography>
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Terms:
            <Typography display="inline" variant="body1">
              {' '}
              {paymentTerms}
            </Typography>
          </Typography>
        </Grid>

        {isLoading || !transactionDetailsState?.data?.order_no ? null : (
          <Grid item md={6} xs={12} className={classes.rowWithField}>
            <Typography display="inline" variant="h5">
              (New) Warranty Slip / Order No.:
            </Typography>
            <Typography display="inline">
              {` ${transactionDetailsState?.data?.order_no || ''}`}
            </Typography>
          </Grid>
        )}

        {isLoading ||
        !transactionDetailsState?.data?.warranty_slip_no ? null : (
          <Grid item md={6} xs={12} className={classes.rowWithField}>
            <Typography display="inline" variant="h5">
              (old) Warranty Slip No.:
            </Typography>
            <TextField
              disabled
              helperText="This feature is deprecated"
              size="small"
              placeholder="Warranty Slip No."
              className={classes.inputField}
              name="warranty_slip_no"
              onChange={(e) => onHandleDetailsChange(e)}
              value={transactionDetailsState?.data?.warranty_slip_no || ''}
              variant="outlined"
            />
          </Grid>
        )}

        {isLoading ? null : (
          <Grid item md={6} xs={12} className={classes.rowWithField}>
            <Typography display="inline" variant="h5">
              Acknowledgement Receipt No.:
            </Typography>
            <TextField
              size="small"
              placeholder="Acknowledgement Receipt No."
              className={classes.inputField}
              name="acknowledgement_receipt_no"
              onChange={(e) => onHandleDetailsChange(e)}
              value={
                transactionDetailsState?.data?.acknowledgement_receipt_no || ''
              }
              variant="outlined"
            />
          </Grid>
        )}
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Type Of Shipping
          </Typography>
          <SelectOptions
            label="Type Of Shipping Options"
            initialValue={transactionDetails?.data?.type_of_shipping}
            options={transactionOptions?.type_of_shipping || []}
            onHandleSelectedOptions={(opt?: string) => {
              setTransactionSelectedOption((prev) => ({
                ...prev,
                type_of_shipping: opt
              }));
            }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Purchase Category
          </Typography>
          <SelectOptions
            label="Purchase Category Options"
            initialValue={transactionDetails?.data?.purchase_category}
            options={transactionOptions?.purchase_category || []}
            onHandleSelectedOptions={(opt?: string) => {
              setTransactionSelectedOption((prev) => ({
                ...prev,
                purchase_category: opt
              }));
            }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Build Type
          </Typography>
          <SelectOptions
            label="Build Type Options"
            initialValue={transactionDetails?.data?.build_type}
            options={transactionOptions?.build_type || []}
            onHandleSelectedOptions={(opt?: string) => {
              setTransactionSelectedOption((prev) => ({
                ...prev,
                build_type: opt
              }));
            }}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Type of Sales Transaction
          </Typography>
          <SelectOptions
            label="Type of Sales Transaction Options"
            initialValue={transactionDetails?.data?.type_of_sales_transaction}
            options={transactionOptions?.type_of_sales_transaction || []}
            onHandleSelectedOptions={(opt?: string) => {
              setTransactionSelectedOption((prev) => ({
                ...prev,
                type_of_sales_transaction: opt
              }));
            }}
          />
        </Grid>

        {isLoading ? null : (
          <Grid item xs={12}>
            <Typography display="inline" variant="h5">
              Transaction Remarks:
            </Typography>
            <TextField
              fullWidth
              multiline
              className={classes.remarks}
              rows={4}
              name="transaction_remarks"
              onChange={(e) => onHandleRemarksChange(e)}
              value={transactionInfoState?.transaction_remarks}
              variant="outlined"
            />
          </Grid>
        )}
      </Grid>
      <div className={classes.btnContainer}>
        <Button
          disabled={isUpdateBtnDisabled}
          onClick={onUpdateInternal}
          color="primary"
          variant="outlined"
          className={classes.updateBtn}
        >
          Update Transaction Details
        </Button>
      </div>
    </Card>
  );
};

export const TransactionInfo = React.memo(Info);
