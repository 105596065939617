import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  UpdateEcomOrderResponse,
  EcomOrderUpdateViaOrderRefRequest
} from 'src/types';

export const ecomOrderUpdateThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateEcomOrderResponse>,
  EcomOrderUpdateViaOrderRefRequest,
  { state: RootState }
>('ecom/ecomOrderUpdateThunk', async (requestData) => {
  const response = await Api.patch(
    `/ecomm/internal/order/update/${requestData?.order_ref_no}`,
    requestData.data
  );
  return response;
});
