import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { colors } from 'src/constants';

interface Props {
  priceListDate?: string;
}

const useStyles = makeStyles((theme) => ({
  mainHeader: {
    maxWidth: 2000,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: theme.spacing(0.5)
  },
  logoQuoteImg: {
    width: undefined,
    height: 70,
    marginRight: theme.spacing(1)
  },
  priceListDate: {
    backgroundColor: colors.blue[800],
    display: 'flex',
    flex: 1,
    height: 80,
    justifyContent: 'center',
    alignItems: 'center'
  },
  priceListDateTxt: {
    color: colors.common.white,
    fontWeight: 900,
    letterSpacing: 1
  },
  logoImg: {
    width: undefined,
    height: 70,
    marginLeft: theme.spacing(1)
  },
  pricesChangesAlert: {
    backgroundColor: colors.red[700],
    height: 50,
    maxWidth: 2000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(1)
  },
  employeeNotice: {
    backgroundColor: colors.lime[400],
    padding: theme.spacing(3)
  },
  ramNotice: {
    backgroundColor: colors.warning,
    padding: theme.spacing(3)
  },
  pricesChangesAlertTxt: {
    color: colors.common.black
  }
}));

const component = ({ priceListDate }: Props) => {
  const classes = useStyles();

  return (
    <div>
      {priceListDate ? (
        <div className={classes.mainHeader}>
          <img
            className={classes.logoQuoteImg}
            src="/static/images/logo_quote.png"
          />
          <div className={classes.priceListDate}>
            <Typography className={classes.priceListDateTxt} variant="h2">
              {priceListDate}
            </Typography>
          </div>
          <img
            className={classes.logoImg}
            src="/static/images/avatars/pcworth_logo.png"
          />
        </div>
      ) : null}

      <div className={clsx(classes.pricesChangesAlert, classes.employeeNotice)}>
        <Typography className={classes.pricesChangesAlertTxt} variant="h3">
          !!! This Price List is for employees only. Some item(s) may be
          intentionally HIDDEN for CUSTOMERS but VISIBLE to you !!!
        </Typography>
      </div>
      <div className={clsx(classes.pricesChangesAlert, classes.ramNotice)}>
        <Typography className={classes.pricesChangesAlertTxt} variant="h3">
          !!! If RAM is not whole number, Something is wrong on its stock /
          quantity !!!
        </Typography>
      </div>
    </div>
  );
};

export const SubHeader = React.memo(component);
