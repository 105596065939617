import { Chip, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { colors } from 'src/constants';
import { ListingPriceTypeEnum } from 'src/enums';
import { Listing } from 'src/types';
import { getListingFreebieColor, getListingPriceTypeTxt } from 'src/utils';

interface Props {
  listing?: Listing;
  title?: string;
  chipStyle?: React.CSSProperties;
}

export const ListingFreebieChip = ({ title, listing, chipStyle }: Props) => {
  const isShow = useMemo(() => {
    if (title) {
      return true;
    }
    if (
      !listing?.price_type ||
      listing?.price_type === ListingPriceTypeEnum.NotFree
    ) {
      return false;
    }
    return true;
  }, [listing, title]);

  const chipTitle = useMemo(() => {
    if (title) {
      return title;
    }
    return getListingPriceTypeTxt(listing?.price_type);
  }, [listing, title]);

  const chipColor = useMemo(() => {
    if (title) {
      return colors.success;
    }
    return getListingFreebieColor(listing?.price_type);
  }, [listing, title]);

  return isShow ? (
    <Chip
      style={chipStyle}
      label={
        <Typography style={{ color: chipColor }} variant="subtitle2">
          {chipTitle}
        </Typography>
      }
      size="medium"
      variant="outlined"
      color="default"
    />
  ) : null;
};
