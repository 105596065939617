import React from 'react';
import { Divider, makeStyles, TableCell, Typography } from '@material-ui/core';

import { Sale } from 'src/types';
import { colors } from 'src/constants';
import { formatCurrency } from 'src/utils';

interface Props {
  sale?: Sale;
}

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

const TableCellAmount = ({ sale }: Props) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  return (
    <TableCell style={{ verticalAlign: 'top' }}>
      <Typography variant="h5" style={{ color: colors.blue[600] }}>
        Total Retail Price: {formatCurrency(sale?.total_retail_price)}
      </Typography>
      <Typography variant="h6" style={{ color: colors.orange[600] }}>
        TDP: {formatCurrency(sale?.total_dealers_price)}
      </Typography>
      <Divider />

      <Typography variant="h6" style={{ color: colors.purple[600] }}>
        Amount Paid: {formatCurrency(sale?.amount_paid)}
      </Typography>
      <Typography variant="h6" style={{ color: colors.red[600] }}>
        Balance: {formatCurrency(sale?.balance)}
      </Typography>
      <Divider />

      <Typography variant="h6" style={{ color: colors.green[500] }}>
        Gross: {formatCurrency(sale?.total_gross)}
      </Typography>
    </TableCell>
  );
};

export const SalesAmountTableCell = React.memo(TableCellAmount);
