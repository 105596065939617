import { createSlice } from '@reduxjs/toolkit';
import { CustomerServiceState } from 'src/types';
import * as thunks from './thunks';

const initialState: CustomerServiceState = {
    customerList: [],
    customerDetails: {}
};

const slice = createSlice({
    name: 'customerService',
    initialState,
    reducers: {},
    extraReducers(builder) {
        builder.addCase(thunks.getCustomerServiceListThunk.fulfilled, (state, { payload }) => {
            state.customerList = payload?.originalData?.customers || [];
        });
        builder.addCase(thunks.getCustomerServiceThunk.fulfilled, (state, { payload }) => {
            state.customerDetails = payload?.originalData?.customer || {};
        });
    }
});

export const reducer = slice.reducer;

export const actions = {
    ...slice.actions,
    ...thunks
};