import React, { FC, memo, useCallback } from 'react';
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  colors,
  Paper,
  makeStyles
} from '@material-ui/core';
import { AlternatingColorTableRow } from 'src/components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { useStockTransferReceiver } from 'src/hooks/stock-transfer';
import { Alert } from '@material-ui/lab';
import { TransferSnListError } from './TransferSnListError';
import { TransferSnReceivingErrors } from 'src/redux/slices/stock-transfer';

const useStyles = makeStyles(() => ({
  tableHeader: {
    position: 'sticky',
    top: 0,
    backgroundColor: 'white',
    zIndex: 1
  }
}));

const rowHeaders = [
  '_',
  'Serial No',
  'Product Name',
  'Category',
  'Origin',
  'Processed By',
  'St No'
];

interface Props {}

const component: FC<Props> = () => {
  const classes = useStyles();
  const {
    transferStockProductsReceivedStatus,
    transferReceivingSnErrorList,
    updateReceiveSerializeProduct,
    removeTransferReceivingSnErrors,
    clearAllTransferReceivingSnErrors
  } = useStockTransferReceiver();

  const onHandleRetry = useCallback(
    (params: TransferSnReceivingErrors) => {
      if (params) {
        updateReceiveSerializeProduct(params);
      }
    },
    [updateReceiveSerializeProduct]
  );

  return (
    <Card style={{ marginTop: '1rem' }}>
      <TransferSnListError
        transferSnErrorList={transferReceivingSnErrorList || []}
        onClearAll={clearAllTransferReceivingSnErrors}
        onRemove={removeTransferReceivingSnErrors}
        onRetry={onHandleRetry}
      />
      <PerfectScrollbar>
        {transferStockProductsReceivedStatus?.length === 0 ? (
          <Alert severity="info">{`Scan item for receiving transfer stock(s)`}</Alert>
        ) : (
          <TableContainer
            component={Paper}
            style={{ maxHeight: '50vh', overflow: 'auto' }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {rowHeaders.map((row, index) => (
                    <TableCell
                      key={index}
                      className={classes.tableHeader}
                      style={{ width: index === 0 ? '2%' : 'auto' }}
                    >
                      {row}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {transferStockProductsReceivedStatus.map((st) => (
                  <AlternatingColorTableRow key={st?.serial_no}>
                    <TableCell>
                      {st?.status === 'received' ? (
                        <CheckCircleIcon style={{ color: colors.green[700] }} />
                      ) : (
                        <CancelIcon color="secondary" />
                      )}
                    </TableCell>
                    <TableCell>{st?.serial_no || '---'}</TableCell>
                    <TableCell>{st?.product_name || '---'}</TableCell>
                    <TableCell>{st?.category || '---'}</TableCell>
                    <TableCell>{st?.origin_branch || '---'}</TableCell>
                    <TableCell>{st?.processed_by || '---'}</TableCell>
                    <TableCell>{st?.st_no || '---'}</TableCell>
                  </AlternatingColorTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </PerfectScrollbar>
    </Card>
  );
};

export const ReceivingTable = memo(component);
