import { cloneDeep } from 'lodash';
import { GalleryComponent, Quotation } from 'src/types';
import { componentToQuotation } from '../quotationComponentsOrder';

export const componentProductsToQuotationProducts: (
    products: GalleryComponent[]
) =>
    Quotation[] = (products: GalleryComponent[]) => {
        let clonedProducts = cloneDeep(products);
        let mappedProductsQuotation: Quotation[] = clonedProducts?.reduce(
            (accumulator: Quotation[], currentProduct: GalleryComponent) => {
                const index = accumulator.findIndex(
                    (x) => x.product.product_id === currentProduct.product_id
                );

                if (index !== -1) {
                    accumulator[index].quantity++;
                } else {
                    accumulator.push({
                        quantity: 1,
                        product: {
                            product_id: currentProduct.product_id || undefined,
                            product_name: currentProduct?.product_name,
                            category_id: currentProduct?.category?.id,
                            category_name: currentProduct?.category?.name,
                            retail_price: currentProduct?.retail_price || 0,
                            slug: currentProduct?.product_slug,
                        },
                    });
                }

                return accumulator;
            },
            []
        );
        return componentToQuotation(mappedProductsQuotation);
    };
