import { Gallery, GalleryCreateRequest } from 'src/types';

export const isGalleryCreateParamsValid = (data: GalleryCreateRequest) => {
  if (!data?.title) {
    return 'Title required';
  }
  if (!data?.cover) {
    return 'Cover required';
  }
  if (!data?.badge) {
    return 'Badge required';
  }
  if (!data?.description) {
    return 'Description required';
  }
  if (!data?.quote) {
    return 'Quote required';
  }
  if (!data?.build_tags) {
    return 'Build Tags required';
  }
  if (
    !data?.product_ids ||
    (data?.product_ids && data?.product_ids?.length <= 0)
  ) {
    return 'Products required';
  }
  if (!data?.build_tier) {
    return 'Build Tier required';
  }
  if (!data?.cpu) {
    return 'CPU required';
  }
  if (!data?.keyword) {
    return 'Keyword required';
  }
};

export const isGalleryUpdateParamsValid = (data?: Gallery) => {
  if (!data?.title) {
    return 'Title required';
  }
  if (!data?.cover) {
    return 'Cover required';
  }
  if (!data?.badge) {
    return 'Badge required';
  }
  if (!data?.quote) {
    return 'Quote required';
  }
  if (!data?.build_tags) {
    return 'Build Tags required';
  }
  if (!data?.build_tier) {
    return 'Build Tier required';
  }
  if (!data?.cpu) {
    return 'CPU required';
  }
};
