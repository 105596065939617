import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonManufacturerResponse,
  ManufacturerThunkRequest,
  GetManufacturerViaIDResponse,
  DeleteManufacturerResponse,
  CreateManufacturerRequest,
  CreateManufacturerResponse,
  UpdateManufacturerResponse,
  UpdateManufacturerThunkRequest
} from 'src/types/manufacturer';
import { CommonAxiosResponse } from 'src/types';

export const getManufacturersThunk = createAsyncThunk<
  CommonAxiosResponse<CommonManufacturerResponse>,
  ManufacturerThunkRequest,
  { state: RootState }
>('manufacturers/getManufacturersThunk', async (data) => {
  const response = await Api.get('/manufacturers', data);
  if (!response.success) {
    throw new Error('Failed getManufacturersThunk');
  }
  return response;
});

export const getManufacturersFilterThunk = createAsyncThunk<
  CommonAxiosResponse<CommonManufacturerResponse>,
  ManufacturerThunkRequest,
  { state: RootState }
>('manufacturers/getManufacturersFilterThunk', async (data) => {
  const response = await Api.get('/manufacturers', data);
  if (!response.success) {
    throw new Error('Failed getManufacturersFilterThunk');
  }
  return response;
});

export const getManufacturerViaIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetManufacturerViaIDResponse>,
  number,
  { state: RootState }
>('manufacturers/getManufacturerViaIdThunk', async (id) => {
  const response = await Api.get(`/manufacturers/${id}`);
  if (!response.success) {
    throw new Error('Failed getManufacturerViaIdThunk');
  }
  return response;
});

export const deleteManufacturerThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteManufacturerResponse>,
  number,
  { state: RootState }
>('manufacturers/deleteManufacturerThunk', async (id) => {
  const response = await Api.delete(`/manufacturers/${id}`);
  if (!response.success) {
    throw new Error('Failed deleteManufacturerThunk');
  }
  return response;
});

export const updateManufacturerThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateManufacturerResponse>,
  UpdateManufacturerThunkRequest,
  { state: RootState }
>('manufacturers/updateManufacturerThunk', async (data) => {
  const response = await Api.patch(`/manufacturers/${data.id}`, data);
  if (!response.success) {
    throw new Error('Failed updateManufacturerThunk');
  }
  return response;
});

export const createManufacturerThunk = createAsyncThunk<
  CommonAxiosResponse<CreateManufacturerResponse>,
  CreateManufacturerRequest,
  { state: RootState }
>('manufacturers/createManufacturerThunk', async (data) => {
  const response = await Api.post('/manufacturers', data);
  // if (!response.success) {
  //   throw new Error('Failed createManufacturerThunk');
  // }
  return response;
});
