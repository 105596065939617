import React, { FC, memo } from 'react';
import { Box, Button, ButtonGroup, makeStyles } from '@material-ui/core';
import { Editor } from '@tiptap/react';

import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import FormatAlignRightIcon from '@material-ui/icons/FormatAlignRight';
import FormatAlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import FormatAlignJustifyIcon from '@material-ui/icons/FormatAlignJustify';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';

const useStyles = makeStyles(() => ({
  buttons: {
    padding: 4
  }
}));

interface Props {
  editor: Editor | null;
}

type TextAlignmentType = 'left' | 'right' | 'center' | 'justify';
type alignmentOptionType = { label: TextAlignmentType; icon: any };
const textAlignmentOption: alignmentOptionType[] = [
  { label: 'left', icon: FormatAlignLeftIcon },
  { label: 'right', icon: FormatAlignRightIcon },
  { label: 'center', icon: FormatAlignCenterIcon },
  { label: 'justify', icon: FormatAlignJustifyIcon }
];

const component: FC<Props> = ({ editor }) => {
  const classes = useStyles();

  if (!editor) {
    return null;
  }

  return (
    <Box>
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
      >
        {textAlignmentOption.map((textOption, index) => (
          <Button
            key={index}
            className={classes.buttons}
            onClick={() => {
              {
                editor
                  .chain()
                  .focus()
                  .setTextAlign(textOption.label)
                  .run();
              }
            }}
            disabled={
              !editor
                .can()
                .chain()
                .focus()
                .setTextAlign(textOption.label)
                .run()
            }
            variant={
              editor.isActive(`textAlign`, { textAlign: textOption })
                ? 'contained'
                : 'outlined'
            }
            color="primary"
          >
            <textOption.icon />
          </Button>
        ))}
        <Button
          className={classes.buttons}
          onClick={() => {
            {
              editor
                .chain()
                .focus()
                .toggleBulletList()
                .run();
            }
          }}
          // disabled={
          //   !editor
          //     .can()
          //     .chain()
          //     .focus()
          //     .toggleBulletList()
          //     .run()
          // }
          variant={editor.isActive('bulletList') ? 'contained' : 'outlined'}
          color="primary"
        >
          <FormatListBulletedIcon />
        </Button>
        <Button
          className={classes.buttons}
          onClick={() => {
            {
              editor
                .chain()
                .focus()
                .toggleOrderedList()
                .run();
            }
          }}
          // disabled={
          //   !editor
          //     .can()
          //     .chain()
          //     .focus()
          //     .toggleOrderedList()
          //     .run()
          // }
          variant={editor.isActive('orderedList') ? 'contained' : 'outlined'}
          color="primary"
        >
          <FormatListNumberedIcon />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export const TextAlginmentOption = memo(component);
