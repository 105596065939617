import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';

type State = {};

const initialState: State = {};

const slice = createSlice({
  name: 'oauth',
  initialState,
  reducers: {},
  extraReducers() {}
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
