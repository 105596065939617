import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EcomAvailableProduct, EcomOrder, EcomOrdersGetRequest, PaginationMeta } from 'src/types';
import * as thunks from './thunks';

type EcomOrderState = {
  ecomOrders: EcomOrder[];
  ecomOrdersPaginationMeta: PaginationMeta;
  isListLoading: boolean;
  getEcomOrderListRequest: EcomOrdersGetRequest;
  ecomOrderDetails: EcomOrder;
  availableProducts: EcomAvailableProduct[];
};

const initialState: EcomOrderState = {
  ecomOrders: [],
  isListLoading: false,
  getEcomOrderListRequest: {},
  ecomOrderDetails: {},
  ecomOrdersPaginationMeta: {},
  availableProducts: []
};

const slice = createSlice({
  name: 'ecomOrders',
  initialState,
  reducers: {
    updateKeywordRequest: (state, { payload }: PayloadAction<string>) => {
      state.getEcomOrderListRequest = {
        ...state.getEcomOrderListRequest,
        page: 1,
        keyword: payload || ''
      };
    },
    updateGetEcomOrdersListRequest: (
      state,
      { payload }: PayloadAction<EcomOrdersGetRequest>
    ) => {
      state.getEcomOrderListRequest = {
        ...state.getEcomOrderListRequest,
        ...payload
      };
    }
  },
  extraReducers(builder) {
    builder.addCase(thunks.getEcomOrderListThunk.pending, (state) => {
      state.isListLoading = true;
    });
    builder.addCase(thunks.getEcomOrderListThunk.rejected, (state) => {
      state.isListLoading = false;
    });
    builder.addCase(
      thunks.getEcomOrderListThunk.fulfilled,
      (state, { payload }) => {
        state.ecomOrders = payload?.originalData?.data || [];
        state.ecomOrdersPaginationMeta = payload?.originalData?.meta || {};
        state.isListLoading = false;
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
