import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { usePermissions, useSnackBar } from 'src/hooks';
import { useKachiPoints } from 'src/hooks/kachi-points';
import { unwrapResult } from '@reduxjs/toolkit';

type Props = {
  userId?: number;
  pointsId?: number;
  isOpen: boolean;
  userFullName?: string;
  onClose: () => void;
};

export const ForfeitKachiPoints: FC<Props> = ({
  userId,
  pointsId,
  isOpen,
  onClose
}) => {
  const { canForfeitKachiPoints } = usePermissions();
  const {
    forfeitUserKachiPoints,
    getKachiPointsUserHistory
  } = useKachiPoints();
  const initialValues = {
    forfeit_reason: ''
  };
  const snackbar = useSnackBar();

  const validationSchema = Yup.object().shape({
    forfeit_reason: Yup.string().required('Details is required')
  });

  const onHandleClose = () => {
    onClose();
  };

  const onSubmit = async (values: any, pointsId?: number, user_id?: number) => {
    const forfeitPayload = { ...values, kachi_points_id: pointsId };
    const response = unwrapResult(await forfeitUserKachiPoints(forfeitPayload));

    if (response?.success) {
      snackbar.show({
        message: response?.message,
        severity: 'success'
      });
      getKachiPointsUserHistory(user_id);
      onClose();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography variant="h3">{'Forfeit Kachi Points'}</Typography>
        <Typography
          style={{ marginTop: '5px' }}
        >{`Forfeit Kachi points id: ${pointsId} `}</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(e) => {
              onSubmit(e, pointsId, userId);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit} style={{ minWidth: '500px' }}>
                <TextField
                  fullWidth
                  label="Forfeit Reason"
                  id="forfeit_reason"
                  margin="normal"
                  name="forfeit_reason"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.forfeit_reason}
                  error={!!touched.forfeit_reason && !!errors.forfeit_reason}
                  helperText={touched.forfeit_reason && errors.forfeit_reason}
                  variant="outlined"
                  multiline
                  inputProps={{ maxLength: 500 }}
                />
                <DialogActions style={{ padding: '2rem' }}>
                  <Button
                    color="secondary"
                    disabled={!canForfeitKachiPoints}
                    fullWidth
                    size="small"
                    type="submit"
                    variant="contained"
                  >
                    {'Forfeit'}
                  </Button>
                  <Button
                    onClick={onHandleClose}
                    color="primary"
                    fullWidth
                    size="small"
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};
