export enum EcomOrderStatusEnum {
  Pending = 'pending',
  Paid = 'paid',
  Expired = 'expired',
  Processing = 'processing',
  Completed = 'completed',
  Cancelled = 'cancelled',
  Refunded = 'refunded',
  COD = 'COD'
}
