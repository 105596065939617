import React, { useMemo } from 'react';
import { View } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { warrantyViewStyles } from '../styles';
import { concat } from 'lodash';

interface Props {
  style?: Style | Style[];
  children: React.ReactNode;
}

export const ViewWarrantyRow = ({ style = {}, ...props }: Props) => {
  const toBeAppliedStyle = useMemo(() => {
    if (style) {
      if (Array.isArray(style)) {
        return concat(style, [warrantyViewStyles.row]);
      }
    }
    return [warrantyViewStyles.row, style];
  }, [style]);

  return <View {...props} style={toBeAppliedStyle} />;
};

export const ViewWarrantyFlexOne = ({ style = {}, ...props }: Props) => {
  const toBeAppliedStyle = useMemo(() => {
    if (style) {
      if (Array.isArray(style)) {
        return concat(style, [warrantyViewStyles.flex1]);
      }
    }
    return [warrantyViewStyles.flex1, style];
  }, [style]);

  return <View {...props} style={toBeAppliedStyle} />;
};
