import { ListingPriceTypeEnum, ListingStatusEnum } from 'src/enums';

export const listingPriceTypeOptions: { name: string; value: number }[] = [
  {
    name: 'Not Free (Default)',
    value: ListingPriceTypeEnum.NotFree
  },
  {
    name: 'Free from supplier',
    value: ListingPriceTypeEnum.FreeFromSupplier
  },
  {
    name: 'Free for all',
    value: ListingPriceTypeEnum.FreeForAll
  }
];

export const listingStatusOptions: { name: string; value: string }[] = [
  {
    name: 'Available',
    value: ListingStatusEnum.Available
  },
  {
    name: 'Purchased',
    value: ListingStatusEnum.Purchased
  },
  {
    name: 'Hold',
    value: ListingStatusEnum.OnHold
  },
  {
    name: 'Transfer',
    value: ListingStatusEnum.Transfer
  },
  {
    name: 'Defective',
    value: ListingStatusEnum.Defective
  }
];
