export const appInformation = {
  name: process.env.REACT_APP_NAME,
  version: process.env.REACT_APP_VERSION,
  env: process.env.NODE_ENV,
  branch_cicd: process.env.REACT_APP_BRANCH,
  store_branch: process.env.REACT_APP_STORE_BRANCH,
};

export const appClientCredentials = {
  scope: '*',
  grant_type: 'client_credentials',
  client_id: process.env.REACT_APP_CLIENT_ID,
  client_secret: process.env.REACT_APP_CLIENT_SECRET
};

export const appUrls = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  oauthUrl: process.env.REACT_APP_OAUTH_URL
};

export const pusherCredentials = {
  pusherAppId: process.env.REACT_APP_PUSHER_APP_ID || 1515497,
  pusherAppKey: process.env.REACT_APP_PUSHER_APP_KEY || '0ea7ac0ec637cc731c1c',
  pusherAppSecret:
    process.env.REACT_APP_PUSHER_APP_SECRET || '9e979d190da9a5150cc9',
  pusherAppCluster: process.env.REACT_APP_PUSHER_APP_CLUSTER || 'ap1',
};

export enum SliceStatusValue {
  Idle = 'idle',
  Loading = 'loading',
  Resolve = 'resolve',
  Reject = 'reject'
}
