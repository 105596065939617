import React from 'react';
import { CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import { DeletableImg, DragAndDropImgUpload } from 'src/components';
import { GalleryPhotos } from 'src/types';
import { convertImageFileToBase64 } from 'src/utils';
import { cloneDeep } from 'lodash';

interface Props {
  data?: GalleryPhotos[];
  onPhotosChange: (imgs: GalleryPhotos[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cpuField: {
    borderWidth: 5
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  previewDiv: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  previewImg: {
    height: 150,
    width: undefined
  }
}));

// eslint-disable-next-line no-empty-pattern
const component = ({ data = [], onPhotosChange }: Props) => {
  const classes = useStyles();

  const onPhotosChangeInternal = async (img: File[]) => {
    if (img && img?.length > 0) {
      var imgsArr: any[] = await Promise.all(
        img?.map(
          async (item): Promise<any> => {
            const newImg = {
              id: 0,
              url: await convertImageFileToBase64(item)
            };
            return newImg;
          }
        )
      );
      const clonedDataImgs = cloneDeep(data) || [];
      const mergedImgs = [...clonedDataImgs, ...imgsArr];
      onPhotosChange(mergedImgs);
    }
  };

  const onDeleteImage = (index: number) => {
    const clonedData = cloneDeep(data);
    const itemImg = clonedData[index];

    if (itemImg?.id) {
      itemImg.action = 'delete';
    } else {
      clonedData?.splice(index, 1);
    }
    onPhotosChange(clonedData);
  };

  return (
    <CardContent>
      <Typography className={classes.subHeader} variant="h6">
        Images
      </Typography>
      <DragAndDropImgUpload
        title="Drag or select photos"
        onImageSelected={onPhotosChangeInternal}
      />
      <Grid container spacing={2} className={classes.previewDiv}>
        {data?.map((x, i) => {
          if (x?.action === 'delete') {
            return null;
          } else {
            return (
              <Grid key={`${x?.id}${i}`} item>
                <DeletableImg index={i} imgSrc={x?.url} onDelete={onDeleteImage} />
              </Grid>
            );
          }
        })}
      </Grid>
    </CardContent>
  );
};

export const GalleryImagesCardContent = React.memo(component);
