import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Checkbox,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  Paper,
  Tooltip,
  Typography
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import {
  SerialNoProductData,
  TransferListAction
} from 'src/redux/slices/stock-transfer';
import { useStockTransfer } from 'src/hooks/stock-transfer';

interface Props {
  //we can only have list for these two types
  action: TransferListAction;
  branchListItem: SerialNoProductData[];
  isReceivingBranch?: boolean;

  onRemoveItem: (serialNum?: string) => void;
}

const Component = ({
  branchListItem,
  isReceivingBranch,
  action,
  onRemoveItem
}: Props) => {
  const {
    onAddOriginListingId,
    onAddReceivingListingId,
    onHandleCheckListing,
    toggleCheckUncheckAll
  } = useStockTransfer();

  const listingIdOnly = useMemo(() => {
    return branchListItem
      ?.filter((item) => item?.isChecked && typeof item?.id === 'number') // Filter out unchecked items and non-numeric IDs
      ?.map((item) => Number(item.id)); // Convert IDs to numbers
  }, [branchListItem]);

  /**
   * If one item is unchecked, the checkbox for select all should be unchecked
   */
  const isCheckBoxForSelectAllEnabled = useMemo(
    () => branchListItem?.findIndex((item) => !item?.isChecked) === -1,
    [branchListItem]
  );

  const checkBoxLabel = useMemo(
    () => (isCheckBoxForSelectAllEnabled ? 'UNSELECT ALL' : 'SELECT ALL'),
    [isCheckBoxForSelectAllEnabled]
  );

  const onCheckedBoxChange = (
    event: React.ChangeEvent<HTMLInputElement> | null,
    item: SerialNoProductData
  ) => {
    if (item.isChecked) {
      onHandleCheckListing(action, { ...item, isChecked: false });
    } else {
      onHandleCheckListing(action, { ...item, isChecked: true });
    }
  };

  const onHandleListId = useCallback(
    (listingId?: number[]) => {
      switch (action) {
        case 'origin':
          onAddOriginListingId(listingId);
          break;
        case 'receiving':
          onAddReceivingListingId(listingId);
          break;
        default:
      }
    },
    [action, onAddOriginListingId, onAddReceivingListingId]
  );

  useEffect(() => {
    onHandleListId(listingIdOnly);
  }, [listingIdOnly, onHandleListId]);

  if (branchListItem && branchListItem?.length === 0) {
    return (
      <Paper
        elevation={1}
        style={{
          padding: '1rem',
          display: 'grid',
          placeItems: 'center',
          maxHeight: '500px',
          minHeight: '500px'
        }}
      >
        <Typography>{`Currently, there are no product(s) added yet`}</Typography>
      </Paper>
    );
  }

  return (
    <Paper
      elevation={1}
      style={{
        padding: '1rem',
        maxHeight: '500px',
        minHeight: '500px',
        overflowY: 'scroll'
      }}
    >
      {branchListItem?.length > 0 ? (
        <Paper style={{ marginBottom: '0.5rem' }}>
          <ListItem
            onClick={() =>
              toggleCheckUncheckAll(!isCheckBoxForSelectAllEnabled, action)
            }
            role={undefined}
            dense
            button
            style={{ marginBottom: '0.5rem' }}
          >
            <Checkbox
              color="primary"
              edge="start"
              name="checkedBoxForSelectAll"
              checked={isCheckBoxForSelectAllEnabled}
              tabIndex={-1}
            />
            <Typography style={{ fontWeight: 500 }}>{checkBoxLabel}</Typography>
          </ListItem>
        </Paper>
      ) : null}
      <List>
        {branchListItem?.map((item) => (
          <Paper key={item?.serial_no} style={{ marginBottom: '0.5rem' }}>
            <ListItem
              onClick={() => onCheckedBoxChange(null, item || '')}
              role={undefined}
              dense
              button
              style={{ marginBottom: '0.5rem', padding: '1rem' }}
            >
              <Checkbox
                edge="start"
                checked={item.isChecked}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': 'labelId' }}
                color="primary"
                onChange={(event) => onCheckedBoxChange(event, item || '')}
              />

              <Box
                style={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2
                }}
              >
                <Box style={{ display: 'flex' }}>
                  <Typography style={{ fontWeight: 550 }}>
                    {item?.serial_no || ''}
                  </Typography>
                </Box>

                <Tooltip title={item?.product_name || ''}>
                  <Typography variant="caption">
                    {item?.product_name}
                  </Typography>
                </Tooltip>
              </Box>

              {isReceivingBranch ? (
                <ListItemSecondaryAction>
                  <Tooltip title="Remove product from list">
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => onRemoveItem(item.serial_no || '')}
                    >
                      <ClearIcon color="secondary" />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              ) : null}
            </ListItem>
          </Paper>
        ))}
      </List>
    </Paper>
  );
};

export const BranchItemListField = React.memo(Component);
