import { createAsyncThunk } from '@reduxjs/toolkit';
import Api from 'src/services/ApiService';
import { RootState } from 'src/redux/store';

import {
  AssignBranchRequest,
  CommonAxiosResponse,
  CommonResponseMessage,
  RemoveBranchRequest
} from 'src/types';
import {
  GetBranchesResponse,
  DeleteBranchResponse,
  UpdateBranchRequest,
  GetBranchRequest,
  CommonBranchResponse,
  CreateBranchRequest,
  GetBranchDetailsRequest,
  GetBranchDetailsResponse,
  GetUserAssignedOnBranchResponse,
  GetUserAssignedOnBranchPayload,
  GetUserNotOnBranchResponse,
  GetUserNotOnBranchPayload,
  AddUserToBranchResponse,
  AddUserToBranchPayload,
  DeleteUserToBranchPayload,
  DeleteUserToBranchResponse,
  GetBranchCategoryResponse,
  GetBranchCategoryPayload
} from 'src/types/branch';

export const getBranchesThunk = createAsyncThunk<
  CommonAxiosResponse<GetBranchesResponse>,
  GetBranchRequest,
  { state: RootState }
>('branches/getBranches', async (data) => {
  const response = await Api.get('/branch/list/get', data);
  if (response?.success) {
    return response;
  } else {
    throw new Error('Failed at getBranchesThunk.');
  }
});

export const getBranchDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetBranchDetailsResponse>,
  GetBranchDetailsRequest,
  { state: RootState }
>('branches/getBranchDetailsThunk', async (data) => {
  const response = await Api.get(`/branch/details/${data.id}`);
  if (response?.success) {
    return response;
  } else {
    throw new Error('Failed at getBranchDetailsThunk.');
  }
});

export const createBranchThunk = createAsyncThunk<
  CommonAxiosResponse<CommonBranchResponse>,
  CreateBranchRequest,
  { state: RootState }
>('branches/createBranchThunk', async (data) => {
  const response = await Api.post('/branch/create', data);
  if (response?.success) {
    return response;
  } else {
    throw new Error('Failed at createBranchThunk.');
  }
});

export const branchDeleteThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteBranchResponse>,
  number,
  { state: RootState }
>('branches/branchDeleteThunk', async (id) => {
  const response = await Api.delete(`/branch/delete/${id}`);
  if (response?.success) {
    return response;
  } else {
    throw new Error('Failed at branchDeleteThunk.');
  }
});

export const updateBranchThunk = createAsyncThunk<
  CommonAxiosResponse<CommonBranchResponse>,
  UpdateBranchRequest,
  { state: RootState }
>('Branch/updateBranchThunk', async (data) => {
  const response = await Api.patch(`/branch/update/${data.id}`, data);
  return response;
});

export const assignBranchToUserThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  AssignBranchRequest,
  { state: RootState }
>('user/assignBranch', async (data) => {
  const response = await Api.post(`/user/branch/create`, data);
  return response;
});

export const removeBranchesFromUserThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  RemoveBranchRequest,
  { state: RootState }
>('user/removeBranch', async (data) => {
  const response = await Api.delete(`/user/branch/delete`, data);
  return response;
});

export const getUsersAssignedOnBranchThunk = createAsyncThunk<
  CommonAxiosResponse<GetUserAssignedOnBranchResponse>,
  GetUserAssignedOnBranchPayload,
  { state: RootState }
>('user/getUsersAssignedOnBranchThunk', async (data) => {
  const response = await Api.get(`/branch/users/get`, data);
  return response;
});

export const getUsersNotOnBranchThunk = createAsyncThunk<
  CommonAxiosResponse<GetUserNotOnBranchResponse>,
  GetUserNotOnBranchPayload,
  { state: RootState }
>('user/getUsersNotOnBranchThunk', async (data) => {
  const response = await Api.get(
    `/branch/users/not_in_branch/get/${data.branch_id}`,
    { ...data, limit: 30 }
  );
  return response;
});

export const addUserToBranchThunk = createAsyncThunk<
  CommonAxiosResponse<AddUserToBranchResponse>,
  AddUserToBranchPayload,
  { state: RootState }
>('user/addUserToBranchThunk', async (data) => {
  const response = await Api.post(`branch/users/add/${data?.branch_id}`, {
    user_ids: data?.user_ids
  });
  return response;
});

export const removeUserFromBranchThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteUserToBranchResponse>,
  DeleteUserToBranchPayload,
  { state: RootState }
>('user/removeUserFromBranchThunk', async (data) => {
  const response = await Api.delete(`user/branch/delete`, {
    user_branch_ids: data?.user_branch_ids
  });
  return response;
});

export const getBranchCategoryProductThunk = createAsyncThunk<
  CommonAxiosResponse<GetBranchCategoryResponse>,
  GetBranchCategoryPayload,
  { state: RootState }
>('branches/getBranchCategoryProductThunk', async (data) => {
  const response = await Api.get(`branch/category/product/get`, data);
  return response;
});
