import React, { useCallback, useEffect } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import { PcPackageListToolbar } from './PcPackageListToolbar';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { PcPackageTable } from './PcPackageTable';
import { useIsMount } from 'src/hooks';
import { Pagination } from '@material-ui/lab';
import { PcPackageListPayload } from 'src/types';

const {
  selectors: pcPackageSelectors,
  actions: pcPackageActions
} = slices.pcPackage;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  galleryCard: {
    height: '100%'
  }
}));

export const PcPackageListView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const isFirstMount = useIsMount();

  const isLoading = useAppSelector(pcPackageSelectors.selectIsListLoading);
  const paginationMeta = useAppSelector(
    pcPackageSelectors.selectPcPackagePagination
  );

  const getPackage = useCallback(
    async (request?: PcPackageListPayload) =>
      dispatch(
        pcPackageActions.getPcPackageListThunk(
          request || { limit: 10, sort: 'DESC' }
        )
      ),
    [dispatch]
  );

  useEffect(() => {
    if (isFirstMount) {
      getPackage();
    }
    return;
  }, [getPackage, isFirstMount]);

  return (
    <Page className={classes.root} title="PC Package List">
      <Container maxWidth={false}>
        <PcPackageListToolbar />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <PcPackageTable />
        </Box>
      </Container>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Pagination
          count={paginationMeta?.last_page || 1}
          page={paginationMeta?.current_page || 1}
          onChange={(e, p) => getPackage({ page: p })}
        />
      </Box>
    </Page>
  );
};
