import React, { useEffect, useMemo } from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import { PriceListToolbar, SubHeader } from './components';
import useUserInfo from 'src/hooks/user/use-user-info';
import { MainPriceListTable } from './components/MainPriceListTable';
import { usePriceList } from 'src/hooks/priceList';
import { queryParamsInterface } from './constants';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(3),
    marginTop: theme.spacing(3)
  },
  modalContainer: {
    textAlign: 'right',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export const PriceListView = () => {
  const classes = useStyles();
  const { userBranchOptions } = useUserInfo();
  const {
    isLoading,
    loadMore,
    queryParams,
    priceListState,
    priceListDate,
    getPriceList,
    onLoadMoreItems,
    onUpdatePriceList,
    setQueryParams
  } = usePriceList();
  const defaultValueBranch = useMemo(() => {
    let branchIds: number[] = [];

    branchIds = userBranchOptions.map((branch) => branch?.value);
    return branchIds;
  }, [userBranchOptions]);

  const onHandleSearchParams = (keyword?: string) => {
    setQueryParams((prev: queryParamsInterface) => ({
      ...prev,
      keyword,
      page: 1
    }));
  };

  const onHandleCategoryParams = (category_id?: number) => {
    setQueryParams((prev: queryParamsInterface) => ({
      ...prev,
      category_id,
      page: 1
    }));
  };

  const onHandleBranchParams = (branch_ids?: number[]) => {
    setQueryParams((prev: queryParamsInterface) => ({
      ...prev,
      branch_ids,
      page: 1
    }));
  };

  useEffect(() => {
    getPriceList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  return (
    <Page className={classes.root} title="Price List">
      <Typography
        color="textPrimary"
        variant="h1"
        style={{ marginBottom: '1rem' }}
      >
        Price List
      </Typography>
      <Container maxWidth="xl">
        <PriceListToolbar
          isLoading={isLoading}
          defaultBranch={defaultValueBranch || []}
          onChangeCategory={(selectedCategory) => {
            onHandleCategoryParams(selectedCategory);
          }}
          onChangeBranch={(selectedBranch?: number[]) => {
            onHandleBranchParams(selectedBranch);
          }}
          onSearchProd={(keyword?: string) => {
            onHandleSearchParams(keyword);
          }}
        />
        {/* TODO: add loaders here */}
        <div style={{ marginTop: '1rem' }}>
          <SubHeader priceListDate={priceListDate} />
        </div>
        <LoaderBar isLoading={false} />
        <MainPriceListTable
          isLoading={isLoading}
          loadMore={loadMore}
          priceList={priceListState}
          onUpdatePriceList={(data) => onUpdatePriceList(data)}
          onLoadMoreItems={onLoadMoreItems}
        />
      </Container>
    </Page>
  );
};
