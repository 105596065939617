import React, {
  ChangeEvent,
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  SvgIcon,
  TextField
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { BranchListDropDown } from 'src/components/dropdown';
import { AutoCompleteProductTextField } from './AutoCompleteProductTextField';
import { NonSnReceivingModal } from './NonSnReceivingModal';
import { useStockTransferReceiver } from 'src/hooks/stock-transfer';
import useUserInfo from 'src/hooks/user/use-user-info';
import { CustomKeyboardEvent } from 'src/types';

const useStyles = makeStyles(() => ({
  toolbarContainer: {
    display: 'flex',
    justiftContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%'
  }
}));

interface Props {}

const component: FC<Props> = () => {
  const classes = useStyles();
  const textFieldRef = useRef<HTMLInputElement>(null);
  const {
    isNonSnModal,
    isTransferStockRecordLoading,
    updateReceiveSerializeProduct,
    getNonSnProductsReceiving,
    setIsNonSnModal
  } = useStockTransferReceiver();

  const { userBranchOptions } = useUserInfo();

  const [productType, setProductType] = useState<string>('serialized');
  const [branchId, setBranchId] = useState<number | undefined>();
  const [serial_no, setSerialNo] = useState<string>('');
  const [prodId, setProdId] = useState<number>();
  const [isClear, setIsClear] = useState<boolean>(false);

  const defaultValueBranch = useMemo(() => {
    const branchIds: number[] = userBranchOptions.map(
      (branch) => branch?.value
    );
    return branchIds.length > 0 ? [branchIds[0]] : [];
  }, [userBranchOptions]);

  const onChangeKeyword = (event: ChangeEvent<HTMLInputElement>) => {
    const keywordValue = event.target.value;
    setSerialNo(keywordValue);
  };

  const onBranchChange = (branch_id?: number[]) => {
    setIsClear(true);
    setBranchId(branch_id && branch_id?.length > 0 ? branch_id[0] : undefined);
    setProdId(undefined);
  };

  const onKeyDownUpdateReceivingSerialNoStatus = useCallback(
    (e: CustomKeyboardEvent) => {
      if (e.key === 'Enter') {
        //reset the serial no input
        setSerialNo('');
        setIsClear(false);
        updateReceiveSerializeProduct({
          branch_id: branchId,
          product_type: productType,
          serial_no
        });
      }
    },
    [branchId, productType, serial_no, updateReceiveSerializeProduct]
  );

  useEffect(() => {
    if (!isNonSnModal) {
      setIsClear(false);
      getNonSnProductsReceiving({ branch_id: branchId, product_id: prodId });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchId, prodId]);

  useEffect(() => {
    if (!branchId) {
      setBranchId(
        defaultValueBranch.length > 0 ? defaultValueBranch[0] : undefined
      );
    }
  }, [branchId, defaultValueBranch]);

  //refocus on textfield even there is an error
  useEffect(() => {
    if (!isTransferStockRecordLoading && textFieldRef.current) {
      textFieldRef.current.focus();
    }
  }, [isTransferStockRecordLoading]);

  return (
    <Box className={classes.toolbarContainer}>
      <Grid container spacing={1}>
        <Grid item sm={12} md={3}>
          <BranchListDropDown
            defaultValue={defaultValueBranch || []}
            branchLabel="Receiving Branch"
            allowNoSelectedBranch={false}
            isElevated={false}
            onHandleBranchChange={(branchIds?: number[]) =>
              onBranchChange(branchIds)
            }
          />
        </Grid>
        <Grid item sm={12} md={3}>
          <FormControl
            variant="outlined"
            fullWidth
            style={{ marginTop: '10px' }}
          >
            <InputLabel id="product-type-id">Product Type</InputLabel>
            <Select
              labelId="product-type-id"
              id="select-product-type"
              value={productType}
              onChange={(e: any) => setProductType(e?.target?.value)}
              label="Product Type"
            >
              <MenuItem value="serialized">Serialized</MenuItem>
              <MenuItem value="non_serialized">Non Serialized</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={12} md={3}>
          {productType === 'serialized' ? (
            <TextField
              autoFocus
              inputRef={textFieldRef}
              style={{ marginRight: '1rem', marginTop: '10px' }}
              onChange={onChangeKeyword}
              onKeyDown={(e) => {
                onKeyDownUpdateReceivingSerialNoStatus(e);
              }}
              value={serial_no}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon fontSize="small" color="action">
                      <SearchIcon />
                    </SvgIcon>
                  </InputAdornment>
                )
              }}
              placeholder="Scan Serial No."
              variant="outlined"
            />
          ) : (
            <Box sx={{ marginTop: '10px' }}>
              <AutoCompleteProductTextField
                action="prod_id"
                onClear={isClear}
                onOptionSelected={(prod_id?: number) => {
                  setProdId(prod_id || undefined);
                }}
              />
            </Box>
          )}
        </Grid>
        <Grid
          item
          sm={12}
          md={3}
          style={{ display: 'flex', alignItems: 'center' }}
        ></Grid>
      </Grid>
      <NonSnReceivingModal
        isOpen={isNonSnModal}
        product_type={productType}
        branch_id={branchId}
        product_id={prodId}
        onHandleClose={() => setIsNonSnModal(false)}
      />
    </Box>
  );
};

export const Toolbar = memo(component);
