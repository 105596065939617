import { cloneDeep, isNil } from 'lodash';
import { useCallback, useMemo } from 'react';
import { slices, useAppSelector } from 'src/redux';
import { PermissionApiName } from 'src/types';

const { selectors: userSelector } = slices.user;

export const usePermissions = () => {
  const userInfo = useAppSelector(userSelector.selectUserInfo);

  // Will return boolean if permission name exist in logged in user.
  const permissionChecker = useCallback(
    (permissionName: PermissionApiName) => {
      if (!permissionName) {
        return false;
      }
      const index = userInfo?.all_permissions?.findIndex(
        (x: any) => x?.name === permissionName
      );

      return !isNil(index) && index > -1;
    },
    [userInfo]
  );

  const userPermissions = useMemo(() => userInfo?.all_permissions || [], [
    userInfo
  ]);

  const userPermissionsStringArray = useMemo(() => {
    const clonedArr = cloneDeep(userInfo?.all_permissions) || [];
    // let arrStr: PermissionApiName[] = [];
    let arrStr: any[] = [];
    const mapped = clonedArr?.map((x) => x.name);
    arrStr = mapped?.filter((y) => y);
    return arrStr;
  }, [userInfo]);

  const canViewInventoryTotal = useMemo(
    () => permissionChecker('view_inventory_total'),
    [permissionChecker]
  );

  const canViewSales = useMemo(() => permissionChecker('view_sales'), [
    permissionChecker
  ]);

  const canEditOptions = useMemo(() => permissionChecker('can_edit_options'), [
    permissionChecker
  ]);

  const canEditPriceListPrice = useMemo(
    () => permissionChecker('edit_pricelist_price'),
    [permissionChecker]
  );

  const canManageUsers = useMemo(() => permissionChecker('manage_users'), [
    permissionChecker
  ]);

  const canManagePermissions = useMemo(
    () => permissionChecker('edit_permissions_roles'),
    [permissionChecker]
  );

  const canEditBranches = useMemo(
    () => permissionChecker('can_edit_branches'),
    [permissionChecker]
  );

  // listings related
  const canUpdateListing = useMemo(
    () => permissionChecker('can_update_listing'),
    [permissionChecker]
  );

  const canDeleteListing = useMemo(
    () => permissionChecker('can_delete_listing'),
    [permissionChecker]
  );

  // for purchaser role permissions
  const canSeeDealersPrice = useMemo(
    () => permissionChecker('can_see_dealers_price'),
    [permissionChecker]
  );

  const canSeeGrossPrice = useMemo(
    () => permissionChecker('can_see_gross_price'),
    [permissionChecker]
  );

  const canSeeMarginPercentage = useMemo(
    () => permissionChecker('can_see_margin_percentage'),
    [permissionChecker]
  );

  // For accounting / purchaser
  const canSeeAvailableInventory = useMemo(
    () => permissionChecker('can_see_available_inventory'),
    [permissionChecker]
  );

  const canViewStockTransfer = useMemo(
    () => permissionChecker('view_stock_transfer'),
    [permissionChecker]
  );

  const canReceiveTransferStocks = useMemo(
    () => permissionChecker('can_receive_transfer_stocks'),
    [permissionChecker]
  );

  const canViewStockTransferLogs = useMemo(
    () => permissionChecker('can_view_transfer_stocks_logs'),
    [permissionChecker]
  );

  // for marketing role permissions
  const canViewEcomBanners = useMemo(
    () => permissionChecker('view_ecomm_banners'),
    [permissionChecker]
  );

  const canEditEcomBanners = useMemo(
    () => permissionChecker('edit_ecomm_banners'),
    [permissionChecker]
  );

  const canViewFlashDeals = useMemo(
    () => permissionChecker('can_see_flash_deals'),
    [permissionChecker]
  );

  const canEditFlashDeals = useMemo(
    () => permissionChecker('can_edit_flash_deals'),
    [permissionChecker]
  );

  const canEditCategory = useMemo(
    () => permissionChecker('can_edit_category'),
    [permissionChecker]
  );

  const canSMSBlast = useMemo(() => permissionChecker('can_sms_blast'), [
    permissionChecker
  ]);

  //for pc package permission
  const canCreatePCPackage = useMemo(
    () => permissionChecker('can_create_pc_package'),
    [permissionChecker]
  );

  const canEditPCPackage = useMemo(
    () => permissionChecker('can_edit_pc_package'),
    [permissionChecker]
  );

  const canDeletePCPackage = useMemo(
    () => permissionChecker('can_delete_pc_package'),
    [permissionChecker]
  );

  //for pcw account
  const canViewEcomUserAccount = useMemo(
    () => permissionChecker('view_ecom_user_account'),
    [permissionChecker]
  );

  const canEditEcomUserAccount = useMemo(
    () => permissionChecker('can_edit_ecom_user_account'),
    [permissionChecker]
  );

  //for pcw account addresses
  const canViewUserEcommAddress = useMemo(
    () => permissionChecker('can_view_user_address'),
    [permissionChecker]
  );

  const canViewUserEcommAddressDetail = useMemo(
    () => permissionChecker('can_view_user_address_details'),
    [permissionChecker]
  );

  const canDeleteUserEcommAddress = useMemo(
    () => permissionChecker('can_delete_user_address'),
    [permissionChecker]
  );
  //for minigame
  const canViewMiniGame = useMemo(() => permissionChecker('view_minigame'), [
    permissionChecker
  ]);

  //for code event generator
  const canViewCodeEvent = useMemo(() => permissionChecker('view_code_event'), [
    permissionChecker
  ]);

  const canGenerateCodeEvent = useMemo(
    () => permissionChecker('can_generate_code_event'),
    [permissionChecker]
  );

  const canViewKachiPoints = useMemo(
    () => permissionChecker('view_kachi_points'),
    [permissionChecker]
  );

  const featureToggleKachiPoints = useMemo(
    () => permissionChecker('feature_toggle_kachi_points'),
    [permissionChecker]
  );

  const canAddKachiPoints = useMemo(
    () => permissionChecker('can_add_kachi_points'),
    [permissionChecker]
  );

  const canForfeitKachiPoints = useMemo(
    () => permissionChecker('can_forfeit_kachi_points'),
    [permissionChecker]
  );

  const canShowHistoryKachiPoints = useMemo(
    () => permissionChecker('can_show_history_points'),
    [permissionChecker]
  );

  //======BRANCH PERMISSION =========
  const canViewBranchUsers = useMemo(
    () => permissionChecker('can_view_branch_users'),
    [permissionChecker]
  );

  const canAddBranchUsers = useMemo(
    () => permissionChecker('can_add_branch_users'),
    [permissionChecker]
  );

  const canDeleteBranchUsers = useMemo(
    () => permissionChecker('can_delete_branch_users'),
    [permissionChecker]
  );

  //for products recent out of stock item
  const viewRecentOutOfStockProducts = useMemo(
    () => permissionChecker('view_recent_out_of_stock'),
    [permissionChecker]
  );

  //======for pricelist v2 permission
  const viewPricelist = useMemo(() => permissionChecker('can_view_pricelist'), [
    permissionChecker
  ]);

  const canSeePriceListDealersPrice = useMemo(
    () => permissionChecker('can_see_pricelist_delears_price'),
    [permissionChecker]
  );

  const canSeePriceListGrossPrice = useMemo(
    () => permissionChecker('can_see_pricelist_gross_price'),
    [permissionChecker]
  );

  const canSeePriceListMarginPercentage = useMemo(
    () => permissionChecker('can_see_pricelist_margin_percentage'),
    [permissionChecker]
  );

  const canUpdateRetailPrice = useMemo(
    () => permissionChecker('can_update_pricelist_retail_price'),
    [permissionChecker]
  );

  const canViewDecayingStocks = useMemo(
    () => permissionChecker('can_view_decaying_stocks'),
    [permissionChecker]
  );

  const canViewFastMovingProducts = useMemo(
    () => permissionChecker('can_view_fast_moving_products'),
    [permissionChecker]
  );

  const canViewEcommSavedBuilds = useMemo(
    () => permissionChecker('can_view_ecomm_saved_builds'),
    [permissionChecker]
  );

  const canUpdateEcommSavedBuilds = useMemo(
    () => permissionChecker('can_update_ecomm_saved_builds'),
    [permissionChecker]
  );

  const canDeleteEcommSavedBuilds = useMemo(
    () => permissionChecker('can_delete_ecomm_saved_builds'),
    [permissionChecker]
  );

  const canVoidTransaction = useMemo(
    () => permissionChecker('can_void_transaction'),
    [permissionChecker]
  );

  const canDeleteTransactionPayment = useMemo(
    () => permissionChecker('can_delete_transaction_payment'),
    [permissionChecker]
  );

  const canGenerateEcommOrderPaymentSuccessLink = useMemo(
    () => permissionChecker('can_generate_ecomm_order_payment_success_link'),
    [permissionChecker]
  );

  const canUpdateProductDescription = useMemo(
    () => permissionChecker('can_update_product_description'),
    [permissionChecker]
  );

  const viewSalesDashboard = useMemo(
    () => permissionChecker('view_sales_dashboard'),
    [permissionChecker]
  );

  //===blog permission===
  const canViewBlog = useMemo(() => permissionChecker('can_view_blog'), [
    permissionChecker
  ]);

  const canUpdateBlog = useMemo(() => permissionChecker('can_update_blog'), [
    permissionChecker
  ]);

  const canDeleteBlog = useMemo(() => permissionChecker('can_delete_blog'), [
    permissionChecker
  ]);

  const canCreateBlog = useMemo(() => permissionChecker('can_create_blog'), [
    permissionChecker
  ]);
  const viewTransferListingStock = useMemo(
    () => permissionChecker('can_view_listing_transfer_stocks_list'),
    [permissionChecker]
  );

  const canCreateBlogCategory = useMemo(
    () => permissionChecker('can_create_blog_category'),
    [permissionChecker]
  );

  const canUpdateBlogCategory = useMemo(
    () => permissionChecker('can_update_blog_category'),
    [permissionChecker]
  );

  const canDeleteBlogCategory = useMemo(
    () => permissionChecker('can_delete_blog_category'),
    [permissionChecker]
  );

  const canViewBlogCategory = useMemo(
    () => permissionChecker('can_view_blog_category'),
    [permissionChecker]
  );

  //===INVENTORY AUDIT PERMISSION ===

  const canInventoryAudit = useMemo(
    () => permissionChecker('can_inventory_audit'),
    [permissionChecker]
  );

  const canViewAuditLogs = useMemo(
    () => permissionChecker('can_view_audit_logs'),
    [permissionChecker]
  );

  const canCreateInventoryLog = useMemo(
    () => permissionChecker('can_create_inventory_audit_log'),
    [permissionChecker]
  );

  const canSeeActualAvailableProductCount = useMemo(
    () => permissionChecker('can_see_actual_available_product_count'),
    [permissionChecker]
  );

  return {
    userPermissions,
    userPermissionsStringArray,
    permissionChecker,
    canViewSales,
    canManageUsers,
    canManagePermissions,
    canEditPriceListPrice,
    canViewInventoryTotal,
    canEditCategory,
    canEditBranches,
    canEditOptions,

    // For listings
    canUpdateListing,
    canDeleteListing,

    // For purchaser role
    canSeeDealersPrice,
    canSeeGrossPrice,
    canSeeMarginPercentage,
    canSeeAvailableInventory,
    canViewStockTransfer,
    canReceiveTransferStocks,
    canViewStockTransferLogs,

    // For Marketing Role
    canViewEcomBanners,
    canEditEcomBanners,
    canEditFlashDeals,
    canViewFlashDeals,
    canCreatePCPackage,
    canEditPCPackage,
    canDeletePCPackage,
    canSMSBlast,

    // For pcw user account
    canEditEcomUserAccount,
    canViewEcomUserAccount,

    // For pcw ecomm user address
    canViewUserEcommAddress,
    canViewUserEcommAddressDetail,
    canDeleteUserEcommAddress,

    // For pcw ecomm user builds
    canViewEcommSavedBuilds,
    canDeleteEcommSavedBuilds,
    canUpdateEcommSavedBuilds,

    //for minigame
    canViewMiniGame,

    //for code event
    canViewCodeEvent,
    canGenerateCodeEvent,

    //for kachi points
    canViewKachiPoints,
    featureToggleKachiPoints,
    canAddKachiPoints,
    canForfeitKachiPoints,
    canShowHistoryKachiPoints,

    //for branch views
    canViewBranchUsers,
    canAddBranchUsers,
    canDeleteBranchUsers,

    //for product recent out of stocks
    viewRecentOutOfStockProducts,

    //for pricelist v2
    viewPricelist,
    canSeePriceListDealersPrice,
    canSeePriceListGrossPrice,
    canSeePriceListMarginPercentage,
    canUpdateRetailPrice,

    //decaying stocks
    canViewDecayingStocks,

    //fast moving products
    canViewFastMovingProducts,

    //can update product description
    canUpdateProductDescription,

    // transactions
    canVoidTransaction,
    canDeleteTransactionPayment,
    canGenerateEcommOrderPaymentSuccessLink,

    // dashboard
    viewSalesDashboard,

    //blog
    canViewBlog,
    canUpdateBlog,
    canDeleteBlog,
    canCreateBlog,

    //transfer stock listing
    viewTransferListingStock,

    //blog category
    canCreateBlogCategory,
    canUpdateBlogCategory,
    canDeleteBlogCategory,
    canViewBlogCategory,

    //inventory audit
    canInventoryAudit,
    canViewAuditLogs,
    canCreateInventoryLog,
    canSeeActualAvailableProductCount
  };
};
