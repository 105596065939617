import React, { useCallback } from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Transaction } from 'src/types';
import { Alert } from '@material-ui/lab';
import { CheckBoxLabel } from 'src/components';
import { cloneDeep } from 'lodash';

interface Props {
  className?: string;
  items: Transaction[];
  onUpdateOfRmaItems: (newItems: Transaction[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

export const RenderRMAEditor = ({
  className,
  items,
  onUpdateOfRmaItems,
  ...rest
}: Props) => {
  const classes = useStyles();

  const isItemSelected = useCallback((item: Transaction) => {
    if (item?.rma?.is_hidden_print || item?.rma?.is_hidden_print === undefined) {
      return true;
    }
    if (item?.rma?.is_hidden_print === false) {
      return false;
    }
  }, []);

  const onToggleItem = useCallback(
    (item: Transaction, value: boolean, i: number) => {
      const clonedItems = cloneDeep(items);
      const newItem: Transaction = {
        ...item,
        rma: {
          ...item?.rma,
          is_hidden_print: value
        }
      };
      clonedItems[i] = newItem;
      onUpdateOfRmaItems(clonedItems);
    },
    [items, onUpdateOfRmaItems]
  );

  return (
    <Box m={2}>
      <Alert className={classes.alert} severity="warning">
        This transaction has RMA. Hide or Unhide them here. This will only
        update the document that is about to be printed
        <Typography variant="h5">
          BY DEFAULT ALL RMA ITEMS ARE HIDDEN (CHECKED MEANS HIDDEN)
        </Typography>
      </Alert>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Grid container spacing={3}>
            {items?.map((item: Transaction, i: number) => {
              if (item?.rma) {
                return (
                  <Grid key={item?.id || i} item xs={12}>
                    <CheckBoxLabel
                      disabled={!item?.rma}
                      label={`${item?.product_name || ''} - ${item?.serial_no}`}
                      checked={isItemSelected(item)}
                      onChange={(value) => onToggleItem(item, value, i)}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
        </CardContent>
      </Card>
    </Box>
  );
};
