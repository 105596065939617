import { PartFields } from 'src/types';
import { MotherboardPartDetailsFields } from 'src/types';

export const motherboardPartsFields: PartFields<
  MotherboardPartDetailsFields
>[] = [
  {
    label: 'Socket',
    hint: 'Remove spaces and CAPITAL LETTERS only. Eg: LGA1200, AM4, FM2',
    part_property: 'socket',
    type: 'string',
    required: true,
    transformRule: ['all-caps', 'no-spaces']
  },
  {
    label: 'Form Factor',
    part_property: 'form_factor',
    type: 'string',
    options: ['M-ATX', 'ATX', 'ITX', 'EATX'],
    transformRule: ['all-caps', 'no-spaces'],
    required: true
  },
  {
    label: 'Memory Slots',
    part_property: 'memory_slots',
    hint: 'DDR4, DDR5',
    transformRule: ['all-caps', 'no-spaces'],
    type: 'string',
    required: true
  },
  {
    label: 'Chipset',
    part_property: 'chipset',
    type: 'string',
    required: true,
    transformRule: ['all-caps', 'no-spaces'],
    hint: 'eg: A300, X300, A320'
  },
  {
    label: 'Max Memory',
    part_property: 'max_memory',
    hint: '64GB, 32GB',
    unit: 'GB',
    type: 'number',
    required: true
  },
  // {
  //   label: 'NVME',
  //   part_property: 'nvme',
  //   type: 'string',
  //   required: true
  // },
  {
    label: 'Image',
    part_property: 'image_upload',
    hint: 'Select Product Image. Only one is allowed for now',
    type: 'img',
    required: false
  }
];
