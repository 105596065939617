import { unwrapResult } from '@reduxjs/toolkit';
import { useMemo, useState } from 'react';
import { CategoriesEnum, ProductTypeEnum } from 'src/enums';
import { slices, useAppDispatch } from 'src/redux';
import {
  Category,
  Manufacturer,
  Product,
  ProductsThunkRequest
} from 'src/types';
import { getCategoryNameViaId } from 'src/utils';
import { useSnackBar } from '../use-snackbar';
import { useProductPartDetailsLogic } from './use-product-part-details-logic';

const { actions: productActions } = slices.product;
const { actions: productDetailsActions } = slices.productPartDetails;

// we might need screenId later
// eslint-disable-next-line no-unused-vars
export const useAddProductLogic = (screenId?: string) => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const { transformToValidDataForApi } = useProductPartDetailsLogic();

  const [isCreateCategoryVisible, setIsCreateCategoryVisible] = useState<
    boolean
  >(false);

  const [
    isCreateManufacturerVisible,
    setIsCreateManufacturerVisible
  ] = useState<boolean>(false);
  const [addManufacturerLoading, setAddManufacturerLoading] = useState<boolean>(
    false
  );
  const [addCategoryLoading, setAddCategoryLoading] = useState<boolean>(false);
  const [
    selectedManufacturer,
    setSelectedManufacturer
  ] = useState<Manufacturer | null>(null);
  const [manufacturerInputValue, setManufacturerInputValue] = useState('');
  const [manufacturers, setManufacturers] = useState<Manufacturer[]>([]);

  const [selectedWarranty, setSelectedWarranty] = useState<any | null>(null);

  const [categories, setCategories] = useState<Category[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [references, setReferences] = useState<string[]>();
  const [warrantyInput, setWarrantyInput] = useState('');
  const [selectedName, setSelectedName] = useState('');
  const [selectedEcDisplayName, setSelectedEcDisplayName] = useState('');
  const [selectedSKU, setSelectedSKU] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(
    null
  );
  const [retailPrice, setRetailPrice] = useState('');
  const [dealersPrice, setDealersPrice] = useState('');
  const [recentProducts, setRecentProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedRamKitType, setSelectedRamKitType] = useState<
    undefined | number
  >(undefined);
  const [consumableUnit, setConsumableUnit] = useState<string | null>();
  const [productType, setProductType] = useState<number | undefined>(
    ProductTypeEnum.Serialized
  );

  const onReferencesChange = (referenceArg?: string[]) => {
    setReferences(referenceArg);
  };

  const isSelectedCategoryRAM = useMemo(() => {
    // RAM - 13 on stage, prod and dev
    // NOTE: RAM's ID on database should always be 13
    if (selectedCategory?.id === CategoriesEnum.RAM) {
      return true;
    }
    return false;
  }, [selectedCategory]);

  const addProductAndProductDetails = async (
    newProductData: ProductsThunkRequest
  ) => {
    if (newProductData?.specification?.length === 0) {
      delete newProductData.specification;
    }
    const createProductRes = unwrapResult(
      await dispatch(productActions.createProductsThunk(newProductData))
    );
    const categoryKey = getCategoryNameViaId(
      createProductRes?.originalData?.product?.category_id
    );
    if (
      createProductRes &&
      createProductRes?.success &&
      createProductRes?.originalData?.product &&
      categoryKey
    ) {
      const product_id = createProductRes?.originalData?.product?.id;
      const dataForPartDetail = transformToValidDataForApi(categoryKey);
      const response = unwrapResult(
        await dispatch(
          productDetailsActions.createProductDetailsThunk({
            product_id,
            category_name: categoryKey,
            ...dataForPartDetail
          })
        )
      );

      if (!response?.success) {
        snackBar.show({
          severity: 'success',
          message: `Adding ${categoryKey} details failed. You may edit it later on main product page.`,
          useSound: true
        });
      }
    }
    dispatch(productDetailsActions.resetProductPartDetails());
    return createProductRes;
  };

  return {
    references,
    onReferencesChange,
    addProductAndProductDetails,
    isSelectedCategoryRAM,
    isCreateCategoryVisible,
    setIsCreateCategoryVisible,
    isCreateManufacturerVisible,
    setIsCreateManufacturerVisible,
    addManufacturerLoading,
    setAddManufacturerLoading,
    addCategoryLoading,
    setAddCategoryLoading,
    selectedManufacturer,
    setSelectedManufacturer,
    manufacturerInputValue,
    setManufacturerInputValue,
    manufacturers,
    setManufacturers,
    selectedWarranty,
    setSelectedWarranty,
    categories,
    setCategories,
    inputValue,
    setInputValue,
    warrantyInput,
    setWarrantyInput,
    selectedName,
    setSelectedName,
    selectedCategory,
    setSelectedCategory,
    retailPrice,
    setRetailPrice,
    dealersPrice,
    setDealersPrice,
    recentProducts,
    setRecentProducts,
    isLoading,
    setIsLoading,
    selectedRamKitType,
    setSelectedRamKitType,
    consumableUnit,
    setConsumableUnit,
    productType,
    setProductType,
    selectedSKU,
    setSelectedSKU,
    selectedEcDisplayName,
    setSelectedEcDisplayName
  };
};
