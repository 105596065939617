import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import { Page } from 'src/components';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackBar } from 'src/hooks';

const { actions: categoryActions } = slices.category;

const useStyles = makeStyles(() => ({
  root: {}
}));

const AddProductView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [categoryName, setCategoryName] = useState('');

  const onClearNameField = () => {
    setCategoryName('');
  };

  const onCreateCategory = async () => {
    if (categoryName) {
      const newCategoryData = { name: categoryName };
      const response = unwrapResult(
        await dispatch(categoryActions.createCategoryThunk(newCategoryData))
      );

      if (response.success) {
        snackBar.show({ severity: 'success', message: response.message });
        onClearNameField();
        // TODO: Add table of recently added categories.
        // onAddRecentProduct(response.originalData.products);
      } else {
        snackBar.show({ severity: 'error', message: response.message });
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.'
      });
    }
  };

  // const onKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     onCreateCategory();
  //   }
  // };

  return (
    <Page className={classes.root} title="New Category">
      <Container maxWidth={false}>
        <Box mt={2}>
          <Card>
            <CardHeader
              title="New Category"
              subheader="The information of new category."
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={categoryName}
                    required
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        onCreateCategory();
                      }
                    }}
                    onChange={(e) => setCategoryName(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="space-between" p={2}>
              <Button
                startIcon={<ClearIcon />}
                onClick={onClearNameField}
                color="secondary"
                variant="contained"
              >
                Clear
              </Button>
              <Button
                startIcon={<SaveIcon />}
                style={{ marginLeft: 18 }}
                onClick={onCreateCategory}
                color="primary"
                variant="contained"
              >
                Add new category
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default AddProductView;
