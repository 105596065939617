import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectRaffle = (state: RootState) => state.raffle;

const selectRaffleWinner = createSelector(
  selectRaffle,
  (state) => state.raffleWinner
);
const selectRaffleWinners = createSelector(
  selectRaffle,
  (state) => state.raffleWinners
);
const selectAvailableRaffleEntries = createSelector(
  selectRaffle,
  (state) => state.availableRaffleEntries
);
const selectRemovedRaffleWinners = createSelector(
  selectRaffle,
  (state) => state.removedRaffleWinners
);

export const selectors = {
  selectRaffleWinner,
  selectRaffleWinners,
  selectAvailableRaffleEntries,
  selectRemovedRaffleWinners
};
