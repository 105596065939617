import { removeNonDigits } from 'src/utils';

const standardizeMobileNumber = (input: string) => {
  // Remove non-digit characters
  let digits: string | null = removeNonDigits(input);

  // Check the length and format
  if (digits.length === 10 && digits.startsWith('9')) {
    // If it's 10 digits, add '63' at the beginning
    digits = '63' + digits;
  } else if (digits.length === 11 && digits.startsWith('0')) {
    // If it's 11 digits and starts with '0', replace '0' with '63'
    digits = '63' + digits.substring(1);
  } else if (digits.length !== 12) {
    // If the length is not 10, 11, or 12, return an error message or handle accordingly
    digits = null;
  }

  return digits;
};

export const utils = {
  standardizeMobileNumber
};
