import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { LinkComponent, LoaderBar, Page } from 'src/components';
import { Branch, Permission, PermissionApiName, Role } from 'src/types';

import { UserBasicInfo } from 'src/types/user';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import { batch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  menuItem: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  clearText: {
    color: 'rgb(244, 67, 54)',
    display: 'flex',
    justifyContent: 'space-between'
  },
  statusContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const { actions: userActions, selectors: userSelectors } = slices.user;
const { actions: roleActions, selectors: roleSelectors } = slices.roles;
const {
  actions: permissionActions,
  selectors: permissionSelectors
} = slices.permissions;

const UsersView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const users = useAppSelector(userSelectors?.selectAllUsers);
  const roles = useAppSelector(roleSelectors.selectRoleList);
  const permissions = useAppSelector(permissionSelectors.selectPermissionList);

  const [isUsersLoading, setUsersLoading] = useState<boolean>(false);
  const [filteredUsers, setFilteredUsers] = useState<UserBasicInfo[]>([]);
  const [isRolesLoading, setRolesLoading] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPermissionsLoading, setPermissionsLoading] = useState<boolean>(
    false
  );
  //   filters state
  const [fullNameVal, setFullNameVal] = useState<string>('');
  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);
  const [selectedPerms, setSelectedPerms] = useState<PermissionApiName[]>([]);

  const onChangeRoles = useCallback((val: any) => {
    if (val.includes('clear')) {
      setSelectedRoles([]);
      return;
    }
    setSelectedRoles(val);
  }, []);

  const permsChecker = useCallback(
    (user?: UserBasicInfo) => {
      const userPerms = user?.all_permissions?.map((i) => i.name);
      return selectedPerms.every((i) => userPerms?.includes(i));
    },
    [selectedPerms]
  );

  const roleChecker = useCallback(
    (user?: UserBasicInfo) => {
      const userRoles = user?.all_roles?.map((i) => i.name);
      return selectedRoles.every((i) => userRoles?.includes(i));
    },
    [selectedRoles]
  );

  const onApplyFilter = () => {
    setIsLoading(true);
    if (users) {
      const results = users.filter((user) => {
        const fullName = fullNameVal.toLowerCase().split(' ');

        const userNameMatch = fullName.every((i) =>
          user?.username?.toLowerCase().includes(i)
        );

        const fullNameMatch = fullName.every((word) =>
          `${user.first_name} ${user.last_name}`.toLowerCase().includes(word)
        );

        const rolesMatch = roleChecker(user);
        const permsMatch = permsChecker(user);

        return (userNameMatch || fullNameMatch) && rolesMatch && permsMatch;
      });

      setFilteredUsers(results);
    }
    setIsLoading(false);
  };

  const onPressClear = useCallback(() => {
    setFullNameVal('');
    setSelectedPerms([]);
    setSelectedRoles([]);
  }, []);

  const onChangePerms = useCallback((val: any) => {
    if (val.includes('clear')) {
      setSelectedPerms([]);
      return;
    }
    setSelectedPerms(val);
  }, []);

  const rolesOfAUser = useCallback(
    (rolesArg: Role[]) => rolesArg?.map((x) => x.name).join(', '),
    []
  );

  const branchOfAUser = useCallback(
    (branchArg: Branch[]) => branchArg?.map((x) => x.branch_name).join(', '),
    []
  );

  const permissionsOfAUser = useCallback(
    (permissionsArg: Permission[]) =>
      permissionsArg?.map((x) => x.name).join(', '),
    []
  );

  const onFullNameInputChange = (val: string) => {
    setFullNameVal(val);
  };

  const getRolesAndPermissions = useCallback(() => {
    batch(() => {
      setRolesLoading(true);
      dispatch(roleActions.getRolesThunk()).finally(() =>
        setRolesLoading(false)
      );

      setPermissionsLoading(true);
      dispatch(permissionActions.getPermissionsThunk()).finally(() =>
        setPermissionsLoading(false)
      );
    });
  }, [dispatch]);

  const getAllUser = useCallback(async () => {
    setUsersLoading(true);
    dispatch(userActions.getAllUsersThunk()).finally(() =>
      setUsersLoading(false)
    );
  }, [dispatch]);

  useEffect(() => {
    getAllUser();
    getRolesAndPermissions();
  }, [getAllUser, getRolesAndPermissions]);

  useEffect(() => {
    if (users && users[0]) {
      setFilteredUsers(users);
    }
  }, [users]);

  return (
    <Page className={classes.root} title="Manage Users">
      <Container maxWidth={false}>
        <Typography color="textPrimary" gutterBottom variant="h3">
          Users / Employees
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          style={{ marginTop: '40px' }}
        >
          <Box display="flex">
            <FormControl
              variant="outlined"
              style={{
                width: '200px',
                marginRight: '1em'
              }}
            >
              <TextField
                value={fullNameVal}
                variant="outlined"
                onKeyDown={(e) => {
                  e.key === 'Enter' && onApplyFilter();
                }}
                placeholder="Search Name"
                onChange={(e) => {
                  const val = e.target.value;
                  onFullNameInputChange(val);
                }}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              style={{
                width: '200px',
                marginRight: '1em'
              }}
            >
              <InputLabel>Select Roles</InputLabel>
              <Select
                value={selectedRoles}
                label="Select Roles"
                multiple
                disabled={isRolesLoading}
                placeholder="Select Roles"
                onChange={(e) => onChangeRoles(e.target.value)}
                renderValue={(selected: any) => (
                  <Typography>{selected.join(', ')}</Typography>
                )}
              >
                <MenuItem value={'clear'} className={classes.clearText}>
                  Clear Roles
                </MenuItem>
                {roles &&
                  roles[0] &&
                  roles.map((i, ind) => (
                    <MenuItem
                      className={classes.menuItem}
                      key={ind}
                      value={i.name}
                    >
                      {i.name}
                      {i?.name && selectedRoles.includes(i?.name) && (
                        <CheckIcon />
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              style={{
                width: '200px',
                marginRight: '1em'
              }}
            >
              <InputLabel>Select Perms</InputLabel>
              <Select
                value={selectedPerms}
                multiple
                label="Select Perms"
                disabled={isPermissionsLoading}
                onChange={(e) => onChangePerms(e.target.value)}
                renderValue={(selected: any) => (
                  <Typography>{selected.join(',')}</Typography>
                )}
              >
                <MenuItem value={'clear'} className={classes.clearText}>
                  Clear Perms
                  <ClearIcon color="secondary" />
                </MenuItem>
                {permissions &&
                  permissions[0] &&
                  permissions.map((i, ind) => (
                    <MenuItem
                      className={classes.menuItem}
                      key={ind}
                      value={i.name}
                    >
                      {i.name}
                      {i?.name && selectedPerms.includes(i?.name) && (
                        <CheckIcon />
                      )}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={onApplyFilter}
              disabled={isLoading}
            >
              Apply Filter
            </Button>
            <Button
              style={{ marginLeft: '5px' }}
              endIcon={<ClearIcon />}
              onClick={onPressClear}
              color="secondary"
            >
              Set To Default
            </Button>
          </Box>
          <Button
            onClick={() => navigate('/app/user/add')}
            color="primary"
            variant="contained"
          >
            Create a new user
          </Button>
        </Box>
        <Box marginY={2}>
          <Card>
            <Divider />
            <PerfectScrollbar>
              <Box>
                <LoaderBar isLoading={isUsersLoading} />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Employee Full Name</TableCell>
                      <TableCell>UserName</TableCell>
                      <TableCell>Roles</TableCell>
                      {multiBranchFeat ? <TableCell>Branches</TableCell> : null}
                      <TableCell>Permissions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredUsers?.map((item: UserBasicInfo) => (
                      <TableRow hover key={item.id}>
                        <TableCell>
                          <LinkComponent
                            href={`/app/user/${item.id}`}
                            onClick={() => navigate(`/app/user/${item.id}`)}
                            title={`${item?.first_name ||
                              ''} ${item?.last_name || ''}`}
                          />
                        </TableCell>
                        <TableCell>{item?.username || '--'}</TableCell>
                        <TableCell>
                          {rolesOfAUser(item?.all_roles || [])}
                        </TableCell>
                        {multiBranchFeat ? (
                          <TableCell>
                            {branchOfAUser(item?.branches || [])}
                          </TableCell>
                        ) : null}
                        <TableCell>
                          {permissionsOfAUser(item?.all_permissions || [])}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default UsersView;
