import {useRef, useEffect} from 'react';

/**
 * used to call a useEffect only once
 * or call a function only one the component rendered
 */
export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};
