import React, { useEffect, useState } from 'react';
import { LoaderBar, Page } from 'src/components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  makeStyles,
  colors,
  Container
} from '@material-ui/core';
import InventoryToolbar from './component/AvailableInventoryToolbar';
import { slices, useAppDispatch } from 'src/redux';
import { InventoryTableBody } from './component/AvailableInventoryTableBody';
import AvailableInventoryFilter from './component/AvailableInventoryFilter';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  AvailableInventorySumData,
  GetAvailableInventoryResponseData
} from 'src/types/available-inventory';
import { InventoryTableHeader } from './component';
import { Pagination } from '@material-ui/lab';
import { usePermissions } from 'src/hooks';
import useUserInfo from 'src/hooks/user/use-user-info';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';

const { actions: inventoryActions } = slices.inventory;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  inventoryCard: {
    height: '100%'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 10
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  },
  retailPriceCell: {
    width: 200
  },
  columnBtn: {
    marginTop: theme.spacing(2),
    justifyContent: 'flex-end',
    display: 'flex'
  },
  toCsvText: {
    color: theme.palette.primary.main
  },
  toCsvBtn: {
    marginLeft: theme.spacing(2)
  }
}));

const AvailableInventoryView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { canSeeAvailableInventory } = usePermissions();
  const { userBranchOptions, getUserDetails } = useUserInfo();
  const { selectedBranchIDs, setSelectedBranchIDs } = useBranchInfo();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [fromDate, setFromDate] = useState<string>();
  const [toDate, setToDate] = useState<string>();

  const [availableInventorySum, setAvailableInventorySum] = useState<
    AvailableInventorySumData
  >();
  const [pageDataList, setPageDataList] = useState<
    GetAvailableInventoryResponseData
  >();

  const getInventoryListPage = async (page: number) => {
    const response = unwrapResult(
      await dispatch(
        inventoryActions.getAvailableInventoryListThunk({
          page,
          start_date: fromDate,
          end_date: toDate,
          branch_ids: selectedBranchIDs
        })
      ).finally(() => setIsLoading(false))
    );
    setPageDataList(response.originalData?.data);
  };

  const getInventory = async () => {
    if (!canSeeAvailableInventory) {
      return;
    }
    setIsLoading(true);
    setPageDataList(undefined);

    const response = unwrapResult(
      await dispatch(
        inventoryActions.getAvailableInventorySumThunk({
          start_date: fromDate,
          end_date: toDate
        })
      ).finally(() => setIsLoading(false))
    );
    setAvailableInventorySum(response?.originalData?.data);
    if (response?.success) {
      const response = unwrapResult(
        await dispatch(
          inventoryActions.getAvailableInventoryListThunk({
            start_date: fromDate,
            end_date: toDate,
            to_get: 'list',
            branch_ids: selectedBranchIDs
          })
        ).finally(() => setIsLoading(false))
      );
      setPageDataList(response.originalData?.data);
    }
  };

  useEffect(() => {
    getUserDetails();
  }, [getUserDetails]);

  return (
    <Page className={classes.root} title="Available Inventory ">
      <Container maxWidth={false}>
        <AvailableInventoryFilter
          fromDate={fromDate}
          toDate={toDate}
          onChangeFromDate={(date) => setFromDate(date)}
          onChangeToDate={(date) => setToDate(date)}
          onApplyFilterPress={getInventory}
          userBranchOptions={userBranchOptions}
          isLoading={isLoading}
          selectedBranchIDs={selectedBranchIDs}
          setSelectedBranchIDs={setSelectedBranchIDs}
        />
        <InventoryToolbar
          sumAvailable={availableInventorySum}
          loaderBar={isLoading}
        />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <Card>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Table size="small">
                  <InventoryTableHeader />
                  <InventoryTableBody
                    inventoryItem={pageDataList?.data || []}
                  />
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Pagination
            count={pageDataList?.last_page || 1}
            page={pageDataList?.current_page || 1}
            onChange={(e, p) => getInventoryListPage(p)}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default AvailableInventoryView;
