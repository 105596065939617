import React, { memo, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Container,
  LinearProgress,
  Typography,
  makeStyles
} from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import { OutOfStockToolbar } from './components/Toolbar';
import { OutOfStockProduct } from './components/OutOfStockTable';
import { useRecentOutOfStocks } from 'src/hooks/recent-out-of-stocks/useRecentOutOfStock';
import { GetOutOfStocksProdPayload } from 'src/redux/slices/out-of-stocks-product';
import { Alert, Pagination } from '@material-ui/lab';
import useUserInfo from 'src/hooks/user/use-user-info';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const component = () => {
  const classes = useStyles();
  const {
    recentOutOfStockProduct,
    metaOutOfStockProd,
    isLoading,
    getRecentOutOfStockProductList
  } = useRecentOutOfStocks();
  const { userBranchOptions } = useUserInfo();

  const [outOfStockParams, setOutOfStockParams] = useState<
    GetOutOfStocksProdPayload
  >();
  const [pageNum, setPageNum] = useState<number>(1);

  const defaultValueBranch = useMemo(() => {
    let branchIds: number[] = [];

    branchIds = userBranchOptions.map((branch) => branch?.value);
    return branchIds;
  }, [userBranchOptions]);

  const onChangeCategory = (category?: number) => {
    setOutOfStockParams((prev) => ({
      ...prev,
      page: 1,
      category
    }));
    setPageNum(1);
  };

  const onChangeBranch = (branchIds?: number[]) => {
    setOutOfStockParams((prev) => ({
      ...prev,
      page: 1,
      branch_ids: branchIds || defaultValueBranch
    }));
    setPageNum(1);
  };

  const onPageChange = (page: number) => {
    getRecentOutOfStockProductList({ page, ...outOfStockParams });
    setPageNum(page);
  };

  const onChangeSearchKeyword = (keyword?: string) => {
    setOutOfStockParams((prev) => ({
      ...prev,
      keyword
    }));
    setPageNum(1);
  };

  const onHandleLoadOutOfStockProduct = useCallback(() => {
    getRecentOutOfStockProductList(outOfStockParams);
  }, [getRecentOutOfStockProductList, outOfStockParams]);

  return (
    <Page title={'Recent out of stock products'} className={classes.root}>
      <Container maxWidth="xl" style={{ minHeight: '85vh' }}>
        <Typography color="textPrimary" gutterBottom variant="h2">
          {'Recent out of stock products'}
        </Typography>
        <OutOfStockToolbar
          defaultBranch={defaultValueBranch}
          onChangeCategory={(categoryId?: number) => {
            onChangeCategory(categoryId);
          }}
          onChangeBranch={onChangeBranch}
          onChangeSearch={onChangeSearchKeyword}
          onHandleFilterToLoadOutOfStock={onHandleLoadOutOfStockProduct}
        />
        <LoaderBar isLoading={false} />
        {!outOfStockParams && recentOutOfStockProduct.length === 0 && (
          <Box>
            <Alert severity="info">{`Select Filter To Load Recent Out Of Stock Products`}</Alert>
          </Box>
        )}
        {isLoading ? (
          <LinearProgress />
        ) : (
          <OutOfStockProduct outOfStockProducts={recentOutOfStockProduct} />
        )}
        <Box display="flex" justifyContent="flex-end" p={2} marginBottom={5}>
          {recentOutOfStockProduct.length > 0 && (
            <Pagination
              count={metaOutOfStockProd?.last_page}
              page={pageNum}
              onChange={(e, pageNumber) => onPageChange(pageNumber)}
            />
          )}
        </Box>
      </Container>
    </Page>
  );
};

export const ProductRecentOutOfStockView = memo(component);
