import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import { GetOutOfStocksProdPayload, GetOutOfStocksProdResponse } from './types';

export const getRecentOutOfStockProducts = createAsyncThunk<
  CommonAxiosResponse<GetOutOfStocksProdResponse>,
  GetOutOfStocksProdPayload,
  { state: RootState }
>('ecomm/banner/getRecentOutOfStockProducts', async (data) => {
  const response = await Api.get('/products/recently_out_of_stocks/get', data);
  return response;
});
