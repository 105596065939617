import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  CommonResponseMessage,
  CreateTransactionDetailRequest
} from 'src/types';

export const createTransactionDetailThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  CreateTransactionDetailRequest,
  { state: RootState }
>('transaction/createTransactionDetailThunk', async (data) => {
  const response = await Api.post(`/transaction/details/create`, data);
  // if (!response.success) {
  //   throw new Error('Failed createTransactionDetailThunk');
  // }
  return response;
});
