import React, { useMemo } from 'react';
import {
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { slices, useAppSelector } from 'src/redux';
import { IconButtonComponent } from 'src/components';
import FilterListIcon from '@material-ui/icons/FilterList';
import { SalesColumn } from 'src/types';
import { cloneDeep } from 'lodash';
import { salesColumnsLocal } from 'src/constants';

const {
  selectors: salesSelectors,
  // eslint-disable-next-line no-unused-vars
  actions: salesActions
} = slices.sales;

interface Props {
  onColumnFilterClick: (column: SalesColumn) => void;
}

const useStyles = makeStyles(() => ({
  mainRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: 'pointer'
  }
}));

const TableHeader = ({ onColumnFilterClick }: Props) => {
  const classes = useStyles();
  // const dispatch = useAppDispatch();

  const salesColumnsRedux = useAppSelector(
    salesSelectors.selectSalesColumns
  );

  // const salesLocalFilter = useAppSelector(
  //   salesSelectors.selectSalesLocalFilter
  // );

  // const onSortByClicked = useCallback(
  //   (column: SalesColumn) => {
  //     const currentOrder = salesLocalFilter[column]?.sortBy;
  //     const newSort: 'ASC' | 'DESC' = currentOrder === 'ASC' ? 'DESC' : 'ASC';

  //     const newFilter = {
  //       ...salesLocalFilter,
  //       [column]: {
  //         ...salesLocalFilter[column],
  //         columnName: column,
  //         sortBy: newSort
  //       }
  //     };

  //     dispatch(salesActions.onUpdateLocalFilter({ newFilter, column }));
  //   },
  //   [dispatch, salesLocalFilter]
  // );

  const memoizedColumns = useMemo(() => {
    const columnsToBeFixed = cloneDeep(salesColumnsLocal);
    const columnsWithValue = columnsToBeFixed?.map((x) => ({
      ...x,
      // ...salesLocalFilter,
      visible: salesColumnsRedux[x.value]
    }));
    return columnsWithValue;
  }, [salesColumnsRedux]);

  // const isFilterChipVisible = useCallback(
  //   (item: SalesColumnForArrTable) => {
  //     // God I fuckin hate this version of TS. So Dumb
  //     if (salesLocalFilter) {
  //       if (salesLocalFilter[item.value]?.text) {
  //         // @ts-ignore: Object is possibly 'null'. Intended.
  //         if (salesLocalFilter[item.value]?.text?.length > 0) {
  //           return true;
  //         }
  //       }
  //     }
  //     return false;
  //   },
  //   [salesLocalFilter]
  // );

  // const sortByChipLabel = useCallback(
  //   (item: SalesColumnForArrTable) => {
  //     const order = salesLocalFilter[item.value]?.sortBy || '';
  //     if (order === 'ASC') {
  //       return 'Ascending (0 to Z)';
  //     }
  //     if (order === 'DESC') {
  //       return 'Descending (Z to 0)';
  //     }
  //     return '';
  //   },
  //   [salesLocalFilter]
  // );

  return (
    <TableHead>
      <TableRow>
        {memoizedColumns?.map((column, i) => {
          if (column?.visible) {
            return (
              <TableCell
                key={`${i}-${column?.value}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className={classes.mainRow}>
                  {!column?.hideFilter ? (
                    <IconButtonComponent
                      customIcon={<FilterListIcon fontSize={'small'} />}
                      onClick={() => onColumnFilterClick(column.value)}
                    />
                  ) : null}
                  <Typography
                    variant="h6"
                    onClick={() => {}}
                  >
                    {column.name || ''}
                  </Typography>
                </div>
                {/* {isFilterChipVisible(column) ? (
                  <div className={classes.mainRow}>
                    <Chip
                      onClick={() => onColumnFilterClick(column.value)}
                      label={`${salesLocalFilter[column.value]?.text
                        ?.length || 0} filter(s) selected`}
                      style={{ height: '100%' }}
                      size="small"
                      variant="outlined"
                      color="primary"
                    />
                  </div>
                ) : null}
                {salesLocalFilter[column.value]?.sortBy ? (
                  <div className={classes.mainRow}>
                    <Chip
                      onClick={() => onSortByClicked(column.value)}
                      label={sortByChipLabel(column)}
                      style={{ height: '100%' }}
                      size="small"
                      variant="outlined"
                      color="secondary"
                    />
                  </div>
                ) : null} */}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export const SalesTableHeader = React.memo(TableHeader);
