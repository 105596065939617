import { CategoriesEnum } from 'src/enums';
import { Quotation } from 'src/types';

export const initialCompatibleQuotationState: Quotation[] = [
  {
    quantity: 1,
    product: {
      product_id: undefined,
      product_name: undefined,
      retail_price: 0,
      category_id: CategoriesEnum.CPU,
      category_name: 'CPU'
    }
  },
  {
    quantity: 1,
    product: {
      product_id: undefined,
      product_name: undefined,
      retail_price: 0,
      category_id: CategoriesEnum.Motherboard,
      category_name: 'Motherboard'
    }
  },
  {
    quantity: 1,
    product: {
      product_id: undefined,
      product_name: undefined,
      retail_price: 0,
      category_id: CategoriesEnum.RAM,
      category_name: 'RAM'
    }
  },
  {
    quantity: 1,
    product: {
      product_id: undefined,
      product_name: undefined,
      retail_price: 0,
      category_id: CategoriesEnum.GPU,
      category_name: 'GPU'
    }
  },
  {
    quantity: 1,
    product: {
      product_id: undefined,
      product_name: undefined,
      retail_price: 0,
      category_id: CategoriesEnum.SSD,
      category_name: 'SSD'
    }
  },
  {
    quantity: 1,
    product: {
      product_id: undefined,
      product_name: undefined,
      retail_price: 0,
      category_id: CategoriesEnum.HDD,
      category_name: 'HDD'
    }
  },
  {
    quantity: 1,
    product: {
      product_id: undefined,
      product_name: undefined,
      retail_price: 0,
      category_id: CategoriesEnum.PSU,
      category_name: 'PSU'
    }
  },
  {
    quantity: 1,
    product: {
      product_id: undefined,
      product_name: undefined,
      retail_price: 0,
      category_id: CategoriesEnum.Casing,
      category_name: 'Casing'
    }
  },
  // {
  //   quantity: 1,
  //   product: {
  //     product_id: undefined,
  //     product_name: undefined,
  //     retail_price: 0,
  //     category_id: CategoriesEnum.Monitor,
  //     category_name: 'Monitor'
  //   }
  // },
  // {
  //   quantity: 1,
  //   product: {}
  // },
  // {
  //   quantity: 1,
  //   product: {},
  //   custom: true
  // }
];
