import {
  Box,
  Container,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
  Tabs,
  Tab,
  Paper
} from '@material-ui/core';
import { useLocation } from 'react-router';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';

import { usePermissions, useSnackBar } from 'src/hooks';
import { AlertDialog } from 'src/components';
import { Branch, BranchDetails, CommonBranchResponse } from 'src/types/branch';
import { localize } from 'src/constants';
import { phPhoneNumberChecker } from 'src/utils';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import UserBranchList from './UserList';

const { actions: branchActions } = slices.branch;

type ErrData = {
  errName: string;
  errAddress: string;
  errActive: string;
  errContactNO: string;
};

// eslint-disable-next-line no-undef
type BranchData = Partial<BranchDetails>;

export const BranchView = () => {
  const location = useLocation();
  const branch: any = location.state;
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const { canViewBranchUsers } = usePermissions();

  // Default values need for MUI
  const intialErrData: ErrData = {
    errName: '',
    errAddress: '',
    errActive: '',
    errContactNO: ''
  };

  const intialBranchData: BranchData = {
    branch_name: '',
    address: '',
    active: 1,
    contact_no_raw: ''
  };

  const [branchTitle, setBranchTitle] = useState<string>('');
  const [branchData, setBranchData] = useState<BranchData>(intialBranchData);
  const [errData, setErrData] = useState<ErrData>(intialErrData);
  const [deleteBranchDialog, setDeleteBranchDialog] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [contactNumbers, setContactNumber] = useState<string[]>([]);
  const [tabValue, setTabValue] = useState<any>(0);

  const handleFieldChange = (
    e: any,
    branchDataField: string,
    errDataField: string
  ) => {
    const updatedBranchData = {
      ...branchData,
      [branchDataField]: e.target.value
    };

    const errorData = {
      ...errData,
      [errDataField]: ''
    };

    setBranchData(updatedBranchData);
    setErrData(errorData);
  };

  const handleActiveToggle = () => {
    const updatedBranchData = {
      ...branchData
    };

    branchData.active == 1
      ? (updatedBranchData.active = 0)
      : (updatedBranchData.active = 1);

    setBranchData(updatedBranchData);
  };

  const openDialog = () => {
    setDeleteBranchDialog(true);
  };

  const closeDialog = () => {
    setDeleteBranchDialog(false);
  };

  const renderBranchByUrl = () => {
    const branchIDRegex = /(?<=branches\/)\d+/;
    const branchIDFromUrl = location?.pathname?.match(branchIDRegex)?.[0];

    if (branchIDFromUrl) {
      setBranchDetails({ id: parseInt(branchIDFromUrl) });
    } else {
      return;
    }
  };

  // eslint-disable-next-line no-undef
  const renderBranchOnClick = (branch: Partial<Branch>) => {
    setBranchDetails(branch);
  };

  // eslint-disable-next-line no-undef
  const setBranchDetails = async (branch: Partial<Branch>) => {
    const response = (await getBranchDetails(branch)).originalData?.data;

    if (response) {
      const { branch_name, contact_no_raw } = response;
      setBranchData({ ...response });
      setBranchTitle(branch_name);
      setContactNumber(contact_no_raw?.split(',') || []);
    }
  };

  // eslint-disable-next-line no-undef
  const getBranchDetails = async (branch: Partial<Branch>) => {
    return unwrapResult(
      await dispatch(branchActions.getBranchDetailsThunk(branch))
    );
  };

  const updateBranch = async () => {
    const concatenatedNumbers = contactNumbers.join(',');
    if (!branchData.id) {
      return;
    }

    if (!branchData.branch_name) {
      setErrData({ ...errData, errName: 'Insert  Name' });
      return;
    }

    if (!branchData.address) {
      setErrData({ ...errData, errAddress: 'Insert Address' });
      return;
    }

    if (!branchData.contact_no_raw) {
      setErrData({ ...errData, errContactNO: 'Insert Contact Number' });
      return;
    }

    setLoading(true);
    // eslint-disable-next-line no-undef
    const updateBranchResponse: Partial<CommonBranchResponse> = unwrapResult(
      await dispatch(
        branchActions.updateBranchThunk({
          id: branchData.id,
          branch_name: branchData.branch_name,
          address: branchData.address,
          active: String(branchData.active),
          contact_no: concatenatedNumbers
        })
      )
    );

    if (updateBranchResponse?.success) {
      snackBar.show({
        severity: 'success',
        message: updateBranchResponse.message
      });
      setContactNumber([]);
      setBranchDetails(branch);
    } else if (!updateBranchResponse?.success) {
      snackBar.show({
        severity: 'error',
        message: updateBranchResponse?.message
          ? updateBranchResponse.message
          : localize.ERR_API_UNKNOWN
      });
    }
    setLoading(false);
  };

  const deleteBranch = async () => {
    if (!branchData.id) {
      return;
    }
    const deleteBranchResponse = unwrapResult(
      await dispatch(branchActions.branchDeleteThunk(branchData.id))
    );
    if (deleteBranchResponse?.success) {
      navigate(-1);
    } else {
      alert('Failed at branchDeleteThunk');
    }
  };

  const onChangeContactNumber = (
    mobileNumber: string,
    val: any,
    reason: AutocompleteChangeReason
  ) => {
    const isNumberInArray = contactNumbers.includes(mobileNumber);
    if (reason === 'remove-option') {
      setContactNumber(val);
      return;
    }
    if (!phPhoneNumberChecker(mobileNumber)) {
      snackBar.show({
        severity: 'error',
        message:
          ' Please provide a valid PH number compose of 11 digits for mobile or 8 digits for telephone number'
      });
      return;
    }
    if (isNumberInArray) {
      snackBar.show({
        severity: 'error',
        message: 'This number is already added'
      });
      return;
    }
    setContactNumber((prev) => {
      return [...prev, mobileNumber].flat();
    });
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    setLoading(true);
    branch ? renderBranchOnClick(branch) : renderBranchByUrl();
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Container maxWidth="xl">
        <Box mt={5}>
          <Paper>
            <Tabs centered value={tabValue} onChange={handleTabChange}>
              <Tab label="Branch Details" />
              <Tab label="User Assigned On This Branch" />
            </Tabs>
          </Paper>
          <Box>
            {tabValue === 0 && (
              <Box marginY={2}>
                <Card style={{ marginTop: '10px' }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Typography
                        style={{ marginTop: 10 }}
                        color="textPrimary"
                        gutterBottom
                        variant="h2"
                      >
                        {branchTitle}
                      </Typography>
                    </Grid>
                    <Box>
                      <CardContent>
                        <Grid container>
                          <Box width="inherit">
                            <Typography color="textSecondary" variant="h4">
                              Details:
                            </Typography>
                          </Box>
                          <Grid item lg={6} md={6} xs={12}>
                            <Box>
                              <Typography
                                color="textPrimary"
                                variant="h5"
                                display="inline"
                              >
                                Created On:{' '}
                              </Typography>
                              <Typography display="inline">
                                {branchData.created_date}
                              </Typography>
                            </Box>
                            <Box mb={3}>
                              <Typography
                                color="textPrimary"
                                variant="h5"
                                display="inline"
                              >
                                Last Updated:{' '}
                              </Typography>
                              <Typography display="inline">
                                {branchData.created_date}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                        <TextField
                          error={errData.errName === '' ? false : true}
                          helperText={errData.errName}
                          fullWidth
                          required
                          name="branchName"
                          variant="outlined"
                          label="Branch Name"
                          onChange={(e) =>
                            handleFieldChange(e, 'branch_name', 'errName')
                          }
                          value={branchData.branch_name}
                          style={{ marginBottom: '15px' }}
                          disabled={isLoading}
                        />
                        <TextField
                          error={errData.errAddress === '' ? false : true}
                          helperText={errData.errAddress}
                          fullWidth
                          required
                          name="branchAddress"
                          variant="outlined"
                          label="Branch Address"
                          onChange={(e) =>
                            handleFieldChange(e, 'address', 'errAddress')
                          }
                          value={branchData.address}
                          style={{ marginBottom: '15px' }}
                          disabled={isLoading}
                        />
                        <Autocomplete
                          multiple
                          options={[]}
                          id="contact-number"
                          freeSolo
                          value={contactNumbers}
                          onChange={(e: any, val, reason) => {
                            onChangeContactNumber(e.target.value, val, reason);
                          }}
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Add New Contact Number(s)"
                              placeholder="Enter single/multiple number"
                            />
                          )}
                          disabled={isLoading}
                        />

                        <FormControlLabel
                          control={
                            <Switch
                              checked={branchData.active == 1}
                              color="primary"
                              onChange={() => handleActiveToggle()}
                            />
                          }
                          label="Active"
                          disabled={isLoading}
                        />
                      </CardContent>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        paddingX={2}
                        mt={2}
                      >
                        <Button
                          onClick={openDialog}
                          color="secondary"
                          variant="contained"
                        >
                          Delete Branch
                        </Button>

                        <AlertDialog
                          title={`Delete ${branchTitle}`}
                          customButtons={
                            <>
                              <Button
                                onClick={deleteBranch}
                                color="secondary"
                                autoFocus
                              >
                                Delete
                              </Button>
                              <Button onClick={closeDialog} color="primary">
                                Cancel
                              </Button>
                            </>
                          }
                          subTitle={`Are you sure you want to delete ${branchTitle}`}
                          isVisible={deleteBranchDialog}
                        />

                        <Button
                          onClick={updateBranch}
                          color="primary"
                          variant="contained"
                        >
                          Save details
                        </Button>
                      </Box>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            )}
            {canViewBranchUsers
              ? tabValue === 1 && (
                  <Box p={3}>
                    {id ? <UserBranchList branchId={+id} /> : 'no branch id'}
                  </Box>
                )
              : 'You dont have permission to view this page'}
          </Box>
        </Box>
      </Container>
    </div>
  );
};
