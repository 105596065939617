import React from 'react';
import { Page } from 'src/components';
import {
  Container,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import { Toolbar } from './component/Toolbar';
import { AuditLogsListing } from './component/AuditLogsListing';
import { GetInventoryAuditLogsListingsPayload } from 'src/redux/slices/inventory-audit-logs';
import { useInventoryAuditLogs } from 'src/hooks/inventory-audit';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const InventoryAuditLogsView = () => {
  const classes = useStyles();
  const {
    inventoryAuditListing,
    isLoading,
    getInventoryAuditListing
  } = useInventoryAuditLogs();

  const onApplyFilter = (params: GetInventoryAuditLogsListingsPayload) => {
    getInventoryAuditListing(params);
  };

  return (
    <Page title="Inventory Audit Logs" className={classes.root}>
      <Container maxWidth={false}>
        <Typography color="textPrimary" gutterBottom variant="h3">
          Inventory Audit Logs
        </Typography>
        <Toolbar onHandleApplyFilter={onApplyFilter} />
        {isLoading && <LinearProgress />}
        {inventoryAuditListing && inventoryAuditListing?.length > 0 && (
          <AuditLogsListing auditListing={inventoryAuditListing} />
        )}
      </Container>
    </Page>
  );
};

export default InventoryAuditLogsView;
