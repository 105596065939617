import { DrjInfo } from 'src/types';
import { appInformation } from './app';
import { PCWorthBranchesEnum } from 'src/enums/branches';

const drjInfoEarnshaw: DrjInfo = {
  name: 'DRJ TECHNOLOGIES TRADING CORP.',
  subName: 'PC WORTH',
  location: '618 Earnshaw St. Sampaloc Manila',
  telNo: '(02)8 656 0586',
  cellNo: '(+63) 967-701-8178',
  cellNo2: '(+63) 998 594 3037',
  facebook: 'fb.me/PC.WORTH',
  website: 'pcworth.com',
  email: 'inquire@pcworth.com',
  branchId: PCWorthBranchesEnum.Earnshaw
};

const drjInfoAlabang: DrjInfo = {
  name: 'DRJ TECHNOLOGIES TRADING CORP.',
  subName: 'PC WORTH',
  location:
    '2nd Flr. Mega Accent Bldg. 479 Alabang-Zapote Road, Brgy. Almanza Uno, Las Piñas City',
  telNo: '(02)8 292 9044',
  cellNo: '(+63) 931-062-1294',
  cellNo2: '',
  facebook: 'PC WORTH ',
  website: 'PC Worth ',
  email: 'inquire@pcworth.com',
  branchId: PCWorthBranchesEnum.LPC
};

export const selectedDrjInfo = () => {
  switch (appInformation.store_branch) {
    case 'alabang':
      return drjInfoAlabang;
    case 'earnshaw':
      return drjInfoEarnshaw;
    default:
      return drjInfoEarnshaw;
  }
};

export const drjInfo: DrjInfo = selectedDrjInfo();
