import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  SubmitProductInventoryAuditPayload,
  SubmitProductInventoryAuditResponse
} from './types';

export const SubmitProductInventoryAuditThunk = createAsyncThunk<
  CommonAxiosResponse<SubmitProductInventoryAuditResponse>,
  SubmitProductInventoryAuditPayload,
  { state: RootState }
>('audit/SubmitProductInventoryAuditThunk', async (data) => {
  const response = await Api.post(`product/inventory/audit/log/create`, data);
  return response;
});
