import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
    CommonAxiosResponse,
    PcPackageDetailsPayload,
    PcPackageDetailsResponse
} from 'src/types';

export const getPackageDetailsThunk = createAsyncThunk<
    CommonAxiosResponse<PcPackageDetailsResponse>,
    PcPackageDetailsPayload,
    { state: RootState }
>('pc-package/getPackageDetailsThunk', async (data) => {
    const response = await Api.get(`/ecomm/internal/pc_package/details/get/${data?.uuid}`);
    if (!response.success) {
        throw new Error('Failed getPackageDetailList');
    }
    return response;
});
