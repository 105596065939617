import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { LoadingButton } from 'src/components/buttons';
import { CustomKeyboardEvent } from 'src/types';
import { Autocomplete, AutocompleteChangeReason } from '@material-ui/lab';
import { phPhoneNumberChecker } from 'src/utils';
import { useSnackBar } from 'src/hooks';

interface Props {
  title?: string;
  subTitle?: string;
  isVisible: boolean;
  handleClose: () => void;
  onAddPress: (name: string, address: string, contact_no: string) => void;
  loading?: boolean;
  resetForm?: boolean;
}

const BranchFormDialog = ({
  title,
  subTitle,
  isVisible = false,
  handleClose,
  onAddPress,
  loading,
  resetForm
}: Props) => {
  const snackBar = useSnackBar();
  const [branch_name, setBranchName] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [contact_no, setContact_no] = useState<string[]>([]);
  const [errName, setErrName] = useState<string>('');
  const [errAddress, setErrAddress] = useState<string>('');

  const resetState = () => {
    setBranchName('');
    setErrName('');
    setAddress('');
    setErrAddress('');
  };

  const onAddBtnPress = () => {
    const concatenatedNumbers = contact_no.join(',');
    if (loading) {
      return;
    }

    if (!branch_name) {
      setErrName('Insert Name');
      return;
    }

    if (!address) {
      setErrAddress('Insert Address');
      return;
    }

    if (!contact_no) {
      snackBar.show({
        severity: 'error',
        message: ' Please prrovide branch contact number'
      });
    }

    if (onAddPress) {
      onAddPress(branch_name, address, concatenatedNumbers);
    }
    if (resetForm) {
      resetState();
    }
  };

  const onCancelBtnPress = () => {
    resetState();
    handleClose();
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onAddBtnPress();
    }
  };

  const onChangeContactNumber = (
    mobileNumber: string,
    val: any,
    reason: AutocompleteChangeReason
  ) => {
    const isNumberInArray = contact_no.includes(mobileNumber);
    if (reason === 'remove-option') {
      setContact_no(val);
      return;
    }
    if (!phPhoneNumberChecker(mobileNumber)) {
      snackBar.show({
        severity: 'error',
        message:
          ' Please provide a valid PH number compose of 11 digits for mobile or 8 digits for telephone number'
      });
      return;
    }
    if (isNumberInArray) {
      snackBar.show({
        severity: 'error',
        message: 'This number is already added'
      });
      return;
    }
    setContact_no((prev) => {
      return [...prev, mobileNumber].flat();
    });
  };

  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="branch-form-dialog-title"
    >
      <DialogTitle id="branch-form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{subTitle}</DialogContentText>
        <TextField
          error={errName === '' ? false : true}
          helperText={errName}
          autoFocus
          margin="dense"
          id="name"
          label="Branch Name"
          fullWidth
          required
          variant="outlined"
          value={branch_name}
          onKeyPress={onKeyPress}
          onChange={(e) => {
            setErrName('');
            setBranchName(e.target.value);
          }}
        />
        <TextField
          error={errAddress === '' ? false : true}
          helperText={errAddress}
          margin="dense"
          id="address"
          label="Branch Address"
          fullWidth
          required
          variant="outlined"
          value={address}
          onKeyPress={onKeyPress}
          onChange={(e) => {
            setErrAddress('');
            setAddress(e.target.value);
          }}
        />
        <Autocomplete
          style={{ marginTop: '10px' }}
          multiple
          options={[]}
          id="contact_no"
          freeSolo
          value={contact_no}
          onChange={(e: any, val, reason) => {
            onChangeContactNumber(e.target.value, val, reason);
          }}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Contact Number(s)"
              placeholder="Enter single/multiple number"
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton
          title="Add"
          //default to false if loading has no value yet
          loading={loading ? loading : false}
          onClick={onAddBtnPress}
          color="primary"
        />
        <Button onClick={onCancelBtnPress}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default BranchFormDialog;
