import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Typography
} from '@material-ui/core';

import { slices, useAppSelector } from 'src/redux';

import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import { formatCurrency } from 'src/utils';
import { useNavigate } from 'react-router-dom';

import { colors } from 'src/constants';
import { Alert } from '@material-ui/lab';
import { PcPackageListItems } from 'src/types/pc-package';

const { selectors: pcPackageSelectors } = slices.pcPackage;

interface Props {
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  imgPreview: {
    width: undefined,
    height: 50
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  }
}));

const Component = ({ className, ...rest }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const pcPackageList = useAppSelector(pcPackageSelectors.selectPcPackageList);

  const onPackagePress = (item: PcPackageListItems) => {
    navigate(`/app/pc-package/view/${item?.uuid}`, { state: item });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Alert severity="info">
            <Typography variant="subtitle2">
              Will only show the latest 10 for now
            </Typography>
          </Alert>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>UUID</TableCell>
                <TableCell>Package Name</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pcPackageList?.map((item: PcPackageListItems, i: number) => (
                <AlternatingColorTableRow hover key={i}>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onPackagePress(item)}
                      href={`/app/pc-package/view/${item?.uuid}`}
                      title={`${item?.uuid?.split('-')?.[4]}`}
                    ></LinkComponent>
                  </TableCell>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onPackagePress(item)}
                      href={`/app/pc-package/view/${item?.uuid}`}
                      title={`${item?.title}`}
                    ></LinkComponent>
                  </TableCell>
                  <TableCell>{formatCurrency(item?.amount)}</TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        color: item?.published ? colors.success : colors.warning
                      }}
                    >
                      {`${item?.published ? 'published' : 'not published'}`}
                    </Typography>
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const PcPackageTable = React.memo(Component);
