import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  colors,
  Button,
  Typography
} from '@material-ui/core';
import {
  AlternatingColorTableRow,
  ConditionalVisibleCell,
  LinkComponent
} from 'src/components';
import {
  copyToClipboard,
  formatCurrency,
  marginColorViaGross
} from 'src/utils';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import { FastMovingProductsData } from 'src/redux/slices/fast-moving-products';
import { fastMovingProductRowHeader } from 'src/constants/table';

interface Props {
  className?: string;
  columnToHide?: number[];
  fastMovingProducts?: FastMovingProductsData[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 30
  },
  tableCell: {
    border: 'none',
    padding: '1rem'
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  },
  retailPriceCell: {
    width: 200
  }
}));

const component = ({
  className,
  fastMovingProducts,
  columnToHide,
  ...rest
}: Props) => {
  const classes = useStyles();

  const onHandleCopyProductName = (productName?: string) => {
    copyToClipboard(productName || '');
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableRow}>
                {fastMovingProductRowHeader.map(
                  (rowHeader, index) =>
                    //meaning if the cell is not included in array it is visible
                    !columnToHide?.includes(index) && (
                      <TableCell key={index}>{rowHeader}</TableCell>
                    )
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {fastMovingProducts?.map(
                (item: FastMovingProductsData, index) => (
                  <AlternatingColorTableRow hover key={index}>
                    {/* 0, 1, 2, 3, and so on ,must be equal to the index of fastMovingProductRowHeader */}
                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(0)}
                      RenderItem={item?.branches_sold ?? '--'}
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(1)}
                      RenderItem={
                        <Box display={'flex'}>
                          <Button
                            style={{ padding: 0, margin: 0 }}
                            startIcon={<FileCopyIcon />}
                            onClick={() =>
                              onHandleCopyProductName(item?.product_name)
                            }
                          />
                          <Box>
                            <LinkComponent
                              href={`/app/products/${item.product_id}`}
                              title={`${item?.product_name ?? '--'}`}
                            />
                          </Box>
                        </Box>
                      }
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(2)}
                      RenderItem={
                        formatCurrency(item?.last_dealer_price) || '--'
                      }
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(3)}
                      RenderItem={
                        formatCurrency(item?.last_retail_price) || '--'
                      }
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(4)}
                      RenderItem={formatCurrency(item?.gross) || '--'}
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(5)}
                      RenderItem={
                        <Typography
                          style={{
                            color: marginColorViaGross(item?.margin_percentage)
                          }}
                        >
                          {item?.margin_percentage}
                        </Typography>
                      }
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(6)}
                      RenderItem={item?.suppliers}
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(7)}
                      RenderItem={item?.sold_quantity ?? '--'}
                    />

                    <ConditionalVisibleCell
                      isVisible={!columnToHide?.includes(8)}
                      RenderItem={item?.available_stocks}
                    />
                  </AlternatingColorTableRow>
                )
              )}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const FastMovingProductTableList = React.memo(component);
