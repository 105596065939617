import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import { UserAddressState } from 'src/types/pcw-user-address';

const initialState: UserAddressState = {
  addressListData: []
};

const slice = createSlice({
  name: 'pcwUserAddress',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getUserAddressThunk.fulfilled,
      (state, { payload }) => {
        state.addressListData = payload?.originalData?.data || [];
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
