import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import { localize } from 'src/constants';
import { useAppDispatch } from 'src/redux/hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import LocalStorageService from 'src/services/LocalStorageService';
import { FixMeLater, LoginRequest } from 'src/types';
import { useSnackBar } from 'src/hooks';
import { SystemInfo } from 'src/components/app';
import { slices } from 'src/redux';

const { actions: userActions } = slices.user;
const { actions: oauthActions } = slices.oauth;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async (values: FixMeLater) => {
    if (
      window.location.href.indexOf('amplify') > -1 &&
      window.location.href.indexOf('master') > -1
    ) {
      const text = `We will navigate you to the new internal system. Please use the new URL (https://master.d2manj0utt1eau.pcworth.com/) to login to internal system`;
      if (confirm(text) == true) {
        window.location.href = 'https://master.d2manj0utt1eau.pcworth.com/';
      }
      return;
    }

    setIsLoading(true);
    const getOAuthRes = unwrapResult(
      await dispatch(oauthActions.getOAuthTokenThunk())
    );
    if (getOAuthRes?.error) {
      snackBar.show({
        severity: 'error',
        message: getOAuthRes?.error || 'Error getting oauth token'
      });
      setIsLoading(false);
      return;
    }
    const loginData: LoginRequest = {
      username: values.username,
      password: values.password,
      oAuthBearerToken: getOAuthRes?.access_token || ''
    };
    const loginResponse = unwrapResult(
      await dispatch(userActions.login(loginData)).finally(() =>
        setIsLoading(false)
      )
    );
    const isLoggedIn = LocalStorageService.getItem('userInfo');
    if (
      loginResponse.success &&
      loginResponse.originalData?.user &&
      isLoggedIn
    ) {
      navigate('/app/welcome-page', { replace: true });
    } else if (!loginResponse.success && loginResponse.message) {
      snackBar.show({ severity: 'error', message: loginResponse.message });
    } else {
      snackBar.show({ severity: 'error', message: localize.ERR_LOGIN });
    }
  };

  return (
    <Page className={classes.root} title="Login">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .max(255)
                .required('Username is required'),
              password: Yup.string()
                .max(255)
                .required('Password is required')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3}>
                  <Typography color="textPrimary" variant="h2">
                    Sign in
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Sign in on the internal platform
                  </Typography>
                  <SystemInfo />
                </Box>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Sign in
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <LoaderBar isLoading={isLoading} />
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
