import { appClientCredentials, appInformation, appUrls } from 'src/constants';
import { GetOAuthTokenResponse } from 'src/types';

const getOAuthToken = async () => {
  const baseUrl = appUrls.oauthUrl ||  '';
  try {
    const response = await fetch(baseUrl, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        AppName: appInformation?.name || '',
        AppVersion: appInformation?.version || ''
      },
      body: JSON.stringify(appClientCredentials)
    });
    if (response.ok) {
      const jsonRes: GetOAuthTokenResponse = await response.json();
      return jsonRes;
    } else {
      const responseNotOk: GetOAuthTokenResponse = {
        error: 'response is not ok'
      };
      return responseNotOk;
    }
  } catch (error) {
    const responseCatch: GetOAuthTokenResponse = {
      error: 'response got some error on catch'
    };
    return responseCatch;
  }
};

export default {
  getOAuthToken
};
