import { useCallback, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';

import { Branch } from 'src/types/branch';

const { actions: branchActions, selectors: branchSelectors } = slices.branch;

export const useBranchInfo = () => {
  const dispatch = useAppDispatch();
  const branches = useAppSelector(branchSelectors.selectBranchesList);

  const [selectedBranch, setSelectedBranch] = useState<Branch | null>(null);
  const [selectedBranches, setSelectedBranches] = useState<Branch[]>([]);
  const [selectedBranchIDs, setSelectedBranchIDs] = useState<number[]>([]);
  const [branchInputValue, setBranchInputValue] = useState<string>('');

  const getBranches = useCallback(async (keyword: string = '') => {
    await dispatch(branchActions.getBranchesThunk({ keyword }));
  },[dispatch])

    // eslint-disable-next-line no-undef
    const getBranchDetails = async (branch: Partial<Branch>) => {
      return unwrapResult(
        await dispatch(branchActions.getBranchDetailsThunk(branch))
      );
    };

  return {
    branches,
    selectedBranch,
    setSelectedBranch,
    selectedBranchIDs,
    setSelectedBranchIDs,
    branchInputValue,
    setBranchInputValue,
    getBranches,
    selectedBranches,
    setSelectedBranches,
    getBranchDetails
  };
};
