import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectSales = (state: RootState) => state.sms;

const selectMessage = createSelector(selectSales, (state) => state.message);
const selectMessageLength = createSelector(
  selectSales,
  (state) => state.message?.length || 0
);

const selectSendSMSIsLoading = createSelector(
  selectSales,
  (state) => state.isLoading || false
);

const selectCustomMobileNos = createSelector(
  selectSales,
  (state) => state.customMobileNos
);

const selectExcludedMobileNos = createSelector(
  selectSales,
  (state) => state.excludedMobileNos
);

const selectDeliveryDate = createSelector(
  selectSales,
  (state) => state.deliveryDate
);

export const selectors = {
  selectMessage,
  selectMessageLength,
  selectSendSMSIsLoading,
  selectCustomMobileNos,
  selectExcludedMobileNos,
  selectDeliveryDate
};
