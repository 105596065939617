import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  GalleryGetRequest,
  GalleryGetResponse
} from 'src/types';

export const getGalleryListThunk = createAsyncThunk<
  CommonAxiosResponse<GalleryGetResponse>,
  GalleryGetRequest,
  { state: RootState }
>('gallery/getGalleryListThunk', async (data, api) => {
  const keyword =
    api?.getState()?.gallery?.getGalleryListRequest?.keyword || '';
  data = {
    sort: {
      field: 'created_at',
      direction: 'desc',
      amount: ''
    },
    filter: {
      cpu: '',
      build_tier: ''
    },
    page: 1,
    limit: 50,
    ...data
  };

  const response = await Api.post('/gallery/get', { ...data, keyword });
  return response;
});
