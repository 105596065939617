import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import { Page } from 'src/components';
import { CustomKeyboardEvent } from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackBar } from 'src/hooks';

const { actions: manufacturerActions } = slices.manufacturer;

const useStyles = makeStyles(() => ({
  root: {}
}));

// page unused boss dabs mukhang dito ata sana mag redirect upon clicking add manufacturer on manufacturers page
// pero instead may modal na nalabas pag pinindot yon. if ok dedelete ko to
const ManufacturersAddView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [manufacturerName, setManufacturerName] = useState('');

  const onClearNameField = () => {
    setManufacturerName('');
  };

  const onCreateManufacturer = async () => {
    if (manufacturerName) {
      const newManufacturerData = { name: manufacturerName };
      const response = unwrapResult(
        await dispatch(
          manufacturerActions.createManufacturerThunk(newManufacturerData)
        )
      );

      if (response.success) {
        snackBar.show({ severity: 'success', message: response.message });
        onClearNameField();
        // TODO: Add table of recently added manufacturers.
        // onAddRecentProduct(response.originalData.products);
      } else {
        snackBar.show({ severity: 'error', message: response.message });
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.'
      });
    }
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      setManufacturerName('');
    }
  };

  return (
    <Page className={classes.root} title="New manufacturer">
      <Container maxWidth={false}>
        <Box mt={2}>
          <Card>
            <CardHeader
              title="New Manufacturer"
              subheader="The information of new manufacturer."
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3}>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    label="Name"
                    name="name"
                    value={manufacturerName}
                    required
                    onKeyPress={(e) => onKeyPress(e)}
                    onChange={(e) => setManufacturerName(e.target.value)}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="space-between" p={2}>
              <Button
                startIcon={<ClearIcon />}
                onClick={onClearNameField}
                color="secondary"
                variant="contained"
              >
                Clear
              </Button>
              <Button
                startIcon={<SaveIcon />}
                style={{ marginLeft: 18 }}
                onClick={onCreateManufacturer}
                color="primary"
                variant="contained"
              >
                Add new manufacturer
              </Button>
            </Box>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default ManufacturersAddView;
