import React, { useCallback, useMemo } from 'react';
import { TextField, makeStyles, Chip } from '@material-ui/core';

import { useProductPartDetailsLogic } from 'src/hooks';
import { PartFields, ProductPartDetailsCategory } from 'src/types';
import { colors } from 'src/constants';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  productCategory: ProductPartDetailsCategory;
  field: PartFields<any>;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  uploadImageField: {
    borderWidth: 1,
    borderColor: colors.grey[800]
  }
}));

const component = ({ productCategory, field }: Props) => {
  const classes = useStyles();
  const {
    fieldError,
    setProductPartDetailsPropertyViaKey,
    getProductPartDetailsViaKey
  } = useProductPartDetailsLogic();

  const fieldErr = useCallback(
    (fieldArg) => {
      return fieldError(productCategory, fieldArg) ? true : false;
    },
    [fieldError, productCategory]
  );

  const fieldValue: string[] = useMemo(() => {
    const currentData = getProductPartDetailsViaKey(productCategory);
    const valueInStr: string = currentData[field?.part_property];
    let valueInArr = valueInStr?.split(',');
    valueInArr = valueInArr?.map((x) =>
      x
        ?.trim()
        ?.toUpperCase()
        ?.replace(/\s+/g, '')
    );
    return valueInArr || [];
  }, [field.part_property, getProductPartDetailsViaKey, productCategory]);

  const onChange = (newValue: string[]) => {
    let newValuesArr = newValue || [];
    // Trim spaces please
    newValuesArr = newValuesArr?.map((x) => x?.trim());

    if (field?.transformRule?.includes('all-caps')) {
      newValuesArr = newValuesArr?.map((x) => x?.toUpperCase());
    }
    if (field?.transformRule?.includes('no-spaces')) {
      newValuesArr = newValuesArr?.map((x) => x?.replace(/\s+/g, ''));
    }
    const newValuesStr = newValuesArr?.join() || null;
    setProductPartDetailsPropertyViaKey(
      productCategory,
      field?.part_property,
      newValuesStr
    );
  };

  return (
    <div className={classes.uploadImageField}>
      <Autocomplete
        multiple
        id="tags-filled"
        options={field?.options || []}
        freeSolo
        value={fieldValue || []}
        // Intended any
        renderTags={(value: any, getTagProps) =>
          value.map((option: string, index: number) => (
            <Chip
              key={`${option}${index}`}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        // Intended any
        onChange={(_, newValue: any) => onChange(newValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            label={field?.label}
            name={field?.part_property}
            required={field?.required}
            error={fieldErr(field?.part_property)}
            type={field?.type}
            helperText={
              fieldError(productCategory, field?.part_property)?.error ||
              field?.hint
            }
          />
        )}
      />
    </div>
  );
};

export const ProductPartMultipleField = React.memo(component);
