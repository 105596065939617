import React, { FC, memo } from 'react';
import {
  Box,
  Button,
  Paper,
  Slide,
  Snackbar,
  Typography
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface Props {
  notificationMessage?: string;
  isOpen: boolean;
  redirectLink?: string;
  onHandleClose: () => void;
}

const component: FC<Props> = ({
  isOpen,
  notificationMessage,
  redirectLink,
  onHandleClose
}) => {
  const onHandleRedirectToEcommOrder = () => {
    window.location.href = redirectLink || '';
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      //added if there is scenarios of multiple orders
      autoHideDuration={5000}
      open={isOpen}
      onClose={onHandleClose}
    >
      <Slide direction="down" in={isOpen}>
        <Paper style={{ padding: '0.5rem' }} elevation={1}>
          <Alert severity="info" style={{ padding: '1rem' }}>
            <Typography style={{ fontWeight: 700 }}>
              {notificationMessage}
            </Typography>
            <Box
              sx={{
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'flex-end'
              }}
            >
              <Button
                color="inherit"
                size="small"
                onClick={onHandleRedirectToEcommOrder}
                variant="outlined"
                style={{ marginRight: '10px' }}
              >
                Go To Ecomm-Orders
              </Button>
            </Box>
          </Alert>
        </Paper>
      </Slide>
    </Snackbar>
  );
};

export const SomeonePaidDialog = memo(component);
