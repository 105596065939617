import React from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Box, Card, Paper, Typography, makeStyles } from '@material-ui/core';
import { Category, FixMeLaterApi } from 'src/types';

interface Props {
  categories: FixMeLaterApi;
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  paperStyle: {
    margin: '0.5rem',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    cursor: 'pointer',
    '&:hover': { background: '#F5F5F5' }
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: 0
  }
}));

const Categories = ({ className, categories, ...rest }: Props) => {
  const navigate = useNavigate();
  const classes = useStyles();

  const onCategoryPress = (item: FixMeLaterApi) => {
    navigate(`/app/categories/${item.id}`, { state: item });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box>
          {categories.map((item: Category) => (
            <Paper className={classes.paperStyle} key={item.id}>
              <Box className={classes.flexRow}>
                <Box
                  className={classes.flexRow}
                  style={{
                    justifyContent: 'flex-start',
                    width: '100%',
                    padding: '0.5rem'
                  }}
                  onClick={() => onCategoryPress(item)}
                >
                  <img
                    alt="sub-category-img"
                    style={{
                      width: '60px',
                      height: '60px',
                      marginRight: '1rem'
                    }}
                    src={
                      item.img_url ||
                      '/static/images/products/product_not_found.png'
                    }
                  />
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      flexDirection: 'column'
                    }}
                  >
                    <Typography>{item?.name}</Typography>
                    {item?.main_category ? (
                      <Typography variant="caption">
                        {`Main Category: ${item?.main_category}`}
                      </Typography>
                    ) : (
                      <Typography variant="caption" color="error">
                        {`Not Assigned to a Main Category`}
                      </Typography>
                    )}
                  </Box>
                </Box>
              </Box>
            </Paper>
          ))}
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default Categories;
