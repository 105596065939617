import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Paper,
  Tab,
  Tabs,
  makeStyles
} from '@material-ui/core';
import { Page } from 'src/components';
import Toolbar from './Toolbar';
import { LoaderBar } from 'src/components';
import { GetCategoryMainData, SubCategoryListData } from 'src/types';
import MainCategories from './MainCategory';
import { useMainCategory } from 'src/hooks/category';
import { SubCategoryModal } from './SubCategory/SubCategoryModal';
import Categories from './Categories';
import { Pagination } from '@material-ui/lab';
import { UpdateMainCategoryModal } from './UpdateMainCategory';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const ProductList = () => {
  const classes = useStyles();
  const {
    isLoading,
    mainCategoryList,
    subCategoryList,
    metaSubCategory,
    getMainCategory,
    getSubCategories
  } = useMainCategory();

  const [openSubCategory, setOpenSubCategory] = useState<boolean>(false);
  const [mainCategoryName, setMainCategoryName] = useState<string>('');
  const [subCategoryData, setSubCategoryData] = useState<SubCategoryListData[]>(
    []
  );
  const [mainCategoryPayload, setMainCategoryPayload] = useState<
    GetCategoryMainData
  >();
  const [tabValue, setTabValue] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentSearchKeyword, setCurrentSearchKeyword] = useState<string>();
  const [isOpenUpdateDialog, setIsOpenUpdateDialog] = useState<boolean>(false);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabValue(newValue);
    if (newValue === 1) {
      //call sub categories
      getSubCategories('', 1);
      setCurrentPage(1);
    }
    if (newValue === 0) {
      getMainCategory();
    }
  };

  const onSearchCategory = (keyword?: string) => {
    getSubCategories(keyword || '', 1);
    setCurrentPage(1);
    setCurrentSearchKeyword(keyword);
  };

  const onSearchMainCategory = (keyword?: string) => {
    getMainCategory(keyword || '');
  };

  const onHandlePagechange = (event: React.ChangeEvent<any>, value: number) => {
    setCurrentPage(value);
    getSubCategories(currentSearchKeyword || '', value);
  };

  const onCategoryRefetch = () => {
    getMainCategory();
    getSubCategories('', 1);
    setCurrentPage(1);
  };

  useEffect(() => {
    getMainCategory();
  }, [getMainCategory]);

  return (
    <Page className={classes.root} title="Categories">
      <Container maxWidth={false}>
        <Toolbar
          //action can be use for main category or sub search
          action={tabValue === 0 ? 'main' : 'sub'}
          onSearchCategory={(keyword?: string) => onSearchCategory(keyword)}
          onSearchMainCategory={(keyword?: string) =>
            onSearchMainCategory(keyword || '')
          }
          onCategoryRefetch={onCategoryRefetch}
        />
        <Paper>
          <Tabs centered value={tabValue} onChange={handleTabChange}>
            <Tab label="Main Categories" />
            <Tab label="Sub Categories" />
          </Tabs>
        </Paper>
        {tabValue === 0 && (
          <Box mt={2}>
            <LoaderBar isLoading={isLoading} />
            <MainCategories
              mainCategories={mainCategoryList}
              onHandleMainCategoryName={(name: string) =>
                setMainCategoryName(name)
              }
              onHandleGetSubCategory={(subCategory: SubCategoryListData[]) =>
                setSubCategoryData(subCategory)
              }
              onHandleShowSubCategory={() =>
                setOpenSubCategory(!openSubCategory)
              }
              onHandleMainCategoryPayload={setMainCategoryPayload}
              onUpdateToggleDialog={() =>
                setIsOpenUpdateDialog(!isOpenUpdateDialog)
              }
              onCategoryRefetch={onCategoryRefetch}
            />
          </Box>
        )}
        {tabValue === 1 && (
          <>
            <Box mt={2}>
              <LoaderBar isLoading={isLoading} />
              <Categories categories={subCategoryList} />
            </Box>

            <Box
              sx={{ mt: '1rem', display: 'flex', justifyContent: 'flex-end' }}
            >
              <Pagination
                count={metaSubCategory?.last_page}
                page={currentPage}
                onChange={onHandlePagechange}
              />
            </Box>
          </>
        )}
      </Container>

      <SubCategoryModal
        isOpen={openSubCategory}
        mainCategoryName={mainCategoryName}
        subCategoryData={subCategoryData}
        onHandleClose={() => setOpenSubCategory(!openSubCategory)}
        onCategoryRefetch={onCategoryRefetch}
      />
      <UpdateMainCategoryModal
        isOpenUpdateDialog={isOpenUpdateDialog}
        mainCategoryDetails={mainCategoryPayload || {}}
        onToggleDialog={() => setIsOpenUpdateDialog(!isOpenUpdateDialog)}
        onCategoryRefetch={onCategoryRefetch}
      />
    </Page>
  );
};

export default ProductList;
