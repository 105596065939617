import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
    CommonAxiosResponse,
    CommonCustomerServiceResponse,
    CreateCustomerServicePayload
} from 'src/types';

export const createCustomerServiceThunk = createAsyncThunk<
    CommonAxiosResponse<CommonCustomerServiceResponse>,
    CreateCustomerServicePayload,
    { state: RootState }
>('customer/createCustomerServiceThunk', async (data) => {
    const response = await Api.post('/customers', data);
    return response;
});