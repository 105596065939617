import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  EcomOrderGetViaOrderRefResponse
} from 'src/types';

export const ecomOrderGetDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<EcomOrderGetViaOrderRefResponse>,
  string,
  { state: RootState }
>('ecom/ecomOrderGetDetailsThunk', async (orderRefNo) => {
  const response = await Api.get(`/ecomm/internal/order/get/${orderRefNo}`);
  return response;
});
