import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Container,
  LinearProgress,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Page } from 'src/components';
import { useFastMovingProducts } from 'src/hooks/fast-moving-products';
import {
  FastMovingProductSubToolbar,
  FastMovingProductTableList,
  FastMovingProductToolBar
} from './components';
import useUserInfo from 'src/hooks/user/use-user-info';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

export const FastMovingStockView = () => {
  const classes = useStyles();
  const { userBranchOptions } = useUserInfo();
  const {
    isLoading,
    fastMovingProdParams,
    fastMovingProductData,
    setFastMovingProdParams,
    getFastMovingProducts
  } = useFastMovingProducts();

  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [isCategoryRequire, setIsCategoryRequire] = useState<boolean>(false);
  const [isDateRangeRequire, setIsDateRangeRequire] = useState<boolean>(false);
  const [columnToHide, setColumnToHide] = useState<number[]>([]);

  const defaultValueBranch = useMemo(() => {
    let branchIds: number[] = [];

    branchIds = userBranchOptions.map((branch) => branch?.value);
    return branchIds;
  }, [userBranchOptions]);

  const onApplyFilterPress = useCallback(() => {
    if (!fastMovingProdParams.category_id) {
      setIsCategoryRequire(true);
      return;
    }

    if (
      !fastMovingProdParams?.date_sold_from ||
      !fastMovingProdParams?.date_sold_to
    ) {
      setIsDateRangeRequire(true);
      return;
    }

    getFastMovingProducts(fastMovingProdParams);
  }, [fastMovingProdParams, getFastMovingProducts]);

  const handleErrorClose = () => {
    setIsCategoryRequire(false);
    setIsDateRangeRequire(false);
  };

  //has default branch for multiple selection
  useEffect(() => {
    setFastMovingProdParams((prev) => ({
      ...prev,
      keyword: searchKeyword || undefined,
      branch_ids: prev.branch_ids || defaultValueBranch
    }));
  }, [defaultValueBranch, searchKeyword, setFastMovingProdParams]);

  return (
    <Page title="Fast Moving Products" className={classes.root}>
      <Container maxWidth={false}>
        <Typography color="textPrimary" gutterBottom variant="h3">
          Fast Moving Products
        </Typography>

        <FastMovingProductToolBar
          loadingData={isLoading}
          initialBranchIds={defaultValueBranch}
          onApplyFilter={onApplyFilterPress}
          categoryRequire={isCategoryRequire}
          dateRangeRequire={isDateRangeRequire}
          onHandleBranch={(branch_ids?: number[]) =>
            setFastMovingProdParams((prev) => ({ ...prev, branch_ids }))
          }
          onHandleSearchKeyword={(keyword?: string) =>
            setSearchKeyword(keyword)
          }
          onHandleCategoryId={(category_id?: number) =>
            setFastMovingProdParams((prev) => ({ ...prev, category_id }))
          }
          onHandleDateFrom={(date_sold_from?: string | undefined) =>
            setFastMovingProdParams((prev) => ({ ...prev, date_sold_from }))
          }
          onHandleDateTo={(date_sold_to?: string | undefined) =>
            setFastMovingProdParams((prev) => ({ ...prev, date_sold_to }))
          }
          onHandleErrorClose={handleErrorClose}
        />

        {isLoading && <LinearProgress />}
        {!fastMovingProductData && (
          <Alert severity="info" style={{ marginTop: '1rem' }}>
            <Typography variant="subtitle2">
              Select category and date sold range to load fast moving products
            </Typography>
          </Alert>
        )}
        {fastMovingProductData && fastMovingProductData?.length > 0 && (
          <>
            <FastMovingProductSubToolbar
              columnIndicesToHide={columnToHide}
              fastMovingProductToCsv={fastMovingProductData}
              columnToHide={(indices: number[]) => setColumnToHide(indices)}
            />
            <FastMovingProductTableList
              columnToHide={columnToHide}
              fastMovingProducts={fastMovingProductData || []}
            />
          </>
        )}
      </Container>
    </Page>
  );
};
