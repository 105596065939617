import { cloneDeep } from 'lodash';
import { Transaction } from 'src/types';
import { initialCategory } from 'src/views/quotation/constants';

//We use transaction type here but only category name or id is important
export const sortProductsByCategory = (products: Transaction[]) => {
  //TODO: add category id here if needed
  const clonedProducts = cloneDeep(products);
  clonedProducts.sort((a, b) => {
    const indexA = initialCategory.findIndex(
      (initCategory) => initCategory.category_name === a.category_name
    );
    const indexB = initialCategory.findIndex(
      (initCategory) => initCategory.category_name === b.category_name
    );
    // If the category_name is not found in initialCategory,
    // sort those items at the end of the list.
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;
    return indexA - indexB;
  });

  return clonedProducts;
};
