import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { Editor } from '@tiptap/react';
import { Box, Button, ButtonGroup, makeStyles } from '@material-ui/core';

import FormatBoldIcon from '@material-ui/icons/FormatBold';
import FormatItalicIcon from '@material-ui/icons/FormatItalic';
import StrikethroughSIcon from '@material-ui/icons/StrikethroughS';
import FormatUnderlinedIcon from '@material-ui/icons/FormatUnderlined';
import LinkIcon from '@material-ui/icons/Link';
import LinkOffIcon from '@material-ui/icons/LinkOff';
import CodeIcon from '@material-ui/icons/Code';

const useStyles = makeStyles(() => ({
  buttons: {
    padding: 3
  }
}));

interface Props {
  editor?: Editor | null;
}

const component: FC<Props> = ({ editor }) => {
  const classes = useStyles();

  const [isBold, setIsBold] = useState<boolean>(false);
  const [isItalic, setIsItalic] = useState<boolean>(false);
  const [isStrike, setIsStrike] = useState<boolean>(false);
  const [isUnderLine, setIsUnderLine] = useState<boolean>(false);
  const [isLink, setIsLink] = useState<boolean>(false);
  const [isCode, setIsCode] = useState<boolean>(false);

  if (!editor) {
    return null;
  }

  //for checking active state
  useEffect(() => {
    if (!editor) {
      return;
    }
    //for event listener to set active state
    editor.on('transaction', ({ editor }) => {
      setIsBold(editor.isActive('bold'));
      setIsItalic(editor.isActive('italic'));
      setIsStrike(editor.isActive('strike'));
      setIsUnderLine(editor.isActive('underline'));
      setIsLink(editor.isActive('link'));
      setIsCode(editor.isActive('code'));
    });
  }, [editor]);

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt(
      'Enter URL to be set on the highlighted text',
      previousUrl
    );

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .unsetLink()
        .run();

      return;
    }

    // update link
    editor
      .chain()
      .focus()
      .extendMarkRange('link')
      .setLink({ href: url })
      .run();
  }, [editor]);

  return (
    <Box display={'flex'} flexDirection={'row'} width={'100%'}>
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
      >
        <Button
          className={classes.buttons}
          onClick={() =>
            editor
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .toggleBold()
              .run()
          }
          variant={isBold ? 'contained' : 'outlined'}
          color="primary"
        >
          <FormatBoldIcon />
        </Button>
        <Button
          className={classes.buttons}
          onClick={() =>
            editor
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .toggleItalic()
              .run()
          }
          variant={isItalic ? 'contained' : 'outlined'}
          color="primary"
        >
          <FormatItalicIcon />
        </Button>
        <Button
          onClick={() =>
            editor
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .toggleStrike()
              .run()
          }
          variant={isStrike ? 'contained' : 'outlined'}
          color="primary"
        >
          <StrikethroughSIcon />
        </Button>
        <Button
          className={classes.buttons}
          onClick={() =>
            editor
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .toggleUnderline()
              .run()
          }
          variant={isUnderLine ? 'contained' : 'outlined'}
          color="primary"
        >
          <FormatUnderlinedIcon />
        </Button>

        <Button
          className={classes.buttons}
          onClick={() => setLink()}
          variant={isLink ? 'contained' : 'outlined'}
          color="primary"
        >
          <LinkIcon />
        </Button>
        <Button
          className={classes.buttons}
          onClick={() => {
            {
              editor
                .chain()
                .focus()
                .unsetLink()
                .run();
            }
          }}
          disabled={!isLink}
          color="primary"
        >
          <LinkOffIcon />
        </Button>
        <Button
          className={classes.buttons}
          onClick={() =>
            editor
              .chain()
              .focus()
              .toggleCode()
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .toggleCode()
              .run()
          }
          variant={isCode ? 'contained' : 'outlined'}
          color="primary"
        >
          <CodeIcon />
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export const TextStyleOptions = memo(component);
