// import { pcWorthLatLng } from 'src/constants';

export const generateGoogleMapUrl = (lat: string, lng: string) => {
  // const origin = `${pcWorthLatLng?.lat},${pcWorthLatLng?.lng}`;
  const destination = `${lat},${lng}`;

  // const mapUrl = `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}`;
  const mapUrl = `https://www.google.com/maps/search/?api=1&query=${destination}`;
  return mapUrl;
};
