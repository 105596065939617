import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { colors } from 'src/constants';

interface Props {
  value?: number;
}

const StyledBorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
  },
  bar: {
    borderRadius: 5,
    backgroundColor: colors.primary
  }
}))(LinearProgress);

export const BorderLinearProgress = ({ value = 100 }: Props) => {
  return <StyledBorderLinearProgress variant="determinate" value={value} />;
};
