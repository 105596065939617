import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';

interface Props {
  label?: string;
  checked?: boolean;
  disabled?: boolean;
  onChange: (value: boolean) => void;
}

export const CheckBoxLabel = ({
  checked,
  label = 'label',
  onChange,
  disabled = false
}: Props) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={disabled}
          color="primary"
          name="checkBox"
          checked={checked}
          onChange={(e, val) => onChange(val)}
        />
      }
      label={label}
    />
  );
};
