import {useCallback, useState} from 'react';

/**
 * Easily add or remove an array item.
 *
 * Works only for array of primitive types (for now) `number`, `string`, `boolean`.
 */
export const useToggleArrayState = <TState>(
  initialState: TState[],
): [
  TState[],
  (next: TState) => void,
  () => void,
  (items: TState[]) => void,
] => {
  const [state, setState] = useState<TState[]>(initialState);

  const toggleArrayItem = useCallback((next: TState) => {
    setState(prev => {
      return prev.includes(next)
        ? prev.filter(p => p !== next)
        : [...prev, next];
    });
  }, []);

  const reset = () => setState([]);

  const toggleCustomItems = useCallback((items: TState[]) => {
    setState(items);
  }, []);

  return [state, toggleArrayItem, reset, toggleCustomItems];
};
