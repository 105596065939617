import React, { FC, memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Paper, makeStyles } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import { SearchKeyword } from 'src/components/filters';
import { usePermissions } from 'src/hooks';
import { useBlog } from 'src/hooks/blogs/use-blog';

const useStyles = makeStyles(() => ({
  toolbar: {
    padding: '1rem',
    width: '100%'
  },
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
}));

interface Props {}

const Component: FC<Props> = () => {
  const classes = useStyles();
  const { getBlogList } = useBlog();
  const permission = usePermissions();
  const navigate = useNavigate();

  const [searchBlog, setSearchBlog] = useState<string | undefined>('');

  const onNavigateCreateBlog = () => {
    navigate('/app/blog/create');
  };

  const onChangeSearchBlog = (keyword?: string) => {
    setSearchBlog(keyword);
  };

  const onHandleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      getBlogList({ keyword: searchBlog });
    }
  };

  return (
    <Paper className={classes.toolbar}>
      <Box className={classes.flexBox}>
        <Box width={'30%'}>
          <div onKeyDown={(event) => onHandleKeyDown(event)}>
            <SearchKeyword
              searchLabel={`Search Blog`}
              onHandleSearchKeyword={(keyword: string) =>
                onChangeSearchBlog(keyword)
              }
            />
          </div>
        </Box>
        {permission.canCreateBlog ? (
          <Button
            endIcon={<CreateIcon />}
            variant="outlined"
            onClick={onNavigateCreateBlog}
          >
            Create Blog
          </Button>
        ) : (
          //Intended empty
          <></>
        )}
      </Box>
    </Paper>
  );
};

export const BlogToolbar = memo(Component);
