import { ProductTypeEnum } from 'src/enums';
import { ProductType } from 'src/types';

export const productTypeOptions: ProductType[] = [
  {
    id: ProductTypeEnum.Serialized,
    value: ProductTypeEnum.Serialized,
    name: 'Serialized (GPU, CPU, RAM etc)'
  },
  // {
  //   id: ProductTypeEnum.NonSerialized,
  //   value: ProductTypeEnum.NonSerialized,
  //   name: 'Non-Serialized (Fans, Accessories etc)'
  // },
  {
    id: ProductTypeEnum.Consumable,
    value: ProductTypeEnum.Consumable,
    name: 'Consumable (Cables, RJ45 etc)'
  }
];
