import React, { FC, memo } from 'react';
import { Popover } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

interface Props {
  severity: 'info' | 'error' | 'success' | 'warning';
  textInfo?: string;
  isOpen: boolean;
  anchorRef: any;
  onHandleClose: () => void;
}

const component: FC<Props> = ({
  severity,
  textInfo,
  isOpen,
  anchorRef,
  onHandleClose
}) => {
  return (
    <Popover
      open={isOpen}
      anchorEl={anchorRef}
      onClose={onHandleClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
    >
      <Alert severity={severity}>{textInfo || 'A notification message'}</Alert>
    </Popover>
  );
};

export const PopoverNotification = memo(component);
