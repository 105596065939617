import React, { useMemo, useState } from 'react';
import { Card, Collapse, Divider } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import { Alert } from '@material-ui/lab';

import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { CardHeaderButton } from 'src/components';
import { MobileNosInput } from './MobileNosInput';

const { selectors: smsSelectors, actions: smsActions } = slices.sms;

export const AdvanceSettings: React.FC = () => {
  const dispatch = useAppDispatch();
  const customMobileNos = useAppSelector(smsSelectors.selectCustomMobileNos);
  const excludedMobileNos = useAppSelector(
    smsSelectors.selectExcludedMobileNos
  );
  const [expand, setExpand] = useState<boolean>(false);

  const isCustomMobileNosFieldDisabled = useMemo(
    () => (excludedMobileNos || [])?.length > 0,
    [excludedMobileNos]
  );

  const isExcludedMobileNosFieldDisabled = useMemo(
    () => (customMobileNos || [])?.length > 0,
    [customMobileNos]
  );

  return (
    <div>
      <Card style={{ marginTop: 20, marginBottom: 20 }}>
        <CardHeaderButton
          icon={expand ? <ExpandLess /> : <ExpandMoreIcon />}
          title="Advance Settings"
          onClick={() => setExpand((s) => !s)}
        />
        <Divider />
      </Card>

      <Collapse in={expand}>
        <Alert severity="warning">
          Adding CSV file will replace the current phone number list.
        </Alert>
        <Alert severity="info">
          You can only choose to include OR exclude mobile no(s).
        </Alert>

        {isCustomMobileNosFieldDisabled ? null : (
          <MobileNosInput
            value={customMobileNos}
            title="Include custom mobile nos."
            subtitle="Enter mobile no(s). or upload a CSV"
            onChange={(val) =>
              dispatch(smsActions.onChangeCustomMobileNos(val))
            }
            disabled={isCustomMobileNosFieldDisabled}
          />
        )}

        {isExcludedMobileNosFieldDisabled ? null : (
          <MobileNosInput
            value={excludedMobileNos}
            title="Exclude custom mobile nos."
            subtitle="Skip mobile no(s)."
            onChange={(val) =>
              dispatch(smsActions.onChangeExcludedMobileNos(val))
            }
            disabled={isExcludedMobileNosFieldDisabled}
          />
        )}
      </Collapse>
    </div>
  );
};
