import React from 'react';
import { makeStyles, Fab } from '@material-ui/core';
import { theme } from 'src/theme';
import RefreshIcon from '@material-ui/icons/Refresh';

interface Props {
  onRefresh: () => void;
}

const useStyles = makeStyles(
  {
    fab: {
      position: 'fixed',
      bottom: theme.spacing(5),
      right: 10
    },
  },
  { index: 999 }
);

const component = ({ onRefresh }: Props) => {
  const classes = useStyles();

  return (
    <Fab
      size="large"
      color="primary"
      variant="extended"
      className={classes.fab}
      onClick={onRefresh}
    >
      <RefreshIcon />
      Refresh
    </Fab>
  );
};

export const RefreshFABButton = React.memo(component);
