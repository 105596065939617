import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import { CreateListingProduct, Product } from 'src/types';
import { formatCurrency, formatDate } from 'src/utils';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { ProductTypeEnum } from 'src/enums';
import { Alert } from '@material-ui/lab';
import { multiBranchFeat } from 'src/constants/feature-toggle';

interface TemporaryListingsProps {
  product?: Product;
  subHeader?: string;
  recentListings: CreateListingProduct[];
  onPressDeleteRecentItem: (i: number) => void;
}

const useStyles = makeStyles((theme) => ({
  consumableAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}));

const Listings = ({
  recentListings,
  onPressDeleteRecentItem,
  product,
  subHeader
}: TemporaryListingsProps) => {
  const classes = useStyles();

  const isProductConsumable = useMemo(
    () => product?.product_type === ProductTypeEnum.Consumable,
    [product]
  );

  // TODO: Replace once TS is upgraded
  const itemQty = useCallback((param: number | undefined) => param || 0, []);

  const tempListingsLength = useMemo(() => {
    if (recentListings?.length) {
      if (isProductConsumable) {
        const totalConsumableQty =
          recentListings?.reduce(
            (accumulator, item) =>
              +accumulator + +itemQty(item?.consumable_qty) || 0,
            0
          ) || 0;
        return totalConsumableQty;
      }
      return recentListings?.length;
    }
    return 0;
  }, [isProductConsumable, itemQty, recentListings]);

  return (
    <Box marginY={2}>
      {isProductConsumable ? (
        <Alert hidden className={classes.consumableAlert} severity="info">
          This product is consumable and does not have serial numbers under it.
        </Alert>
      ) : null}
      <Card>
        <CardHeader
          title={`Temporary Listings - Quantity: ${tempListingsLength}`}
          subheader={`${subHeader} | Temporary listings to be uploaded to the database`}
        />
        <Divider />
        <PerfectScrollbar>
          <Box>
            {isProductConsumable ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Qty</TableCell>
                    <TableCell>ID</TableCell>
                    <TableCell>Supplier</TableCell>
                    {multiBranchFeat ? <TableCell>Branch</TableCell> : null}
                    <TableCell>Purchase Date</TableCell>
                    <TableCell>Dealers Price</TableCell>
                    <TableCell>Retail Price</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentListings?.map((item, i) => (
                    <TableRow hover key={i}>
                      <TableCell>{item?.consumable_qty}</TableCell>
                      <TableCell>{item?.serial_no || 'N/A'}</TableCell>
                      <TableCell>{item?.supplier}</TableCell>
                      {multiBranchFeat ? (
                        <TableCell>{item?.branch_name}</TableCell>
                      ) : null}
                      <TableCell>{formatDate(item?.purchase_date)}</TableCell>
                      <TableCell>
                        {`DP ${formatCurrency(item?.dealers_price) ?? '--'}`}
                      </TableCell>
                      <TableCell>
                        {`SRP ${formatCurrency(item?.retail_price) ?? '--'}`}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => onPressDeleteRecentItem(i)}>
                          <DeleteForeverIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Serial No.</TableCell>
                    <TableCell>Supplier</TableCell>
                    {multiBranchFeat ? <TableCell>Branch</TableCell> : null}
                    <TableCell>Dealers Price</TableCell>
                    <TableCell>Price</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentListings?.map((item, i) => (
                    <TableRow hover key={i}>
                      <TableCell>{item?.serial_no || 'N/A'}</TableCell>
                      <TableCell>{item.supplier}</TableCell>
                      {multiBranchFeat ? (
                        <TableCell>{item?.branch_name}</TableCell>
                      ) : null}
                      <TableCell>
                        {`DP ${formatCurrency(item?.dealers_price) ?? '--'}`}
                      </TableCell>
                      <TableCell>
                        {`SRP ${formatCurrency(item?.retail_price) ?? '--'}`}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => onPressDeleteRecentItem(i)}>
                          <DeleteForeverIcon color="secondary" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
    </Box>
  );
};

export const TemporaryListings = React.memo(Listings);
