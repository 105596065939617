export const messagePrompt = {
  originTitle: 'Change Origin Branch',
  receivingTitle: 'Change Receiving Branch',
  originBranchMsg:
    'You are about to change the origin branch. Upon changing the branch, the added item(s) from both origin and receiving branch will be emptied.',
  receivingBranchMsg:
    'You are about to change the receiving branch. Upon changing the branch, the added item(s) from receiving branch will be emptied',

  //====error messages=====
  errorSameBranchMsg:
    'Error: Ensure the selected origin branch differs from the receiving branch to prevent potential conflicts or unintended changes.',
  warningNoReceiveBranch:'Warning: Please select a receiving branch to transfer item.'
};
