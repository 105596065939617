import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import { DecayingStockPayload, DecayingStockResponse } from './types';

export const getDecayingStockProductsThunk = createAsyncThunk<
  CommonAxiosResponse<DecayingStockResponse>,
  DecayingStockPayload,
  { state: RootState }
>('decayingStock/getDecayingStockProductsThunk', async (data) => {
  const response = await Api.get('/listings/decaying_stocks/get', data);
  return response;
});
