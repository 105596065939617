import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import {
  BlogData,
  CreateBlogPayload,
  GetBlogListPayload
} from 'src/redux/slices/blogs/types';
import { useSnackBar } from '../use-snackbar';
import { useNavigate, useParams } from 'react-router';

const { actions: blogsActions, selectors: blogSelectors } = slices.blog;

export const useBlog = () => {
  const snackBar = useSnackBar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const blogListLoading = useAppSelector(blogSelectors?.selectBlogListLoading);
  const blogDetailsLoading = useAppSelector(
    blogSelectors?.selectBlogDetailsLoading
  );

  const blogListData = useAppSelector(blogSelectors?.selectBlogList);
  const blogListMeta = useAppSelector(blogSelectors?.selectBlogListMeta);
  const blogDetails = useAppSelector(blogSelectors?.selectBlogDetails);
  const currentPage = useAppSelector(blogSelectors?.selectCurrentPage);

  const [isCreateLoading, setIsCreateLoading] = useState<boolean>(false);
  const [createdUuid, setCreateUuid] = useState<string>('');
  const [updateDetailLoading, setUpdateDetailLoading] = useState<boolean>(
    false
  );
  const [isPublish, setIsPublish] = useState<boolean>(false);

  const createBlog = useCallback(
    async (params: CreateBlogPayload) => {
      setIsCreateLoading(true);
      try {
        const response = unwrapResult(
          await dispatch(blogsActions?.createBlogThunk(params))
        );
        if (response?.success) {
          const { uuid } = response?.originalData?.data || {};
          setIsCreateLoading(false);
          setCreateUuid(uuid || '');
          navigate(`/app/blog/details/${uuid}`);

          snackBar.show({
            severity: 'success',
            useSound: true,
            message: response?.message
          });
        }
        if (response?.errors && !response?.success) {
          const { errors } = response;
          const errMessage = Object.values(errors)
            .flat()
            .join(' | ');
          snackBar.show({
            severity: 'error',
            useSound: true,
            message: `${errMessage}`
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsCreateLoading(false);
      }
    },
    [dispatch, navigate, snackBar]
  );

  const getBlogList = useCallback(
    async (params?: GetBlogListPayload) => {
      try {
        await dispatch(
          blogsActions?.getBlogListThunk({
            keyword: params?.keyword || undefined,
            page: params?.page || 1
          })
        );
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const getBlogDetail = useCallback(async () => {
    try {
      await dispatch(blogsActions?.getBlogDetailsThunk(id || ''));
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, id]);

  const updateBlogContent = useCallback(
    async (contentHtml?: string) => {
      try {
        const response = unwrapResult(
          await dispatch(
            blogsActions?.updateBlogContentThunk({
              content_html: contentHtml || '',
              uuid: id || ''
            })
          )
        );

        if (response?.success) {
          const { message } = response;
          snackBar.show({ severity: 'success', message });
        } else {
          const { errors } = response;
          const errMessage = Object.values(errors)
            .flat()
            .join(' | ');
          snackBar.show({
            severity: 'error',
            useSound: true,
            message: `${errMessage}`
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, id, snackBar]
  );

  const updateBlogDetails = useCallback(
    async (publish: boolean, params: BlogData) => {
      setUpdateDetailLoading(true);
      try {
        const response = unwrapResult(
          await dispatch(
            blogsActions?.updateBlogDetailsThunk({ uuid: id, data: params })
          )
        );
        if (response?.success) {
          setUpdateDetailLoading(false);
          //for updating state of publish action
          if (publish) {
            setIsPublish(!isPublish);
          }
          getBlogDetail();
          snackBar.show({
            severity: 'success',
            message: response?.message
          });
        } else {
          const { errors } = response;
          const errMessage = Object.values(errors)
            .flat()
            .join(' | ');
          snackBar.show({
            severity: 'error',
            useSound: true,
            message: `${errMessage}`
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setUpdateDetailLoading(false);
      }
    },
    [dispatch, getBlogDetail, id, isPublish, snackBar]
  );

  return {
    isCreateLoading,
    blogListLoading,
    blogDetailsLoading,
    updateDetailLoading,
    blogListMeta,
    blogListData,
    blogDetails,
    currentPage,
    createdUuid,

    createBlog,
    getBlogList,
    getBlogDetail,
    updateBlogContent,
    updateBlogDetails,

    isPublish,
    setIsPublish
  };
};
