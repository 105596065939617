import React, { useMemo } from 'react';
import {
  Card,
  CardContent,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core';
import { EcomOrderDetails } from 'src/types';
import { colors } from 'src/constants';
import {
  formatCurrency,
  getOrderPaymentStatusColor,
  openInNewTab
} from 'src/utils';
import { LinkComponent, LoaderBar } from 'src/components';

interface Props {
  isLoading?: boolean;
  orderDetails?: EcomOrderDetails;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(0),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  }
}));

export const EcomTotalAmount = ({ isLoading = false, orderDetails }: Props) => {
  const classes = useStyles();

  // U = Used
  // U  "payment_status": null, // payment status
  // U   "addtl_fee": 0, // If cpu only or any other cases
  // U   "shipping_fee": 541, // shipping fee
  // U   "total_amount": 24065, // cart amount  when checked out
  // U   "grand_total_amount": 24606, // shipping fee + additional fee + cart total
  // U   "current_total_amount": 24065, // current total amount of cart
  // U   "total_amount_fluctuation": "none", //

  const fluctuation = useMemo(() => {
    return orderDetails?.total_amount_fluctuation || 'none';
  }, [orderDetails]);

  const currentTotalAmount = useMemo(() => {
    return `${formatCurrency(orderDetails?.current_total_amount || 0) ??
      `0.00`}`;
  }, [orderDetails]);

  const checkoutTotal = useMemo(() => {
    return `${formatCurrency(orderDetails?.total_amount || 0) ?? `0.00`}`;
  }, [orderDetails]);

  return (
    <div>
      <LoaderBar isLoading={isLoading} />
      <Card className={classes.root}>
        {isLoading ? null : (
          <CardContent>
            {orderDetails?.payment_link ? (
              <Typography variant="h5">
                Payment Link:{' '}
                <LinkComponent
                  openInNewTab
                  href={orderDetails?.payment_link}
                  title={`OPEN PAYMENT LINK`}
                  onClick={() => openInNewTab(`${orderDetails?.payment_link}`)}
                  extraProps={{
                    style: { textDecoration: 'underline', color: colors.link }
                  }}
                />
              </Typography>
            ) : null}

            {orderDetails?.payment_status ? (
              <Typography variant="h5">
                Payment Status:{' '}
                <Typography
                  display="inline"
                  variant="body1"
                  style={{
                    color: getOrderPaymentStatusColor(
                      orderDetails?.payment_status
                    )
                  }}
                >
                  {` ${orderDetails?.payment_status ?? ''}`}
                </Typography>
              </Typography>
            ) : null}

            {orderDetails?.payment_link && orderDetails?.payment_status ? (
              <Divider />
            ) : null}

            <Typography variant="h6">
              Check-out Total Amount: {checkoutTotal}
            </Typography>

            <Typography variant="h6">
              Deducted Kachi Points:{' '}
              {`-${formatCurrency(Math.abs(orderDetails?.kachi_points || 0))}`}
            </Typography>

            <Typography variant="h6">
              Shipping Fee Total:{' '}
              {`${formatCurrency(orderDetails?.shipping_fee) ?? `0.00`}`}
            </Typography>

            <Typography variant="h6">
              Additional Fee Total:{' '}
              {`${formatCurrency(orderDetails?.addtl_fee) ?? `0.00`}`}
            </Typography>

            {/* Meaning price of product fluctuated (increased or decreased) */}
            {fluctuation !== 'none' ? (
              <Typography variant="h6">
                Current Total Amount: {currentTotalAmount}
              </Typography>
            ) : null}
            {fluctuation === 'none' ? null : (
              <Typography
                style={{
                  color:
                    fluctuation === 'decrease' ? colors.success : colors.warning
                }}
              >
                order amount has: {fluctuation}
              </Typography>
            )}

            {/* TODO: Add alert with definitions */}

            <Divider />
            <Typography variant="h3">
              Grand Total:{' '}
              {`${formatCurrency(orderDetails?.grand_total_amount) ?? `0.00`}`}
            </Typography>
          </CardContent>
        )}
      </Card>
    </div>
  );
};
