import React, { useCallback, useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { Page } from 'src/components';
import { Transaction } from 'src/types';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import TransactionsTable from './components/TransactionsTable';
import ToolBar from './components/ToolBar';

const { actions: transactionActions } = slices.transaction;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const TransactionView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getTransactions = useCallback(
    async (keyword?: string, branch_ids?: number[]) => {
      setIsLoading(true);
      const response = unwrapResult(
        await dispatch(
          transactionActions.getTransactionsThunk({
            keyword: keyword,
            branch_ids: branch_ids
          })
        ).finally(() => setIsLoading(false))
      );

      if (response.success && response.originalData?.transactions) {
        setTransactions(response.originalData?.transactions);
      }
    },
    [dispatch]
  );

  const onSearchTransactions = (keyword?: string, branch_ids?: number[]) => {
    getTransactions(keyword || '', branch_ids || []);
  };

  useEffect(() => {
    getTransactions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Transaction">
      <Container maxWidth={false}>
        <ToolBar
          onSearchTransaction={onSearchTransactions}
          isLoading={isLoading}
        />
        <TransactionsTable transactions={transactions} loading={isLoading} />
      </Container>
    </Page>
  );
};

export default TransactionView;
