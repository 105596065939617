import React from 'react';
import {
  CardContent,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Product } from 'src/types/product';
import { CustomInputEvent } from 'src/types';
import { usePermissions } from 'src/hooks';
import { CheckBoxLabel } from 'src/components';
import { CategoriesEnum } from 'src/enums';

interface Props {
  productInfo?: Product;
  onChangeValue?: (newInfo: CustomInputEvent) => void;
  hasDivider?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  }
}));

const component = ({
  productInfo,
  onChangeValue,
  hasDivider = true
}: Props) => {
  const classes = useStyles();
  const permissions = usePermissions();

  const onChangeValueInternal = (e: CustomInputEvent) => {
    if (onChangeValue && permissions.canEditPriceListPrice) {
      onChangeValue(e);
    }
  };

  // Intended `any` type
  const onWithBundleToggle = (value: any) => {
    const newWithBundleValue: any = { target: { value, name: 'is_bundle' } };
    onChangeValueInternal(newWithBundleValue);

    if (!value) {
      const newAdditionalPaymentIsZero: any = {
        target: { value: 0, name: 'addtl_non_bundle_price' }
      };
      onChangeValueInternal(newAdditionalPaymentIsZero);
    }
  };

  return (
    <div>
      <CardContent>
        <Typography className={classes.subHeader} variant="h6">
          Pricing Information
        </Typography>
        <Grid container spacing={3}>
          {permissions.canSeeDealersPrice ? (
            <Grid item md={6} xs={12}>
              <TextField
                disabled={!permissions.canEditPriceListPrice}
                fullWidth
                helperText="If category is RAM, DP here should be per stick"
                label="Dealers Price"
                name="dealers_price"
                required
                type="number"
                variant="outlined"
                value={productInfo?.dealers_price || ''}
                onChange={onChangeValueInternal}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₱</InputAdornment>
                  )
                }}
              />
            </Grid>
          ) : null}
          <Grid item md={6} xs={12}>
            <TextField
              fullWidth
              disabled={!permissions.canEditPriceListPrice}
              label="Retail Price"
              name="retail_price"
              helperText="If category is RAM, SRP here should be per stick"
              required
              type="number"
              variant="outlined"
              value={productInfo?.retail_price || ''}
              onChange={onChangeValueInternal}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">₱</InputAdornment>
                )
              }}
            />
          </Grid>

          {productInfo?.category_id === CategoriesEnum.CPU ? (
            <Grid item md={6} xs={12}>
              <CheckBoxLabel
                onChange={onWithBundleToggle}
                label="Strictly for motherboard bundle"
                disabled={!permissions.canEditPriceListPrice}
                checked={productInfo?.is_bundle ? true : false}
              />
            </Grid>
          ) : null}

          {productInfo?.category_id === CategoriesEnum.CPU &&
          !productInfo?.is_bundle ? (
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                disabled={!permissions.canEditPriceListPrice}
                label="Additional Non Bundle Price"
                name="addtl_non_bundle_price"
                helperText="This amount will be added if CPU is bought without compatible CPU (only for items not strictly bundled)"
                required
                type="number"
                variant="outlined"
                value={productInfo?.addtl_non_bundle_price || ''}
                onChange={onChangeValueInternal}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">₱</InputAdornment>
                  )
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </CardContent>
      {hasDivider ? <Divider /> : null}
    </div>
  );
};

export const ProductInfoPricing = React.memo(component);
