import { PartFields } from 'src/types';
import { SsdPartDetailsFields } from 'src/types';


export const ssdPartsFields: PartFields<SsdPartDetailsFields>[] = [
  {
    label: 'Capacity',
    part_property: 'capacity',
    hint: '128GB, 500GB, 1TB, 2TB',
    transformRule: ['all-caps', 'no-spaces'],
    options: [
      '120GB',
      '128GB',
      '240GB',
      '250GB',
      '256GB',
      '480GB',
      '500GB',
      '512GB',
      '1TB',
      '2TB',
      '6TB'
    ],
    type: 'string',
    required: true
  },
  {
    label: 'PCIE Gen',
    part_property: 'pcie_gen',
    type: 'string',
    options: ['GEN1', 'GEN2', 'GEN3', 'GEN4', 'GEN5', 'GEN6'],
    transformRule: ['all-caps', 'no-spaces'],
    required: false
  },
  {
    label: 'Size',
    part_property: 'size',
    hint: '22110, 2230, 2242, 2260, 2280, M.2 2280, SATA 2.5',
    options: ['22110', '2230', '2242', '2260', '2280', 'M.2 2280', 'SATA 2.5'],
    type: 'string',
    required: true,
  },
  {
    label: 'Form Factor',
    part_property: 'form_factor',
    hint: 'M.2, SATA',
    options: ['M.2', 'SATA'],
    type: 'string',
    required: true,
  },
    {
    label: 'NVME',
    part_property: 'nvme',
    type: 'boolean',
    required: false
  },
  {
    label: 'Image',
    part_property: 'image_upload',
    hint: 'Select Product Image. Only one is allowed for now',
    type: 'img',
    required: false
  }
];
