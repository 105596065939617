import React, { useCallback } from 'react';
import {
  IconButton,
  makeStyles,
  TableCell,
  TextField,
  Typography
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { CustomInputEvent, Quotation } from 'src/types';
import { formatCurrency } from 'src/utils';
interface QuotationPromoPriceCellProps {
  item: Quotation;
  index: number;
  customPriceInput: string;
  onCustomPriceInputChange: (e: CustomInputEvent, index: number) => void;
}

const width = 170;

const useStyles = makeStyles(
  () => ({
    root: {
      maxWidth: width,
      minWidth: width
    },
    pesoSign: {
      padding: 0,
      fontWeight: 400,
      fontSize: 14,
      paddingTop: 1
    },
    input: {
      fontSize: 14,
      verticalAlign: 'text-top'
    },
    amount: {
      fontWeight: 500
    },
    showSRPInput: {
    }
  }),
  { index: 999 }
);

const PromoPrice = ({
  index,
  item,
  customPriceInput,
  onCustomPriceInputChange
}: QuotationPromoPriceCellProps) => {
  const classes = useStyles();

  const onClear = useCallback(() => {
    const mockedEvent: any = {
      target: {
        value: undefined
      }
    };
    onCustomPriceInputChange(mockedEvent, index);
  }, [index, onCustomPriceInputChange]);

  return (
    /* Do not show promo price input if showSRP is enabled. */
    <TableCell className={classes.root} onClick={(e) => e.stopPropagation()}>
      {item?.custom ? (
        <TextField
          type="number"
          value={customPriceInput}
          onChange={(e) => onCustomPriceInputChange(e, index)}
          label=""
          margin="none"
          variant="standard"
          InputProps={{
            className: classes.input,
            startAdornment: (
              <Typography className={classes.pesoSign}>₱</Typography>
            ),
            endAdornment: (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onClear();
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />
      ) : (
        <Typography className={classes.input} >
          {formatCurrency(item?.product?.retail_price || 0)}
        </Typography>
      )}
    </TableCell>
  );
};

export const QuotationPromoPriceCell = React.memo(PromoPrice);
