import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectEcomOrdersState = (state: RootState) => state.ecomOrders;

const selectEcomOrdersList = createSelector(
  selectEcomOrdersState,
  (state) => state.ecomOrders
);

const selectEcomOrdersLoading = createSelector(
  selectEcomOrdersState,
  (state) => state.isListLoading
);

const getEcomOrderListRequest = createSelector(
  selectEcomOrdersState,
  (state) => state.getEcomOrderListRequest
);

const getEcomOrderPaginationMeta = createSelector(
  selectEcomOrdersState,
  (state) => state.ecomOrdersPaginationMeta
);

const availableProducts = createSelector(
  selectEcomOrdersState,
  (state) => state.availableProducts
);

export const selectors = {
  availableProducts,
  selectEcomOrdersList,
  getEcomOrderListRequest,
  getEcomOrderPaginationMeta,
  selectEcomOrdersLoading
};
