import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  makeStyles
} from '@material-ui/core';
import { slices, useAppSelector } from 'src/redux';
import { RaffleEntry } from 'src/types';
import { WinnerListItem } from './WinnerListitem';

const { selectors: raffleSelectors } = slices.raffle;

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  winnerText: {
    fontSize: '25px',
    marginBottom: '10px'
  }
}));

const Winners = () => {
  const classes = useStyles();
  const raffleWinners = useAppSelector(raffleSelectors.selectRaffleWinners);

  return (
    <>
      <Typography className={classes.winnerText}>Winners:</Typography>
      <Card>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Contact</TableCell>
                  <TableCell> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {raffleWinners.map((winner: RaffleEntry, index) => (
                  <WinnerListItem
                    key={winner.id}
                    winner={winner}
                    index={index}
                  />
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </>
  );
};

export default Winners;
