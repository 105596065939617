import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  Box,
  InputAdornment,
  Paper,
  SvgIcon,
  TextField,
  makeStyles
} from '@material-ui/core';
import { BranchListDropDown } from 'src/components/dropdown';
import { Search as SearchIcon } from 'react-feather';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { Autocomplete } from '@material-ui/lab';
import { debounce } from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';

const {
  actions: categoryActions,
  selectors: categorySelectors
} = slices.category;

const useStyle = makeStyles({
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flexStart',
    alignItems: 'center',
    padding: '1rem'
  }
});

interface ToolbarProps {
  defaultBranch?: number[];
  isLoading?: boolean;
  onChangeCategory: (selectedCategory?: number) => void;
  onChangeBranch: (selectedBranch?: number[]) => void;
  onSearchProd: (keyword?: string) => void;
}

const component = ({
  defaultBranch,
  isLoading,
  onChangeCategory,
  onChangeBranch,
  onSearchProd
}: ToolbarProps) => {
  const classes = useStyle();
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const categories = useAppSelector(categorySelectors.selectCategories);
  const [searchName, setSearchName] = useState<string>();
  const [categLoading, setCategLoading] = useState<boolean>();

  const getCategories = useCallback(
    async (keyword?: string) => {
      setCategLoading(true);
      try {
        setCategLoading(true);
        await dispatch(categoryActions.getCategoriesThunk({ keyword }));
      } catch (error) {
        console.error(error);
      } finally {
        setCategLoading(false);
      }
    },
    [dispatch]
  );

  const onChangeInputKeyword = useCallback(
    debounce((chosenCategId?: number, val?: string) => {
      if (val) {
        getCategories(val);
      }
      if (chosenCategId) {
        onChangeCategory(chosenCategId);
      }
    }, 1000),
    [getCategories, onChangeCategory]
  );

  const onSearchKeyword = useCallback(
    debounce((keyword?: string) => {
      onSearchProd(keyword);
      setSearchName(keyword);
    }, 1000),
    [onSearchProd, setSearchName]
  );

  const onClear = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
    }
    onSearchProd('');
  };

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <Paper>
      <Box className={classes.flexBox}>
        <Box minWidth={500} className={classes.flexBox}>
          <TextField
            fullWidth
            disabled={isLoading}
            inputRef={inputRef}
            onChange={(event) => onSearchKeyword(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" style={{ cursor: 'pointer' }}>
                  {searchName ? <ClearIcon onClick={() => onClear()} /> : null}
                </InputAdornment>
              )
            }}
            placeholder="Search Pricelist Product"
            variant="outlined"
          />
        </Box>
        <div style={{ minWidth: '200px' }}>
          <BranchListDropDown
            defaultValue={defaultBranch}
            onHandleBranchChange={onChangeBranch}
            multiple={true}
          />
        </div>
        <Box>
          <Autocomplete
            fullWidth
            clearOnBlur
            onInputChange={(e, newInputValue, reason) => {
              //when text is clear back to default list
              if (reason === 'clear') {
                onChangeCategory();
                getCategories();
              }
              const chosenCategory = categories?.find(
                (category) => category?.name === newInputValue
              );
              const chosenCategoryId = chosenCategory?.id;
              onChangeInputKeyword(chosenCategoryId, newInputValue);
            }}
            style={{ minWidth: 200 }}
            id="category"
            options={categories}
            loading={categLoading}
            getOptionLabel={(option: any) => option.name}
            renderInput={(params: any) => (
              <TextField {...params} label="Category" variant="outlined" />
            )}
          />
        </Box>
      </Box>
    </Paper>
  );
};

export const PriceListToolbar = memo(component);
