import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { KachiPointsApi } from './api';
import { CommonAxiosResponse, CommonResponseMessage } from 'src/types';
import {
  KachiHistoryRequest,
  KachiPointsAddRequest,
  KachiPointsForfeitRequest,
  KachiPointsUserListRequest,
  KachiUserHistoryResponse,
  KachiUserListResponse
} from 'src/types/kachi-points';

export const getKachiPointsUserListThunk = createAsyncThunk<
  CommonAxiosResponse<KachiUserListResponse>,
  KachiPointsUserListRequest | undefined,
  { state: RootState }
>('kachipoints/getKachiPointsUserList', async (data) => {
  const response = await KachiPointsApi.getKachiPointsListApi(data);
  return response;
});

export const getKachiUserHistoryThunk = createAsyncThunk<
  CommonAxiosResponse<KachiUserHistoryResponse>,
  KachiHistoryRequest,
  { state: RootState }
>('kachipoints/getKachiUserHistoryThunk', async (data) => {
  const response = await KachiPointsApi.getKachiPointsUserHistoryApi(data);
  return response;
});

export const addKachiPointsThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  KachiPointsAddRequest,
  { state: RootState }
>('kachipoints/addKachiPointsThunk', async (data) => {
  const response = await KachiPointsApi.addKachiPointsApi(data);
  return response;
});

export const deleteKachiPointsThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  KachiPointsForfeitRequest,
  { state: RootState }
>('kachipoints/deleteKachiPointsThunk', async (data) => {
  const response = await KachiPointsApi.deleteKachiPointsUserApi(data);
  return response;
});
