import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, GetFlashDealViaIdResponse } from 'src/types';

export const flashDealsGetViaIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetFlashDealViaIdResponse>,
  number,
  { state: RootState }
>('flashDeals/flashDealsGetViaIdThunk', async (id) => {
  const response = await Api.get(`/ecomm/internal/flash_deal/details/get/${id}`);
  return response;
});
