import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import clsx from 'clsx';
import size from 'lodash/size';
import React, { useEffect, useMemo, useState } from 'react';
import { AlertDialog, LoaderBar } from 'src/components';
import { useRoles, useSnackBar } from 'src/hooks';
import { slices, useAppSelector } from 'src/redux';
import { CustomInputEvent } from 'src/types';
import { UserBasicInfo } from 'src/types/user';
import { isEmptyOrSpaces } from 'src/utils';

const { selectors: userSelectors } = slices.user;

interface Props {
  user?: UserBasicInfo;
  loading?: boolean;
  className?: string;
  onDeleteUser?: (user: UserBasicInfo) => void;
  onSaveDetailPress: (updatedUser: UserBasicInfo) => void;
}

const useStyles = makeStyles(() => ({
  root: {},
  passwordDiv: {
    flexDirection: 'row',
    display: 'flex'
  }
}));

const UserDetailCard = ({
  className,
  user,
  onDeleteUser,
  onSaveDetailPress,
  loading,
  ...rest
}: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const { isOwner, isSupervisor } = useRoles();

  // Account that is used on login.
  const accountNow = useAppSelector(userSelectors.selectUserInfo);

  const [deleteTransactionDialog, setDeleteTransactionDialog] = useState<
    boolean
  >(false);
  const [userDetails, setUserDetails] = useState<UserBasicInfo>();
  const [isPasswordEnabled, setPasswordEnabled] = useState<boolean>(false);
  const [canSeePassword, setCanSeePassword] = useState<boolean>(false);
  const [password, setPassword] = useState<string>('');
  const [isPasscodeEnabled, setIsPasscodeEnabled] = React.useState(false);
  const [isVisiblePasscode, setIsVisiblePasscode] = React.useState(false);
  const [passcode, setPasscode] = React.useState('');

  // Checks if trying to edit the account that is logged in
  const isOwnAccount = useMemo(() => userDetails?.id === accountNow?.id, [
    accountNow,
    userDetails
  ]);

  const onDeletePressConfirm = () => {
    handleCloseDeleteDialog();
    if (userDetails && onDeleteUser) {
      onDeleteUser(userDetails);
    }
  };

  const onDeleteUserOpenDialog = () => {
    // Deleting own account is not allowed.
    if (isOwnAccount) {
      return;
    }
    setDeleteTransactionDialog(true);
  };

  const resetPasswordRelation = () => {
    setPasswordEnabled(false);
    setCanSeePassword(false);
    setPassword('');
  };

  const userFullName = useMemo(
    () => `${user?.first_name || ''} ${user?.last_name || ''}`,
    [user]
  );

  const handleCloseDeleteDialog = () => {
    setDeleteTransactionDialog(false);
  };

  const handleChange = (event: CustomInputEvent) => {
    const { name, value } = event.target;
    setUserDetails((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const toggleEditPasswordIcon = () => {
    // If disable is press clear password field
    if (isPasswordEnabled) {
      resetPasswordRelation();
    }
    setPasswordEnabled(!isPasswordEnabled);
  };

  const toggleCanSeePassword = () => {
    setCanSeePassword(!canSeePassword);
  };

  const toggleIsVisiblePasscode = () => {
    setIsVisiblePasscode((isVisible) => !isVisible);
  };

  const resetPasscode = () => {
    setIsPasscodeEnabled(false);
    setIsVisiblePasscode(false);
    setPasscode('');
  };

  const toggleEditPasscode = () => {
    if (isPasscodeEnabled) {
      setIsVisiblePasscode(false);
      setPasscode('');
    }
    setIsPasscodeEnabled((enabled) => !enabled);
  };

  const onChangePasscode = (event: CustomInputEvent) => {
    setPasscode(event.target.value);
  };

  const onSaveDetailValidate = async () => {
    if (isEmptyOrSpaces(userDetails?.first_name)) {
      snackBar.show({
        severity: 'error',
        message: 'First Name cannot be empty'
      });
      return;
    }
    if (isEmptyOrSpaces(userDetails?.last_name)) {
      snackBar.show({
        severity: 'error',
        message: 'Last Name cannot be empty'
      });
      return;
    }
    if (isEmptyOrSpaces(userDetails?.username)) {
      snackBar.show({
        severity: 'error',
        message: 'UserName cannot be empty'
      });
      return;
    }
    if (isPasscodeEnabled && 0 < size(passcode) && size(passcode) < 6) {
      snackBar.show({
        severity: 'error',
        message: 'Passcode is less than 6 character'
      });
      return;
    }
    let newUserData: UserBasicInfo | undefined = {
      id: userDetails?.id,
      first_name: userDetails?.first_name,
      last_name: userDetails?.last_name,
      username: userDetails?.username
    };

    if (!isEmptyOrSpaces(password)) {
      newUserData = {
        ...newUserData,
        password
      };
    }

    if (size(passcode) === 6) {
      newUserData = {
        ...newUserData,
        passcode
      };
    }

    resetPasswordRelation();
    resetPasscode();
    if (newUserData) {
      onSaveDetailPress(newUserData);
    }
  };

  useEffect(() => {
    if (user) {
      setUserDetails(user);
    }
  }, [user]);

  return (
    <Box mt={2}>
      <LoaderBar isLoading={loading ? loading : false} />
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={8} md={8} xs={8}>
              <Box>
                <Typography
                  style={{ marginTop: 10 }}
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                >
                  {userFullName}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box hidden={!userDetails} mt={2}>
            <Card elevation={0}>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="First Name"
                      name="first_name"
                      required
                      variant="outlined"
                      value={userDetails?.first_name || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Last Name"
                      name="last_name"
                      required
                      variant="outlined"
                      value={userDetails?.last_name || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      label="UserName"
                      name="username"
                      required
                      variant="outlined"
                      value={userDetails?.username || ''}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={12} xs={12}>
                    <TextField
                      fullWidth
                      helperText={`Toggle Enable/Disable by clicking "Edit" icon (current status: ${
                        isPasswordEnabled ? 'enabled' : 'disabled'
                      })`}
                      type={canSeePassword ? 'text' : 'password'}
                      disabled={!isPasswordEnabled}
                      label="Password"
                      name="password"
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <div className={classes.passwordDiv}>
                            <IconButton onClick={() => toggleCanSeePassword()}>
                              {canSeePassword ? (
                                <VisibilityOffIcon />
                              ) : (
                                <VisibilityIcon />
                              )}
                            </IconButton>
                            <IconButton
                              onClick={() => toggleEditPasswordIcon()}
                            >
                              <EditIcon />
                            </IconButton>
                          </div>
                        )
                      }}
                      value={password || ''}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Grid>
                  {isOwner || isSupervisor ? (
                    <Grid item md={12} xs={12}>
                      <TextField
                        fullWidth
                        helperText={`Toggle Enable/Disable by clicking "Edit" icon (current status: ${
                          isPasscodeEnabled ? 'enabled' : 'disabled'
                        })`}
                        type={isVisiblePasscode ? 'text' : 'password'}
                        disabled={!isPasscodeEnabled}
                        label="Passcode"
                        name="passcode"
                        variant="outlined"
                        inputProps={{
                          maxLength: 6,
                          minlength: 6
                        }}
                        InputProps={{
                          endAdornment: (
                            <div className={classes.passwordDiv}>
                              <IconButton onClick={toggleIsVisiblePasscode}>
                                {isVisiblePasscode ? (
                                  <VisibilityOffIcon />
                                ) : (
                                  <VisibilityIcon />
                                )}
                              </IconButton>
                              <IconButton onClick={toggleEditPasscode}>
                                <EditIcon />
                              </IconButton>
                            </div>
                          )
                        }}
                        value={passcode || ''}
                        onChange={onChangePasscode}
                      />
                    </Grid>
                  ) : null}
                </Grid>
              </CardContent>
              <Box
                display="flex"
                justifyContent="space-between"
                paddingX={2}
                mt={2}
              >
                <Button
                  disabled={isOwnAccount}
                  onClick={onDeleteUserOpenDialog}
                  color="secondary"
                  variant="contained"
                >
                  Delete User
                </Button>
                <Button
                  onClick={() => onSaveDetailValidate()}
                  color="primary"
                  variant="contained"
                >
                  Save details
                </Button>
              </Box>
            </Card>
          </Box>
        </CardContent>
      </Card>
      <AlertDialog
        title={`Delete ${userFullName}`}
        customButtons={
          <>
            <Button onClick={onDeletePressConfirm} color="secondary" autoFocus>
              Delete
            </Button>
            <Button onClick={handleCloseDeleteDialog} color="primary">
              Cancel
            </Button>
          </>
        }
        subTitle={`Are you sure you want to delete ${userFullName}`}
        isVisible={deleteTransactionDialog}
      />
    </Box>
  );
};

export default UserDetailCard;
