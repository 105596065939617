import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  CommonCustomerServicePayload,
  CommonCustomerServiceResponse,
  GetCustomerServiceListResponse
} from 'src/types';

export const getCustomerServiceThunk = createAsyncThunk<
  CommonAxiosResponse<CommonCustomerServiceResponse>,
  CommonCustomerServicePayload,
  { state: RootState }
>('customer/getCustomerServiceThunk', async (data) => {
  const response = await Api.get(
    `/customers/${data.id}${
      data.is_ecomm_user ? `?is_ecomm_user=${data.is_ecomm_user}` : ``
    }`
  );

  if (!response.success) {
    throw new Error('Failed getCustomerServiceThunk');
  }
  return response;
});

export const getCustomerServiceListThunk = createAsyncThunk<
  CommonAxiosResponse<GetCustomerServiceListResponse>,
  CommonCustomerServicePayload,
  { state: RootState }
>('customer/getCustomerServiceListThunk', async (data) => {
  const payload = {
    keyword: data?.keyword,
    page_number: data?.page_number,
    page_size: data?.page_size
  };
  const response = await Api.get(`/customers`, payload);
  if (!response.success) {
    throw new Error('Failed getCustomerServiceListThunk');
  }
  return response;
});
