import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles
} from '@material-ui/core';
import { colors } from 'src/constants';
import { formatCurrency } from 'src/utils';
import { AvailableInventorySumData } from 'src/types/available-inventory';
import { LoaderBar } from 'src/components';

interface Props {
  className?: string;
  sumAvailable?: AvailableInventorySumData;
  loaderBar: boolean;
}

const useStyles = makeStyles(() => ({
  root: {},
  cardContent: {
    flexDirection: 'row'
  },
  totalContainer: {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  gross: {
    color: colors.success
  }
}));

const InventoryToolbar = ({
  className,
  sumAvailable,
  loaderBar,
  ...rest
}: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={3}>
        <Card>
          <LoaderBar isLoading={loaderBar} />
          <CardContent>
            <Grid container spacing={8} alignItems="center" justify="center">
              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Retail Price</Typography>
                  <Typography variant="h4" style={{ color: colors.blue[600] }}>
                    {formatCurrency(sumAvailable?.retail_price)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Gross Price</Typography>
                  <Typography variant="h4" style={{ color: colors.green[600] }}>
                    {formatCurrency(sumAvailable?.gross_price)}
                  </Typography>
                </div>
              </Grid>

              <Grid item>
                <div className={classes.totalContainer}>
                  <Typography variant="h5">Total Dealers Price</Typography>
                  <Typography
                    variant="h4"
                    style={{ color: colors.orange[600] }}
                  >
                    {formatCurrency(sumAvailable?.dealers_price)}
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default InventoryToolbar;
