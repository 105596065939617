import { createSlice } from '@reduxjs/toolkit';
import { Option } from 'src/types/option';
import * as thunks from './thunks';
import { PaginationV2 } from 'src/types';

type State = {
  options: Option[];
  optionPaginationMeta: PaginationV2;
  currentOption: Option | {};
};

const initialState: State = {
  options: [],
  optionPaginationMeta: {},
  currentOption: {}
};

const slice = createSlice({
  name: 'options',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getOptionsThunk.fulfilled, (state, { payload }) => {
      const { data, ...pageMeta } = payload.originalData.data;
      state.options = data;
      state.optionPaginationMeta = pageMeta;
    });
    builder.addCase(
      thunks.getOptionsByIdThunk.fulfilled,
      (state, { payload }) => {
        state.currentOption = payload.originalData.data;
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
