import React, { memo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  InputAdornment,
  Paper,
  SvgIcon,
  TextField,
  makeStyles
} from '@material-ui/core';
import { BranchListDropDown } from 'src/components/dropdown';
import { Search as SearchIcon } from 'react-feather';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { Autocomplete } from '@material-ui/lab';

const {
  actions: categoryActions,
  selectors: categorySelectors
} = slices.category;

const useStyle = makeStyles({
  flexBox: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flexStart',
    alignItems: 'center',
    padding: '1rem'
  }
});

interface ToolbarProps {
  defaultBranch?: number[];
  onChangeCategory: (selectedCategory?: number) => void;
  onChangeBranch: (selectedBranch?: number[]) => void;
  onChangeSearch: (keyword?: string) => void;
  onHandleFilterToLoadOutOfStock: () => void;
}

const component = ({
  defaultBranch,
  onChangeCategory,
  onChangeBranch,
  onChangeSearch,
  onHandleFilterToLoadOutOfStock
}: ToolbarProps) => {
  const classes = useStyle();
  const dispatch = useAppDispatch();
  const categories = useAppSelector(categorySelectors.selectCategories);
  const [searchName, setSearchName] = useState<string>();
  const [categLoading, setCategLoading] = useState<boolean>();

  const getCategories = useCallback(
    async (keyword?: string) => {
      setCategLoading(true);
      try {
        setCategLoading(true);
        await dispatch(categoryActions.getCategoriesThunk({ keyword }));
      } catch (error) {
        console.error(error);
      } finally {
        setCategLoading(false);
      }
    },
    [dispatch]
  );

  const onChangeInputKeyword = useCallback(
    (chosenCategId?: number) => {
      onChangeCategory(chosenCategId);
    },
    [onChangeCategory]
  );

  const onHandleSearchProductName = (keyword: string) => {
    onChangeSearch(keyword);
    setSearchName(keyword);
  };

  useEffect(() => {
    getCategories();
  }, [getCategories]);

  return (
    <Paper>
      <Box className={classes.flexBox}>
        <div style={{ minWidth: '200px' }}>
          <BranchListDropDown
            defaultValue={defaultBranch}
            onHandleBranchChange={onChangeBranch}
            multiple={true}
          />
        </div>
        <Box>
          <Autocomplete
            fullWidth
            clearOnBlur
            onInputChange={(e, newInputValue) => {
              const chosenCategory = categories?.find(
                (category) => category?.name === newInputValue
              );
              const chosenCategoryId = chosenCategory?.id;
              onChangeInputKeyword(chosenCategoryId);
            }}
            style={{ minWidth: 200 }}
            id="category"
            options={categories}
            loading={categLoading}
            getOptionLabel={(option: any) => option.name}
            renderInput={(params: any) => (
              <TextField {...params} label="Category" variant="outlined" />
            )}
          />
        </Box>
        <Box maxWidth={500} className={classes.flexBox}>
          <TextField
            fullWidth
            value={searchName}
            onChange={(event) => onHandleSearchProductName(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Search product"
            variant="outlined"
            onKeyPress={(event) =>
              event.key === 'Enter' && onHandleFilterToLoadOutOfStock()
            }
          />
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '0.5rem' }}
            onClick={onHandleFilterToLoadOutOfStock}
          >
            Search
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export const OutOfStockToolbar = memo(component);
