import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { Permission } from 'src/types';
import * as thunks from './thunks';

type State = {
  permissions: Permission[];
};

const initialState: State = {
  permissions: []
};

const slice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getPermissionsThunk.fulfilled,
      (state, { payload }) => {
        state.permissions = payload.originalData.permissions;
      }
    );
    builder.addCase(
      thunks.permissionDeleteThunk.fulfilled,
      (state, actions) => {
        if (actions.payload?.success) {
          const clonedPermissions = cloneDeep(state.permissions);
          const filtered = clonedPermissions?.filter(
            (x) => x.id !== actions.meta.arg
          );
          state.permissions = filtered;
        }
      }
    );
    builder.addCase(
      thunks.createPermissionThunk.fulfilled,
      (state, actions) => {
        if (actions.payload?.success) {
          const clonedPermissions = cloneDeep(state.permissions);
          const index = clonedPermissions?.findIndex(
            (x) => x.name === actions.meta.arg.name
          );
          if (index === -1) {
            clonedPermissions.push(actions.payload?.originalData.permission);
          }
          state.permissions = clonedPermissions;
        }
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
