import React from 'react';
import { IconButton } from '@material-ui/core';

interface Props {
  onClick: () => void;
  disabled?: boolean;
  customIcon: JSX.Element;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
}

// const useStyles = makeStyles((theme: Theme) => ({}));

// eslint-disable-next-line no-empty-pattern
export const IconButtonComponent = ({
  onClick,
  customIcon,
  color,
  disabled
}: Props) => {
  return (
    <IconButton
      disabled={disabled}
      onClick={onClick}
      size="small"
      color={color}
    >
      {customIcon}
    </IconButton>
  );
};
