import React from 'react';
import clsx from 'clsx';
import { Box, Button, Typography, makeStyles } from '@material-ui/core';
import { FixMeLater } from 'src/types';
import { isEmpty } from 'lodash';

interface Props {
  className?: string;
  isEcomm?: string | null;
  onClick: FixMeLater;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, isEcomm, onClick, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        my={2}
        mt={6}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
      >
        <Typography color="textPrimary" gutterBottom variant="h3">
          Transactions
        </Typography>
        <Button
          onClick={onClick}
          color="primary"
          variant="contained" //disable button if the user is ecomm user
          disabled={!isEmpty(isEcomm) && isEcomm !== null}
        >
          Add New Transaction on Customer
        </Button>
      </Box>
    </div>
  );
};

export default Toolbar;
