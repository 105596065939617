import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Gallery, GalleryGetRequest, PaginationMeta } from 'src/types';
import * as thunks from './thunks';

type GalleryState = {
  galleryList: Gallery[];
  isListLoading: boolean;
  getGalleryListRequest: GalleryGetRequest;
  galleryDetails: Gallery;
  galleryPaginationMeta: PaginationMeta;
};

const initialState: GalleryState = {
  galleryList: [],
  isListLoading: false,
  getGalleryListRequest: {},
  galleryDetails: {},
  galleryPaginationMeta: {}
};

const slice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    updateGalleryKeywordRequest: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      state.getGalleryListRequest = {
        ...state.getGalleryListRequest,
        page: 1,
        keyword: payload || ''
      };
    },
    updateGalleryRequest: (
      state,
      { payload }: PayloadAction<GalleryGetRequest>
    ) => {
      state.getGalleryListRequest = {
        ...state.getGalleryListRequest,
        ...payload
      };
    }
  },
  extraReducers(builder) {
    builder.addCase(thunks.getGalleryListThunk.pending, (state) => {
      state.isListLoading = true;
    });
    builder.addCase(thunks.getGalleryListThunk.rejected, (state) => {
      state.isListLoading = false;
    });
    builder.addCase(
      thunks.getGalleryListThunk.fulfilled,
      (state, { payload }) => {
        state.galleryList = payload?.originalData?.data || [];
        state.galleryPaginationMeta = payload?.originalData?.meta || {};
        state.isListLoading = false;
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
