import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectOptionState = (state: RootState) => state.options;

const selectOptionList = createSelector(
  selectOptionState,
  (state) => state.options
);

const selectOptionPaginationMeta = createSelector(
  selectOptionState,
  (state) => state.optionPaginationMeta
);

const selectCurrentOption = createSelector(
  selectOptionState,
  (state) => state.currentOption
);

export const selectors = {
  selectOptionList,
  selectOptionPaginationMeta,
  selectCurrentOption
};
