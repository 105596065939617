import { RAMKitEnum } from 'src/enums';
import { RamKitOption } from 'src/types';

export const ramKitTypeOptions: RamKitOption[] = [
  {
    id: RAMKitEnum.Single,
    value: RAMKitEnum.Single,
    name: 'Single'
  },
  {
    id: RAMKitEnum.Dual,
    value: RAMKitEnum.Dual,
    name: 'Dual'
  },
  {
    id: RAMKitEnum.Quad,
    value: RAMKitEnum.Quad,
    name: 'Quad'
  }
];
