import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2)
  },
  disclaimerContainer: {
    fontWeight: 500,
    letterSpacing: 0.75,
    fontSize: 10
  }
}));

export const FooterNote = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.disclaimerContainer}>
        NOTE: THIS QUOTATION IS NOT A CONTRACT OR A BILL. IT IS OUR BEST GUESS
        AT THE TOTAL PRICE FOR THE SERVICE AND GOODS DESCRIBED ABOVE. THE
        CUSTOMER WILL BE BILLED AFTER INDICATING ACCEPTANCE OF THIS QUOTE.
        PAYMENT WILL BE DUE PRIOR TO THE DELIVERY OF SERVICE AND GOODS. PRICE
        ARE SUBJECT TO CHANGE WITHOUT ANY PRIOR NOTICE / SHIPPING FEES NOT YET
        INCLUDED
      </Typography>
    </div>
  );
};

export const QuotationFooterNote = React.memo(FooterNote);
