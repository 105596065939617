import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
    CommonAxiosResponse,
    UpdateCustomerServicePayload,
    CommonCustomerServiceResponse
} from 'src/types';

export const updateCustomerServiceThunk = createAsyncThunk<
    CommonAxiosResponse<CommonCustomerServiceResponse>,
    UpdateCustomerServicePayload,
    { state: RootState }
>('customer/updateCustomerServiceThunk', async (data) => {
    const response = await Api.patch(`/customers/${data.id}`, data);
    if (!response.success) {
        throw new Error('Failed updateCustomerServiceThunk');
    }
    return response;
});