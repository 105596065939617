import React, { useCallback, useMemo } from 'react';
import {
  Box,
  Button,
  Card,
  Divider,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { FixMeLater, Listing, PermissionApiName } from 'src/types';
import { formatCurrency, formatDate, getStatusColor } from 'src/utils';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { ListingMenuEnum } from 'src/enums';
import {
  LinkComponent,
  ListingFreebieChip,
  LoaderBar,
  RmaChip
} from 'src/components';
import { useNavigate } from 'react-router-dom';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { cloneDeep } from 'lodash';
import { usePermissions } from 'src/hooks';
import { multiBranchFeat } from 'src/constants/feature-toggle';

type MenuItemsType = {
  type: ListingMenuEnum;
  text: string;
  permissions?: PermissionApiName[];
};

const menuItems: MenuItemsType[] = [
  {
    type: ListingMenuEnum.Edit,
    text: 'View Details / Edit',
    permissions: ['can_update_listing']
  },
  {
    type: ListingMenuEnum.ViewHistory,
    text: 'View Updates History',
    permissions: ['can_update_listing']
  },
  {
    type: ListingMenuEnum.SetOnHold,
    text: 'Set as `Hold`',
    permissions: ['can_update_listing']
  },
  {
    type: ListingMenuEnum.SetAvailable,
    text: 'Set as `Available`',
    permissions: ['can_update_listing']
  },
  {
    type: ListingMenuEnum.SetPurchased,
    text: 'Set as `Purchased`',
    permissions: ['can_update_listing']
  },
  {
    type: ListingMenuEnum.SetDefective,
    text: 'Set as `Defective`',
    permissions: ['can_update_listing']
  },
  //TODO: Rma is deprecated, remove later
  // { type: ListingMenuEnum.SetForRma, text: 'Set as `RMA`' },
  {
    type: ListingMenuEnum.Delete,
    text: 'Delete',
    permissions: ['can_delete_listing']
  }
];

interface Props {
  isLoading?: boolean;
  listings: Listing[];
  anchorEls: Element[];
  onCopySerialNo: (e: any, a: string) => void;
  handleActionClose: (item: Listing, type?: ListingMenuEnum) => void;
  handleActionClick: (menuId: number, event: FixMeLater) => void;
}

const useStyles = makeStyles((theme) => ({
  consumableAlert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  statusContainer: {
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const Listings = ({
  listings,
  isLoading,
  anchorEls,
  onCopySerialNo,
  handleActionClick,
  handleActionClose
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { userPermissionsStringArray, canSeeDealersPrice } = usePermissions();

  const tooltipPurchasedBy = useCallback((item: Listing) => {
    if (item?.purchase_info?.full_name) {
      return `Purchased by ${item?.purchase_info?.full_name}`;
    }
    return '';
  }, []);

  const menuItemWithPermissions = useMemo(() => {
    const clonedItems = cloneDeep(menuItems || []);
    let itemsWillAppear: MenuItemsType[] = clonedItems;
    itemsWillAppear = itemsWillAppear?.filter((x) => {
      if (x?.permissions && x?.permissions?.length > 0) {
        return x?.permissions?.some((r) =>
          userPermissionsStringArray?.includes(r)
        );
      }
      return true;
    });
    return itemsWillAppear;
  }, [userPermissionsStringArray]);

  return (
    <Box marginY={2}>
      <Card>
        <Divider />
        <PerfectScrollbar>
          <Box>
            <LoaderBar isLoading={isLoading ? isLoading : false} />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>DR No.</TableCell>
                  <TableCell>Serial No</TableCell>
                  <TableCell>Supplier</TableCell>
                  {multiBranchFeat ? <TableCell>Branch</TableCell> : null}
                  {canSeeDealersPrice ? (
                    <TableCell>Dealers Price</TableCell>
                  ) : null}
                  <TableCell>Price</TableCell>
                  <TableCell>Date Purchased (Supplier)</TableCell>
                  <TableCell>Date Encoded</TableCell>
                  <TableCell align="center">Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listings.map((item: Listing) => (
                  <TableRow hover key={item.id}>
                    <TableCell>{item?.dr_no ?? '--'}</TableCell>
                    <TableCell>
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/products-listings/${item.id}`)
                        }
                        href={`/app/products-listings/${item.id}`}
                        title={`${item?.serial_no ?? '--'}`}
                      />
                      <Button
                        startIcon={<FileCopyIcon />}
                        onClick={(e) =>
                          onCopySerialNo(e, item?.serial_no ?? '')
                        }
                      />
                      <ListingFreebieChip listing={item} />
                    </TableCell>
                    <TableCell>{item?.supplier?.name ?? '--'}</TableCell>
                    {multiBranchFeat ? (
                      <TableCell>{item?.branch ?? '--'}</TableCell>
                    ) : null}
                    {canSeeDealersPrice ? (
                      <TableCell>
                        {`DP ${formatCurrency(item?.dealers_price) ?? '--'}`}
                      </TableCell>
                    ) : null}
                    <TableCell>
                      {`SRP ${formatCurrency(item?.retail_price) ?? '--'}`}
                    </TableCell>
                    <TableCell>{formatDate(item?.purchase_date)}</TableCell>
                    <TableCell>{formatDate(item?.created_at)}</TableCell>
                    <TableCell>
                      <Tooltip title={tooltipPurchasedBy(item)}>
                        <div className={classes.statusContainer}>
                          <Typography
                            style={{ color: getStatusColor(item?.status) }}
                          >
                            {item.status}
                          </Typography>
                          {item?.rma ? <RmaChip rma={item?.rma} /> : null}
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        onClick={(e) => handleActionClick(item.id, e)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        id={item.id.toString()}
                        anchorEl={anchorEls[item.id]}
                        keepMounted
                        open={Boolean(anchorEls[item.id])}
                        onClose={() => handleActionClose(item)}
                      >
                        {menuItemWithPermissions?.map((menuItem, i) => (
                          <MenuItem
                            key={menuItem?.type || i}
                            onClick={() =>
                              handleActionClose(item, menuItem?.type)
                            }
                          >
                            {menuItem?.text}
                          </MenuItem>
                        ))}
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </Box>
  );
};

export const SerializedListingsProductTable = React.memo(Listings);
