import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  colors,
  Button,
  Typography
} from '@material-ui/core';
import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import { copyToClipboard, formatCurrency, getStatusColor } from 'src/utils';

import FileCopyIcon from '@material-ui/icons/FileCopy';
import { DecayingStockData } from 'src/redux/slices/decaying-stock';

interface Props {
  className?: string;
  decayingStockProducts: DecayingStockData[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 30
  },
  tableCell: {
    border: 'none',
    padding: '1rem'
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  },
  retailPriceCell: {
    width: 200
  }
}));

const component = ({ className, decayingStockProducts, ...rest }: Props) => {
  const classes = useStyles();

  const onHandleCopyProductName = (productName?: string) => {
    copyToClipboard(productName || '');
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell>Category</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Retail Price</TableCell>
                <TableCell>Dealers Price</TableCell>
                <TableCell>Purchased Date</TableCell>
                <TableCell>DMC</TableCell>
                <TableCell>MDV</TableCell>
                <TableCell>SDV</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {decayingStockProducts.map((item: DecayingStockData, index) => (
                <AlternatingColorTableRow hover key={index}>
                  <TableCell className={classes.tableCell}>
                    {item?.category_name ?? '--'}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    <Button
                      style={{ padding: 0, margin: 0 }}
                      startIcon={<FileCopyIcon />}
                      onClick={() =>
                        onHandleCopyProductName(item?.product_name)
                      }
                    />
                    <Box>
                      <LinkComponent
                        href={`/app/products/${item.product_id}`}
                        title={`${item?.product_name ?? '--'}`}
                      />
                    </Box>
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    style={{ color: getStatusColor(item?.status) }}
                  >
                    {item.status}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatCurrency(item?.retail_price) || '--'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatCurrency(item?.dealer_price) || '--'}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item?.purchased_date}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {item?.depreciation_months_count}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {formatCurrency(item?.monthly_depreciation_value)}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <Typography
                      style={{
                        color: item?.svd_font_color ?? 'black'
                      }}
                    >
                      {formatCurrency(item?.suggested_depreciated_value)}
                    </Typography>
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const DecayingStockTableList = React.memo(component);
