import React, { FC, useCallback, useEffect, useState } from 'react';
import {
  Button,
  Container,
  makeStyles,
  TextField,
  Grid,
  CardHeader,
  Card,
  Divider,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { DragAndDropImgUpload, Page } from 'src/components';
import {
  allowStringOnlyInput,
  convertImageFileToBase64,
  yearOptions
} from 'src/utils';
import { Autocomplete } from '@material-ui/lab';
import { contentType } from '../constant';
import { useBlog } from 'src/hooks/blogs/use-blog';
import { CreateBlogPayload } from 'src/redux/slices/blogs/types';
import { useBlogCategory } from 'src/hooks/blog-category';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  imgPreview: {
    display: 'grid',
    placeContent: 'center',
    padding: '1rem',
    width: '100%',
    height: 'auto'
  }
}));

interface Props {}

export const CreateBlogView: FC<Props> = () => {
  const { createBlog } = useBlog();
  const { blogCategoryOptions, getBlogCategoryListOption } = useBlogCategory();

  const classes = useStyles();

  const [thumbnail, setThumbnail] = useState<any>();
  const [inputContentValue, setInputContentValue] = useState<string>('');

  const formik = useFormik({
    initialValues: {
      title: '',
      brief_intro: '',
      author: '',
      content_type: '',
      year_published: 2024,
      blog_category_id: '',
      thumbnail_url: ''
    },
    validationSchema: validationSchema,
    onSubmit: (values: CreateBlogPayload) => {
      createBlog(values);
    }
  });

  //TODO: add thumbnail image errors
  const onChangeThumbnail = useCallback(
    async (img: File[]) => {
      if (img && img?.length > 0) {
        const firstFile = img[0];
        const imgInTxt: any = await convertImageFileToBase64(firstFile);
        setThumbnail(imgInTxt);
        formik.setFieldValue('thumbnail_url', imgInTxt ? imgInTxt : '');
      }
    },
    [formik]
  );

  const onHandleInputChangeContentType = (value: string) => {
    if (allowStringOnlyInput(value) || value === '') {
      setInputContentValue(value);
      formik.setFieldValue('content_type', value);
    }
  };

  useEffect(() => {
    getBlogCategoryListOption({ list: 'active', page: 1 });
  }, [getBlogCategoryListOption]);

  return (
    <Page title="Create Blog" className={classes.root}>
      <Container maxWidth={false}>
        <Card style={{ padding: '1rem' }}>
          <CardHeader
            title="New Blog"
            subheader="The information of new blog."
          />
          <Divider />

          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              spacing={2}
              style={{ marginTop: '1rem', marginBottom: '1rem' }}
            >
              <Grid item xs={12} md={12}>
                {thumbnail && (
                  <Box className={classes.imgPreview}>
                    <img
                      alt="Cover Image"
                      src={thumbnail}
                      style={{ height: 300, width: undefined }}
                    />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <DragAndDropImgUpload
                  title="Drag or select photos for blog thumbnail"
                  onImageSelected={onChangeThumbnail}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="title"
                  name="title"
                  label="Blog Title"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.title && Boolean(formik.errors.title)}
                  helperText={formik.touched.title && formik.errors.title}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="author"
                  name="author"
                  label="Author"
                  value={formik.values.author}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.author && Boolean(formik.errors.author)}
                  helperText={formik.touched.author && formik.errors.author}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="content-type-list"
                  options={contentType}
                  fullWidth
                  freeSolo
                  value={formik.values.content_type} // Formik's current value
                  inputValue={inputContentValue} // Controlled input value
                  getOptionLabel={(option) => option}
                  onChange={(event, newValue) => {
                    // Update Formik state on option select
                    formik.setFieldValue('content_type', newValue || '');
                  }}
                  onInputChange={(event, newInputValue) =>
                    onHandleInputChangeContentType(newInputValue)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Content Type"
                      variant="outlined"
                      error={
                        formik.touched.content_type &&
                        Boolean(formik.errors.content_type)
                      }
                      helperText={
                        formik.touched.content_type &&
                        formik.errors.content_type
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Autocomplete
                  id="category-list"
                  options={blogCategoryOptions}
                  fullWidth
                  getOptionLabel={(option) => option?.name || ''}
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      'blog_category_id',
                      newValue ? newValue.id : ''
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Blog Category"
                      variant="outlined"
                      error={
                        formik.touched.blog_category_id &&
                        Boolean(formik.errors.blog_category_id)
                      }
                      helperText={
                        formik.touched.blog_category_id &&
                        formik.errors.blog_category_id
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel id="year-published-label">
                    Year Published
                  </InputLabel>
                  <Select
                    labelId="year-published-label"
                    id="year_published"
                    name="year_published"
                    value={formik.values.year_published}
                    onChange={formik.handleChange}
                    label="Year Published"
                    error={
                      formik.touched.year_published &&
                      Boolean(formik.errors.year_published)
                    }
                  >
                    {yearOptions.map((year) => (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} md={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  multiline
                  id="brief_intro"
                  name="brief_intro"
                  label="Brief Intro"
                  value={formik.values.brief_intro}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.brief_intro &&
                    Boolean(formik.errors.brief_intro)
                  }
                  helperText={
                    formik.touched.brief_intro && formik.errors.brief_intro
                  }
                />
              </Grid>
            </Grid>

            <Button color="primary" variant="contained" fullWidth type="submit">
              Create
            </Button>
          </form>
        </Card>
      </Container>
    </Page>
  );
};

const validationSchema = Yup.object({
  title: Yup.string()
    .required('Blog title is required')
    .min(2, 'Title is too short - should be 2 chars minimum.')
    .max(150, 'Title cannot exceed 150 characters')
    .test(
      'is-not-numeric',
      'This field must contain letters and cannot be only numbers',
      (value) => isNaN(Number(value))
    ),
  brief_intro: Yup.string()
    .required('Brief intro is required')
    .min(20, 'Brief intro is too short - should be 20 chars minimum.')
    .max(500, 'Brief intro cannot exceed 500 characters')
    .test(
      'is-not-numeric',
      'This field must contain letters and cannot be only numbers',
      (value) => isNaN(Number(value))
    ),
  author: Yup.string()
    .required('Author is required')
    .min(2, 'Author name is too short - should be 2 chars minimum.')
    .max(100, 'Author name cannot exceed 100 characters')
    .test(
      'is-not-numeric',
      'This field must contain letters and cannot be only numbers',
      (value) => isNaN(Number(value))
    ),
  content_type: Yup.string()
    .required('Pleaes select content type')
    .min(2, 'Content type is too short - should be 2 chars minimum.')
    .max(100, 'Content type cannot exceed 100 characters'),
  blog_category_id: Yup.string().required('Please select blog category'),
  year_published: Yup.number().required('Year published is required')
});
