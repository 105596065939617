import React from 'react';
import { AddressDetails } from 'src/types/pcw-user-address';
import CurrentAddressCard from './CurrentAddress';

interface Props {
  address: AddressDetails;
  onAddressPress: (address_Id: any) => void;
  listDisplay?: boolean;
}

export const UserAddressList = ({
  address,
  onAddressPress,
  listDisplay
}: Props) => {
  return (
    <div
      style={{ cursor: 'pointer', marginBottom: '-30px' }}
      onClick={() => onAddressPress(address?.id)}
    >
      <CurrentAddressCard addressDetail={address} isList={listDisplay} />
    </div>
  );
};
