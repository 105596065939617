import { createSlice } from '@reduxjs/toolkit';
import { PcwUserAccountState } from 'src/types';
import * as thunks from './thunks';

const initialState: PcwUserAccountState = {
  userAccountData: [],
  userAccountProfileData: {}
};

const slice = createSlice({
  name: 'pcwUserAccount',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getPcwUserAccount.fulfilled,
      (state, { payload }) => {
        state.userAccountData = payload?.originalData?.data || [];
      }
    );
    builder.addCase(
      thunks.getPcwAccountProfileThunk.fulfilled,
      (state, { payload }) => {
        state.userAccountProfileData = payload?.originalData?.data || {};
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
