import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { useAlertGlobal } from 'src/hooks';
import { slices, useAppSelector } from 'src/redux';

const { selectors: appSelectors } = slices.app;

export const AlertGlobal = () => {
  const alertGlobal = useAlertGlobal();
  const snackBarSettings = useAppSelector(appSelectors.selectAlertGlobal);

  const handleClose = () => {
    alertGlobal.hide();
  };

  const Buttons = useCallback(() => {
    if (snackBarSettings?.buttons && snackBarSettings?.buttons?.length > 0) {
      return (
        <>
          {snackBarSettings?.buttons?.map((btn, i) => (
            <Button key={i} onClick={btn?.onClick} color={btn?.color} autoFocus>
              {btn?.text || ''}
            </Button>
          ))}
        </>
      );
    }
    return (
      <Button onClick={handleClose} color="primary" autoFocus>
        OK
      </Button>
    );
  }, [snackBarSettings?.buttons]);

  return (
    <Dialog
      open={snackBarSettings?.open || false}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {snackBarSettings?.title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {snackBarSettings?.subtitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Buttons />
      </DialogActions>
    </Dialog>
  );
};
