import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectTransferStockState = (state: RootState) => state.transferStock;

const selectGetProdSnLoading = createSelector(
  selectTransferStockState,
  (state) => state?.isProductSnLoading
);

const selectTransferStockLoading = createSelector(
  selectTransferStockState,
  (state) => state?.isTransferStockLoading
);
const selectTransferStockRecordsLoading = createSelector(
  selectTransferStockState,
  (state) => state?.isTransferStockRecordLoading
);

const selectOriginListId = createSelector(
  selectTransferStockState,
  (state) => state?.originBranchListingId
);

const selectReceivingListId = createSelector(
  selectTransferStockState,
  (state) => state?.receivingBranchListingId
);

const selectOriginBranchListProduct = createSelector(
  selectTransferStockState,
  (state) => state?.originBranchItemList || []
);

const selectReceivingBranchListProduct = createSelector(
  selectTransferStockState,
  (state) => state?.receivingBranchItemList || []
);

const selectTransferStockReceivingProducts = createSelector(
  selectTransferStockState,
  (state) => state?.transferStockReceivingProducts || []
);

const selectCurrentTransferStockNumber = createSelector(
  selectTransferStockState,
  (state) => state?.transferStockNumber || ''
);

const selectReceivedStatus = createSelector(
  selectTransferStockState,
  (state) => state?.receivedStatus || ''
);

const selectCurrentOriginBranch = createSelector(
  selectTransferStockState,
  (state) => state?.selectedBranch || {}
);

const selectCurrentNonSnProduct = createSelector(
  selectTransferStockState,
  (state) => state?.currentNonSnList || []
);

const transferStockStatusProductReceived = createSelector(
  selectTransferStockState,
  (state) => state?.transferStockStatusProductReceived
);

const transferStockNonSnStNos = createSelector(
  selectTransferStockState,
  (state) => state?.nonSnProductStNos
);

const transferReceivingSnErrors = createSelector(
  selectTransferStockState,
  (state) => state?.transferSnErrorList
);

export const selectors = {
  selectGetProdSnLoading,
  selectTransferStockLoading,
  selectTransferStockRecordsLoading,
  selectOriginListId,
  selectReceivingListId,
  selectOriginBranchListProduct,
  selectReceivingBranchListProduct,
  selectTransferStockReceivingProducts,
  selectCurrentTransferStockNumber,
  selectReceivedStatus,
  selectCurrentOriginBranch,
  selectCurrentNonSnProduct,

  //transfer stock receiving module revamp
  transferStockStatusProductReceived,
  transferStockNonSnStNos,
  transferReceivingSnErrors
};
