import React from 'react';
import { Container, makeStyles} from '@material-ui/core';
import { Page } from 'src/components';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  avatar: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: 500,
  }
}));

const MaintenanceView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <img
          alt="PC Worth"
          className={classes.avatar}
          src="/static/images/avatars/maintenance.jpg"
        />
      </Container>
    </Page>
  );
};

export default MaintenanceView;
