import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import { CommonAuditsResponse } from 'src/types/audit';

export const getAuditsThunk = createAsyncThunk<
  CommonAxiosResponse<CommonAuditsResponse>,
  undefined,
  { state: RootState }
>('audit/getAudits', async (data) => {
  const response = await Api.get('/audits/getAudits', data);
  if (!response.success) {
    throw new Error('Failed at getAuditsThunk.');
  }
  return response;
});

// export const createAuditThunk = createAsyncThunk<
//   CommonAxiosResponse<CreateAuditsResponse>,
//   CreateAuditRequest,
//   { state: RootState }
// >('audit/createAudit', async (data) => {
//   const response = await Api.post(`/audits`, data);
//   if (!response.success) {
//     throw new Error('Failed at createAuditThunk.');
//   }
//   return response;
// });

// export const updateAuditThunk = createAsyncThunk<
//   CommonAxiosResponse<UpdateAuditResponse>,
//   UpdateAuditThunkRequest,
//   { state: RootState }
// >('audit/updateAuditThunk', async (data) => {
//   const response = await Api.patch(`/audits/${data.id}`, data);
//   if (!response.success) {
//     throw new Error('Failed to at updateAuditThunk.');
//   }
//   return response;
// });

// export const auditDeleteThunk = createAsyncThunk<
//   CommonAxiosResponse<DeleteAuditResponse>,
//   number,
//   { state: RootState }
// >('audits/auditDeleteThunk', async (id) => {
//   const response = await Api.delete(`/audits/${id}`);
//   // if (!response.success) {
//   //   throw new Error('Failed at auditDeleteThunk.');
//   // }
//   return response;
// });
