import React, { FC, memo, useEffect } from 'react';
import { BlogListCard } from './BlogListCard';
import { useBlog } from 'src/hooks/blogs/use-blog';
import { Box, LinearProgress } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { SearchResultNotFound } from 'src/components';

interface Props {}

const component: FC<Props> = () => {
  const {
    blogListLoading,
    blogListMeta,
    blogListData,
    currentPage,
    getBlogList
  } = useBlog();

  const onHandlePagechange = (event: React.ChangeEvent<any>, value: number) => {
    getBlogList({ page: value });
  };

  const refreshPage = () => {
    window.location.reload();
  };

  useEffect(() => {
    getBlogList();
  }, [getBlogList]);

  if (blogListData && blogListData?.length === 0) {
    return (
      <SearchResultNotFound
        title={`Unable to find any blog posts. Please try again with different
          keywords`}
        onHandleRefresh={refreshPage}
      />
    );
  }

  return (
    <div>
      {blogListLoading && <LinearProgress />}
      {blogListData?.map((blog, index) => (
        <BlogListCard key={index} blogList={blog} uuid={blog?.uuid} />
      ))}

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', p: '1rem' }}>
        <Pagination
          count={blogListMeta?.last_page}
          page={currentPage}
          onChange={onHandlePagechange}
        />
      </Box>
    </div>
  );
};

export const BlogList = memo(component);
