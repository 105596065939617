import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

interface Props {
  title?: string;
  subTitle?: string;
  isVisible: boolean;
  handleClose?: () => void;
  customButtons?: JSX.Element;
}

export const AlertDialog = ({
  title,
  subTitle,
  customButtons,
  isVisible,
  handleClose
}: Props) => {
  return (
    <Dialog
      open={isVisible}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {subTitle}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {customButtons ? (
          customButtons
        ) : (
          <>
            <Button onClick={handleClose} color="primary" autoFocus>
              OK
            </Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};
