import React, { FC, memo } from 'react';
import { Box, Button, Paper, makeStyles } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { downloadCSV, formatCurrency } from 'src/utils';
import { FastMovingProductsData } from 'src/redux/slices/fast-moving-products';
import { fastMovingProductRowHeader } from 'src/constants/table';
import { HideTableRowSelector } from 'src/components';

const useStyles = makeStyles({
  flexBox: {
    display: 'flex',
    alignItems: 'center'
  }
});

interface Props {
  columnIndicesToHide?: number[];
  fastMovingProductToCsv: FastMovingProductsData[];
  columnToHide: (indices: number[]) => void;
}

const component: FC<Props> = ({
  columnIndicesToHide,
  fastMovingProductToCsv,
  columnToHide
}) => {
  const classes = useStyles();

  //if column is hidden undefined
  const checkDataIsHiddenOnColumn = (index: number, data?: any) => {
    return !columnIndicesToHide?.includes(index) ? data : undefined;
  };

  const onExportToCSVFastMovingProducts = () => {
    //fastMovingProductRowHeader check this constant
    //This is the reference for index
    const fastMovingProdCsv = fastMovingProductToCsv
      .map((item) => {
        return {
          Branches_Sold: checkDataIsHiddenOnColumn(0, item?.branches_sold),
          Product_Name: checkDataIsHiddenOnColumn(1, item?.product_name),
          Last_Dealer_Price: checkDataIsHiddenOnColumn(
            2,
            formatCurrency(item?.last_dealer_price)
          ),
          Last_Retail_Price: checkDataIsHiddenOnColumn(
            3,
            formatCurrency(item?.last_retail_price)
          ),
          Gross: checkDataIsHiddenOnColumn(4, item?.gross),
          Margin: checkDataIsHiddenOnColumn(5, item?.margin_percentage),
          Suppliers: checkDataIsHiddenOnColumn(6, item?.suppliers),
          Sold_Quantity: checkDataIsHiddenOnColumn(7, item?.sold_quantity),
          Available_Stocks: checkDataIsHiddenOnColumn(8, item?.available_stocks)
        };
      })
      .map((item) => {
        // map again to filter out undefined values from the object
        const filteredItem = Object.fromEntries(
          // eslint-disable-next-line no-unused-vars
          Object.entries(item).filter(([_, value]) => value !== undefined)
        );

        return filteredItem;
      });

    const csvName = 'FastMovingProduct_PC_Worth.csv';

    downloadCSV(fastMovingProdCsv, csvName);
  };

  return (
    <Paper style={{ marginTop: '1rem' }}>
      <Box
        className={classes.flexBox}
        padding={'1rem'}
        justifyContent={'space-between'}
      >
        <Button
          variant="outlined"
          color="primary"
          startIcon={<GetAppIcon />}
          onClick={onExportToCSVFastMovingProducts}
        >
          Export CSV
        </Button>
        <HideTableRowSelector
          subLabel="Fast Moving Column Filter"
          columnHeaderOptions={fastMovingProductRowHeader}
          selectedColumnToHide={(indices: number[]) => columnToHide(indices)}
        />
      </Box>
    </Paper>
  );
};

export const FastMovingProductSubToolbar = memo(component);


