import React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { CustomInputEvent, CustomKeyboardEvent } from 'src/types';
import { CategoryListDropdown } from 'src/components/filters/CategoryDropdown';

interface Props {
  onFilterPress?: () => void;
  className?: string;
  onSearchProduct: (text?: string, category_id?: number) => void;
  onCategoryChange: (category_id?: number) => void;
  searchInput: string;
  categoryId?: number;
  onSearchInputChange: (e: CustomInputEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  }
}));

const component = ({
  className,
  searchInput,
  categoryId,
  onSearchProduct,
  onSearchInputChange,
  onCategoryChange,
  ...rest
}: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleChange = (event: CustomInputEvent) => {
    onSearchInputChange(event);
  };

  const onSearchClick = () => {
    if (categoryId !== 0) {
      onSearchProduct(searchInput, categoryId);
    } else {
      onSearchProduct(searchInput);
    }
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      if (categoryId !== 0) {
        onSearchProduct(searchInput, categoryId);
      } else {
        onSearchProduct(searchInput);
      }
    }
  };

  const onHandleChangeCategory = (category_id?: number) => {
    onCategoryChange(category_id);
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography color="textPrimary" gutterBottom variant="h3">
          Products
        </Typography>
        <Button
          onClick={() => navigate('/app/products/add')}
          color="primary"
          variant="contained"
        >
          add product
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={4} md={3} xs={4}>
                <Box
                  maxWidth={500}
                  sx={{ display: 'flex', flexDirection: 'row' }}
                >
                  <TextField
                    style={{
                      borderTopLeftRadius: 10,
                      borderBottomLeftRadius: 10
                    }}
                    fullWidth
                    value={searchInput}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder={'Search via product/SKU'}
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>

              <Grid item sm={4} md={3} xs={4}>
                <Box maxWidth={500}>
                  <CategoryListDropdown
                    onHandleCategories={(categoryId: number) =>
                      onHandleChangeCategory(categoryId)
                    }
                  />
                </Box>
              </Grid>

              <Grid alignItems="center" item xs={1} md={1}>
                <Button
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export const Toolbar = React.memo(component);
