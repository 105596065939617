import React from 'react';
import {
  Box,
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Rma } from 'src/types';
import { formatDate } from 'src/utils';
import { LinkComponent, RmaChip } from 'src/components';
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  rmasCard: {
    marginTop: theme.spacing(3)
  },
  rmaCardTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  }
}));

interface Props {
  rmas?: Rma[];
}

const RMACard = ({ rmas }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.rmasCard}>
      <Typography
        className={classes.rmaCardTitle}
        color="textPrimary"
        variant="h5"
      >
        Transaction RMA History
      </Typography>
      <Card title="Rma">
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>RMA ID</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Old Product Name</TableCell>
                  <TableCell>New Product Name</TableCell>
                  <TableCell>Old SN</TableCell>
                  <TableCell>New SN</TableCell>
                  <TableCell>Date Created</TableCell>
                  <TableCell>Remarks</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rmas?.map((item: Rma) => (
                  <TableRow hover key={item.id}>
                    <TableCell>
                      <LinkComponent
                        onClick={() => navigate(`/app/rma/${item.id}`)}
                        href={`/app/rma/${item.id}`}
                        title={`${item?.rma_id ?? '--'}`}
                      />
                    </TableCell>
                    <TableCell>
                      <RmaChip rma={item} />
                    </TableCell>
                    <TableCell>
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/products/${item?.old_product_id}`)
                        }
                        href={`/app/products/${item?.old_product_id}`}
                        title={item?.old_product_name || '--'}
                      />
                    </TableCell>
                    <TableCell>
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/products/${item?.new_product_id}`)
                        }
                        href={`/app/products/${item?.new_product_id}`}
                        title={item?.new_product_name || '--'}
                      />
                    </TableCell>
                    <TableCell>
                      <LinkComponent
                        onClick={() =>
                          navigate(
                            `/app/products-listings/${item?.old_listing_id}`
                          )
                        }
                        href={`/app/products-listings/${item?.old_listing_id}`}
                        title={item?.orig_listing_serial_no || '--'}
                      />
                    </TableCell>
                    <TableCell>
                      <LinkComponent
                        onClick={() =>
                          navigate(
                            `/app/products-listings/${item?.new_listing_id}`
                          )
                        }
                        href={`/app/products-listings/${item?.new_listing_id}`}
                        title={item?.new_listing_serial_no || '--'}
                      />
                    </TableCell>
                    <TableCell>
                      {item?.created_at ? formatDate(item?.created_at) : '--'}
                    </TableCell>
                    <TableCell>{item?.remarks || '--'}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </div>
  );
};

export const TransactionsRmaCard = React.memo(RMACard);
