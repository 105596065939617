import React, { FC, memo } from 'react';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import { Editor } from '@tiptap/react';

const fontFamilyOpt = [
  { name: 'ROBOTO', value: 'Roboto' },
  { name: 'ARIAL', value: 'Arial' },
  { name: 'HELVETICA', value: 'Helvetica' },
  { name: 'COMIC SANS MS', value: 'Comic Sans MS, Comic Sans' },
  { name: 'SERIF', value: 'serif' },
  { name: 'MONOSPACE', value: 'monospace' }
];

interface Props {
  editor: Editor | null;
}

const component: FC<Props> = ({ editor }) => {
  const [selectedValue, setSelectedValue] = React.useState('Roboto');

  const handleChange = (event: React.ChangeEvent<{ value: any }>) => {
    if (!editor) {
      return null;
    }
    setSelectedValue(event.target.value);
    editor
      .chain()
      .focus()
      .setFontFamily(event.target.value)
      .run();
  };

  return (
    <FormControl>
      <Select
        value={selectedValue}
        onChange={handleChange}
        style={{ minWidth: 100, padding: 0, maxHeight: 30 }}
        variant="outlined"
        color="primary"
      >
        {fontFamilyOpt.map((fontItem, index) => (
          <MenuItem key={index} value={fontItem.value}>
            {fontItem.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const TextFontFamilyOption = memo(component);
