import { PartFields } from 'src/types';
import { PsuPartDetailsFields } from 'src/types';

export const psuPartsFields: PartFields<PsuPartDetailsFields>[] = [
  {
    label: 'Form Factor',
    part_property: 'form_factor',
    type: 'string',
    options: ['M-ATX', 'ATX', 'ITX', 'EATX'],
    transformRule: ['all-caps', 'no-spaces'],
    required: true
  },
  {
    label: 'Wattage',
    part_property: 'wattage',
    hint: '1000W, 500W',
    options: ['1000', '1050', '1250', '450', '550', '650', '750', '850'],
    type: 'number',
    required: true,
    unit: 'W'
  },
  {
    label: 'Energy Efficiency',
    part_property: 'energy_efficient',
    type: 'string',
    options: [
      '80+BRONZE',
      '80+SILVER',
      '80+GOLD',
      '80+PLATINUM',
      '80+TITANIUM'
    ],
    transformRule: ['no-spaces', 'all-caps'],
    required: false
  },
  {
    label: 'Modular',
    part_property: 'modular',
    type: 'boolean',
    required: false
  },
  {
    label: 'Image',
    part_property: 'image_upload',
    hint: 'Select Product Image. Only one is allowed for now',
    type: 'img',
    required: false
  }
];
