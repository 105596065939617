import { colors } from 'src/constants';
import {
  EcomOrderStatusEnum,
  ListingPriceTypeEnum,
  ListingStatusEnum,
  UserStatusEnum
} from 'src/enums';
import { RmaStatusEnum } from 'src/enums/rma';
import { EcomOrderStatusType } from 'src/types';

export const getFluctuationColor = (
  fluctuation?: 'increase' | 'increased' | 'decrease' | 'decreased'
) => {
  switch (fluctuation) {
    case 'increase':
    case 'increased':
      return colors.error;
    case 'decrease':
    case 'decreased':
      return colors.success;
    default:
      return colors.normal;
  }
};

export const getOrderStatusColor = (status?: EcomOrderStatusType) => {
  switch (status) {
    case EcomOrderStatusEnum.Completed:
    case EcomOrderStatusEnum.Paid:
      return colors.success;
    case EcomOrderStatusEnum.Pending:
      return colors.warning;
    case EcomOrderStatusEnum.Cancelled:
    case EcomOrderStatusEnum.Expired:
      return colors.error;
    case EcomOrderStatusEnum.Processing:
      return colors.skyBlue;
    default:
      return colors.normal;
  }
};

export const getStatusColor = (status?: ListingStatusEnum | string) => {
  switch (status) {
    case ListingStatusEnum.Available:
      return colors.success;
    case ListingStatusEnum.OnHold:
      return colors.warning;
    case ListingStatusEnum.Defective:
    case ListingStatusEnum.ForWarranty:
      return colors.error;
    case ListingStatusEnum.Purchased:
      return colors.skyBlue;
    default:
      return colors.normal;
  }
};

export const getGrossColor = (price: number) => {
  if (price === 0) {
    return colors.warning;
  }
  if (price < 0) {
    return colors.error;
  }
  if (price) {
    return colors.success;
  }
  return colors.black;
};

export const getRmaStatusColor = (status?: RmaStatusEnum) => {
  switch (status) {
    case RmaStatusEnum.Repaired:
    case RmaStatusEnum.Replaced:
    case RmaStatusEnum.TestedGood:
      return colors.success;
    case RmaStatusEnum.Void:
      return colors.error;
    case RmaStatusEnum.Others:
    case RmaStatusEnum.Refund:
    case RmaStatusEnum.Processing:
      return colors.skyBlue;
    default:
      return colors.normal;
    // return colors.warning;
  }
};

export const getListingFreebieColor = (status?: number) => {
  switch (status) {
    case ListingPriceTypeEnum.NotFree:
      return colors.orange[500];
    case ListingPriceTypeEnum.FreeFromSupplier:
      return colors.green[500];
    case ListingPriceTypeEnum.FreeForAll:
      return colors.green[700];
    default:
      return colors.normal;
  }
};

export const getPaymentTypeColor = (paymentType?: string) => {
  switch (paymentType) {
    case 'BDO':
    case 'GCASH':
      return colors.blue[500];
    case 'BILLEASE':
      return colors.red[500];
    case 'PAYMAYA':
    case 'CASH':
    case 'EXCESS':
    case 'PAYMONGO':
      return colors.green[500];
    case 'SHIPPING':
      return colors.orange[500];
    default:
      return colors.normal;
  }
};

export const getOrderPaymentStatusColor = (status?: string) => {
  switch (status) {
    case 'paid':
      return colors.success;
    case 'unpaid':
      return colors.warning;
    default:
      return colors.normal;
  }
};

export const getColorGeneric = (status?: 'good' | 'warn' | 'bad') => {
  switch (status) {
    case 'good':
      return colors.success;
    case 'warn':
      return colors.warning;
    case 'bad':
      return colors.error;
    default:
      return colors.normal;
  }
};

// NOTE:
// 9.5 - above good - green
// 9.0 - 9.4 moderate - orange
// below - 9.0 bad - red
export const marginColorViaGross = (marginPercent?: string | number) => {
  if (marginPercent === undefined) {
    return 'bad';
  }
  let numMarginPercent: number;
  if (typeof marginPercent === 'string') {
    numMarginPercent = parseFloat(marginPercent);
    if (isNaN(numMarginPercent)) {
      return 'bad';
    }
  } else {
    numMarginPercent = marginPercent;
  }

  let status: 'good' | 'warn' | 'bad';
  if (numMarginPercent < 6) {
    status = 'bad';
  } else if (numMarginPercent < 10) {
    status = 'warn';
  } else {
    status = 'good';
  }

  return getColorGeneric(status);
};

export const getUserStatusColor = (status?: UserStatusEnum | string) => {
  switch (status) {
    case UserStatusEnum.Confirmed:
      return colors.success;
    case UserStatusEnum.Pending:
      return colors.warning;
    default:
      return colors.normal;
  }
};
