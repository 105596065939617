import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Formik, FormikHelpers } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Page } from 'src/components';
import { Autocomplete } from '@material-ui/lab';
import { CreateUserRequest, Role } from 'src/types';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSnackBar } from 'src/hooks';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';

const { actions: userActions } = slices.user;
const { actions: roleActions, selectors: roleSelectors } = slices.roles;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%'
  },
  userSpacing: {
    paddingTop: 14
  }
}));

type FormFields = {
  firstName: string;
  lastName: string;
  userName: string;
  password: string;
  confirmPassword: string;
};

const CreateUserView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const roleFieldRef = useRef(null);
  const branchFieldRef = useRef(null);
  const snackBar = useSnackBar();
  const {
    branches,
    selectedBranch,
    setSelectedBranch,
    getBranches
  } = useBranchInfo();

  const roles = useAppSelector(roleSelectors.selectRoleList);

  const [selectedRole, setSelectedRole] = useState<Role | null>();
  const [userRole, setUserRole] = useState('');
  const [userBranch, setUserBranch] = useState<string>('');

  const resetFields = () => {
    setSelectedRole(undefined);
    setUserRole('');
    setSelectedBranch(null);
    setUserBranch('');
  };

  const onSubmit = async (
    values: FormFields,
    formikHelpers: FormikHelpers<FormFields>
  ) => {
    if (!selectedRole?.id) {
      snackBar.show({ severity: 'error', message: 'Select a Role' });
      return;
    }

    if (!selectedBranch?.id && multiBranchFeat) {
      snackBar.show({ severity: 'error', message: 'Select a Branch' });
      return;
    }

    const data: CreateUserRequest = {
      first_name: values.firstName,
      last_name: values.lastName,
      username: values.userName,
      password: values.password,
      confirm_password: values.confirmPassword,
      role_id: selectedRole?.id,
      branch_ids: selectedBranch?.id ? [selectedBranch?.id] : []
    };

    const response = unwrapResult(
      await dispatch(userActions.createUserThunk(data)).finally(() => {
        formikHelpers.setSubmitting(false);
        resetFields();
      })
    );

    if (response?.success) {
      snackBar.show({
        severity: 'success',
        message: response?.message || 'User created.'
      });
      formikHelpers.setValues(
        {
          firstName: '',
          lastName: '',
          userName: '',
          password: '',
          confirmPassword: ''
        },
        false
      );
    } else {
      snackBar.show({
        severity: 'error',
        message: response?.message || 'User creation failed.'
      });
    }
  };

  useEffect(() => {
    dispatch(roleActions.getRolesThunk());
    getBranches();
  }, [dispatch, getBranches]);

  return (
    <Page className={classes.root} title="Create User">
      <Container maxWidth="sm">
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            userName: '',
            password: '',
            confirmPassword: ''
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .max(255)
              .required('First name is required'),
            lastName: Yup.string()
              .max(255)
              .required('Last name is required'),
            userName: Yup.string()
              .max(255)
              .required('Username is required'),
            password: Yup.string()
              .min(8)
              .max(15)
              .required('password is required'),
            confirmPassword: Yup.string()
              .min(8)
              .max(15)
              .required('Confirm password is required')
              .oneOf([Yup.ref('password'), ''], 'Passwords must match')
          })}
          onSubmit={onSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }) => (
            <form onSubmit={handleSubmit}>
              <Box mb={3}>
                <Typography color="textPrimary" variant="h2">
                  Create new user
                </Typography>
              </Box>
              <TextField
                error={Boolean(touched.firstName && errors.firstName)}
                fullWidth
                helperText={touched.firstName && errors.firstName}
                label="First name"
                margin="normal"
                name="firstName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.firstName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.lastName && errors.lastName)}
                fullWidth
                helperText={touched.lastName && errors.lastName}
                label="Last name"
                margin="normal"
                name="lastName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.lastName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.userName && errors.userName)}
                fullWidth
                helperText={touched.userName && errors.userName}
                label="Username"
                margin="normal"
                name="userName"
                onBlur={handleBlur}
                onChange={handleChange}
                value={values.userName}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              <TextField
                error={Boolean(
                  touched.confirmPassword && errors.confirmPassword
                )}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                label="Confirm Password"
                margin="normal"
                name="confirmPassword"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.confirmPassword}
                variant="outlined"
              />
              <Autocomplete
                className={classes.userSpacing}
                ref={roleFieldRef}
                value={selectedRole}
                onChange={(event, newValue) => {
                  setSelectedRole(newValue);
                }}
                inputValue={userRole}
                onInputChange={(event, newInputValue) => {
                  setUserRole(newInputValue);
                }}
                noOptionsText={`"${userRole}" is an invalid role`}
                id="UserRole"
                options={roles}
                getOptionLabel={(option) => option?.name || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="User Role"
                    variant="outlined"
                  />
                )}
              />
              {multiBranchFeat ? (
                <Autocomplete
                  className={classes.userSpacing}
                  ref={branchFieldRef}
                  value={selectedBranch}
                  onChange={(event, newValue) => {
                    setSelectedBranch(newValue);
                  }}
                  inputValue={userBranch}
                  onInputChange={(event, newInputValue) => {
                    setUserBranch(newInputValue);
                  }}
                  noOptionsText={`"${userBranch}" is an invalid role`}
                  id="User Branch"
                  options={branches}
                  getOptionLabel={(option) => option?.branch_name || ''}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      label="Branch"
                      variant="outlined"
                    />
                  )}
                />
              ) : null}
              <Box my={2}>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Create User
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

export default CreateUserView;
