import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { ProductWithSerialNo } from 'src/types';

interface Props {
  productOptions: ProductWithSerialNo[];
  productLoading?: boolean;
  productNameInput?: string;
  selectedProduct?: ProductWithSerialNo | null;
  setSelectedProduct: (product: ProductWithSerialNo | null) => void;
  isDisabled: () => boolean;
  onProdInputChange: (inputName?: string) => void;
  onFocus: () => void;
}

const component = ({
  productOptions,
  productLoading,
  productNameInput,
  selectedProduct,
  setSelectedProduct,
  onFocus,
  isDisabled,
  onProdInputChange
}: Props) => {
  return (
    <div>
      <Autocomplete
        fullWidth
        loading={productLoading}
        disabled={isDisabled()}
        onFocus={() => onFocus()}
        value={selectedProduct}
        onChange={(_, newValue) => setSelectedProduct(newValue)}
        inputValue={productNameInput}
        onInputChange={(_, newInputValue) => onProdInputChange(newInputValue)}
        id="product_name"
        options={productOptions}
        getOptionLabel={(option) =>
          `${option?.product_name} -> ${option?.serial_no} -> ${option?.branch_name}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            helperText="e.g. Fans"
            name="product_name"
            label="Non-serialized - Product Name"
            variant="outlined"
            value={productNameInput}
          />
        )}
      />
    </div>
  );
};

export const NonSerializeProductDropdown = React.memo(component);
