import { Chip } from '@material-ui/core';
import React from 'react';

export const OutOfStockChip = () => {
  return (
    <Chip
      style={{ marginLeft: 10 }}
      label="Sold Out"
      size="small"
      variant="outlined"
      color="secondary"
    />
  );
};
