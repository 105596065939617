/**
 *
 * @param items An array of items.
 * @param fn A function that accepts an item from the array and returns a promise.
 * @returns {Promise}
 */
export const forEachPromise = <T>(items: T[], fn: (item: T, index: number) => void) => {
  return items.reduce(function(promise, item, index) {
    return promise.then(function() {
      return fn(item, index);
    });
  }, Promise.resolve());
};
