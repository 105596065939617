import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import { GetTransactionsViaTransactionNoResponse } from 'src/types';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { Alert } from '@material-ui/lab';
import { SwitchLabel } from 'src/components';

interface Props {
  className?: string;
  isSIPricing?: boolean;
  transactionDetails: GetTransactionsViaTransactionNoResponse | null;
  onUpdateOfTransaction: (
    newData: GetTransactionsViaTransactionNoResponse
  ) => void;
  onSalesInvoicePriceToggle: (v: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  alert: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  switchContainer: {
    margin: theme.spacing(0)
  }
}));

export const RenderDetailEditor = ({
  className,
  isSIPricing,
  transactionDetails,
  onUpdateOfTransaction,
  onSalesInvoicePriceToggle,
  ...rest
}: Props) => {
  const classes = useStyles();

  const [customerName, setCustomerName] = useState<string>(
    () =>
      `${transactionDetails?.customer?.first_name ?? ''} ${transactionDetails
        ?.customer?.last_name ?? ''} `
  );

  const [datePurchased, setDatePurchased] = useState<
    MaterialUiPickersDate | string
  >(() => {
    if (transactionDetails?.items && transactionDetails?.items?.length > 0) {
      if (transactionDetails?.items[0]?.created_at) {
        return transactionDetails?.items[0].created_at;
      }
    }
    return new Date().toString();
  });

  const [
    tempTransactionDetails,
    setTempTransactionDetails
  ] = useState<GetTransactionsViaTransactionNoResponse | null>(
    transactionDetails
  );

  const onSaveDetailValidate = async () => {
    // all should be spread except date and customer name
    let newData: GetTransactionsViaTransactionNoResponse = {
      ...transactionDetails,
      // clear Mname and Lname to remove redundancy
      customer: {
        ...transactionDetails?.customer,
        first_name: customerName,
        middle_name: '',
        last_name: '',
        contact_no: tempTransactionDetails?.customer?.contact_no
      }
    };
    newData?.items?.forEach((x) => {
      x.created_at = datePurchased?.toString();
    });
    newData.payment_mode = tempTransactionDetails?.payment_mode;

    onUpdateOfTransaction(newData);
    // snackBar.show({ severity: 'error', message: localize.ERR_FIELDS });
  };

  const onChangeContactNo = (contactNo: string) => {
    setTempTransactionDetails((prev) => ({
      ...prev,
      customer: {
        ...prev?.customer,
        contact_no: contactNo
      }
    }));
  };

  const onChangePaymentMode = (paymentMode: string) => {
    setTempTransactionDetails((prev) => ({
      ...prev,
      payment_mode: paymentMode
    }));
  };

  return (
    <Box m={2}>
      <Alert className={classes.alert} severity="info">
        Editing Information here would only edit the information that is about
        to be printed.
      </Alert>

      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Customer Name"
                name="name"
                required
                variant="outlined"
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <DatePicker
                fullWidth
                format="MMMM dd, yyyy"
                inputVariant="outlined"
                name="purchase_date"
                label="Date Purchased"
                value={datePurchased}
                onChange={(date) => setDatePurchased(date)}
                animateYearScrolling
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 11 }}
                helperText="Maximum of 11 characters only. e.g. 09174308787"
                type="tel"
                label="Contact No."
                name="contact_no"
                variant="outlined"
                value={tempTransactionDetails?.customer?.contact_no ?? ''}
                onChange={(e) => onChangeContactNo(e.target.value)}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                helperText="Maximum of 40 characters, ALL CAPS, Add spaces. eg: GCASH - 11,000 DP/COD"
                inputProps={{
                  maxLength: 40,
                  style: { textTransform: 'uppercase' }
                }}
                fullWidth
                label="Payment Mode"
                name="payment_mode"
                variant="outlined"
                value={tempTransactionDetails?.payment_mode}
                onChange={(e) => onChangePaymentMode(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <SwitchLabel
                checked={isSIPricing}
                label="Toggle SI Pricing"
                className={classes.switchContainer}
                onChange={onSalesInvoicePriceToggle}
              />
            </Grid>

            {transactionDetails?.isSRPByDefault ? (
              <Grid item xs={12}>
                <Alert className={classes.alert} severity="error">
                  THERE IS A HIGH CHANCE THAT THIS WARRANTY SLIP IS ALREADY SRP
                </Alert>
              </Grid>
            ) : null}
          </Grid>
          <Box display="flex" justifyContent="flex-end" paddingX={2} mt={2}>
            <Button
              onClick={() => onSaveDetailValidate()}
              color="primary"
              variant="contained"
            >
              Update Information
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
