import { RmaStatusEnum } from 'src/enums';
import { RmaStatusOptions } from 'src/types';

export const rmaStatusOptions: RmaStatusOptions[] = [
  {
    id: RmaStatusEnum.Repaired,
    value: RmaStatusEnum.Repaired,
    name: 'Repaired'
  },
  {
    id: RmaStatusEnum.Replaced,
    value: RmaStatusEnum.Replaced,
    name: 'Replaced with item already in inventory'
  },
  {
    id: RmaStatusEnum.Refund,
    value: RmaStatusEnum.Refund,
    name: 'Refunded money to customer'
  },
  {
    id: RmaStatusEnum.TestedGood,
    value: RmaStatusEnum.TestedGood,
    name: 'Tested Good'
  },
  {
    id: RmaStatusEnum.Void,
    value: RmaStatusEnum.Void,
    name: 'Void'
  },
  {
    id: RmaStatusEnum.Processing,
    value: RmaStatusEnum.Processing,
    name: 'Processing'
  },
  {
    id: RmaStatusEnum.Others,
    value: RmaStatusEnum.Others,
    name: 'Others (Please Specify on remarks)'
  }
];
