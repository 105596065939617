import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { SliceStatusValue } from 'src/constants';
import CookieService from 'src/services/CookieService';
import LocalStorageService from 'src/services/LocalStorageService';
import {
  ErrorResponse,
  FormPasscodeDialogState,
  SliceStatus,
  SuccessResponse,
  UserBasicInfo,
} from 'src/types';
import * as thunks from './thunks';

type State = {
  userInfo?: UserBasicInfo;
  users?: UserBasicInfo[];
  formPasscodeDialog?: FormPasscodeDialogState;
  validatePasscodeStatus?: SliceStatus;
  validatePasscodeResponse?: SuccessResponse;
  validatePasscodeError?: ErrorResponse;
};

const initialState: State = {
  userInfo: undefined,
  users: [],
};

const showFormPasscodeDialog = (state: State) => {
  state.formPasscodeDialog = { isVisible: true };
};

const hideFormPasscodeDialog = (state: State) => {
  state.formPasscodeDialog = { isVisible: false };
  state.validatePasscodeStatus = SliceStatusValue.Idle;
  state.validatePasscodeResponse = undefined;
  state.validatePasscodeError = undefined;
};

const validatePasscodeRequest = (state: State) => {
  state.validatePasscodeStatus = SliceStatusValue.Loading;
  state.validatePasscodeResponse = undefined;
  state.validatePasscodeError = undefined;
};

const validatePasscodeResolve = (
  state: State,
  action: PayloadAction<SuccessResponse>
) => {
  state.validatePasscodeStatus = SliceStatusValue.Resolve;
  state.validatePasscodeResponse = action.payload;
};

const validatePasscodeReject = (
  state: State,
  action: PayloadAction<ErrorResponse>
) => {
  state.validatePasscodeStatus = SliceStatusValue.Reject;
  state.validatePasscodeError = action?.payload;
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateAccountInfo: (state, { payload }: PayloadAction<UserBasicInfo>) => {
      state.userInfo = { ...state.userInfo, ...payload };
    },
    showFormPasscodeDialog,
    hideFormPasscodeDialog,
    validatePasscodeRequest,
    validatePasscodeResolve,
    validatePasscodeReject
  },
  extraReducers(builder) {
    builder.addCase(thunks.getAllUsersThunk.fulfilled, (state, { payload }) => {
      state.users = payload?.originalData?.users || [];
    });
    builder.addCase(thunks.deleteUserThunk.fulfilled, (state, action) => {
      const clonedUsers = cloneDeep(state?.users);
      const filtered = clonedUsers?.filter((x) => x.id !== action.meta.arg);
      state.users = filtered;
    });
    builder.addCase(thunks.login.fulfilled, (state, { payload }) => {
      if (payload?.success && payload.originalData?.user) {
        const userInfo: UserBasicInfo = payload.originalData?.user;
        userInfo.loggedInTime = +new Date();
        state.userInfo = userInfo;
        LocalStorageService.setItem('userInfo', userInfo);
        LocalStorageService.setSecureItem({
          key: 'auth-token',
          value: payload?.originalData?.token || '',
          password: 'malformedUTF8 if not string'
        });
      }
    });
    builder.addCase(thunks.logout.rejected, (state) => {
      state.userInfo = undefined;
      LocalStorageService.localStorageClear();
      CookieService.remove('token');
    });
    builder.addCase(thunks.logout.fulfilled, (state) => {
      state.userInfo = undefined;
      LocalStorageService.localStorageClear();
      CookieService.remove('token');
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
