import React, { FC, memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { warrantyTextStyles } from 'src/views/transactions/TransactionPDF/styles';

const styles = StyleSheet.create({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 10,
    padding: 5
  },
  slipTxt: {
    fontSize: 10,
    letterSpacing: 1
  }
});

interface Props {}

const component: FC<Props> = () => {
  return (
    <View style={styles.root}>
      <View>
        <Text style={[warrantyTextStyles.textBold, styles.slipTxt]}>
          QUOTATION SLIP
        </Text>
      </View>
    </View>
  );
};

export const ViewQuotationTitleSlip = memo(component);
