export enum TransferLogsFilterActionEnum {
  Product = 'Product',
  DrNo = 'Dr No.',
  SerialNo = 'Serial No.',
  StNo = 'ST No.'
}

export enum ProductTypeEnum {
  Serialize = 'serialized',
  NonSerialize = 'non-serialized'
}
