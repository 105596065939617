import { SalesColumnForArrTable } from 'src/types';
import { multiBranchFeat } from '../feature-toggle';

const branchColumn: SalesColumnForArrTable = {
  name: 'Branch',
  value: 'branch',
  hideFilter: true
}

export const salesColumnsLocal: SalesColumnForArrTable[] = [
  {
    name: 'ID',
    value: 'id',
    hideFilter: true
  },
  {
    name: 'Date Sold (Customer)',
    value: 'date_sold',
    hideFilter: true
  },
  {
    name: 'Date Purchased (Supplier)',
    value: 'date_purchased',
    hideFilter: true
  },
  {
    name: 'Customer',
    value: 'customer_name',
    hideFilter: true
  },
  {
    name: 'Order No.',
    value: 'order_no',
    hideFilter: true
  },
  {
    name: 'Transaction No.',
    value: 'transaction_no',
    hideFilter: true
  },
  {
    name: 'Warranty Slip No.',
    value: 'warranty_slip_no',
    hideFilter: true
  },
  {
    name: 'AR No',
    value: 'acknowledgement_receipt_no',
    hideFilter: true
  },
  {
    name: 'Products / Items',
    value: 'products',
    hideFilter: true
  },
  {
    name: 'Payments',
    value: 'payments',
    hideFilter: true
  },
  {
    name: 'Amounts',
    value: 'amounts',
    hideFilter: true
  }
];


if (multiBranchFeat) {
  // First param is where the column will be positioned on the table
  salesColumnsLocal.splice(3,0,branchColumn)
}