import React, { FC, memo, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Paper
} from '@material-ui/core';
import { Editor } from '@tiptap/react';
import { TextStyleOptions } from './TextStyleOptions';
import { TextAlginmentOption } from './TextAlignmentOption';
import { ImageOption } from './ImageOption';
import { TextFontFamilyOption } from './TextFontFamilyOption';
import { useProductDescription } from 'src/hooks';
import { HeadingOptionV2 } from './HeadingOptionV2';
import { TableControlMenu } from './TableControlMenu';
import { useBlog } from 'src/hooks/blogs/use-blog';

interface Props {
  editor?: Editor | null;
  productId?: number;
  saveVariant?: 'blog' | 'product';
  reloadProductDetails: () => void;
}

const component: FC<Props> = ({
  editor,
  productId,
  saveVariant,
  reloadProductDetails
}) => {
  const {
    updateProdIsLoading,
    updateProductDescription
  } = useProductDescription();
  const { getBlogDetail, updateBlogContent } = useBlog();

  const [showTableBorderEcomm, setShowTableBorderEcomm] = useState<boolean>(
    false
  );

  if (!editor) {
    return null;
  }

  const handleSaveDescription = () => {
    let htmlString = editor?.getHTML();

    //if not show ecomm remove the border
    if (!showTableBorderEcomm) {
      //remove the border before passing the html string
      // Replace border style for <th> elements
      htmlString = htmlString.replace(
        /<th([^>]*)style="[^"]*"/g,
        '<th$1 style="border:none;"'
      );

      // Replace border style for <td> elements
      htmlString = htmlString.replace(
        /<td([^>]*)style="[^"]*"/g,
        '<td$1 style="border:none;"'
      );
    }

    //image is always fix to take 100 percent of width
    htmlString = htmlString.replace(
      /<img([^>]*)style="[^"]*"/g,
      '<img$1 style="width: 100%; max-width: 100%; height: auto;"'
    );

    if (saveVariant === 'blog') {
      updateBlogContent(htmlString).then(() => getBlogDetail());
      return;
    }

    if (saveVariant === 'product') {
      updateProductDescription({
        product_id: productId,
        description_html: htmlString
      }).then(() => reloadProductDetails()); //reload product details
      return;
    }
  };

  return (
    <Paper
      elevation={1}
      style={{
        padding: '0.5rem',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: '1rem'
      }}
    >
      <Box mr={1}>
        <TextFontFamilyOption editor={editor} />
      </Box>
      <Box mr={1}>
        <HeadingOptionV2 editor={editor} />
      </Box>
      <Box mr={1}>{editor && <TextStyleOptions editor={editor} />}</Box>
      <Box mr={1}>
        <TextAlginmentOption editor={editor} />
      </Box>
      <Box mr={1}>
        <ImageOption editor={editor} />
      </Box>
      <Box mr={1} mt={1}>
        <TableControlMenu editor={editor} />
      </Box>
      <Box sx={{ width: '100%' }} mr={1} mt={1}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showTableBorderEcomm}
              onChange={() => setShowTableBorderEcomm(!showTableBorderEcomm)}
              name="checkedB"
              color="primary"
            />
          }
          label="Show table border on ecomm (default unchecked for every update)"
        />
      </Box>
      <Box sx={{ width: '100%', mt: 1 }}>
        <Button
          onClick={handleSaveDescription}
          variant="contained"
          color="primary"
          disabled={updateProdIsLoading}
        >
          {updateProdIsLoading ? <CircularProgress size={'2rem'} /> : `Save`}
        </Button>
      </Box>
    </Paper>
  );
};

export const RichTextBoxMenu = memo(component);
