import React from 'react';
import { ButtonBase, CardHeader } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface Props {
  icon?: JSX.Element;
  onClick: () => void;
  title: string;
}

export const CardHeaderButton: React.FC<Props> = ({
  icon,
  onClick,
  title
}: Props) => {
  return (
    <ButtonBase
      style={{
        paddingLeft: 10,
        width: '100%',
        justifyContent: 'left'
      }}
      onClick={onClick}
    >
      {icon ? icon : <ExpandMoreIcon />}
      <CardHeader title={title} />
    </ButtonBase>
  );
};
