import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectRoleState = (state: RootState) => state.role;

const selectRoleList = createSelector(selectRoleState, (state) => state.roles);

export const selectors = {
  selectRoleList
};
