import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Typography,
  makeStyles,
  Grid
} from '@material-ui/core';
import { Customer } from 'src/types';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: 338
  },
  avatar: {
    height: 100,
    width: 100
  }
}));

interface Props {
  customer: Customer;
  className?: string;
}

const Profile = ({ className, customer, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            style={{ paddingTop: 50 }}
          >
            <Avatar
              className={classes.avatar}
              src="/static/images/avatars/pcworth_logo.png"
            />
            <Typography
              style={{ marginTop: 10 }}
              color="textPrimary"
              gutterBottom
              variant="h3"
            >
              {`${customer.first_name ?? ''} ${customer.middle_name ??
                ''} ${customer.last_name ?? ''}`}
            </Typography>
            <Typography color="textSecondary" variant="h6">
              {customer.email}
            </Typography>
            <Typography color="textSecondary" variant="h6">
              {moment(customer.created_at).format('ll')}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default Profile;
