import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core';
import { Transaction } from 'src/types';
import { formatCurrency, formatDate } from 'src/utils';
import { LinkComponent, LoaderBar } from 'src/components';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/constants';
import { multiBranchFeat } from 'src/constants/feature-toggle';

interface Props {
  loading: boolean;
  transactions?: Transaction[];
}

const TransactionsTable = ({ loading, transactions = [] }: Props) => {
  const navigate = useNavigate();

  return (
    <Box mt={2}>
      <Card>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            <LoaderBar isLoading={loading} />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Order No.</TableCell>
                  {multiBranchFeat ? <TableCell>Branch</TableCell> : null}
                  <TableCell>Transaction No.</TableCell>
                  <TableCell>Date</TableCell>
                  <TableCell>Total</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transactions.map((item: Transaction) => (
                  <TableRow
                    hover
                    key={item.id}
                    style={
                      item.isDeleted ? { backgroundColor: colors.error } : {}
                    }
                  >
                    <TableCell>{item?.order_no || '--'}</TableCell>
                    {multiBranchFeat ? (
                      <TableCell>{item?.branch_name || '--'}</TableCell>
                    ) : null}
                    <TableCell>
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/transaction/${item?.transaction_no}`)
                        }
                        href={`/app/transaction/${item?.transaction_no}`}
                        title={`${item?.transaction_no ?? '--'}`}
                      />
                      {item.isDeleted ? (
                        <Tooltip title={`Void reason: ${item?.void_reason}`}>
                          <Chip
                            label="Has Deleted / Deleted"
                            style={{ height: '100%', marginLeft: 10 }}
                            size="small"
                            variant="outlined"
                            color="primary"
                          />
                        </Tooltip>
                      ) : null}
                    </TableCell>
                    <TableCell>
                      {item?.created_at ? formatDate(item?.created_at) : '--'}
                    </TableCell>
                    <TableCell> {formatCurrency(item?.total_price)}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </Card>
    </Box>
  );
};

export default TransactionsTable;
