import React from 'react';
import { CardContent, Divider, Typography } from '@material-ui/core';
import { formatCurrency } from 'src/utils';
import { Product } from 'src/types/product';

interface Props {
  productInfo?: Product;
  hasDivider?: boolean;
}

export const ProductInfoSummary = ({ productInfo, hasDivider = true }: Props) => {
  return (
    <div>
      <CardContent>
        <Typography
          style={{ marginTop: 10 }}
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          {productInfo?.name ?? ''}
        </Typography>
        <Typography
          style={{ marginTop: 10 }}
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          Price:
          {productInfo?.retail_price
            ? formatCurrency(productInfo?.retail_price)
            : '₱0'}
        </Typography>
        <Typography
          style={{ marginTop: 10 }}
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          {`Stock(s): ${productInfo?.available_count}`}
        </Typography>
        <Typography color="textSecondary" variant="h6">
          {productInfo?.category ?? ''}
        </Typography>
      </CardContent>
      {hasDivider ? <Divider /> : null}
    </div>
  );
};
