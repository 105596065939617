import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  DeleteGalleryPhotosResponse,
  GalleryPhotosDeleteRequest
} from 'src/types';

export const galleryPhotosDeleteThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteGalleryPhotosResponse>,
  GalleryPhotosDeleteRequest,
  { state: RootState }
>('gallery/galleryPhotosDeleteThunk', async (data) => {
  const response = await Api.delete(
    `/gallery/photo/delete/${data?.gallery_id}`,
    data
  );
  return response;
});
