import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useMemo, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { cloneDeep } from 'lodash';

import {
  ErrorSerialNoList,
  GetProdThruSerialNoPayload,
  NonSerializeProductPayload,
  SerialNoProductData,
  TransferCountDataList,
  TransferListAction,
  TransferStockOriginToReceivingBranchPayload
} from 'src/redux/slices/stock-transfer';
import { useSnackBar } from '../use-snackbar';

const {
  selectors: stockTransferSelector,
  actions: stockTransferAction
} = slices.stockTransfer;

export const useStockTransfer = () => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  //=========loading state==========
  const isGetProductSnLoading = useAppSelector(
    stockTransferSelector?.selectGetProdSnLoading
  );
  const isTransferStockLoading = useAppSelector(
    stockTransferSelector?.selectTransferStockLoading
  );

  //===== items =======
  const originBranchListProduct = useAppSelector(
    stockTransferSelector?.selectOriginBranchListProduct
  );
  const receivingBranchListProduct = useAppSelector(
    stockTransferSelector.selectReceivingBranchListProduct
  );
  const originListingId = useAppSelector(
    stockTransferSelector?.selectOriginListId
  );
  const receivingListingId = useAppSelector(
    stockTransferSelector?.selectReceivingListId
  );

  const selectedBranch = useAppSelector(
    stockTransferSelector?.selectCurrentOriginBranch
  );

  const currentNonSnList = useAppSelector(
    stockTransferSelector?.selectCurrentNonSnProduct
  );

  const sortedOriginBranchListItems = useMemo(
    () => cloneDeep(originBranchListProduct)?.reverse(),
    [originBranchListProduct]
  );

  const sortedReceivingBranchListItems = useMemo(
    () => cloneDeep(receivingBranchListProduct)?.reverse(),
    [receivingBranchListProduct]
  );

  //=====States======
  //we want to confirm if user can branch change
  const [isConfirmBranchChange, setIsConfirmBranchChange] = useState<boolean>(
    false
  );
  const [productSerialNumber, setProductSerialNumber] = useState<string>('');
  const [errorSerialNos, setErrorSerialNos] = useState<ErrorSerialNoList>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [transferCount, setTransferCount] = useState<number>();
  const [transferCountListing, setTransferCountListing] = useState<
    TransferCountDataList[]
  >([]);

  //=========actions===========
  const onAddOriginListingId = (listingId?: number[]) => {
    dispatch(
      stockTransferAction?.actions?.addOriginListingId({
        originListing: listingId || []
      })
    );
  };

  const onAddReceivingListingId = (listingId?: number[]) => {
    dispatch(
      stockTransferAction?.actions?.addReceivingListingId({
        receivingListing: listingId || []
      })
    );
  };

  const clearAllOriginItems = () => {
    dispatch(stockTransferAction?.actions?.clearAllOriginBranchItems());
  };

  const clearAllReceivingItems = () => {
    dispatch(stockTransferAction?.actions?.clearAllReceivingBranchItems());
  };

  const removeItemOnList = (serialNum?: string) => {
    if (originBranchListProduct.find((prod) => prod.serial_no === serialNum)) {
      const newOriginBranchItemList = originBranchListProduct.filter(
        (prod) => prod.serial_no !== serialNum
      );

      //alsso remove a serial number on current list
      const newCurrentNonSnList = currentNonSnList.map((prod) => {
        if (prod.current_serial_nos) {
          return {
            ...prod,
            current_serial_nos: prod.current_serial_nos.filter(
              (nonSn) => nonSn !== serialNum
            )
          };
        }
        return prod;
      });

      dispatch(
        stockTransferAction?.actions?.removeProdOnBranchList({
          newBranchItem: newOriginBranchItemList,
          newCurrentNonSnList: newCurrentNonSnList
        })
      );
    }
  };

  const onHandleCheckListing = useCallback(
    (action: TransferListAction, newCheckedItem: SerialNoProductData) => {
      dispatch(
        stockTransferAction?.actions?.handleCheckedListingAction({
          action,
          newBranchItem: newCheckedItem
        })
      );
    },
    [dispatch]
  );

  const getSelectedOriginBranch = useCallback(
    (branch_id?: number, value?: string) => {
      dispatch(
        stockTransferAction?.actions?.getSelectedOriginBranch({
          branch_id,
          value
        })
      );
    },
    [dispatch]
  );

  const transferStockOriginBranchToReceivingBranch = async (
    requestParams: TransferStockOriginToReceivingBranchPayload
  ) => {
    try {
      if (errorSerialNos?.length > 0) {
        snackBar.show({
          severity: 'error',
          message:
            'Please resync / delete / resolve invalid error items first.',
          useSound: true
        });
        return;
      }

      const response = unwrapResult(
        await dispatch(
          stockTransferAction?.transferStocksFromOriginToReceivingBranchThunk(
            requestParams
          )
        )
      );

      if (response?.success) {
        //get the item base on listing id transferred to receiving branch
        const newReceivingBranch = originBranchListProduct.filter(
          (prod) => prod.id && requestParams?.listing_ids?.includes(prod.id)
        );

        //remove the origin branch item based on the listing id transferred to receiving branch
        const newOriginBranch = originBranchListProduct.filter((item: any) => {
          if (requestParams.listing_ids) {
            return !requestParams.listing_ids.includes(item?.id);
          }
        });

        dispatch(
          stockTransferAction?.actions?.transferToReceivingBranch({
            newReceivingBranch,
            newOriginBranch
          })
        );

        snackBar.show({
          severity: 'success',
          message: response?.originalData?.message,
          useSound: true
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.originalData?.message,
          useSound: true
        });
      }
    } catch (error) {
      console.error(error);
      snackBar.show({
        severity: 'error',
        message: 'Something went wrong. Try again later',
        useSound: true
      });
    }
  };

  const transferStockBackToOriginBranchFromReceivingbranch = async (
    requestParams: TransferStockOriginToReceivingBranchPayload
  ) => {
    try {
      const response = unwrapResult(
        await dispatch(
          stockTransferAction?.transferStocksFromOriginToReceivingBranchThunk(
            requestParams
          )
        )
      );

      if (response?.success) {
        //get the item base on listing id transferred to origin branch
        const newOriginBranch = receivingBranchListProduct.filter(
          (prod) => prod.id && requestParams?.listing_ids?.includes(prod.id)
        );

        //remove the receiving branch item based on the listing id transferred to origin branch
        const newReceivingBranch = receivingBranchListProduct.filter(
          (item: any) => {
            if (requestParams.listing_ids) {
              return !requestParams.listing_ids.includes(item?.id);
            }
          }
        );

        dispatch(
          stockTransferAction?.actions?.transferBackToOriginBranch({
            newOriginBranch,
            newReceivingBranch
          })
        );

        snackBar.show({
          severity: 'success',
          message: response?.originalData?.message,
          useSound: true
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.originalData?.message,
          useSound: true
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getProdAvailableViaSerialNo = async (
    requestParams: GetProdThruSerialNoPayload
  ) => {
    try {
      if (
        originBranchListProduct.find(
          (prod) => prod.serial_no === requestParams?.serial_no
        )
      ) {
        snackBar.show({
          severity: 'error',
          message: 'Item is already added on the list.',
          useSound: true
        });
        return;
      }

      const response = unwrapResult(
        await dispatch(
          stockTransferAction?.getBranchProductViaSerialNoThunk(requestParams)
        )
      );

      if (response?.success) {
        snackBar.show({
          severity: 'success',
          message: 'Product found and added to the list',
          useSound: true
        });
        //If added new product we will not allow user again to change branch unless is confirm
        setIsConfirmBranchChange(false);
        setProductSerialNumber('');

        // If added sn is already on error list, remove it.
        setErrorSerialNos((prev) =>
          prev?.filter(
            (x) =>
              x?.serial_no !== requestParams?.serial_no ||
              x?.branch_id !== requestParams?.branch_id
          )
        );
      } else {
        const { message } = response;
        snackBar.show({
          severity: 'error',
          message,
          useSound: true
        });

        // Check if new item for error list already exist.
        const itemAlreadyExistInErrSerialNos = errorSerialNos.find(
          (x) =>
            x?.serial_no === requestParams?.serial_no &&
            x?.branch_id === requestParams?.branch_id
        );
        if (itemAlreadyExistInErrSerialNos) {
          setErrorSerialNos((prev) => {
            const newErrorSerialNos = prev.map((x) => {
              if (
                x?.serial_no === requestParams?.serial_no &&
                x?.branch_id === requestParams?.branch_id
              ) {
                return { ...x, message };
              }
              return x;
            });
            return newErrorSerialNos;
          });
          return;
        }
        setErrorSerialNos((prev) => [...prev, { ...requestParams, message }]);
      }
    } catch (error) {
      setErrorSerialNos((prev) => [
        ...prev,
        { ...requestParams, message: 'Something went wrong. Try again later' }
      ]);
      console.error(error);
    }
  };

  const toggleCheckUncheckAll = (v: boolean, action: TransferListAction) => {
    if (action === 'origin') {
      const newOriginBranchItemList = originBranchListProduct?.map((item) => ({
        ...item,
        isChecked: v || false
      }));
      dispatch(
        stockTransferAction?.actions?.updateOriginBranchItemList(
          newOriginBranchItemList
        )
      );
    }
    if (action === 'receiving') {
      const newReceivingBranchItemList = receivingBranchListProduct?.map(
        (item) => ({
          ...item,
          isChecked: v || false
        })
      );
      dispatch(
        stockTransferAction?.actions?.updateReceivingBranchItemList(
          newReceivingBranchItemList
        )
      );
    }
  };

  const getNonSerializeProductCount = useCallback(
    async (params: NonSerializeProductPayload) => {
      const nonProductSn = currentNonSnList.find(
        (nonSn) => nonSn?.product_name === params?.product
      );

      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(
            stockTransferAction?.getNonSerializedProductCountThunk({
              ...params,
              current_serial_nos: nonProductSn?.current_serial_nos || []
            })
          )
        );

        if (response?.success) {
          setIsLoading(false);
          snackBar.show({
            severity: 'success',
            message: 'Fetched non serialize product list'
          });
        }
        ///prio to check this error first
        if (response?.errors) {
          const { errors } = response;
          snackBar.show({
            severity: 'error',
            message: JSON.stringify(errors)
          });
          return;
        }
        if (!response?.success) {
          const { errors } = response;
          snackBar.show({
            severity: 'error',
            message: response?.message || JSON.stringify(errors)
          });
          return;
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [currentNonSnList, dispatch, snackBar]
  );

  const getTransferCount = useCallback(
    async (product_id?: number) => {
      try {
        const response = unwrapResult(
          await dispatch(stockTransferAction?.getTransferStockCount(product_id))
        );

        if (response?.success) {
          setTransferCount(response?.originalData?.data?.transfer_stocks_count);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const getTransferCountListings = useCallback(
    async (product_id?: number) => {
      try {
        const response = unwrapResult(
          await dispatch(
            stockTransferAction?.getTransferStockCountList(product_id)
          )
        );

        if (response?.success) {
          setTransferCountListing(response?.originalData?.data || []);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  return {
    //loading
    isGetProductSnLoading,
    isTransferStockLoading,
    isLoading,

    // ==states
    isConfirmBranchChange,
    setIsConfirmBranchChange,
    productSerialNumber,
    setProductSerialNumber,

    selectedBranch,
    currentNonSnList,

    transferCount,
    transferCountListing,
    getTransferCount,
    getTransferCountListings,

    //===items
    originBranchListProduct,
    receivingBranchListProduct,
    sortedOriginBranchListItems,
    sortedReceivingBranchListItems,
    originListingId,
    receivingListingId,
    errorSerialNos,
    removeItemOnList,

    onAddOriginListingId,
    onAddReceivingListingId,
    transferStockOriginBranchToReceivingBranch,
    transferStockBackToOriginBranchFromReceivingbranch,
    getProdAvailableViaSerialNo,
    clearAllOriginItems,
    clearAllReceivingItems,
    onHandleCheckListing,
    setErrorSerialNos,
    toggleCheckUncheckAll,
    getNonSerializeProductCount,
    getSelectedOriginBranch
  };
};
