import React, { useMemo } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { Logo } from 'src/components';
import { colors, drjInfo } from 'src/constants';
import clsx from 'clsx';
import { dateToday } from 'src/utils';
import { slices, useAppSelector } from 'src/redux';

const { selectors: userSelectors } = slices.user;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: 150,
    width: '100%',
    borderRadius: 20,
    padding: theme.spacing(2)
  },
  info: {
    marginLeft: theme.spacing(2),
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  text: {
    textAlign: 'center'
  },
  link: {
    textDecoration: 'underline'
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontWeight: 700,
    letterSpacing: 0.5,
    color: colors.primary
  },
  shoutOutContainer: {
    marginTop: theme.spacing(1)
  },
  shoutOut: {
    fontWeight: 500,
    letterSpacing: 0.75,
    color: colors.red[800]
  },
  salesId: {
    letterSpacing: 1,
    fontSize: 12,
    color: colors.black
  }
}));

interface QuotationHeaderProps {
  branchId?: number;
}

export const Header = ({ branchId }: QuotationHeaderProps) => {
  const classes = useStyles();
  const userInfo = useAppSelector(userSelectors.selectUserInfo);

  const branchInfo = useMemo(() => {
    const filteredValue = userInfo?.branches?.find(
      (branch) => branch.id === branchId
    );
    return filteredValue;
  }, [branchId, userInfo]);

  const concatMobileNo = useMemo(() => {
    if (branchInfo) {
      const mobileNumbersString = branchInfo?.mobile_no?.join(', ') || '';
      return mobileNumbersString;
    }
    return '';
  }, [branchInfo]);

  const employeeName = useMemo(() => {
    const name = `${userInfo?.first_name} ${userInfo?.last_name}`;
    return name;
  }, [userInfo]);

  return (
    <div className={classes.root}>
      <div>
        <Logo size={100} />
        <Typography className={classes.salesId}>
          {`Sales: ${userInfo?.id}-${employeeName}`}
        </Typography>
        <Typography className={classes.salesId}>
          {`Date: ${dateToday()}`}
        </Typography>
      </div>
      <div className={classes.info}>
        <div className={classes.titleContainer}>
          <Typography className={clsx(classes.text)}>
            Owned and Operated by:
          </Typography>
          <Typography
            style={{ marginLeft: 4 }}
            className={clsx(classes.text, classes.title)}
          >
            {drjInfo?.name || ''}
          </Typography>
        </div>

        <div className={classes.titleContainer}>
          <Typography className={clsx(classes.text, classes.title)}>
            Location:
          </Typography>
          <Typography style={{ marginLeft: 4 }} className={clsx(classes.text)}>
            {branchInfo?.address || drjInfo.location || ''}
          </Typography>
        </div>

        <div className={classes.titleContainer}>
          <div className={classes.titleContainer}>
            <Typography className={clsx(classes.text, classes.title)}>
              Website:
            </Typography>
            <Typography
              style={{
                marginLeft: 4,
                color: colors.link,
                textDecoration: 'underline'
              }}
              className={clsx(classes.text)}
            >
              {`www.${drjInfo.website} `}
            </Typography>
          </div>

          <div style={{ marginLeft: 10 }} className={classes.titleContainer}>
            <Typography className={clsx(classes.text, classes.title)}>
              FB Page:
            </Typography>
            <Typography
              style={{
                marginLeft: 4,
                color: colors.link,
                textDecoration: 'underline'
              }}
              className={clsx(classes.text)}
            >
              {`${drjInfo.facebook} `}
            </Typography>
          </div>
        </div>

        <div className={classes.titleContainer}>
          <div className={classes.titleContainer}>
            <Typography className={clsx(classes.text, classes.title)}>
              Email:
            </Typography>
            <Typography
              style={{
                marginLeft: 4
              }}
              className={clsx(classes.text)}
            >
              {`${drjInfo.email} `}
            </Typography>
          </div>

          <div style={{ marginLeft: 10 }} className={classes.titleContainer}>
            <Typography className={clsx(classes.text, classes.title)}>
              Viber:
            </Typography>
            <Typography
              style={{
                marginLeft: 4,
                color: colors.link,
                textDecoration: 'underline'
              }}
              className={clsx(classes.text)}
            >
              {`${drjInfo.cellNo2} `}
            </Typography>
          </div>
        </div>

        <div style={{ marginLeft: 10 }} className={classes.titleContainer}>
          <Typography className={clsx(classes.text, classes.title)}>
            Contact Details:
          </Typography>

          <Typography className={classes.text}>
            {concatMobileNo || drjInfo.cellNo} {' / '}
            {branchInfo?.tel_no || drjInfo.telNo}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export const QuotationHeader = React.memo(Header);
