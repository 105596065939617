import { cloneDeep } from 'lodash';
import { Quotation, PackageDetailsProduct } from 'src/types';
import { componentToQuotation } from '../quotationComponentsOrder';

export const componentPackageToQuotation: (
    products: PackageDetailsProduct[]
) =>
    Quotation[] = (products: PackageDetailsProduct[]) => {
        let clonedProducts = cloneDeep(products);
        let mappedProductsQuotation: Quotation[] = clonedProducts?.reduce(
            (accumulator: Quotation[], currentProduct: PackageDetailsProduct) => {
                const index = accumulator.findIndex(
                    (x) => x.product.product_id === currentProduct.product_id
                );

                if (index !== -1) {
                    accumulator[index].quantity++;
                } else {
                    accumulator.push({
                        quantity: 1,
                        product: {
                            product_id: currentProduct.product_id || undefined,
                            product_name: currentProduct?.product_name,
                            category_id: currentProduct?.category_id,
                            category_name: currentProduct?.category_name,
                            retail_price: currentProduct?.retail_price || 0,
                            slug: currentProduct?.slug,
                        },
                    });
                }

                return accumulator;
            },
            []
        );

        return componentToQuotation(mappedProductsQuotation)
    };
