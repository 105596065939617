import { isNil } from 'lodash';
import { colors } from 'src/constants';

export const getQtyColor = (quantity?: number) => {
  if (isNil(quantity)) {
    return colors.primary;
  }
  if (quantity <= 0) {
    return colors.red.A700;
  }
  if (quantity <= 3) {
    return colors.warning;
  }
  if (quantity >= 4) {
    return colors.success;
  }
  return colors.primary;
};
