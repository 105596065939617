import {
  Box,
  Button,
  Card,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField
} from '@material-ui/core';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { BranchListDropDown } from 'src/components/dropdown';
import { CategoryListDropdown } from 'src/components/filters/CategoryDropdown';
import { Search as SearchIcon } from 'react-feather';
import { DatePickerRangeComponent } from 'src/components';
import { dateToday, transformerDateField } from 'src/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { GetInventoryAuditLogsListingsPayload } from 'src/redux/slices/inventory-audit-logs';

interface Props {
  onHandleApplyFilter: (params: GetInventoryAuditLogsListingsPayload) => void;
}

const component: FC<Props> = ({ onHandleApplyFilter }) => {
  const [ialKeyword, setIalKeyword] = useState<string>('');
  //TODO: convert later into redux or hooks
  const [logsFilter, setLogsFilter] = useState<
    GetInventoryAuditLogsListingsPayload
  >({});

  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();

  const onHandleIalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIalKeyword(event?.target?.value);
  };

  const onApplyFilter = useCallback(() => {
    onHandleApplyFilter({ ...logsFilter, ial_no: ialKeyword });
  }, [ialKeyword, logsFilter, onHandleApplyFilter]);

  const onChangedDate = useCallback(
    (date: MaterialUiPickersDate | null, field: 'from' | 'to') => {
      const transformedDate = date ? transformerDateField(date) : undefined;

      if (field === 'to') {
        setLogsFilter((prev) => ({ ...prev, to_date: transformedDate }));
        setToDate(transformedDate);
      }
      if (field === 'from') {
        setLogsFilter((prev) => ({ ...prev, from_date: transformedDate }));
        setFromDate(transformedDate);
      }
    },
    []
  );

  useEffect(() => {
    if (ialKeyword) {
      setLogsFilter((prev) => ({ ...prev, ial_no: ialKeyword }));
    }
  }, [ialKeyword]);

  return (
    <Card style={{ padding: '1rem' }}>
      <Grid
        container
        spacing={3}
        style={{ justifyContent: 'flex-end', alignItems: 'center' }}
      >
        <Grid item xs={12} md={3}>
          <TextField
            onChange={onHandleIalChange}
            value={ialKeyword}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SvgIcon fontSize="small" color="action">
                    <SearchIcon />
                  </SvgIcon>
                </InputAdornment>
              )
            }}
            placeholder="Search IAL No. "
            variant="outlined"
            // onKeyPress={onKeyPressEnter}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <div style={{ marginLeft: -9 }}>
            <BranchListDropDown
              allowNoSelectedBranch={false}
              isElevated={false}
              onHandleBranchChange={(branchIds?: number[]) =>
                setLogsFilter((prev) => ({
                  ...prev,
                  branch_id: branchIds && branchIds[0]
                }))
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} md={3}>
          <CategoryListDropdown
            onHandleCategories={(categoryId: number) => {
              setLogsFilter((prev) => ({ ...prev, category_id: categoryId }));
            }}
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <Box sx={{ mt: -1 }}>
            <DatePickerRangeComponent
              fromDateMax={dateToday()}
              toDateMin={fromDate ? fromDate : undefined}
              fromDateValue={fromDate || null}
              toDateValue={toDate || null}
              dateToLabel="To Date"
              dateFromLabel="From Date"
              onChangeToDate={(date) => {
                onChangedDate(date, 'to');
              }}
              onChangeFromDate={(date) => {
                onChangedDate(date, 'from');
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            fullWidth
            onClick={onApplyFilter}
            style={{
              marginTop: 5,
              height: '55px'
            }}
          >
            Apply Filter
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

export const Toolbar = memo(component);
