import { ListingPriceTypeEnum } from 'src/enums';

export const getListingPriceTypeTxt = (status?: number) => {
  switch (status) {
    case ListingPriceTypeEnum.NotFree:
      return 'Not Free';
    case ListingPriceTypeEnum.FreeFromSupplier:
      return 'Free from Supplier';
    case ListingPriceTypeEnum.FreeForAll:
      return 'Free for all';
    default:
      return '';
  }
};
