import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Card,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import {
  PaginationV2,
  UserAssignedOnBranchList,
  UserNotOnBranchData
} from 'src/types';
import { DeleteDialog, LinkComponent } from 'src/components';
import { useNavigate } from 'react-router';
import { UserListToolbar } from './UserListToolbar';
import { AddUserForm } from './AddUserForm';
import { useSnackBar } from 'src/hooks';

const { actions: branchActions } = slices.branch;

interface Props {
  branchId?: number | undefined;
}

const UserBranchList = ({ branchId }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const snackBar = useSnackBar();

  const [userList, setUserList] = useState<UserAssignedOnBranchList[]>();
  const [userNotOnBranchList, setUserNotOnBranchList] = useState<
    UserNotOnBranchData[]
  >();
  const [userNotBranchMeta, setUserNotBranchMeta] = useState<PaginationV2>();
  const [isAddLoading, setIsAddLoading] = useState<boolean>(false);
  const rowKeys = ['user_id', 'full_name', 'user_name'];
  const [isOpenUserList, setIsOpenUserList] = useState<boolean>(false);
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [isRemoveUser, setIsRemoveUser] = useState<boolean>(false);

  const fetchUserAssignedOnBranchList = useCallback(
    async (keyword?: string) => {
      try {
        const response = unwrapResult(
          await dispatch(
            branchActions?.getUsersAssignedOnBranchThunk({
              branch_id: branchId,
              keyword: keyword
            })
          )
        );

        if (response?.success) {
          const { data } = response?.originalData;
          setUserList(data);
        }
      } catch (error) {
        console.error('Error fetching user assigned on branch list:', error);
      }
    },
    [branchId, dispatch]
  );

  const fetchUserNotOnBranchList = useCallback(
    async (keyword?: string, page?: number) => {
      try {
        const response = unwrapResult(
          await dispatch(
            branchActions?.getUsersNotOnBranchThunk({
              keyword,
              page,
              branch_id: branchId
            })
          )
        );

        if (response?.success) {
          const { data, meta } = response?.originalData;
          setUserNotOnBranchList(data);
          setUserNotBranchMeta(meta);
        }
      } catch (error) {
        console.error('Error fetching user list:', error);
      }
    },
    [branchId, dispatch]
  );

  const addUserToBranch = useCallback(
    async (userIds?: number[] | undefined) => {
      if (!userIds || userIds.length <= 0) {
        setIsAddLoading(false);
        snackBar.show({ severity: 'error', message: 'Select user(s)' });
        return;
      }

      try {
        setIsAddLoading(true);
        const response = unwrapResult(
          await dispatch(
            branchActions?.addUserToBranchThunk({
              user_ids: userIds,
              branch_id: branchId
            })
          )
        );

        if (response?.success) {
          setIsAddLoading(false);
          setIsOpenUserList(false);
          //refetch
          fetchUserNotOnBranchList('', 1);
          snackBar.show({ severity: 'success', message: response.message });
        } else {
          setIsAddLoading(false);
          snackBar.show({
            severity: 'error',
            message: response.errors?.user_ids || 'Error Adding user to branch'
          });
        }
      } catch (error) {
        console.error('Error adding user to branch:', error);
        snackBar.show({
          severity: 'error',
          message: 'An error occurred while adding user to branch'
        });
      } finally {
        setIsAddLoading(false);
      }
    },
    [branchId, dispatch, fetchUserNotOnBranchList, snackBar]
  );

  const removeUserFromBranch = useCallback(
    async (userIds?: number[] | undefined) => {
      if (!userIds || userIds.length <= 0) {
        snackBar.show({ severity: 'error', message: 'Select user(s)' });
        return;
      }

      try {
        const response = unwrapResult(
          await dispatch(
            branchActions?.removeUserFromBranchThunk({
              user_branch_ids: userIds
            })
          )
        );

        if (response?.success) {
          setIsOpenUserList(false);
          //refetch after remove
          fetchUserAssignedOnBranchList('');
          fetchUserNotOnBranchList('', 1);
          setIsRemoveUser(false);
          snackBar.show({ severity: 'success', message: response.message });
        } else {
          setIsAddLoading(false);
          snackBar.show({
            severity: 'error',
            message:
              response.errors?.user_ids || 'Error Removing user to branch'
          });
          setIsRemoveUser(false);
        }
      } catch (error) {
        console.error('Error adding user to branch:', error);
        snackBar.show({
          severity: 'error',
          message: 'An error occurred while adding user to branch'
        });
      } finally {
        setSelectedUserIds([]);
        setIsAddLoading(false);
        setIsRemoveUser(false);
      }
    },
    [
      dispatch,
      fetchUserAssignedOnBranchList,
      fetchUserNotOnBranchList,
      snackBar
    ]
  );

  const onChangeSearch = useCallback(
    (keywordParam?: string) => {
      fetchUserAssignedOnBranchList(keywordParam);
    },
    [fetchUserAssignedOnBranchList]
  );

  const handlePageChange = (pageNum?: number) => {
    fetchUserNotOnBranchList('', pageNum);
  };

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    userId?: number | null | undefined
  ) => {
    const isChecked = event.target.checked;
    if (isChecked && userId != null) {
      setSelectedUserIds((prevIds: number[] | undefined) => [
        ...(prevIds ?? []),
        userId
      ]);
    } else {
      setSelectedUserIds((prevIds: number[]) =>
        prevIds?.filter((id) => id !== userId)
      );
    }
  };

  useEffect(() => {
    fetchUserAssignedOnBranchList();
    fetchUserNotOnBranchList();
  }, [fetchUserAssignedOnBranchList, fetchUserNotOnBranchList]);

  return (
    <Box style={{ marginTop: '20px' }}>
      <UserListToolbar
        isCheckedUser={selectedUserIds && selectedUserIds?.length > 0}
        onHandleRemoveUser={() => setIsRemoveUser(!isRemoveUser)}
        onSearchUser={(keywordParam?: string) => {
          onChangeSearch(keywordParam);
        }}
        onHandleUnCheck={() => setSelectedUserIds([])}
        onToggleOpenFormUserList={() => setIsOpenUserList(!isOpenUserList)}
      />

      <Card>
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {/* leave this blank on purpose */}
                <TableCell>{''}</TableCell>
                {rowKeys.map((row: any, index: number) => (
                  <TableCell key={index}>{row}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {userList?.map((users) => (
                <TableRow key={users?.user_id}>
                  <TableCell scope="row" padding="checkbox">
                    <Checkbox
                      indeterminate={false}
                      checked={selectedUserIds?.includes(
                        users?.user_branch_id || 0
                      )}
                      onChange={(event) =>
                        handleCheckboxChange(event, users?.user_branch_id)
                      }
                    />
                  </TableCell>

                  <TableCell scope="row">
                    <LinkComponent
                      onClick={() => navigate(`/app/user/${users.user_id}`)}
                      href={`/app/user/${users?.user_id}`}
                      title={`${users.user_id}`}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <LinkComponent
                      onClick={() => navigate(`/app/user/${users?.user_id}`)}
                      href={`/app/user/${users?.user_id}`}
                      title={`${users.full_name}`}
                    />
                  </TableCell>
                  <TableCell scope="row">
                    <LinkComponent
                      onClick={() => navigate(`/app/user/${users.user_id}`)}
                      href={`/app/user/${users?.user_id}`}
                      title={`${users.user_name}`}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      <AddUserForm
        userNotOnBranchData={userNotOnBranchList}
        metaPage={userNotBranchMeta}
        onAddUserToBranch={addUserToBranch}
        isLoading={isAddLoading}
        isOpen={isOpenUserList}
        onHandlePageChange={handlePageChange}
        onCloseToggle={() => {
          setIsOpenUserList(!isOpenUserList);
        }}
      />
      <DeleteDialog
        title="Remove Assigned User(s) From Branch"
        subTitle="Are you sure you want to remove assigned user(s) from this branch?"
        isVisible={isRemoveUser}
        handleClose={() => setIsRemoveUser(false)}
        onDelete={() => removeUserFromBranch(selectedUserIds)}
      />
    </Box>
  );
};

export default UserBranchList;
