import React from 'react';
import { CardContent, makeStyles, Typography } from '@material-ui/core';
import { DragAndDropImgUpload } from 'src/components';
import { convertImageFileToBase64 } from 'src/utils';

interface Props {
  data?: string;
  onCoverImageChange: (img: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cpuField: {
    borderWidth: 5
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  previewDiv: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  previewImg: {
    height: 300,
    width: undefined
  }
}));

// eslint-disable-next-line no-empty-pattern
const component = ({ data, onCoverImageChange }: Props) => {
  const classes = useStyles();

  const onChangeCoverInternal = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInTxt: any = await convertImageFileToBase64(firstFile);
      onCoverImageChange(imgInTxt);
    }
  };

  return (
    <CardContent>
      <Typography className={classes.subHeader} variant="h6">
        Cover Photo
      </Typography>
      <DragAndDropImgUpload
        multiple={false}
        title="Drag or select a cover photo"
        onImageSelected={onChangeCoverInternal}
      />
      {data ? (
        <div className={classes.previewDiv}>
          <img alt="Cover Image" className={classes.previewImg} src={data} />
        </div>
      ) : null}
    </CardContent>
  );
};

export const GalleryCoverImageCardContent = React.memo(component);
