import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { CommonAxiosResponse, PcwMinigameResponse } from 'src/types';
import Api from 'src/services/ApiService';

export const getRaffleEntries = createAsyncThunk<
  CommonAxiosResponse<PcwMinigameResponse>,
  undefined,
  { state: RootState }
>('customer/getRaffleEntries', async (_, thunkApi) => {
  const raffleWinnersState = thunkApi.getState().raffle.raffleWinners;
  const ids = raffleWinnersState?.map((x) => x.id);

  const response = await Api.get('/ecomm/internal/user/roulette/list/get', {
    ids
  });

  return response;
});
