import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectRmaState = (state: RootState) => state.rma;

const selectRmaList = createSelector(selectRmaState, (state) => state.rmas);

export const selectors = {
  selectRmaList
};
