import React, { useEffect, useState } from 'react';
import { Container, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';

import { Page } from 'src/components';
import OptionDetailView from './OptionDetails';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '90vh'
  }
}));

const { actions: optionActions, selectors: optionSelectors } = slices.options;

const OptionView = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const state: any = location.state;
  const option = useAppSelector(optionSelectors.selectCurrentOption);
  const fromClick = state ? true : false;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const renderOptionDetails = async (id: number) => {
    setIsLoading(true);
    dispatch(optionActions.getOptionsByIdThunk(id)).finally(() =>
      setIsLoading(false)
    );
  };

  const renderFromClick = () => {
    if (state) {
      renderOptionDetails(state?.id);
    }
  };

  const renderFromSearch = () => {
    const OptionIdRegex = /(?<=options\/)\d+/;
    const optionIdFromUrl = location?.pathname?.match(OptionIdRegex)?.[0];
    optionIdFromUrl ? renderOptionDetails(Number(optionIdFromUrl)) : null;
  };

  useEffect(() => {
    fromClick ? renderFromClick() : renderFromSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title={`Option: `}>
      <Container maxWidth={false}>
        <OptionDetailView
          option={option}
          isLoading={isLoading}
          renderOptionDetails={renderOptionDetails}
          setIsLoading={setIsLoading}
        />
      </Container>
    </Page>
  );
};

export default OptionView;
