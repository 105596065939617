import React from 'react';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { Option } from 'src/types/option';

interface Props {
  className?: string;
  options: Option[];
  onOptionPress: (data: Option) => void;
}

const Options = ({ className, options, onOptionPress, ...rest }: Props) => {
  return (
    <Card className={clsx(className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Option Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {options.map((item: Option) => (
                <TableRow
                  onClick={() => onOptionPress(item)}
                  hover
                  key={item.id}
                >
                  <TableCell>{item.name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export default Options;
