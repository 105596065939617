
// nAH ID RATHER DO CONST
export enum PaymentTypeEnum {
  Billease = 'BILLEASE',
  Paymongo = 'PAYMONGO',
  Gcash = 'GCASH',
  Paymaya = 'PAYMAYA',
  Bdo = 'BDO',
  Cash = 'CASH',

  BdoBt = 'BDO_BT',
  BpiBt = 'BPI_BT',
  CcStraight = 'CC_STRAIGHT',
  BdoCc3M = 'BDO_CC_3M',
  BdoCc6M = 'BDO_CC_6M',
  BdoCc9M = 'BDO_CC_9M',
  BdoCc12M = 'BDO_CC_12M',
  BdoCc18M = 'BDO_CC_18M',
  BdoCc24M = 'BDO_CC_24M',
  BpiCc3M = 'BPI_CC_3M',
  BpiCc6M = 'BPI_CC_6M',
  BpiCc9M = 'BPI_CC_9M',
  BpiCc12M = 'BPI_CC_12M',
  BpiCc18M = 'BPI_CC_18M',
  BpiCc24M = 'BPI_CC_24M',
  EwCc3M = 'EW_CC_3M',
  EwCc6M = 'EW_CC_6M',
  EwCc9M = 'EW_CC_9M',
  EwCc12M = 'EW_CC_12M',
  EwCc18M = 'EW_CC_18M',
  EwCc24M = 'EW_CC_24M',
  HsbcCc3M = 'HSBC_CC_3M',
  HsbcCc6M = 'HSBC_CC_6M',
  HsbcCc9M = 'HSBC_CC_9M',
  HsbcCc12M = 'HSBC_CC_12M',
  HsbcCc18M = 'HSBC_CC_18M',
  HsbcCc24M = 'HSBC_CC_24M',
  CbCc3M = 'CB_CC_3M',
  CbCc6M = 'CB_CC_6M',
  CbCc9M = 'CB_CC_9M',
  CbCc12M = 'CB_CC_12M',
  CbCc18M = 'CB_CC_18M',
  CbCc24M = 'CB_CC_24M',
  PaymongoGGives = 'PAYMONGO_GGIVES',
  PaymongoBillease = 'PAYMONGO_BILLEASE',
  PaymongoCc = 'PAYMONGO_CC'
}

export enum PaymentCategoryEnum {
  Payment = 'payment',
  Shipping = 'shipping',
  Tip = 'tip'
}
