import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';

import { slices, useAppSelector } from 'src/redux';
import { useSnackBar } from 'src/hooks';
import { createStyles, makeStyles } from '@material-ui/core';

const { selectors: appSelectors } = slices.app;

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minWidth: 300
    }
  })
);

export const SnackBar = () => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const snackBarSettings = useAppSelector(appSelectors.selectSnackBar);

  const handleClose = () => {
    snackBar.hide();
  };

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      open={snackBarSettings?.open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        className={classes.root}
        onClose={handleClose}
        severity={snackBarSettings?.severity}
      >
        {snackBarSettings?.message || 'Alert'}
      </Alert>
    </Snackbar>
  );
};
