import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectSupplierState = (state: RootState) => state.supplier;

const selectSupplierList = createSelector(
  selectSupplierState,
  (state) => state.suppliers
);

export const selectors = {
  selectSupplierList
}