import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
    CommonAxiosResponse,
    PackageCreateResponse,
    PackageCreatePayload
} from 'src/types';

export const pcPackageCreateThunk = createAsyncThunk<
    CommonAxiosResponse<PackageCreateResponse>,
    PackageCreatePayload,
    { state: RootState }
>('pc-package/createPcPackageThunk', async (data) => {
    const response = await Api.post(`/ecomm/internal/pc_package/create`, data);
    return response;
});
