import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  GetOptionsResponse,
  GetOptionRequest,
  CommonOptionsRequest,
  UpdateOptionRequest,
  CommonOptionsResponse,
  GetOptionByIdResponse,
  GetOptionBySlugResponse
} from 'src/types/option';

export const getOptionsThunk = createAsyncThunk<
  CommonAxiosResponse<GetOptionsResponse>,
  GetOptionRequest,
  { state: RootState }
>('option/getOptions', async (data) => {
  const response = await Api.get('/option/get', data);
  if (!response.success) {
    throw new Error('Failed at getOptionsThunk.');
  }
  return response;
});

export const getOptionsByIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetOptionByIdResponse>,
  number,
  { state: RootState }
>('option/getOptionsById', async (data) => {
  const response = await Api.get(`/option/get/${data}`);
  if (!response.success) {
    throw new Error('Failed at getOptionsById.');
  }
  return response;
});

export const getOptionsByIdSlug = createAsyncThunk<
  CommonAxiosResponse<GetOptionBySlugResponse>,
  string,
  { state: RootState }
>('option/getOptionsById', async (data) => {
  const response = await Api.get(`/option/get/${data}`);
  if (!response.success) {
    throw new Error('Failed at getOptionsById.');
  }
  return response;
});

export const createOptionThunk = createAsyncThunk<
  CommonAxiosResponse<CommonOptionsResponse>,
  CommonOptionsRequest,
  { state: RootState }
>('option/createOption', async (data) => {
  const response = await Api.post(`/option/create`, data);
  if (!response.success) {
    throw new Error('Failed at createOptionThunk.');
  }
  return response;
});

export const updateOptionThunk = createAsyncThunk<
  CommonAxiosResponse<CommonOptionsResponse>,
  UpdateOptionRequest,
  { state: RootState }
>('option/updateOptionThunk', async (data) => {
  const response = await Api.patch(`/option/update/${data.id}`, data);
  if (!response.success) {
    throw new Error('Failed at updateOptionThunk.');
  }
  return response;
});

export const deleteOptionThunk = createAsyncThunk<
  CommonAxiosResponse<CommonOptionsResponse>,
  number,
  { state: RootState }
>('option/deleteOptionThunk', async (data) => {
  const response = await Api.delete(`/option/delete/${data}`);
  if (!response.success) {
    throw new Error('Failed at deleteOptionThunk.');
  }
  return response;
});
