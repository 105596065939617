import { StyleSheet } from '@react-pdf/renderer';

export const warrantyViewStyles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row'
  },
  flex1: {
    flex: 1
  },
  flex2: {
    flex: 2
  },
});
