import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { UpdateTransactionRemarksRequest } from 'src/types/transaction';
import { CommonAxiosResponse, CommonResponseMessage } from 'src/types';

export const updateTransactionRemarksThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  UpdateTransactionRemarksRequest,
  { state: RootState }
>('transaction/updateTransactionRemarksThunk', async (data) => {
  const response = await Api.patch(`/transactions/updateRemarks`, data);
  // if (!response.success) {
  //   throw new Error('Failed at updateTransactionRemarksThunk.');
  // }
  return response;
});
