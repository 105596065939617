// INTENDED any BECAUSE THIS IS A FILTER

export const buildFilter = (filter: any) => {
  let query: any = {};
  for (let keys in filter) {
    if (filter[keys]?.constructor === Array && filter[keys].length > 0) {
      query[keys] = filter[keys];
    }
  }
  return query;
};

export const filterData = (data: any, filter: any) => {
  const query = buildFilter(filter);
  const filteredData = data.filter((item: any) => {
    for (let key in query) {
      if (item[key] === undefined || !query[key].includes(item[key])) {
        return false;
      }
    }
    return true;
  });
  return filteredData;
};
