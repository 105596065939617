import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectCategory = (state: RootState) => state.manufacturer;

const selectManufacturers = createSelector(
  selectCategory,
  (state) => state.manufacturers
);

const selectManufacturersOnFilter = createSelector(
  selectCategory,
  (state) => state.manufacturersOnFilter
);

export const selectors = {
  selectManufacturers,
  selectManufacturersOnFilter
};
