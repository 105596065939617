import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import {
  CircularProgress,
  InputAdornment,
  SvgIcon,
  TextField
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { slices, useAppDispatch } from 'src/redux';
import { Product, ProductsThunkRequest } from 'src/types';
import { unwrapResult } from '@reduxjs/toolkit';
import { Search as SearchIcon } from 'react-feather';

const { actions: productActions } = slices.product;

interface Props {
  label?: string;
  placeholder?: string;
  debounceDelay?: number;
  onClear?: boolean;
  action?: 'prod_id' | 'prod_name';
  onOptionSelected?: (option?: any) => void;
}

const component: FC<Props> = ({
  label = 'Search Products',
  placeholder = '',
  action = 'prod_id',
  debounceDelay = 1000,
  onClear = false,
  onOptionSelected
}) => {
  const dispatch = useAppDispatch();

  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Product | null>(null);

  const getProducts = useCallback(
    async (request: ProductsThunkRequest) => {
      setLoading(true);
      try {
        const response = unwrapResult(
          await dispatch(productActions.getProductsThunk(request))
        );
        if (response.success && response.originalData?.data) {
          setOptions(response.originalData.data);
        } else {
          setOptions([]);
        }
      } catch (error) {
        console.error('Failed to fetch products:', error);
        setOptions([]);
      } finally {
        setLoading(false);
      }
    },
    [dispatch]
  );

  const handleOptionSelected = (option?: Product) => {
    if (!options) {
      return;
    }

    switch (action) {
      case 'prod_id':
        if (onOptionSelected) {
          onOptionSelected(option?.id || undefined);
        }
        break;
      case 'prod_name':
        if (onOptionSelected) {
          onOptionSelected(option?.name || '');
        }
        break;
    }
    setSelectedOption(option || null);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (inputValue === '') {
        setOptions([]);
        setLoading(false);
        return;
      }
      setLoading(true);
      if (inputValue !== '') {
        setLoading(false);
        getProducts({ keyword: inputValue });
        return;
      }
    };

    const debounceTimeout = setTimeout(fetchData, debounceDelay);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [debounceDelay, getProducts, inputValue]);

  useEffect(() => {
    if (onClear) {
      setInputValue('');
      setOptions([]);
      setSelectedOption(null);
    }
  }, [onClear]);

  return (
    <Autocomplete
      freeSolo
      value={selectedOption}
      onChange={(event, newValue: any) => handleOptionSelected(newValue)}
      options={options}
      getOptionLabel={(option) => `${option.name} (${option.category})`}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder={placeholder}
          onChange={(e) => setInputValue(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon fontSize="small" color="action">
                  <SearchIcon />
                </SvgIcon>
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
            inputProps: {
              ...params.inputProps,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.preventDefault(); // Prevent default behavior
                  e.stopPropagation(); // Stop event from propagating
                }
              }
            }
          }}
        />
      )}
    />
  );
};

export const AutoCompleteProductTextField = memo(component);
