import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  CommonResponseMessage,
  UserUpdatePayload
} from 'src/types';

export const updatePcwAccountProfile = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  UserUpdatePayload,
  { state: RootState }
>('customer/updatePcwAccountProfile', async (data) => {
  const response = await Api.patch(
    `/ecomm/internal/user/details/update/${data?.id}`,
    data
  );
  return response;
});
