import React, { FC, memo, useCallback, useMemo } from 'react';
import {
  Page as PDFPage,
  View,
  PDFViewer,
  Document,
  StyleSheet,
  PDFDownloadLink
} from '@react-pdf/renderer';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { cloneDeep } from 'lodash';
import { limitPerPage } from 'src/views/stock-transfer/constant';
import { ViewTableAuditLogs } from './PdfTableAuditLogs';
import { AuditListingPdfData } from 'src/redux/slices/inventory-audit-logs';
import { ViewFooterLog } from 'src/views/stock-transfer/transfer-stocks-log/TransferLogPDF/ViewFooterLogs';
import { AuditHeader } from './AuditHeader';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  parentView: {
    flexDirection: 'column',
    padding: 5,
    margin: 5,
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5
  },
  pdfDownloadButton: {
    color: 'white',
    padding: '1rem',
    backgroundColor: '#0c304a',
    fontFamily: 'Roboto',
    borderRadius: '5px'
  }
});

interface Props {
  isOpen: boolean;
  ial_no?: string;
  auditLogs?: AuditListingPdfData[];
  onHandleCloseDialog: () => void;
}

const component: FC<Props> = ({
  isOpen,
  auditLogs,
  ial_no,
  onHandleCloseDialog
}) => {
  const logsPerPage: AuditListingPdfData[] = useMemo(() => {
    const clonedAuditLogs = cloneDeep(auditLogs);

    if (!clonedAuditLogs) {
      return [];
    }

    const logsPerPageResult = clonedAuditLogs?.reduce(
      (accumulator: any, value: any, index) => {
        const pageIndex = Math.floor(index / limitPerPage);
        //check if accumulator is the current index else assign new array to new accumulator index
        accumulator[pageIndex] = accumulator[pageIndex] || [];
        accumulator[pageIndex].push(value);

        return accumulator;
      },
      []
    );
    return logsPerPageResult;
  }, [auditLogs]);

  const RenderDocument = useCallback(() => {
    return (
      <Document title={'Inventory Audit Logs'}>
        {logsPerPage.map((itemLogs: any, index) => (
          <PDFPage size={[842, 595]} style={styles.page} key={index}>
            <View style={styles.parentView} wrap>
              <AuditHeader title="Inventory Audit Logs" ial_no={ial_no} />
              <ViewTableAuditLogs auditListing={itemLogs} />
              <ViewFooterLog
                processedBy={''}
                currentPage={index + 1}
                lastPage={logsPerPage.length}
              />
            </View>
          </PDFPage>
        ))}
      </Document>
    );
  }, [ial_no, logsPerPage]);

  return (
    <Dialog
      maxWidth={'lg'}
      fullWidth
      open={isOpen}
      onClose={onHandleCloseDialog}
    >
      <DialogTitle>
        <Typography variant="h4">Inventory Audit Logs</Typography>
        <IconButton
          aria-label="close"
          style={styles.closeButton}
          color="secondary"
          onClick={onHandleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            padding: '1rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <PDFDownloadLink
            document={<RenderDocument />}
            fileName={ial_no}
            style={styles.pdfDownloadButton}
          >
            {({ loading }) =>
              loading ? `Loading document...` : `Download pdf file: ${ial_no}`
            }
          </PDFDownloadLink>
        </Box>
        <PDFViewer
          width="100%"
          height="100%"
          style={{ height: '80vh', width: '100%' }}
        >
          <RenderDocument />
        </PDFViewer>
      </DialogContent>
    </Dialog>
  );
};

export const PrintAuditLogs = memo(component);
