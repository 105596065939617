import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { slices, useAppDispatch } from 'src/redux';
import { Category, GetCategoryMainData, PaginationV2 } from 'src/types';
import { useSnackBar } from '../use-snackbar';

const { actions: categoryActions } = slices.category;

export const useMainCategory = () => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [mainCategoryList, setMainCategoryList] = useState<
    GetCategoryMainData[]
  >([]);
  const [subCategoryList, setSubCategoryList] = useState<Category[]>([]);
  const [metaSubCategory, setMetaSubCategory] = useState<PaginationV2>();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMainCategory = useCallback(
    async (keyword?: string) => {
      setIsLoading(true);
      try {
        const response = unwrapResult(
          await dispatch(categoryActions?.getMainCategoryThunk({ keyword }))
        );
        if (response?.success) {
          setIsLoading(false);
          const { data } = response?.originalData || {};
          if (data) {
            setMainCategoryList(data);
          }
        }
      } catch (error) {
        setIsLoading(false);
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const getSubCategories = useCallback(
    async (keyword?: string, pageNum?: number) => {
      setIsLoading(true);
      try {
        const getCategoriesResponse = unwrapResult(
          await dispatch(
            categoryActions.getCategoriesThunk({
              keyword,
              limit: 10,
              page: pageNum
            })
          ).finally(() => setIsLoading(false))
        );
        if (
          getCategoriesResponse?.success &&
          getCategoriesResponse?.originalData
        ) {
          setIsLoading(false);
          const { data, meta } = getCategoriesResponse?.originalData;
          if (data) {
            setSubCategoryList(data);
            setMetaSubCategory(meta);
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const updateMainCategories = useCallback(
    async (payload: GetCategoryMainData) => {
      setIsLoading(true);
      try {
        const updateCategoriesResponse = unwrapResult(
          await dispatch(
            categoryActions.updateCategoryThunk({
              ...payload,
              target_data: payload.target_data_id
            })
          ).finally(() => setIsLoading(false))
        );
        if (updateCategoriesResponse?.success) {
          setIsLoading(false);
          snackBar.show({
            severity: 'success',
            message: 'Successfully update main category informationn',
            useSound: true
          });

          return updateCategoriesResponse?.success;
        }
        return false;
      } catch (error) {
        console.error(error);
        snackBar.show({
          severity: 'error',
          message: 'Something went wrong',
          useSound: true
        });
        return false;
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, snackBar]
  );

  const unAssignSubCategoryFromMainCategory = useCallback(
    async (subCategoryId?: number) => {
      try {
        const response = unwrapResult(
          await dispatch(
            categoryActions?.unAssignSubCategoryToMainCategoryThunk(
              subCategoryId
            )
          )
        );
        if (response.success) {
          snackBar.show({
            severity: 'success',
            message: 'Successfully unassign sub category',
            useSound: true
          });
          return response.success;
        }
        return false;
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch, snackBar]
  );

  return {
    isLoading,
    mainCategoryList,
    subCategoryList,
    metaSubCategory,
    getMainCategory,
    getSubCategories,
    updateMainCategories,
    unAssignSubCategoryFromMainCategory
  };
};
