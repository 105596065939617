import React, { FC, memo, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Page as PDFPage,
  View,
  PDFViewer,
  Document,
  StyleSheet
} from '@react-pdf/renderer';
import {
  ViewQuotationBuildInfo,
  ViewQuotationHeaderSlip,
  ViewQuotationItems,
  ViewQuotationSlipFooter
} from './QuotationReceiptComponent';
import { ViewQuotationTitleSlip } from './QuotationReceiptComponent/ViewQuotationTitleSlip';
import { TemplateDetails, TemplateProductDetails } from 'src/types';
import { cloneDeep, forEach } from 'lodash';
import { promoPriceToSRP } from 'src/utils';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  parentView: {
    flexDirection: 'column',
    padding: 5,
    margin: 5,
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5
  }
});

interface Props {
  isOpen: boolean;
  quotationTemplateItems?: TemplateDetails;
  showSRP?: boolean;
  onHandleClose: () => void;
}

const pageSize = 17;

const component: FC<Props> = ({
  isOpen,
  quotationTemplateItems,
  showSRP = false,
  onHandleClose
}) => {
  const itemTotalAmountSRP = useMemo(() => {
    let total = 0;
    if (quotationTemplateItems && quotationTemplateItems?.products) {
      forEach(quotationTemplateItems?.products, (item) => {
        const item_price = promoPriceToSRP(item?.product?.retail_price);
        total += item.quantity * item_price;

        return total;
      });
    }

    //total zero
    return total;
  }, [quotationTemplateItems]);

  const itemsPerPage = useMemo(() => {
    const cloneQuotationProducts = cloneDeep(quotationTemplateItems?.products);
    if (!cloneQuotationProducts) {
      return [];
    }

    const productItemsPerPage: TemplateProductDetails[] = cloneQuotationProducts?.reduce(
      (
        accumulator: TemplateProductDetails | any,
        value: TemplateProductDetails,
        index
      ) => {
        //if index is equal to 1 or so on then it becomes next page index
        //meaning there is more item than pageSize
        const pageIndex = Math.floor(index / pageSize);
        //check if accumulator is the current index else assign new array to new accumulator index
        accumulator[pageIndex] = accumulator[pageIndex] || [];
        accumulator[pageIndex].push(value);

        return accumulator;
      },
      []
    );
    return productItemsPerPage;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quotationTemplateItems?.products]);

  return (
    <Dialog maxWidth={'md'} fullWidth open={isOpen} onClose={onHandleClose}>
      <DialogTitle>
        <Typography variant="h4">Quotation Slip</Typography>
        <IconButton
          aria-label="close"
          style={styles.closeButton}
          color="secondary"
          onClick={onHandleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PDFViewer
          width="100%"
          height="100%"
          style={{ height: '80vh', width: '100%' }}
        >
          <Document title={'Quotation Slip'}>
            {itemsPerPage.map((item: any, index: number) => (
              <PDFPage key={index} size={[421, 612]} style={styles.page}>
                <View style={styles.parentView}>
                  <ViewQuotationHeaderSlip />
                  <ViewQuotationTitleSlip />
                  <ViewQuotationBuildInfo
                    buildName={quotationTemplateItems?.raw_name}
                  />
                  <ViewQuotationItems
                    templateProducts={item || []}
                    showSRP={showSRP}
                  />
                  <ViewQuotationSlipFooter
                    pageIndex={index + 1}
                    pageSize={itemsPerPage?.length}
                    totalAmount={
                      showSRP
                        ? itemTotalAmountSRP
                        : quotationTemplateItems?.total_amount
                    }
                  />
                </View>
              </PDFPage>
            ))}
          </Document>
        </PDFViewer>
      </DialogContent>
    </Dialog>
  );
};

export const QuotationPrintModal = memo(component);
