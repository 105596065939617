import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  TransferStockLogsPayload,
  TransferStockLogsResponse,
  TransferLogsSerialResponse
} from './types';

export const getTransferStockLogsThunk = createAsyncThunk<
  CommonAxiosResponse<TransferStockLogsResponse>,
  TransferStockLogsPayload,
  { state: RootState }
>('transferStockLogs/getTransferStockLogsThunk', async (data) => {
  const response = await Api.get('/transfer_stocks_logs/get', data);
  return response;
});

export const getTransferStockLogsSerialThunk = createAsyncThunk<
  CommonAxiosResponse<TransferLogsSerialResponse>,
  string | undefined,
  { state: RootState }
>('transferStockLogs/getTransferStockLogsSerialThunk', async (st_no) => {
  const response = await Api.get(`/transfer_stocks_logs/get/${st_no}`);
  return response;
});
