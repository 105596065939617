import { Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { ChangeEvent, FC, memo, useCallback, useEffect } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';

const {
  actions: categoryActions,
  selectors: categorySelectors
} = slices.category;

interface Props {
  onHandleCategories: (categoryId: number) => void;
}

const component: FC<Props> = ({ onHandleCategories }) => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(categorySelectors.selectCategories);

  const getCategories = useCallback(
    async (keyword?: string) => {
      try {
        await dispatch(categoryActions.getCategoriesThunk({ keyword }));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  const onHandleSelectedChangeCategory = (
    event: ChangeEvent<{}>,
    value: any
  ) => {
    const categoryId = value?.id;
    onHandleCategories(categoryId || 0);
  };

  useEffect(() => {
    getCategories('');
  }, [getCategories]);

  return (
    <Box>
      <Autocomplete
        id="category-list-dropdown"
        options={categories}
        fullWidth
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField {...params} label="Categories" variant="outlined" />
        )}
        onChange={onHandleSelectedChangeCategory}
      />
    </Box>
  );
};

export const CategoryListDropdown = memo(component);
