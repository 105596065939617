import React, {
  useRef,
  useEffect,
  useCallback,
  useMemo,
  useState
} from 'react';
import { cloneDeep } from 'lodash';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  makeStyles
} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import { LoaderBar, Page } from 'src/components';
import { localize, warranties } from 'src/constants';
import {
  CustomInputEvent,
  FixMeLater,
  Product,
  ProductCustomSpecifications,
  ProductImage,
  ProductsThunkRequest
} from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  chunkArrayInGroups,
  forEachPromise,
  getCategoryNameViaId,
  isEmptyOrSpaces
} from 'src/utils';
import {
  useAddProductLogic,
  useAlertGlobal,
  useProductPartDetailsLogic,
  useSnackBar
} from 'src/hooks';
import FormAddDialog from 'src/components/dialogs/FormDialog';
import { ProductTypeEnum } from 'src/enums';
import {
  AddProductFooterButtons,
  AddProductGeneralInfoCardContent,
  AddProductImagesInfo,
  AddProductInfoCustomSpecs,
  AddProductOtherInfo,
  AddProductPricingInfo,
  AddProductReferences,
  RecentAddedProductsTable
} from './component';
import { ProductPartDetails } from '../component';
import usePrompt from 'src/utils/navigation-prompt';

const { actions: categoryActions } = slices.category;
const { actions: manufacturerActions } = slices.manufacturer;
const { actions: productActions } = slices.product;

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  }
}));

const AddProductView = ({ className, ...rest }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  const snackBar = useSnackBar();
  const categoryFieldRef = useRef(null);
  const manufacturerFieldRef = useRef(null);
  const alertGlobal = useAlertGlobal();

  const [productImages, setProductImages] = useState<ProductImage[]>([]);
  const [imgUploadProgress, setImgUploadProgress] = useState<number>(0);
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const [productDetails, setProductDetails] = useState<Product | undefined>();
  const [canCheckValidation, setCanCheckValidation] = useState(false);

  const {
    isProductDetailOfKeyValid,
    resetProductPartsStates
  } = useProductPartDetailsLogic();

  const {
    references,
    onReferencesChange,
    addProductAndProductDetails,
    isSelectedCategoryRAM,
    isCreateCategoryVisible,
    setIsCreateCategoryVisible,
    // isCreateManufacturerVisible,
    // setIsCreateManufacturerVisible,
    // addManufacturerLoading,
    // setAddManufacturerLoading,
    addCategoryLoading,
    setAddCategoryLoading,
    selectedManufacturer,
    setSelectedManufacturer,
    manufacturerInputValue,
    setManufacturerInputValue,
    manufacturers,
    setManufacturers,
    selectedWarranty,
    setSelectedWarranty,
    categories,
    setCategories,
    inputValue,
    setInputValue,
    warrantyInput,
    setWarrantyInput,
    selectedName,
    setSelectedName,
    selectedSKU,
    setSelectedSKU,
    selectedCategory,
    setSelectedCategory,
    retailPrice,
    setRetailPrice,
    dealersPrice,
    setDealersPrice,
    recentProducts,
    setRecentProducts,
    isLoading,
    setIsLoading,
    selectedRamKitType,
    setSelectedRamKitType,
    consumableUnit,
    setConsumableUnit,
    productType,
    setProductType,
    selectedEcDisplayName,
    setSelectedEcDisplayName
  } = useAddProductLogic();

  //   const onOpenCreateManufacturerDialog = () => {
  //     setIsCreateManufacturerVisible(true);
  //   };

  //   const onCloseCreateManufacturerDialog = () => {
  //     setIsCreateManufacturerVisible(false);
  //     setAddManufacturerLoading(false);
  //   };

  //   const onAddManufacturerOnModalPress = async (name: string) => {
  //     // TODO: Add checker loader before close.
  //     setAddManufacturerLoading(true);
  //     if (name) {
  //       const newManufacturerData = { name };
  //       const response = unwrapResult(
  //         await dispatch(
  //           manufacturerActions.createManufacturerThunk(newManufacturerData)
  //         )
  //       );
  //       if (response.success) {
  //         snackBar.show({ severity: 'success', message: response.message });
  //         getManufacturers();
  //         onCloseCreateManufacturerDialog();
  //       } else {
  //         snackBar.show({ severity: 'error', message: response.message });
  //         setAddManufacturerLoading(false);
  //       }
  //     } else {
  //       snackBar.show({
  //         severity: 'error',
  //         message: 'Please fill up all required information.1'
  //       });
  //     }
  //   };

  const onCloseCreateCategoryDialog = () => {
    setIsCreateCategoryVisible(false);
    setAddCategoryLoading(false);
  };

  const onAddCategoryOnModalPress = async (name: string) => {
    // TODO: Add checker loader before close.
    setAddCategoryLoading(true);
    if (name) {
      const newCategoryData = { name };
      const response = unwrapResult(
        await dispatch(categoryActions.createCategoryThunk(newCategoryData))
      );

      if (response.success) {
        snackBar.show({ severity: 'success', message: response.message });
        getCategories();
        onCloseCreateCategoryDialog();
      } else {
        snackBar.show({ severity: 'error', message: response.message });
        setAddCategoryLoading(false);
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.2'
      });
    }
  };

  const getCategories = useCallback(async () => {
    const getCategoriesResponse = unwrapResult(
      await dispatch(categoryActions.getCategoriesThunk({}))
    );
    if (getCategoriesResponse?.originalData?.data) {
      setCategories(getCategoriesResponse.originalData.data);
    }
  }, [dispatch, setCategories]);

  const getManufacturers = useCallback(async () => {
    const getManufacturersResponse = unwrapResult(
      await dispatch(manufacturerActions.getManufacturersThunk({}))
    );

    if (getManufacturersResponse?.originalData?.manufacturers) {
      setManufacturers(getManufacturersResponse.originalData.manufacturers);
    }
  }, [dispatch, setManufacturers]);

  const handleRamKitTypeChange = (event: FixMeLater) => {
    setSelectedRamKitType(event?.target?.value || undefined);
  };

  const onWarrantyInputChange = (text: string) => {
    setWarrantyInput(text);
  };
  const onClearFields = useCallback(() => {
    alertGlobal.hide();
    setSelectedName('');
    setSelectedSKU('');
    setInputValue('');
    setSelectedCategory(null);
    setSelectedManufacturer(null);
    setManufacturerInputValue('');
    setRetailPrice('');
    setDealersPrice('');
    setWarrantyInput('');
    setSelectedWarranty(null);
    setConsumableUnit(null);
    setProductType(ProductTypeEnum.Serialized);
    setProductImages([]);
    onChangeCustomSpecs([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    alertGlobal,
    setConsumableUnit,
    setDealersPrice,
    setInputValue,
    setManufacturerInputValue,
    setProductType,
    setRetailPrice,
    setSelectedCategory,
    setSelectedManufacturer,
    setSelectedName,
    setSelectedWarranty,
    setWarrantyInput,
    setSelectedSKU
  ]);

  const onClearFieldsWarning = () => {
    alertGlobal.show({
      title: 'Clear Fields',
      subtitle: `Are you sure you want to clear fields?`,
      buttons: [
        {
          text: 'Clear',
          onClick: onClearFields,
          color: 'secondary'
        },
        {
          text: 'Cancel',
          onClick: () => alertGlobal.hide()
        }
      ]
    });
  };

  const onAddRecentProduct = useCallback(
    (product: Product) => {
      const clonedRecentProducts = cloneDeep(recentProducts);
      clonedRecentProducts.push(product);
      setRecentProducts(clonedRecentProducts);
    },
    [recentProducts, setRecentProducts]
  );

  const getWarrantyInDays = (val: string) => {
    if (val) {
      const daysValue = warranties.find((x) => x.name === val)?.value;
      if (daysValue) {
        return daysValue;
      }
      if (!isNaN(+val) && +val > 0) {
        return Math.round(+val);
      }
    }
    return undefined;
  };

  const isProductDataValid = useCallback(() => {
    const warrantyDuration = getWarrantyInDays(warrantyInput);
    let errMsg = '';
    if (!warrantyDuration) {
      errMsg = localize.ERR_FIELD_WARRANTY;
    }
    if (isEmptyOrSpaces(selectedName)) {
      errMsg = localize.ERR_FIELD_NAME;
    }
    if (productType === ProductTypeEnum.Consumable && !consumableUnit) {
      errMsg = localize.ERR_REQUIRED_UNIT;
    }
    if (!selectedName) {
      errMsg = 'Invalid name';
    }
    if (!selectedCategory) {
      errMsg = 'Invalid category';
    }
    if (!selectedManufacturer) {
      errMsg = 'Invalid manufacturer';
    }
    if (errMsg) {
      snackBar.show({
        severity: 'error',
        message: errMsg || 'error',
        useSound: true
      });
      return false;
    }
    return true;
  }, [
    consumableUnit,
    productType,
    selectedCategory,
    selectedManufacturer,
    selectedName,
    snackBar,
    warrantyInput
  ]);

  const selectedCategoryNameLowerCase = useMemo(() => {
    return getCategoryNameViaId(selectedCategory?.id);
  }, [selectedCategory]);

  const updateUploadProgress = (newNum: number = 0) => {
    setImgUploadProgress(newNum);
  };

  const uploadProductImage = (prodID: number | undefined) => {
    const clonedImage = cloneDeep(productImages);
    const photosStrArr = clonedImage
      ?.filter((x) => x?.image_filepath === '')
      ?.map((y) => y.url);

    if (photosStrArr?.length <= 0) {
      return;
    }

    if (photosStrArr && photosStrArr?.length > 0) {
      const groupedByTwo = chunkArrayInGroups(photosStrArr, 2);
      const groupLength = groupedByTwo?.length;
      forEachPromise(groupedByTwo, (item, uploadedIndex) => {
        return new Promise((resolve) => {
          process.nextTick(() => {
            dispatch(
              productActions.createProductImagesThunk({
                product_id: prodID,
                images: item
              })
            )
              .then(() => {
                resolve(true);
                updateUploadProgress(((uploadedIndex + 1) / groupLength) * 100);
              })
              .catch(() => {
                resolve(true);
                updateUploadProgress(((uploadedIndex + 1) / groupLength) * 100);
              });
          });
        });
      }).then(() => {
        setImgUploadProgress(0);
        setIsImageUploading(false);
        snackBar.show({
          severity: 'success',
          message: localize.SUC_IMG_UPLOAD,
          useSound: true
        });
      });
    }
  };

  const onCreateProduct = useCallback(async () => {
    if (!isProductDataValid()) {
      return;
    }
    if (selectedCategoryNameLowerCase) {
      if (!isProductDetailOfKeyValid(selectedCategoryNameLowerCase)) {
        return;
      }
    }
    const warrantyDuration = getWarrantyInDays(warrantyInput);
    setIsLoading(true);
    if (selectedName && selectedCategory && selectedManufacturer) {
      const newProductData: ProductsThunkRequest = {
        name: selectedName,
        ec_display_name: selectedEcDisplayName,
        sku: selectedSKU,
        category_id: selectedCategory.id,
        manufacturer_id: selectedManufacturer?.id || undefined,
        dealers_price: Math.ceil(+dealersPrice),
        retail_price: Math.ceil(+retailPrice),
        warranty_duration: warrantyDuration,
        ram_kit: isSelectedCategoryRAM ? selectedRamKitType : undefined,
        product_type: productType,
        references:
          references && references?.length > 0 ? references : undefined,
        consumable_unit: consumableUnit ? consumableUnit : undefined,
        specification: productDetails?.specification,
        description: productDetails?.description || ''
      };
      const response = await addProductAndProductDetails(newProductData);
      if (response.success) {
        snackBar.show({
          severity: 'success',
          message: response.message,
          useSound: true
        });
        uploadProductImage(response?.originalData?.product.id);
        onAddRecentProduct(response.originalData.product);
        onClearFields();
        setProductImages([]);
        setCanCheckValidation(false);
        navigate(
          `/app/products/information/${response?.originalData?.product.id}`
        );
      } else {
        snackBar.show({
          severity: 'error',
          useSound: true,
          message: response.message
            ? response.message
            : localize.ERR_API_UNKNOWN
        });
      }
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    addProductAndProductDetails,
    consumableUnit,
    dealersPrice,
    isProductDataValid,
    isProductDetailOfKeyValid,
    isSelectedCategoryRAM,
    onAddRecentProduct,
    onClearFields,
    productType,
    references,
    retailPrice,
    selectedCategory,
    selectedCategoryNameLowerCase,
    selectedManufacturer,
    selectedName,
    selectedRamKitType,
    setIsLoading,
    snackBar,
    warrantyInput,
    selectedSKU
  ]);

  useEffect(() => {
    getCategories();
    getManufacturers();
  }, [getCategories, getManufacturers]);

  const shouldPromptAppear = useMemo(() => {
    if (selectedName.length !== 0) {
      return true;
    }
    if (selectedCategory !== null) {
      return true;
    }
    if (selectedManufacturer !== null) {
      return true;
    }
    if (dealersPrice.length !== 0) {
      return true;
    }
    if (retailPrice.length !== 0) {
      return true;
    }
    if (selectedWarranty !== null) {
      return true;
    }
    return false;
  }, [
    selectedName,
    selectedCategory,
    selectedManufacturer,
    dealersPrice,
    retailPrice,
    selectedWarranty
  ]);

  usePrompt(
    `You still have unsaved change(s). Leaving will discard this`,
    shouldPromptAppear
  );

  const onProductPress = (item: Product) => {
    //Not sure why pass state item in app/products/[id] details
    //TODO: revert back if bug still exist
    // navigate(`/app/products/${item.id}`, { state: item });
    navigate(`/app/products/${item.id}`);
  };

  const onChangeProductType = (value: number) => {
    if (!value || value !== ProductTypeEnum.Consumable) {
      setConsumableUnit(null);
    }
    setProductType(value);
  };

  const onChangeProductInfo = (e: CustomInputEvent) => {
    const { value, name } = e.target;
    setProductDetails((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const onChangeCustomSpecs = (newCustomSpecs: ProductCustomSpecifications) => {
    const referencesToInput: any = {
      target: {
        value: newCustomSpecs,
        name: 'specification'
      }
    };
    onChangeProductInfo(referencesToInput);
  };

  useEffect(() => {
    return () => {
      resetProductPartsStates();
    };
  }, [resetProductPartsStates]);

  return (
    <Page className={classes.root} title="New Product">
      <Container maxWidth={false}>
        <Box mt={2}>
          <form
            autoComplete="off"
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <LoaderBar isLoading={isLoading} />
              <CardHeader
                title="New Product"
                subheader="The information of new product."
              />

              <Divider />
              <AddProductGeneralInfoCardContent
                isSelectedCategoryRAM={isSelectedCategoryRAM}
                selectedManufacturer={selectedManufacturer}
                setSelectedManufacturer={setSelectedManufacturer}
                manufacturerInputValue={manufacturerInputValue}
                setManufacturerInputValue={setManufacturerInputValue}
                manufacturers={manufacturers}
                categories={categories}
                inputValue={inputValue}
                setInputValue={setInputValue}
                selectedName={selectedName}
                setSelectedName={setSelectedName}
                selectedEcDisplayName={selectedEcDisplayName}
                setSelectedEcDisplayName={setSelectedEcDisplayName}
                selectedSKU={selectedSKU}
                setSelectedSKU={setSelectedSKU}
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                categoryFieldRef={categoryFieldRef}
                selectedRamKitType={selectedRamKitType}
                handleRamKitTypeChange={handleRamKitTypeChange}
                manufacturerFieldRef={manufacturerFieldRef}
                // onOpenCreateManufacturerDialog={onOpenCreateManufacturerDialog}
                canCheckValidation={canCheckValidation}
              />

              <Divider />
              {selectedCategoryNameLowerCase ? (
                <ProductPartDetails
                  productCategory={selectedCategoryNameLowerCase}
                />
              ) : null}

              <Divider />
              <AddProductPricingInfo
                dealersPrice={dealersPrice}
                setDealersPrice={setDealersPrice}
                retailPrice={retailPrice}
                setRetailPrice={setRetailPrice}
              />

              <Divider />
              <AddProductOtherInfo
                selectedWarranty={selectedWarranty}
                setSelectedWarranty={setSelectedWarranty}
                onWarrantyInputChange={onWarrantyInputChange}
                warrantyInput={warrantyInput}
                warranties={warranties}
                productType={productType}
                consumableUnit={consumableUnit}
                onChangeProductType={onChangeProductType}
                setConsumableUnit={setConsumableUnit}
                canCheckValidation={canCheckValidation}
              />

              <Divider />
              <AddProductReferences
                data={references}
                onReferenceChange={(data) => onReferencesChange(data)}
              />

              <Divider />
              <AddProductImagesInfo
                data={productImages || []}
                isImageUploading={isImageUploading}
                imgUploadProgress={imgUploadProgress}
                onPhotosChange={(imgs) => setProductImages(imgs)}
              />

              <Divider />
              <AddProductInfoCustomSpecs
                onChangeCustomSpecs={onChangeCustomSpecs}
                customSpecs={productDetails?.specification}
              />

              <Divider />

              <Divider />
              <AddProductFooterButtons
                onClearFields={onClearFieldsWarning}
                onCreateProduct={() => {
                  onCreateProduct();
                  setCanCheckValidation(true);
                }}
              />
            </Card>
          </form>
        </Box>
        <RecentAddedProductsTable
          recentProducts={recentProducts}
          onProductPress={onProductPress}
        />
      </Container>
      <FormAddDialog
        loading={addCategoryLoading}
        fieldName="Category"
        title="Create Category"
        isVisible={isCreateCategoryVisible}
        subTitle="Input Category"
        onAddPress={onAddCategoryOnModalPress}
        handleClose={onCloseCreateCategoryDialog}
      />
      {/* <FormAddDialog
        loading={addManufacturerLoading}
        fieldName="Manufacturer"
        title="Create Manufacturer"
        isVisible={isCreateManufacturerVisible}
        subTitle="Input Manufacturer"
        onAddPress={onAddManufacturerOnModalPress}
        handleClose={onCloseCreateManufacturerDialog}
      /> */}
    </Page>
  );
};

export default AddProductView;
