import { createMuiTheme, colors } from '@material-ui/core';
import { appInformation } from 'src/constants';
import shadows from './shadows';
import typography from './typography';

export const theme = createMuiTheme({
  palette: {
    background: {
      // dark: '#F4F6F8',
      default: colors.common.white,
      paper: colors.common.white
    },
    primary: {
      main: appInformation?.store_branch === 'alabang' ? '#0c4a1b' : '#0C304A'
    },
    error: {
      main: colors.red[500]
    },
    secondary: {
      main: colors.red[500]
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600]
    }
  },
  shadows,
  typography
});