import React, { FC, memo, useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { BranchListDropDown } from 'src/components/dropdown';
import { SearchKeyword, SortDirection } from 'src/components/filters';
import { CategoryListDropdown } from 'src/components/filters/CategoryDropdown';
import { PopoverNotification } from 'src/components/popover';
import { debounce, isEmpty } from 'lodash';

type onKeyDownAction = 'search' | 'category';

type Props = {
  isLoading?: boolean;
  isCategorySelect: boolean;
  defaultBranches?: number[];
  onApplyFilter: () => void;
  onHandleSearch: (keyword: string) => void;
  onHandleCategories: (categoryId: number) => void;
  onHandleSortDirection: (sortDir: 'asc' | 'desc') => void;
  onHandleBranch: (branchIds?: number[]) => void;
  onHandleFilterSearhBy: (searchBy: string) => void;
  onHandleErrorClose: () => void;
};

const component: FC<Props> = ({
  isLoading,
  isCategorySelect,
  defaultBranches,
  onApplyFilter,
  onHandleSearch,
  onHandleCategories,
  onHandleSortDirection,
  onHandleBranch,
  onHandleFilterSearhBy,
  onHandleErrorClose
}) => {
  const [filterByValue, setFilterByValue] = useState<string>('product');
  const [isClearSearchKeyword, setIsClearSearchKeyword] = useState<boolean>(
    false
  );
  const [isCategory, setIsCategory] = useState<boolean>(false); //check if category is set
  const [isSearchKeyword, setIsSearchKeyword] = useState<string>(''); //check search is not empty
  const [isKeyDown, setIsKeyDown] = useState<boolean>(false); //to avoid spamming keys
  const anchorRef = useRef(null);

  const onFilterChange = (filter: string) => {
    onHandleFilterSearhBy(filter);
    setFilterByValue(filter);
  };

  const handleClose = () => {
    onHandleErrorClose();
  };

  const handleCategoryChange = (categoryId: number) => {
    onHandleCategories(categoryId);
    setIsClearSearchKeyword(true);
    if (categoryId) {
      setIsCategory(true);
    } else {
      setIsCategory(false);
    }
  };

  const handleSearchKeyword = (searchKeyword: string) => {
    onHandleSearch(searchKeyword);
    setIsSearchKeyword(searchKeyword);
    if (isEmpty(searchKeyword)) {
      setIsClearSearchKeyword(false);
    }
  };

  const onHandleKeyDown = (
    event: React.KeyboardEvent<HTMLDivElement>,
    action?: onKeyDownAction
  ) => {
    //no category no onkeydown event
    if (!isCategory && action === 'category') {
      return;
    }
    //no keyword no onkeydown event
    if (!isSearchKeyword && action === 'search') {
      return;
    }
    if (event.key === 'Enter' && !isLoading && !isKeyDown) {
      setIsKeyDown(true);
      debounce(() => {
        onApplyFilter();
        setIsKeyDown(false);
      }, 500)();
    }
  };

  useEffect(() => {
    onHandleFilterSearhBy(filterByValue);
  }, [filterByValue, onHandleFilterSearhBy]);

  return (
    <Card style={{ padding: '1rem' }}>
      <Grid container spacing={1}>
        <Grid item md={12} lg={12}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Search Filter by</FormLabel>
            <RadioGroup
              row
              aria-label="search filter"
              name="filter by"
              value={filterByValue}
              onChange={(event) => onFilterChange(event.target.value)}
            >
              <FormControlLabel
                value="product"
                control={<Radio />}
                label="Product Name"
              />
              <FormControlLabel
                value="serial"
                control={<Radio />}
                label="Serial No"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <div onKeyDown={(event) => onHandleKeyDown(event, 'search')}>
            <SearchKeyword
              isClearEvent={isClearSearchKeyword}
              onHandleSearchKeyword={(searchKeyword: string) =>
                handleSearchKeyword(searchKeyword)
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <div
            ref={anchorRef}
            onKeyDown={(event) => onHandleKeyDown(event, 'category')}
          >
            <CategoryListDropdown
              onHandleCategories={(categoryId: number) =>
                handleCategoryChange(categoryId)
              }
            />
          </div>
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <SortDirection
            sortLabel="Sort by Purchased Date"
            onHandleChangeSortDirection={(sortDir: 'asc' | 'desc') =>
              onHandleSortDirection(sortDir)
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} style={{ padding: 0 }}>
          <BranchListDropDown
            defaultValue={defaultBranches}
            multiple
            isElevated={false}
            onHandleBranchChange={(branchIds?: number[]) =>
              onHandleBranch(branchIds)
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <Box style={{ padding: '1rem' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onApplyFilter}
              disabled={isLoading}
            >
              Apply Filter
            </Button>
          </Box>
        </Grid>
      </Grid>

      {isCategorySelect && (
        <PopoverNotification
          severity={'error'}
          textInfo="Category is Required"
          isOpen={isCategorySelect}
          anchorRef={anchorRef.current}
          onHandleClose={handleClose}
        />
      )}
    </Card>
  );
};

export const DecayingStockToolBar = memo(component);
