/* eslint-disable no-unused-vars */
import React, { FC, memo, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { AlternatingColorTableRow } from 'src/components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { TransferCountDataList } from 'src/redux/slices/stock-transfer';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { copyToClipboard } from 'src/utils';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  root: {},
  tableRow: {
    height: 55,
    padding: 10
  },
  closeButton: { position: 'absolute', right: 5, top: 5 }
}));

interface Props {
  isOpen: boolean;
  transferListings?: TransferCountDataList[];
  onHandleClose: () => void;
}

const component: FC<Props> = ({ isOpen, transferListings, onHandleClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'lg'} onClose={onHandleClose}>
      <DialogTitle>
        <Typography variant="h5">
          Transfer Stock Listings(On Transfer Item)
        </Typography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          color="secondary"
          onClick={onHandleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PerfectScrollbar>
          <Box minWidth={1050} mb={5}>
            <Table size="small">
              <TableHead>
                <TableRow style={{ borderBottom: '2px solid gray' }}>
                  <TableCell>Serial No.</TableCell>
                  <TableCell> Origin Branch</TableCell>
                  <TableCell>Receiving Branch</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {transferListings?.map((transfer) => (
                  <AlternatingColorTableRow
                    key={transfer?.serial_no}
                    className={classes.tableRow}
                  >
                    <TableCell>
                      <Button
                        startIcon={<FileCopyIcon />}
                        onClick={() =>
                          copyToClipboard(transfer?.serial_no || '')
                        }
                      />
                      {transfer?.serial_no}{' '}
                    </TableCell>
                    <TableCell>{transfer?.origin_branch}</TableCell>
                    <TableCell>{transfer?.receiving_branch}</TableCell>
                  </AlternatingColorTableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </PerfectScrollbar>
      </DialogContent>
    </Dialog>
  );
};

export const TransferStockListingDialog = memo(component);
