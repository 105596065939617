import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectKachiPointsState = (state: RootState) => state.kachiPoints;

const selectKachiPointsUserList = createSelector(
  selectKachiPointsState,
  (state) => state.kachiPointsList
);

const selectKachiPointsUserHistory = createSelector(
  selectKachiPointsState,
  (state) => state.kachiPointsUserHistory
);

const selectUserListLoading = createSelector(
  selectKachiPointsState,
  (state) => state.isLoadingUserList
);

const selectUserHistoryLoading = createSelector(
  selectKachiPointsState,
  (state) => state.isLoadingUserHistory
);

const selectShowAddKachiPointForm = createSelector(
  selectKachiPointsState,
  (state) => state.showAddKachiForm
);

const selectKachiUserListMeta = createSelector(
  selectKachiPointsState,
  (state) => state.kachiListMeta
);

const selectKachiUserHistorytMeta = createSelector(
  selectKachiPointsState,
  (state) => state.kachiListMeta
);

export const selectors = {
  selectKachiPointsUserList,
  selectKachiPointsUserHistory,
  selectUserListLoading,
  selectUserHistoryLoading,
  selectShowAddKachiPointForm,

  selectKachiUserListMeta,
  selectKachiUserHistorytMeta
};
