//===== Declare constant value here when needed for working with tables ====

export const fastMovingProductRowHeader = [
  'Branches Sold',
  'Product Name',
  'Dealer Price',
  'Retail Price',
  'Gross',
  'Margin',
  'Suppliers',
  'Sold Quantity',
  'Available Stock'
];
