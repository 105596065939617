import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import { slices, useAppDispatch } from 'src/redux';
import {
  CustomInputEvent,
  PackageDetailsData,
  PackageUpdatePayload
} from 'src/types';

import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate, useParams } from 'react-router-dom';
import { localize } from 'src/constants';
import { Alert } from '@material-ui/lab';
import usePrompt from 'src/utils/navigation-prompt';
import {
  PackageCoverImage,
  PackageGeneralInfoContent,
  PackageProductCard
} from '../package-component';
import { isPackageUpdateParamsValid } from 'src/utils/pc-package';
import { useAlertGlobal, usePermissions, useSnackBar } from 'src/hooks';
import { isEqual } from 'lodash';

const { actions: pcPackageActions } = slices.pcPackage;

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveBtn: {
    marginLeft: theme.spacing(2)
  }
}));

const component = ({ className, ...rest }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const snackBar = useSnackBar();
  const alertGlobal = useAlertGlobal();
  const navigate = useNavigate();
  const { id } = useParams();

  const [origpackageDetails, setOrigpackageDetails] = useState<
    PackageDetailsData
  >();
  const [packageDetails, setPackageDetails] = useState<PackageDetailsData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [publishFlag, setpublishFlag] = useState<boolean>();
  const { canEditPCPackage, canDeletePCPackage } = usePermissions();

  const nothingChanged = useMemo(
    () => isEqual(origpackageDetails, packageDetails),
    [packageDetails, origpackageDetails]
  );
  const publishFlagChanged =
    packageDetails?.published === publishFlag ? true : false;

  const getPackageDetails = async () => {
    if (id) {
      setLoading(true);
      const response = unwrapResult(
        await dispatch(
          pcPackageActions.getPackageDetailsThunk({ uuid: id })
        ).finally(() => setLoading(false))
      );
      if (response?.success && response?.originalData?.data) {
        setPackageDetails(response?.originalData?.data);
        setOrigpackageDetails(response?.originalData?.data);
        setpublishFlag(response?.originalData?.data?.published);
      }
    }
  };

  const onUpdatePackage = async () => {
    const errMsg = isPackageUpdateParamsValid(packageDetails);
    if (errMsg) {
      snackBar.show({
        severity: 'error',
        message: errMsg || 'Somethings Wrong'
      });
      return;
    }
    if (packageDetails) {
      setLoading(true);
      const productData = packageDetails?.products;
      const newproductData: any = productData?.map((obj) => {
        return {
          id: obj.product_id,
          slug: obj.slug,
          quantity: obj.quantity
        };
      });
      const adjustPayload: PackageUpdatePayload = {
        products: newproductData,
        title: packageDetails?.title,
        description: packageDetails?.description,
        published: publishFlag,
        cover: packageDetails?.cover,
        uuid: packageDetails?.uuid
      };
      snackBar.show({
        severity: 'info',
        message:
          'Updating PC package build.... This might take some time depending on images...'
      });
      const response = unwrapResult(
        await dispatch(
          pcPackageActions.pcPackageUpdateThunk(adjustPayload)
        ).finally(() => setLoading(false))
      );

      if (response.success) {
        snackBar.show({
          severity: 'success',
          message: 'Package Updated Successfully'
        });
        return;
      }

      snackBar.show({
        severity: 'error',
        message: response?.message || localize.ERR_API_UNKNOWN
      });
    }
  };

  const onDeletePackageWarning = () => {
    alertGlobal.show({
      title: 'DELETE BUILD GALLERY ITEM',
      subtitle: 'Are you sure you want to delete this item?',
      buttons: [
        {
          text: 'Yes',
          onClick: () => {
            onDeleteGallery();
            alertGlobal.hide();
          },
          color: 'secondary'
        },
        {
          text: 'Cancel',
          onClick: () => alertGlobal.hide()
        }
      ]
    });
  };

  const onDeleteGallery = async () => {
    if (packageDetails?.uuid) {
      setLoading(true);
      const response = unwrapResult(
        await dispatch(
          pcPackageActions.packageDeleteThunk(packageDetails?.uuid)
        ).finally(() => setLoading(false))
      );
      if (response.success) {
        navigate(-1);
        snackBar.show({
          severity: 'success',
          message: 'Package deleted successfuly'
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || localize.ERR_API_UNKNOWN
        });
      }
    }
  };

  const onChangeGeneralInfoInput = (e: CustomInputEvent) => {
    const { value, name } = e.target;
    setPackageDetails((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const onChangePropertyViaName = (value: any, name: string) => {
    const inputEvent: any = { target: { name, value } };
    onChangeGeneralInfoInput(inputEvent);
  };

  const onPackagePublishChange = async () => {
    setpublishFlag(!publishFlag);
    window.alert(
      'Publish state changed, please update this package to save changes'
    );
  };

  usePrompt(
    'PC package is still updating / loading. Are you sure you want to leave?',
    loading
  );

  useEffect(() => {
    getPackageDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="PC Package Details">
      <LoaderBar isLoading={loading} />

      {loading || !canEditPCPackage ? null : (
        <Alert severity={publishFlag ? 'info' : 'warning'}>
          <Typography variant="subtitle2">{`THIS PACKAGE IS ${
            publishFlag ? 'PUBLISHED' : 'NOT YET PUBLISHED'
          }`}</Typography>
          <Button
            size="small"
            variant="outlined"
            onClick={onPackagePublishChange}
            color={publishFlag ? 'secondary' : 'primary'}
          >
            {publishFlag ? 'UNPUBLISH' : 'PUBLISH'}
          </Button>
        </Alert>
      )}
      <Container maxWidth={false}>
        <Box mt={2}>
          <form
            autoComplete="off"
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <LoaderBar isLoading={false} />
              <CardHeader
                title="PC Package Details"
                subheader="The information of pc package."
              />
              <Divider />
              <PackageCoverImage
                data={packageDetails?.cover}
                onCoverImageChange={(imgStr) => {
                  onChangePropertyViaName(imgStr, 'cover');
                }}
              />
              <Divider />
              <PackageGeneralInfoContent
                data={packageDetails}
                onChangeInput={onChangeGeneralInfoInput}
              />
              <Divider />
              <PackageProductCard
                data={packageDetails?.products}
                onComponentsChange={(products) =>
                  onChangePropertyViaName(products, 'products')
                }
              />
              <Divider />
              <Divider />
              <Divider />
              <LoaderBar isLoading={loading} />
            </Card>
          </form>
        </Box>
        <div className={classes.btnContainer}>
          {canDeletePCPackage ? (
            <Button
              fullWidth
              startIcon={<DeleteIcon />}
              onClick={onDeletePackageWarning}
              color="secondary"
              variant="outlined"
              disabled={loading}
            >
              Delete PC Package
            </Button>
          ) : null}
          {canEditPCPackage ? (
            <Button
              fullWidth
              disabled={(nothingChanged && publishFlagChanged) || loading}
              startIcon={<SaveIcon />}
              onClick={onUpdatePackage}
              color="primary"
              className={classes.saveBtn}
              variant="contained"
            >
              Update PC Package
            </Button>
          ) : null}
        </div>
      </Container>
    </Page>
  );
};

export const PcPackageDetailsView = React.memo(component);
