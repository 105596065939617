import React from 'react';
import { transactionDetails } from 'src/constants';
import {
  Box,
  Card,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 20
  },
  logo: {
    paddingTop: 10
  },
  intro: {
    paddingRight: 20,
    textAlign: 'center',
    paddingTop: 20
  },
  quotationslip: {
    paddingTop: 20
  },
  footer: {
    marginTop: 20,
    marginBottom: 20
  },
  background: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const TransactionSlipView = () => {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <PerfectScrollbar>
        <Grid container spacing={1}>
          <Grid className={classes.background} item xs={12} sm={3}>
            <img
              className={classes.logo}
              height="170px"
              alt="pc"
              src="/static/images/avatars/pcworth_logo.png"
            />
          </Grid>
          <Grid item xs={12} sm={9}>
            <Typography
              className={classes.intro}
              color="textPrimary"
              gutterBottom
              variant="h2"
            >
              Owned and Operated by: DRJ TECHNOLOGIES TRADING CORP Location: 650
              sulucan st. Barangay 458 Sampaloc Manila Tel No. 863-89910 Cell
              No. 0967-701-8178
            </Typography>
          </Grid>
        </Grid>
        <Typography
          className={classes.quotationslip}
          color="textPrimary"
          gutterBottom
          variant="h3"
        >
          QUOTATION SLIP
        </Typography>
        <Grid alignItems="center" container spacing={1}>
          <Grid item lg={10} md={3} xs={6}>
            <Typography color="textPrimary" gutterBottom variant="h5">
              Sold to:
            </Typography>
            <Typography color="textPrimary" gutterBottom variant="h5">
              Contact No:
            </Typography>
          </Grid>
          <Grid item lg={2} md={3} xs={6}>
            <Typography color="textPrimary" gutterBottom variant="h5">
              Date:
            </Typography>
            <Typography color="textPrimary" gutterBottom variant="h5">
              Term:
            </Typography>
          </Grid>
        </Grid>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Qty</TableCell>
                <TableCell>TYPE</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Unit Price</TableCell>
                <TableCell>Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionDetails.items.map((item: any) => (
                <TableRow
                  // onClick={() => onCategoryListingPress(item)}
                  hover
                  key={item.id}
                >
                  <TableCell>{item.quantity}</TableCell>
                  <TableCell>{item.category_name}</TableCell>
                  <TableCell>{item.product_name}</TableCell>
                  <TableCell>{item.retail_price}</TableCell>
                  <TableCell>{item.retail_price}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
        <Typography
          className={classes.footer}
          color="textPrimary"
          gutterBottom
          variant="h5"
        >
          Note: This quotation is not a contract or a bill. it is our best guess
          at the total price for the service and goods described above. The
          Customer will be billed after indicating acceptance of this quote.
          Payment will be due prior to the delivery of service and goods.
        </Typography>
      </PerfectScrollbar>
    </Card>
  );
};

export default TransactionSlipView;
