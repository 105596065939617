import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { AddressDetailData } from 'src/types/pcw-user-address';
interface Props {
  className?: string;
  addressDetail: AddressDetailData;
  isList?: boolean;
}

const CurrentAddressCard = ({ addressDetail, isList }: Props) => {
  return (
    <Box style={{ padding: '25px' }}>
      <Typography
        style={{ marginTop: 1, marginBottom: 15 }}
        color="textPrimary"
        gutterBottom
        variant="h4"
      >
        {!isList ? 'Current Address' : ''}
      </Typography>
      <Grid
        container
        spacing={3}
        style={{ border: '2px solid #F5F5F5', padding: '15px' }}
      >
        <Grid item md={12} xs={12}>
          <Grid item md={12} style={{ display: 'flex', marginBottom: 5 }}>
            <Typography>
              {addressDetail?.contact_person}
              {' | '}
              {addressDetail?.contact_number}
            </Typography>
          </Grid>
          <Grid item md={12} style={{ display: 'flex' }}>
            <Typography style={{ color: 'gray', fontSize: '14px' }}>
              {addressDetail?.street_details}
            </Typography>
          </Grid>
          <Grid item md={12} style={{ display: 'flex' }}>
            <Typography
              style={{
                display: '-webkit-box',
                overflow: 'hidden',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 2,
                color: 'gray',
                fontSize: '14px'
              }}
            >
              {addressDetail?.delivery_address}
            </Typography>
          </Grid>
          {!isList ? (
            <Grid item md={12} style={{ display: 'flex', marginBottom: 1 }}>
              <Typography
                style={{
                  display: '-webkit-box',
                  overflow: 'hidden',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  color: 'grey.600',
                  fontSize: '14px'
                }}
              >
                {addressDetail?.barangay +
                  ' | ' +
                  addressDetail?.city +
                  ' | ' +
                  addressDetail?.province +
                  ' | ' +
                  addressDetail?.region}
              </Typography>
            </Grid>
          ) : null}
          <Grid item md={12} style={{ display: 'flex' }}>
            <Typography>{addressDetail?.address_type}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CurrentAddressCard;
