import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectFlashDealsState = (state: RootState) => state.flashDeals;

const selectFlashDealsList = createSelector(
  selectFlashDealsState,
  (state) => state.flashDealsList
);

const selectGetFlashDealsListRequest = createSelector(
  selectFlashDealsState,
  (state) => state.getFlashDealsListRequest
);

const selectIsListLoading = createSelector(
  selectFlashDealsState,
  (state) => state.isListLoading
);

const selectFlashDealsDetails = createSelector(
  selectFlashDealsState,
  (state) => state.flashDealsDetails
);

const selectFlashDealsPaginationMeta = createSelector(
  selectFlashDealsState,
  (state) => state.flashDealsPaginationMeta
);

export const selectors = {
  selectFlashDealsList,
  selectFlashDealsDetails,
  selectIsListLoading,
  selectGetFlashDealsListRequest,
  selectFlashDealsPaginationMeta
};
