import { Box, Container } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { Formik } from 'formik';
import React from 'react';
import { useFormPasscodeDialog } from 'src/hooks';
import { slices, useAppSelector } from 'src/redux';
import { Passcode } from 'src/types';
import * as Yup from 'yup';
import { LoaderBar } from '../LoaderBar';

const { selectors } = slices.user;

export const FormPasscodeDialog: React.FC = () => {
  const {
    hideFormPasscodeDialog,
    validatePasswordRequest
  } = useFormPasscodeDialog();
  const formDialog = useAppSelector(selectors.selectFormPasscodeDialog);
  const validatePasscodeLoading = useAppSelector(
    selectors.selectValidatePasscodeLoading
  );

  const handleClose = () => {
    hideFormPasscodeDialog();
  };

  const onSubmit = (values: Passcode) => {
    validatePasswordRequest(values);
  };

  return (
    <Dialog
      open={!!formDialog?.isVisible}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{'Enter Passcode'}</DialogTitle>
      <DialogContent>
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              username: '',
              passcode: ''
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string()
                .max(255)
                .required('Username is required'),
              passcode: Yup.string()
                .max(255)
                .required('Passcode is required')
            })}
            onSubmit={onSubmit}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.username && errors.username)}
                  fullWidth
                  helperText={touched.username && errors.username}
                  label="Username"
                  id="username"
                  margin="normal"
                  name="username"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.username}
                  variant="outlined"
                />
                <TextField
                  id="passcode"
                  error={Boolean(touched.passcode && errors.passcode)}
                  fullWidth
                  helperText={touched.passcode && errors.passcode}
                  label="Passcode"
                  margin="normal"
                  name="passcode"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.passcode}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    disabled={validatePasscodeLoading}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {'Validate'}
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
          <LoaderBar isLoading={validatePasscodeLoading} />
        </Container>
      </DialogContent>
    </Dialog>
  );
};
