export const selectors = {};

// oauth slice might no need a selector.
// import { createSelector } from 'reselect';
// import { RootState } from 'src/redux/store';

// const selectUser = (state: RootState) => state.user;

// const selectUserInfo = createSelector(selectUser, (state) => state.userInfo);
// const selectAllUsers = createSelector(selectUser, (state) => state.users);

// export const selectors = {
//   selectUserInfo,
//   selectAllUsers
// };
