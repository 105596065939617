import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { OutOfStockChip, Page } from 'src/components';
import Toolbar from './Toolbar';
import CategoryDetail from './CategoryDetail';
import { FixMeLaterApi } from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { Product } from 'src/types/product';
import { isNil } from 'lodash';

const { actions: productActions } = slices.product;

const useStyles = makeStyles(() => ({
  root: {}
}));

// TODO: Get category detail.
const CategoryView = () => {
  const classes = useStyles();
  const location = useLocation();
  const state: any = location.state;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [products, setProducts] = useState<Product[]>([]);

  const onCategoryListingPress = (item: FixMeLaterApi) => {
    //removed state routes
    navigate(`/app/products/${item.id}`);
  };

  const getProductsViaCategory = async (keyword?: string) => {
    if (state?.id) {
      const response = unwrapResult(
        await dispatch(
          productActions.getProductsWithFilterThunk({
            category_id: state?.id || '',
            keyword
          })
        )
      );
      if (response?.success && response.originalData.data) {
        setProducts(response.originalData.data);
      }
    }
  };

  const onSearchProduct = (keyword: string | undefined) => {
    if (!isNil(keyword)) {
      getProductsViaCategory(keyword);
    }
  };

  useEffect(() => {
    getProductsViaCategory();
  }, []);

  return (
    <Page className={classes.root} title={`Category: ${state?.name}`}>
      <Container maxWidth={false}>
        <Toolbar onSearchProduct={onSearchProduct} />
        <CategoryDetail category={state} />
        <Box marginY={2}>
          <Card>
            <Divider />
            <PerfectScrollbar>
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Retail Price</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((item: Product) => (
                      <TableRow
                        onClick={() => onCategoryListingPress(item)}
                        hover
                        key={item.id}
                      >
                        <TableCell>
                          {item?.name ?? '--'}
                          {!item?.count ? <OutOfStockChip /> : null}
                        </TableCell>
                        <TableCell>₱ {item.retail_price}</TableCell>
                        <TableCell>{item.count}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default CategoryView;
