import { TransactionCategoryOption } from "src/types";

export const paymentCategoriesOptions: TransactionCategoryOption[] = [
  {
    name: 'Payment',
    value: 'payment'
  },
  {
    name: 'Shipping',
    value: 'shipping'
  },
  {
    name: 'Tip',
    value: 'tip'
  }
];

