import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectGalleryState = (state: RootState) => state.gallery;

const selectGalleryList = createSelector(
  selectGalleryState,
  (state) => state.galleryList
);

const selectGetGalleryListRequest = createSelector(
  selectGalleryState,
  (state) => state.getGalleryListRequest
);

const selectIsListLoading = createSelector(
  selectGalleryState,
  (state) => state.isListLoading
);

const selectGalleryDetails = createSelector(
  selectGalleryState,
  (state) => state.galleryDetails
);

const selectGalleryPaginationMeta = createSelector(
  selectGalleryState,
  (state) => state.galleryPaginationMeta
);

export const selectors = {
  selectGalleryList,
  selectGalleryDetails,
  selectIsListLoading,
  selectGetGalleryListRequest,
  selectGalleryPaginationMeta
};
