import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { KachiPointsState } from 'src/types/kachi-points';
import * as thunks from './thunks';

const initialState: KachiPointsState = {
  isLoadingUserList: false,
  isLoadingUserHistory: false,
  showAddKachiForm: false,
  kachiPointsList: [],
  kachiPointsUserHistory: [],

  kachiListMeta: {},
  kachiListHistoryMeta: {}
};

const showAddKachiForm = (
  state: KachiPointsState,
  action: PayloadAction<boolean>
) => {
  state.showAddKachiForm = action.payload;
};

const slice = createSlice({
  name: 'kachiPoints',
  initialState,
  reducers: { showAddKachiForm },
  extraReducers(builder) {
    builder.addCase(thunks.getKachiPointsUserListThunk.pending, (state) => {
      state.isLoadingUserList = true;
    });
    builder.addCase(thunks.getKachiPointsUserListThunk.rejected, (state) => {
      state.isLoadingUserList = false;
    });
    builder.addCase(
      thunks.getKachiPointsUserListThunk.fulfilled,
      (state, actions) => {
        state.isLoadingUserList = false;
        state.kachiPointsList = actions?.payload?.originalData.data || [];
        state.kachiListMeta = actions?.payload?.originalData?.meta || {};
      }
    );
    //==========
    builder.addCase(thunks.getKachiUserHistoryThunk.pending, (state) => {
      state.isLoadingUserHistory = true;
    });
    builder.addCase(thunks.getKachiUserHistoryThunk.rejected, (state) => {
      state.isLoadingUserHistory = false;
    });
    builder.addCase(
      thunks.getKachiUserHistoryThunk.fulfilled,
      (state, actions) => {
        state.isLoadingUserHistory = false;
        state.kachiPointsUserHistory = actions?.payload?.originalData.data || [];
        state.kachiListHistoryMeta = actions?.payload?.originalData?.meta || {};
      }
    );
  }
});

export const reducer = slice.reducer;
export const actions = { ...slice.actions, ...thunks };
