import { createSlice } from '@reduxjs/toolkit';
import { Category } from 'src/constants/inventory';
import * as thunks from './thunks';

type State = {
  categories: Category[];
  categoriesOnFilter: Category[];
};

const initialState: State = {
  categories: [],
  categoriesOnFilter: []
};

const slice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(
      thunks.getCategoriesThunk.fulfilled,
      (state, { payload }) => {
        state.categories = payload.originalData.data;
      }
    );
    builder.addCase(
      thunks.getCategoriesFilterThunk.fulfilled,
      (state, { payload }) => {
        state.categoriesOnFilter = payload.originalData.data;
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
