import React, { Fragment, useEffect, useState } from 'react';
// import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { Page } from 'src/components';
import ProfileAddressDetails from './ProfileAddressDetails';
import Profile from '../user-profile/Profile';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { AccountProfileData } from 'src/types';
import { unwrapResult } from '@reduxjs/toolkit';
import { useParams } from 'react-router-dom';
import { AddressDetailData } from 'src/types/pcw-user-address';

const { selectors: pcwUserSelectors } = slices.pcwUserAccounts;
const { actions: PcwAddressActions } = slices.pcwUserAddress;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export default function UserAccountAddress() {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { userId, addressId } = useParams();

  const userAccountData: AccountProfileData = useAppSelector(
    pcwUserSelectors?.selectUserAccountProfileData || {}
  );

  const [userAddresses, getAddressDetail] = useState<AddressDetailData>();

  const getUserAddressDetail = async (userId?: any, addressId?: any) => {
    const response = unwrapResult(
      await dispatch(
        PcwAddressActions?.getUserAddressDetailThunk({
          user_id: userId,
          address_id: addressId
        })
      )
    );
    if (response?.success) {
      getAddressDetail(response?.originalData?.data);
    }
  };

  useEffect(() => {
    getUserAddressDetail(userId, addressId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAccountData]);

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Fragment>
          {/* <LoaderBar isLoading={isLoading} /> */}

          <Grid container spacing={3}>
            <Profile customer={userAccountData} />
            <Grid item xs={12}>
              {userAddresses ? (
                <ProfileAddressDetails addressDetail={userAddresses} />
              ) : null}
            </Grid>
          </Grid>
        </Fragment>
      </Container>
    </Page>
  );
}
