import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import { appInformation } from 'src/constants';
import { slices, useAppSelector } from 'src/redux';

const { selectors: userSelectors } = slices.user;

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: 'pink',
    height: '10vh',
    width: '90vw',
    zIndex: 9999,
    position: 'absolute'
  }
}));

export const WarningOverlay = () => {
  const classes = useStyles();
  const userInfo = useAppSelector(userSelectors.selectUserInfo);

  const joshuaIsUsingProd = useMemo(
    () =>
      appInformation.branch_cicd === 'master' &&
      userInfo?.username === 'joshuadablo123',
    [userInfo]
  );

  return joshuaIsUsingProd ? (
    <div className={classes.container}>
      <h1>WARNING JOSHUA! THIS IS PRODUCTION!!!!</h1>
    </div>
  ) : null;
};
