import React, { useCallback, useMemo } from 'react';
import { TextField, makeStyles } from '@material-ui/core';

import { useProductPartDetailsLogic } from 'src/hooks';
import {
  CustomInputEvent,
  PartFields,
  ProductPartDetailsCategory
} from 'src/types';
import { convertImageFileToBase64, isImageStrJpgJpeg } from 'src/utils';
import { colors } from 'src/constants';

interface Props {
  productCategory: ProductPartDetailsCategory;
  field: PartFields<any>;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  uploadImageField: {
    borderWidth: 1,
    borderColor: colors.grey[800],
    display: 'flex',
    flexDirection: 'row'
  },
  imgPreview: {
    width: undefined,
    height: 70,
    marginRight: theme.spacing(1)
  }
}));

const component = ({ productCategory, field }: Props) => {
  const classes = useStyles();

  const {
    fieldError,
    setProductPartDetailsPropertyViaKey,
    getProductPartDetailsViaKey
  } = useProductPartDetailsLogic();

  const getImageFileOnChange = (e: any) => {
    if (e?.target?.files && e?.target?.files?.length > 0) {
      return e.target.files[0];
    }
    return '';
  };

  const onImageChange = async (e: CustomInputEvent) => {
    const { name } = e.target;
    const targetFile = getImageFileOnChange(e);
    const imgInTxt = await convertImageFileToBase64(targetFile);
    // TODO: Allow only images that are Jpg or jpeg format
    if (
      imgInTxt &&
      typeof imgInTxt === 'string' &&
      isImageStrJpgJpeg(imgInTxt)
    ) {
      setProductPartDetailsPropertyViaKey(productCategory, name, imgInTxt);
    }
  };

  const fieldErr = useCallback(
    (fieldArg) => {
      return fieldError(productCategory, fieldArg) ? true : false;
    },
    [fieldError, productCategory]
  );

  const imagePreview = useMemo(() => {
    const data = getProductPartDetailsViaKey(productCategory);
    if (data?.image_upload) {
      return data?.image_upload;
    }
    if (data?.image_url) {
      return data?.image_url;
    }
    return '';
  }, [getProductPartDetailsViaKey, productCategory]);

  const isRequired = useMemo(() => {
    if (!imagePreview) {
      return true;
    }
    return field?.required;
  }, [field, imagePreview]);

  return (
    <div className={classes.uploadImageField}>
      {imagePreview ? (
        <img
          alt="img_preview"
          className={classes.imgPreview}
          src={imagePreview}
        />
      ) : null}
      <TextField
        helperText={
          fieldError(productCategory, field?.part_property)?.error ||
          field?.hint
        }
        fullWidth
        error={fieldErr(field?.part_property)}
        hiddenLabel
        required={isRequired}
        variant="outlined"
        inputProps={{ accept: 'image/jpg, image/jpeg' }}
        id={field?.part_property}
        name={field?.part_property}
        onChange={onImageChange}
        type="file"
      />
    </div>
  );
};

export const ProductPartImageFieldInput = React.memo(component);
