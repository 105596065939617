import React from 'react';
import { View, StyleSheet, Image } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';
import { formatCurrency } from 'src/utils';
import { TextRegularWarranty } from './TextWarranty';

interface Props {
  amount: number;
  containerStyle?: Style;
  isSIPricing?: boolean;
}

const styles = StyleSheet.create({
  text: {
    fontSize: 8,
    maxLines: 1
  },
  image: {
    height: 5,
    width: 5,
    marginTop: 2,
    marginHorizontal: 2
  },
  container: {
    flexDirection: 'row'
  }
});

// NOTE: Save yourself some headache and do not spend
// so much time on wrong unicode on peso sign.
export const RenderCurrency = ({
  isSIPricing = false,
  amount,
  containerStyle = {}
}: Props) => {
  return (
    <View style={[styles.container, containerStyle]}>
      {isSIPricing ? (
        <TextRegularWarranty style={styles.text}>PHP </TextRegularWarranty>
      ) : (
        <Image
          style={[styles.image, { paddingLeft: 2 }]}
          src="/static/images/icons/ico-php.png"
        />
      )}
      <TextRegularWarranty style={styles.text}>
        {`${formatCurrency(amount, '', 2, { removeCurrency: true })}`}
      </TextRegularWarranty>
    </View>
  );
};
