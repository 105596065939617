import React from 'react';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

interface Props {
  orderRefNo?: string;
  updateDisabled?: boolean;
  onUpdateDetails?: () => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(4),
    alignItems: 'center'
  }
}));

const component = ({
  orderRefNo,
  onUpdateDetails,
  updateDisabled = false
}: Props) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between">
      <Typography color="textPrimary" variant="h3" className={classes.root}>
        {`Order Reference No. ${orderRefNo || ''}`}
      </Typography>
      <div>
        <Button
          disabled={updateDisabled}
          onClick={onUpdateDetails}
          color="primary"
          variant="outlined"
        >
          Update Order Details
        </Button>
      </div>
    </Box>
  );
};

export const EcomOrderDetailsToolbar = React.memo(component);
