import { StyleSheet } from '@react-pdf/renderer';
import { colors } from 'src/constants';

// NOTE: Check https://react-pdf.org/fonts for more built in fonts.
const mainFont = 'Helvetica';
const mainFontBold = `${mainFont}-Bold`;

export const transferLogsText = StyleSheet.create({
  text: {
    fontSize: 8,
    fontFamily: mainFont,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  textBold: {
    fontSize: 8,
    fontFamily: mainFontBold,
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  url: {
    fontSize: 8,
    textDecoration: 'underline',
    color: colors.blue[600],
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  underline: {
    textDecoration: 'underline'
  }
});
