import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectProductPartDetailsState = (state: RootState) =>
  state.productPartDetails;

const selectProductPartDetails = createSelector(
  selectProductPartDetailsState,
  (state) => state.productPartDetails
);

const selectProductPartDetailsErrFields = createSelector(
  selectProductPartDetailsState,
  (state) => state.errFields
);

export const selectors = {
  selectProductPartDetails,
  selectProductPartDetailsErrFields
};
