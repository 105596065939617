import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, CommonResponseMessage } from 'src/types';
import {
  AssignPermissionToUserRequest,
  CommonPermissionsResponse,
  CreatePermissionAddToRoleRequest,
  CreatePermissionAddToUserRequest,
  CreatePermissionRequest,
  CreatePermissionResponse,
  DeletePermissionResponse,
  GetPermissionViaIDResponse,
  GetUsersViaPermissionIDResponse,
  RevokePermissionToUserRequest
} from 'src/types/permission';

export const getPermissionsThunk = createAsyncThunk<
  CommonAxiosResponse<CommonPermissionsResponse>,
  undefined,
  { state: RootState }
>('permission/getPermissions', async (data) => {
  const response = await Api.get('/permissions/getPermissions', data);
  if (!response.success) {
    throw new Error('Failed at getPermissionsThunk.');
  }
  return response;
});

export const getPermissionViaIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetPermissionViaIDResponse>,
  number,
  { state: RootState }
>('permission/getPermissionViaIdThunk', async (id) => {
  const response = await Api.get(`/permissions/getPermission/${id}`);
  if (!response.success) {
    throw new Error('Failed at getPermissionViaIdThunk.');
  }
  return response;
});

export const getUsersViaPermissionId = createAsyncThunk<
  CommonAxiosResponse<GetUsersViaPermissionIDResponse>,
  number,
  { state: RootState }
>('permission/getUsersViaPermissionId', async (id) => {
  const response = await Api.get(`/permissions/getUsersViaPermissionId/${id}`);
  if (!response.success) {
    throw new Error('Failed at getUsersViaPermissionId.');
  }
  return response;
});

export const createPermissionThunk = createAsyncThunk<
  CommonAxiosResponse<CreatePermissionResponse>,
  CreatePermissionRequest,
  { state: RootState }
>('permission/createPermission', async (data) => {
  const response = await Api.post(`/permissions/createPermission`, data);
  return response;
});

export const createPermissionThenAddToRoleThunk = createAsyncThunk<
  CommonAxiosResponse<CreatePermissionResponse>,
  CreatePermissionAddToRoleRequest,
  { state: RootState }
>('permission/createPermissionThenAddToRoleThunk', async (data) => {
  const response = await Api.post(
    `/permissions/createPermissionThenAddToRole`,
    data
  );
  return response;
});

export const createPermissionThenAddToUserThunk = createAsyncThunk<
  CommonAxiosResponse<CreatePermissionResponse>,
  CreatePermissionAddToUserRequest,
  { state: RootState }
>('permission/createPermissionThenAddToUserThunk', async (data) => {
  const response = await Api.post(
    `/permissions/createPermissionThenAddToUser`,
    data
  );
  return response;
});

export const assignPermissionToUserThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  AssignPermissionToUserRequest,
  { state: RootState }
>('role/assignPermissionToUserThunk', async (data) => {
  const response = await Api.post(`/permissions/assignPermissionToUser`, data);
  return response;
});

export const revokePermissionToUserThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  RevokePermissionToUserRequest,
  { state: RootState }
>('role/revokePermissionToUserThunk', async (data) => {
  const response = await Api.post(`/permissions/removePermissionToUser`, data);
  return response;
});

export const permissionDeleteThunk = createAsyncThunk<
  CommonAxiosResponse<DeletePermissionResponse>,
  number,
  { state: RootState }
>('permissions/permissionDeleteThunk', async (id) => {
  const response = await Api.delete(`/permissions/deletePermission/${id}`);
  return response;
});
