import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { Page } from 'src/components';
import Toolbar from './Toolbar';
import Suppliers from './Suppliers';
import { LoaderBar } from 'src/components';
import { debounce } from 'lodash';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { Supplier } from 'src/types/supplier';
import { useNavigate } from 'react-router-dom';
import { Pagination } from '@material-ui/lab';

const {
  actions: supplierActions,
  selectors: supplierSelectors
} = slices.supplier;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const SupplierList = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const suppliers = useAppSelector(supplierSelectors.selectSupplierList);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = React.useState(1);

  const getSuppliers = debounce(async (keyword: string = '') => {
    setIsLoading(true);
    dispatch(supplierActions.getSuppliersThunk({ keyword })).finally(() =>
      setIsLoading(false)
    );
  }, 500);

  const onAddSupplierGetData = () => {
    getSuppliers();
  };

  const onSearchSupplier = (keyword?: string) => {
    getSuppliers(keyword || '');
  };

  const onSupplierPress = (supplier: Supplier) => {
    navigate(`/app/suppliers/${supplier.id}`, { state: supplier });
  };

  const handleChange = (event: React.ChangeEvent<any>, value: number) => {
    setPage(value);
  };

  useEffect(() => {
    getSuppliers();
  }, []);

  return (
    <Page className={classes.root} title="Suppliers">
      <Container maxWidth={false}>
        <Toolbar
          onSearchSupplier={onSearchSupplier}
          onAddSupplierGetData={onAddSupplierGetData}
        />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <Suppliers onSupplierPress={onSupplierPress} suppliers={suppliers} />
        </Box>
      </Container>
      <Box display="flex" justifyContent="flex-end" p={2}>
        <Pagination count={10} page={page} onChange={handleChange} />
      </Box>
    </Page>
  );
};

export default SupplierList;
