export const allowedCategoriesForProductPartDetails = [
  'casing',
  'cpu',
  'gpu',
  'hdd',
  'motherboard',
  'psu',
  'ram',
  'ssd'
];
