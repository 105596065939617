import React, { useCallback, useEffect, useState } from 'react';
import { Button, Container, makeStyles, Typography } from '@material-ui/core';
import { AlertDialog, Page } from 'src/components';
import { AdvanceSettings, SendSmsButton, SMSContent } from './components';
import { useFormPasscodeDialog, usePermissions, useSnackBar } from 'src/hooks';
import { appInformation } from 'src/constants';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';

const { selectors: smsSelectors, actions: smsActions } = slices.sms;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#f4f6f8',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const component = () => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const dispatch = useAppDispatch();
  const { canSMSBlast } = usePermissions();
  const {
    showFormPasscodeDialog,
    hideFormPasscodeDialog,
    validatePasscodeSuccess,
    validatePasscodeMessage,
    validatePasscodeError
  } = useFormPasscodeDialog();

  const [isConfirmSendSMSVisible, setIsConfirmSendSMSVisible] = useState<
    boolean
  >(false);

  const message = useAppSelector(smsSelectors.selectMessage);
  const isLoading = useAppSelector(smsSelectors.selectSendSMSIsLoading);

  const onValidateSendSMS = useCallback(() => {
    if (!message) {
      snackBar.show({
        message: 'Message content cannot be empty',
        severity: 'error'
      });
      return false;
    }
    if (!canSMSBlast) {
      snackBar.show({ message: 'Permission Denied', severity: 'error' });
      return false;
    }
    if (appInformation?.branch_cicd === 'master') {
      snackBar.show({
        message: 'Permission Denied. Not yet permitted in master',
        severity: 'error'
      });
      return false;
    }
    return true;
  }, [canSMSBlast, message, snackBar]);

  const onToggleConfirmMessage = useCallback(
    async (value: boolean) => {
      if (value) {
        if (!onValidateSendSMS()) {
          // Invalid send sms
          return;
        }
      }
      setIsConfirmSendSMSVisible(value);
      return;
    },
    [onValidateSendSMS]
  );

  const onShowPassCodeDialog = () => {
    showFormPasscodeDialog();
  };

  const handleSend = useCallback(async () => {
    onToggleConfirmMessage(false);
    if (isLoading) {
      return;
    }
    if (!onValidateSendSMS()) {
      // Invalid send sms
      return;
    }
    if (isConfirmSendSMSVisible) {
      setIsConfirmSendSMSVisible(false);
    }
    const response = unwrapResult(
      await dispatch(smsActions.sendSMSBlastThunkV2())
    );

    if (response?.originalData?.success) {
      snackBar.show({ message: 'Send SMS blast success', severity: 'success' });
    } else {
      snackBar.show({ message: 'Send SMS blast failed', severity: 'error' });
    }
  }, [
    dispatch,
    isConfirmSendSMSVisible,
    isLoading,
    onToggleConfirmMessage,
    onValidateSendSMS,
    snackBar
  ]);

  useEffect(() => {
    if (validatePasscodeSuccess) {
      hideFormPasscodeDialog();

      handleSend();
      snackBar.show({ severity: 'success', message: validatePasscodeMessage });
    }

    if (!validatePasscodeSuccess && validatePasscodeError) {
      snackBar.show({ severity: 'error', message: validatePasscodeError });
    }
  }, [
    snackBar,
    hideFormPasscodeDialog,
    validatePasscodeSuccess,
    validatePasscodeMessage,
    validatePasscodeError,
    handleSend
  ]);

  return (
    <Page className={classes.root} title="SMS Blast">
      <Container maxWidth={false}>
        <Typography color="textPrimary" gutterBottom variant="h2">
          SMS Blast V2
        </Typography>
        <SMSContent />
        <AdvanceSettings />
        <SendSmsButton onSend={() => onToggleConfirmMessage(true)} />
        <AlertDialog
          isVisible={isConfirmSendSMSVisible}
          title="SEND SMS BLAST"
          subTitle="Are you sure you want to send SMS blast?"
          customButtons={
            <>
              <Button onClick={onShowPassCodeDialog} color="primary">
                Send SMS Blast
              </Button>
              <Button
                onClick={() => onToggleConfirmMessage(false)}
                color="secondary"
              >
                Cancel
              </Button>
            </>
          }
        />
      </Container>
    </Page>
  );
};

export const SMSBlastV2 = React.memo(component);
