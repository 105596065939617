import React from 'react';
import Button from '@material-ui/core/Button';

import { AlertDialog } from 'src/components';
import { localize } from 'src/constants';

interface Props {
  isVisible: boolean;
  onLogout: () => void;
  onDismiss: () => void;
}

export const LogoutDialog = ({
  isVisible,
  onLogout,
  onDismiss,
  ...props
}: Props) => {
  const RenderButtons = () => (
    <>
      <Button onClick={onLogout} color="secondary" autoFocus>
        Logout
      </Button>
      <Button onClick={onDismiss} color="primary" autoFocus>
        Cancel
      </Button>
    </>
  );

  return (
    <AlertDialog
      title="Logout"
      customButtons={<RenderButtons />}
      subTitle={localize.CONF_LOGOUT}
      isVisible={isVisible}
      {...props}
    />
  );
};
