import '../../style/styles.css';
import React, { FC, useEffect } from 'react';
import { Box, Paper } from '@material-ui/core';
import { RichTextBoxMenu } from './RichTextBoxMenu';
import { RichTextBoxBubbleMenu } from './RichTextBoxBubbleMenu';

import { EditorContent, useEditor } from '@tiptap/react';
import Gapcursor from '@tiptap/extension-gapcursor';
import Document from '@tiptap/extension-document';
import StarterKit from '@tiptap/starter-kit';
import TextAlign from '@tiptap/extension-text-align';
import Underline from '@tiptap/extension-underline';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import FontFamily from '@tiptap/extension-font-family';
import TextStyle from '@tiptap/extension-text-style';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import Youtube from '@tiptap/extension-youtube';

// define your extension array
//please refer to documentation if you want to add new feature for
//rich text box https://tiptap.dev/docs/editor/extensions
const extensions = [
  StarterKit,
  Document,
  Gapcursor,
  FontFamily.configure({
    types: ['textStyle']
  }),
  TextStyle,
  BulletList.configure({
    keepMarks: true,
    keepAttributes: true,
    HTMLAttributes: {
      style: 'padding-left:20px; list-style-type: circle; color: black; '
    } //for indentation
  }),
  OrderedList.configure({
    keepMarks: true,
    keepAttributes: true,
    HTMLAttributes: { style: 'padding-left:20px' } //for indentation
  }),
  //you can make image responsive here
  Image.configure({
    inline: true,
    allowBase64: true,
    HTMLAttributes: {
      style: ' max-width:100%; height: auto;'
    }
  }),
  Link.configure({
    openOnClick: false,
    autolink: true
  }),
  TextAlign.configure({
    types: ['heading', 'paragraph']
  }),
  Underline,
  Table.configure({
    resizable: true,
    cellMinWidth: 25,
    allowTableNodeSelection: true,
    lastColumnResizable: false,
    HTMLAttributes: {
      style: 'border-collapse: collapse; width:100%; max-width:100%;'
    }
  }),
  TableRow.configure({
    HTMLAttributes: {
      style: 'min-height:20px; max-width: 700px;'
    }
  }),
  TableHeader,
  TableCell.configure({
    HTMLAttributes: {
      style: 'border:2px solid black; padding:5px;' //style for output
    }
  }),
  Youtube.configure({
    controls: true,
    nocookie: true,
    allowFullscreen: true,
    HTMLAttributes: {
      style: 'width:100%' //style for output
    }
  })
];

const content = `
<h1>
   Add Content Here
</h1>
<br>
<p>Type anything you want here</p>
<br>
`;

interface Props {
  description?: string;
  productId?: number;
  variant?: 'blog' | 'product';
  reloadProductDetails: () => void;
}

export const Tiptap: FC<Props> = ({
  description,
  productId,
  variant,
  reloadProductDetails
}) => {
  const editorV2 = useEditor({
    extensions: extensions,
    content: description || content,
    editorProps: {
      attributes: {
        style: 'padding: 25px; border:2px solid black; font-family: Roboto '
      }
    }
  });

  useEffect(() => {
    if (description) {
      editorV2?.commands.setContent(description);
    }
  }, [description, editorV2]);

  return (
    <Paper
      elevation={3}
      style={{
        padding: '1rem',
        marginTop: '1rem',
        marginBottom: '1rem'
      }}
    >
      <RichTextBoxMenu
        editor={editorV2}
        productId={productId}
        reloadProductDetails={reloadProductDetails}
        saveVariant={variant}
      />

      <Box sx={{ maxHeight: '60vh', overflow: 'scroll' }}>
        <EditorContent editor={editorV2} />
        <RichTextBoxBubbleMenu editor={editorV2} />
      </Box>
    </Paper>
  );
};
