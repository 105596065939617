import useSound from 'use-sound';

const successSound = '/static/sounds/success.mp3';
const errorSound = '/static/sounds/error.mp3';
const ecommPaid = '/static/sounds/ecommPaid.mp3';
const ecommCod = '/static/sounds/ecommCod.mp3';

export const useNotifSound = () => {
  const [playSoundSuccess] = useSound(successSound);
  const [playSoundError] = useSound(errorSound);
  const [playEcommPaid] = useSound(ecommPaid);
  const [playEcommCOD] = useSound(ecommCod);

  const success = () => {
    playSoundSuccess();
  };

  const sucessEcommPaid = () => {
    playEcommPaid();
  }

  const sucessEcommCOD = () => {
    playEcommCOD();
  }

  const error = () => {
    playSoundError();
  };

  return {
    success,
    error, 
    sucessEcommPaid,
    sucessEcommCOD,
  };
};
