import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectAuditState = (state: RootState) => state.audit;

const selectAuditList = createSelector(
  selectAuditState,
  (state) => state.audits
);

export const selectors = {
  selectAuditList
}