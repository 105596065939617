import { EcomOrderStatusEnum } from 'src/enums';

export const ecomOrderStatus: string[] = [
  EcomOrderStatusEnum.Cancelled,
  EcomOrderStatusEnum.Completed,
  EcomOrderStatusEnum.Expired,
  EcomOrderStatusEnum.Paid,
  EcomOrderStatusEnum.Pending,
  EcomOrderStatusEnum.Processing,
  EcomOrderStatusEnum.Refunded,
  EcomOrderStatusEnum.COD,

  // Old status
  'approved',
  'served'
];
