import React, { useCallback, useMemo } from 'react';
import {
  Chip,
  makeStyles,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { IconButtonComponent } from 'src/components';
import FilterListIcon from '@material-ui/icons/FilterList';
import { InventoryColumn, InventoryColumnForArrTable } from 'src/types';
import { cloneDeep } from 'lodash';
import { inventoryColumnsLocal } from 'src/constants/inventory';
import { usePermissions } from 'src/hooks';

const {
  selectors: inventorySelectors,
  actions: inventoryActions
} = slices.inventory;

interface Props {
  onColumnFilterClick: (column: InventoryColumn) => void;
}

const useStyles = makeStyles(() => ({
  mainRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexWrap: 'wrap',
    cursor: 'pointer'
  }
}));

const TableHeader = ({ onColumnFilterClick }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { canSeeDealersPrice } = usePermissions();

  const inventoryColumnsRedux = useAppSelector(
    inventorySelectors.selectInventoryColumns
  );
  const inventoryLocalFilter = useAppSelector(
    inventorySelectors.selectInventoryLocalFilter
  );

  const onSortByClicked = useCallback(
    (column: InventoryColumn) => {
      const currentOrder = inventoryLocalFilter[column]?.sortBy;
      const newSort: 'ASC' | 'DESC' = currentOrder === 'ASC' ? 'DESC' : 'ASC';

      const newFilter = {
        ...inventoryLocalFilter,
        [column]: {
          ...inventoryLocalFilter[column],
          columnName: column,
          sortBy: newSort
        }
      };

      dispatch(inventoryActions.onUpdateLocalFilter({ newFilter, column }));
    },
    [dispatch, inventoryLocalFilter]
  );

  const memoizedColumns = useMemo(() => {
    const columnsToBeFixed = cloneDeep(inventoryColumnsLocal);
    const columnsWithValue = columnsToBeFixed?.map((x) => ({
      ...x,
      ...inventoryLocalFilter,
      visible:
        x.value === 'dealers_price' && inventoryColumnsRedux[x.value]
          ? canSeeDealersPrice
          : inventoryColumnsRedux[x.value]
    }));
    return columnsWithValue;
  }, [canSeeDealersPrice, inventoryColumnsRedux, inventoryLocalFilter]);

  const isFilterChipVisible = useCallback(
    (item: InventoryColumnForArrTable) => {
      // God I fuckin hate this version of TS. So Dumb
      if (inventoryLocalFilter) {
        if (inventoryLocalFilter[item.value]?.text) {
          // @ts-ignore: Object is possibly 'null'. Intended.
          if (inventoryLocalFilter[item.value]?.text?.length > 0) {
            return true;
          }
        }
      }
      return false;
    },
    [inventoryLocalFilter]
  );

  const sortByChipLabel = useCallback(
    (item: InventoryColumnForArrTable) => {
      const order = inventoryLocalFilter[item.value]?.sortBy || '';
      if (order === 'ASC') {
        return 'Ascending (0 to Z)';
      }
      if (order === 'DESC') {
        return 'Descending (Z to 0)';
      }
      return '';
    },
    [inventoryLocalFilter]
  );

  return (
    <TableHead>
      <TableRow>
        {memoizedColumns?.map((column, i) => {
          if (column?.visible) {
            return (
              <TableCell
                key={`${i}-${column?.value}`}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div className={classes.mainRow}>
                  {!column?.hideFilter ? (
                    <IconButtonComponent
                      customIcon={<FilterListIcon fontSize={'small'} />}
                      onClick={() => onColumnFilterClick(column.value)}
                    />
                  ) : null}
                  <Typography
                    variant="h6"
                    onClick={() => onSortByClicked(column.value)}
                  >
                    {column.name || ''}
                  </Typography>
                </div>
                {isFilterChipVisible(column) ? (
                  <div className={classes.mainRow}>
                    <Chip
                      onClick={() => onColumnFilterClick(column.value)}
                      label={`${inventoryLocalFilter[column.value]?.text
                        ?.length || 0} filter(s) selected`}
                      style={{ height: '100%' }}
                      size="small"
                      variant="outlined"
                      color="primary"
                    />
                  </div>
                ) : null}
                {inventoryLocalFilter[column.value]?.sortBy ? (
                  <div className={classes.mainRow}>
                    <Chip
                      onClick={() => onSortByClicked(column.value)}
                      label={sortByChipLabel(column)}
                      style={{ height: '100%' }}
                      size="small"
                      variant="outlined"
                      color="secondary"
                    />
                  </div>
                ) : null}
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
};

export const InventoryTableHeader = React.memo(TableHeader);
