import React from 'react';
import { StyleSheet, View } from '@react-pdf/renderer';
import { TextBoldWarranty, TextRegularWarranty } from './TextWarranty';
import { ViewWarrantyRow } from './ViewWarranty';

interface Props {
  releasedBy?: string;
}

const styles = StyleSheet.create({
  root: {
    height: 28,
    marginTop: 4,
    borderWidth: 1
  },
  checkedByContainer: {
    flex: 1,
    padding: 4
  },
  releasedByContainer: {
    flex: 1,
    padding: 4,
    borderLeftWidth: 1
  }
});

export const RenderEmployeeInfo = ({ releasedBy = '' }: Props) => {
  return (
    <ViewWarrantyRow style={styles.root}>
      <View style={styles.checkedByContainer}>
        <TextBoldWarranty>Checked By: </TextBoldWarranty>
      </View>

      <ViewWarrantyRow style={styles.releasedByContainer}>
        <TextBoldWarranty>Released By: </TextBoldWarranty>
        <TextRegularWarranty>{releasedBy}</TextRegularWarranty>
      </ViewWarrantyRow>
    </ViewWarrantyRow>
  );
};
