import React, { useState } from 'react';
import {
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography,
  Button,
  IconButton,
  Divider
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

import { CustomInputEvent } from 'src/types';
import { LinkComponent } from 'src/components';
import { cloneDeep } from 'lodash';
import {
  addArrayInArrayUnique,
  addItemInArrayUnique,
  isValidHttpUrl
} from 'src/utils';
import { useSnackBar } from 'src/hooks';

interface Props {
  data?: string[];
  onReferenceChange: (newReferences: string[]) => void;
  hasDivider?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cpuField: {
    borderWidth: 5
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  addBtn: {
    marginTop: theme.spacing(2)
  },
  referencesListDiv: {
    display: 'flex',
    flexDirection: 'row'
  },
  linkTxt: {
    fontSize: 14
  },
  deleteIcon: {
    padding: 0,
    marginRight: theme.spacing(1)
  }
}));

const component = ({ data = [], onReferenceChange, hasDivider = true }: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const [input, setInput] = useState<string>('');
  const [multiLineInput, setMultiLineInput] = useState<string>('');

  const onChangeInputInternal = (e: CustomInputEvent) => {
    const { value } = e.target;
    setInput(value);
  };

  const onChangeInputMultiLineInternal = (e: CustomInputEvent) => {
    const { value } = e.target;
    setMultiLineInput(value);
  };

  const onAddReferenceSingleInternal = () => {
    if (isValidHttpUrl(input)) {
      const clonedData = cloneDeep(data);
      const newReferencesArray = addItemInArrayUnique(
        clonedData,
        input?.replace(/\s+/g, '')
      );
      onReferenceChange(newReferencesArray);
    } else {
      snackBar.show({
        severity: 'error',
        message: `${input} may not be a valid URL.`,
        useSound: true
      });
    }
  };

  const onAddReferenceMultipleInternal = () => {
    const multiLineToArrHasNotUrl = multiLineInput
      ?.split(/\r?\n/)
      ?.filter((x) => x && !isValidHttpUrl(x));
    if (multiLineToArrHasNotUrl?.length > 0) {
      snackBar.show({
        severity: 'error',
        message: `some links may not be a valid URL.`,
        useSound: true
      });
    }
    const multiLineToArr = multiLineInput
      ?.split(/\r?\n/)
      ?.filter((x) => x && isValidHttpUrl(x));
    const clonedData = cloneDeep(data);
    const newReferencesArray = addArrayInArrayUnique(
      clonedData,
      multiLineToArr
    );
    onReferenceChange(newReferencesArray);
  };

  const onAddReferenceInternal = () => {
    if (input) {
      onAddReferenceSingleInternal();
    }
    // If single reference is has no value and the the multi line has value
    if (!input && multiLineInput) {
      onAddReferenceMultipleInternal();
    }
    setInput('');
    setMultiLineInput('');
  };

  const onRemoveReference = (index: number) => {
    if (onReferenceChange) {
      const clonedData = cloneDeep(data);
      clonedData.splice(index, 1);
      onReferenceChange(clonedData);
      setInput('');
    }
  };

  const onEnterPress = () => {
    onAddReferenceInternal();
  };

  // TODO: See https://docs.google.com/document/d/1mQZFUQEx3LxcYuaNyJgiV4aZMeUxY_YtdJ7YA4_fZUE/edit?usp=sharing
  return (
    <div>
      <CardContent>
        <Typography className={classes.subHeader} variant="h6">
          Add References / Links
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="References and links (Single Line version)"
              name="references"
              required
              helperText="References must contain http or https"
              value={input}
              variant="outlined"
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onEnterPress();
                }
              }}
              onChange={(e) => onChangeInputInternal(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              multiline
              rows={10}
              onChange={(e) => onChangeInputMultiLineInternal(e)}
              value={multiLineInput}
              variant="outlined"
              helperText="References must contain http or https"
              label="References and links (Multi Line version)"
            />
            <Button
              disabled={!input && !multiLineInput}
              className={classes.addBtn}
              onClick={onAddReferenceInternal}
              color="primary"
              variant="contained"
            >
              Add Reference(s)
            </Button>
          </Grid>
          <Grid item xs={12}>
            {data?.map((x, i) => (
              <div className={classes.referencesListDiv} key={`${x}${i}`}>
                <IconButton
                  className={classes.deleteIcon}
                  onClick={() => onRemoveReference(i)}
                >
                  <DeleteIcon
                    fontSize="small"
                    color="secondary"
                    onClick={() => onRemoveReference(i)}
                  />
                </IconButton>
                <Typography className={classes.linkTxt}>
                  <LinkComponent
                    href={x}
                    title={` • ${x}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  />
                </Typography>
              </div>
            ))}
          </Grid>
        </Grid>
      </CardContent>
      {hasDivider ? <Divider /> : null}
    </div >
  );
};

export const AddProductReferences = React.memo(component);
