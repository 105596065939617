import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { slices, useAppDispatch } from 'src/redux';
import {
  FastMovingProductsData,
  FastMovingProductsPayload
} from 'src/redux/slices/fast-moving-products';

const { getFastMovingProductsThunk } = slices.fastMovingProducts;

export const useFastMovingProducts = () => {
  const dispatch = useAppDispatch();
  const [fastMovingProductData, setFastMovingProductData] = useState<
    FastMovingProductsData[]
  >();
  const [fastMovingProdParams, setFastMovingProdParams] = useState<
    FastMovingProductsPayload
  >({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getFastMovingProducts = useCallback(
    async (params: FastMovingProductsPayload) => {
      try {
        setIsLoading(true);
        const response = unwrapResult(
          await dispatch(getFastMovingProductsThunk(params))
        );
        if (response?.success) {
          const { data } = response?.originalData;
          setIsLoading(false);
          setFastMovingProductData(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  return {
    isLoading,
    fastMovingProductData,
    fastMovingProdParams,

    getFastMovingProducts,
    setFastMovingProdParams
  };
};
