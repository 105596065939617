import { TableCell } from '@material-ui/core';
import React from 'react';
import { AlternatingColorTableRow } from 'src/components/table';
import { Customer } from 'src/types';

interface Props {
  customer: Customer;
  onCustomerPress: (customer: Customer) => void;
}

export const CustomerListItem = ({ customer, onCustomerPress }: Props) => {
  return (
    <AlternatingColorTableRow
      onClick={() => onCustomerPress(customer)}
      hover
      key={customer.id}
    >
      <TableCell>
        {customer.first_name} {customer.last_name}
      </TableCell>
      <TableCell>{customer.contact_no}</TableCell>
    </AlternatingColorTableRow>
  );
};
