import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  FlashDealsGetRequest,
  GetFlashDealsResponse
} from 'src/types';

export const getFlashDealsListThunk = createAsyncThunk<
  CommonAxiosResponse<GetFlashDealsResponse>,
  FlashDealsGetRequest,
  { state: RootState }
>('flashDeals/getFlashDealsListThunk', async (data, api) => {
  const keyword =
    api?.getState()?.flashDeals?.getFlashDealsListRequest?.keyword || '';

  data = {
    page: 1,
    limit: 50,
    ...data
  };

  const response = await Api.get('/ecomm/internal/flash_deal/get', { ...data, keyword });
  return response;
});
