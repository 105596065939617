import React, { FC, memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import { processByField, transferLogPdfFooter } from '../../constant';
import { transferLogsText } from './styles';

const styles = StyleSheet.create({
  parent: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  rowHeaderFooter: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    flexWrap: 'wrap',
    borderLeftWidth: 1,
    borderTopWidth: 1
  },
  rowFooterItem: {
    borderRightWidth: 1,
    borderBottomWidth: 1,
    paddingVertical: 5,
    justifyContent: 'center',
    alignItems: 'center'
  },
  rowItemEmpty: {
    borderRightWidth: 1,
    borderBottomWidth: 1,
    paddingVertical: 15,
    alignItems: 'center'
  },
  renderPageNum: {
    flexDirection: 'row',
    width: '100%',
    borderLeftWidth: 1,
    borderRightWidth: 1,
    borderBottomWidth: 1,
    padding: 5,
    marginBottom: 15
  }
});

interface Props {
  processedBy?: string;
  currentPage?: number;
  lastPage?: number;
}

const component: FC<Props> = ({ processedBy, currentPage, lastPage }) => {
  const RenderPageNumber = () => {
    return (
      <View style={styles.renderPageNum} break>
        <Text
          style={transferLogsText.textBold}
        >{`Page ${currentPage} of ${lastPage} `}</Text>
      </View>
    );
  };

  return (
    <View style={styles.parent}>
      <RenderPageNumber />
      {currentPage === lastPage && (
        <>
          <View style={styles.rowHeaderFooter}>
            {transferLogPdfFooter?.map((footer, index) => (
              <View
                key={index}
                style={[styles.rowFooterItem, { flex: footer.flex }]}
              >
                <Text style={transferLogsText.textBold}>{footer?.name}</Text>
              </View>
            ))}
          </View>
          {/* This is for empty space */}
          <View style={styles.rowHeaderFooter}>
            {transferLogPdfFooter.map((footerEmpty, index) => (
              <View
                key={index}
                style={[styles.rowItemEmpty, { flex: footerEmpty.flex }]}
              >
                <Text style={transferLogsText.text}>
                  {footerEmpty.name === processByField ? processedBy : ''}
                </Text>
              </View>
            ))}
          </View>
        </>
      )}
    </View>
  );
};

export const ViewFooterLog = memo(component);
