import React, { useMemo, useState } from 'react';
import {
  Box,
  CardContent,
  Divider,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import { DragAndDropImgUpload, LoaderBar } from 'src/components';
import { convertImageFileToBase64 } from 'src/utils';
import { cloneDeep } from 'lodash';
import { ProductImage } from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { useSnackBar } from 'src/hooks';
import { ProductDraggableImageList } from './ProductDraggableImg';
import { Alert } from '@material-ui/lab';

const { actions: productActions } = slices.product;

interface Props {
  productId?: number;
  data?: ProductImage[];

  isLoading?: boolean;
  isImageUploading?: boolean;
  imgUploadProgress?: number;
  hasDivider?: boolean;
  hasNewImageAdded?: boolean;

  onPhotosChange: (imgs: ProductImage[]) => void;
  onChangeImgOrder: (imgList: ProductImage[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cpuField: {
    borderWidth: 5
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  previewDiv: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  previewImg: {
    height: 150,
    width: undefined
  }
}));

const component = ({
  data = [],
  onPhotosChange,
  isLoading = false,
  isImageUploading,
  imgUploadProgress,
  productId,
  hasDivider = true,
  hasNewImageAdded,
  onChangeImgOrder
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [isInternalLoading, setIsInternalLoading] = useState<boolean>(false);

  const onPhotosChangeInternal = async (img: File[]) => {
    if (img && img?.length > 0) {
      var imgsArr: any[] = await Promise.all(
        img?.map(
          async (item): Promise<any> => {
            const newImg = {
              image_filepath: '',
              url: await convertImageFileToBase64(item)
            };
            return newImg;
          }
        )
      );
      const clonedDataImgs = cloneDeep(data) || [];
      const mergedImgs = [...clonedDataImgs, ...imgsArr];
      onPhotosChange(mergedImgs);
    }
  };

  const onDeleteImage = async (index: number) => {
    if (isLoading || isImageUploading || imgUploadProgress) {
      snackBar.show({
        severity: 'error',
        message: 'Cannot delete while uploading / loading',
        useSound: true
      });
      return;
    }

    const clonedData = cloneDeep(data);
    if (clonedData[index]?.image_filepath) {
      setIsInternalLoading(true);
      dispatch(
        productActions.deleteProductImageThunk({
          product_id: productId,
          image_filepath: clonedData[index].image_filepath
        })
      ).finally(() => setIsInternalLoading(false));
    }

    clonedData?.splice(index, 1);
    onPhotosChange(clonedData);
  };

  const isOpacityLower = useMemo(
    () =>
      isLoading || isInternalLoading || imgUploadProgress || isImageUploading,
    [imgUploadProgress, isInternalLoading, isImageUploading, isLoading]
  );

  return (
    <div>
      {isImageUploading ? (
        <LinearProgress variant="determinate" value={imgUploadProgress} />
      ) : (
        false
      )}
      <LoaderBar isLoading={isLoading || isInternalLoading} />
      <CardContent
        style={{
          opacity: isOpacityLower ? 0.3 : 1
        }}
      >
        <Typography className={classes.subHeader} variant="h6">
          Product Images
        </Typography>
        {hasNewImageAdded && (
          <Box mb={1}>
            <Alert severity="info">{`New image has been added, save first before you can rearrange images`}</Alert>
          </Box>
        )}

        <ProductDraggableImageList
          imgUrl={data}
          onDeleteImg={(id: number) => onDeleteImage(id)}
          onHandleGetImagesInOrder={(imgOrderList: ProductImage[]) => {
            onPhotosChange(imgOrderList);
          }}
          onHandleChangeImageOrder={onChangeImgOrder}
          isDragDisable={hasNewImageAdded}
        />
        <DragAndDropImgUpload
          title="Drag or select photos"
          onImageSelected={onPhotosChangeInternal}
        />
      </CardContent>
      {hasDivider ? <Divider /> : null}
    </div>
  );
};

export const ProductImagesInfo = React.memo(component);
