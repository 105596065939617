import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, GetTransactionDetailResponse } from 'src/types';

export const getTransactionDetailThunk = createAsyncThunk<
  CommonAxiosResponse<GetTransactionDetailResponse>,
  string | undefined,
  { state: RootState }
>('transaction/getTransactionDetailThunk', async (transaction_no) => {
  const response = await Api.get(`/transaction/details/get/${transaction_no}`);
  // if (!response.success) {
  //   throw new Error('Failed getTransactionDetailThunk');
  // }
  return response;
});
