import React, {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { CustomKeyboardEvent, ProductPriceListData } from 'src/types';
import { between, formatCurrency, getColorGeneric } from 'src/utils';
import { TypographyPriceList } from './TypographyPriceList';
import { isEmpty } from 'lodash';
import { colors } from 'src/constants';
import { usePermissions } from 'src/hooks';

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8
  },
  filter: {
    marginTop: 20,
    marginBottom: 20
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: 20
  },
  computationContainer: {
    marginTop: theme.spacing(2)
  },

  priceInput: {
    height: 25
  },
  saveIcon: {
    padding: 0
  }
}));

const bodyStyle: CSSProperties = {
  top: '20%'
};

const modalStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

interface Props {
  visible: boolean;
  product?: ProductPriceListData;
  onHandleClose: (action: string, data?: ProductPriceListData) => void;
}

export const ProductPriceModal = ({
  visible,
  product,
  onHandleClose
}: Props) => {
  const classes = useStyles();
  const { canUpdateRetailPrice } = usePermissions();

  const [updatedProduct, setUpdatedProduct] = useState<ProductPriceListData>(
    product || {}
  );

  const onClose = useCallback(
    (action: string, marginStat?: 'good' | 'bad' | 'warn') => {
      const updatedProductCopy = { ...updatedProduct };
      onHandleClose(action, {
        ...updatedProductCopy,
        margin_percentage: margin.toFixed(3) + '%',
        gross_price: gross,
        standard_price: srp,
        margin_status: marginStat //update locally for now status
      });
      setUpdatedProduct({});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onHandleClose, updatedProduct]
  );

  const onKeyDown = useCallback(
    (e: CustomKeyboardEvent) => {
      if (e.key === 'Enter') {
        onClose('update');
      }
    },
    [onClose]
  );

  const onChangeValue = (value: string) => {
    setUpdatedProduct({ ...product, retail_price: parseInt(value) });
  };

  const gross = useMemo(() => {
    const dealersPrice = updatedProduct?.dealers_price || 0;
    const promoPrice = updatedProduct?.retail_price || 0;
    const difference = promoPrice - dealersPrice;

    return difference || 1;
  }, [updatedProduct]);

  const margin = useMemo(() => {
    const divisor = updatedProduct?.dealers_price || 1;
    const dividend = gross || 1;

    const quotient = dividend / divisor;
    const percentage = quotient * 100;
    return percentage;
  }, [updatedProduct]);

  const srp = useMemo(() => {
    const retailPrice = updatedProduct?.retail_price || 0;

    const newSrp = retailPrice + retailPrice * 0.12;

    return newSrp || 0;
  }, [updatedProduct]);

  const marginColorViaGross = useMemo(() => {
    let status: 'good' | 'warn' | 'bad' | undefined = undefined;

    if (between(margin, -999999, 6)) {
      status = 'bad';
    }
    if (between(margin, 6, 10)) {
      status = 'warn';
    }
    if (between(margin, 10, 999999)) {
      status = 'good';
    }

    return getColorGeneric(status);
  }, [margin]);

  const marginStatusViaGross = useMemo(() => {
    if (between(margin, -999999, 6)) {
      return 'bad';
    }
    if (between(margin, 6, 10)) {
      return 'warn';
    }
    if (between(margin, 10, 999999)) {
      return 'good';
    }
  }, [margin]);

  useEffect(() => {
    setUpdatedProduct(isEmpty(product) ? {} : { ...product });
  }, [product, visible]);

  return (
    <>
      <Modal
        disableBackdropClick
        open={visible}
        onClose={() => onClose('close')}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={modalStyle}
      >
        <div style={bodyStyle} className={classes.body}>
          <div className={classes.titleContainer}>
            <Typography variant="h4" color="textPrimary">
              Product Listing Computations
            </Typography>
          </div>
          <div className={classes.computationContainer}>
            <Typography
              style={{ marginBottom: 12 }}
              variant="h5"
              color="textPrimary"
            >
              {product?.product_name}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TypographyPriceList
                  color={colors.warning}
                  text={
                    'Dealer Price: ' +
                    formatCurrency(product?.dealers_price || 0, 'PHP ')
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <TypographyPriceList
                  color={colors.grey[600]}
                  extraTextStyle={{ textDecoration: 'line-through' }}
                  text={'SRP: ' + formatCurrency(srp || 0, 'PHP ')}
                />
              </Grid>

              <Grid item xs={6}>
                <TypographyPriceList
                  color={marginColorViaGross}
                  text={`Gross: ${formatCurrency(gross)}`}
                />
              </Grid>

              <Grid item xs={6}>
                <TypographyPriceList
                  color={marginColorViaGross}
                  text={`Margin: ${margin?.toFixed(3)} %`}
                />
              </Grid>
              <Grid item xs={12} style={{ marginTop: 12 }}>
                <TextField
                  className={classes.priceInput}
                  onFocus={(e) => {
                    e.stopPropagation();
                  }}
                  fullWidth
                  size="small"
                  variant="outlined"
                  onKeyDown={(e) => onKeyDown(e)}
                  type="number"
                  name="retail_price"
                  onChange={(e) => onChangeValue(e.target.value)}
                  value={updatedProduct?.retail_price ?? ''}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment
                        className={classes.saveIcon}
                        position="start"
                      >
                        ₱
                      </InputAdornment>
                    )
                  }}
                />
                <Typography variant="subtitle1" style={{ marginTop: 20 }}>
                  Current Promo Price:{' '}
                  {formatCurrency(product?.retail_price || 0, 'PHP ')}
                </Typography>
              </Grid>
            </Grid>
          </div>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            className={classes.footer}
          >
            <Button
              fullWidth
              onClick={() => onClose('close')}
              color="secondary"
              variant="outlined"
              style={{ marginRight: 10 }}
            >
              Close
            </Button>
            <Button
              fullWidth
              onClick={() => onClose('update', marginStatusViaGross)}
              color="primary"
              variant="outlined"
              disabled={!canUpdateRetailPrice}
            >
              Update
            </Button>
          </Box>
        </div>
      </Modal>
    </>
  );
};
