import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, CommonResponseMessage } from 'src/types';
import {
  AssignPermissionToRoleRequest,
  AssignRoleToUserRequest,
  CommonRoleResponse,
  CreateRoleAddPermissionRequest,
  CreateRoleAddToUserRequest,
  CreateRoleRequest,
  CreateRoleResponse,
  DeleteRoleResponse,
  GetRoleViaIDResponse,
  GetUsersViaRoleIDResponse,
  RevokePermissionToRoleRequest,
  RevokeRoleToUserRequest
} from 'src/types/role';

export const getRolesThunk = createAsyncThunk<
  CommonAxiosResponse<CommonRoleResponse>,
  undefined,
  { state: RootState }
>('role/getRoles', async (data) => {
  const response = await Api.get('/roles/getRoles', data);
  if (!response.success) {
    throw new Error('Failed at getRolesThunk.');
  }
  return response;
});

export const getRoleViaIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetRoleViaIDResponse>,
  number,
  { state: RootState }
>('role/getRole', async (id) => {
  const response = await Api.get(`/roles/getRole/${id}`);
  if (!response.success) {
    throw new Error('Failed at getRoleViaIdThunk.');
  }
  return response;
});

export const getUsersViaRoleIdThunk = createAsyncThunk<
  CommonAxiosResponse<GetUsersViaRoleIDResponse>,
  number,
  { state: RootState }
>('role/getUsersViaRoleIdThunk', async (id) => {
  const response = await Api.get(`/roles/getUsersViaRoleId/${id}`);
  if (!response.success) {
    throw new Error('Failed at getUsersViaRoleIdThunk.');
  }
  return response;
});

export const createRoleThunk = createAsyncThunk<
  CommonAxiosResponse<CreateRoleResponse>,
  CreateRoleRequest,
  { state: RootState }
>('role/createRole', async (data) => {
  const response = await Api.post(`/roles/createRole`, data);
  // if (!response.success) {
  //   throw new Error('Failed at createRoleThunk.');
  // }
  return response;
});

export const createRoleThenAddPermissionThunk = createAsyncThunk<
  CommonAxiosResponse<CreateRoleResponse>,
  CreateRoleAddPermissionRequest,
  { state: RootState }
>('permission/createRoleThenAddPermissionThunk', async (data) => {
  const response = await Api.post(`/roles/createRoleThenAddPermission`, data);
  // if (!response.success) {
  //   throw new Error('Failed at createRoleThenAddPermissionThunk.');
  // }
  return response;
});

export const createRoleThenAddUserThunk = createAsyncThunk<
  CommonAxiosResponse<CreateRoleResponse>,
  CreateRoleAddToUserRequest,
  { state: RootState }
>('permission/createRoleThenAddUserThunk', async (data) => {
  const response = await Api.post(`/roles/createRoleThenAddUser`, data);
  return response;
});

export const assignPermissionToRoleThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  AssignPermissionToRoleRequest,
  { state: RootState }
>('role/assignPermissionToRoleThunk', async (data) => {
  const response = await Api.post(`/roles/assignPermissionToRole`, data);
  if (!response.success) {
    throw new Error('Failed at assignPermissionToRoleThunk.');
  }
  return response;
});

export const revokePermissionToRoleThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  RevokePermissionToRoleRequest,
  { state: RootState }
>('role/removePermissionToRoleThunk', async (data) => {
  const response = await Api.post(`/roles/revokePermissionToRole`, data);
  if (!response.success) {
    throw new Error('Failed at revokePermissionToRoleThunk.');
  }
  return response;
});

export const assignRoleToUserThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  AssignRoleToUserRequest,
  { state: RootState }
>('role/assignRoleToUserThunk', async (data) => {
  const response = await Api.post(`/roles/assignRoleToUser`, data);
  return response;
});

export const revokeRoleToUserThunk = createAsyncThunk<
  CommonAxiosResponse<CommonResponseMessage>,
  RevokeRoleToUserRequest,
  { state: RootState }
>('role/revokeRoleToUserThunk', async (data) => {
  const response = await Api.post(`/roles/removeRoleToUser`, data);
  return response;
});

export const roleDeleteThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteRoleResponse>,
  number,
  { state: RootState }
>('roles/roleDeleteThunk', async (id) => {
  const response = await Api.delete(`/roles/deleteRole/${id}`);
  return response;
});
