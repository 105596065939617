// import { cloneDeep } from 'lodash';
// import { useCallback } from 'react';
import { slices, useAppSelector } from 'src/redux';
// import {
//   PartDetailErrField,
//   ProductPartDetails,
//   ProductPartDetailsCategory
// } from 'src/types';
// import {
//   getCategoryFieldsViaName,
//   transformToValidDataForCreateUtil,
//   transformToValidDataForReadUtil,
//   transformToValidDataForUpdateUtil
// } from 'src/utils';

const {
  // actions: productPartActions,
  selectors: gallerySelectors
} = slices.gallery;

// we might need screenId later
// eslint-disable-next-line no-unused-vars
export const useGalleryLogic = (screenId?: string) => {
  // const dispatch = useAppDispatch();
  // const [foo, setErrFields] = useState<PartDetailErrField[]>([]);

  const galleryDetails = useAppSelector(
    gallerySelectors.selectGalleryDetails
  );

  // const errFields = useAppSelector(
  //   productPartSelectors.selectProductPartDetailsErrFields
  // );

  // const setErrFields = useCallback(
  //   (newErrFields: PartDetailErrField[]) => {
  //     dispatch(productPartActions.setErrFields(newErrFields));
  //   },
  //   [dispatch]
  // );

  // const getProductPartDetailsViaKey = useCallback(
  //   (key: ProductPartDetailsCategory) => {
  //     if (key) {
  //       return productPartDetails[key] || {};
  //     }
  //   },
  //   [productPartDetails]
  // );

  // const setProductPartDetailsViaKey = useCallback(
  //   (key: ProductPartDetailsCategory, newData: ProductPartDetails) => {
  //     setErrFields([]);
  //     dispatch(
  //       productPartActions.updateProductPartDetailsViaKey({ key, newData })
  //     );
  //   },
  //   [dispatch, setErrFields]
  // );

  // /**
  //  * Returns a transformed data to be consumed by API for product detail creation.
  //  * @param key cpu | gpu | any category
  //  * @returns data for create product detail.
  //  */
  // const transformToValidDataForApi = (key: ProductPartDetailsCategory) => {
  //   const data = getProductPartDetailsViaKey(key) || {};
  //   const newData = transformToValidDataForCreateUtil(key, data);
  //   return newData;
  // };

  // /**
  //  * Returns a transformed data to be consumed by READ / UPDATE for product detail.
  //  * @param key cpu | gpu | any category
  //  * @returns data for READ product detail.
  //  */
  // const transformToValidDataForRead = (key: ProductPartDetailsCategory) => {
  //   const data = getProductPartDetailsViaKey(key);
  //   const newData = transformToValidDataForReadUtil(key, data);
  //   return newData;
  // };

  // /**
  //  * Returns a transformed data to be consumed by READ / UPDATE for product detail.
  //  * @param key cpu | gpu | any category
  //  * @returns data for UPDATE product detail.
  //  */
  // const transformToValidDataForUpdate = (key: ProductPartDetailsCategory) => {
  //   const data = transformToValidDataForApi(key);
  //   const newData = transformToValidDataForUpdateUtil(data);
  //   return newData;
  // };

  // const isProductDetailOfKeyValid = (key: ProductPartDetailsCategory) => {
  //   const data = getProductPartDetailsViaKey(key);
  //   const fields = getCategoryFieldsViaName(key);

  //   let errFields: PartDetailErrField[] = [];

  //   fields?.forEach((x) => {
  //     const valueInData = data[x?.part_property];
  //     // if img_url IS NULL then REQUIRE img_upload else continue.
  //     if (x?.part_property === 'image_upload' && !data['image_url'] && !data['image_upload']) {
  //       errFields.push({
  //         key,
  //         field: x?.part_property,
  //         error: 'field is required'
  //       });
  //       return;
  //     }
  //     if (x?.required && !valueInData) {
  //       errFields.push({
  //         key,
  //         field: x?.part_property,
  //         error: 'field is required'
  //       });
  //     }
  //   });

  //   if (errFields && errFields?.length > 0) {
  //     setErrFields(errFields);
  //     return false;
  //   }
  //   return true;
  // };

  // // changing a property inside of a component.
  // // key = cpu, mobo, etc.
  // // property = socket, no_of_clock
  // const setProductPartDetailsPropertyViaKey = useCallback(
  //   (
  //     key: ProductPartDetailsCategory,
  //     property: string,
  //     newData: string | null | undefined | number | boolean
  //   ) => {
  //     setErrFields([]);
  //     dispatch(
  //       productPartActions.updateProductPartDetailsPropertyViaKey({
  //         key,
  //         property,
  //         newData
  //       })
  //     );
  //   },
  //   [dispatch, setErrFields]
  // );

  // const fieldError = useCallback(
  //   (key: string, field: string) => {
  //     const clonedData = cloneDeep(errFields);
  //     const exist = clonedData?.find((x) => x.key === key && x.field == field);
  //     return exist;
  //   },
  //   [errFields]
  // );

  // const resetProductPartsStates = useCallback(() => {
  //   dispatch(productPartActions.resetErrFields());
  //   dispatch(productPartActions.resetProductPartDetails());
  // }, [dispatch]);

  return {
    galleryDetails,
    // fieldError,
    // setErrFields,
    // transformToValidDataForApi,
    // transformToValidDataForRead,
    // transformToValidDataForUpdate,
    // isProductDetailOfKeyValid,
    // getProductPartDetailsViaKey,
    // setProductPartDetailsViaKey,
    // setProductPartDetailsPropertyViaKey
  };
};
1;
