import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
    CommonAxiosResponse,
    CommonPackageResponse,
    PackageUpdatePayload,
} from 'src/types';

export const pcPackagePublishThunk = createAsyncThunk<
    CommonAxiosResponse<CommonPackageResponse>,
    PackageUpdatePayload,
    { state: RootState }
>('pc-package/pcPackagePublishThunk', async (data) => {
    const response = await Api.patch(`/ecomm/internal/pc_package/details/update/${data?.uuid}`, data);
    return response;
});
