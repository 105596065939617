import { unwrapResult } from '@reduxjs/toolkit';
import { useCallback, useState } from 'react';
import { slices, useAppDispatch } from 'src/redux';
import {
  TransferLogsSerialData,
  TransferStockLogsData,
  TransferStockLogsPayload,
  getTransferStockLogsSerialThunk
} from 'src/redux/slices/transfer-stock-logs';
import { PaginationV2 } from 'src/types';

const { getTransferStockLogsThunk } = slices.transferStockLogs;

export const useTransferStockLogs = () => {
  const dispatch = useAppDispatch();
  //This use to prevent api call to item that is already fetched
  const [fetchedStNo, setFetchedStNo] = useState<string[]>([]);
  const [transferLogsData, setTransferLogsData] = useState<
    TransferStockLogsData[]
  >();
  const [transferLogsSerialData, setTransferLogSerialData] = useState<
    TransferLogsSerialData[]
  >();
  const [isTransferLogsLoading, setIsTransferLogsLoading] = useState<boolean>(
    false
  );
  const [
    isTransferLogsSerialLoading,
    setIsTransferLogsSerialLoading
  ] = useState<boolean>(false);
  const [transferLogsParams, setTransferLogsParams] = useState<
    TransferStockLogsPayload
  >();
  //TODO: to be improve after adjustment on api
  // eslint-disable-next-line no-unused-vars
  const [metaTransferLogs, setMetaTransferLogs] = useState<PaginationV2>({});

  const getTransferStockLogs = useCallback(
    async (params: TransferStockLogsPayload) => {
      try {
        setIsTransferLogsLoading(true);
        const response = unwrapResult(
          await dispatch(getTransferStockLogsThunk(params))
        );
        if (response?.success) {
          const { data, meta } = response?.originalData;
          setIsTransferLogsLoading(false);
          setTransferLogsData(data);
          setMetaTransferLogs(meta);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsTransferLogsLoading(false);
      }
    },
    [dispatch]
  );

  const getTransferStockLogsThroughSt = useCallback(
    async (st_no?: string) => {
      try {
        setIsTransferLogsSerialLoading(true);
        const response = unwrapResult(
          await dispatch(getTransferStockLogsSerialThunk(st_no))
        );
        if (response?.success) {
          const { data } = response?.originalData;
          setIsTransferLogsSerialLoading(false);
          setTransferLogSerialData(data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsTransferLogsSerialLoading(false);
      }
    },
    [dispatch]
  );

  return {
    //transfer logs list
    transferLogsData,
    transferLogsParams,
    isTransferLogsLoading,
    metaTransferLogs,
    getTransferStockLogs,
    setTransferLogsParams,

    //transfer logs with serial
    transferLogsSerialData,
    isTransferLogsSerialLoading,
    getTransferStockLogsThroughSt,

    fetchedStNo,
    setFetchedStNo
  };
};
