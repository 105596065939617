import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles } from '@material-ui/core';
import { debounce } from 'lodash';
import { useNavigate } from 'react-router';

import { Branch } from 'src/types/branch';
import { LoaderBar, Page } from 'src/components';
import { useBranchInfo } from 'src/hooks/branch/use-branch-info';
import { Toolbar, BranchesTable } from './component';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const BranchList = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { branches, getBranches } = useBranchInfo();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleGetBranch = debounce(async (keyword?: string) => {
    setIsLoading(true);
    await getBranches(keyword).finally(() => {
      setIsLoading(false);
    });
  }, 500);

  const searchBranch = (keyword: string) => {
    handleGetBranch(keyword || '');
  };

  const onBranchPress = (branch: Branch) => {
    navigate(`/app/branches/${branch.id}`, { state: branch });
  };

  useEffect(() => {
    handleGetBranch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Branches">
      <Container maxWidth={false}>
        <Toolbar
          isLoading={isLoading}
          searchBranch={searchBranch}
          getBranches={handleGetBranch}
        />
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <BranchesTable branches={branches} onBranchPress={onBranchPress} />
        </Box>
      </Container>
    </Page>
  );
};

export default BranchList;
