import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  MenuItem,
  TextField,
  Typography
} from '@material-ui/core';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { RewardTypeEnum } from 'src/enums/kachi-points';
import { usePermissions, useSnackBar } from 'src/hooks';
import { useKachiPoints } from 'src/hooks/kachi-points';
import { unwrapResult } from '@reduxjs/toolkit';

type Props = {
  userId?: number;
  isOpen: boolean;
  userFullName?: string;
  onClose: () => void;
};

export const AddKachiPointsFormDialog: FC<Props> = ({
  userId,
  isOpen,
  userFullName,
  onClose
}) => {
  const { canAddKachiPoints } = usePermissions();
  const { addKachiPoints, getKachiPointsUserHistory } = useKachiPoints();
  const initialValues = {
    earned_points: 0,
    reward_type: '',
    details: ''
  };
  const snackbar = useSnackBar();

  const [enableFields, setEnableFields] = React.useState<boolean>(false);

  const validationSchema = Yup.object().shape({
    earned_points: Yup.number()
      .required('Earned Points is required')
      .when('reward_type', {
        is: 'deduction',
        then: Yup.number().max(
          0,
          'Earned Points must be a negative number for deduction'
        ),
        otherwise: Yup.number().min(
          1,
          'Earned Points must be a positive number'
        )
      }),
    reward_type: Yup.string().required('Reward Type is required'),
    details: Yup.string().required('Details is required')
  });

  const onHandleClose = () => {
    setEnableFields(false);
    onClose();
  };

  const onSubmit = async (values: any, user_id?: number) => {
    const addPayload = { ...values, user_id };
    const response = unwrapResult(await addKachiPoints(addPayload));

    if (response?.success) {
      snackbar.show({
        message: response?.message,
        severity: 'success'
      });
      getKachiPointsUserHistory(userId);
      onClose();
    }
  };

  return (
    <Dialog open={isOpen}>
      <DialogTitle>
        <Typography variant="h3">{'Add Kachi Points'}</Typography>
        <Typography
          style={{ marginTop: '5px' }}
        >{`Reward or deduct kachi points to ${userFullName}`}</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(e) => {
              onSubmit(e, userId);
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Reward Type"
                  id="reward_type"
                  margin="normal"
                  name="reward_type"
                  onBlur={handleBlur}
                  onChange={(e) => {
                    handleChange(e);
                    setEnableFields(!!e);
                  }}
                  value={values.reward_type}
                  error={!!touched.reward_type && !!errors.reward_type}
                  helperText={touched.reward_type && errors.reward_type}
                  variant="outlined"
                  select
                >
                  {/* map through enums hehehe */}
                  {Object.values(RewardTypeEnum).map((rewardType) => (
                    <MenuItem key={rewardType} value={rewardType}>
                      {rewardType}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  fullWidth
                  type="number"
                  label="Earn Points"
                  id="earned_points"
                  margin="normal"
                  name="earned_points"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.earned_points}
                  error={!!touched.earned_points && !!errors.earned_points}
                  helperText={touched.earned_points && errors.earned_points}
                  variant="outlined"
                  disabled={!enableFields}
                />
                <TextField
                  fullWidth
                  label="Details"
                  id="details"
                  margin="normal"
                  name="details"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.details}
                  error={!!touched.details && !!errors.details}
                  helperText={touched.details && errors.details}
                  variant="outlined"
                  multiline
                  inputProps={{ maxLength: 200 }}
                  disabled={!enableFields}
                />
                <DialogActions style={{ padding: '2rem' }}>
                  <Button
                    color="primary"
                    disabled={!canAddKachiPoints}
                    fullWidth
                    size="small"
                    type="submit"
                    variant="contained"
                  >
                    {'Add Kachi Points'}
                  </Button>
                  <Button
                    onClick={onHandleClose}
                    color="secondary"
                    fullWidth
                    size="small"
                    variant="contained"
                  >
                    Cancel
                  </Button>
                </DialogActions>
              </form>
            )}
          </Formik>
        </FormControl>
      </DialogContent>
    </Dialog>
  );
};
