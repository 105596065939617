import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { Page } from 'src/components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LoaderBar } from 'src/components';
import { debounce, rest } from 'lodash';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { Audit } from 'src/types';

const { actions: auditActions, selectors: auditSelectors } = slices.audit;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const AuditView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const audits = useAppSelector(auditSelectors.selectAuditList);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAudits = debounce(async () => {
    setIsLoading(true);
    dispatch(auditActions.getAuditsThunk()).finally(() => setIsLoading(false));
  }, 500);

  useEffect(() => {
    getAudits();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Audits">
      <Container maxWidth={false}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography color="textPrimary" gutterBottom variant="h3">
            Audits
          </Typography>
        </Box>
        <Box mt={2}>
          <LoaderBar isLoading={isLoading} />
          <Card className={classes.root} {...rest}>
            <PerfectScrollbar>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>User</TableCell>
                      <TableCell>Event</TableCell>
                      <TableCell>Model</TableCell>
                      <TableCell>Model_ID</TableCell>
                      <TableCell>Old Value</TableCell>
                      <TableCell>New Value</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {audits?.map((item: Audit) => (
                      <TableRow hover key={item.id}>
                        <TableCell>{item?.id}</TableCell>
                        <TableCell>{item?.user_full_name}</TableCell>
                        <TableCell>{item?.event}</TableCell>
                        <TableCell>{item?.auditable_type}</TableCell>
                        <TableCell>{item?.auditable_id}</TableCell>
                        <TableCell>{item?.old_values}</TableCell>
                        <TableCell>{item?.new_values}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default AuditView;
