import { createAsyncThunk } from '@reduxjs/toolkit';
import { CommonAxiosResponse } from 'src/types';
import {
  DeleteBuildsRequest,
  DeleteBuildsResponse,
  GetBuildDetailsRequest,
  GetBuildDetailsResponse,
  GetBuildsRequest,
  GetBuildsResponse,
  UpdateBuildDetailsRequest,
  UpdateBuildDetailsResponse
} from 'src/types/user-builds';
import Api from 'src/services/ApiService';

export const getUserBuildsThunk = createAsyncThunk<
  CommonAxiosResponse<GetBuildsResponse>,
  GetBuildsRequest
>('userBuilds/getUserBuildsThunk', async (data) => {
  const response = await Api.get('/ecomm/internal/user/builds/get', data);
  if (response?.success) {
    return response;
  } else {
    throw new Error('Failed at getUserBuildsThunk.');
  }
});

export const deleteUserBuildThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteBuildsResponse>,
  DeleteBuildsRequest
>('userBuilds/deleteUserBuildThunk', async (data) => {
  const response = await Api.delete(`/ecomm/internal/user/builds/delete/${data.id}`);
  if (response?.success) {
    return response;
  } else {
    throw new Error('Failed at deleteUserBuildThunk.');
  }
});

export const getUserBuildDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetBuildDetailsResponse>,
  GetBuildDetailsRequest
>('userBuilds/getUserBuildDetailsThunk', async (data) => {
  const response = await Api.get(`/ecomm/internal/user/builds/get/${data.id}`);
  if (response?.success) {
    return response;
  } else {
    throw new Error('Failed at getUserBuildDetailsThunk.');
  }
});

export const updateUserBuildThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateBuildDetailsResponse>,
  UpdateBuildDetailsRequest
>('userBuilds/updateUserBuildThunk', async (data) => {
  const response = await Api.patch(`/ecomm/internal/user/builds/update/${data.id}`, data);
  if (response?.success) {
    return response;
  } else {
    throw new Error('Failed at updateUserBuildThunk.');
  }
});

