import {
  pcWorthSFPercentOnTechOut,
  riderSFPercentOnTechOut,
  techOutSFPercentOnTechOut
} from 'src/constants/ecom/shipping';

// Get TechOut Shipping Fees Breakdown After Percentages
export const getTechOutShippingFeesBreakdown = (amount?: number) => {
  if (!amount) {
    const amountZero = {
      forPCWorth: 0,
      forRider: 0,
      forTechOut: 0,
      forTotal: 0
    };
    return amountZero;
  }

  // Initialize to zero
  let forPCWorth = 0;
  let forRider = 0;
  let forTechOut = 0;
  let forTotal = 0;

  // Convert percentage to decimal
  const pcWorthPercentDecimal = pcWorthSFPercentOnTechOut / 100;
  const riderPercentDecimal = riderSFPercentOnTechOut / 100;
  const techOutPercentDecimal = techOutSFPercentOnTechOut / 100;

  // Get Shipping Fees BUT might have some decimal points
  forPCWorth = pcWorthPercentDecimal * amount;
  forRider = riderPercentDecimal * amount;
  forTechOut = techOutPercentDecimal * amount;
  forTotal = amount || 0;

  // Get the decimal part of shipping fee if it has any
  const decimalOfForPCWorth = forPCWorth % 1;
  const decimalOfForTechOut = forTechOut % 1;

  // Subtract decimals on pcWorth and techOut shipping fee.
  forPCWorth = forPCWorth - decimalOfForPCWorth;
  forTechOut = forTechOut - decimalOfForTechOut;

  // And add decimals to rider shipping fee.
  forRider = forRider + decimalOfForPCWorth + decimalOfForTechOut;

  // convert rider SF to whole number
  forRider = Math.round(forRider);

  const finalShippingFees = {
    forPCWorth,
    forRider,
    forTechOut,
    forTotal
  };

  return finalShippingFees;
};
