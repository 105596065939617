// TODO:
import React, { useState } from 'react';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  datePickersContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dashSeparator: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}));
interface Props {
  onChange: (date: Date | null) => void;
  initialDate: string;
  hasPermission: boolean;
}

export const DatePickerComponent: React.FC<Props> = ({
  initialDate,
  onChange,
  hasPermission
}) => {
  const classes = useStyles();

  const [selectedDate, handleDateChange] = useState<Date | null>(
    new Date(initialDate)
  );

  onChange(selectedDate);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div className={classes.datePickersContainer}>
        <DatePicker
          label="Birthdate"
          value={selectedDate}
          onChange={handleDateChange}
          animateYearScrolling
          format="dd/MM/yyyy"
          views={['year', 'month', 'date']}
          autoOk
          inputVariant="outlined"
          variant="inline"
          maxDate={new Date()}
          disabled={hasPermission}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};
