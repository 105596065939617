import { createSlice } from '@reduxjs/toolkit';

import {
  Branch,
  UserAssignedOnBranchList,
  UserNotOnBranchData
} from 'src/types';
import * as thunks from './thunks';

type State = {
  branches: Branch[];
  userAssignedOnBranchList: UserAssignedOnBranchList[];
  userNotAssignedOnBranchList: UserNotOnBranchData[];
};

const initialState: State = {
  branches: [],
  userAssignedOnBranchList: [],
  userNotAssignedOnBranchList: []
};

const slice = createSlice({
  name: 'branches',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getBranchesThunk.fulfilled, (state, { payload }) => {
      state.branches = payload.originalData.data;
    });
    builder.addCase(
      thunks.getUsersAssignedOnBranchThunk.fulfilled,
      (state, { payload }) => {
        state.userAssignedOnBranchList = payload.originalData.data || [];
      }
    );
    builder.addCase(
      thunks.getUsersNotOnBranchThunk.fulfilled,
      (state, { payload }) => {
        state.userNotAssignedOnBranchList = payload?.originalData?.data || [];
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
