import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  Typography
} from '@material-ui/core';
import { slices, useAppSelector } from 'src/redux';
import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import { formatDate } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { colors } from 'src/constants';
import { FlashDeal } from 'src/types';

const { selectors: flashDealsSelectors } = slices.flashDeals;

interface Props {
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  imgPreview: {
    width: undefined,
    height: 50
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  }
}));

const Component = ({ className, ...rest }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const flashDealsList = useAppSelector(
    flashDealsSelectors.selectFlashDealsList
  );

  const onFlashDealPress = (item: FlashDeal) => {
    navigate(`/app/flash-deals/view/${item?.id}`, { state: item });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Deal Start</TableCell>
                <TableCell>Deal End</TableCell>
                <TableCell>Last Updated</TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {flashDealsList?.map((item: FlashDeal, i: number) => (
                <AlternatingColorTableRow hover key={i}>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onFlashDealPress(item)}
                      href={`/app/flash-deals/view/${item?.id}`}
                      title={`${item?.id}`}
                    />
                  </TableCell>
                  <TableCell>
                    <LinkComponent
                      onClick={() => onFlashDealPress(item)}
                      href={`/app/flash-deals/view/${item?.id}`}
                      title={item?.name || '--'}
                    />
                  </TableCell>
                  <TableCell>{formatDate(item?.deal_start, 'PPp')}</TableCell>
                  <TableCell>{formatDate(item?.deal_end, 'PPp')}</TableCell>
                  <TableCell>
                    <Typography>{formatDate(item?.updated_at)}</Typography>
                    <Typography>by: {item?.updated_by || '--'}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography>{formatDate(item?.created_at)}</Typography>
                    <Typography>by: {item?.created_by || '--'}</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      style={{
                        color: item?.published ? colors.success : colors.warning
                      }}
                    >
                      {`${item?.published ? 'published' : 'not published'}`}
                    </Typography>
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const FlashDealsListTable = React.memo(Component);
