import React from 'react';
import { Button, ListItem, makeStyles } from '@material-ui/core';
import { DrawerItem as DrawerItemType } from 'src/types/layout/drawer';
import clsx from 'clsx';
import { DrawerItemLabelEnum } from 'src/layouts/DashboardLayout/types';

interface Props {
  isActive?: boolean;
  data: DrawerItemType;
  className?: string;
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: 'red',
    // fontWeight: theme.typography.fontWeightMedium,
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

export const DrawerItem = ({ data, onClick, isActive, ...props }: Props) => {
  const classes = useStyles();

  return (
    <ListItem {...props} className={classes.item} disableGutters>
      <Button
        href={
          data?.title === DrawerItemLabelEnum.Logout ? undefined : data?.href
        }
        onClick={onClick}
        className={clsx(classes.button, isActive ? classes.active : undefined)}
        color="secondary"
      >
        {data.icon ? (
          <data.icon className={classes.icon} fontSize="small" />
        ) : null}
        <span className={classes.title}>{data.title}</span>
      </Button>
    </ListItem>
  );
};
