import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectTransactionPayment = (state: RootState) => state.transactionPayment;

const selectTransactionPaymentsResponse = createSelector(selectTransactionPayment, (state) => state.transactionPaymentsResponse);

export const selectors = {
  selectTransactionPaymentsResponse
};
