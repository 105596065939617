/* eslint-disable no-unused-vars */
import { cloneDeep } from 'lodash';
import { CategoriesEnum } from 'src/enums';
import { EcomOrderProduct, Quotation } from 'src/types';

// const dummyProducts = [
//   {
//     product_id: 19,
//     product_slug: 'kingston-fury-2x8gb-=-16gb-3200mhz-rgb-ddr4',
//     product_name: 'KINGSTON FURY 2X8GB = 16GB 3200MHZ RGB DDR4',
//     quantity: 1,
//     checkout_unit_price: 4330,
//     current_unit_price: 4330,
//     checkout_amount: 4330,
//     current_amount: 4330,
//     category_id: CategoriesEnum.RAM,
//     category_name: 'RAM',
//     amount_fluctuation: 'none'
//   },
//   {
//     quantity: 2,
//     product_id: 34,
//     product_name: 'AMD RYZEN 5 5600X',
//     product_slug: 'amd-ryzen-5-5600x',
//     category_id: CategoriesEnum.CPU,
//     category_name: 'CPU',
//     checkout_unit_price: 13495,
//     current_unit_price: 14000,
//     checkout_amount: 26990,
//     current_amount: 28000,
//     amount_fluctuation: 'increase'
//   },
//   {
//     product_id: 233,
//     product_slug:
//       'geforce-rtx-3070-msi-gaming-z-trio-oc-8gb-triple-fan-rgb-lhr',
//     product_name:
//       'GEFORCE RTX 3070 MSI GAMING Z TRIO OC 8GB TRIPLE FAN RGB LHR',
//     quantity: 1,
//     checkout_unit_price: 41950,
//     current_unit_price: 41950,
//     checkout_amount: 41950,
//     current_amount: 41950,
//     category_id: CategoriesEnum.GPU,
//     category_name: 'GPU',
//     amount_fluctuation: 'none'
//   },
//   {
//     product_id: 51,
//     product_slug: 'asrock-b550m-steel-legend',
//     product_name: 'ASROCK B550M STEEL LEGEND',
//     quantity: 1,
//     checkout_unit_price: 7600,
//     current_unit_price: 7600,
//     checkout_amount: 7600,
//     current_amount: 7600,
//     category_id: CategoriesEnum.Motherboard,
//     category_name: 'Motherboard',
//     amount_fluctuation: 'none'
//   }
// ];

export const ecomProductsToQuotationProducts: (
  products: EcomOrderProduct[]
) => // eslint-disable-next-line no-unused-vars
Quotation[] = (products: EcomOrderProduct[]) => {
  let clonedProducts = cloneDeep(products);
  clonedProducts = clonedProducts?.filter((x) => !x?.unavailable);
  // let mappedProductsQuotation: Quotation[] = dummyProducts?.map((x) => ({
  let mappedProductsQuotation: Quotation[] = clonedProducts?.map((x) => ({
    quantity: x?.quantity || 1,
    product: {
      product_id: x?.product_id,
      product_name: x?.product_name,
      category_id: x?.category_id,
      category_name: x?.category_name,
      retail_price: x?.current_unit_price || 0
    }
  }));

  // eslint-disable-next-line no-unused-vars
  const quotationComponentsOrder = [
    CategoriesEnum.GPU,
    CategoriesEnum.CPU,
    CategoriesEnum.Motherboard,
    CategoriesEnum.RAM,
    CategoriesEnum.SSD,
    CategoriesEnum.HDD,
    CategoriesEnum.PSU,
    CategoriesEnum.Casing,
    CategoriesEnum.Monitor
  ];

  const orderedResult = mappedProductsQuotation.sort((a, b) => {
    if (!a || !b) {
      return 1;
    }
    if (!a?.product?.category_id || !b?.product?.category_id) {
      return 1;
    }
    if (!a?.product?.category_id || !b?.product?.category_id) {
      return 1;
    }
    if (
      quotationComponentsOrder.indexOf(a?.product?.category_id) < 0 ||
      quotationComponentsOrder.indexOf(b?.product?.category_id) < 0
    ) {
      return 1;
    }
    return (
      quotationComponentsOrder.indexOf(a?.product?.category_id) -
      quotationComponentsOrder.indexOf(b?.product?.category_id)
    );
  });

  const twoEmptyComponent = [
    {
      quantity: 1,
      product: {}
    },
    {
      quantity: 1,
      product: {},
      custom: true
    }
  ];

  const orderedWithEmptyRows = orderedResult.concat(twoEmptyComponent);

  return orderedWithEmptyRows;
};
