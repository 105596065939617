import React from 'react';
import {
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core';
import { consumableUnitOptions, productTypeOptions } from 'src/constants';
import { ProductTypeEnum } from 'src/enums';

interface LogoProps {
  productType?: number;
  selectedConsumable?: string | null;

  onChangeProductType: (productType: number) => void;
  setConsumableUnit: (productType: string) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    flexDirection: 'row',
    display: 'flex'
  }
}));

export const ConsumableProductField = ({
  productType = ProductTypeEnum.Serialized,
  selectedConsumable,
  onChangeProductType,
  setConsumableUnit
}: LogoProps) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <FormControl style={{ width: 350 }}>
        <InputLabel variant="outlined">Product Type</InputLabel>
        <Select
          name="product_type"
          defaultValue={''}
          variant="outlined"
          label="Product Type"
          value={productType ?? ''}
          onChange={(e: any) => onChangeProductType(e.target.value)}
        >
          {productTypeOptions.map((item) => (
            <MenuItem key={item.id} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {productType === ProductTypeEnum.Consumable ? (
        <div>
          <FormControl style={{ width: 200 }}>
            <InputLabel variant="outlined" id="demo-simple-select-label">
              Consumable Unit
            </InputLabel>
            <Select
              name="consumable_unit"
              defaultValue={''}
              variant="outlined"
              label="Consumable Unit"
              value={selectedConsumable ?? ''}
              onChange={(e: any) => setConsumableUnit(e.target.value)}
            >
              {consumableUnitOptions.map((item) => (
                <MenuItem key={item.id} value={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      ) : null}
    </div>
  );
};
