import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  CommonGalleryResponse,
  GalleryPublishRequest
} from 'src/types';

export const galleryPublishThunk = createAsyncThunk<
  CommonAxiosResponse<CommonGalleryResponse>,
  GalleryPublishRequest,
  { state: RootState }
>('gallery/galleryPublishThunk', async (data) => {
  const response = await Api.patch(`/gallery/publish/update/${data.id}`, data);
  return response;
});
