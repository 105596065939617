import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectBranchesState = (state: RootState) => state.branches;

const selectBranchesList = createSelector(
  selectBranchesState,
  (state) => state.branches
);

export const selectors = {
  selectBranchesList
}