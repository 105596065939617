import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { DeleteDialog, LinkComponent, LoaderBar, Page } from 'src/components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import DeleteIcon from '@material-ui/icons/Delete';

import { Permission, Role } from 'src/types';
import FormAddDialog from 'src/components/dialogs/FormDialog';
import { usePermissions, useSnackBar } from 'src/hooks';
import { slices, useAppDispatch } from 'src/redux';
import { batch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate } from 'react-router-dom';
import NotFoundView from '../errors/NotFoundView';
import { SearchBox } from 'src/components/SearchBox';
import usePermsAndRolesSearch from 'src/hooks/use-perms-and-roles-search';

const { actions: roleActions } = slices.roles;
const { actions: permissionActions } = slices.permissions;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  searchText: {
    width: '200px',
    marginRight: '1em'
  },
  tableTitle: {
    marginTop: '2em'
  },
  toolbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  iconBtn: {
    padding: 0
  }
}));

const RolesAndPermissionsView = () => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const permissionsHook = usePermissions();
  const {
    roles,
    permissions,
    filteredPerms,
    filteredRoles,
    roleInputVal,
    permsInputVal,
    onChangePermsInput,
    onChangeRoleInput,
    onSearchPerms,
    onSearchRole
  } = usePermsAndRolesSearch();

  const [isCreateRoleVisible, setIsCreateRoleVisible] = useState<boolean>(
    false
  );
  const [isCreatePermissionVisible, setIsCreatePermissionVisible] = useState<
    boolean
  >(false);
  const [deleteRoleData, setDeleteRoleData] = useState<Role>();
  const [deletePermissionData, setDeletePermissionData] = useState<
    Permission
  >();
  const [addRoleLoading, setAddRoleLoading] = useState<boolean>(false);
  const [addPermissionLoading, setAddPermissionLoading] = useState<boolean>(
    false
  );
  const [isRolesLoading, setRolesLoading] = useState<boolean>(false);
  const [isPermissionsLoading, setPermissionsLoading] = useState<boolean>(
    false
  );

  const onOpenCreateRoleDialog = () => {
    setIsCreateRoleVisible(true);
  };

  const onCloseCreateRoleDialog = () => {
    setIsCreateRoleVisible(false);
    setAddRoleLoading(false);
  };

  const onAddRoleOnModalPress = async (name: string) => {
    // TODO: Add checker loader before close.
    setAddRoleLoading(true);

    const response = unwrapResult(
      await dispatch(roleActions.createRoleThunk({ name }))
    );
    if (response?.success) {
      snackBar.show({
        severity: 'success',
        message: response?.message || 'Role creation success.'
      });
    } else {
      snackBar.show({
        severity: 'error',
        message: response?.message || 'Role creation failed.'
      });
    }
    onCloseCreateRoleDialog();
  };

  const onOpenCreatePermissionDialog = () => {
    setIsCreatePermissionVisible(true);
  };

  const onCloseCreatePermissionDialog = () => {
    setIsCreatePermissionVisible(false);
    setAddPermissionLoading(false);
  };

  const onAddPermissionOnModalPress = async (name: string) => {
    // TODO: Add checker loader before close.
    setAddPermissionLoading(true);

    const response = unwrapResult(
      await dispatch(permissionActions.createPermissionThunk({ name }))
    );
    if (response?.success) {
      snackBar.show({
        severity: 'success',
        message: response?.message || 'Permission creation success.'
      });
    } else {
      snackBar.show({
        severity: 'error',
        message: response?.message || 'Permission creation failed.'
      });
    }

    onCloseCreatePermissionDialog();
  };

  const onDeleteRoleSure = async () => {
    if (!deleteRoleData) {
      setDeleteRoleData(undefined);
      return;
    }
    if (deleteRoleData?.id) {
      const response = unwrapResult(
        await dispatch(roleActions.roleDeleteThunk(deleteRoleData.id))
      );
      if (response?.success) {
        snackBar.show({
          severity: 'success',
          message: response?.message || 'Role deletion success.'
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || 'Role deletion failed.'
        });
      }
    }
    setDeleteRoleData(undefined);
  };

  const onDeleteRole = (role: Role) => {
    if (role?.name === 'owner' || role?.name === 'employee') {
      snackBar.show({
        severity: 'error',
        message: "Role 'owner' or 'employee' cannot be deleted"
      });
      return;
    }
    setDeleteRoleData(role);
  };

  const onDeletePermissionSure = async () => {
    if (!deletePermissionData) {
      setDeletePermissionData(undefined);
      return;
    }
    if (deletePermissionData?.id) {
      const response = unwrapResult(
        await dispatch(
          permissionActions.permissionDeleteThunk(deletePermissionData.id)
        )
      );
      if (response?.success) {
        snackBar.show({
          severity: 'success',
          message: response?.message || 'Permission deletion success.'
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || 'Permission deletion failed.'
        });
      }
    }
    setDeletePermissionData(undefined);
  };

  const onDeletePermission = (permission: Permission) => {
    if (permission?.name === 'edit_permissions_roles') {
      snackBar.show({
        severity: 'error',
        message: "Permission 'edit_permissions_roles' cannot be deleted"
      });
      return;
    }
    setDeletePermissionData(permission);
  };

  const getRolesAndPermissions = useCallback(() => {
    batch(() => {
      setRolesLoading(true);
      dispatch(roleActions.getRolesThunk()).finally(() =>
        setRolesLoading(false)
      );

      setPermissionsLoading(true);
      dispatch(permissionActions.getPermissionsThunk()).finally(() =>
        setPermissionsLoading(false)
      );
    });
  }, [dispatch]);

  useEffect(() => {
    getRolesAndPermissions();
  }, [getRolesAndPermissions]);

  return permissionsHook.canManagePermissions ? (
    <Page className={classes.root} title="Roles and Permission">
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="textPrimary" gutterBottom variant="h3">
            {`Roles & Permissions`}
          </Typography>
        </Box>
        <Typography
          className={classes.tableTitle}
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          Roles
        </Typography>
        <Box className={classes.toolbarContainer}>
          <SearchBox
            options={roles}
            inputValue={roleInputVal}
            onInputChange={onChangeRoleInput}
            onSearchKeyWord={onSearchRole}
            placeholder={'Search Role'}
          />
          <Button
            onClick={onOpenCreateRoleDialog}
            color="primary"
            variant="contained"
          >
            Create Role
          </Button>
        </Box>
        <Box mt={2}>
          <Card>
            <LoaderBar isLoading={isRolesLoading} />
            <PerfectScrollbar style={{ height: '30vh' }}>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Role Name</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRoles[0] &&
                      filteredRoles?.map((item: Role) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <LinkComponent
                              onClick={() => navigate(`/app/role/${item.id}`)}
                              href={`/app/role/${item.id}`}
                              title={`${item?.name || '--'}`}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              className={classes.iconBtn}
                              onClick={() => onDeleteRole(item)}
                            >
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>

        <Typography
          className={classes.tableTitle}
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          Permissions
        </Typography>
        <Box className={classes.toolbarContainer}>
          <SearchBox
            options={permissions}
            inputValue={permsInputVal}
            onInputChange={onChangePermsInput}
            onSearchKeyWord={onSearchPerms}
            placeholder={'Search Perms'}
          />
          <Button
            onClick={onOpenCreatePermissionDialog}
            color="primary"
            variant="contained"
          >
            Create Permission
          </Button>
        </Box>
        <Box mt={2}>
          <LoaderBar isLoading={isPermissionsLoading} />
          <Card>
            <PerfectScrollbar style={{ height: '30vh' }}>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Permission Name</TableCell>
                      <TableCell>Delete</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredPerms[0] &&
                      filteredPerms?.map((item: Permission) => (
                        <TableRow key={item.id}>
                          <TableCell>
                            <LinkComponent
                              onClick={() =>
                                navigate(`/app/permission/${item.id}`)
                              }
                              href={`/app/permission/${item.id}`}
                              title={`${item?.name || '--'}`}
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              className={classes.iconBtn}
                              onClick={() => onDeletePermission(item)}
                            >
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>

      <FormAddDialog
        loading={addRoleLoading}
        fieldName="Role Name"
        title="Create a role"
        isVisible={isCreateRoleVisible}
        subTitle="Input name for this new Role"
        onAddPress={onAddRoleOnModalPress}
        handleClose={onCloseCreateRoleDialog}
      />

      <FormAddDialog
        loading={addPermissionLoading}
        fieldName="Permission Name"
        title="Create a Permission"
        isVisible={isCreatePermissionVisible}
        subTitle="Input name for this new Permission"
        onAddPress={onAddPermissionOnModalPress}
        handleClose={onCloseCreatePermissionDialog}
      />

      <DeleteDialog
        title={`Delete ${deleteRoleData?.name}`}
        subTitle={`Are you sure you want to delete ${deleteRoleData?.name}`}
        isVisible={deleteRoleData ? true : false}
        handleClose={() => setDeleteRoleData(undefined)}
        onDelete={onDeleteRoleSure}
      />

      <DeleteDialog
        title={`Delete ${deletePermissionData?.name}`}
        subTitle={`Are you sure you want to delete ${deletePermissionData?.name}`}
        isVisible={deletePermissionData ? true : false}
        handleClose={() => setDeletePermissionData(undefined)}
        onDelete={onDeletePermissionSure}
      />
    </Page>
  ) : (
    <NotFoundView />
  );
};

export default RolesAndPermissionsView;
