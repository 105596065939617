import React, { CSSProperties, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Box,
  Button,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { TransactionPayment } from 'src/types';
import { usePermissions, useSnackBar } from 'src/hooks';

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: 20
  },
  subAmountContainer: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  formContainer: {
    marginTop: theme.spacing(3)
  },
  paymentField: {
    marginTop: theme.spacing(3)
  },
  footerBtn: {
    marginTop: theme.spacing(1)
  }
}));

const bodyStyle: CSSProperties = {
  top: '20%'
};

const modalStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

interface Props {
  data?: TransactionPayment;
  visible: boolean;
  onHandleClose: () => void;
  title?: string;
  fieldName?: string;
  onDeletePress?: (paymentData: TransactionPayment) => void;
  loading?: boolean;
}

export const RemarksModal = ({
  visible,
  onHandleClose,
  title,
  fieldName,
  onDeletePress,
  data
}: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const { canDeleteTransactionPayment } = usePermissions();

  const [remarks, setRemarks] = useState<string>();

  const resetState = () => {
    setRemarks(undefined);
  };

  const onDeleteInternal = () => {
    if (!remarks) {
      snackBar.show({ severity: 'error', message: 'Remarks required' });
      return;
    }
    if (data?.id && onDeletePress) {
      const paymentData: TransactionPayment = {
        remarks,
        id: data?.id
      };
      onDeletePress(paymentData);
    }
  };

  const onCloseInternal = () => {
    resetState();
    onHandleClose();
  };

  return (
    <Modal
      disableBackdropClick
      open={visible}
      onClose={onHandleClose}
      style={modalStyle}
    >
      <div style={bodyStyle} className={classes.body}>
        {title ? (
          <div className={classes.titleContainer}>
            <Typography variant="h4" color="textPrimary">
              {title}
            </Typography>
          </div>
        ) : null}

        <div className={classes.formContainer}>
          <TextField
            fullWidth
            multiline
            rows={4}
            label={fieldName}
            name={`${fieldName}`}
            onChange={(e) => setRemarks(e.target.value || '')}
            value={remarks || ''}
            variant="outlined"
          />
        </div>

        <Box className={classes.footer}>
          <Button
            fullWidth
            onClick={onDeleteInternal}
            color="secondary"
            variant="contained"
            className={classes.footerBtn}
            disabled={!canDeleteTransactionPayment}
          >
            Delete
          </Button>
          <Button
            className={classes.footerBtn}
            fullWidth
            onClick={onCloseInternal}
            color="primary"
            variant="outlined"
          >
            Close
          </Button>
        </Box>
      </div>
    </Modal>
  );
};
