import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import { SMSBlastRequest, SMSBlastResponse, SMSResponse } from './types';

export const sendSMSBlastThunk = createAsyncThunk<
  CommonAxiosResponse<SMSResponse>,
  SMSBlastRequest,
  { state: RootState }
>('sms/sendSMSBlastThunk', async (data) => {
  const response = await Api.post('/sms/blast/send', data);
  if (!response.success) {
    throw new Error('Failed sendSMSBlastThunk');
  }
  return response;
});

export const sendSMSBlastThunkV2 = createAsyncThunk<
  CommonAxiosResponse<SMSBlastResponse>,
  undefined,
  { state: RootState }
>('sms/sendSMSBlastThunk', async (_, { getState }) => {
  const smsReducerState = getState()?.sms;
  const payload: SMSBlastRequest = {
    custom_mobile_nos: smsReducerState?.customMobileNos,
    numbers_to_skip: smsReducerState?.excludedMobileNos,
    content: smsReducerState?.message || '',
    delivery_date: smsReducerState?.deliveryDate || undefined
  };

  const response = await Api.post('/sms/movider/blast/send', payload);
  if (!response.success) {
    throw new Error('Failed sendSMSBlastThunk');
  }
  return response;
});
