import { useCallback, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { ProductPriceListData, UpdateProductThunkRequest } from 'src/types';
import { queryParamsInterface } from 'src/views/price-list/constants';

const { actions: productActions, selectors: productSelectors } = slices.product;

interface CurrentMetaDetails {
  current_page?: number;
  last_page?: number;
}

//lets reuse this code
export const usePriceList = () => {
  const dispatch = useAppDispatch();
  const priceList = useAppSelector(productSelectors.selectPriceList);
  const priceListDate = useAppSelector(productSelectors.selectPriceListDate);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [priceListState, setPriceList] = useState<ProductPriceListData[]>(
    priceList
  );
  const [loadMore, onSetLoadMore] = useState<boolean>(false);
  const [currentMeta, onSetCurrentMeta] = useState<CurrentMetaDetails | null>(
    null
  );
  const [queryParams, setQueryParams] = useState<queryParamsInterface>({
    out_of_stock_only: 1,
    sort_by: 1,
    branch_ids: []
  });

  const getPriceList = useCallback(() => {
    setIsLoading(true);
    dispatch(
      productActions.getPriceListThunk({
        keyword: queryParams?.keyword,
        sort_by: queryParams?.sort_by,
        page: queryParams?.page ?? 1,
        category_id: queryParams?.category_id,
        branch_ids: queryParams?.branch_ids,
        limit: 50
      })
    )
      .then((val) => {
        const payload: any = val?.payload;
        if (payload?.originalData?.data) {
          const originalData = payload?.originalData;
          onSetCurrentMeta(originalData.meta);
          onSetLoadMore(
            originalData.meta.last_page != originalData.meta.current_page
          );
          if (originalData.meta.current_page > 1) {
            setPriceList((prevState) => [...prevState, ...originalData.data]);
          } else {
            setPriceList(originalData.data);
          }
        }
      })
      .catch(() => {
        console.log('error');
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParams]);

  const onLoadMoreItems = () => {
    let newPage = currentMeta?.current_page ? ++currentMeta.current_page : 1;
    let lastPage = currentMeta?.last_page ?? 1;
    if (newPage <= lastPage) {
      setQueryParams({ ...queryParams, page: newPage });
    }
  };

  const onUpdatePriceList = async (item: ProductPriceListData) => {
    const newProductData: UpdateProductThunkRequest = {
      category_id: item?.category_id,
      id: item?.id,
      retail_price: item?.retail_price,
      ram_kit: item?.ram_kit,
      pricelist_edit: true
    };
    setIsLoading(true);
    dispatch(productActions.updateProductsThunk(newProductData)).finally(() => {
      setIsLoading(false);
      //only modified the changed pricelist item
      const itemList = priceListState.map((data) =>
        data.id === item.id ? item : data
      );
      setPriceList(itemList);
    });
  };

  return {
    loadMore,
    priceListDate,
    isLoading,
    priceListState,
    queryParams,

    setQueryParams,
    onUpdatePriceList,
    onLoadMoreItems,
    getPriceList
  };
};
