import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SnackBarType, SnackBarShow, AlertGlobal } from 'src/types';

type State = {
  snackBar: SnackBarType;
  alertGlobal: AlertGlobal;
  openDrawerSectionIndices: number[];
};

const initialState: State = {
  // SnackBar:
  snackBar: {
    open: false,
    message: 'Hello',
    severity: undefined
  },

  // GlobalAlert:
  alertGlobal: {
    open: false
  },

  // drawer
  openDrawerSectionIndices: []
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    // SnackBar
    showSnackBar: (state, { payload }: PayloadAction<SnackBarShow>) => {
      state.snackBar = {
        ...initialState.snackBar,
        message: payload?.message,
        severity: payload?.severity,
        open: true
      };
    },
    hideSnackBar: (state) => {
      state.snackBar.open = false;
    },

    // Alert Global
    showAlertGlobal: (state, { payload }: PayloadAction<AlertGlobal>) => {
      state.alertGlobal = {
        title: payload?.title,
        subtitle: payload?.subtitle,
        buttons: payload.buttons,
        open: true
      };
    },
    hideAlertGlobal: (state) => {
      state.alertGlobal.open = false;
    },
    toggleDrawerSection: (state, { payload }: PayloadAction<number>) => {
      const indexExist = state.openDrawerSectionIndices?.findIndex(
        (x) => x === payload
      );
      let newDrawerSectionIndices = state.openDrawerSectionIndices;

      if (indexExist > -1) {
        // if it already exist. (its open) - we will close it (remove it in array)
        newDrawerSectionIndices.splice(indexExist, 1);
      } else {
        // if it already exist. (its open) - we will close it (add it in array)
        newDrawerSectionIndices.push(payload);
      }
      state.openDrawerSectionIndices = newDrawerSectionIndices;
    }
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions
};
