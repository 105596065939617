import {
  Box,
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  makeStyles
} from '@material-ui/core';
import React, { FC } from 'react';
import { useDrawerItemsWithPerms } from 'src/hooks/use-drawer-items-with-perms';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: theme.palette.text.secondary,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '0px',
    textTransform: 'none',
    width: '100%'
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  title: {
    color: theme.palette.text.secondary,
    fontFamily: 'Roboto',
    fontWeight: 'bold',
    justifyContent: 'flex-start',
    letterSpacing: 0,
    padding: '10px 8px',
    textTransform: 'none',
    width: '100%'
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.primary.main
    }
  },
  nested: {
    paddingLeft: theme.spacing(2)
  }
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

export const DashboardDrawer: FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const { drawerItemsWithPermissions } = useDrawerItemsWithPerms();

  return (
    <Drawer open={open} onClose={onClose}>
      <Box width={'60vw'}>
        <List>
          {drawerItemsWithPermissions[0] &&
            drawerItemsWithPermissions.map((i) => (
              <>
                <div key={i?.sectionTitle}>
                  <ListItem className={classes.title}>
                    {i.sectionTitle}
                  </ListItem>
                  {i.sectionItems[0] &&
                    i.sectionItems.map((j, ind) => (
                      <ListItem className={classes.nested} key={ind}>
                        <Button
                          disableRipple
                          className={classes.button}
                          href={j.href}
                        >
                          {j.icon ? (
                            <j.icon className={classes.icon} fontSize="small" />
                          ) : null}
                          {j.title}
                        </Button>
                      </ListItem>
                    ))}
                </div>
                <Divider style={{ margin: '1em 0' }} />
              </>
            ))}
        </List>
      </Box>
    </Drawer>
  );
};
