import { slices, useAppDispatch } from 'src/redux';
import { SnackBarShow } from 'src/types';
import { useNotifSound } from './use-notif-sound';

const { actions } = slices.app;

export const useSnackBar = () => {
  const dispatch = useAppDispatch();
  const notifSound = useNotifSound();

  const show = (data: SnackBarShow) => {
    if (data?.useSound) {
      if (data?.severity === 'success') {
        notifSound.success();
      }
      if (data?.severity === 'error') {
        notifSound.error();
      }
    }
    dispatch(actions.showSnackBar(data));
  };

  const hide = () => {
    dispatch(actions.hideSnackBar());
  };

  return {
    show,
    hide
  };
};
