import { Button, CircularProgress, makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { colors } from 'src/constants';

interface Props {
  loading: boolean;
  startIcon?: JSX.Element;
  title: string;
  onClick: () => void;
  disabled?: boolean;
  fullWidth?: boolean;
  height?: number;
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  variant?: 'text' | 'outlined' | 'contained';
}

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    position: 'relative'
  },
  buttonProgress: {
    color: colors.primary,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export const LoadingButton = ({
  loading,
  title = 'Click',
  color = 'primary',
  variant = 'contained',
  startIcon,
  onClick,
  height,
  fullWidth,
  disabled,
  ...otherProps
}: Props) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Button
        style={{ height }}
        startIcon={startIcon}
        color={color}
        onClick={onClick}
        variant={variant}
        disabled={loading || disabled}
        fullWidth={fullWidth}
        {...otherProps}
      >
        {title}
      </Button>
      {loading && (
        <CircularProgress size={24} className={classes.buttonProgress} />
      )}
    </div>
  );
};
