import React, { FC, memo, useEffect, useState } from 'react';
import { Editor } from '@tiptap/react';
import { Box, Button, ButtonGroup, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  buttons: {
    padding: 3
  }
}));

interface Props {
  editor?: Editor | null;
}

const component: FC<Props> = ({ editor }) => {
  const classes = useStyles();

  const [isParagraph, setIsParagraph] = useState<boolean>(false);
  const [isH1, setIsH1] = useState<boolean>(false);
  const [isH2, setIsH2] = useState<boolean>(false);
  const [isH3, setIsH3] = useState<boolean>(false);
  const [isH4, setIsH4] = useState<boolean>(false);
  const [isH5, setIsH5] = useState<boolean>(false);
  const [isH6, setIsH6] = useState<boolean>(false);

  if (!editor) {
    return null;
  }

  //for checking active state
  useEffect(() => {
    if (!editor) {
      return;
    }
    //for event listener to set active state
    editor.on('transaction', ({ editor }) => {
      setIsParagraph(editor.isActive('paragraph'));
      setIsH1(editor.isActive('heading', { level: 1 }));
      setIsH2(editor.isActive('heading', { level: 2 }));
      setIsH3(editor.isActive('heading', { level: 3 }));
      setIsH4(editor.isActive('heading', { level: 4 }));
      setIsH5(editor.isActive('heading', { level: 5 }));
      setIsH6(editor.isActive('heading', { level: 6 }));
    });
  }, [editor]);

  return (
    <Box display={'flex'} flexDirection={'row'} width={'100%'}>
      <ButtonGroup
        variant="text"
        color="primary"
        aria-label="text primary button group"
      >
        <Button
          className={classes.buttons}
          onClick={() =>
            editor
              .chain()
              .focus()
              .setParagraph()
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .setParagraph()
              .run()
          }
          variant={isParagraph ? 'contained' : 'outlined'}
          color="primary"
        >
          {`PARAGRAPH`}
        </Button>
        <Button
          className={classes.buttons}
          onClick={() =>
            editor
              .chain()
              .focus()
              .setHeading({ level: 1 })
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .setHeading({ level: 1 })
              .run()
          }
          variant={isH1 ? 'contained' : 'outlined'}
          color="primary"
        >
          {`H1`}
        </Button>
        <Button
          className={classes.buttons}
          onClick={() =>
            editor
              .chain()
              .focus()
              .setHeading({ level: 2 })
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .setHeading({ level: 2 })
              .run()
          }
          variant={isH2 ? 'contained' : 'outlined'}
          color="primary"
        >
          {`H2`}
        </Button>
        <Button
          onClick={() =>
            editor
              .chain()
              .focus()
              .setHeading({ level: 3 })
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .setHeading({ level: 3 })
              .run()
          }
          variant={isH3 ? 'contained' : 'outlined'}
          color="primary"
        >
          {`H3`}
        </Button>
        <Button
          className={classes.buttons}
          onClick={() =>
            editor
              .chain()
              .focus()
              .setHeading({ level: 4 })
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .setHeading({ level: 4 })
              .run()
          }
          variant={isH4 ? 'contained' : 'outlined'}
          color="primary"
        >
          {`H4`}
        </Button>

        <Button
          className={classes.buttons}
          onClick={() =>
            editor
              .chain()
              .focus()
              .setHeading({ level: 5 })
              .run()
          }
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .setHeading({ level: 5 })
              .run()
          }
          variant={isH5 ? 'contained' : 'outlined'}
          color="primary"
        >
          {`H5`}
        </Button>
        <Button
          className={classes.buttons}
          onClick={() => {
            {
              editor
                .chain()
                .focus()
                .setHeading({ level: 6 })
                .run();
            }
          }}
          disabled={
            !editor
              .can()
              .chain()
              .focus()
              .setHeading({ level: 6 })
              .run()
          }
          variant={isH6 ? 'contained' : 'outlined'}
          color="primary"
        >
          {`H6`}
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export const HeadingOptionV2 = memo(component);
