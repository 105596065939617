import React, { useMemo } from 'react';
import {
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from '@material-ui/core';

import { MultipleChipInput, SelectImageButton } from 'src/components';
import { CustomInputEvent, Gallery } from 'src/types';
import { convertImageFileToBase64 } from 'src/utils';

interface Props {
  data?: Gallery;
  onChangeInput?: (e: CustomInputEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cpuField: {
    borderWidth: 5
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  imgPreview: {
    width: undefined,
    height: 70,
    marginRight: theme.spacing(1)
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

const component = ({ data, onChangeInput }: Props) => {
  const classes = useStyles();

  const onChangeInputInternal = (e: CustomInputEvent) => {
    if (onChangeInput) {
      onChangeInput(e);
    }
  };

  const onChangeBuildTags = (e: string | null) => {
    if (onChangeInput) {
      const buildTagsStr = (e)?.split(',') || [];
      const buildTagsArr: string[] = buildTagsStr
        ?.map((x) => x)
        ?.filter((y) => y !== '' || y !== undefined || y !== null);
      const inputEvent: any = {
        target: {
          name: 'build_tags',
          value: buildTagsArr
        }
      };
      onChangeInput(inputEvent);
    }
  };

  const onChangeBadge = async (img: File[]) => {
    if (onChangeInput && img && img?.length > 0) {
      const firstFile = img[0];
      const imgInTxt = await convertImageFileToBase64(firstFile);
      const inputEvent: any = {
        target: {
          name: 'badge',
          value: imgInTxt
        }
      };
      onChangeInput(inputEvent);
    }
  };

  const buildTags: string | undefined = useMemo(() => {
    if (data?.build_tags && data?.build_tags?.length > 0) {
      return data?.build_tags?.join(',');
    }
    return undefined;
  }, [data]);

  return (
    <CardContent>
      <Typography className={classes.subHeader} variant="h6">
        General Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <TextField
            fullWidth
            label="Title"
            name="title"
            required
            variant="outlined"
            value={data?.title || ''}
            onChange={(e) => onChangeInputInternal(e)}
          />
        </Grid>
        <Grid item md={6} xs={12} className={classes.row}>
          {data?.badge ? (
            <img
              alt="img_preview"
              className={classes.imgPreview}
              src={data?.badge}
            />
          ) : null}
          <SelectImageButton
            title="Select / Update Badge Image"
            multiple={false}
            onImageSelected={onChangeBadge}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Quote"
            name="quote"
            required
            variant="outlined"
            value={data?.quote || ''}
            onChange={(e) => onChangeInputInternal(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            name="description"
            required
            multiline
            minRows={3}
            variant="outlined"
            value={data?.description || ''}
            onChange={(e) => onChangeInputInternal(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <MultipleChipInput
            required
            name="build_tags"
            label="Build Tags"
            value={buildTags}
            helperText="Streaming, Video Editing, etc..."
            onChange={onChangeBuildTags}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel required>CPU</InputLabel>
            <Select
              required
              name="cpu"
              value={data?.cpu || ''}
              onChange={(e: any) => onChangeInputInternal(e)}
              label="CPU *"
            >
              {['AMD', 'Intel']?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              PLEASE DOUBLE CHECK IF CPU IS CORRECT
            </FormHelperText>
          </FormControl>
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth variant="outlined">
            <InputLabel required>Build Tier</InputLabel>
            <Select
              required
              name="build_tier"
              value={data?.build_tier || ''}
              onChange={(e: any) => onChangeInputInternal(e)}
              label="Build Tier"
            >
              {['Entry', 'Mid', 'Premium']?.map((item) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </CardContent>
  );
};

export const FlashDealsGeneralInfoCardContent = React.memo(component);
