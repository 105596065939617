/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Card,
  Container,
  Divider,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { LoaderBar, OutOfStockChip, Page } from 'src/components';
import Toolbar from './Toolbar';
import ManufacturerDetail from './ManufacturerDetail';
import { FixMeLaterApi } from 'src/types';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import { Product } from 'src/types/product';

const { actions: productActions } = slices.product;

const useStyles = makeStyles(() => ({
  root: {}
}));

const ManufacturerView = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const location = useLocation();
  const state: any = location.state;
  const navigate = useNavigate();

  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onCategoryListingPress = (item: FixMeLaterApi) => {
    navigate(`/app/products/${item.id}`);
  };

  const getProductsViaManufacturer = async (keyword?: string) => {
    setIsLoading(true);
    if (state?.id) {
      const response = unwrapResult(
        await dispatch(
          productActions.getProductsWithFilterThunk({
            manufacturer_id: state?.id || '',
            keyword
          })
        ).finally(() => setIsLoading(false))
      );
      if (response?.success && response.originalData.data) {
        setProducts(response.originalData.data);
      }
    }
  };

  const onSearchProduct = (keyword: string) => {
    getProductsViaManufacturer(keyword);
  };

  useEffect(() => {
    getProductsViaManufacturer();
  }, []);

  return (
    <Page className={classes.root} title={`Manufacturer: ${state.name}`}>
      <Container maxWidth={false}>
        <Toolbar onSearchProduct={onSearchProduct} />
        <ManufacturerDetail manufacturer={state} />
        <Box marginY={2}>
          <Card>
            <Divider />
            <PerfectScrollbar>
              <Box>
                <LoaderBar isLoading={isLoading} />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product Name</TableCell>
                      <TableCell>Retail Price</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {products.map((item: Product) => (
                      <TableRow
                        onClick={() => onCategoryListingPress(item)}
                        hover
                        key={item.id}
                      >
                        <TableCell>
                          {item.name ?? '--'}
                          {!item?.count ? <OutOfStockChip /> : null}
                        </TableCell>
                        <TableCell>₱ {item.retail_price}</TableCell>
                        <TableCell>{item.count}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default ManufacturerView;
