import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import { CommonAxiosResponse } from 'src/types';
import Api from 'src/services/ApiService';
import {
  BranchAvailableProductsPayload,
  BranchAvailableProductsResponse,
  GetBranchAvailProdViaSerialNoResponse,
  GetNonSerializeProductResponse,
  GetNonSnProductPayload,
  GetProdThruSerialNoPayload,
  GetTransferCountListingsResponse,
  GetTransferCountResponse,
  GetStNonSnProductResponse,
  GetTransferReceivingPayload,
  GetTransferStockReceivingRecordResponse,
  NonSerializeProductPayload,
  ReceivedNonSnPayload,
  ReceivedNonSnResponse,
  ReceivedSerializedPayload,
  ReceivedSerializedResponse,
  TransferStockOriginToReceivingBranchPayload,
  TransferStockOriginToReceivingBranchResponse,
  UpdateTransferStockReceivingStatusPayload,
  UpdateTransferStockReceivingStatusResponse
} from './types';

//TODO: remove if not use
export const getBranchAvailableProductsThunk = createAsyncThunk<
  CommonAxiosResponse<BranchAvailableProductsResponse>,
  BranchAvailableProductsPayload,
  { state: RootState }
>('transfer/getBranchAvailableProducts', async (data) => {
  const response = await Api.get(
    `branch/products/available/get/${data?.branch_id}`,
    data
  );
  return response;
});

export const transferStocksFromOriginToReceivingBranchThunk = createAsyncThunk<
  CommonAxiosResponse<TransferStockOriginToReceivingBranchResponse>,
  TransferStockOriginToReceivingBranchPayload,
  { state: RootState }
>('transfer/transferStocksFromOriginToReceivingBranchThunk', async (data) => {
  const response = await Api.post(
    `branch/products/origin_to_receiving/transfer`,
    data
  );
  return response;
});

export const getBranchProductViaSerialNoThunk = createAsyncThunk<
  CommonAxiosResponse<GetBranchAvailProdViaSerialNoResponse>,
  GetProdThruSerialNoPayload,
  { state: RootState }
>('transfertransfer/getBranchProductViaSerialNoThunk', async (data) => {
  const response = await Api.get(
    `branch/products/available/serial_no/get/${data?.branch_id}`,
    data
  );
  return response;
});

//===transfer stock module receiver thunks ===
export const getTransferStockRecordThunk = createAsyncThunk<
  CommonAxiosResponse<GetTransferStockReceivingRecordResponse>,
  GetTransferReceivingPayload,
  { state: RootState }
>('transfer/getTransferStockRecordThunk', async (data) => {
  const response = await Api.get(`transfer_stocks_receiving/get`, data);
  return response;
});

export const updateTransferStockReceivingStatusThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateTransferStockReceivingStatusResponse>,
  UpdateTransferStockReceivingStatusPayload,
  { state: RootState }
>('transfer/updateTransferStockReceivingStatusThunk', async (data) => {
  const response = await Api.patch(
    `transfer_stocks_receiving/status/update`,
    data
  );
  return response;
});

export const getNonSerializedProductCountThunk = createAsyncThunk<
  CommonAxiosResponse<GetNonSerializeProductResponse>,
  NonSerializeProductPayload,
  { state: RootState }
>('transfer/getNonSerializedProductCountThunk', async (data) => {
  const response = await Api.get(
    `products/non_serialized_available_count/get`,
    data
  );
  return response;
});

//==details for transfer stock status count and list===
export const getTransferStockCount = createAsyncThunk<
  CommonAxiosResponse<GetTransferCountResponse>,
  number | undefined,
  { state: RootState }
>('transfer/getTransferStockCount', async (product_id) => {
  const response = await Api.get(
    `listings/status_transfer_stocks/get?product_id=${product_id}&get_count=1`
  );
  return response;
});

//===revamped transfer receiving thunks====
export const getStNosNonSnProductsThunk = createAsyncThunk<
  CommonAxiosResponse<GetStNonSnProductResponse>,
  GetNonSnProductPayload,
  { state: RootState }
>('transfer/GetStNosNonSnProductsThunk', async (data) => {
  const { branch_id, product_id } = data;
  const response = await Api.get(
    `/transfer_stocks_receiving/non_serialized_product_st_nos/get?branch_id=${branch_id}&product_id=${product_id}`
  );
  return response;
});

export const getTransferStockCountList = createAsyncThunk<
  CommonAxiosResponse<GetTransferCountListingsResponse>,
  number | undefined,
  { state: RootState }
>('transfer/getTransferStockCountList', async (product_id) => {
  const response = await Api.get(
    `listings/status_transfer_stocks/get?product_id=${product_id}&get_count=0`
  );
  return response;
});

export const updateReceiveSerializedItemsThunk = createAsyncThunk<
  CommonAxiosResponse<ReceivedSerializedResponse>,
  ReceivedSerializedPayload,
  { state: RootState }
>('transfer/UpdateReceiveSerializedItemsThunk', async (data) => {
  const response = await Api.patch(
    `/transfer_stocks_receiving/status/update/v2`,
    data
  );
  return response;
});

export const updateReceiveNonSerializedItemsThunk = createAsyncThunk<
  CommonAxiosResponse<ReceivedNonSnResponse>,
  ReceivedNonSnPayload,
  { state: RootState }
>('transfer/UpdateReceiveNonSerializedItemsThunk', async (data) => {
  const response = await Api.patch(
    `/transfer_stocks_receiving/status/update/v2`,
    data
  );
  return response;
});
