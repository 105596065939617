import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  GetSalesResponse,
  GetSalesRequest,
  GetSalesComputation
} from 'src/types';
import { CommonAxiosResponse } from 'src/types';

const getSalesNextPageController = new AbortController();

export const getSalesThunk = createAsyncThunk<
  CommonAxiosResponse<GetSalesResponse>,
  GetSalesRequest | undefined,
  { state: RootState }
>('sale/getSalesThunk', async (data, api) => {
  getSalesNextPageController.abort();
  let request = data ?? api.getState().sales.salesFilter;
  request = { ...request, action: 'list', page_number: 1 };
  let response: CommonAxiosResponse<GetSalesResponse> = await Api.post(
    '/sales/get',
    request
  );
  if (response?.success) {
    response = {
      ...response,
      originalData: {
        items: response.originalData.data,
        meta: response.originalData.meta
      }
    };
  }
  if (!response.success) {
    throw new Error('Failed getSalesThunk');
  }
  return response;
});

export const getSalesNextPageThunk = createAsyncThunk<
  CommonAxiosResponse<GetSalesResponse>,
  undefined,
  { state: RootState }
>('sale/getSalesNextPageThunk', async (_, api) => {
  let request = api.getState().sales.salesFilter;
  let nextPage =
    (api.getState().sales.salesResponse?.meta?.current_page || 1) + 1;

  request = { ...request, action: 'list', page_number: nextPage };
  let response: CommonAxiosResponse<GetSalesResponse> = await Api.post(
    '/sales/get',
    request,
    {
      signal: getSalesNextPageController.signal
    }
  );
  if (response?.success) {
    response = {
      ...response,
      originalData: {
        items: response.originalData.data,
        meta: response.originalData.meta
      }
    };
  }
  if (!response.success) {
    throw new Error('Failed getSalesNextPageThunk');
  }
  return response;
});

export const getSalesComputationThunk = createAsyncThunk<
  CommonAxiosResponse<GetSalesComputation>,
  GetSalesRequest | undefined,
  { state: RootState }
>('sale/getSalesComputationThunk', async (data, api) => {
  let request = data ?? api.getState().sales.salesFilter;
  request = { ...request, action: 'computation' };
  let response: CommonAxiosResponse<GetSalesComputation> = await Api.post(
    '/sales/get',
    request
  );
  if (!response.success) {
    throw new Error('Failed getSalesComputationThunk');
  }
  return response;
});
