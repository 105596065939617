import React from 'react';
import { TableCell, TableHead, TableRow } from '@material-ui/core';
import { multiBranchFeat } from 'src/constants/feature-toggle';

const TableHeader = () => {
  return (
    <TableHead>
      <TableRow>
        <TableCell>Product Name</TableCell>
        <TableCell>Serial no.</TableCell>
        <TableCell>Category Name</TableCell>
        {multiBranchFeat ? <TableCell>Branch Name</TableCell> : null}
        <TableCell>Created at</TableCell>
        <TableCell>Dealers price</TableCell>
        <TableCell>Retail price</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Purchased date</TableCell>
        <TableCell>Is purchased</TableCell>
      </TableRow>
    </TableHead>
  );
};

export const InventoryTableHeader = React.memo(TableHeader);
