import * as thunks from './thunks';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type State = {
  message?: string;
  isLoading?: boolean;
  customMobileNos?: string[];
  excludedMobileNos?: string[];
  deliveryDate?: string | null;
};

const initialState: State = {
  message: '',
  isLoading: false,
  customMobileNos: [],
  excludedMobileNos: [],
  deliveryDate: null
};

const slice = createSlice({
  name: 'sms',
  initialState,
  reducers: {
    onChangeMessage: (state, { payload }: PayloadAction<string>) => {
      state.message = payload;
    },
    onChangeCustomMobileNos: (state, { payload }: PayloadAction<string[]>) => {
      state.customMobileNos = payload;
    },
    onChangeExcludedMobileNos: (
      state,
      { payload }: PayloadAction<string[]>
    ) => {
      state.excludedMobileNos = payload;
    },
    onChangeDeliveryDate: (state, { payload }: PayloadAction<typeof state.deliveryDate>) => {
      state.deliveryDate = payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(thunks.sendSMSBlastThunkV2.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(thunks.sendSMSBlastThunkV2.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(thunks.sendSMSBlastThunkV2.rejected, (state) => {
      state.isLoading = false;
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...thunks,
  ...slice.actions
};
