import React from 'react';
import { Page } from 'src/components';
import { Container, Typography } from '@material-ui/core';
import { Toolbar } from './components';

import { InventoryAuditListing } from './components/InventoryAuditListing';

const InventoryAuditView = () => {
  return (
    <Page title="Inventory Audit" className={''}>
      <Container maxWidth={false} style={{ marginTop: '1.5rem' }}>
        <Typography color="textPrimary" gutterBottom variant="h3">
          Inventory Audit
        </Typography>
        <Toolbar />
        <InventoryAuditListing />
      </Container>
    </Page>
  );
};

export default InventoryAuditView;
