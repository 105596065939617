import React, { FC, memo, useState } from 'react';
import {
  Box,
  Card,
  Dialog,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { SubCategoryListData } from 'src/types';
import clsx from 'clsx';
import { useNavigate } from 'react-router';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { DecisionDialog } from 'src/components';
import { useMainCategory } from 'src/hooks/category';

const useStyles = makeStyles((theme) => ({
  root: { padding: '0.5rem', width: 800, height: 'auto', overflow: 'scroll' },
  avatar: {
    marginRight: theme.spacing(2)
  },
  paperStyle: {
    margin: '0.5rem',
    padding: '0.5rem',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    cursor: 'pointer',
    '&:hover': { background: '#F5F5F5' }
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
    padding: 0
  }
}));

interface Props {
  isOpen: boolean;
  mainCategoryName?: string;
  onHandleClose: () => void;
  onCategoryRefetch: () => void;
  subCategoryData: SubCategoryListData[];
}

const Component: FC<Props> = ({
  subCategoryData,
  isOpen,
  mainCategoryName,
  onCategoryRefetch,
  onHandleClose
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { unAssignSubCategoryFromMainCategory } = useMainCategory();
  const [subCategory, setSubCategory] = useState<{
    subCategId?: number;
    subCategName?: string;
  }>();
  const [showUnAssignShowDialog, setShowUnAssignShowDialog] = useState<boolean>(
    false
  );

  const onCategoryPress = (item?: any) => {
    navigate(`/app/categories/${item.id}`, { state: item });
  };

  const onHandleUnAssignCategory = (
    subCategId?: number,
    subCategName?: string
  ) => {
    setSubCategory({ subCategId, subCategName });
    setShowUnAssignShowDialog(true);
  };

  const onConfirmUnAssignSubCategory = async () => {
    if (subCategory?.subCategId) {
      const response = await unAssignSubCategoryFromMainCategory(
        subCategory?.subCategId
      );
      if (response) {
        setShowUnAssignShowDialog(false);
        onHandleClose();
        onCategoryRefetch();
      }
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onHandleClose}
      aria-labelledby="simple-dialog-title"
      aria-describedby="simple-dialog-description"
      maxWidth="lg"
    >
      <Card className={clsx(classes.root)}>
        <Box sx={{ padding: '1rem' }}>
          <Typography color="textPrimary" gutterBottom variant="h3">
            {`${mainCategoryName} Categories`}
          </Typography>
        </Box>
        <Box sx={{ padding: '1rem' }}>
          {subCategoryData.length <= 0 ? (
            <Typography>No sub category added yet</Typography>
          ) : null}
          {subCategoryData.map((item: SubCategoryListData) => (
            <Paper className={classes.paperStyle} key={item.id}>
              <Box className={classes.flexRow}>
                <Box
                  className={classes.flexRow}
                  style={{
                    justifyContent: 'flex-start',
                    width: '100%',
                    padding: '0.5rem'
                  }}
                  onClick={() => onCategoryPress(item)}
                >
                  <img
                    alt="main-category-img"
                    style={{
                      width: '60px',
                      height: '60px',
                      marginRight: '1rem'
                    }}
                    src={
                      item.icon ||
                      '/static/images/products/product_not_found.png'
                    }
                  />
                  <Typography>{item?.name}</Typography>
                </Box>
                <Tooltip
                  title={`Unassign ${item.name} from ${mainCategoryName} main category`}
                >
                  <IconButton
                    color="secondary"
                    aria-label="unassign-category"
                    component="span"
                    onClick={() => onHandleUnAssignCategory(item.id, item.name)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Paper>
          ))}
        </Box>
      </Card>
      <DecisionDialog
        isOpen={showUnAssignShowDialog}
        title="Un-assign Sub Category"
        subTitle={`Are you sure you want to remove/unassign ${subCategory?.subCategName}`}
        onHandleConfirmAction={() => onConfirmUnAssignSubCategory()}
        onHandleClose={() => setShowUnAssignShowDialog(!showUnAssignShowDialog)}
      />
    </Dialog>
  );
};

export const SubCategoryModal = memo(Component);
