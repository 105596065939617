// NOTE: If you want to use this component. Convert your selected product to 'Product' type

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import { slices, useAppDispatch } from 'src/redux';
import { useAlertGlobal, usePermissions, useSnackBar } from 'src/hooks';

import { FlashDeal, FlashDealsUpdateRequest, Product } from 'src/types';
import { FlashDealsProductsCardContent } from '../component';
import { unwrapResult } from '@reduxjs/toolkit';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import format from 'date-fns/format';
import { useNavigate, useParams } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import { localize } from 'src/constants';
import { isEqual } from 'lodash';
import { Alert } from '@material-ui/lab';
import { TimeLeftCircles } from 'src/components/progress-bar';

const { actions: flashDealsActions } = slices.flashDeals;

const useStyles = makeStyles((theme) => ({
  root: {
    paddingBottom: 20
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  createBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 60
  },
  saveBtn: {
    marginLeft: theme.spacing(2)
  }
}));

const component = () => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const snackBar = useSnackBar();
  const { canEditFlashDeals } = usePermissions();
  const navigate = useNavigate();
  const alertGlobal = useAlertGlobal();
  const { id } = useParams();

  const [origFlashDealDetails, setOrigFlashDealDetails] = useState<FlashDeal>();
  const [flashDealDetails, setFlashDealDetails] = useState<FlashDeal>();
  const [loading, setLoading] = useState<boolean>(false);

  const onDatePublishChange = async () => {
    if (origFlashDealDetails?.deal_start === '0000-00-00 00:00:00') {
      snackBar.show({
        severity: 'error',
        message: 'Something is wrong with deal_start the Dates'
      });
      return;
    }
    if (origFlashDealDetails?.deal_end === '0000-00-00 00:00:00') {
      snackBar.show({
        severity: 'error',
        message: 'Something is wrong with deal_end the Dates'
      });
      return;
    }

    const newPublishedValue = !flashDealDetails?.published;
    const publishPayload = {
      id: flashDealDetails?.id,
      deal_start: format(
        new Date(origFlashDealDetails?.deal_start || ''),
        'yyyy-MM-dd HH:mm:ss'
      ),
      deal_end: format(
        new Date(origFlashDealDetails?.deal_end || ''),
        'yyyy-MM-dd HH:mm:ss'
      ),
      published: newPublishedValue
    };

    setLoading(true);
    const resp = unwrapResult(
      await dispatch(
        flashDealsActions.flashDealsPublishThunk(publishPayload)
      ).finally(() => setLoading(false))
    );

    if (resp?.success) {
      setFlashDealDetails((s) => ({
        ...s,
        published: newPublishedValue ? 1 : 0
      }));
    }
  };

  const onDateRangeChange = (
    timeField: 'deal_start' | 'deal_end',
    newDate?: MaterialUiPickersDate
  ) => {
    setFlashDealDetails((s) => ({ ...s, [timeField]: newDate }));
  };

  const selectedProduct = useMemo(() => {
    let product: Product = { name: '' };
    if (flashDealDetails?.product_id && flashDealDetails?.product_name) {
      product = {
        id: flashDealDetails?.product_id,
        name: flashDealDetails?.product_name
      };
    }
    return product;
  }, [flashDealDetails]);

  const nothingChanged = useMemo(
    () => isEqual(origFlashDealDetails, flashDealDetails),
    [flashDealDetails, origFlashDealDetails]
  );

  const isFlashDealsUpdateParamsValid = () => {
    if (!canEditFlashDeals) {
      return localize.ERR_PERMISSION;
    }
    if (!flashDealDetails?.product_id) {
      return 'Please select a product';
    }
    if (!flashDealDetails?.deal_start) {
      return 'Please select a start date';
    }
    if (!flashDealDetails?.deal_end) {
      return 'Please select an end date';
    }
    return '';
  };

  const onUpdateFlashDeal = async () => {
    const errMsg = isFlashDealsUpdateParamsValid();
    if (errMsg) {
      snackBar.show({
        severity: 'error',
        message: errMsg || 'Something is Wrong'
      });
      return;
    }

    if (flashDealDetails) {
      setLoading(true);
      snackBar.show({
        severity: 'info',
        message: 'Updating Flash Deal....'
      });

      const finalUpdatePayload: FlashDealsUpdateRequest = {
        id: flashDealDetails?.id,
        deal_start: format(
          new Date(flashDealDetails?.deal_start || ''),
          'yyyy-MM-dd HH:mm:ss'
        ),
        deal_end: format(
          new Date(flashDealDetails?.deal_end || ''),
          'yyyy-MM-dd HH:mm:ss'
        ),
        published: flashDealDetails?.published ? true : false
      };

      const response = unwrapResult(
        await dispatch(
          flashDealsActions.flashDealsUpdateThunk(finalUpdatePayload)
        ).finally(() => setLoading(false))
      );
      if (response.success) {
        snackBar.show({
          severity: 'success',
          message: 'Flash Deal updated successfully'
        });
        getFlashDealDetails();
        return;
      }
      snackBar.show({
        severity: 'error',
        message: response?.message || localize.ERR_API_UNKNOWN
      });
    }
  };

  const onDeleteFlashDeal = async () => {
    if (!canEditFlashDeals) {
      snackBar.show({
        severity: 'error',
        message: localize.ERR_PERMISSION
      });
      return;
    }
    if (flashDealDetails?.id) {
      setLoading(true);
      const response = unwrapResult(
        await dispatch(
          flashDealsActions.flashDealsDeleteThunk(flashDealDetails?.id)
        ).finally(() => setLoading(false))
      );
      if (response.success) {
        navigate(-1);
        dispatch(flashDealsActions.getFlashDealsListThunk({}));
        snackBar.show({
          severity: 'success',
          message: 'FlashDeal deleted successfuly'
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || localize.ERR_API_UNKNOWN
        });
      }
    }
  };

  const onDeleteFlashDealWarning = () => {
    if (!canEditFlashDeals) {
      snackBar.show({
        severity: 'error',
        message: localize.ERR_PERMISSION
      });
      return;
    }
    alertGlobal.show({
      title: 'DELETE FLASH DEAL',
      subtitle: 'Are you sure you want to delete this flash deal?',
      buttons: [
        {
          text: 'Yes',
          onClick: () => {
            onDeleteFlashDeal();
            alertGlobal.hide();
          },
          color: 'secondary'
        },
        {
          text: 'Cancel',
          onClick: () => alertGlobal.hide()
        }
      ]
    });
  };

  const getFlashDealDetails = useCallback(async () => {
    if (!id) {
      return;
    }
    setLoading(true);
    const response = unwrapResult(
      await dispatch(
        flashDealsActions.flashDealsGetViaIdThunk(+id)
      ).finally(() => setLoading(false))
    );
    if (response.success) {
      setFlashDealDetails(response?.originalData?.data);
      setOrigFlashDealDetails(response?.originalData?.data);
    } else {
      snackBar.show({
        severity: 'error',
        message:
          response?.message || response?.errors
            ? JSON.stringify(response?.errors)
            : 'Something went wrong' || 'Something went wrong'
      });
    }
  }, [dispatch, id, snackBar]);

  useEffect(() => {
    getFlashDealDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="New Flash Deal">
      <LoaderBar isLoading={loading} />
      <Container maxWidth={false}>
        <Box mt={2}>
          {loading ? null : (
            <Alert severity={flashDealDetails?.published ? 'info' : 'warning'}>
              <Typography variant="subtitle2">{`THIS FLASH DEAL IS ${
                flashDealDetails?.published ? 'PUBLISHED' : 'NOT YET PUBLISHED'
              }`}</Typography>
              <Button
                size="small"
                variant="outlined"
                onClick={() => onDatePublishChange()}
                color={flashDealDetails?.published ? 'secondary' : 'primary'}
              >
                {flashDealDetails?.published ? 'UNPUBLISH' : 'PUBLISH'}
              </Button>
            </Alert>
          )}

          <Card>
            <CardHeader
              title="Flash Deal Details"
              subheader="The information of Flash Deal."
            />

            <Divider />
            <FlashDealsProductsCardContent
              productSelectDisabled
              dateRangeDisabled={loading}
              selectedProduct={selectedProduct}
              fromDateTime={flashDealDetails?.deal_start}
              onFromDateTimeChange={(d) => onDateRangeChange('deal_start', d)}
              toDateTime={flashDealDetails?.deal_end}
              onToDateTimeChange={(d) => onDateRangeChange('deal_end', d)}
            />

            <Divider />
            <CardContent>
              <Typography className={classes.subHeader} variant="h5">
                Time Left
              </Typography>
              {/* <BorderLinearProgress /> */}

              <Box mt={1}>
                <TimeLeftCircles
                  endDate={flashDealDetails?.deal_end}
                  startDate={flashDealDetails?.deal_start}
                />
              </Box>
            </CardContent>
            <Divider />
          </Card>
        </Box>

        <div className={classes.btnContainer}>
          <Button
            fullWidth
            startIcon={<DeleteIcon />}
            onClick={onDeleteFlashDealWarning}
            color="secondary"
            variant="outlined"
            disabled={loading || !canEditFlashDeals}
          >
            Delete Flash Deal
          </Button>
          <Button
            fullWidth
            disabled={nothingChanged || loading || !canEditFlashDeals}
            startIcon={<SaveIcon />}
            onClick={onUpdateFlashDeal}
            color="primary"
            className={classes.saveBtn}
            variant="contained"
          >
            Update Flash Deal
          </Button>
        </div>
      </Container>
    </Page>
  );
};

export const FlashDealDetailsView = React.memo(component);
