import React, { FC, memo } from 'react';
import { Editor } from '@tiptap/react';
import { Box, Button, ButtonGroup } from '@material-ui/core';

interface Props {
  editor?: Editor | null;
}

const component: FC<Props> = ({ editor }) => {
  if (!editor) {
    return null;
  }

  //for checking active state

  return (
    <Box display={'flex'} flexDirection={'row'} width={'100%'}>
      <ButtonGroup
        variant="outlined"
        color="primary"
        aria-label="text primary button group"
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            editor
              .chain()
              .focus()
              .insertTable({ rows: 3, cols: 3, withHeaderRow: true })
              .run()
          }
        >
          insert Table
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            editor
              .chain()
              .focus()
              .deleteTable()
              .run()
          }
        >
          delete Table
        </Button>
        <Button
          onClick={() =>
            editor
              .chain()
              .focus()
              .mergeOrSplit()
              .run()
          }
        >
          merge Or Split
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            editor
              .chain()
              .focus()
              .addColumnBefore()
              .run()
          }
        >
          add Column Before
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            editor
              .chain()
              .focus()
              .addColumnAfter()
              .run()
          }
        >
          add Column After
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            editor
              .chain()
              .focus()
              .deleteColumn()
              .run()
          }
        >
          delete Column
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            editor
              .chain()
              .focus()
              .addRowBefore()
              .run()
          }
        >
          add Row Before
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            editor
              .chain()
              .focus()
              .addRowAfter()
              .run()
          }
        >
          add Row After
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() =>
            editor
              .chain()
              .focus()
              .deleteRow()
              .run()
          }
        >
          delete Row
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export const TableControlMenu = memo(component);
