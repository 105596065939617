import React, { FC, memo, useState } from 'react';
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { stTableHeader } from '../constant';
import { TransferLogsSerialData } from 'src/redux/slices/transfer-stock-logs';
import PrintIcon from '@material-ui/icons/Print';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import { TransferLogPrintDialog } from './TransferLogPDF';
import { colors } from 'src/constants';

interface Props {
  isLoading: boolean;
  isOpen: boolean;
  stNo?: string;
  stBarcode?: string;
  processedBy?: string;
  dateTransferred?: string;
  origin?: string;
  receiving?: string;
  transferLogsSerialData: TransferLogsSerialData[];
}

const component: FC<Props> = ({
  isLoading,
  isOpen,
  stNo,
  stBarcode,
  origin,
  receiving,
  dateTransferred,
  transferLogsSerialData,
  processedBy
}) => {
  const [isOpenPrintDialog, setIsOpenPrintDialog] = useState<boolean>(false);

  return (
    <Paper style={{ marginTop: '15px', marginBottom: '15px' }}>
      {isLoading && <LinearProgress />}

      {isOpen && !isLoading && (
        <>
          <Box
            padding={'1rem'}
            sx={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Typography variant="h4">{`Stock Transfer Products (${stNo})`}</Typography>
            <Button
              variant="outlined"
              color="primary"
              startIcon={<PrintIcon />}
              onClick={() => setIsOpenPrintDialog(true)}
            >
              Print
            </Button>
          </Box>
          <Table>
            <TableHead>
              <TableRow>
                {stTableHeader.map((header, index) => (
                  <TableCell key={index}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transferLogsSerialData.map((logs) => (
                <TableRow key={logs?.serial_no}>
                  <TableCell>
                    {logs?.received === 1 ? (
                      <CheckCircleIcon style={{ color: colors.green[600] }} />
                    ) : (
                      <CancelIcon color="secondary" />
                    )}
                  </TableCell>
                  <TableCell>{logs?.serial_no}</TableCell>
                  <TableCell>{logs?.dr_no}</TableCell>
                  <TableCell>{logs?.category_name}</TableCell>
                  <TableCell>{logs?.product_name}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      )}
      <TransferLogPrintDialog
        isOpen={isOpenPrintDialog}
        stNo={stNo}
        stBarcode={stBarcode}
        dateTransfer={dateTransferred}
        origin={origin}
        receiving={receiving}
        processedBy={processedBy}
        transferLogs={transferLogsSerialData || []}
        onHandleCloseDialog={() => setIsOpenPrintDialog(false)}
      />
    </Paper>
  );
};

export const TransferLogAccordion = memo(component);
