import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CheckBoxLabel, LoadingButton, SelectImageButton } from '..';
import {
  CreateCategoryRequest,
  CustomKeyboardEvent,
  GetCategoryMainData
} from 'src/types';
import { Autocomplete } from '@material-ui/lab';

import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup
} from '@material-ui/core';
import { targetDataCategories } from 'src/constants';
import { convertImageFileToBase64 } from 'src/utils';
import { useSnackBar } from 'src/hooks';
import { isEmpty } from 'lodash';

interface Props {
  title?: string;
  subTitle?: string;
  isVisible: boolean;
  handleClose: () => void;
  fieldName?: string;
  onAddPress: (categoryParams?: CreateCategoryRequest) => void;
  customButtons?: JSX.Element;
  loading?: boolean;

  errMsg?: string;
  hasErrCreateCategory?: boolean;
  nameValue?: string;

  mainCategOptions?: GetCategoryMainData[];
}

const CategoryAddDialog = ({
  title = 'Title',
  subTitle = 'Subtitle',
  fieldName = 'Email',
  isVisible = false,
  handleClose,
  onAddPress,
  customButtons,
  errMsg,
  nameValue,
  loading,
  mainCategOptions
}: Props) => {
  const snackBar = useSnackBar();
  const [name, setName] = useState<string>(nameValue || '');
  const [isMainCategoryOpt, setIsMainCategoryOpt] = useState<boolean>(false);
  const [hideCheckToggle, setHideCheckToggle] = useState<boolean>(false);
  const [isVisibleSideDrawer, setIsVisibleSideDrawer] = useState<boolean>(true);

  const [categoryPayload, setCategoryPayload] = useState<
    CreateCategoryRequest | undefined
  >(undefined);

  const [errName, setErrName] = useState<string>(errMsg || '');
  const [errTargetData, setErrTargetData] = useState<string>(errMsg || '');
  const [errMainCateg, setErrMainCateg] = useState<string>(errMsg || '');
  const [isHiddenInPricelist, setIsHiddenInPriceList] = useState<boolean>(
    false
  );

  const resetErrState = () => {
    setErrName('');
    setErrTargetData('');
    setErrMainCateg('');
  };

  const resetState = useCallback(() => {
    setName('');
    resetErrState();
    setCategoryPayload(undefined);
  }, []);

  const onAddBtnPress = () => {
    if (loading) {
      return;
    }
    // Check if name is valid.
    if (!name) {
      setErrName('Invalid Value');
      return;
    }
    //main category is required
    if (!isMainCategoryOpt && !categoryPayload?.main_category_id) {
      setErrMainCateg('Main Category is Required');
      return;
    }
    //if creating main category target data is required
    if (isMainCategoryOpt && !categoryPayload?.target_data) {
      setErrTargetData('Target data is Required');
      return;
    }

    if (!categoryPayload?.img_url && isEmpty(categoryPayload?.img_url)) {
      snackBar.show({
        severity: 'error',
        message: `Category Image Icon is Required`
      });
      return;
    }

    if (!categoryPayload?.icon_sidedrawer) {
      snackBar.show({
        severity: 'error',
        message: `Category Side Drawer Icon is Required`
      });
      return;
    }

    if (isVisibleSideDrawer && isEmpty(categoryPayload?.icon_sidedrawer)) {
      snackBar.show({
        severity: 'error',
        message: `Side Icon is Required if Category is visible on side drawer`
      });
      return;
    }

    if (onAddPress) {
      onAddPress({
        ...categoryPayload,
        name: name,
        is_hidden_pricelist: isHiddenInPricelist
      });
    }
  };

  const onImageSelected = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      setCategoryPayload((prev) => ({ ...prev, img_url: imgInBase64 }));
    }
  };

  const onIconSelected = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      setCategoryPayload((prev) => ({ ...prev, icon_sidedrawer: imgInBase64 }));
    }
  };

  const onHandleChangeMainCategory = (
    event: React.ChangeEvent<{ value?: any }>,
    newVal: GetCategoryMainData | null
  ) => {
    if (newVal?.id) {
      setCategoryPayload((prev) => ({ ...prev, main_category_id: newVal?.id }));
    }
  };

  const onHandleChangeTargetData = (
    event: React.ChangeEvent<{ value?: any }>,
    newVal: { name: string; value: number }
  ) => {
    setErrTargetData('');
    setCategoryPayload((prev) => ({ ...prev, target_data: newVal?.value }));
  };

  const onHandleChangeCreateCategoryFor = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const radioBtnVal = event?.target.value;
    switch (radioBtnVal) {
      case 'main':
        setIsMainCategoryOpt(true);
        resetState();
        setHideCheckToggle(true);
        setIsHiddenInPriceList(false);
        break;
      case 'sub':
        setIsMainCategoryOpt(false);
        resetState();
        setHideCheckToggle(false);
        break;
      default:
    }
  };

  const onCancelBtnPress = () => {
    handleClose();
  };

  const onHandleClose = () => {
    handleClose();
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onAddBtnPress();
    }
  };

  useEffect(() => {
    resetState();
    setIsMainCategoryOpt(false);
  }, [isVisible, resetState]);

  return (
    <Dialog
      open={isVisible}
      onClose={onHandleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent style={{ minWidth: '500px' }}>
        <DialogContentText>{subTitle}</DialogContentText>
        <Paper style={{ padding: '15px', marginTop: '5px' }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Create Category As:</FormLabel>
            <RadioGroup
              aria-label="Create category for"
              defaultValue="sub"
              name="createCategory"
              onChange={onHandleChangeCreateCategoryFor}
            >
              <FormControlLabel
                value="sub"
                control={<Radio />}
                label="Sub Category"
              />
              <FormControlLabel
                value="main"
                control={<Radio />}
                label="Main Category"
              />
            </RadioGroup>
          </FormControl>
        </Paper>
        <TextField
          style={{ marginTop: '15px' }}
          error={errName === '' ? false : true}
          helperText={errName}
          autoFocus
          margin="dense"
          id="name"
          label={fieldName}
          fullWidth
          required
          variant="outlined"
          value={name}
          onKeyPress={onKeyPress}
          onChange={(e) => {
            setErrName('');
            setName(e.target.value);
          }}
        />

        {isMainCategoryOpt ? (
          // Target data is required when creating main category
          <div style={{ marginTop: '20px' }}>
            <Autocomplete
              id="target-data"
              options={targetDataCategories || []}
              style={{ minWidth: 300, marginTop: '0.7rem' }}
              getOptionLabel={(option: any) => option?.name}
              onChange={(event, newVal: any) =>
                onHandleChangeTargetData(event, newVal)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Set target data"
                  variant="outlined"
                  fullWidth
                  title="Setting the target data for main category"
                  helperText={errTargetData}
                  error={errTargetData === '' ? false : true}
                  onChange={() => {
                    setErrTargetData('');
                  }}
                />
              )}
            />
          </div>
        ) : (
          <Autocomplete
            id="main-category"
            options={mainCategOptions || []}
            style={{ minWidth: 300, marginTop: '0.7rem' }}
            getOptionLabel={(option: any) => option.name}
            onChange={(event, newVal) =>
              onHandleChangeMainCategory(event, newVal)
            }
            onInputChange={(event, newInputValue) => {
              if (!newInputValue)
                setCategoryPayload((...prev) => ({
                  ...prev,
                  main_category_id: undefined
                }));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Set To Main Category"
                variant="outlined"
                fullWidth
                title="Setting main category will make the created category subset to this main category"
                helperText={errMainCateg}
                error={errMainCateg === '' ? false : true}
              />
            )}
          />
        )}
        {!hideCheckToggle ? (
          <Box sx={{ marginTop: '1rem' }}>
            <CheckBoxLabel
              onChange={() => setIsHiddenInPriceList(!isHiddenInPricelist)}
              label="Hide on website's price list"
              checked={isHiddenInPricelist}
            />
          </Box>
        ) : null}
        <CheckBoxLabel
          onChange={() => setIsVisibleSideDrawer(!isVisibleSideDrawer)}
          label="Visible on Side Drawer?"
          checked={isVisibleSideDrawer}
        />
        <Paper
          elevation={1}
          style={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'row',
            padding: '1rem'
          }}
        >
          <img
            alt=""
            style={{
              width: undefined,
              height: 70,
              marginRight: '1rem'
            }}
            src={categoryPayload?.icon_sidedrawer}
          />
          <SelectImageButton
            containerStyle={{ width: '100%' }}
            title="Select/ Update Side Drawer Icon"
            multiple={false}
            onImageSelected={onIconSelected}
          />
        </Paper>
        <Paper
          elevation={1}
          style={{
            marginTop: '1rem',
            display: 'flex',
            flexDirection: 'row',
            padding: '1rem'
          }}
        >
          <img
            alt=""
            style={{
              width: undefined,
              height: 70,
              marginRight: '1rem'
            }}
            src={categoryPayload?.img_url}
          />
          <SelectImageButton
            containerStyle={{ width: '100%' }}
            title="Select/ Update Category Icon"
            multiple={false}
            onImageSelected={onImageSelected}
          />
        </Paper>
      </DialogContent>
      <DialogActions style={{ marginRight: '1rem' }}>
        {customButtons ? (
          customButtons
        ) : (
          <>
            <LoadingButton
              title="Add"
              loading={loading ? loading : false}
              onClick={onAddBtnPress}
              color="primary"
            />
            <Button onClick={onCancelBtnPress}>Cancel</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default CategoryAddDialog;
