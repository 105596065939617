import { createSlice } from '@reduxjs/toolkit';
import * as thunks from './thunks';
import { BuildDetails } from 'src/types/user-builds';
import { PaginationLinks, PaginationMeta } from 'src/types';

type State = {
  buildList: BuildDetails[];
  meta: PaginationMeta;
  links: PaginationLinks;
};

const initialState: State = {
  buildList: [],
  meta: {},
  links: {},
};

const slice = createSlice({
  name: 'sampleSlice',
  initialState,
  reducers: {}, // TODO: will add more ass needed
  extraReducers(builder) {
    builder.addCase(
      thunks.getUserBuildsThunk.fulfilled,
      (state, { payload }) => {
        state.buildList = payload?.originalData?.data ?? [];
        state.meta = payload?.originalData?.meta ?? {}
        state.links = payload?.originalData?.links ?? {}
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
