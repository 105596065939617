import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  banner: {
    backgroundImage:
      'linear-gradient(to right, rgba(255,255,255,0.9) , rgba(255,255,255,0.4)), url(/static/images/product_header_background.png)',
    height: 230,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    alignItems: 'center',
    justifyContent: 'space-between',
    display: 'flex',
    px: 10
  },
  brandsContainer: {
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  brandWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
    minHeight: 400
  },
  redInfoWrapper: {
    backgroundColor: 'secondary',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1
  },
  whiteInfoWrapper: {
    backgroundColor: 'white'
  },
  redInfoTxt: {
    color: 'white'
  },
  whiteInfoTxt: {
    color: 'secondary'
  },
  mapouter: {
    position: 'relative',
    textAlign: 'right',
    height: '100%',
    minHeight: 400
  },
  gmap_canvas: {
    overflow: 'hidden',
    background: 'none!important',
    height: '100%',
    minHeight: 400
  },
  mapLoading: {
    position: 'absolute',
    top: '48%',
    left: '50%'
  }
}));

type Props = {
  long?: string;
  lat?: string;
};

const GoogleMapFrame = ({ long, lat }: Props) => {
  const classes = useStyles();
  const updateMapSrc = () => {
    const latitude = lat;
    const longitude = long;
    const src = `https://maps.google.com/maps?q=${latitude},${longitude}&z=17&ie=UTF8&iwloc=&output=embed`;
    return src;
  };
  return (
    <Grid container>
      <Grid item xs={12} md={12}>
        <div className={classes.brandWrapper}>
          <div className={classes.mapouter}>
            <div className={classes.gmap_canvas}>
              <iframe
                loading="lazy"
                // onLoad={() => setMapLoader(false)}
                height="400"
                width="100%"
                id="gmap_canvas"
                // src="https://maps.google.com/maps?q=pc%20worth&t=&z=17&ie=UTF8&iwloc=&output=embed"
                src={updateMapSrc()}
                // frameBorder="0"
                // scrolling="no"
                // marginHeight="0"
                // marginWidth="0"
              />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};

export default GoogleMapFrame;
