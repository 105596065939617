import { createSlice } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';
import { Rma } from 'src/types';
import * as thunks from './thunks';

type State = {
  rmas: Rma[];
};

const initialState: State = {
  rmas: []
};

const slice = createSlice({
  name: 'rmas',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getRmasThunk.fulfilled, (state, { payload }) => {
      state.rmas = payload.originalData.rmas;
    });
    builder.addCase(thunks.rmaDeleteThunk.fulfilled, (state, actions) => {
      const rmaId = actions.meta.arg;
      const clonedProducts = cloneDeep(state.rmas);
      const index = state?.rmas?.findIndex((x) => x?.id === rmaId);
      if (index > -1) {
        clonedProducts.splice(index, 1);
      }
      state.rmas = clonedProducts;
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
