import React, { CSSProperties, useEffect, useState } from 'react';
import Modal from '@material-ui/core/Modal';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import ClearIcon from '@material-ui/icons/Clear';

import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { isEmpty } from 'lodash';

const {
  actions: categoryActions,
  selectors: categorySelectors
} = slices.category;

const {
  actions: manufacturerActions,
  selectors: manufacturerSelectors
} = slices.manufacturer;

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8
  },
  filter: {
    marginTop: 20,
    marginBottom: 20
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: 20
  }
}));

const bodyStyle: CSSProperties = {
  top: '20%'
};

const modalStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

interface Props {
  visible: boolean;
  onHandleClose: () => void;
}

export const ProductSearchFilterModal = ({ visible, onHandleClose }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const categories = useAppSelector(categorySelectors.selectCategoriesOnFilter);
  const manufacturers = useAppSelector(
    manufacturerSelectors.selectManufacturersOnFilter
  );

  const [noStockCheck, setNoStockCheck] = useState<boolean>(false);
  const [selectedCategory, setSelectedCategory] = useState<any | null>(null);
  const [selectedManufacturer, setSelectedManufacturer] = useState<any | null>(
    null
  );
  const [categoryInput, setCategoryInput] = useState('');
  const [manufacturerInput, setManufacturerInput] = useState('');

  const onCategoryInputChange = (text: string) => {
    setCategoryInput(text);
  };

  const onManufacturerInputChange = (text: string) => {
    setManufacturerInput(text);
  };

  const onApplyFilter = () => {
    // TODO: Add filter state in product redux
    // TODO: Add dispatch. for filter.
    // and create  update products.
    onHandleClose();
  };

  const onClear = () => {
    setSelectedCategory(null);
    setSelectedManufacturer(null);
    setCategoryInput('');
    setManufacturerInput('');
    setNoStockCheck(false);
  };

  const onCancel = () => {
    onClear();
    onHandleClose();
  };

  useEffect(() => {
    if (visible && isEmpty(categories)) {
      dispatch(categoryActions.getCategoriesFilterThunk({}));
    }
    if (visible && isEmpty(manufacturers)) {
      dispatch(manufacturerActions.getManufacturersFilterThunk({}));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible, dispatch]);

  return (
    <Modal
      disableBackdropClick
      open={visible}
      onClose={onHandleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={modalStyle}
    >
      <div style={bodyStyle} className={classes.body}>
        <div className={classes.titleContainer}>
          <Typography variant="h4" color="textPrimary">
            Product Filter
          </Typography>
          <Button endIcon={<ClearIcon />} onClick={onClear}>
            Clear
          </Button>
        </div>
        <Autocomplete
          value={selectedCategory}
          className={classes.filter}
          onChange={(_, newValue) => {
            setSelectedCategory(newValue);
          }}
          inputValue={categoryInput}
          onInputChange={(_, newInputValue) => {
            onCategoryInputChange(newInputValue);
          }}
          id="category"
          options={categories}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Category" variant="outlined" />
          )}
        />
        <Autocomplete
          value={selectedManufacturer}
          className={classes.filter}
          onChange={(_, newValue) => {
            setSelectedManufacturer(newValue);
          }}
          inputValue={manufacturerInput}
          onInputChange={(_, newInputValue) => {
            onManufacturerInputChange(newInputValue);
          }}
          id="manufacturer"
          options={manufacturers}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField {...params} label="Manufacturer" variant="outlined" />
          )}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              name="hideNoStock"
              checked={noStockCheck}
              onChange={(e, checked) => setNoStockCheck(checked)}
            />
          }
          label="Hide products without stock"
        />
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          className={classes.footer}
        >
          <Button
            fullWidth
            onClick={onCancel}
            color="secondary"
            variant="contained"
          >
            Cancel
          </Button>
          <Button
            style={{ marginLeft: 20 }}
            fullWidth
            onClick={onApplyFilter}
            color="primary"
            variant="contained"
          >
            Apply
          </Button>
        </Box>
      </div>
    </Modal>
  );
};
