import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SvgIcon,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { AlertDialog, Page } from 'src/components';
import React, { useEffect, useState } from 'react';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { Search as SearchIcon } from 'react-feather';
import { CustomKeyboardEvent } from 'src/types';
import { BuildDetails } from 'src/types/user-builds';
import { DeleteOutline, LaunchOutlined } from '@material-ui/icons';
import { unwrapResult } from '@reduxjs/toolkit';
import { usePermissions } from 'src/hooks';
import { useNavigate } from 'react-router';
import { Pagination } from '@material-ui/lab';

const {
  selectors: userBuildsSelectors,
  actions: userBuildsActions
} = slices.userBuilds;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  },
  tableHeader: {
    fontWeight: 'bold',
    borderBottom: 'black'
  },
  tableCard: {
    marginTop: '2em'
  },
  sortContainer: {
    marginRight: '2em'
  },
  buildAmount: {
    display: 'flex',
    alignItems: 'center'
  },
  deleteButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  dialogTitle: {
    fontSize: '2em'
  },
  dialogTextContainer: {
    width: '20vw',
    maxWidth: '364px',
    textAlign: 'center'
  }
}));

export const EcommSavedBuildsView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const buildList = useAppSelector(userBuildsSelectors.selectBuildList);
  const paginationMeta = useAppSelector(
    userBuildsSelectors.selectBuildListMeta
  );
  const { canDeleteEcommSavedBuilds } = usePermissions();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [keyword, setKeyword] = useState<string>('');
  const [keywordSearch, setKeywordSearch] = useState<string>('');
  const [buildListState, setBuildlListState] = useState<BuildDetails[]>([]);
  const [sortDir, setSortDir] = useState<string>('latest');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
  const [currentID, setCurrentID] = useState<number | null>(null);

  const onSearchClick = () => {
    setKeywordSearch(keyword);
    handleGetBuilds(keyword);
    setSortDir('latest');
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter' && !isLoading) {
      handleGetBuilds(keyword);
      setKeywordSearch(keyword);
      return;
    }
    return;
  };

  const handleChangeSort = (e?: React.ChangeEvent<{ value: any }>) => {
    const newSortDir = e?.target?.value;
    let newBuildList = [...buildList];
    setSortDir(newSortDir);

    switch (true) {
      case newSortDir === 'asc':
        newBuildList.sort((a, b) => a.build_amount - b.build_amount);
        setBuildlListState(newBuildList);
        return;
      case newSortDir == 'desc':
        newBuildList.sort((a, b) => b.build_amount - a.build_amount);
        setBuildlListState(newBuildList);
        return;
      default:
        setBuildlListState(buildList);
    }
  };

  const handlePageChange = (event: any, page: number) => {
    dispatch(
      userBuildsActions.getUserBuildsThunk({
        keyword: keywordSearch,
        page: page
      })
    );
  };

  const handleGetBuilds = async (val = '') => {
    const payload = {
      keyword: val
    };

    setIsLoading(true);
    const response = await dispatch(
      userBuildsActions.getUserBuildsThunk(payload)
    );

    if (response) {
      setIsLoading(false);
    }
  };

  const handleChange = (val: string) => {
    setKeyword(val);
  };

  const handleDeleteBuild = async () => {
    const newID = currentID?.toString();

    const response = unwrapResult(
      await dispatch(userBuildsActions.deleteUserBuildThunk({ id: newID }))
    );

    if (response.success) {
      setIsDeleteDialogOpen(false);
    } else {
      // TODO: May replace with snackbar
      console.log(response.message);
    }

    // Refresh ID and build list
    setCurrentID(null);
    handleGetBuilds();
  };

  const handleBuildPress = (build: BuildDetails) =>
    navigate(`/app/saved-builds/details/${build.id}`, { state: build });

  const handleOpenDeleteDialog = (id: number) => {
    setCurrentID(id);
    setIsDeleteDialogOpen(true);
  };

  const handleCancelDelete = () => setIsDeleteDialogOpen(false);

  useEffect(() => {
    setBuildlListState(buildList.map((i) => i));
  }, [buildList]);

  useEffect(() => {
    handleGetBuilds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="Ecomm User Builds" className={classes.root}>
      <Container maxWidth={false}>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Typography color="textPrimary" gutterBottom variant="h3">
            Ecomm Saved Builds
          </Typography>
        </Box>
        <Box mt={3}>
          <Card>
            <CardContent>
              <Grid container justifyContent="space-between">
                <Grid item container md={9} spacing={2} alignItems="center">
                  <Grid item sm={4} md={3} xs={4}>
                    <Box maxWidth={500}>
                      <TextField
                        onChange={(e) => handleChange(e.target.value)}
                        fullWidth
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SvgIcon fontSize="small" color="action">
                                <SearchIcon />
                              </SvgIcon>
                            </InputAdornment>
                          )
                        }}
                        placeholder="Search Build"
                        variant="outlined"
                        onKeyPress={onKeyPress}
                      />
                    </Box>
                  </Grid>
                  <Grid alignItems="center" item xs={1}>
                    <Button
                      disabled={isLoading}
                      onClick={onSearchClick}
                      color="primary"
                      variant="contained"
                    >
                      Search
                    </Button>
                  </Grid>
                </Grid>
                <Grid item md={2} className={classes.sortContainer}>
                  <FormControl fullWidth>
                    <InputLabel>Sort by:</InputLabel>
                    <Select
                      value={sortDir}
                      label="Sort by:"
                      onChange={handleChangeSort}
                    >
                      <MenuItem value={'latest'}>Latest Build</MenuItem>
                      <MenuItem value={'asc'}>Ascending Price</MenuItem>
                      <MenuItem value={'desc'}>Descending Price</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Box>
        <Card className={classes.tableCard}>
          {isLoading ? (
            <Container
              style={{
                padding: '2rem',
                display: 'flex',
                width: '100%',
                justifyContent: 'center'
              }}
            >
              <CircularProgress />
            </Container>
          ) : (
            <Table>
              <TableRow>
                <TableCell>
                  <Typography className={classes.tableHeader}>
                    Build Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableHeader}>
                    Built By:
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography className={classes.tableHeader}>
                    Build Amount
                  </Typography>
                </TableCell>
              </TableRow>
              {buildListState?.[0]
                ? buildListState.map((item) => (
                    <TableRow key={item.build_name}>
                      <TableCell onClick={() => handleBuildPress(item)}>
                        <Button variant="text" endIcon={<LaunchOutlined />}>
                          <Typography style={{ display: 'inline-block' }}>
                            {item.build_name}
                          </Typography>
                        </Button>
                      </TableCell>
                      <TableCell>
                        <Typography>{item.built_by.full_name}</Typography>
                      </TableCell>
                      <TableCell>
                        <Grid container justifyContent="space-between">
                          <Grid item md className={classes.buildAmount}>
                            <Typography>{item.build_amount}</Typography>
                          </Grid>
                          <Grid item md className={classes.deleteButton}>
                            {canDeleteEcommSavedBuilds ? (
                              <Button
                                color="secondary"
                                onClick={() => handleOpenDeleteDialog(item.id)}
                              >
                                <DeleteOutline />
                              </Button>
                            ) : null}
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  ))
                : null}
            </Table>
          )}
        </Card>
      </Container>
      <Box display="flex" justifyContent="flex-end" p={2}>
        {(paginationMeta?.last_page || 0) > 1 ? (
          <Pagination
            count={paginationMeta?.last_page || 1}
            page={paginationMeta?.current_page || 1}
            onChange={handlePageChange}
          />
        ) : null}
      </Box>
      <AlertDialog
        isVisible={isDeleteDialogOpen}
        title="Warning!"
        subTitle="You are about to delete this build"
        customButtons={
          <>
            <Button color="secondary" onClick={handleCancelDelete}>
              Cancel
            </Button>
            <Button color="primary" onClick={handleDeleteBuild}>
              Confirm
            </Button>
          </>
        }
      />
    </Page>
  );
};
