import { cloneDeep } from 'lodash';
import { ListingStatusEnum } from 'src/enums';
import {
  GetInventoryResponse,
  Inventory,
  InventoryColumn,
  InventoryLocalColumnFilter,
  InventoryLocalFilter
} from 'src/types';
import { filterData, getNumberOrZero, objectMap } from 'src/utils';

export const isAllStatusSelected = (statuses?: string[]) => {
  if (!statuses) {
    return false;
  }
  const allOptions = [
    ListingStatusEnum.Available,
    ListingStatusEnum.Purchased,
    ListingStatusEnum.OnHold,
    ListingStatusEnum.Defective
  ];
  const allExist = allOptions.every((status: string) => {
    const index = statuses?.findIndex((x) => x === status);
    return index !== undefined && index > -1 ? true : false;
  });
  return allExist;
}

export const removeSortByOnOtherColumns = (
  filter: InventoryLocalFilter,
  excemptedCol: InventoryColumn
) => {
  const clonedFilter = cloneDeep(filter);
  const withoutSortBy = objectMap(
    clonedFilter,
    (v: InventoryLocalColumnFilter) => {
      if (v?.columnName === excemptedCol) {
        return v;
      } else {
        delete v?.sortBy;
        return v;
      }
    }
  );
  return withoutSortBy;
};

export const filterInventoryLocal = (
  getInventoryResponse: GetInventoryResponse,
  filter: InventoryLocalFilter
) => {
  const clonedInventoryItems = cloneDeep(getInventoryResponse?.items || []);
  const clonedFilter = cloneDeep(filter);

  const mappedFilter = objectMap(
    clonedFilter,
    (v: InventoryLocalColumnFilter) => v?.text
  );
  const result: Inventory[] = filterData(clonedInventoryItems, mappedFilter);

  const newTotalDP = result?.reduce(
    (a, c) => getNumberOrZero(c.dealers_price) + +a,
    0
  ) || 0;
  const newTotalRP = result?.reduce(
    (a, c) => getNumberOrZero(c.retail_price) + +a,
    0
  ) || 0;

  const newGross = newTotalRP - newTotalDP;

  const newResponse: GetInventoryResponse = {
    ...getInventoryResponse,
    items: result,
    total_dealers_price: newTotalDP,
    total_gross_price: newGross,
    total_retail_price: newTotalRP
  };
  return newResponse;
};

export const sortInventory = (
  getInventoryResponse: GetInventoryResponse,
  column: InventoryColumn,
  order: 'ASC' | 'DESC'
) => {
  const clonedInventoryItems = cloneDeep(getInventoryResponse?.items || []);

  if (order === 'ASC') {
    clonedInventoryItems.sort((a: any, b: any) => {
      if (
        a[column] === undefined ||
        a[column] === null ||
        b[column] === undefined ||
        b[column] === null
      ) {
        return -1;
      }
      if (typeof a[column] === 'number' || typeof b[column] === 'number') {
        return parseFloat(a[column]) - parseFloat(b[column]);
      }
      if (a[column]?.toUpperCase() > b[column]?.toUpperCase()) {
        return 1;
      }
      if (a[column]?.toUpperCase() < b[column]?.toUpperCase()) {
        return -1;
      }
      return 0;
    });
  } else {
    clonedInventoryItems.sort((a: any, b: any) => {
      if (
        a[column] === undefined ||
        a[column] === null ||
        b[column] === undefined ||
        b[column] === null
      ) {
        return -1;
      }
      if (typeof a[column] === 'number' || typeof b[column] === 'number') {
        return parseFloat(b[column]) - parseFloat(a[column]);
      }
      if (a[column]?.toUpperCase() < b[column]?.toUpperCase()) {
        return 1;
      }
      if (a[column]?.toUpperCase() > b[column]?.toUpperCase()) {
        return -1;
      }
      return 0;
    });
  }

  const newResponse: GetInventoryResponse = {
    ...getInventoryResponse,
    items: clonedInventoryItems
  };
  return newResponse;
};
