import { cloneDeep } from 'lodash';
import { ProductPartDetailsCategory } from 'src/types';
import { getCategoryFieldsViaName } from '../categories';

/**
 * Returns a transformed data to be consumed by CREATE for product detail.
 * This is in util because hooks cannot be used in slices
 * @param key cpu | gpu | any category
 * @returns data for READ product detail.
 */
export const transformToValidDataForCreateUtil = (
  key: ProductPartDetailsCategory,
  data: any = {}
) => {
  const fields = getCategoryFieldsViaName(key);
  let clonedData = cloneDeep(data);

  fields?.forEach((x) => {
    if (clonedData[x?.part_property]) {
      if (x?.unit) {
        clonedData[x?.part_property] = `${clonedData[x?.part_property]}${
          x?.unit
        }`;
      }
      if (x?.transformRule?.includes('all-caps')) {
        if (x?.type === 'multiple') {
          let propertyArr = clonedData[x?.part_property]?.split(',');
          propertyArr = propertyArr?.map((y: string) => y?.toUpperCase());
          propertyArr = propertyArr?.join();
          clonedData[x?.part_property] = propertyArr;
        } else {
          clonedData[x?.part_property] = `${
            clonedData[x?.part_property]
          }`?.toUpperCase();
        }
      }
      if (x?.transformRule?.includes('no-spaces')) {
        if (x?.type === 'multiple') {
          let propertyArr = clonedData[x?.part_property]?.split(',');
          propertyArr = propertyArr?.map((y: string) => y?.replace(/\s+/g, ''));
          propertyArr = propertyArr?.join();
          clonedData[x?.part_property] = propertyArr;
        } else {
          clonedData[x?.part_property] = `${
            clonedData[x?.part_property]
          }`?.replace(/\s+/g, '');
        }
      }
    }

    if (!clonedData[x?.part_property] && x?.type === 'boolean') {
      // some boolean might be undefined. but in reality they are false
      clonedData[x?.part_property] = false;
    }
  });
  return clonedData;
};

/**
 * Returns a transformed data to be consumed by UPDATE for product detail.
 * This is in util because hooks cannot be used in slices
 * @param key cpu | gpu | any category
 * @returns data for READ product detail.
 */
export const transformToValidDataForReadUtil = (
  key: ProductPartDetailsCategory,
  data: any = {}
) => {
  const fields = getCategoryFieldsViaName(key);
  let clonedData = cloneDeep(data);

  fields?.forEach((x) => {
    if (clonedData[x?.part_property]) {
      if (x?.unit) {
        const toBeDeletedStr = x.unit;
        const newStr = clonedData[x?.part_property].replace(toBeDeletedStr, '');
        clonedData[x?.part_property] = newStr;
      }
    }
    if (!clonedData[x?.part_property] && x?.type === 'boolean') {
      // some boolean might be undefined. but in reality they are false
      clonedData[x?.part_property] = false;
    }
  });
  return clonedData;
};

/**
 * Returns a transformed data to be consumed by UPDATE for product detail.
 * This is in util because hooks cannot be used in slices
 * @param key cpu | gpu | any category
 * @returns data for READ product detail.
 */
export const transformToValidDataForUpdateUtil = (data: any = {}) => {
  let clonedData = cloneDeep(data);
  const toBeDeletedProps = [
    'created_by',
    'created_date',
    'id',
    'product_name',
    'retail_price',
    'updated_by',
    'updated_date',
    'image_url_raw',
    'image_thumbnail_url_raw',
    'category'
  ];

  if (clonedData?.image_url && clonedData?.image_url_raw) {
    clonedData.image_url = clonedData.image_url_raw;
  }

  if (clonedData?.image_thumbnail_url && clonedData?.image_thumbnail_url_raw) {
    clonedData.image_thumbnail_url = clonedData.image_thumbnail_url_raw;
  }

  toBeDeletedProps.forEach((x) => delete clonedData[x]);

  return clonedData;
};
