import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  colors,
  Tooltip,
  Button
} from '@material-ui/core';
import { AlternatingColorTableRow, LinkComponent } from 'src/components';
import { copyToClipboard, formatCurrency } from 'src/utils';
import { OutOfStockProducts } from 'src/redux/slices/out-of-stocks-product/types';
import FileCopyIcon from '@material-ui/icons/FileCopy';

interface Props {
  className?: string;
  outOfStockProducts: any[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 10
  },
  priceInput: {
    height: 30
  },
  lightGrey: {
    backgroundColor: colors.blueGrey[50]
  },
  retailPriceCell: {
    width: 200
  }
}));

const component = ({ className, outOfStockProducts = [], ...rest }: Props) => {
  const classes = useStyles();

  const onHandleCopyProductName = (productName?: string) => {
    copyToClipboard(productName || '');
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell>Category</TableCell>
                <TableCell>Product Name</TableCell>
                <TableCell>Branches</TableCell>
                <TableCell>Out Of Stock At</TableCell>
                <TableCell>Retail Price</TableCell>
                <TableCell>Dealers Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {outOfStockProducts.map((item: OutOfStockProducts) => (
                <AlternatingColorTableRow hover key={item.id}>
                  <TableCell>{item?.category_name ?? '--'}</TableCell>
                  <TableCell>
                    <Button
                      style={{ padding: 0, margin: 0 }}
                      startIcon={<FileCopyIcon />}
                      onClick={() =>
                        onHandleCopyProductName(item?.product_name)
                      }
                    />
                    <LinkComponent
                      href={`/app/products/${item.id}`}
                      title={`${item?.product_name ?? '--'}`}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      padding: 0
                    }}
                  >
                    {item.recently_out_of_stocks?.map((prod, index) => (
                      <TableRow key={index}>
                        <TableCell>{prod?.branch_name}</TableCell>
                      </TableRow>
                    ))}
                  </TableCell>
                  <TableCell>
                    {item.recently_out_of_stocks?.map((prod, index) => (
                      <Tooltip
                        title={`${prod?.out_of_stock_date}` || ''}
                        key={index}
                      >
                        <TableRow key={index}>
                          <TableCell className={classes.tableRow}>
                            {prod?.out_of_stock_date_human_format}
                          </TableCell>
                        </TableRow>
                      </Tooltip>
                    ))}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(item?.retail_price) || '--'}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(item?.dealers_price) || '--'}
                  </TableCell>
                </AlternatingColorTableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const OutOfStockProduct = React.memo(component);
