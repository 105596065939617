import { Card, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { FC, memo } from 'react';
import { GetInventoryAuditLogDetailData } from 'src/redux/slices/inventory-audit-logs';

const useStyles = makeStyles((theme) => ({
  customerInfoBox: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginTop: '1.5rem'
  }
}));

interface Props {
  inventoryAuditDetails?: GetInventoryAuditLogDetailData;
}

const component: FC<Props> = ({ inventoryAuditDetails }) => {
  const classes = useStyles();

  return (
    <Card className={classes?.customerInfoBox}>
      <Grid container spacing={3}>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            IAL NO:
          </Typography>
          <Typography display="inline" style={{ marginLeft: 3 }}>
            {inventoryAuditDetails?.ial_no || '--'}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Audited Date:
          </Typography>
          <Typography display="inline" style={{ marginLeft: 3 }}>
            {inventoryAuditDetails?.audited_date || '--'}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Branch:
          </Typography>
          <Typography display="inline" style={{ marginLeft: 3 }}>
            {inventoryAuditDetails?.branch_name || '--'}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Audited By:
          </Typography>
          <Typography display="inline" style={{ marginLeft: 3 }}>
            {inventoryAuditDetails?.audited_by || '--'}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography display="inline" variant="h5">
            Category:
          </Typography>
          <Typography display="inline" style={{ marginLeft: 3 }}>
            {inventoryAuditDetails?.category || '--'}
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export const AuditLogGeneralInfo = memo(component);
