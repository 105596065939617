import Api from 'src/services/ApiService';

type CodeProductPayload = {
  product_id?: number;
};

const createCodeUrl = (payload: CodeProductPayload): any => {
  return Api.post(`/ecomm/internal/code_url/create`, payload);
};

export const EventCodeApi = {
  createCodeUrl
};
