import React, { useCallback, useMemo } from 'react';
import { TextField, makeStyles } from '@material-ui/core';

import { useProductPartDetailsLogic } from 'src/hooks';
import { PartFields, ProductPartDetailsCategory } from 'src/types';
import { colors } from 'src/constants';
import { Autocomplete } from '@material-ui/lab';

interface Props {
  productCategory: ProductPartDetailsCategory;
  field: PartFields<any>;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  uploadImageField: {
    borderWidth: 1,
    borderColor: colors.grey[800]
  }
}));

const component = ({ productCategory, field }: Props) => {
  const classes = useStyles();
  const {
    fieldError,
    setProductPartDetailsPropertyViaKey,
    getProductPartDetailsViaKey
  } = useProductPartDetailsLogic();

  const fieldErr = useCallback(
    (fieldArg) => {
      return fieldError(productCategory, fieldArg) ? true : false;
    },
    [fieldError, productCategory]
  );

  const detailsValue = useMemo(
    () => getProductPartDetailsViaKey(productCategory) || {},
    [getProductPartDetailsViaKey, productCategory]
  );

  const onChange = (newValue: string) => {
    setProductPartDetailsPropertyViaKey(
      productCategory,
      field?.part_property,
      newValue
    );
  };

  return (
    <div className={classes.uploadImageField}>
      <Autocomplete
        options={field?.options || []}
        freeSolo={!field?.strictOption || true} // intended reverse logic
        value={detailsValue[field?.part_property] || ''}
        onChange={(_, newValue) => onChange(newValue)}
        onInputChange={(_, newInputValue) => onChange(newInputValue)}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            fullWidth
            label={field?.label}
            name={field?.part_property}
            required={field?.required}
            error={fieldErr(field?.part_property)}
            type={field?.type}
            helperText={
              fieldError(productCategory, field?.part_property)?.error ||
              field?.hint
            }
          />
        )}
      />
    </div>
  );
};

export const ProductPartFieldInputOptions = React.memo(component);
