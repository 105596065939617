import { PackageDetailsData } from 'src/types';


export const isPackageCreateParamsValid = (data?: PackageDetailsData) => {
    if (!data?.title) {
        return 'Title required';
    }
    if (!data?.cover) {
        return 'Cover required';
    }
    if (!data?.description) {
        return 'Description required';
    }

};

export const isPackageUpdateParamsValid = (data?: PackageDetailsData) => {
    if (!data?.title) {
        return 'Title required';
    }
    if (!data?.cover) {
        return 'Cover required';
    }
    if (!data?.description) {
        return 'Description required';
    }
};
