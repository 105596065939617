import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { useStockTransferReceiver } from 'src/hooks/stock-transfer';
import { toInteger } from 'lodash';

const useStyles = makeStyles(() => ({
  table: {
    borderCollapse: 'collapse'
  },
  tableCell: {
    border: '1px solid #dddddd'
  },
  controls: {
    padding: '1rem',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end'
  }
}));

interface Props {
  isOpen: boolean;
  st_items?: any[];
  product_type?: string;
  branch_id?: number;
  product_id?: number;
  onHandleClose: () => void;
}

const component: FC<Props> = ({
  isOpen = false,
  product_type,
  branch_id,
  product_id,
  onHandleClose
}) => {
  const classes = useStyles();

  const {
    isSuccess,
    setIsSuccess,
    transferStockNonSnStNos,
    updateReceiveNonSerializedItems
  } = useStockTransferReceiver();

  const [selectedStValue, setSelectedStValue] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('0');

  const onHandleReceiveNoSnProduct = useCallback(() => {
    updateReceiveNonSerializedItems({
      st_no: selectedStValue,
      quantity: toInteger(quantity),
      product_type,
      branch_id,
      product_id
    });
  }, [
    branch_id,
    product_id,
    product_type,
    quantity,
    selectedStValue,
    updateReceiveNonSerializedItems
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedValue = event.target.value;
    setSelectedStValue(selectedValue);
    const selectedItem = transferStockNonSnStNos.find(
      (item) => item.st_no === selectedValue
    );
    setQuantity(selectedItem?.total?.toString() || '0');
  };

  const onHandleChangeQuantity = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setQuantity(event.target.value);
  };

  useEffect(() => {
    if (isSuccess) {
      onHandleClose();
      setIsSuccess(false);
    }
  }, [isSuccess, onHandleClose, setIsSuccess]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'md'} onClose={onHandleClose}>
      <IconButton
        style={{ position: 'absolute', top: 0, right: 0 }}
        aria-label="close"
        color="secondary"
        onClick={onHandleClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle id="form-dialog-title">
        {'NON SERIALIZE PRODUCT'}
      </DialogTitle>

      <DialogContent style={{ padding: '1rem' }}>
        <TableContainer component={Paper}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} width={'75%'}>
                  ST No
                </TableCell>
                <TableCell className={classes.tableCell} width={'25%'}>
                  Quantity
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transferStockNonSnStNos.map((nonSt) => (
                <TableRow key={nonSt?.st_no}>
                  <TableCell className={classes.tableCell}>
                    <div>
                      <Radio
                        checked={selectedStValue === nonSt.st_no}
                        onChange={handleChange}
                        value={nonSt.st_no}
                        name="stNo-radio-button"
                      />
                      {nonSt?.st_no}
                    </div>
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    {selectedStValue === nonSt?.st_no ? (
                      <TextField
                        id="outlined-qty"
                        label="qty"
                        type="number"
                        value={quantity}
                        onChange={onHandleChangeQuantity}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true
                        }}
                        inputProps={{
                          min: 0
                        }}
                      />
                    ) : (
                      0
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <Box className={classes.controls}>
        <Button
          variant="outlined"
          color="primary"
          style={{ marginRight: 10 }}
          onClick={onHandleClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onHandleReceiveNoSnProduct}
        >
          Receive
        </Button>
      </Box>
    </Dialog>
  );
};

export const NonSnReceivingModal = memo(component);
