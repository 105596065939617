import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  makeStyles
} from '@material-ui/core';
import { Customer, CustomInputEvent } from 'src/types';
import { useSnackBar } from 'src/hooks';
interface Props {
  className?: string;
  customer: Customer;
  onSaveDetails: (data: Customer) => void;
}

const useStyles = makeStyles(() => ({
  root: {},
  uploadImageButton: {
    marginRight: 20
  }
}));

const ProfileDetails = ({ className, customer, onSaveDetails }: Props) => {
  const classes = useStyles();
  const [customerDetail, setCustomerDetail] = useState<Customer>(customer);
  const snackBar = useSnackBar();

  const handleChange = (event: CustomInputEvent) => {
    setCustomerDetail({
      ...customerDetail,
      [event.target.name]: event.target.value
    });
  };

  // const onDeleteCustomer = async () => {
  // const response = await CustomerService.deleteCustomer(customer);
  // if (response?.success && response?.message) {
  //   alert(response.message);
  //   navigate(-1);
  // }
  // };

  const onSaveCustomer = async () => {
    if (customerDetail.contact_no?.length !== 11) {
      snackBar.show({
        severity: 'error',
        message: 'please input valid contact number'
      });
      return;
    }
    onSaveDetails(customerDetail);
  };

  useEffect(() => {
    setCustomerDetail(customer);
  }, [customer]);

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
    >
      <Card>
        <CardHeader title="Complete Customer Information" />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                helperText="Please specify the first name"
                label="First name"
                name="first_name"
                onChange={handleChange}
                required
                value={customerDetail.first_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Middle Name"
                name="middle_name"
                onChange={handleChange}
                value={customerDetail?.middle_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Last name"
                name="last_name"
                onChange={handleChange}
                required
                value={customerDetail?.last_name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Email Address"
                name="email"
                onChange={handleChange}
                value={customerDetail?.email}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="transaction ID"
                name="transaction_slug"
                onChange={handleChange}
                value={customerDetail?.transaction_slug}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Address"
                name="address"
                onChange={handleChange}
                value={customerDetail?.address}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                inputProps={{ maxLength: 11 }}
                helperText="Maximum of 11 characters only. e.g. 09174308787"
                type="tel"
                name="contact_no"
                variant="outlined"
                label="Contact No."
                onChange={handleChange}
                value={customerDetail?.contact_no}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                fullWidth
                label="Social Media"
                name="social_media"
                onChange={handleChange}
                value={customerDetail?.social_media}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                label="Notes about customer"
                name="notes"
                onChange={handleChange}
                value={customerDetail?.notes}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </CardContent>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button
            onClick={onSaveCustomer}
            color="primary"
            variant="contained"
            //need  to convert number to truthy values
            disabled={!!customerDetail?.is_ecomm_user}
          >
            Save details
          </Button>
        </Box>
      </Card>
    </form>
  );
};

export default ProfileDetails;
