import React, { useState, useEffect, useCallback, useMemo } from 'react';
import clsx from 'clsx';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import { localize } from 'src/constants';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { usePermissions, useSnackBar } from 'src/hooks';
import { Category, GetCategoryMainData } from 'src/types/category';
import { CheckBoxLabel, SelectImageButton } from 'src/components';
import { theme } from 'src/theme';
import {
  containsAlphabetsWithSpacesAndDashes,
  convertImageFileToBase64,
  isEmptyOrSpaces
} from 'src/utils';
import { useMainCategory } from 'src/hooks/category';
import { cloneDeep, isEqual, pick } from 'lodash';

const { actions: categoryActions } = slices.category;
interface Props {
  category: Category;
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100
  },
  imgPreview: {
    width: undefined,
    height: 70,
    marginRight: theme.spacing(1)
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
}));

const CategoryDetailView = ({
  // onChangeInput,
  className,
  category,
  ...rest
}: Props) => {
  const classes = useStyles();
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const { canEditCategory } = usePermissions();
  const { mainCategoryList, getMainCategory } = useMainCategory();

  const [origCategoryDetail, setOrigCategoryDetail] = useState<Category>(
    category
  );
  const [categoryDetail, setCategoryDetail] = useState<Category>(category);

  const isCheckBoxDataLoaded = useMemo(
    () =>
      categoryDetail.is_hidden_pricelist !== undefined &&
      categoryDetail.is_visible_sidedrawer !== undefined,
    [categoryDetail]
  );

  //check if any of the field change
  const isNoCategoryDetailChanges = useMemo(() => {
    return isEqual(
      pick(category, [
        'name',
        'img_url',
        'main_category',
        'icon_sidedrawer',
        'is_visible_sidedrawer',
        'is_hidden_pricelist'
      ]),
      pick(categoryDetail, [
        'name',
        'img_url',
        'main_category',
        'icon_sidedrawer',
        'is_visible_sidedrawer',
        'is_hidden_pricelist'
      ])
    );
  }, [category, categoryDetail]);

  const onSaveDetailPress = async () => {
    if (
      !categoryDetail?.name ||
      isEmptyOrSpaces(categoryDetail?.name) ||
      !containsAlphabetsWithSpacesAndDashes(categoryDetail?.name)
    ) {
      snackBar.show({
        severity: 'error',
        message: 'Please provide proper category name'
      });
      return;
    }
    if (!categoryDetail?.main_category) {
      snackBar.show({
        severity: 'error',
        message: 'Please set a main category'
      });
    }
    if (!categoryDetail.icon_sidedrawer) {
      snackBar.show({
        severity: 'error',
        message: 'Please set a side drawer icon'
      });
    }
    if (id && categoryDetail) {
      const response = unwrapResult(
        await dispatch(
          categoryActions.updateCategoryThunk({
            ...categoryDetail
            // target_data: null
          })
        )
      );

      if (response?.success && response?.message) {
        snackBar.show({ severity: 'success', message: response.message });
        getCategoryDetail();
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message
            ? response.message
            : localize.ERR_API_UNKNOWN
        });
      }
    }
  };

  const getCategoryDetail = useCallback(async () => {
    if (!id) {
      return;
    }
    const getCategoriesResponse = unwrapResult(
      await dispatch(categoryActions.getCategoryViaIdThunk(+id))
    );
    if (getCategoriesResponse?.originalData?.category) {
      const categoryData = cloneDeep(
        getCategoriesResponse.originalData.category
      );
      delete categoryData.target_data;

      setOrigCategoryDetail(categoryData);
      setCategoryDetail(categoryData);
    }
  }, [dispatch, id]);

  const onDeleteCategory = async () => {
    if (!id) {
      return;
    }
    const deleteCategoryResponse = unwrapResult(
      await dispatch(categoryActions.deleteCategoryThunk(+id))
    );
    if (deleteCategoryResponse?.success && deleteCategoryResponse?.message) {
      alert(deleteCategoryResponse.message);
      navigate(-1);
    }
  };

  const onImageSelected = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      setCategoryDetail((s) => ({ ...s, img_url: imgInBase64 }));
    }
  };

  const onIconSelected = async (img: File[]) => {
    if (img && img?.length > 0) {
      const firstFile = img[0];
      const imgInBase64: any = await convertImageFileToBase64(firstFile);
      setCategoryDetail((s) => ({ ...s, icon_sidedrawer: imgInBase64 }));
    }
  };

  const onNameChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { value } = e?.target;
    setCategoryDetail((s) => ({ ...s, name: value }));
  };

  const onToggleHideOnPriceList = (val: boolean) => {
    setCategoryDetail((s) => ({ ...s, is_hidden_pricelist: val }));
  };

  const handleIsVisibleChange = (val: boolean) => {
    setCategoryDetail((s) => ({ ...s, is_visible_sidedrawer: val }));
  };

  const onHandleChangeMainCategory = (
    event: React.ChangeEvent<{ name?: string; value: any }>
  ) => {
    const selectedValue = event.target.value;

    const selectedCategory = mainCategoryList.find(
      (item) => item.name === selectedValue
    );
    if (selectedCategory) {
      setCategoryDetail((prevState) => ({
        ...prevState,
        main_category: selectedCategory.name,
        main_category_id: selectedCategory.id
      }));
    }
  };

  useEffect(() => {
    getCategoryDetail();
    getMainCategory();
  }, [getCategoryDetail, getMainCategory]);

  return (
    <Box mt={2}>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item lg={9} md={9} xs={9} className={classes.row}>
              {origCategoryDetail?.img_url ? (
                <img
                  alt=""
                  className={classes.imgPreview}
                  src={origCategoryDetail?.img_url}
                />
              ) : null}

              <Box>
                <Typography
                  style={{ marginTop: 10 }}
                  color="textPrimary"
                  gutterBottom
                  variant="h3"
                >
                  {origCategoryDetail?.name
                    ? origCategoryDetail?.name
                    : category?.name}
                </Typography>
                <Typography color="textSecondary" variant="h6">
                  CATEGORY
                  {origCategoryDetail?.is_hidden_pricelist
                    ? ' HIDDEN'
                    : ' VISIBLE'}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {canEditCategory ? (
            <Box mt={2}>
              <Card elevation={0}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item md={6} xs={6}>
                      <TextField
                        fullWidth
                        required
                        name="categoryName"
                        variant="outlined"
                        label="Category Name"
                        onChange={onNameChange}
                        value={categoryDetail?.name || ''}
                      />
                    </Grid>
                    <Grid item md={6} xs={6} className={classes.row}>
                      <img
                        alt=""
                        className={classes.imgPreview}
                        src={categoryDetail?.img_url}
                      />
                      <SelectImageButton
                        containerStyle={{ width: '100%' }}
                        title="Select/ Update Category Icon"
                        multiple={false}
                        onImageSelected={onImageSelected}
                      />
                    </Grid>
                    <Grid item md={6}>
                      <FormControl style={{ width: 350 }}>
                        <InputLabel variant="outlined">
                          Main Category
                        </InputLabel>
                        <Select
                          name="main category"
                          defaultValue={''}
                          variant="outlined"
                          label="Product Type"
                          value={categoryDetail?.main_category ?? ''}
                          onChange={onHandleChangeMainCategory}
                        >
                          {mainCategoryList.map((item: GetCategoryMainData) => (
                            <MenuItem key={item.id} value={item.name}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item md={6} className={classes.row}>
                      <img
                        alt=""
                        className={classes.imgPreview}
                        src={categoryDetail?.icon_sidedrawer}
                      />
                      <SelectImageButton
                        containerStyle={{ width: '100%' }}
                        title="Select/ Update Side Drawer Icon"
                        multiple={false}
                        onImageSelected={onIconSelected}
                      />
                    </Grid>
                    <Grid item md={6} xs={6}>
                      {isCheckBoxDataLoaded ? (
                        <>
                          <CheckBoxLabel
                            onChange={onToggleHideOnPriceList}
                            label="Hide on website's price list"
                            checked={categoryDetail?.is_hidden_pricelist}
                          />
                          <CheckBoxLabel
                            onChange={handleIsVisibleChange}
                            label="Visible on Side Drawer?"
                            checked={categoryDetail?.is_visible_sidedrawer}
                          />
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </CardContent>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  paddingX={2}
                  mt={2}
                >
                  <Button
                    onClick={onDeleteCategory}
                    color="secondary"
                    variant="contained"
                  >
                    Delete Category
                  </Button>
                  <Button
                    onClick={onSaveDetailPress}
                    color="primary"
                    variant="contained"
                    disabled={isNoCategoryDetailChanges}
                  >
                    Save details
                  </Button>
                </Box>
              </Card>
            </Box>
          ) : null}
        </CardContent>
      </Card>
    </Box>
  );
};

export default CategoryDetailView;
