import React, { FC, memo, useCallback, useMemo, useState } from 'react';
import {
  Page as PDFPage,
  View,
  PDFViewer,
  Document,
  StyleSheet,
  PDFDownloadLink
} from '@react-pdf/renderer';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { ViewHeaderLogs } from './ViewHeaderLogs';
import { ViewTableItemLogs } from './ViewTableItemLogs';
import { TransferLogsSerialData } from 'src/redux/slices/transfer-stock-logs';
import { limitEncryptedPerPage, limitPerPage } from '../../constant';
import { cloneDeep } from 'lodash';
import { ViewFooterLog } from './ViewFooterLogs';
import HttpsIcon from '@material-ui/icons/Https';
import NoEncryptionIcon from '@material-ui/icons/NoEncryption';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  parentView: {
    flexDirection: 'column',
    padding: 5,
    margin: 5,
    width: '100%'
  },
  closeButton: {
    position: 'absolute',
    right: 5,
    top: 5
  },
  pdfDownloadButton: {
    color: 'white',
    padding: '1rem',
    backgroundColor: '#0c304a',
    fontFamily: 'Roboto',
    borderRadius: '5px'
  }
});

interface Props {
  isOpen: boolean;
  stNo?: string;
  stBarcode?: string;
  dateTransfer?: string;
  processedBy?: string;
  origin?: string;
  receiving?: string;
  transferLogs?: TransferLogsSerialData[];
  onHandleCloseDialog: () => void;
}

const component: FC<Props> = ({
  isOpen,
  transferLogs,
  stNo,
  stBarcode,
  dateTransfer,
  processedBy,
  origin,
  receiving,
  onHandleCloseDialog
}) => {
  const [isEncrypted, setIsEncrypted] = useState<boolean>(false);

  const formattedDate = useMemo(() => {
    const date = new Date(dateTransfer || '');
    const dateResult = date.toLocaleDateString();
    return dateResult;
  }, [dateTransfer]);

  const logsPerPage: TransferLogsSerialData[] = useMemo(() => {
    const clonedTransferLogs = cloneDeep(transferLogs);

    if (!clonedTransferLogs) {
      return [];
    }

    let countWithData = 0;
    clonedTransferLogs.forEach((log) => {
      if (log.encrypted_serial_no?.includes('data')) {
        countWithData += 1;
      }
    });

    const itemLimitPerPage =
      isEncrypted && countWithData > 4 ? limitEncryptedPerPage : limitPerPage;

    return clonedTransferLogs.reduce((accumulator: any, value, index) => {
      const pageIndex = Math.floor(index / itemLimitPerPage);
      accumulator[pageIndex] = accumulator[pageIndex] || [];
      accumulator[pageIndex].push(value);
      return accumulator;
    }, []);
  }, [isEncrypted, transferLogs]);

  const RenderDocument = useCallback(() => {
    return (
      <Document title={'Transfer Stock Logs'}>
        {logsPerPage.map((itemLogs: any, index) => (
          <PDFPage size={[842, 595]} style={styles.page} key={index}>
            <View style={styles.parentView} wrap>
              <ViewHeaderLogs
                stNo={stNo}
                st_no_barcode={stBarcode}
                dateTransfer={formattedDate}
                origin={origin}
                receiving={receiving}
              />
              <ViewTableItemLogs
                encrpytedFile={isEncrypted}
                transferLogsItem={itemLogs || []}
              />
              <ViewFooterLog
                processedBy={processedBy}
                currentPage={index + 1} // plus 1 because index start in 0
                lastPage={logsPerPage.length}
              />
            </View>
          </PDFPage>
        ))}
      </Document>
    );
  }, [
    formattedDate,
    isEncrypted,
    logsPerPage,
    origin,
    processedBy,
    receiving,
    stBarcode,
    stNo
  ]);

  return (
    <Dialog
      maxWidth={'lg'}
      fullWidth
      open={isOpen}
      onClose={onHandleCloseDialog}
    >
      <DialogTitle>
        <Typography variant="h4">Transfer Stock Logs</Typography>
        <IconButton
          aria-label="close"
          style={styles.closeButton}
          color="secondary"
          onClick={onHandleCloseDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            padding: '1rem',
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <Tooltip
            title={
              isEncrypted ? 'Decrypt transfer logs' : 'Encrypt transfer logs'
            }
          >
            <Button
              variant="outlined"
              onClick={() => setIsEncrypted(!isEncrypted)}
              style={{ marginRight: 3 }}
            >
              {isEncrypted ? <HttpsIcon /> : <NoEncryptionIcon />}
            </Button>
          </Tooltip>
          <PDFDownloadLink
            document={<RenderDocument />}
            fileName={stNo}
            style={styles.pdfDownloadButton}
          >
            {({ loading }) =>
              loading
                ? `Loading document...`
                : isEncrypted
                ? `Download encrpyted file: ${stNo}`
                : `Download pdf file: ${stNo}`
            }
          </PDFDownloadLink>
        </Box>
        <PDFViewer
          width="100%"
          height="100%"
          style={{ height: '80vh', width: '100%' }}
        >
          <RenderDocument />
        </PDFViewer>
      </DialogContent>
    </Dialog>
  );
};

export const TransferLogPrintDialog = memo(component);
