import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  CommonFlashDealsResponse,
  FlashDealsPublishRequest
} from 'src/types';

export const flashDealsPublishThunk = createAsyncThunk<
  CommonAxiosResponse<CommonFlashDealsResponse>,
  FlashDealsPublishRequest,
  { state: RootState }
>('flashDeals/flashDealsPublishThunk', async (data) => {
  const response = await Api.patch(
    `/ecomm/internal/flash_deal/details/update/${data.id}`,
    data
  );
  return response;
});
