import React, { useCallback, useMemo } from 'react';
import { CardContent, Divider, Grid, makeStyles, Typography } from '@material-ui/core';

import { PartFields, ProductPartDetailsCategory } from 'src/types';
import { getCategoryFieldsViaName } from 'src/utils';
import { colors } from 'src/constants';
import { ProductPartImageFieldInput } from './ProductPartImageFieldInput';
import { ProductPartFieldInput } from './ProductPartFieldInput';
import { ProductPartMultipleField } from './ProductPartMultipleField';
import { ProductPartFieldInputOptions } from './ProductPartFieldInputOptions';
import { ProductPartFieldBoolean } from './ProductPartFieldBoolean';

interface Props {
  productCategory: ProductPartDetailsCategory;
  hasDivider?: boolean;
  canCheckValidation?: boolean;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  uploadImageField: {
    borderWidth: 1,
    borderColor: colors.grey[800]
  }
}));

const component = ({ productCategory, hasDivider = true }: Props) => {
  const classes = useStyles();

  const inputFields = useMemo(() => getCategoryFieldsViaName(productCategory), [
    productCategory
  ]);

  const RenderField = useCallback(
    ({ field }: { field: PartFields<any> }) => {
      /* Need another type for 'boolean' for true or false kind of field checkbox is optimal for this */
      if (field?.type === 'img') {
        return (
          <ProductPartImageFieldInput
            field={field}
            productCategory={productCategory}
          />
        );
      }
      if (field?.type === 'multiple') {
        return (
          <ProductPartMultipleField
            field={field}
            productCategory={productCategory}
          />
        );
      }
      if (field?.type === 'boolean') {
        return (
          <ProductPartFieldBoolean
            field={field}
            productCategory={productCategory}
          />
        );
      }
      if (field?.options && field?.options?.length > 0) {
        return (
          <ProductPartFieldInputOptions
            field={field}
            productCategory={productCategory}
          />
        );
      }
      return (
        <ProductPartFieldInput
          field={field}
          productCategory={productCategory}
        />
      );
    },
    [productCategory]
  );

  return (
    <div>
      <CardContent>
        <Typography className={classes.subHeader} variant="h6">
          {productCategory?.toUpperCase()} Product Details
        </Typography>
        <Grid container spacing={3}>
          {inputFields?.map((field, i) => (
            <Grid key={`${field?.part_property}-${i}`} item md={6} xs={12}>
              <RenderField field={field} />
            </Grid>
          ))}
        </Grid>
      </CardContent>
      {hasDivider ? <Divider /> : null}
    </div >
  );
};

export const ProductPartDetails = React.memo(component);
