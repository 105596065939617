import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
  CommonAxiosResponse,
  EcomOrdersGetRequest,
  EcomOrdersGetResponse
} from 'src/types';

export const getEcomOrderListThunk = createAsyncThunk<
  CommonAxiosResponse<EcomOrdersGetResponse>,
  EcomOrdersGetRequest,
  { state: RootState }
>('ecom/getEcomOrderListThunk', async (data, api) => {
  const request = api?.getState()?.ecomOrders.getEcomOrderListRequest;

  data = {
    ...request,
    ...data,
    limit: 50
  };

  const response = await Api.get('/ecomm/internal/order/get', data);
  return response;
});
