import React, { FC, memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import {
  warrantyTextStyles,
  warrantyViewStyles
} from 'src/views/transactions/TransactionPDF/styles';
import { RenderCurrency } from 'src/views/transactions/TransactionPDF/components';

const styles = StyleSheet.create({
  root: {
    borderWidth: 1
  },
  pageNoContainer: {
    flex: 3.5,
    borderLeftWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    marginLeft: 5,
    height: 20,
    justifyContent: 'center'
  },
  totalContainer: {
    flex: 2,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    marginRight: 5,
    height: 20,
    justifyContent: 'center'
  }
});

interface Props {
  pageIndex?: number;
  pageSize?: number;
  totalAmount?: number;
}

const component: FC<Props> = ({ pageIndex, pageSize, totalAmount }) => {
  return (
    <View>
      <View style={warrantyViewStyles.row}>
        <View style={styles.pageNoContainer}>
          <Text style={[warrantyTextStyles.textBold, { paddingLeft: 4 }]}>
            {`PAGE ${pageIndex} OF ${pageSize}`}
          </Text>
        </View>
        <View style={styles.totalContainer}>
          <View style={warrantyViewStyles.row}>
            {totalAmount === undefined ||
            totalAmount === 0 ||
            //checking if last page
            pageSize !== pageIndex ? null : (
              <Text style={[warrantyTextStyles.textBold, { paddingLeft: 4 }]}>
                {`TOTAL: `}
                <RenderCurrency amount={totalAmount || 0} />
              </Text>
            )}
          </View>
        </View>
      </View>
      <View
        style={{
          marginTop: 5,
          color: 'red',
          justifyContent: 'center'
        }}
      >
        <Text
          style={[
            warrantyTextStyles.text,
            { textAlign: 'center', fontSize: 6 }
          ]}
        >
          Note: this quotation is not a contract or a bill. it is our best guess
          at the total price for the service and goods described above. the
          customer will be billed after indicating acceptance of this quote.
          payment will be due prior to the delivery of service and goods.
        </Text>
      </View>
    </View>
  );
};

export const ViewQuotationSlipFooter = memo(component);
