import React, { FC, memo } from 'react';
import { useFormik } from 'formik';

import * as Yup from 'yup';
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import {
  CreateBlogCategoryPayload,
  GetBlogCategoryListData
} from 'src/redux/slices/blog-category';
import CloseIcon from '@material-ui/icons/Close';

interface Props {
  isOpen: boolean;
  action: 'create' | 'update';
  blogCategoryDetailsData?: GetBlogCategoryListData;
  handleClose: () => void;
  onHandleSubmit: (params: CreateBlogCategoryPayload) => void;
}

const component: FC<Props> = ({
  isOpen,
  action,
  blogCategoryDetailsData,
  handleClose,
  onHandleSubmit
}) => {
  const isActionCreate = action === 'create' ? true : false;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: blogCategoryDetailsData?.name || '',
      is_hidden:
        blogCategoryDetailsData?.is_hidden === '1' ? true : false || false
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      onHandleSubmit({
        name: values?.name,
        is_hidden: formik?.values?.is_hidden ? '1' : '0'
      });
      resetForm();
    }
  });

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogTitle>
        <Typography>
          {isActionCreate ? `Create Blog Category` : 'Blog Category'}
        </Typography>
        <IconButton
          aria-label="close"
          style={{ position: 'absolute', right: 5, top: 5 }}
          color="secondary"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={formik.handleSubmit} noValidate>
          <TextField
            fullWidth
            id="name"
            name="name"
            label="Blog Category Name"
            variant="outlined"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
            margin="normal"
          />
          <FormControlLabel
            control={
              <Checkbox
                id="isHidden"
                name="is_hidden"
                checked={formik.values.is_hidden}
                onChange={formik.handleChange}
              />
            }
            label="Is Hidden"
          />

          <Divider />

          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            style={{ marginTop: '1rem', marginBottom: '1rem' }}
            disabled={formik.isSubmitting || !formik.dirty}
          >
            {isActionCreate ? `Create` : 'Update'}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const validationSchema = Yup.object({
  name: Yup.string().required('Required'),
  is_hidden: Yup.boolean()
});

export const BlogCategoryForm = memo(component);
