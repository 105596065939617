import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectInventory = (state: RootState) => state.inventory;

const selectAllInventory = createSelector(
  selectInventory,
  (state) => state.inventory
);
const selectInventoryResponse = createSelector(
  selectInventory,
  (state) => state.inventoryResponse
);
const selectInventoryResponseItems = createSelector(
  selectInventory,
  (state) => state.inventoryResponse?.items || []
);

const selectIsSummaryFetched = createSelector(
  selectInventory,
  (state) => state.isSummaryFetched
);

const selectManufacturerID = createSelector(
  selectInventory,
  (state) => state.inventoryFilter?.manufacturer_id
);

const selectAllInventoryFilter = createSelector(
  selectInventory,
  (state) => state.inventoryFilter
);

const selectCategory = createSelector(
  selectInventory,
  (state) => state.inventoryFilter?.category_id ?? undefined
);

const selectMeta = createSelector(
  selectInventory,
  (state) => state.inventoryResponse?.meta ?? {}
);

const selectInventoryLocalFilter = createSelector(
  selectInventory,
  (state) => state.inventoryLocalFilter
);

const selectTotalDP = createSelector(
  selectInventory,
  (state) => state.inventoryTotals.total_dealers_price
);

const selectMargin = createSelector(
  selectInventory,
  (state) => state.inventoryTotals.margin_percentage
);

const selectTotalRP = createSelector(
  selectInventory,
  (state) => state.inventoryTotals.total_retail_price
);

const selectTotalGP = createSelector(
  selectInventory,
  (state) => state.inventoryTotals.total_gross_price
);

const selectStatus = createSelector(
  selectInventory,
  (state) => state.inventoryFilter?.status
);

const selectInventoryColumns = createSelector(
  selectInventory,
  (state) => state.inventoryColumns
);

export const selectors = {
  selectMeta,
  selectStatus,
  selectIsSummaryFetched,
  selectCategory,
  selectMargin,
  selectTotalRP,
  selectTotalGP,
  selectTotalDP,
  selectAllInventory,
  selectAllInventoryFilter,
  selectInventoryResponse,
  selectInventoryColumns,
  selectInventoryLocalFilter,
  selectInventoryResponseItems,
  selectManufacturerID
};
