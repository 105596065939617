import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Container, Grid, makeStyles } from '@material-ui/core';
import { useNavigate, useParams } from 'react-router-dom';

import { LoaderBar, Page } from 'src/components';
import Profile from './Profile';
import ProfileDetails from './ProfileDetails';
import Toolbar from './Toolbar';
import { Customer, Transaction } from 'src/types';
import { useSnackBar } from 'src/hooks';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import TransactionsTable from 'src/views/transactions/components/TransactionsTable';

const { actions: transactionActions } = slices.transaction;
const { actions: getCustomerDetailsAction } = slices.customerService;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

export default function CustomerProfileView() {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const { id } = useParams();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const isEcommUser = urlParams.get('is_ecomm_user');

  const [customerState, setCustomerState] = useState<Customer | undefined>();
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getCustomer = useCallback(async () => {
    setIsLoading(true);

    const getCustomerPayload = {
      id: id,
      is_ecomm_user: isEcommUser
    };
    const response = unwrapResult(
      await dispatch(
        getCustomerDetailsAction.getCustomerServiceThunk(getCustomerPayload)
      )
    );
    if (response?.success && response?.originalData?.customer) {
      setCustomerState(response?.originalData?.customer);
      setIsLoading(false);
    }
  }, [dispatch, id, isEcommUser]);

  const getCustomerTransactions = useCallback(async () => {
    if (!id) {
      return;
    }
    const customer_id = parseInt(id);
    if (isNaN(customer_id)) {
      return;
    }
    const getCustomerTransactionPayload = {
      customer_id,
      is_ecomm_user: isEcommUser
    };
    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(
        transactionActions.getCustomerTransactionsThunk(
          getCustomerTransactionPayload
        )
      ).finally(() => setIsLoading(false))
    );

    if (response.success && response.originalData?.transactions) {
      setTransactions(response.originalData?.transactions);
    }
  }, [dispatch, id, isEcommUser]);

  const onCustomerUpdate = async (data: Customer) => {
    setIsLoading(true);
    const response = unwrapResult(
      await dispatch(getCustomerDetailsAction.updateCustomerServiceThunk(data))
    );
    if (response?.success && response?.message) {
      snackBar.show({ severity: 'success', message: response.message });
      setCustomerState(response.originalData?.customer);
      setIsLoading(false);
    } else {
      snackBar.hide();
    }
  };

  const onAddTransaction = () => {
    navigate('/app/transaction/add', { state: customerState });
  };

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  useEffect(() => {
    getCustomerTransactions();
  }, [getCustomerTransactions]);

  return (
    <Page className={classes.root} title="Account">
      <Container maxWidth="lg">
        <Fragment>
          <LoaderBar isLoading={isLoading} />
          <Grid container spacing={3}>
            {customerState ? <Profile customer={customerState} /> : null}
            <Grid item xs={12}>
              {customerState ? (
                <ProfileDetails
                  customer={customerState}
                  onSaveDetails={(data: Customer) => onCustomerUpdate(data)}
                />
              ) : null}
            </Grid>
          </Grid>
          {customerState ? (
            <Toolbar onClick={onAddTransaction} isEcomm={isEcommUser} />
          ) : null}
          <TransactionsTable transactions={transactions} loading={isLoading} />
        </Fragment>
      </Container>
    </Page>
  );
}
