import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  CircularProgress,
  IconButton,
  makeStyles,
  Paper,
  TableCell,
  TextField
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';

import { CustomInputEvent, Quotation, QuotationProduct } from 'src/types';
import { Autocomplete } from '@material-ui/lab';
import { colors } from 'src/constants';
import { QuotationProductOption } from './QuotationProductOption';

interface QuotationProductCellProps {
  item: Quotation;
  branchId?: number;
  index: number;
  showSRP: boolean;
  customProductInput: string;
  productOptions: QuotationProduct[];
  isCategoryLoading?: boolean;
  onChangeProduct: (val: QuotationProduct | null, index: number) => void;
  onCustomProductInputChange: (e: CustomInputEvent, index: number) => void;
}

const width = 700;

const useStyles = makeStyles(
  (theme) => ({
    root: {
      width: width,
      padding: theme.spacing(1)
    },
    autoComplete: {
      maxWidth: width,
      borderBottomWidth: 1,
      borderBottomColor: 'pink'
    },
    input: {
      color: colors.blue[500],
      fontWeight: 500
    },
    optionListPrice: {
      fontWeight: 600
    }
  }),
  { index: 999 }
);

const ProductCell = ({
  index,
  item,
  showSRP,
  productOptions,
  isCategoryLoading,
  customProductInput,
  onCustomProductInputChange,
  onChangeProduct
}: QuotationProductCellProps) => {
  const inputRef = useRef([]);
  const classes = useStyles();

  const [productOptionsState, setProductOptionsState] = useState<
    QuotationProduct[]
  >(productOptions);

  const productCellOptionLabel = useCallback(
    (option: QuotationProduct) => {
      return <QuotationProductOption product={option} showSRP={showSRP} />;
    },
    [showSRP]
  );

  const onBlur = (e: CustomInputEvent) => {
    const { value } = e.target;
    const customInputMatch = productOptions?.filter(
      (x) => x.product_name?.toUpperCase() === value?.toUpperCase()
    );
    if (customInputMatch?.length > 0) {
      onChangeProduct(customInputMatch[0], index);
    }
  };

  //   const onClickLoadOptions = async () => {
  //     setIsLoading(true);
  //     if (item?.product?.category_id) {
  //       const response = unwrapResult(
  //         await dispatch(
  //           quotationActions.getQuotationsProductsThunk({
  //             category_ids: [item?.product?.category_id],
  //             branch_id: branchId,
  //             limit: 200,
  //             page: 1
  //           })
  //         )
  //       );

  //       if (response?.success) {
  //         setIsLoading(false);
  //         const meta = response?.originalData?.meta;

  //         if (meta && meta.last_page !== undefined && meta.last_page >= 2) {
  //           setIsLastPage(false);
  //           setProductOptionsState((prevState) => [
  //             ...prevState,
  //             ...(response?.originalData?.data || [])
  //           ]);
  //         } else {
  //           setIsLastPage(true);
  //         }
  //       }
  //     }
  //   };

  useEffect(() => {
    let categProducts = productOptions;

    if (item?.product?.category_id) {
      categProducts = productOptions?.filter(
        (x) => x.category_id === item?.product?.category_id
      );
    }

    setProductOptionsState(categProducts);
  }, [item.product.category_id, productOptions]);

  return (
    <TableCell
      hidden={productOptionsState?.length <= 0}
      onClick={(e) => e.stopPropagation()}
      className={classes.root}
    >
      {item?.custom ? (
        <TextField
          fullWidth
          onBlur={onBlur}
          value={customProductInput}
          onChange={(e) => onCustomProductInputChange(e, index)}
          label=""
          margin="none"
          variant="standard"
          InputProps={{
            className: classes.input,
            endAdornment: (
              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  onChangeProduct(null, index);
                }}
              >
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />
      ) : (
        <Autocomplete
          fullWidth
          size="small"
          id="product"
          ref={inputRef.current[index]}
          value={item?.product}
          // dont disable the input whenever loading
          // disabled={isCategoryLoading}
          className={classes.autoComplete}
          options={productOptionsState}
          getOptionSelected={(option, value) =>
            option?.product_id === value?.product_id
          }
          onChange={(_, newValue) => onChangeProduct(newValue, index)}
          getOptionLabel={(option) => option.product_name ?? ''}
          renderOption={productCellOptionLabel}
          onInputChange={() => {}}
          renderInput={(params) => (
            <TextField
              {...params}
              multiline
              label=""
              margin="none"
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {isCategoryLoading ? (
                      <CircularProgress color="primary" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                )
              }}
            />
          )}
          PaperComponent={({ children }) => {
            return <Paper>{children}</Paper>;
          }}
        />
      )}
    </TableCell>
  );
};

export const QuotationProductCell = React.memo(ProductCell);
