import React, { FC, memo } from 'react';
import { View, Text, StyleSheet } from '@react-pdf/renderer';
import {
  warrantyTextStyles,
  warrantyViewStyles
} from 'src/views/transactions/TransactionPDF/styles';
import { dateToday } from 'src/utils';

const styles = StyleSheet.create({
  root: {
    marginTop: 10,
    marginLeft: 5
  },

  firstColumn: {
    ...warrantyViewStyles.flex2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  firstColumnValue: {
    ...warrantyViewStyles.flex1,
    borderBottomWidth: 0.5,
    marginHorizontal: 5
  },
  secondColumn: {
    ...warrantyViewStyles.flex1,
    display: 'flex',
    flexDirection: 'row',
    marginLeft: 6
  },
  secondColumnValue: {
    ...warrantyViewStyles.flex1,
    borderBottomWidth: 0.5,
    marginHorizontal: 5
  },
  secondRow: {
    marginTop: 4
  }
});

interface Props {
  buildName?: string;
}

const component: FC<Props> = ({ buildName = 'PC Worth' }) => {
  const dateNow = dateToday('MMMM, d, yyyy');

  return (
    <View style={styles.root}>
      <View style={warrantyViewStyles.row}>
        <View style={styles.firstColumn}>
          <Text style={[warrantyTextStyles.textBold]}>Name:</Text>
          <Text style={[warrantyTextStyles.text, styles.firstColumnValue]}>
            {buildName}
          </Text>
        </View>

        <View style={styles.secondColumn}>
          <Text style={[warrantyTextStyles.textBold]}>Date:</Text>
          <Text style={[warrantyTextStyles.text, styles.secondColumnValue]}>
            {dateNow}
          </Text>
        </View>
      </View>
    </View>
  );
};

export const ViewQuotationBuildInfo = memo(component);
