import React, {
  FC,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Grid, Paper, Box, Button } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { TransferStockFilterAciton } from 'src/redux/slices/transfer-stock-logs';
import {
  SearchKeyword,
  SearchKeywordFilterGroup
} from 'src/components/filters';
import { BranchListDropDown } from 'src/components/dropdown';
import { PopoverNotification } from 'src/components/popover';
import { DatePickerRangeComponent } from 'src/components';
import { dateToday, transformerDateField } from 'src/utils';
import { searchFilter } from '../constant';
import { TransferLogsFilterActionEnum } from '../enum';

interface Props {
  isLoading: boolean;
  dateRangeRequire: boolean;
  onHandleApplyFilter: () => void;
  onHandleFilterAction: (action: TransferStockFilterAciton) => void;
  onHandleSearchBy: (keyword?: string) => void;
  onHandleBranchReceiver: (branch_id?: number) => void;
  onHandleDateFrom: (dateFrom?: string | undefined) => void;
  onHandleDateTo: (dateTo?: string | undefined) => void;
  onHandleCloseError: () => void;
}

const component: FC<Props> = ({
  isLoading,
  dateRangeRequire,
  onHandleApplyFilter,
  onHandleFilterAction,
  onHandleSearchBy,
  onHandleBranchReceiver,
  onHandleDateFrom,
  onHandleDateTo,
  onHandleCloseError
}) => {
  const dateRangeFieldRef = useRef(null);
  const [searchLabel, setSearchLabel] = useState<string | undefined>(
    searchFilter[0]
  );

  const [isClearSearch, setIsClearSearch] = useState<boolean>(false);
  const [fromDate, setFromDate] = useState<string | undefined>();
  const [toDate, setToDate] = useState<string | undefined>();

  const handleFilterAction = useCallback(
    (searchAction?: string) => {
      switch (searchAction) {
        case TransferLogsFilterActionEnum.Product:
          onHandleFilterAction('product');
          setIsClearSearch(true);
          break;
        case TransferLogsFilterActionEnum.DrNo:
          onHandleFilterAction('dr_no');
          setIsClearSearch(true);
          break;
        case TransferLogsFilterActionEnum.SerialNo:
          onHandleFilterAction('serial_no');
          setIsClearSearch(true);
          break;
        case TransferLogsFilterActionEnum.StNo:
          onHandleFilterAction('st_no');
          setIsClearSearch(true);
          break;
        default:
          onHandleFilterAction('product');
          setIsClearSearch(true);
      }
    },
    [onHandleFilterAction]
  );

  const onChangeFilterAction = (filter?: string) => {
    setSearchLabel(filter);
  };

  const onChangedDate = useCallback(
    (date: MaterialUiPickersDate | undefined, field: 'from' | 'to') => {
      const transformedDate = date ? transformerDateField(date) : undefined;

      if (field === 'to') {
        setToDate(transformedDate);
        onHandleDateTo(transformedDate);
      }
      if (field === 'from') {
        setFromDate(transformedDate);
        onHandleDateFrom(transformedDate);
      }
    },
    [onHandleDateFrom, onHandleDateTo]
  );

  const onHandleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter' && !isLoading) {
      onHandleApplyFilter();
    }
  };

  useEffect(() => {
    handleFilterAction(searchLabel);
  }, [handleFilterAction, searchLabel]);

  return (
    <Paper style={{ padding: '2rem' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={12}>
          <SearchKeywordFilterGroup
            filterOptions={searchFilter}
            onHandleFilterSearhBy={(filter?: string) =>
              onChangeFilterAction(filter)
            }
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <div onKeyDown={(event) => onHandleKeyDown(event)}>
            <SearchKeyword
              isClearEvent={isClearSearch}
              searchLabel={`Search By ${searchLabel}`}
              onHandleSearchKeyword={(keyword: string) =>
                onHandleSearchBy(keyword)
              }
              isDisable={isLoading}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} lg={6}>
          <BranchListDropDown
            branchLabel="Branch Receiver"
            isElevated={false}
            paperSpace={0}
            onHandleBranchChange={(branchIds?: number[]) =>
              onHandleBranchReceiver(
                //We are sure that it only contain 1 value
                branchIds && branchIds.length > 0 ? branchIds[0] : undefined
              )
            }
          />
        </Grid>

        <Grid xs={12} md={12} lg={12}>
          <Box
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            flexDirection={'column'}
          >
            <div ref={dateRangeFieldRef} />
            <DatePickerRangeComponent
              title="Date Transfer Range"
              fromDateMax={dateToday()}
              fromDateValue={fromDate || null}
              toDateValue={toDate || null}
              dateToLabel="End Date"
              dateFromLabel="Start Date"
              toDateMin={fromDate}
              onChangeToDate={(date) => onChangedDate(date, 'to')}
              onChangeFromDate={(date) => onChangedDate(date, 'from')}
            />
            <Box sx={{ mt: '1.5rem' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={onHandleApplyFilter}
                disabled={isLoading}
              >
                Apply Filter
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <PopoverNotification
        severity={'error'}
        textInfo="Transfer Date Range is Required"
        isOpen={dateRangeRequire}
        anchorRef={dateRangeFieldRef.current ?? undefined}
        onHandleClose={onHandleCloseError}
      />
    </Paper>
  );
};

export const TransferStockLogToolBar = memo(component);
