import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectBlogs = (state: RootState) => state.blogs;

const selectBlogListLoading = createSelector(
  selectBlogs,
  (state) => state?.getBlogListLoading
);

const selectBlogDetailsLoading = createSelector(
  selectBlogs,
  (state) => state?.getBlogDetailsLoading
);

const selectBlogList = createSelector(
  selectBlogs,
  (state) => state?.getBlogListResponse?.data
);

const selectBlogListMeta = createSelector(
  selectBlogs,
  (state) => state?.getBlogListResponse?.meta
);

const selectBlogDetails = createSelector(
  selectBlogs,
  (state) => state?.getBlogDetails
);

const selectCurrentPage = createSelector(
  selectBlogs,
  (state) => state?.getBlogListResponse?.meta?.current_page
);

export const selectors = {
  selectBlogListLoading,
  selectBlogList,
  selectBlogListMeta,
  //===blog details===
  selectBlogDetailsLoading,
  selectBlogDetails,
  selectCurrentPage
};
