import React, { CSSProperties, useCallback } from 'react';
import Modal from '@material-ui/core/Modal';
import { Box, Button, makeStyles, Typography } from '@material-ui/core';

import DoneAllIcon from '@material-ui/icons/DoneAll';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { CheckBoxLabel } from 'src/components';
import { SalesColumn } from 'src/types';
import { salesColumnsLocal } from 'src/constants';

const { actions: salesActions, selectors: salesSelectors } = slices.sales;

const useStyles = makeStyles((theme) => ({
  body: {
    position: 'absolute',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
    borderRadius: 8
  },
  filter: {
    marginTop: 20,
    marginBottom: 20
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  footer: {
    marginTop: 20
  }
}));

const bodyStyle: CSSProperties = {
  top: '20%'
};

const modalStyle: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
};

interface Props {
  visible: boolean;
  onHandleClose: () => void;
}

export const SalesColumnsFilterModal = ({ visible, onHandleClose }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const salesColumns = useAppSelector(salesSelectors.selectSalesColumns);

  const onToggleAll = () => {
    dispatch(salesActions.showAllColumns());
  };

  const onToggleItem = (item: SalesColumn) => {
    dispatch(salesActions.toggleSalesColumn(item));
  };

  const onClose = () => {
    onHandleClose();
  };

  const isColumnChecked = useCallback(
    (columnValue: SalesColumn) => (salesColumns[columnValue] ? true : false),
    [salesColumns]
  );

  return (
    <Modal
      disableBackdropClick
      open={visible}
      onClose={onHandleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={modalStyle}
    >
      <div style={bodyStyle} className={classes.body}>
        <div className={classes.titleContainer}>
          <Typography variant="h4" color="textPrimary">
            Sales Columns Filter
          </Typography>
          <Button endIcon={<DoneAllIcon />} onClick={onToggleAll}>
            Toggle All
          </Button>
        </div>
        {salesColumnsLocal?.map((column, i) => (
          <CheckBoxLabel
            key={column?.value || i}
            label={column?.name}
            checked={isColumnChecked(column.value)}
            onChange={() => onToggleItem(column.value)}
          />
        ))}
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          className={classes.footer}
        >
          <Button
            fullWidth
            onClick={onClose}
            color="primary"
            variant="outlined"
          >
            Close
          </Button>
        </Box>
      </div>
    </Modal>
  );
};
