import debounce from 'lodash/debounce';
import { useCallback, useEffect, useState } from 'react';

const useWindowSize = () => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const windowListener = useCallback(
    debounce(() => {
      if (window) {
        setWidth(window?.innerWidth);
        setHeight(window?.innerHeight);
      }
    }, 250),
    []
  );

  useEffect(() => {
    if (window) {
      setWidth(window?.innerWidth);
      setHeight(window?.innerHeight);
      window.addEventListener('resize', windowListener);
    }
    return () => {
      windowListener.cancel();
      window && window.removeEventListener('resize', windowListener);
    };
  }, [windowListener]);


  return {
    width,
    height
  };
};

export default useWindowSize;
