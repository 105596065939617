import React, { useMemo } from 'react';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography
} from '@material-ui/core';
import { CustomInputEvent, EcomOrderDetails } from 'src/types';
import { DeliveryOptionsEnum } from 'src/enums';
import { usePermissions } from 'src/hooks';
import { generateGoogleMapUrl, openInNewTab } from 'src/utils';
import { LinkComponent } from 'src/components';
import { colors, deliveryOptions, paymentOptions } from 'src/constants';
import { getTechOutShippingFeesBreakdown } from 'src/utils/techout';

import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

interface Props {
  className?: string;
  isShippingPaid: boolean;
  orderDetails?: EcomOrderDetails;
  onDeliveryInfoDetailChange: (event: CustomInputEvent) => void;
  onHandleShippingFeePaid: (isPaid: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(2)
  },
  formControl: {
    marginBottom: theme.spacing(2)
  }
}));

const component = ({
  orderDetails,
  isShippingPaid,
  onDeliveryInfoDetailChange,
  onHandleShippingFeePaid
}: Props) => {
  const classes = useStyles();
  const { permissionChecker } = usePermissions();
  const canEditEcomOrder = permissionChecker('can_edit_ecom_order');

  const handleChange = (event: CustomInputEvent) => {
    onDeliveryInfoDetailChange(event);
  };

  const googleMapsUrl = useMemo(() => {
    if (!orderDetails?.latitude || !orderDetails?.longitude) {
      return '';
    }
    const url = generateGoogleMapUrl(
      `${orderDetails?.latitude}`,
      `${orderDetails?.longitude}`
    );
    return url;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  const isEcomOrderSRP = useMemo(() => {
    // Check if the ecom order has 5% on its shipping fee
    // if id is above 359 and mode_of_payment="Paymongo"
    const latestEcomIDBeforeSRPDeployment = 359;
    const latestEcomIDNow = orderDetails?.id || 0;
    const isPaymongo = orderDetails?.mode_of_payment === 'Paymongo';

    if (latestEcomIDNow > latestEcomIDBeforeSRPDeployment && isPaymongo) {
      return true;
    }
    return false;
  }, [orderDetails]);

  const orderDetailsShippingFee = useMemo(() => {
    let initialSFAmount = orderDetails?.shipping_fee || 0;

    if (isEcomOrderSRP) {
      // const discountRate = 0.05; // SRP in decimal form (5%) <-- orig pagisipan later pano compute
      const discountRate = 0.12;
      const magicNumber = 1 + discountRate;
      initialSFAmount = (orderDetails?.shipping_fee || 0) / magicNumber;
    }
    return initialSFAmount;
  }, [isEcomOrderSRP, orderDetails]);

  // eslint-disable-next-line no-unused-vars
  const techOutShippingFeeBreakdown = useMemo(() => {
    if (orderDetails?.delivery_option === DeliveryOptionsEnum.TechOut) {
      return getTechOutShippingFeesBreakdown(orderDetailsShippingFee);
    } else {
      return undefined;
    }
  }, [orderDetails, orderDetailsShippingFee]);

  const onHandleShippingPaidStatus = () => {
    onHandleShippingFeePaid(!isShippingPaid);
  };

  return (
    <Card className={classes.root}>
      <CardHeader title="Delivery Information" />
      <Divider />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <TextField
              disabled={!canEditEcomOrder}
              fullWidth
              name="province"
              label="Province"
              onChange={handleChange}
              value={orderDetails?.province || ''}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControl
              disabled={!canEditEcomOrder}
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel>Delivery Option</InputLabel>
              <Select
                defaultValue=""
                color="primary"
                name="Delivery_option"
                label="delivery_option"
                value={orderDetails?.delivery_option || ''}
                onChange={(e: any) => handleChange(e)}
              >
                {deliveryOptions?.map((item) => (
                  <MenuItem key={item?.value} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12}>
            <FormControl
              disabled={!canEditEcomOrder}
              fullWidth
              variant="outlined"
              className={classes.formControl}
            >
              <InputLabel>Payment Method</InputLabel>
              <Select
                defaultValue=""
                name="mode_of_payment"
                label="mode_of_payment"
                color="primary"
                value={orderDetails?.mode_of_payment || ''}
                onChange={(e: any) => handleChange(e)}
              >
                {paymentOptions?.map((item) => (
                  <MenuItem key={item?.value} value={item?.value}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item md={6} xs={12}>
            <TextField
              disabled={!canEditEcomOrder}
              multiline
              fullWidth
              rows={1}
              name="delivery_address"
              label="Additional Address Information"
              onChange={handleChange}
              value={orderDetails?.delivery_address || ''}
              variant="outlined"
            />
          </Grid>

          <Grid item md={6} xs={12}>
            <Typography display="inline" variant="h5">
              Google Formatted Address:
              <Typography display="inline" variant="body1">
                {` ${orderDetails?.google_formatted_address ?? ''}`}
              </Typography>
            </Typography>
          </Grid>

          {googleMapsUrl ? (
            <Grid item md={6} xs={12}>
              <Typography display="inline" variant="h5">
                Google Map URL:{' '}
                <LinkComponent
                  openInNewTab
                  href={googleMapsUrl}
                  title={googleMapsUrl}
                  onClick={() => openInNewTab(googleMapsUrl)}
                  extraProps={{
                    style: { textDecoration: 'underline', color: colors.link }
                  }}
                />
              </Typography>
            </Grid>
          ) : null}
          <Grid item md={12}>
            <Tooltip
              title={
                isShippingPaid
                  ? 'Toggle to shipping fee not paid'
                  : 'Toggle to confirm shipping fee payment'
              }
            >
              <Button
                variant="outlined"
                name="shipping_fee_paid_at"
                startIcon={
                  isShippingPaid ? <AttachMoneyIcon /> : <MoneyOffIcon />
                }
                color="inherit"
                style={{
                  color: isShippingPaid ? colors.green[700] : colors.red[700]
                }}
                onClick={onHandleShippingPaidStatus}
              >
                {isShippingPaid
                  ? ' SHIPPING FEE PAID'
                  : 'CONFIRM SHIPPING FEE PAYMENT'}
              </Button>
            </Tooltip>
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            style={{ flexDirection: 'column', display: 'flex' }}
          >
            {orderDetails?.delivery_option === DeliveryOptionsEnum.TechOut ? (
              <div style={{ flexDirection: 'column', display: 'flex' }}>
                {/* 85 */}
                {/* <Typography display="inline" variant="h5">
                  Shipping Fee (Rider):
                  <Typography display="inline" variant="body1">
                    {` ${formatCurrency(
                      techOutShippingFeeBreakdown?.forRider
                    )}`}
                  </Typography>
                </Typography> */}

                {/* 7.5 */}
                {/* <Typography display="inline" variant="h5">
                  Shipping Fee (Tech Out):
                  <Typography display="inline" variant="body1">
                    {` ${formatCurrency(
                      techOutShippingFeeBreakdown?.forTechOut
                    )}`}
                  </Typography>
                </Typography> */}

                {/* 7.5 */}
                {/* <Typography display="inline" variant="h5">
                  Shipping Fee (PC Worth):
                  <Typography display="inline" variant="body1">
                    {` ${formatCurrency(
                      techOutShippingFeeBreakdown?.forPCWorth
                    )}`}
                  </Typography>
                </Typography> */}
              </div>
            ) : null}

            {/* 7.5 */}
            {/* <Typography display="inline" variant="h5">
              Shipping Fee (Total):
              <Typography display="inline" variant="body1">
                {` ${formatCurrency(orderDetailsShippingFee)}`}
              </Typography>
            </Typography> */}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const DeliveryInfo = React.memo(component);
