import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PcPackageState } from 'src/types'
import * as thunks from './thunks';

const initialState: PcPackageState = {
    pcPackageListItems: [],
    isListLoading: false,
    packagePayload: {},
    pcPackageDetails: {},
    pcPackagePagination: {}
};

const slice = createSlice({
    name: 'pc-package',
    initialState,
    reducers: {
        updatePackageKeywordRequest: (
            state,
            { payload }: PayloadAction<string>
        ) => {
            state.packagePayload = {
                ...state.packagePayload,
                page: 1,
                keyword: payload || ''
            }
        },

    },
    extraReducers(builder) {
        //for getting the package list
        builder.addCase(thunks.getPcPackageListThunk.pending, (state) => {
            state.isListLoading = true;
        });
        builder.addCase(thunks.getPcPackageListThunk.rejected, (state) => {
            state.isListLoading = true;
        });
        builder.addCase(thunks.getPcPackageListThunk.fulfilled, (state, { payload }) => {
            state.isListLoading = false;
            state.pcPackageListItems = payload?.originalData?.data || [];
            state.pcPackagePagination = payload?.originalData?.meta || {}
        });

        //for getting the package details
        builder.addCase(thunks.getPackageDetailsThunk.pending, (state) => {
            state.isListLoading = true;
        });
        builder.addCase(thunks.getPackageDetailsThunk.rejected, (state) => {
            state.isListLoading = true;
        });
        builder.addCase(thunks.getPackageDetailsThunk.fulfilled, (state, { payload }) => {
            state.isListLoading = false;
            state.pcPackageDetails = payload?.originalData?.data || {};
        })

    }
});

export const reducer = slice.reducer;

export const actions = {
    ...slice.actions,
    ...thunks
};
