import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse, Gallery, GalleryPhotos, UpdateGalleryResponse } from 'src/types';
import {
  chunkArrayInGroups,
  forEachPromise,
  galleryPhotosArrToStrArr
} from 'src/utils';
import { galleryDetailsToUpdateTransformer } from 'src/utils/gallery/galleryDetailsToUpdate';

const uploadImagesOnUpdate = async (
  imgsToBeUploaded: GalleryPhotos[],
  gallery_id: number
) => {
  const uploadImgStrArr = galleryPhotosArrToStrArr(imgsToBeUploaded);
  const uploadByTwo = chunkArrayInGroups(uploadImgStrArr, 2);

  return forEachPromise(uploadByTwo, (item) => {
    return new Promise((resolve) => {
      process.nextTick(() => {
        Api.post(`/gallery/photo/upload/create`, { gallery_id, photos: item })
          .then(() => resolve(true))
          .catch(() => resolve(true));
      });
    });
  });
};

const deleteImagesOnUpdate = async (
  imgsToBeDeleted: GalleryPhotos[],
  gallery_id: number
) => {
  // Intended any but is really number[]
  const imgsIdToBeDeleted: any[] = imgsToBeDeleted
    ?.map((y) => y?.id)
    ?.filter((x) => x !== null || x !== undefined);
  const uploadByTwo = chunkArrayInGroups(imgsIdToBeDeleted, 2);

  return forEachPromise(uploadByTwo, (item) => {
    return new Promise((resolve) => {
      process.nextTick(() => {
        Api.delete(`/gallery/photo/delete/${gallery_id}`, {
          gallery_id,
          gallery_photo_ids: item
        })
          .then(() => resolve(true))
          .catch(() => resolve(true));
      });
    });
  });
};

export const galleryUpdateThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateGalleryResponse>,
  Gallery,
  { state: RootState }
>('gallery/galleryUpdateThunk', async (data) => {
  data = galleryDetailsToUpdateTransformer(data);
  const updateDateRes = await Api.patch(`/gallery/update/${data?.id}`, data);
  const imgs = data?.photos || [];

  if (data?.id && updateDateRes?.success && imgs && imgs?.length > 0) {
    const filteredImgsToBeUploaded = imgs?.filter((x) => !x?.id);
    if (filteredImgsToBeUploaded?.length > 0) {
      await uploadImagesOnUpdate(filteredImgsToBeUploaded, data?.id);
    }

    const imgsToBeDeleted = imgs?.filter((x) => x?.action === 'delete');
    if (imgsToBeDeleted?.length > 0) {
      await deleteImagesOnUpdate(imgsToBeDeleted, data?.id);
    }
  }

  return updateDateRes;
});
