import React, { memo } from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';

import { TransferStockLogsData } from 'src/redux/slices/transfer-stock-logs';
import { TransferStockRow } from './TransferStockLogRow';

interface Props {
  className?: string;
  transferLogsData?: TransferStockLogsData[];
}

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  tableRow: {
    height: 35,
    padding: 30
  }
}));

const component = ({ className, transferLogsData, ...rest }: Props) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableRow}>
                <TableCell width={'5%'} />
                <TableCell>Status</TableCell>
                <TableCell>ST No.</TableCell>
                <TableCell>Transferred By</TableCell>
                <TableCell>Transferred Date</TableCell>
                <TableCell>Origin Branch</TableCell>
                <TableCell>Receiving Branch</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transferLogsData?.map((logs, index) => (
                <TransferStockRow key={index} logsData={logs} />
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

export const TransferStockLogsTable = memo(component);
