import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import AuthService from 'src/services/AuthService';

import { GetOAuthTokenResponse } from 'src/types';

export const getOAuthTokenThunk = createAsyncThunk<
  GetOAuthTokenResponse,
  undefined,
  { state: RootState }
>('user/createUser', async () => {
  const response = await AuthService.getOAuthToken();
  return response;
});
