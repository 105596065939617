import React, { FC, memo, useCallback, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import { GetBranchCategoryData } from 'src/types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { AlternatingColorTableRow } from 'src/components';
import { ProductByCategory } from './ProductByCategory';
import { useInventoryAudit } from 'src/hooks/inventory-audit';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Alert } from '@material-ui/lab';
import { slices, useAppSelector } from 'src/redux';
import { useSnackBar } from 'src/hooks';

const { selectors: categorySelectors } = slices.category;

interface Props {
  isOpen: boolean;
  onHandleClose: () => void;
}

const component: FC<Props> = ({ isOpen, onHandleClose }) => {
  const categories = useAppSelector(categorySelectors.selectCategories);
  const snackBar = useSnackBar();

  const {
    inventoryAuditProductListing,
    productsToBeAudited,
    commonAuditParams,
    isSuccess,
    setIsSuccess,
    handleSelectedAuditProducts,
    handleRemoveSelectedAuditProduct,
    getProductWithTotalAvailableItem
  } = useInventoryAudit();

  const categoryName = useMemo(() => {
    const category = categories.find(
      (category) => category.id === commonAuditParams?.category_id
    );
    return category ? category.name : null;
  }, [categories, commonAuditParams]);

  const onHandleAddProductToAudit = useCallback(
    (selectedProduct?: GetBranchCategoryData) => {
      if (selectedProduct) {
        handleSelectedAuditProducts(selectedProduct);
      }
    },
    [handleSelectedAuditProducts]
  );

  const onHandleRemoveProducttoAudit = useCallback(
    (prod_id?: number) => {
      if (prod_id) {
        handleRemoveSelectedAuditProduct(prod_id);
      }
    },
    [handleRemoveSelectedAuditProduct]
  );

  const onHandleAddToInventoryAuditList = useCallback(() => {
    if (productsToBeAudited && productsToBeAudited.length > 0) {
      const isExist = inventoryAuditProductListing?.some((item) => {
        return productsToBeAudited?.some(
          (itemToAudit) => itemToAudit?.product_id === item?.id
        );
      });

      if (isExist) {
        snackBar.show({
          severity: 'error',
          message:
            'Some items added here is already in audit listings, kindly remove the duplicates'
        });
        return;
      }
      //intended any there is unknown ts problem :<
      const filterArray: any[] = productsToBeAudited
        .map((prod) => prod.product_id)
        .filter((id: any) => id !== undefined);

      //fetch single product
      getProductWithTotalAvailableItem('single', {
        branch_id: commonAuditParams?.branch_id,
        category_id: commonAuditParams?.category_id,
        product_ids: filterArray
      });
    }
  }, [
    productsToBeAudited,
    inventoryAuditProductListing,
    getProductWithTotalAvailableItem,
    commonAuditParams,
    snackBar
  ]);

  const onHandleGetAllProductToBeAudit = useCallback(() => {
    //fetch all product
    getProductWithTotalAvailableItem('all', {
      branch_id: commonAuditParams?.branch_id,
      category_id: commonAuditParams?.category_id,
      product_ids: []
    });
  }, [commonAuditParams, getProductWithTotalAvailableItem]);

  useEffect(() => {
    if (isSuccess) {
      setIsSuccess(false);
      onHandleClose();
    }
  }, [isSuccess, onHandleClose, setIsSuccess]);

  return (
    <Dialog open={isOpen} fullWidth maxWidth={'md'} onClose={onHandleClose}>
      <IconButton
        style={{ position: 'absolute', top: 0, right: 0 }}
        aria-label="close"
        color="secondary"
        onClick={onHandleClose}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle
        id="select-product-dialog"
        style={{ borderBottom: '2px solid lightGray' }}
      >
        {'Select Product(s)'}
      </DialogTitle>

      <DialogContent>
        <Box
          mt={1}
          width={'100%'}
          sx={{ display: 'flex', flexDirection: 'row' }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} md={8}>
              <ProductByCategory
                onOptionSelected={(option?: GetBranchCategoryData) =>
                  onHandleAddProductToAudit(option)
                }
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={4}
              style={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <Button
                size="large"
                variant="contained"
                color="primary"
                onClick={onHandleGetAllProductToBeAudit}
              >{`Get all products for ${categoryName}`}</Button>
            </Grid>
          </Grid>
        </Box>

        <PerfectScrollbar style={{ marginTop: '1rem', minHeight: '50vh' }}>
          <TableContainer
            component={Paper}
            style={{ maxHeight: '50vh', overflow: 'auto' }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Product Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {productsToBeAudited && productsToBeAudited?.length <= 0 && (
                  <Alert severity="info">
                    Please use the search field to add products to be audited.
                  </Alert>
                )}
                {productsToBeAudited?.map((product) => (
                  <AlternatingColorTableRow key={product?.product_id}>
                    <TableCell>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          flexDirection: 'row'
                        }}
                      >
                        {product?.product_name}
                        <IconButton
                          color="secondary"
                          onClick={() => {
                            onHandleRemoveProducttoAudit(product?.product_id);
                          }}
                        >
                          <HighlightOffIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </AlternatingColorTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </PerfectScrollbar>

        <Box
          mt={3}
          mb={3}
          sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onHandleAddToInventoryAuditList}
            disabled={productsToBeAudited.length <= 0}
          >
            Add to list
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export const SelectProductAuditModal = memo(component);
