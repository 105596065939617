import React, { useEffect, useMemo, useState } from 'react';
import { Page } from 'src/components';
import {
  Box,
  Button,
  Container,
  LinearProgress,
  makeStyles,
  Typography
} from '@material-ui/core';
import { useInventoryAuditLogs } from 'src/hooks/inventory-audit';
import { useParams } from 'react-router-dom';
import { AuditLogGeneralInfo } from './component/AuditLogsGeneralInfo';
import { AuditRemarks } from './component/AuditRemarkts';
import { UpdateInventoryAuditsRemarksPayload } from 'src/redux/slices/inventory-audit-logs';
import { AuditDetailListing } from './component/AuditDetailListing';
import { Alert } from '@material-ui/lab';
import { usePermissions } from 'src/hooks';
import PrintIcon from '@material-ui/icons/Print';
import { PrintAuditLogs } from './print-logs';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const InventoryAuditLogsDetailsView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const {
    isLoading,
    inventoryAuditDetails,
    getInventoryAuditDetails,
    updateInventoryAuditRemarks
  } = useInventoryAuditLogs();
  const { canViewAuditLogs } = usePermissions();

  const [isPrintDialog, setIsPrintDialog] = useState<boolean>(false);

  const historyRemarks = useMemo(() => {
    return inventoryAuditDetails?.remarks;
  }, [inventoryAuditDetails]);

  const inventoryProductsWithIssue = useMemo(() => {
    return inventoryAuditDetails?.products_with_issue;
  }, [inventoryAuditDetails]);

  const inventoryProduct = useMemo(() => {
    return inventoryAuditDetails?.products;
  }, [inventoryAuditDetails]);

  const inventoryProductPdfData = useMemo(() => {
    const newAuditProduct = inventoryProduct?.reduce(
      (accumulator: any, current: any) => {
        const newProduct = inventoryProductsWithIssue?.find(
          (withIssues) => withIssues?.product_name === current?.product_name
        );

        if (newProduct) {
          accumulator.push({
            product_name: current?.product_name,
            missing_serial_nos: newProduct?.missing_serial_nos || '',
            audited_quantity: current?.audited_quantity,
            available_items_count: current?.available_items_count
          });
        } else {
          accumulator.push({
            product_name: current?.product_name,
            missing_serial_nos: 'No missing serial number',
            audited_quantity: current?.audited_quantity,
            available_items_count: current?.available_items_count
          });
        }
        return accumulator;
      },
      []
    );

    return newAuditProduct || [];
  }, [inventoryProduct, inventoryProductsWithIssue]);

  const onHandleSubmitRemarks = (
    params: UpdateInventoryAuditsRemarksPayload
  ) => {
    updateInventoryAuditRemarks(params);
  };

  const onHandlePrint = () => {
    setIsPrintDialog(true);
  };

  useEffect(() => {
    if (id) {
      getInventoryAuditDetails(id);
    }
  }, [getInventoryAuditDetails, id]);

  return (
    <Page title="Inventory Audit Logs Details" className={classes.root}>
      <Container maxWidth={false}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography color="textPrimary" gutterBottom variant="h3">
            Inventory Audit Logs Details
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            startIcon={<PrintIcon />}
            onClick={onHandlePrint}
          >
            Print Audited Products
          </Button>
        </Box>
        {isLoading && <LinearProgress />}
        <AuditLogGeneralInfo inventoryAuditDetails={inventoryAuditDetails} />
        {canViewAuditLogs && (
          <AuditRemarks
            ialNo={id || ''}
            historyRemarks={historyRemarks}
            onSubmitRemarks={(params: UpdateInventoryAuditsRemarksPayload) =>
              onHandleSubmitRemarks(params)
            }
          />
        )}

        {inventoryProductsWithIssue && inventoryProductsWithIssue.length > 0 && (
          <>
            <Alert severity="error">
              Discrepancies have been found in the audited logs. Please review
              the records.
            </Alert>
            <AuditDetailListing
              withIssue={true}
              inventoryAuditProductsWithIssue={inventoryProductsWithIssue}
            />
          </>
        )}
        {inventoryProduct && inventoryProduct.length > 0 && (
          <>
            {inventoryProduct.length > 0 &&
              inventoryProductsWithIssue &&
              inventoryProductsWithIssue?.length <= 0 && (
                <Alert severity="success">
                  All audited items have been reviewed and no discrepancies were
                  recorded.
                </Alert>
              )}
            <AuditDetailListing
              withIssue={false}
              inventoryAuditProducts={inventoryProduct}
            />
          </>
        )}
        <PrintAuditLogs
          isOpen={isPrintDialog}
          ial_no={id}
          onHandleCloseDialog={() => setIsPrintDialog(false)}
          auditLogs={inventoryProductPdfData}
        />
      </Container>
    </Page>
  );
};

export default InventoryAuditLogsDetailsView;
