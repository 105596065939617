import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';

import {
  Box,
  Button,
  Card,
  CardHeader,
  Container,
  Divider,
  makeStyles,
  Typography
} from '@material-ui/core';
import { LoaderBar, Page } from 'src/components';
import { slices, useAppDispatch } from 'src/redux';
import { useAlertGlobal, useSnackBar } from 'src/hooks';

import { CustomInputEvent, Gallery } from 'src/types';
import {
  GalleryCoverImageCardContent,
  GalleryGeneralInfoCardContent,
  GalleryImagesCardContent,
  GalleryProductsCardContent
} from '../component';
import { unwrapResult } from '@reduxjs/toolkit';
import { useNavigate, useParams } from 'react-router-dom';
import { isEqual } from 'lodash';
import { isGalleryUpdateParamsValid } from 'src/utils/gallery/galleryParamsValidation';
import { localize } from 'src/constants';
import { Alert } from '@material-ui/lab';
import usePrompt from 'src/utils/navigation-prompt';

const { actions: galleryActions } = slices.gallery;

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  saveBtn: {
    marginLeft: theme.spacing(2)
  }
}));

const component = ({ className, ...rest }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const snackBar = useSnackBar();
  const alertGlobal = useAlertGlobal();
  const navigate = useNavigate();
  const { id } = useParams();

  const [origGalleryDetails, setOrigGalleryDetails] = useState<Gallery>();
  const [galleryDetails, setGalleryDetails] = useState<Gallery>();
  const [loading, setLoading] = useState<boolean>(false);

  const nothingChanged = useMemo(
    () => isEqual(origGalleryDetails, galleryDetails),
    [galleryDetails, origGalleryDetails]
  );

  const getGalleryDetails = async () => {
    if (id) {
      setLoading(true);
      const response = unwrapResult(
        await dispatch(
          galleryActions.galleryGetViaIdThunk({ id: +id })
        ).finally(() => setLoading(false))
      );
      if (response?.success && response?.originalData?.data) {
        setGalleryDetails(response?.originalData?.data);
        setOrigGalleryDetails(response?.originalData?.data);
      }
    }
  };

  const onUpdateGallery = async () => {
    const errMsg = isGalleryUpdateParamsValid(galleryDetails);
    if (errMsg) {
      snackBar.show({
        severity: 'error',
        message: errMsg || 'Somethings Wrong'
      });
      return;
    }
    if (galleryDetails) {
      setLoading(true);
      snackBar.show({
        severity: 'info',
        message:
          'Updating gallery build.... This might take some time depending on images...'
      });
      const response = unwrapResult(
        await dispatch(
          galleryActions.galleryUpdateThunk(galleryDetails)
        ).finally(() => setLoading(false))
      );
      if (response.success) {
        snackBar.show({
          severity: 'success',
          message: 'Gallery updated successfully'
        });
        getGalleryDetails();
        return;
      }

      snackBar.show({
        severity: 'error',
        message: response?.message || localize.ERR_API_UNKNOWN
      });
    }
  };

  const onDeleteGalleryWarning = () => {
    alertGlobal.show({
      title: 'DELETE BUILD GALLERY ITEM',
      subtitle: 'Are you sure you want to delete this item?',
      buttons: [
        {
          text: 'Yes',
          onClick: () => {
            onDeleteGallery();
            alertGlobal.hide();
          },
          color: 'secondary'
        },
        {
          text: 'Cancel',
          onClick: () => alertGlobal.hide()
        }
      ]
    });
  };

  const onDeleteGallery = async () => {
    if (galleryDetails?.id) {
      setLoading(true);
      const response = unwrapResult(
        await dispatch(
          galleryActions.galleryDeleteThunk(galleryDetails?.id)
        ).finally(() => setLoading(false))
      );
      if (response.success) {
        navigate(-1);
        snackBar.show({
          severity: 'success',
          message: 'Gallery deleted successfuly'
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || localize.ERR_API_UNKNOWN
        });
      }
    }
  };

  const onChangeGeneralInfoInput = (e: CustomInputEvent) => {
    const { value, name } = e.target;
    setGalleryDetails((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  const onChangePropertyViaName = (value: any, name: string) => {
    const inputEvent: any = { target: { name, value } };
    onChangeGeneralInfoInput(inputEvent);
  };

  const onPublishToggle = async () => {
    if (id) {
      setLoading(true);
      const newStatus = !(galleryDetails?.published || false);
      const response = unwrapResult(
        await dispatch(
          galleryActions.galleryPublishThunk({ id: +id, status: newStatus })
        ).finally(() => setLoading(false))
      );
      if (response?.success) {
        snackBar.show({
          severity: 'success',
          message: response?.message || 'Successfully Published',
          useSound: true
        });
        getGalleryDetails();
        return;
      }
      snackBar.show({
        severity: 'error',
        message: 'Something went wrong in publishing',
        useSound: true
      });
    }
  };

  usePrompt(
    'Build Gallery is still updating / loading. Are you sure you want to leave?',
    loading
  );

  useEffect(() => {
    getGalleryDetails();
    // Intended disable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page className={classes.root} title="Gallery Details">
      <LoaderBar isLoading={loading} />
      {loading ? null : (
        <Alert severity={galleryDetails?.published ? 'info' : 'warning'}>
          <Typography variant="subtitle2">{`THIS BUILD IS ${
            galleryDetails?.published ? 'PUBLISHED' : 'NOT YET PUBLISHED'
          }`}</Typography>
          <Button
            size="small"
            variant="outlined"
            onClick={onPublishToggle}
            color={galleryDetails?.published ? 'secondary' : 'primary'}
          >
            {galleryDetails?.published ? 'UNPUBLISH' : 'PUBLISH'}
          </Button>
        </Alert>
      )}
      <Container maxWidth={false}>
        <Box mt={2}>
          <form
            autoComplete="off"
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <LoaderBar isLoading={false} />
              <CardHeader
                title="Gallery Details"
                subheader="The information of gallery."
              />

              <Divider />
              <GalleryGeneralInfoCardContent
                data={galleryDetails}
                onChangeInput={onChangeGeneralInfoInput}
              />

              <Divider />
              <GalleryProductsCardContent
                data={galleryDetails?.components}
                onComponentsChange={(products) =>
                  onChangePropertyViaName(products, 'components')
                }
              />

              <Divider />
              <GalleryCoverImageCardContent
                data={galleryDetails?.cover}
                onCoverImageChange={(imgStr) =>
                  onChangePropertyViaName(imgStr, 'cover')
                }
              />

              <Divider />
              <GalleryImagesCardContent
                data={galleryDetails?.photos}
                onPhotosChange={(imgs) =>
                  onChangePropertyViaName(imgs, 'photos')
                }
              />

              <Divider />
              <LoaderBar isLoading={loading} />
            </Card>
          </form>
        </Box>
        <div className={classes.btnContainer}>
          <Button
            fullWidth
            startIcon={<DeleteIcon />}
            onClick={onDeleteGalleryWarning}
            color="secondary"
            variant="outlined"
            disabled={loading}
          >
            Delete gallery
          </Button>
          <Button
            fullWidth
            disabled={nothingChanged || loading}
            startIcon={<SaveIcon />}
            onClick={onUpdateGallery}
            color="primary"
            className={classes.saveBtn}
            variant="contained"
          >
            Update gallery
          </Button>
        </div>
      </Container>
    </Page>
  );
};

export const GalleryDetailsView = React.memo(component);
