import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Card,
  Container,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { Page } from 'src/components';
import { useParams } from 'react-router-dom';
import { Permission, Role, UserBasicInfo } from 'src/types';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import UserDetailCard from '../users/detail/components/UserDetailCard';
import { useSnackBar } from 'src/hooks';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { multiBranchFeat } from 'src/constants/feature-toggle';
import useUserInfo from 'src/hooks/user/use-user-info';

const { actions: userActions, selectors: userSelectors } = slices.user;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

const AccountView = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();
  const { id } = useParams();
  const { userBranchOptions } = useUserInfo();

  const userDetails = useAppSelector(userSelectors.selectUserInfo);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getAccountDetails = useCallback(async () => {
    if (id) {
      const user_id = +id;
      if (isNaN(user_id)) {
        return;
      }
      setIsLoading(true);
      const response = unwrapResult(
        await dispatch(userActions.getUserThunk(user_id)).finally(() =>
          setIsLoading(false)
        )
      );
      if (response.success && response.originalData?.user) {
        dispatch(userActions.updateAccountInfo(response.originalData?.user));
      }
    }
  }, [dispatch, id]);

  const onUpdateAccount = async (user: UserBasicInfo) => {
    if (user?.id) {
      setIsLoading(true);
      const response = unwrapResult(
        await dispatch(userActions.updateOwnAccountThunk(user))
      );
      if (response?.success && response?.originalData?.user) {
        dispatch(userActions.updateAccountInfo(response?.originalData?.user));
        snackBar.show({
          severity: 'success',
          message: response?.message || 'User update success'
        });
        setIsLoading(false);
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || 'User Update Failed'
        });
      }
    }
  };

  useEffect(() => {
    getAccountDetails();
  }, [getAccountDetails]);

  return (
    <Page className={classes.root} title="Transaction">
      <Container maxWidth={false}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography color="textPrimary" gutterBottom variant="h3">
            Manage Account
          </Typography>
        </Box>
        <UserDetailCard
          loading={isLoading}
          user={userDetails}
          onSaveDetailPress={onUpdateAccount}
        />
      </Container>
      {multiBranchFeat ? (
        <Container maxWidth={false}>
          <Box
            paddingTop={5}
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography color="textPrimary" gutterBottom variant="h4">
              Branches
            </Typography>
          </Box>
          <Box mt={2}>
            <Card>
              <PerfectScrollbar style={{ height: '30vh' }}>
                <Box minWidth={1050}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Branch Name</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {userBranchOptions?.map((b) => (
                        <TableRow key={b.value}>
                          <TableCell>{b.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          </Box>
        </Container>
      ) : null}
      <Container maxWidth={false}>
        <Box
          paddingTop={5}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="textPrimary" gutterBottom variant="h4">
            Roles
          </Typography>
        </Box>
        <Box mt={2}>
          <Card>
            <PerfectScrollbar style={{ height: '30vh' }}>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Role Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userDetails?.all_roles?.map((item: Role) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>

      <Container maxWidth={false}>
        <Box
          paddingTop={5}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="textPrimary" gutterBottom variant="h4">
            Permissions
          </Typography>
        </Box>
        <Box mt={2}>
          <Card>
            <PerfectScrollbar style={{ height: '30vh' }}>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Permissions Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {userDetails?.all_permissions?.map((item: Permission) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>
    </Page>
  );
};

export default AccountView;
