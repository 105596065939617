import React, { useState } from 'react';
import clsx from 'clsx';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import {
  Box,
  Card,
  CardHeader,
  Container,
  Divider,
  makeStyles
} from '@material-ui/core';
import { LoaderBar, LoadingButton, Page } from 'src/components';
import { slices, useAppDispatch } from 'src/redux';
import { usePermissions, useSnackBar } from 'src/hooks';
import {
  CustomInputEvent,
  Gallery,
  PackageDetailsData,
  PackageUpdatePayload
} from 'src/types';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  PackageCoverImage,
  PackageGeneralInfoContent,
  PackageProductCard
} from '../package-component';
import { isEmpty } from 'lodash';
import { CategoriesEnum } from 'src/enums';
import { isPackageCreateParamsValid } from 'src/utils/pc-package';

const { actions: packageActions } = slices.pcPackage;

interface Props {
  className?: string;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  createBtn: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 60
  }
}));

const component = ({ className, ...rest }: Props) => {
  const dispatch = useAppDispatch();
  const classes = useStyles();
  const snackBar = useSnackBar();

  const [galleryDetails, setGalleryDetails] = useState<Gallery>();
  const [packageDetails, setPackageDetails] = useState<PackageDetailsData>();
  const [loading, setLoading] = useState<boolean>(false);
  const { canCreatePCPackage } = usePermissions();

  const onCreatePackage = async () => {
    const newProducts = packageDetails?.products;
    const newPackageProducts: any = newProducts?.map((obj) => {
      return {
        id: obj.product_id,
        slug: obj.slug,
        quantity: obj.quantity
      };
    });
    const adjustPayload: PackageUpdatePayload = {
      products: newPackageProducts,
      title: packageDetails?.title,
      description: packageDetails?.description,
      cover: packageDetails?.cover
    };
    const errMsg = isPackageCreateParamsValid(packageDetails);
    if (errMsg) {
      snackBar.show({
        severity: 'error',
        message: errMsg || 'Somethings Wrong'
      });
      return;
    }
    setLoading(true);
    const response = unwrapResult(
      await dispatch(
        packageActions.pcPackageCreateThunk(adjustPayload)
      ).finally(() => setLoading(false))
    );
    if (response.success) {
      snackBar.show({
        severity: 'success',
        message: 'PC package created successfuly.. '
      });
      setPackageDetails(undefined);
    } else {
      snackBar.show({
        severity: 'error',
        message:
          response?.message || response?.errors
            ? JSON.stringify(response?.errors)
            : 'Something went wrong' || 'Something went wrong'
      });
    }
  };

  const onChangeGeneralInfoInput = (e: CustomInputEvent) => {
    const { value, name } = e.target;
    setPackageDetails((prev) => ({ ...prev, [name]: value }));
  };

  const onChangePropertyViaName = (value: any, name: string) => {
    const inputEvent: any = { target: { name, value } };

    // If changed property is 'components' and cpu is not yet selected.
    if (inputEvent?.target?.name === 'components' && !galleryDetails?.cpu) {
      let newCpu: '' | 'AMD' | 'Intel' = '';
      const filteredCpu = inputEvent.target.value.filter(
        (x: any) => x.category.id === CategoriesEnum.CPU
      );
      if (!isEmpty(filteredCpu)) {
        if (filteredCpu[0].product_name.includes('AMD')) {
          newCpu = 'AMD';
        }
        if (filteredCpu[0].product_name.includes('INTEL')) {
          newCpu = 'Intel';
        }
        setGalleryDetails((prev) => ({ ...prev, cpu: newCpu }));
      }
    }

    onChangeGeneralInfoInput(inputEvent);
  };

  return (
    <Page className={classes.root} title="New PC Package">
      <LoaderBar isLoading={loading} />
      <Container maxWidth={false}>
        <Box mt={2}>
          <form
            autoComplete="off"
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <LoaderBar isLoading={false} />
              <CardHeader
                title="New PC Package"
                subheader="The information of new PC Package."
              />
              <Divider />
              <PackageCoverImage
                data={packageDetails?.cover}
                onCoverImageChange={(imgStr) =>
                  onChangePropertyViaName(imgStr, 'cover')
                }
              />
              <Divider />
              <PackageGeneralInfoContent
                data={packageDetails}
                onChangeInput={onChangeGeneralInfoInput}
              />
              <Divider />
              <PackageProductCard
                data={packageDetails?.products}
                onComponentsChange={(products) =>
                  onChangePropertyViaName(products, 'products')
                }
              />
              <Divider />

              <Divider />
            </Card>
          </form>
        </Box>
        <LoadingButton
          startIcon={<PlusOneIcon />}
          color="primary"
          height={100}
          loading={loading}
          title="Create PC Package"
          fullWidth
          variant="contained"
          onClick={onCreatePackage}
          disabled={!canCreatePCPackage}
        />
      </Container>
    </Page>
  );
};

export const AddPcPackageView = React.memo(component);
