import React, { useMemo, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import MemoryIcon from '@material-ui/icons/Memory';

import {
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography,
  Divider,
  IconButton,
  Button
} from '@material-ui/core';

import { PackageDetailsProduct, Product } from 'src/types';
import { formatCurrency } from 'src/utils';
import clsx from 'clsx';
import { LinkComponent } from 'src/components';
import { useNavigate } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { cloneDeep, debounce } from 'lodash';
import { usePermissions } from 'src/hooks';
import { componentPackageToQuotation } from 'src/utils/pc-package/componentPackageToQuotation';

const { actions: productaction } = slices.product;

interface Props {
  data?: PackageDetailsProduct[];
  onComponentsChange?: (newComponents: PackageDetailsProduct[]) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cpuField: {
    borderWidth: 5
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  addBtn: {
    marginTop: theme.spacing(2)
  },
  componentsListDiv: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  productTitle: {
    fontWeight: 600,
    letterSpacing: 0.5,
    fontSize: 12
  },
  optionListPrice: {
    fontWeight: 500,
    fontSize: 12
  },
  category: {
    fontSize: 10,
    marginLeft: theme.spacing(0.5)
  },
  deleteIcon: {
    padding: 0,
    marginRight: theme.spacing(1)
  }
}));

const component = ({ data = [], onComponentsChange }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { canEditPCPackage } = usePermissions();

  //TODO: by default branch id is equal to 1
  const branch_ids = [1];

  const [loading, setLoading] = useState<boolean>(false);
  const [products, setProducts] = useState<Product[]>([]);

  const getProducts = debounce(async (keyword: string) => {
    setLoading(true);
    const response = unwrapResult(
      await dispatch(
        productaction.getProductsForGalleryThunk({
          keyword,
          branch_ids
        })
      ).finally(() => setLoading(false))
    );
    if (response?.success && response?.originalData?.data?.length > 0) {
      setProducts(response?.originalData?.data || []);
    }
  }, 500);

  const onAddComponentInternal = (newProduct: Product | null) => {
    if (onComponentsChange && newProduct) {
      const clonedData = cloneDeep(data);
      const adjustedNewProduct: PackageDetailsProduct = {
        product_id: newProduct?.id,
        action: 'create',
        product_name: newProduct?.name,
        retail_price: newProduct?.retail_price,
        category_name: newProduct?.category,
        slug: newProduct?.slug || 'mock-data',
        quantity: newProduct?.quantity || 1
      };
      onComponentsChange([...clonedData, adjustedNewProduct]);
    }
  };

  const onRemoveComponent = (index: number) => {
    if (onComponentsChange) {
      const clonedData = cloneDeep(data);
      if (clonedData[index]?.id) {
        clonedData[index].product_id = null;
        clonedData[index].action = 'delete';
      } else {
        clonedData.splice(index, 1);
      }
      onComponentsChange(clonedData);
    }
  };

  const packageToQuotation = useMemo(
    () => componentPackageToQuotation(data || []),
    [data]
  );

  const pcPackageToQuotation = () => {
    navigate(`/app/quotation`, { state: packageToQuotation });
  };

  return (
    <CardContent>
      <Typography className={classes.subHeader} variant="h6">
        PC Package Components / Products
      </Typography>
      <Grid container spacing={3}>
        {canEditPCPackage ? (
          <Grid item md={6} xs={12}>
            <Autocomplete
              fullWidth
              loading={loading}
              onChange={(_, newValue) => onAddComponentInternal(newValue)}
              onInputChange={(_, newInputValue) => getProducts(newInputValue)}
              id="product_name"
              options={products}
              getOptionLabel={(option) => `${option?.name}`}
              renderInput={(params) => (
                <TextField
                  {...params}
                  name="product_name"
                  label="Product Name"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        ) : null}
        <Grid item xs={12}>
          {data?.map((product, i) => {
            if (product?.action === 'delete') {
              return null;
            } else {
              return (
                <div
                  className={classes.componentsListDiv}
                  key={`${product?.product_id}${i}`}
                >
                  <IconButton
                    disabled={!canEditPCPackage}
                    className={classes.deleteIcon}
                    onClick={() => onRemoveComponent(i)}
                  >
                    {canEditPCPackage ? (
                      <DeleteIcon
                        onClick={() => {}}
                        fontSize="small"
                        color="secondary"
                      />
                    ) : (
                      <MemoryIcon
                        onClick={() => {}}
                        fontSize="small"
                        color="secondary"
                      />
                    )}
                  </IconButton>

                  <div>
                    <Typography className={classes.productTitle}>
                      <LinkComponent
                        onClick={() =>
                          navigate(`/app/products/${product?.product_id}`)
                        }
                        href={`/app/products/${product?.product_id}`}
                        title={product?.product_name || '--'}
                        target="_blank"
                        rel="noopener noreferrer"
                      />
                    </Typography>

                    <div className={classes.componentsListDiv}>
                      <Typography className={classes.optionListPrice}>
                        {product?.retail_price
                          ? `${formatCurrency(product?.retail_price)}`
                          : '--'}
                      </Typography>
                      <Typography
                        className={clsx(
                          classes.optionListPrice,
                          classes.category
                        )}
                      >
                        {`(${product?.category_name})` || `--`}
                      </Typography>
                    </div>
                    <Divider />
                  </div>
                </div>
              );
            }
          })}
        </Grid>
        <Button
          fullWidth
          onClick={pcPackageToQuotation}
          color="primary"
          variant="outlined"
        >
          CREATE/CUSTOMIZE QUOTATION
        </Button>
      </Grid>
    </CardContent>
  );
};

export const PackageProductCard = React.memo(component);
