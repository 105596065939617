import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  CommonSuppliersResponse,
  CreateSupplierRequest,
  CreateSuppliersResponse,
  DeleteSupplierResponse,
  SuppliersThunkRequest,
  UpdateSupplierResponse,
  UpdateSupplierThunkRequest
} from 'src/types/supplier';

export const getSuppliersThunk = createAsyncThunk<
  CommonAxiosResponse<CommonSuppliersResponse>,
  SuppliersThunkRequest,
  { state: RootState }
>('supplier/getSuppliers', async (data) => {
  const response = await Api.get('/suppliers', data);
  if (!response.success) {
    throw new Error('Failed at getSuppliersThunk.');
  }
  return response;
});

export const createSupplierThunk = createAsyncThunk<
  CommonAxiosResponse<CreateSuppliersResponse>,
  CreateSupplierRequest,
  { state: RootState }
>('supplier/createSupplier', async (data) => {
  const response = await Api.post(`/suppliers`, data);
  if (!response.success) {
    throw new Error('Failed at createSupplierThunk.');
  }
  return response;
});

export const updateSupplierThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateSupplierResponse>,
  UpdateSupplierThunkRequest,
  { state: RootState }
>('supplier/updateSupplierThunk', async (data) => {
  const response = await Api.patch(`/suppliers/${data.id}`, data);
  if (!response.success) {
    throw new Error('Failed to at updateSupplierThunk.');
  }
  return response;
});

export const supplierDeleteThunk = createAsyncThunk<
  CommonAxiosResponse<DeleteSupplierResponse>,
  number,
  { state: RootState }
>('suppliers/supplierDeleteThunk', async (id) => {
  const response = await Api.delete(`/suppliers/${id}`);
  // if (!response.success) {
  //   throw new Error('Failed at supplierDeleteThunk.');
  // }
  return response;
});
