import { RmaStatusEnum } from 'src/enums';

export const getRmaStatusViaId = (id?: number) => {
  if (id === RmaStatusEnum.Replaced) {
    return 'Replaced';
  }
  if (id === RmaStatusEnum.TestedGood) {
    return 'Tested Good';
  }
  if (id === RmaStatusEnum.Repaired) {
    return 'Repaired';
  }
  if (id === RmaStatusEnum.Void) {
    return 'Void';
  }
  if (id === RmaStatusEnum.Processing) {
    return 'Processing';
  }
  if (id === RmaStatusEnum.Refund) {
    return 'Refunded to Customer';
  }
  if (id === RmaStatusEnum.Others) {
    return 'Others';
  }
  return '--';
};

export const isItemHiddenOnPrintCauseRma = (id?: number) => {
  switch (id) {
    // Repaired && TestedGood - should still be visible on print.
    // case RmaStatusEnum.Repaired:
    // case RmaStatusEnum.TestedGood:

    case RmaStatusEnum.Replaced:
    case RmaStatusEnum.Void:
    case RmaStatusEnum.Others:
    case RmaStatusEnum.Refund:
    case RmaStatusEnum.Processing:
      return true;
    default:
      return false;
  }
};
