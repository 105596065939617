import React, { useMemo } from 'react';
import { Text } from '@react-pdf/renderer';
import { Style } from '@react-pdf/types';

import { warrantyTextStyles } from '../styles';
import { concat } from 'lodash';

interface Props {
  style?: Style | Style[] | any;
  children: React.ReactNode;
}

export const TextRegularWarranty = ({ style = {}, ...props }: Props) => {
  const toBeAppliedStyle = useMemo(() => {
    if (style) {
      if (Array.isArray(style)) {
        return concat(style, [warrantyTextStyles.text]);
      }
    }
    return [warrantyTextStyles.text, style];
  }, [style]);

  return <Text {...props} style={toBeAppliedStyle} />;
};

export const TextBoldWarranty = ({ style = {}, ...props }: Props) => {
  const toBeAppliedStyle = useMemo(() => {
    if (style) {
      if (Array.isArray(style)) {
        return concat(style, [warrantyTextStyles.textBold]);
      }
    }
    return [warrantyTextStyles.textBold, style];
  }, [style]);

  return <Text {...props} style={toBeAppliedStyle} />;
};

export const TextBoldPlayFair = ({ style = {}, ...props }: Props) => {
  const toBeAppliedStyle = useMemo(() => {
    if (style) {
      if (Array.isArray(style)) {
        return concat([warrantyTextStyles.playFairBold], style);
      }
    }
    return [warrantyTextStyles.playFairBold, style];
  }, [style]);

  return <Text {...props} style={toBeAppliedStyle} />;
};
