import { RootState } from 'src/redux/store';
import { CommonAxiosResponse } from 'src/types';
import Api from 'src/services/ApiService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetInventoryAuditLogDetailResponse,
  GetInventoryAuditLogsListingsPayload,
  GetInventoryAuditLogsListingsResponse,
  UpdateInventoryAuditsRemarksPayload,
  UpdateInventoryAuditsRemarksResponse
} from './types';

export const getInventoryAuditLogsListingsThunk = createAsyncThunk<
  CommonAxiosResponse<GetInventoryAuditLogsListingsResponse>,
  GetInventoryAuditLogsListingsPayload,
  { state: RootState }
>('inventory-audit-logs/getInventoryAuditLogsListingsThunk', async (data) => {
  const response = await Api.get(
    `product/inventory/audit/log/details/get`,
    data
  );
  return response;
});

export const getInventoryAuditLogsDetailsThunk = createAsyncThunk<
  CommonAxiosResponse<GetInventoryAuditLogDetailResponse>,
  string | undefined,
  { state: RootState }
>('inventory-audit-logs/getInventoryAuditLogsDetailsThunk', async (ial_no) => {
  const response = await Api.get(
    `product/inventory/audit/log/details/get/${ial_no}`
  );
  return response;
});

export const updateInventoryAuditLogsRemarksThunk = createAsyncThunk<
  CommonAxiosResponse<UpdateInventoryAuditsRemarksResponse>,
  UpdateInventoryAuditsRemarksPayload,
  { state: RootState }
>('inventory-audit-logs/updateInventoryAuditLogsRemarksThunk', async (data) => {
  const response = await Api.patch(
    `product/inventory/audit/log/remarks/create`,
    data
  );
  return response;
});
