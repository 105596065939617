import React from 'react';
import {
  Box,
  Button,
  Card,
  IconButton,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  CustomInputEvent,
  GetTransactionPaymentsResponse,
  TransactionPayment
} from 'src/types';
import { formatCurrency, getPaymentTypeColor } from 'src/utils';
import { colors } from 'src/constants';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import { LoaderBar } from 'src/components';
import { TransactionDetailsARCell } from './TransactionARTableCell';
import { usePermissions } from 'src/hooks';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  extraInfo: {
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  addBtn: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}));

interface Props {
  isLoading?: boolean;
  onAddPaymentPress: () => void;
  data?: GetTransactionPaymentsResponse;
  onDeletePayment: (item: TransactionPayment) => void;
  onSaveARField: (e: any, item: TransactionPayment) => void;
  onChangeARFieldText: (e: CustomInputEvent, index: number) => void;
}

const PaymentsCard = ({
  isLoading,
  data,
  onAddPaymentPress,
  onDeletePayment,
  onSaveARField,
  onChangeARFieldText
}: Props) => {
  const classes = useStyles();
  const { canDeleteTransactionPayment } = usePermissions();

  return (
    <div>
      <Typography color="textPrimary" variant="h5">
        Payments
      </Typography>
      <LoaderBar isLoading={isLoading || false} />
      <Card title="Payments" className={classes.cardContainer}>
        <PerfectScrollbar>
          <Box minWidth={1050}>
            {data?.data && data?.data?.length > 0 ? (
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date Posted</TableCell>
                    <TableCell>Acknowledgement Receipt No.</TableCell>
                    <TableCell>Type / MOP</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell>Created By</TableCell>
                    {canDeleteTransactionPayment && (
                      <TableCell>Delete</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.data?.map((item: TransactionPayment, index: number) =>
                    !item?.deleted_by ? (
                      <TableRow hover key={item.id}>
                        <TableCell>{item?.date_posted || '--'}</TableCell>
                        <TransactionDetailsARCell
                          data={item}
                          index={index}
                          onSave={onSaveARField}
                          onChangeText={onChangeARFieldText}
                        />
                        <TableCell>
                          <Typography
                            variant="h6"
                            style={{
                              color: getPaymentTypeColor(item?.payment_type)
                            }}
                          >
                            {item?.payment_type || '--'}
                          </Typography>
                        </TableCell>
                        <TableCell>{formatCurrency(item?.amount)}</TableCell>
                        <TableCell>{item?.created_by || '--'}</TableCell>
                        {canDeleteTransactionPayment && (
                          <TableCell>
                            <IconButton
                              size="small"
                              onClick={(e) => {
                                e.stopPropagation();
                                onDeletePayment(item);
                              }}
                            >
                              <DeleteIcon color="secondary" />
                            </IconButton>
                          </TableCell>
                        )}
                      </TableRow>
                    ) : null
                  )}
                </TableBody>
              </Table>
            ) : null}

            {data ? (
              <div className={classes.extraInfo}>
                <Button
                  disabled={isLoading}
                  startIcon={<LocalAtmIcon />}
                  onClick={onAddPaymentPress}
                  color="primary"
                  variant="outlined"
                  className={classes.addBtn}
                >
                  Add payment
                </Button>

                <Typography variant="h5" style={{ color: colors.purple[500] }}>
                  TOTAL PAID AMOUNT :{' '}
                  {`${formatCurrency(data?.total_paid_amount) ?? `0.00`}`}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ color: colors.blue[500] }}
                >
                  total transaction amount:{' '}
                  {`${formatCurrency(data?.total_transaction_amount) ??
                    `0.00`}`}
                </Typography>
                <Typography
                  variant="subtitle2"
                  style={{ color: colors.red[500] }}
                >
                  total balance:{' '}
                  {`${formatCurrency(data?.total_balance) ?? `0.00`}`}
                </Typography>
              </div>
            ) : (
              <Button
                fullWidth
                disabled={isLoading}
                startIcon={<LocalAtmIcon />}
                onClick={onAddPaymentPress}
                color="primary"
                variant="outlined"
              >
                Add payment
              </Button>
            )}
          </Box>
        </PerfectScrollbar>
      </Card>
    </div>
  );
};

export const TransactionPaymentsCard = React.memo(PaymentsCard);
