import { TransactionPaymentOption } from 'src/types';

export const PaymentType = {
  Billease: 'BILLEASE',
  Paymongo: 'PAYMONGO',
  Gcash: 'GCASH',
  Paymaya: 'PAYMAYA',
  Bdo: 'BDO',
  Cash: 'CASH',
  BdoBt: 'BDO_BT',
  BpiBt: 'BPI_BT',
  CcStraight: 'CC_STRAIGHT',

  DepositCheque: 'DEPOSIT_CHEQUE',
  DepositCash: 'DEPOSIT_CASH',
  PaymongoGcash: 'PAYMONGO_GCASH',
  UnionbankBT: 'UNIONBANK_BT',

  BdoCc3M: 'BDO_CC_3M',
  BdoCc6M: 'BDO_CC_6M',
  BdoCc9M: 'BDO_CC_9M',
  BdoCc12M: 'BDO_CC_12M',
  BdoCc18M: 'BDO_CC_18M',
  BdoCc24M: 'BDO_CC_24M',
  BpiCc3M: 'BPI_CC_3M',
  BpiCc6M: 'BPI_CC_6M',
  BpiCc9M: 'BPI_CC_9M',
  BpiCc12M: 'BPI_CC_12M',
  BpiCc18M: 'BPI_CC_18M',
  BpiCc24M: 'BPI_CC_24M',
  EwCc3M: 'EW_CC_3M',
  EwCc6M: 'EW_CC_6M',
  EwCc9M: 'EW_CC_9M',
  EwCc12M: 'EW_CC_12M',
  EwCc18M: 'EW_CC_18M',
  EwCc24M: 'EW_CC_24M',
  HsbcCc3M: 'HSBC_CC_3M',
  HsbcCc6M: 'HSBC_CC_6M',
  HsbcCc9M: 'HSBC_CC_9M',
  HsbcCc12M: 'HSBC_CC_12M',
  HsbcCc18M: 'HSBC_CC_18M',
  HsbcCc24M: 'HSBC_CC_24M',
  CbCc3M: 'CB_CC_3M',
  CbCc6M: 'CB_CC_6M',
  CbCc9M: 'CB_CC_9M',
  CbCc12M: 'CB_CC_12M',
  CbCc18M: 'CB_CC_18M',
  CbCc24M: 'CB_CC_24M',
  PaymongoGGives: 'PAYMONGO_GGIVES',
  PaymongoBillease: 'PAYMONGO_BILLEASE',
  PaymongoCc: 'PAYMONGO_CC',
  PaymongoMaya: 'PAYMONGO_MAYA',
  PaymongoShopee: 'PAYMONGO_SHOPEE',
  PaymongoGrab: 'PAYMONGO_GRAB',
  HomeCredit: 'HOME_CREDIT',
  KachiPoints: 'KACHI POINTS',
  Refund: 'REFUND'
};

export const paymentTypeOptionsOriginal: TransactionPaymentOption[] = [
  {
    name: 'Paymongo',
    value: PaymentType.Paymongo
  },
  {
    name: 'Bdo',
    value: PaymentType.Bdo
  },
  {
    name: 'Gcash',
    value: PaymentType.Gcash
  },
  {
    name: 'Cash',
    value: PaymentType.Cash
  },
  {
    name: 'Paymaya',
    value: PaymentType.Paymaya
  },
  {
    name: 'Billease',
    value: PaymentType.Billease
  }
];

export const paymentTypeOptions: TransactionPaymentOption[] = [
  ...paymentTypeOptionsOriginal,
  { name: 'BDO BT', value: PaymentType.BdoBt },
  { name: 'BDO CC-12M', value: PaymentType.BdoCc12M },
  { name: 'BDO CC-18M', value: PaymentType.BdoCc18M },
  { name: 'BDO CC-24M', value: PaymentType.BdoCc24M },
  { name: 'BDO CC-3M', value: PaymentType.BdoCc3M },
  { name: 'BDO CC-6M', value: PaymentType.BdoCc6M },
  { name: 'BDO CC-9M', value: PaymentType.BdoCc9M },
  { name: 'BPI BT', value: PaymentType.BpiBt },
  { name: 'BPI CC-12M', value: PaymentType.BpiCc12M },
  { name: 'BPI CC-18M', value: PaymentType.BpiCc18M },
  { name: 'BPI CC-24M', value: PaymentType.BpiCc24M },
  { name: 'BPI CC-3M', value: PaymentType.BpiCc3M },
  { name: 'BPI CC-6M', value: PaymentType.BpiCc6M },
  { name: 'BPI CC-9M', value: PaymentType.BpiCc9M },
  { name: 'CB CC-12M', value: PaymentType.CbCc12M },
  { name: 'CB CC-18M', value: PaymentType.CbCc18M },
  { name: 'CB CC-24M', value: PaymentType.CbCc24M },
  { name: 'CB CC-3M', value: PaymentType.CbCc3M },
  { name: 'CB CC-6M', value: PaymentType.CbCc6M },
  { name: 'CB CC-9M', value: PaymentType.CbCc9M },
  { name: 'CC Straight', value: PaymentType.CcStraight },
  { name: 'Deposit-Cash', value: PaymentType.DepositCash },
  { name: 'Deposit-Cheque', value: PaymentType.DepositCheque },
  { name: 'EW CC-12M', value: PaymentType.EwCc12M },
  { name: 'EW CC-18M', value: PaymentType.EwCc18M },
  { name: 'EW CC-24M', value: PaymentType.EwCc24M },
  { name: 'EW CC-3M', value: PaymentType.EwCc3M },
  { name: 'EW CC-6M', value: PaymentType.EwCc6M },
  { name: 'EW CC-9M', value: PaymentType.EwCc9M },
  { name: 'HomeCredit', value: PaymentType.HomeCredit },
  { name: 'HomeCredit', value: PaymentType.HomeCredit },
  { name: 'HSBC CC-12M', value: PaymentType.HsbcCc12M },
  { name: 'HSBC CC-18M', value: PaymentType.HsbcCc18M },
  { name: 'HSBC CC-24M', value: PaymentType.HsbcCc24M },
  { name: 'HSBC CC-3M', value: PaymentType.HsbcCc3M },
  { name: 'HSBC CC-6M', value: PaymentType.HsbcCc6M },
  { name: 'HSBC CC-9M', value: PaymentType.HsbcCc9M },
  { name: 'Kachi Points', value: PaymentType.KachiPoints },
  { name: 'Paymongo-Billease', value: PaymentType.PaymongoBillease },
  { name: 'Paymongo-CC', value: PaymentType.PaymongoCc },
  { name: 'Paymongo-Gcash', value: PaymentType.PaymongoGcash },
  { name: 'Paymongo-GGives', value: PaymentType.PaymongoGGives },
  { name: 'Paymongo-Grab', value: PaymentType.PaymongoGrab },
  { name: 'Paymongo-Maya', value: PaymentType.PaymongoMaya },
  { name: 'Paymongo-Shopee', value: PaymentType.PaymongoShopee },
  { name: 'Refund', value: PaymentType.Refund },
  { name: 'Unionbank BT', value: PaymentType.UnionbankBT }
];

export const allowedPaymentOnTermsField: string[] = [
  PaymentType.Billease,
  PaymentType.Gcash,
  PaymentType.Paymaya,
  PaymentType.Bdo,
  PaymentType.Paymongo,
  PaymentType.Cash
];
