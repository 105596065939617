import React from 'react';
import {
  CardContent,
  Grid,
  TextField,
  makeStyles,
  Typography
} from '@material-ui/core';

import { CustomInputEvent, PackageDetailsData } from 'src/types';
import { usePermissions } from 'src/hooks';

interface Props {
  data?: PackageDetailsData;
  onChangeInput?: (e: CustomInputEvent) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  cpuField: {
    borderWidth: 5
  },
  subHeader: {
    marginBottom: theme.spacing(2)
  },
  imgPreview: {
    width: undefined,
    height: 70,
    marginRight: theme.spacing(1)
  },
  row: {
    display: 'flex',
    flexDirection: 'row'
  }
}));

const component = ({ data, onChangeInput }: Props) => {
  const classes = useStyles();
  const { canEditPCPackage } = usePermissions();

  const onChangeInputInternal = (e: CustomInputEvent) => {
    if (onChangeInput) {
      onChangeInput(e);
    }
  };

  return (
    <CardContent>
      <Typography className={classes.subHeader} variant="h6">
        General Information
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={12} xs={12}>
          <TextField
            fullWidth
            label="Title"
            name="title"
            required
            variant="outlined"
            value={data?.title || ''}
            onChange={(e) => onChangeInputInternal(e)}
            disabled={!canEditPCPackage}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Description"
            name="description"
            required
            variant="outlined"
            value={data?.description || ''}
            onChange={(e) => onChangeInputInternal(e)}
            disabled={!canEditPCPackage}
          />
        </Grid>
      </Grid>
    </CardContent>
  );
};

export const PackageGeneralInfoContent = React.memo(component);
