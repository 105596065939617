import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { ChangeEvent, FC, memo, useState, useEffect } from 'react';

interface Props {
  label?: string;
  options: any[];
  initialValue?: string;
  onHandleSelectedOptions: (opt?: string) => void;
}

const component: FC<Props> = ({
  label = 'Select Options',
  options,
  initialValue = '',
  onHandleSelectedOptions
}) => {
  const [value, setValue] = useState<string>(initialValue);

  const handleChange = (event: ChangeEvent<{ name?: string; value: any }>) => {
    setValue(event.target.value);
    onHandleSelectedOptions(event.target.value);
  };

  useEffect(() => {
    // Update the value when the initialValue prop changes
    setValue(initialValue);
  }, [initialValue]);

  return (
    <FormControl variant="outlined" fullWidth margin="normal">
      <InputLabel>{label}</InputLabel>
      <Select value={value} onChange={handleChange} label={label}>
        {options.map((option, index) => (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const SelectOptions = memo(component);
