import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
    CommonAxiosResponse,
    CommonResponseMessage,
    CommonCustomerServicePayload,
} from 'src/types';

export const deleteCustomerServiceThunk = createAsyncThunk<
    CommonAxiosResponse<CommonResponseMessage>,
    CommonCustomerServicePayload,
    { state: RootState }
>('customer/deleteCustomerServiceThunk', async (data) => {
    const response = await Api.delete(`/customers/'${data.id}`);
    if (!response.success) {
        throw new Error('Failed deleteCustomerServiceThunk');
    }
    return response;
});