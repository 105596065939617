import { cloneDeep } from 'lodash';
import { Gallery, GalleryCreateRequest, GalleryPhotos } from 'src/types';

export const galleryKeywordGenerator = (galleryDetails: Gallery) => {
  const stringOfProducts = JSON.stringify(galleryDetails?.components || []);
  const newKeyword = `
  ${galleryDetails?.title}
  ${galleryDetails?.build_tier}
  ${galleryDetails?.quote}
  ${galleryDetails?.description}
  ${galleryDetails?.cpu}
  ${galleryDetails?.build_tags}
  ${stringOfProducts}`;

  return newKeyword;
};

export const buildTagsToString = (data?: string[]) => {
  if (data && data?.length > 0) {
    return data?.join(',');
  }
  return undefined;
};

export const galleryPhotosArrToStrArr = (data?: GalleryPhotos[]) => {
  if (data && data?.length > 0) {
    const photoStrArr = data
      ?.map((x) => x?.url)
      ?.filter((y) => y !== undefined);
    return photoStrArr;
  }
  return [];
};

// transform Gallery type from read to GalleryCreateRequest type
export const galleryDetailsToCreateTransformer = (galleryDetails: Gallery) => {
  const clonedData = cloneDeep(galleryDetails);
  const mappedProductIds: number[] = (clonedData?.components || [])
    ?.map((x) => x?.product_id || 0)
    ?.filter((y) => y !== 0);
  const mappedPhotos: string[] = (clonedData?.photos || [])
    ?.map((x) => x?.url || '')
    ?.filter((y) => y !== '');

  const newData: GalleryCreateRequest = {
    title: clonedData?.title,
    cover: clonedData?.cover,
    badge: clonedData?.badge,
    build_tier: clonedData?.build_tier,
    client_id: 0,
    quote: clonedData?.quote,
    description: clonedData?.description,
    cpu: clonedData?.cpu,
    keyword: galleryKeywordGenerator(clonedData),
    build_tags: buildTagsToString(clonedData?.build_tags),
    product_ids: mappedProductIds,
    photos: mappedPhotos
  };

  return newData;
};
