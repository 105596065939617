import { Font, StyleSheet } from '@react-pdf/renderer';
import { colors } from 'src/constants';

// NOTE: Check https://react-pdf.org/fonts for more built in fonts.
const mainFont = 'Courier';
const mainFontBold = `${mainFont}-Bold`;

// Font.register({ family: 'Playfair Display', src: './PlayfairDisplay-Bold.ttf', fontWeight: 'bold' });
Font.register({
  family: 'Playfair Display',
  src: '/static/fonts/playfairDisplay/PlayfairDisplay-Bold.ttf',
  fontWeight: 'bold'
});

export const warrantyTextStyles = StyleSheet.create({
  text: {
    fontSize: 8,
    fontFamily: mainFont
  },
  textBold: {
    fontSize: 8,
    fontFamily: mainFontBold
  },
  playFairBold: {
    fontFamily: 'Playfair Display',
    fontSize: 8,
  },
  url: {
    fontSize: 8,
    textDecoration: 'underline',
    color: colors.blue[600]
  },
  underline: {
    textDecoration: 'underline'
  }
});
