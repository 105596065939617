import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import { CommonAxiosResponse } from 'src/types';
import {
  CreateBlogCategoryPayload,
  CreateBlogCategoryResponse,
  GetBlogCategoryDetailResponse,
  GetBlogCategoryListOptions,
  GetBlogCategoryListOptionsPayload,
  GetBlogCategoryListResponse,
  GetBlogCategoryPayload,
  UpdateBlogCategoryPayload
} from './types';

export const createBlogCategoryThunk = createAsyncThunk<
  CommonAxiosResponse<CreateBlogCategoryResponse>,
  CreateBlogCategoryPayload,
  { state: RootState }
>('ecomm/blog-category/createBlogCategory', async (data) => {
  const response = await Api.post('/ecomm/internal/blog/category/create', data);
  return response;
});

export const getBlogCategoryThunk = createAsyncThunk<
  CommonAxiosResponse<GetBlogCategoryListResponse>,
  GetBlogCategoryPayload,
  { state: RootState }
>('ecomm/blog-category/getBlogCategoryThunk', async (data) => {
  const response = await Api.get(`/ecomm/internal/blog/category/get`, data);
  return response;
});

export const getBlogCategoryOptionListThunk = createAsyncThunk<
  CommonAxiosResponse<GetBlogCategoryListOptions>,
  GetBlogCategoryListOptionsPayload,
  { state: RootState }
>('ecomm/blog-category/getBlogCategoryOptionListThunk', async (data) => {
  const bodyParams = { limit: 100, page: data?.page || 1 };
  const response = await Api.get(
    `/ecomm/internal/blog/category/get?list=${data?.list}`,
    bodyParams
  );
  return response;
});

export const getBlogCategoryDetailThunk = createAsyncThunk<
  CommonAxiosResponse<GetBlogCategoryDetailResponse>,
  number,
  { state: RootState }
>('ecomm/blog-category/getBlogCategoryDetailThunk', async (blog_id) => {
  const response = await Api.get(
    `/ecomm/internal/blog/category/get/${blog_id || ''}`
  );
  return response;
});

export const updateBlogCategoryThunk = createAsyncThunk<
  CommonAxiosResponse<null>,
  UpdateBlogCategoryPayload,
  { state: RootState }
>('ecomm/blog-category/upddateBlogCategoryThunk', async (data) => {
  const response = await Api.patch(
    `ecomm/internal/blog/category/update/${data?.blog_id}`,
    data
  );
  return response;
});

export const deleteBlogCategoryThunk = createAsyncThunk<
  CommonAxiosResponse<null>,
  number,
  { state: RootState }
>('/ecomm/internal/blog/category/delete/', async (blog_id) => {
  const response = await Api.delete(
    `ecomm/internal/blog/category/delete/${blog_id}`
  );
  return response;
});
