import React from 'react';
import { Divider, makeStyles, TableCell, Typography } from '@material-ui/core';

import { SalePayment } from 'src/types';
import { colors } from 'src/constants';
import { formatCurrency, getPaymentTypeColor } from 'src/utils';

interface Props {
  payments?: SalePayment[];
}

const useStyles = makeStyles((theme) => ({
  itemContainer: {
    marginBottom: theme.spacing(1)
  },
  grey: {
    color: colors.grey[600]
  },
  amountRow: {
    display: 'flex',
    flexDirection: 'row'
  },
  amount: {
    color: colors.purple[600]
  }
}));

const TableCellPayments = ({ payments = [] }: Props) => {
  const classes = useStyles();

  return (
    <TableCell style={{ verticalAlign: 'top' }}>
      {payments?.map((payment, index) => {
        return (
          <div
            key={`${payment?.date_posted}-${index}`}
            className={classes.itemContainer}
          >
            <div className={classes.amountRow}>
              <Typography variant="h6" className={classes.amount}>
                {`${formatCurrency(payment?.amount)} `}
              </Typography>

              <Typography variant="h5">{` - `}</Typography>
              <Typography
                variant="subtitle1"
                style={{ color: getPaymentTypeColor(payment?.payment_type) }}
              >
                {` (${payment?.payment_type})`}
              </Typography>
            </div>

            {payment?.acknowledgement_receipt_no ? (
              <Typography variant="h6">
                {`AR No: ${payment?.acknowledgement_receipt_no}`}
              </Typography>
            ) : null}

            <Typography variant="subtitle1" className={classes.grey}>
              {payment?.created_by}
            </Typography>

            <Typography variant="subtitle1" className={classes.grey}>
              {payment?.date_posted}
            </Typography>
            <Divider />
          </div>
        );
      })}
    </TableCell>
  );
};

export const SalesPaymentsTableCell = React.memo(TableCellPayments);
