import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { CheckBoxLabel, LinkComponent, LoaderBar, Page } from 'src/components';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { AssignPermissionToRoleRequest, Permission, Role } from 'src/types';
import FormAddDialog from 'src/components/dialogs/FormDialog';
import { useSnackBar } from 'src/hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { UserBasicInfo } from 'src/types/user';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import { cloneDeep } from 'lodash';
import usePermsAndRolesSearch from 'src/hooks/use-perms-and-roles-search';
import { SearchBox } from 'src/components/SearchBox';

const { actions: permissionActions } = slices.permissions;
const { actions: roleActions } = slices.roles;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  tableTitle: {
    marginTop: '2em'
  },
  productCard: {
    height: '100%'
  },
  tableRow: {
    height: 35,
    padding: 10
  },
  permissionBox: {
    padding: theme.spacing(3)
  }
}));

const PermissionDetailView = () => {
  const classes = useStyles();
  const { id } = useParams();
  const snackBar = useSnackBar();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    roles,
    filteredRoles,
    roleInputVal,
    onChangeRoleInput,
    onSearchRole
  } = usePermsAndRolesSearch();

  const [isCreateRoleVisible, setIsCreateRoleVisible] = useState<boolean>(
    false
  );
  const [rolesLoading, setRolesLoading] = useState<boolean>(false);
  const [permissionDetail, setPermissionDetail] = useState<Permission>();
  const [selectedRoles, setSelectedRoles] = useState<Role[]>([]);
  const [roleItemLoading, setRoleItemLoading] = useState<number>(); // Id Only
  const [addRoleLoading, setAddRoleLoading] = useState<boolean>(false);
  const [usersLoading, setUsersLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<UserBasicInfo[]>([]);

  const getPermissionDetail = useCallback(async () => {
    if (!id) {
      return;
    }
    const permissionId = +id;
    if (!isNaN(permissionId)) {
      setRolesLoading(true);
      await dispatch(roleActions.getRolesThunk());
      const roleDetailRes = unwrapResult(
        await dispatch(
          permissionActions.getPermissionViaIdThunk(permissionId)
        ).finally(() => setRolesLoading(false))
      );
      if (roleDetailRes?.success && roleDetailRes?.originalData?.permission) {
        setPermissionDetail(roleDetailRes.originalData.permission);
        setSelectedRoles(roleDetailRes.originalData.permission?.roles || []);
      }
    }
  }, [dispatch, id]);

  const getUsersWithThisPermission = useCallback(async () => {
    if (!id) {
      return;
    }
    const permissionId = +id;
    if (!isNaN(permissionId)) {
      setUsersLoading(true);
      const roleDetailRes = unwrapResult(
        await dispatch(
          permissionActions.getUsersViaPermissionId(permissionId)
        ).finally(() => setUsersLoading(false))
      );
      if (roleDetailRes?.success && roleDetailRes?.originalData?.users) {
        setUsers(roleDetailRes?.originalData?.users);
      }
    }
  }, [dispatch, id]);

  const onOpenCreatePermissionDialog = () => {
    setIsCreateRoleVisible(true);
  };

  const onCloseCreateRoleDialog = () => {
    setIsCreateRoleVisible(false);
    setAddRoleLoading(false);
  };

  const onAddRoleOnModalPress = async (name: string) => {
    if (!id) {
      return;
    }
    const permissionId = +id;

    if (isNaN(permissionId)) {
      // Invalid `id` on url params
      return;
    }
    if (permissionId) {
      setAddRoleLoading(true);

      const response = unwrapResult(
        await dispatch(
          roleActions.createRoleThenAddPermissionThunk({
            name,
            permission_id: permissionId
          })
        )
      );
      setAddRoleLoading(false);
      if (response?.success) {
        getPermissionDetail();
        snackBar.show({
          severity: 'success',
          message: response?.message || 'Role creation success.'
        });
      } else {
        snackBar.show({
          severity: 'error',
          message: response?.message || 'Role creation failed.'
        });
      }
    }

    onCloseCreateRoleDialog();
  };

  const onToggleRoleApi = async (
    role_id: number,
    permission_id: number,
    newValue: boolean
  ) => {
    const data: AssignPermissionToRoleRequest = {
      role_id,
      permission_id
    };
    setRoleItemLoading(role_id);
    if (newValue) {
      // If adding permission
      return dispatch(
        roleActions.assignPermissionToRoleThunk(data)
      ).finally(() => setRoleItemLoading(undefined));
    }
    // If revoking permission
    return dispatch(roleActions.revokePermissionToRoleThunk(data)).finally(() =>
      setRoleItemLoading(undefined)
    );
  };

  const onToggleRole = async (item: Role, newValue: boolean) => {
    if (!id) {
      return;
    }
    const clonedSelectedRoles = cloneDeep(selectedRoles);
    const roleId = +id;

    if (isNaN(roleId)) {
      // Invalid `id` on url params
      return;
    }
    if (item?.id) {
      onToggleRoleApi(item?.id, roleId, newValue);
      if (newValue) {
        // If toggle to be checked
        clonedSelectedRoles?.push(item);
        setSelectedRoles(clonedSelectedRoles);
        return;
      }
      // If toggle to be unchecked
      const filtered = clonedSelectedRoles?.filter((x) => x.id !== item?.id);
      setSelectedRoles(filtered);
    }
  };

  const isRoleItemLoading = useCallback(
    (item: Role) => item?.id === roleItemLoading,
    [roleItemLoading]
  );

  const isRoleSelected = useCallback(
    (item: Role) => {
      const index = selectedRoles?.findIndex((x) => x.id === item?.id);
      if (index > -1) {
        return true;
      }
      return false;
    },
    [selectedRoles]
  );

  useEffect(() => {
    getPermissionDetail();
    getUsersWithThisPermission();
  }, [getPermissionDetail, getUsersWithThisPermission]);

  return (
    <Page
      className={classes.root}
      title={`Permission - ${permissionDetail?.name || ''}`}
    >
      <Container maxWidth={false}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography color="textPrimary" gutterBottom variant="h3">
            {`Permission - ${permissionDetail?.name || ''}`}
          </Typography>
        </Box>
        <Typography
          className={classes.tableTitle}
          color="textPrimary"
          gutterBottom
          variant="h4"
        >
          Roles that have this permission
        </Typography>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <SearchBox
            options={roles}
            inputValue={roleInputVal}
            onInputChange={onChangeRoleInput}
            onSearchKeyWord={onSearchRole}
            placeholder={'Search Role'}
          />
          <Button onClick={onOpenCreatePermissionDialog} variant="contained">
            Create Role and add this permission
          </Button>
        </Box>
        <Box mt={2}>
          <LoaderBar isLoading={rolesLoading} />
          <Card className={classes.permissionBox}>
            <Box minWidth={1050}>
              <Grid container spacing={2}>
                {filteredRoles?.map((item: Role, i: number) => (
                  <Grid key={item?.id || i} item xl={2} lg={3} md={6} xs={12}>
                    <CheckBoxLabel
                      label={item?.name}
                      checked={isRoleSelected(item)}
                      disabled={isRoleItemLoading(item)}
                      onChange={(value) => onToggleRole(item, value)}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Card>
        </Box>

        <Box
          paddingTop={6}
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography color="textPrimary" gutterBottom variant="h4">
            Users with this permission
            <Typography>(either via roles or permissions)</Typography>
          </Typography>
        </Box>
        <Box mt={2}>
          <LoaderBar isLoading={usersLoading} />
          <Card>
            <PerfectScrollbar style={{ height: '30vh' }}>
              <Box minWidth={1050}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Full Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {users?.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item?.id}</TableCell>
                        <TableCell>
                          <LinkComponent
                            onClick={() => navigate(`/app/user/${item.id}`)}
                            href={`/app/user/${item.id}`}
                            title={`${item?.first_name ||
                              '--'} ${item?.last_name || '--'}`}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        </Box>
      </Container>

      <FormAddDialog
        loading={addRoleLoading}
        fieldName="New Role Name"
        title="Create a Role"
        isVisible={isCreateRoleVisible}
        subTitle="Input name for this new Role"
        onAddPress={onAddRoleOnModalPress}
        handleClose={onCloseCreateRoleDialog}
      />
    </Page>
  );
};

export default PermissionDetailView;
