import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { FixMeLater } from 'src/types';
import ClearIcon from '@material-ui/icons/Clear';
import { isEmpty } from 'lodash';
import { dateTodayVanilla } from 'src/utils';

interface Props {
  title?: string;
  dateFromLabel?: string;
  dateToLabel?: string;
  fromDateMin?: string | null;
  fromDateMax?: string | null;
  fromDateValue?: ParsableDate;
  toDateMin?: string | null;
  toDateValue?: ParsableDate;
  onChangeFromDate: (date: MaterialUiPickersDate) => void;
  onChangeToDate: (date: MaterialUiPickersDate) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  datePickersContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dashSeparator: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  }
}));

export const DatePickerRangeComponent = ({
  title,
  dateToLabel = 'To',
  dateFromLabel = 'From',
  fromDateMax = undefined,
  fromDateMin = undefined,
  toDateMin = undefined,
  fromDateValue,
  toDateValue,
  onChangeFromDate,
  onChangeToDate
}: Props) => {
  const classes = useStyles();

  const onClearPress = (e: FixMeLater, item: 'to' | 'from') => {
    e.stopPropagation();
    if (item === 'from') {
      onChangeFromDate(null);
    }
    if (item === 'to') {
      onChangeToDate(null);
    }
  };

  return (
    <div>
      {title ? (
        <Typography align="center" variant="h5">
          {title}
        </Typography>
      ) : null}
      <div className={classes.datePickersContainer}>
        <DatePicker
          autoOk
          format="MMMM dd, yyyy"
          inputVariant="outlined"
          name="from"
          animateYearScrolling
          value={fromDateValue}
          variant="inline"
          minDate={fromDateMin}
          label={dateFromLabel}
          maxDate={fromDateMax}
          onChange={onChangeFromDate}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={(e) => onClearPress(e, 'from')}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
        />
        <Typography
          align="center"
          variant="h1"
          className={classes.dashSeparator}
        >
          -
        </Typography>

        <DatePicker
          autoOk
          format="MMMM dd, yyyy"
          inputVariant="outlined"
          name="to"
          animateYearScrolling
          value={toDateValue}
          variant="inline"
          minDate={toDateMin}
          maxDate={dateTodayVanilla()}
          label={dateToLabel}
          onChange={onChangeToDate}
          InputProps={{
            endAdornment: (
              <IconButton size="small" onClick={(e) => onClearPress(e, 'to')}>
                <ClearIcon fontSize="small" />
              </IconButton>
            )
          }}
          disabled={isEmpty(fromDateValue)}
        />
      </div>
    </div>
  );
};
