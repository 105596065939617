import React, { useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
  makeStyles,
  Divider,
  TextField,
  InputAdornment,
  FormControl
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { cloneDeep, get, set } from 'lodash';
import ClearIcon from '@material-ui/icons/Clear';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

import { CheckBoxLabel, DatePickerRangeComponent } from 'src/components';
import { slices, useAppDispatch, useAppSelector } from 'src/redux';
import { dateToday, toUtcEquivalentDate } from 'src/utils';
import { listingStatusOptions } from 'src/constants';
import {
  BranchMeta,
  CustomInputEvent,
  DateFields,
  InventoryAction,
  InventoryKeywordInputField,
  ManufacturerOption
} from 'src/types';
import { ListingStatusEnum } from 'src/enums';
import { Autocomplete } from '@material-ui/lab';

import { multiBranchFeat } from 'src/constants/feature-toggle';
import { BranchListDropDown } from 'src/components/dropdown';
import { usePermissions } from 'src/hooks';

interface Props {
  className?: string;
  manufacturers?: ManufacturerOption[];
  isLoading: boolean;
  hasStatus: boolean;
  userBranchOptions: BranchMeta[];
  selectedBranchIDs: number[];
  handleBranchChange: (v: any) => void;
  onApplyFilterPress: (val: string) => void;
  handleSetManufacturer: (id?: number) => void;
}

const {
  actions: categoryActions,
  selectors: categorySelectors
} = slices.category;

const {
  actions: inventoryActions,
  selectors: inventorySelectors
} = slices.inventory;

const useStyles = makeStyles((theme) => ({
  root: {},
  filterTitle: {
    marginBottom: theme.spacing(1)
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  buttonsContainer: {
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  applyBtn: {
    marginLeft: theme.spacing(5)
  }
}));

const InventoryFilter = ({
  className,
  hasStatus,
  selectedBranchIDs,
  manufacturers,
  onApplyFilterPress,
  handleBranchChange,
  handleSetManufacturer,
  ...rest
}: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { viewTransferListingStock: canViewTransfer } = usePermissions();
  const inventoryFilter = useAppSelector(
    inventorySelectors.selectAllInventoryFilter
  );

  const categories = useAppSelector(categorySelectors.selectCategories);

  const [manufacturerVal, setManufacturerVal] = useState<string>('');
  const [categVal, setCategVal] = useState<string>('');

  const listingOptions = useMemo(() => {
    let options = listingStatusOptions;
    if (canViewTransfer) {
      return options;
    } else {
      return options.filter((i) => i.value !== ListingStatusEnum.Transfer);
    }
  }, [canViewTransfer]);

  const allStatus = useMemo(() => {
    const statusArr = [
      ListingStatusEnum.Available,
      ListingStatusEnum.Defective,
      ListingStatusEnum.OnHold,
      ListingStatusEnum.Purchased
    ];
    canViewTransfer && statusArr.push(ListingStatusEnum.Transfer);
    return statusArr;
  }, [canViewTransfer]);

  const onManufacturerChange = useCallback(
    (val: string) => {
      const manufacturer = manufacturers?.find((i) => i.label === val);
      const id = manufacturer?.id;
      handleSetManufacturer(id);
      setManufacturerVal(manufacturer?.label ?? val);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [manufacturers]
  );

  const onInternalApply = useCallback((val: InventoryAction) => {
    onApplyFilterPress(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCategories = () => {
    dispatch(categoryActions.getCategoriesThunk({}));
  };

  const getDateKeyObj = (field: DateFields) => {
    let key: 'sold_date' | 'encoded_date' | 'purchase_date' | '' = '';
    let rangeKey: 'to' | 'from' | '' = '';

    if (field.includes('Purchase')) {
      key = 'purchase_date';
    }
    if (field.includes('Sold')) {
      key = 'sold_date';
    }
    if (field.includes('Encoded')) {
      key = 'encoded_date';
    }
    if (field.includes('To')) {
      rangeKey = 'to';
    }
    if (field.includes('From')) {
      rangeKey = 'from';
    }
    return `${key}.${rangeKey}`;
  };

  const onChangeInputKeyword = useCallback(
    (e: CustomInputEvent, field: InventoryKeywordInputField) => {
      const { value } = e.target;
      dispatch(inventoryActions.onChangeInput({ keyword: value, field }));
    },
    [dispatch]
  );

  const onChangeCategory = useCallback((val: number | null) => {
    dispatch(
      inventoryActions.updateCategoryFilter({ category_id: val ?? null })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterTransformerViaDateField = useCallback(
    (date: MaterialUiPickersDate, field: DateFields) => {
      const filterCopy = inventoryFilter ? cloneDeep(inventoryFilter) : {};
      const dateUtc = toUtcEquivalentDate(date)?.toISOString();
      const finalKey = getDateKeyObj(field);
      set(filterCopy, finalKey, dateUtc);
      return filterCopy;
    },
    [inventoryFilter]
  );

  const onChangedDate = useCallback(
    (date: MaterialUiPickersDate, field: DateFields) => {
      const newFilter = filterTransformerViaDateField(date, field);
      dispatch(inventoryActions.updateFilter(newFilter));
    },
    [dispatch, filterTransformerViaDateField]
  );

  const onPressClear = useCallback(() => {
    setManufacturerVal('');
    setCategVal('');
    dispatch(inventoryActions.resetFilter());
    handleBranchChange([]); // intended to call last
  }, [dispatch, handleBranchChange]);

  const dateValue = useCallback(
    (field: DateFields) => {
      const key = getDateKeyObj(field);
      const date = get(inventoryFilter, key);
      return date ?? null;
    },
    [inventoryFilter]
  );

  const isStatusChecked = useCallback(
    (status: string) => {
      const index = inventoryFilter?.status?.findIndex((x) => x === status);
      return index !== undefined && index > -1 ? true : false;
    },
    [inventoryFilter]
  );

  const isAllStatusChecked = useMemo(() => {
    const allOptions = listingOptions.map((i) => i.value);
    const allExist = allOptions.every(isStatusChecked);
    return allExist;
  }, [listingOptions, isStatusChecked]);

  const onToggleStatusAllV2 = useCallback(() => {
    isAllStatusChecked
      ? dispatch(inventoryActions.onToggleStatusAllV2({ status: [] }))
      : dispatch(inventoryActions.onToggleStatusAllV2({ status: allStatus }));
  }, [allStatus, dispatch, isAllStatusChecked]);

  useEffect(() => {
    if (!canViewTransfer) {
      dispatch(
        inventoryActions.onToggleStatus({
          value: ListingStatusEnum.Transfer,
          isCheck: false
        })
      );
    }
  }, [canViewTransfer, dispatch]);

  useEffect(() => {
    getCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const RenderStatusFilters = useCallback(() => {
    return (
      <div>
        <Typography className={classes.filterTitle} align="center" variant="h5">
          Status:
        </Typography>
        <Grid container spacing={5} alignItems="center" justify="center">
          <Grid item>
            <CheckBoxLabel
              label="ALL"
              checked={isAllStatusChecked}
              onChange={onToggleStatusAllV2}
            />
          </Grid>

          {listingOptions?.map((x, i) => (
            <Grid key={i} item>
              <CheckBoxLabel
                label={x?.name?.toUpperCase()}
                checked={isStatusChecked(x.value)}
                onChange={(v) =>
                  dispatch(
                    inventoryActions.onToggleStatus({
                      value: x.value,
                      isCheck: v
                    })
                  )
                }
              />
            </Grid>
          ))}
        </Grid>
      </div>
    );
  }, [
    classes.filterTitle,
    dispatch,
    isAllStatusChecked,
    isStatusChecked,
    listingOptions,
    onToggleStatusAllV2
  ]);

  const RenderInputFilters = useCallback(() => {
    return (
      <div>
        <Typography className={classes.filterTitle} align="center" variant="h5">
          General Information
        </Typography>
        <Grid container spacing={2} alignItems="center" justify="center">
          <Grid item>
            <TextField
              fullWidth
              value={inventoryFilter?.product_name || ''}
              label="Product Name"
              name="product_name"
              onChange={(e) => onChangeInputKeyword(e, 'product_name')}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              label="Serial No"
              name="serial_no"
              value={inventoryFilter?.serial_no || ''}
              onChange={(e) => onChangeInputKeyword(e, 'serial_no')}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              value={inventoryFilter?.supplier_name || ''}
              label="Supplier Name"
              name="supplier_name"
              onChange={(e) => onChangeInputKeyword(e, 'supplier_name')}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item>
            <TextField
              fullWidth
              value={inventoryFilter?.dr_no || ''}
              label="DR No."
              name="dr_no"
              onChange={(e) => onChangeInputKeyword(e, 'dr_no')}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </Grid>
          <Grid item>
            <Autocomplete
              fullWidth
              inputValue={categVal}
              onInputChange={(_, newInputValue) => {
                const chosenCategory = categories?.find(
                  (i) => i?.name === newInputValue
                );
                onChangeCategory(chosenCategory?.id ?? null);
                setCategVal(newInputValue);
              }}
              style={{ minWidth: 250 }}
              id="category"
              options={categories}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Category" variant="outlined" />
              )}
            />
          </Grid>
          {multiBranchFeat ? (
            <Grid item md={2} style={{ marginRight: '10px' }}>
              <BranchListDropDown
                defaultValue={selectedBranchIDs}
                multiple
                onHandleBranchChange={(branchId?: number[]) => {
                  handleBranchChange(branchId);
                }}
              />
            </Grid>
          ) : null}
          <Grid item md={2} style={{ marginRight: '10px' }}>
            {manufacturers?.[0] && (
              <FormControl variant="outlined" fullWidth>
                <Autocomplete
                  disablePortal
                  fullWidth
                  inputValue={manufacturerVal}
                  onInputChange={(_, newVal) => onManufacturerChange(newVal)}
                  options={manufacturers}
                  getOptionLabel={(option) => option.label}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Manufacturer'}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }, [
    classes,
    inventoryFilter,
    manufacturers,
    selectedBranchIDs,
    manufacturerVal,
    categVal,
    categories,
    onChangeInputKeyword,
    onChangeCategory,
    onManufacturerChange,
    handleBranchChange
  ]);

  const RenderButtonsGroup = useCallback(() => {
    return (
      <Grid container spacing={5} alignItems="center" justify="space-evenly">
        <Grid item spacing={5} className={classes.buttonsContainer}>
          <Grid item>
            <Button
              endIcon={<ClearIcon />}
              onClick={onPressClear}
              color="secondary"
            >
              Set To Default
            </Button>
          </Grid>
          <Grid item className={classes.applyBtn}>
            <Button
              disabled={!hasStatus}
              endIcon={<SearchIcon />}
              onClick={() => onInternalApply('computation')}
              color="primary"
              variant="contained"
            >
              APPLY FILTER
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [classes, hasStatus, onInternalApply, onPressClear]);

  const RenderDatePickersFilter = useCallback(() => {
    return (
      <Grid container spacing={4} alignItems="center" justify="center">
        <Grid item>
          <DatePickerRangeComponent
            title="Date Sold (Customer)"
            toDateValue={dateValue('dateSoldTo')}
            fromDateValue={dateValue('dateSoldFrom')}
            fromDateMax={dateToday()}
            toDateMin={inventoryFilter?.sold_date?.from}
            dateToLabel="Date Sold (To)"
            dateFromLabel="Date Sold (From)"
            onChangeToDate={(date) => onChangedDate(date, 'dateSoldTo')}
            onChangeFromDate={(date) => onChangedDate(date, 'dateSoldFrom')}
          />
        </Grid>
        <Grid item>
          <DatePickerRangeComponent
            title="Date Purchased  (Supplier)"
            toDateValue={dateValue('datePurchasedTo')}
            fromDateValue={dateValue('datePurchasedFrom')}
            fromDateMax={dateToday()}
            toDateMin={inventoryFilter?.purchase_date?.from}
            dateToLabel="Date Purchased (To)"
            dateFromLabel="Date Purchased (From)"
            onChangeToDate={(date) => onChangedDate(date, 'datePurchasedTo')}
            onChangeFromDate={(date) =>
              onChangedDate(date, 'datePurchasedFrom')
            }
          />
        </Grid>
        <Grid item>
          <DatePickerRangeComponent
            title="Date Encoded"
            toDateValue={dateValue('dateEncodedTo')}
            fromDateValue={dateValue('dateEncodedFrom')}
            fromDateMax={dateToday()}
            toDateMin={inventoryFilter?.encoded_date?.from}
            dateToLabel="Date Encoded (To)"
            dateFromLabel="Date Encoded (From)"
            onChangeToDate={(date) => onChangedDate(date, 'dateEncodedTo')}
            onChangeFromDate={(date) => onChangedDate(date, 'dateEncodedFrom')}
          />
        </Grid>
      </Grid>
    );
  }, [dateValue, onChangedDate, inventoryFilter]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Typography color="textPrimary" gutterBottom variant="h3">
          Inventory
        </Typography>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            {RenderInputFilters()}
            <Divider className={classes.divider} />
            {RenderStatusFilters()}
            <Divider className={classes.divider} />
            {RenderDatePickersFilter()}
            <Divider className={classes.divider} />
            {RenderButtonsGroup()}
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default InventoryFilter;
