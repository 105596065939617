import React, { useState } from 'react';
import { Page } from 'src/components';
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { slices } from 'src/redux';
import { copyToClipboard } from 'src/utils';
import { usePermissions, useSnackBar } from 'src/hooks';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  codeField: {
    padding: 50,
    border: '5px solid #DE0D3E',
    borderRadius: '25px',
    color: 'white',
    margin: '15px'
  },
  nameText: {
    color: 'white'
  }
}));

const { EventCodeApi: CodeGeneratorApi } = slices.eventCode;

const EventCodeGenerator = () => {
  const classes = useStyles();
  const [codeUrl, getCodeUrl] = useState<string>('');
  const [redirectLink, getRedirectLink] = useState<string>('');
  const [prodId, getProdId] = useState<any>('');
  const snackBar = useSnackBar();
  const { canViewCodeEvent, canGenerateCodeEvent } = usePermissions();

  const GenerateCode = async () => {
    const resp = await CodeGeneratorApi?.createCodeUrl({ product_id: prodId });
    if (resp?.success) {
      getCodeUrl(resp?.originalData?.data?.code);
      getRedirectLink(resp?.originalData?.data?.redirect_link);
    } else if (prodId === '') {
      getCodeUrl('Please input product ID');
    } else {
      getCodeUrl(resp?.message);
    }
  };

  const onCopyText = (text: string, link?: boolean) => {
    let codeText = '';
    if (link) {
      codeText = redirectLink;
    } else {
      codeText = text;
    }
    copyToClipboard(`${codeText}`);
    snackBar.show({
      severity: 'info',
      message: `Copy to clipboard`
    });
  };

  const handleTextBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    getCodeUrl('');
    getProdId(newValue);
  };

  const onResetPress = () => {
    getCodeUrl('');
  };

  if (!canViewCodeEvent) {
    return <div>{`You do not have permission to access this page`}</div>;
  }

  return (
    <Page className={classes.root} title="Event Code Generator">
      <Container>
        <Typography color="textPrimary" gutterBottom variant="h2">
          Code Event Generator
        </Typography>
        <Card
          style={{
            background: `url("/static/images/raffle_decor.png") center center no-repeat, #003E6B left top repeat`,
            backgroundSize: 'auto, cover',
            position: 'relative',
            marginBottom: '40px'
          }}
        >
          <CardContent>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="space-around"
              padding="20px"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <div className={classes.codeField}>
                  <Typography variant="h1" className={classes.nameText}>
                    {codeUrl ? `${codeUrl}` : 'PC Worth Code Generator'}
                  </Typography>
                </div>
                <Button
                  color="inherit"
                  size="large"
                  variant="outlined"
                  onClick={() =>
                    onCopyText(codeUrl ? codeUrl || '' : 'No generated code')
                  }
                  style={{ marginBottom: '15px', color: 'white' }}
                  startIcon={<FileCopyIcon />}
                >
                  Copy Code
                </Button>
                <Button
                  color="inherit"
                  size="large"
                  variant="outlined"
                  onClick={() =>
                    onCopyText(
                      codeUrl ? codeUrl || '' : 'No generated code',
                      true
                    )
                  }
                  style={{ marginBottom: '15px', color: 'white' }}
                  startIcon={<FileCopyIcon />}
                >
                  Copy With Link
                </Button>
                <TextField
                  required
                  name="categoryName"
                  variant="filled"
                  label="Enter Product ID"
                  style={{
                    background: 'white',
                    margin: '10px',
                    minWidth: '400px',
                    visibility: canGenerateCodeEvent ? 'visible' : 'hidden'
                  }}
                  onChange={handleTextBoxChange}
                  disabled={!canGenerateCodeEvent}
                />
                {!codeUrl ? (
                  <Button
                    color="secondary"
                    // disabled={validatePasscodeLoading}
                    size="large"
                    variant="contained"
                    onClick={GenerateCode}
                    style={{
                      visibility: canGenerateCodeEvent ? 'visible' : 'hidden'
                    }}
                    disabled={!canGenerateCodeEvent}
                  >
                    GENERATE CODE
                  </Button>
                ) : (
                  <Button
                    color="secondary"
                    // disabled={validatePasscodeLoading}
                    size="large"
                    variant="contained"
                    onClick={onResetPress}
                  >
                    Generate Again
                  </Button>
                )}
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default EventCodeGenerator;
