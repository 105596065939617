import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { ErrorSerialNoList } from 'src/redux/slices/stock-transfer';
import { Box, Button, IconButton, Tooltip } from '@material-ui/core';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import CachedIcon from '@material-ui/icons/Cached';
import useUserInfo from 'src/hooks/user/use-user-info';

interface Props {
  onClearAll: () => void;
  onRetry: (index: number) => void;
  onRemove: (index: number) => void;
  transferErrorList?: ErrorSerialNoList;
}

const Component = ({
  transferErrorList = [],
  onRetry,
  onRemove,
  onClearAll
}: Props) => {
  const { userBranchNameViaId } = useUserInfo();

  return (
    <Box>
      {transferErrorList.length > 0
        ? transferErrorList?.map((errorSN, index) => (
            <Alert
              style={{ marginBottom: 1 }}
              key={`${errorSN?.serial_no}${index}`}
              severity="error"
              action={
                <div>
                  <Tooltip title="Retry" placement="top">
                    <IconButton
                      aria-label="retry"
                      color="inherit"
                      size="medium"
                      onClick={() => {
                        onRetry(index);
                      }}
                    >
                      <CachedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Remove" placement="top">
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="medium"
                      onClick={() => {
                        onRemove(index);
                      }}
                    >
                      <DeleteOutlineIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                </div>
              }
            >
              <AlertTitle>
                {errorSN?.serial_no} - {userBranchNameViaId(errorSN?.branch_id)}
              </AlertTitle>
              {errorSN?.message}
            </Alert>
          ))
        : null}
      {transferErrorList.length > 0 ? (
        <Box display="flex" justifyContent="flex-end">
          <Button onClick={onClearAll} color="secondary">
            Clear All Invalid Serial No(s)
          </Button>
        </Box>
      ) : null}
    </Box>
  );
};

export const TransferListError = React.memo(Component);
