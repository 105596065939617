import { createSlice } from '@reduxjs/toolkit';
import { Banner } from 'src/types/ecomm/banner';
import * as thunks from './thunks';

type State = {
  banners: Banner[];
  isBannersLoading: boolean;
};

const initialState: State = {
  banners: [],
  isBannersLoading: false
};

const slice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    clearBanners: (state) => {
      state.banners = [];
    }
  },
  extraReducers(builder) {
    builder.addCase(thunks.getEcommBannersThunk.pending, (state) => {
      state.isBannersLoading = true
    });
    builder.addCase(thunks.getEcommBannersThunk.rejected, (state) => {
      state.isBannersLoading = false
    });
    builder.addCase(
      thunks.getEcommBannersThunk.fulfilled,
      (state, { payload }) => {
        state.isBannersLoading = false
        state.banners = payload.originalData.data || [];
      }
    );
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
