import React, { ChangeEvent, FC, memo, useCallback, useRef } from 'react';
import { Editor } from '@tiptap/react';
import { Box, Button, Tooltip } from '@material-ui/core';

import ImageIcon from '@material-ui/icons/Image';
import YouTubeIcon from '@material-ui/icons/YouTube';

interface Props {
  editor: Editor | null;
}

const component: FC<Props> = ({ editor }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  //need to prevent undefined on editor
  //always declare first beefore any editor
  if (!editor) {
    return null;
  }

  //want to hide input
  const handleUpload = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (!file) return;

      const reader = new FileReader();

      reader.onloadend = () => {
        const newImage = reader.result?.toString();
        if (newImage) {
          editor
            .chain()
            .focus()
            .setImage({ src: newImage })
            .run();
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleAddYoutubeVideo = () => {
    const url = prompt('Enter YouTube URL');

    if (url) {
      editor.commands.setYoutubeVideo({
        src: url,
        // width: Math.max(320, parseInt(width, 10)) || 640,
        // height: Math.max(180, parseInt(height, 10)) || 480
      });
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
      <div>
        {/*The input is intended to be hide and the button will do the work */}
        <input
          type="file"
          onChange={handleImageChange}
          style={{ display: 'none' }}
          ref={inputRef}
          accept=".png,.jpeg,.jpg"
        />
        <Tooltip title="Add Image">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleUpload}
            style={{ padding: 3 }}
          >
            <ImageIcon />
          </Button>
        </Tooltip>
      </div>
      <div style={{ marginLeft: 3 }}>
        <Tooltip title="Add youtube video">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAddYoutubeVideo}
            style={{ padding: 3 }}
          >
            <YouTubeIcon />
          </Button>
        </Tooltip>
      </div>
    </Box>
  );
};

export const ImageOption = memo(component);
