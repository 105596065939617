import { createSlice } from '@reduxjs/toolkit';
import { Audit } from 'src/types/audit';
import * as thunks from './thunks';

type State = {
  audits: Audit[];
};

const initialState: State = {
  audits: []
};

const slice = createSlice({
  name: 'audits',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(thunks.getAuditsThunk.fulfilled, (state, { payload }) => {
      state.audits = payload.originalData.audits;
    });
  }
});

export const reducer = slice.reducer;

export const actions = {
  ...slice.actions,
  ...thunks
};
