import { Box, Button, FormControl, TextField } from '@material-ui/core';
import React, { FC } from 'react';

interface Props {
  options: any[];
  inputValue: string;
  placeholder: string;
  onInputChange: (val: string) => void;
  onSearchKeyWord: () => void;
}

export const SearchBox: FC<Props> = ({
  inputValue,
  placeholder,
  onInputChange,
  onSearchKeyWord
}) => {
  const onEnterPress = (e: any) => {
    e.key === 'Enter' && onSearchKeyWord();
  };

  return (
    <Box display={'flex'} alignItems={'center'}>
      <FormControl
        variant="outlined"
        style={{
          width: '200px',
          marginRight: '1em'
        }}
      >
        <TextField
          value={inputValue}
          variant="outlined"
          placeholder={placeholder}
          onKeyDown={(e) => onEnterPress(e)}
          onChange={(e) => {
            const val = e.target.value;
            onInputChange(val);
          }}
        />
      </FormControl>
      <Button onClick={onSearchKeyWord} color="primary" variant="contained">
        Search
      </Button>
    </Box>
  );
};
