import React, { FC, memo } from 'react';
import { View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { warrantyTextStyles } from 'src/views/transactions/TransactionPDF/styles';
import { drjInfo } from 'src/constants';

const styles = StyleSheet.create({
  viewParent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: 15,
    marginTop: 5
  },
  infoContainer: {
    flex: 4,
    marginLeft: 10,
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: { height: 50, width: 60 },
  row: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: 0
  }
});

interface Props {}

const component: FC<Props> = () => {
  return (
    <View style={styles.viewParent}>
      <View>
        <Image
          style={styles.logo}
          src="/static/images/avatars/pcworth_logo.png"
        />
      </View>
      <View style={styles.infoContainer}>
        <Text style={warrantyTextStyles.text}>
          Owned and Operated by:
          <Text style={warrantyTextStyles.textBold}> {drjInfo.name}</Text>
        </Text>
        <Text style={warrantyTextStyles.text}>Location:{drjInfo.location}</Text>

        <Text style={warrantyTextStyles.text}>
          <Text style={warrantyTextStyles.textBold}>Website:</Text>
          <Text style={warrantyTextStyles.url}> {drjInfo.website}</Text>
          <Text> </Text>
          <Text style={warrantyTextStyles.textBold}>Facebook:</Text>
          <Text style={warrantyTextStyles.url}> {drjInfo.facebook}</Text>
        </Text>

        <Text style={warrantyTextStyles.text}>
          <Text style={warrantyTextStyles.textBold}>Email:</Text>
          <Text style={warrantyTextStyles.url}> {drjInfo.email}</Text>
          <Text> </Text>
          <Text style={warrantyTextStyles.textBold}>Viber:</Text>
          <Text style={warrantyTextStyles.url}> {drjInfo.cellNo2}</Text>
        </Text>

        <Text style={warrantyTextStyles.text}>
          <Text style={warrantyTextStyles.textBold}>Tel No:</Text>
          {drjInfo.telNo}
          <Text> </Text>
          <Text style={warrantyTextStyles.textBold}>Cell No:</Text>
          {drjInfo.cellNo}
        </Text>
      </View>
    </View>
  );
};

export const ViewQuotationHeaderSlip = memo(component);
