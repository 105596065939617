import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  CurrentNonSnList,
  SelectedBranch,
  SerialNoProductData,
  TransferSnReceivingErrors,
  TransferStockState
} from './types';
import * as thunks from './thunks';

const initialState: TransferStockState = {
  isProductSnLoading: false,
  isTransferStockLoading: false,
  isTransferStockRecordLoading: false,
  isGetProductNonSnLoading: false,

  transferStockNumber: '',
  selectedBranch: {},
  currentNonSnList: [],

  //only listing id for api payload
  originBranchListingId: [],
  receivingBranchListingId: [],
  originBranchItemList: [],
  receivingBranchItemList: [],
  transferStockReceivingProducts: [],
  receivedStatus: '',

  //receiving stocks revamp
  transferStockStatusProductReceived: [],
  nonSnProductStNos: [],
  transferSnErrorList: []
};

const addOriginListingId = (
  state: TransferStockState,
  { payload }: PayloadAction<{ originListing?: number[] }>
) => {
  state.originBranchListingId = payload.originListing || [];
};

const addReceivingListingId = (
  state: TransferStockState,
  { payload }: PayloadAction<{ receivingListing?: number[] }>
) => {
  state.receivingBranchListingId = payload.receivingListing || [];
};

const clearAllOriginBranchItems = (state: TransferStockState) => {
  state.originBranchItemList = [];
};

const clearAllReceivingBranchItems = (state: TransferStockState) => {
  state.receivingBranchItemList = [];
};

const removeProdOnBranchList = (
  state: TransferStockState,
  {
    payload
  }: PayloadAction<{
    newBranchItem?: SerialNoProductData[];
    newCurrentNonSnList?: CurrentNonSnList[];
  }>
) => {
  state.originBranchItemList = payload.newBranchItem || [];
  state.currentNonSnList = payload.newCurrentNonSnList || [];
};

const handleCheckedListingAction = (
  state: TransferStockState,
  {
    payload
  }: PayloadAction<{
    action: 'origin' | 'receiving';
    newBranchItem: SerialNoProductData;
  }>
) => {
  switch (payload.action) {
    case 'origin':
      return {
        ...state,
        originBranchItemList: state.originBranchItemList.map((item) =>
          item.id === payload.newBranchItem.id
            ? { ...item, isChecked: payload.newBranchItem.isChecked }
            : item
        )
      };
    case 'receiving':
      return {
        ...state,
        receivingBranchItemList: state.receivingBranchItemList.map((item) =>
          item.id === payload.newBranchItem.id
            ? { ...item, isChecked: payload.newBranchItem.isChecked }
            : item
        )
      };
    default:
      return state;
  }
};

const addErrorReceivingSnAction = (
  state: TransferStockState,
  { payload }: PayloadAction<TransferSnReceivingErrors[]>
) => {
  state.transferSnErrorList = [...state.transferSnErrorList, ...payload];
};

const removeErrorReceivingAction = (
  state: TransferStockState,
  { payload }: PayloadAction<TransferSnReceivingErrors[]>
) => {
  state.transferSnErrorList = payload;
};

const updateOriginBranchItemList = (
  state: TransferStockState,
  { payload }: PayloadAction<SerialNoProductData[]>
) => {
  state.originBranchItemList = payload || [];
};

const updateReceivingBranchItemList = (
  state: TransferStockState,
  { payload }: PayloadAction<SerialNoProductData[]>
) => {
  state.receivingBranchItemList = payload || [];
};

const transferToReceivingBranch = (
  state: TransferStockState,
  {
    payload
  }: PayloadAction<{
    newReceivingBranch: SerialNoProductData[];
    newOriginBranch: SerialNoProductData[];
  }>
) => {
  state.receivingBranchItemList = [
    ...state.receivingBranchItemList,
    ...payload.newReceivingBranch
  ];
  state.originBranchItemList = payload.newOriginBranch;

  //empty the non current sn list when transfer success
  state.currentNonSnList = [];
};

const transferBackToOriginBranch = (
  state: TransferStockState,
  {
    payload
  }: PayloadAction<{
    newOriginBranch: SerialNoProductData[];
    newReceivingBranch: SerialNoProductData[];
  }>
) => {
  state.originBranchItemList = [
    ...state.originBranchItemList,
    ...payload.newOriginBranch
  ];
  state.receivingBranchItemList = payload.newReceivingBranch;
};

//=== Transfer receiving stock modules actions===
const setCurrentSTNumber = (
  state: TransferStockState,
  { payload }: PayloadAction<string>
) => {
  state.transferStockNumber = payload;
};

const updateProductStatus = (
  state: TransferStockState,
  { payload }: PayloadAction<string>
) => {
  const serialNumber = payload;
  state.transferStockReceivingProducts = state.transferStockReceivingProducts.map(
    (item) => {
      if (item?.serial_no === serialNumber) {
        return {
          ...item,
          received: 1
        };
      }
      // Return the original item if it doesn't match the serial number
      return item;
    }
  );
};

const clearCurrentReceivingProductList = (state: TransferStockState) => {
  state.transferStockReceivingProducts = [];
};

const getSelectedOriginBranch = (
  state: TransferStockState,
  { payload }: PayloadAction<SelectedBranch>
) => {
  state.selectedBranch = payload;
};

const slice = createSlice({
  name: 'transfer-stock',
  initialState,
  //TODO: build all transfer stock using reducer and actions
  reducers: {
    addOriginListingId,
    addReceivingListingId,
    clearAllOriginBranchItems,
    clearAllReceivingBranchItems,
    removeProdOnBranchList,
    transferToReceivingBranch,
    transferBackToOriginBranch,
    handleCheckedListingAction,
    updateOriginBranchItemList,
    updateReceivingBranchItemList,

    //===for transfer receiving module===
    setCurrentSTNumber,
    updateProductStatus,
    clearCurrentReceivingProductList,

    //=== for non serialize product ===
    getSelectedOriginBranch,

    //=== for transfer error receiving sn ===
    addErrorReceivingSnAction,
    removeErrorReceivingAction
  },
  extraReducers(builder) {
    builder.addCase(
      thunks.getBranchProductViaSerialNoThunk.pending,
      (state) => {
        state.isProductSnLoading = true;
      }
    );
    builder.addCase(
      thunks.getBranchProductViaSerialNoThunk.rejected,
      (state) => {
        state.isProductSnLoading = false;
      }
    );
    builder.addCase(
      thunks.getBranchProductViaSerialNoThunk.fulfilled,
      (state, { payload }) => {
        state.isProductSnLoading = false;

        const toBeInsertedData = payload?.originalData?.data;

        const alreadyExist = state.originBranchItemList.find(
          (x) => x.serial_no === toBeInsertedData?.serial_no
        );

        if (alreadyExist) {
          return;
        }

        if (toBeInsertedData) {
          state.originBranchItemList = [
            ...state.originBranchItemList,
            {
              ...toBeInsertedData,
              isChecked: true
            }
          ];
        }

        //push also on non current sn via product name
        const existingNonSnViaProductName = state.currentNonSnList?.find(
          (nonSn) => nonSn?.product_name === toBeInsertedData?.product_name
        );
        if (existingNonSnViaProductName) {
          existingNonSnViaProductName.current_serial_nos?.push(
            toBeInsertedData?.serial_no || ''
          );
        }
      }
    );

    //===transfer receiving stock===
    builder.addCase(thunks.getTransferStockRecordThunk.pending, (state) => {
      state.isTransferStockRecordLoading = true;
    });
    builder.addCase(thunks.getTransferStockRecordThunk.rejected, (state) => {
      state.isTransferStockRecordLoading = false;
    });
    builder.addCase(
      thunks.getTransferStockRecordThunk.fulfilled,
      (state, { payload }) => {
        state.isTransferStockRecordLoading = false;
        state.receivedStatus = payload?.originalData?.status || '';
        state.transferStockReceivingProducts =
          payload?.originalData?.data || [];
      }
    );

    //===transfer receiving stock revamp===
    builder.addCase(
      thunks.updateReceiveSerializedItemsThunk.pending,
      (state) => {
        state.isTransferStockRecordLoading = true;
      }
    );
    builder.addCase(
      thunks.updateReceiveSerializedItemsThunk.rejected,
      (state) => {
        state.isTransferStockRecordLoading = false;
      }
    );
    builder.addCase(
      thunks.updateReceiveSerializedItemsThunk.fulfilled,
      (state, { payload }) => {
        state.isTransferStockRecordLoading = false;
        if (payload?.originalData?.data) {
          state.transferStockStatusProductReceived.push(
            payload.originalData.data
          );
        }
      }
    );

    builder.addCase(
      thunks.updateReceiveNonSerializedItemsThunk.pending,
      (state) => {
        state.isTransferStockRecordLoading = true;
      }
    );
    builder.addCase(
      thunks.updateReceiveNonSerializedItemsThunk.rejected,
      (state) => {
        state.isTransferStockRecordLoading = false;
      }
    );
    builder.addCase(
      thunks.updateReceiveNonSerializedItemsThunk.fulfilled,
      (state, { payload }) => {
        state.isTransferStockRecordLoading = false;
        if (payload?.originalData?.data) {
          state.transferStockStatusProductReceived.push(
            ...(payload.originalData.data || [])
          );
        }
      }
    );

    builder.addCase(thunks.getStNosNonSnProductsThunk.pending, (state) => {
      state.isTransferStockRecordLoading = true;
    });
    builder.addCase(thunks.getStNosNonSnProductsThunk.rejected, (state) => {
      state.isTransferStockRecordLoading = false;
    });
    builder.addCase(
      thunks.getStNosNonSnProductsThunk.fulfilled,
      (state, { payload }) => {
        state.isTransferStockRecordLoading = false;
        if (payload?.originalData?.data) {
          state.nonSnProductStNos = payload?.originalData?.data || [];
        }
      }
    );

    //===transfer non serialize stock===
    builder.addCase(
      thunks.getNonSerializedProductCountThunk.pending,
      (state) => {
        state.isGetProductNonSnLoading = true;
        state.isTransferStockRecordLoading = true;
      }
    );
    builder.addCase(
      thunks.getNonSerializedProductCountThunk.rejected,
      (state) => {
        state.isGetProductNonSnLoading = false;
        state.isTransferStockRecordLoading = false;
      }
    );
    builder.addCase(
      thunks.getNonSerializedProductCountThunk.fulfilled,
      (state, { payload }) => {
        state.isGetProductNonSnLoading = false;

        const nonSnProduct = payload?.originalData?.data?.data;
        const currentList = state?.originBranchItemList || [];

        if (nonSnProduct) {
          const toBeInsertedNonSn = nonSnProduct.map(
            (prod: SerialNoProductData) => {
              return { ...prod, isChecked: true };
            }
          );

          const existingSerialNumbers = new Set(
            currentList.map((item) => item.serial_no)
          );

          const uniqueNonSnProduct = toBeInsertedNonSn.filter((nonSn) => {
            return !existingSerialNumbers.has(nonSn.serial_no);
          });

          nonSnProduct.map((currentNonSn) => {
            //find if already exist in our state?
            const existingNonSnProduct = state.currentNonSnList?.find(
              (newNonSn) =>
                newNonSn?.product_name === currentNonSn?.product_name
            );

            //if yes the push to current serial no array
            if (existingNonSnProduct) {
              existingNonSnProduct?.current_serial_nos?.push(
                currentNonSn?.serial_no || ''
              );
            } else {
              //else we create new obj grouped by product name
              state.currentNonSnList?.push({
                product_name: currentNonSn?.product_name || '',
                current_serial_nos: [currentNonSn?.serial_no || '']
              });
            }
          });

          state.originBranchItemList = [
            ...state.originBranchItemList,
            ...uniqueNonSnProduct
          ];
        }
      }
    );
  }
});

export const reducer = slice.reducer;
export const actions = { ...slice, ...thunks };
