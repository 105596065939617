// TODO: Convert to a component.

import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CustomInputEvent, CustomKeyboardEvent, FixMeLater } from 'src/types';
import { slices, useAppDispatch } from 'src/redux';
import { useSnackBar } from 'src/hooks';
import FormAddDialog from 'src/components/dialogs/FormDialog';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  containsAlphabetsWithSpacesAndDashes,
  isEmptyOrSpaces
} from 'src/utils';

const { actions: supplierActions } = slices.supplier;

interface Props {
  className?: string;
  onSearchSupplier: (text: string) => void;
  onAddSupplierGetData: FixMeLater;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  }
}));

const Toolbar = ({
  className,
  onAddSupplierGetData,
  onSearchSupplier,
  ...rest
}: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const dispatch = useAppDispatch();

  const [searchText, setSearchText] = useState('');

  const [isCreateSupplierVisible, setIsCreateSupplierVisible] = useState<
    boolean
  >(false);
  const [addSupplierLoading, setAddSupplierLoading] = useState<boolean>(false);

  const onOpenCreateSupplierDialog = () => {
    setIsCreateSupplierVisible(true);
  };

  const onCloseCreateSupplierDialog = () => {
    setIsCreateSupplierVisible(false);
    setAddSupplierLoading(false);
  };

  const onAddSupplierOnModalPress = async (name: string) => {
    // TODO: Add checker loader before close.
    setAddSupplierLoading(true);
    if (
      !name ||
      isEmptyOrSpaces(name) ||
      !containsAlphabetsWithSpacesAndDashes(name)
    ) {
      snackBar.show({
        severity: 'error',
        message: 'Please provide proper Supplier name'
      });
      setAddSupplierLoading(false);
      return;
    }

    if (name) {
      const response = unwrapResult(
        await dispatch(supplierActions.createSupplierThunk({ name }))
      );
      if (response.success) {
        snackBar.show({ severity: 'success', message: response.message });
        onCloseCreateSupplierDialog();
        onAddClick();
      } else {
        snackBar.show({ severity: 'error', message: response.message });
        setAddSupplierLoading(false);
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.'
      });
    }
  };

  const handleChange = (event: CustomInputEvent) => {
    setSearchText(event.target.value);
  };

  const onSearchClick = () => {
    onSearchSupplier(searchText);
  };

  const onAddClick = () => {
    onAddSupplierGetData();
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchSupplier(searchText);
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography color="textPrimary" gutterBottom variant="h3">
          Suppliers
        </Typography>
        <Button
          onClick={onOpenCreateSupplierDialog}
          color="primary"
          variant="contained"
        >
          add supplier
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid alignItems="center" container spacing={2}>
              <Grid item lg={4} md={6} xs={12}>
                <Box maxWidth={500}>
                  <TextField
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search supplier"
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>
              <Grid alignItems="center" item xs={1}>
                <Button
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <FormAddDialog
          loading={addSupplierLoading}
          fieldName="Supplier"
          title="Create Supplier"
          isVisible={isCreateSupplierVisible}
          subTitle="Input Supplier"
          onAddPress={onAddSupplierOnModalPress}
          handleClose={onCloseCreateSupplierDialog}
        />
      </Box>
    </div>
  );
};

export default Toolbar;
