export enum ListingStatusEnum {
  Available = 'available',
  Transfer = 'transfer stock',
  Defective = 'defective',
  Purchased = 'purchased',
  ForWarranty = 'for warranty',
  OnHold = 'on hold'
}

export enum UserStatusEnum {
  Confirmed = 'confirmed',
  Pending = 'pending'
}

export enum ListingMenuEnum {
  Delete = 'DELETE',
  SetDefective = 'SET_DEFECTIVE',
  Edit = 'EDIT',
  SetOnHold = 'SET_ON_HOLD',
  SetAvailable = 'SET_AVAILABLE',
  SetPurchased = 'SET_PURCHASED',
  SetForWarranty = 'SET_FOR_WARRANTY',
  //TODO: Rma is deprecated, remove later
  // SetForRma = 'SET_FOR_RMA',
  ViewHistory = 'VIEW UPDATES HISTORY'
}

export enum TransactionsMenuEnum {
  Delete = 'DELETE',
  Edit = 'EDIT'
}

export enum ListingPriceTypeEnum {
  NotFree = 1,
  FreeFromSupplier = 2,
  FreeForAll = 3
}
