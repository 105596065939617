/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Box, Container, makeStyles, Typography } from '@material-ui/core';
import { Page } from 'src/components';
import { ToolBar } from './components/Toolbar';
import { BlogCategoryList } from './components/BlogCategoryList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const BlogCategoryView = () => {
  const classes = useStyles();
  return (
    <Page title="Blog Category " className={classes.root}>
      <Container maxWidth={false}>
        <Typography color="textPrimary" gutterBottom variant="h3">
          Blog Category
        </Typography>
        <ToolBar />
        <BlogCategoryList />
      </Container>
    </Page>
  );
};

export default BlogCategoryView;
