import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { useDebouncedEffect } from 'src/hooks';
import { CustomInputEvent } from 'src/types';
import { isNil } from 'lodash';

interface Props {
  className?: string;
  onSearchProduct: (str: string | undefined) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  }
}));

const Toolbar = ({ className, onSearchProduct, ...rest }: Props) => {
  const classes = useStyles();
  const [searchText, setSearchText] = useState<string | undefined>();

  const handleChange = (event: CustomInputEvent) => {
    setSearchText(event.target.value);
  };

  const searchCustomer = () => {
    if (!isNil(searchText)) {
      onSearchProduct(searchText);
    }
  };

  useDebouncedEffect(searchCustomer, 500, [searchText]);

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box mt={2}>
        <Card>
          <CardContent>
            <Grid alignItems="center" container spacing={2}>
              <Grid item lg={4} md={6} xs={12}>
                <Box maxWidth={500}>
                  <TextField
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search listing serial no."
                    variant="outlined"
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

export default Toolbar;
