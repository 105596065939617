import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectApp = (state: RootState) => state.app;

const selectSnackBar = createSelector(selectApp, (state) => state.snackBar);

const selectAlertGlobal = createSelector(
  selectApp,
  (state) => state.alertGlobal
);

const selectOpenDrawerSectionIndices = createSelector(
  selectApp,
  (state) => state.openDrawerSectionIndices
);

export const selectors = {
  selectSnackBar,
  selectAlertGlobal,
  selectOpenDrawerSectionIndices
};
