import { isValidCurrency } from './stringUtils';

type ExtraOptionsType = Intl.NumberFormatOptions & { removeCurrency?: boolean };

export const promoPriceToSRP = (
  num: number | undefined | null,
  showSRP: boolean = true
) => {
  let retailPrice = num || 0;
  const toAddIfSRP = retailPrice * 0.12;

  if (showSRP) {
    retailPrice = retailPrice + toAddIfSRP;
    // retailPrice = Math.round(retailPrice);
  }
  return retailPrice;
};

export const isNormalInteger = (str: string | number | undefined) => {
  if (str === 1 || str === '1') {
    return true;
  }
  const n = Math.floor(Number(str));
  return n !== Infinity && String(n) === str && n >= 0;
};

export const getNumberOrZero = (param: number | undefined | null) => {
  return param || 0;
};

// Tread lightly check peso sign on transaction slip if you're going to change this.
export const formatCurrency = (
  amount: any,
  // eslint-disable-next-line no-unused-vars
  symbol: string = '₱',
  decimal = 2,
  extraOptions?: ExtraOptionsType
) => {
  // Sometimes, 'strings' can still pass amount type.
  // and will error TypeError: amount.toFixed is not a function
  // in that case convert amount params type into number
  if (typeof amount !== 'number') {
    if (isValidCurrency(amount)) {
      formatCurrency(+amount);
    } else {
      return '';
    }
  }
  if (amount === null || amount === undefined) {
    return '';
  }
  // Create our number formatter.
  const formatter = new Intl.NumberFormat('en-PH', {
    style: 'currency',
    currency: 'PHP',
    minimumFractionDigits: decimal,
    ...extraOptions
  });

  let currencyInStr = `${formatter.format(amount)}`;

  if (extraOptions?.removeCurrency && amount >= 0) {
    currencyInStr = currencyInStr.substring(1);
  }

  if (extraOptions?.removeCurrency && amount < 0) {
    currencyInStr = currencyInStr.substring(2);
    currencyInStr = `-${currencyInStr}`;
  }

  return currencyInStr;
};

export const getRandomInt = (min: number = 654, max: number = 987) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const between = (x: number, min: number, max: number) => {
  return x >= min && x <= max;
};

export const removeNonDigits = (input: string): string =>
  input?.replace(/\D/g, '');
