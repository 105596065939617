import React, { useCallback, useEffect, useState } from 'react';
import QRCode from 'qrcode';

import {
  Box,
  Button,
  Card,
  Divider,
  Typography,
  makeStyles
} from '@material-ui/core';
import { SwitchLabel } from 'src/components';
import {
  CommonAxiosResponse,
  CreateTemplateResponse,
  TemplateDetails
} from 'src/types';
import {
  SaveAltOutlined,
  CameraAlt,
  RotateLeft,
  Clear,
  Add,
  Beenhere,
  AddToQueue,
  Link,
  FileCopy
} from '@material-ui/icons';

import PrintIcon from '@material-ui/icons/Print';
import { isEmpty } from 'lodash';
import { Alert } from '@material-ui/lab';
import { useSnackBar } from 'src/hooks';
import { copyToClipboard } from 'src/utils';

interface Props {
  showSRP: boolean;
  showCleanSrpQuote: boolean;
  hasCompatibility: boolean;
  template: TemplateDetails | undefined;
  isCategoryLoading?: boolean;
  isSelectedTemplate: boolean;
  onHandlePrintQuotation: () => void;
  onAddInStockRow: () => void;
  onAddCustomRow: () => void;
  onResetNew: () => void;
  onScreenShot: () => void;
  onRemoveEmptyFields: () => void;
  onCheckAvailability: () => void;
  onToggleShowSRPSwitch: (value: boolean) => void;
  onToggleShowCleanSRPSwitch: (value: boolean) => void;
  onToggleHasCompatibility: (value: boolean) => void;
  onSaveTemplate: () => void;
  onDeleteTemplate: () => void;
  isPCBuild: number;
  setIsPCBuild: (value: number) => void;
  isBoost: number;
  setIsBoost: (value: number) => void;
  onGenerateAddToCartLink: () => any; // intended any for some reasone can't enter CommonAxiosResponse<GetTemplateDetailsResponse> | undefined
}

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(15),
    padding: theme.spacing(2)
  },
  buttonContainer: {
    margin: theme.spacing(1.5)
  },
  switchContainer: {
    margin: theme.spacing(0)
  },
  qrCodeImg: {
    // width: '100%',
    // height: '100%'
  }
}));

export const FooterButtons = ({
  showSRP = false,
  hasCompatibility = true,
  showCleanSrpQuote = false,
  template,
  isCategoryLoading,
  isSelectedTemplate,
  onHandlePrintQuotation,
  onToggleShowSRPSwitch,
  onToggleHasCompatibility,
  onAddInStockRow,
  onAddCustomRow,
  onResetNew,
  onRemoveEmptyFields,
  onScreenShot,
  onCheckAvailability,
  onToggleShowCleanSRPSwitch,
  onSaveTemplate,
  onDeleteTemplate,
  isPCBuild,
  setIsPCBuild,
  isBoost,
  setIsBoost,
  onGenerateAddToCartLink
}: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();

  const [walkinUriQR, setWalkInUriQR] = useState<string>('');
  const [walkInFullLink, setWalkInFullLink] = useState<string>('');

  const getTransactionNoUrl = useCallback(() => {
    try {
      if (walkInFullLink) {
        QRCode.toDataURL(walkInFullLink, { errorCorrectionLevel: 'H' })
          .then((url) => setWalkInUriQR(url))
          .catch((err) => console.error(err));
      }
    } catch (error) {
      console.error(error);
    }
  }, [walkInFullLink]);

  const onGenerateLink = async () => {
    // TODO: ADD LOADER. Clear qr code and link immediately even before generating link
    setWalkInUriQR('');
    setWalkInFullLink('');

    const generateResponse:
      | CommonAxiosResponse<CreateTemplateResponse>
      | undefined = await onGenerateAddToCartLink();

    const addToCartLink = generateResponse?.originalData?.add_to_user_cart_link;
    if (addToCartLink) {
      alert(
        'Please ensure that the customer already has account on pcworth.com before sending the link.'
      );
      setWalkInFullLink(addToCartLink);
      snackBar.show({
        severity: 'success',
        message: `Link generated successfully. Link copied to clipboard`,
        useSound: true
      });
      copyToClipboard(addToCartLink);
      return;
    }

    setWalkInFullLink('');
    snackBar.show({
      severity: 'error',
      message: `There was an error generating the add to cart link`,
      useSound: true
    });
  };

  useEffect(getTransactionNoUrl, [getTransactionNoUrl]);

  return (
    <Card
      elevation={5}
      className={classes.root}
      style={{ position: 'relative', margin: 10, padding: 1 }}
    >
      {isCategoryLoading ? (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(240, 240, 240, 0.5)',
            display: 'grid',
            placeContent: 'center',
            zIndex: 1000
          }}
        />
      ) : null}
      <div>
        <SwitchLabel
          disabled
          className={classes.switchContainer}
          label="Allow Compatibility"
          checked={hasCompatibility}
          onChange={(v) => onToggleHasCompatibility(v)}
        />
        <SwitchLabel
          className={classes.switchContainer}
          label="Show SRP comparison"
          checked={showSRP ? true : false}
          onChange={(v) => onToggleShowSRPSwitch(v)}
          disabled={!isEmpty(isCategoryLoading)}
        />
        <SwitchLabel
          className={classes.switchContainer}
          label="PC Build"
          checked={isPCBuild ? true : false}
          onChange={(v) => setIsPCBuild(v ? 1 : 0)}
        />
        <SwitchLabel
          className={classes.switchContainer}
          label="Boost"
          checked={isBoost ? true : false}
          onChange={(v) => setIsBoost(v ? 1 : 0)}
        />
        {showSRP ? (
          <SwitchLabel
            className={classes.switchContainer}
            label="Show clean SRP Quote"
            checked={showCleanSrpQuote ? true : false}
            onChange={(v) => onToggleShowCleanSRPSwitch(v)}
          />
        ) : null}
      </div>
      {showSRP && (
        <Box p={1}>
          <Alert severity="info">
            Printing a quotation slip will display the SRP of items instead of
            promo price
          </Alert>
        </Box>
      )}
      <div>
        <Button
          className={classes.buttonContainer}
          startIcon={<Add />}
          onClick={onAddInStockRow}
          color="primary"
          variant="outlined"
        >
          {`Add "In-Stock" Row`}
        </Button>
        <Button
          className={classes.buttonContainer}
          startIcon={<AddToQueue />}
          onClick={onAddCustomRow}
          color="primary"
          variant="outlined"
        >
          {`Add "Custom / Pre Order" Row`}
        </Button>
        <Button
          className={classes.buttonContainer}
          startIcon={<CameraAlt />}
          onClick={onScreenShot}
          color="primary"
          variant="outlined"
        >
          Take Screenshot
        </Button>
        <Button
          className={classes.buttonContainer}
          startIcon={<Beenhere />}
          onClick={onCheckAvailability}
          color="primary"
          variant="contained"
        >
          Check Availability
        </Button>
        <Button
          className={classes.buttonContainer}
          startIcon={<PrintIcon />}
          onClick={onHandlePrintQuotation}
          color="primary"
          variant="contained"
          disabled={isSelectedTemplate}
        >
          Print Quotation Slip
        </Button>
      </div>
      <div>
        <Button
          className={classes.buttonContainer}
          startIcon={<RotateLeft />}
          onClick={onResetNew}
          color="secondary"
          variant="outlined"
        >
          Reset to New
        </Button>
        <Button
          className={classes.buttonContainer}
          startIcon={<Clear />}
          onClick={onRemoveEmptyFields}
          color="secondary"
          variant="outlined"
        >
          Remove Empty Fields
        </Button>
      </div>

      <div>
        {template && (
          <Button
            className={classes.buttonContainer}
            startIcon={<Clear />}
            onClick={onDeleteTemplate}
            color="secondary"
            variant="outlined"
          >
            Delete
          </Button>
        )}
        <Button
          className={classes.buttonContainer}
          startIcon={<SaveAltOutlined />}
          onClick={onSaveTemplate}
          color="primary"
          variant="outlined"
        >
          Save Template
        </Button>
      </div>

      <Divider />

      <div>
        {walkinUriQR ? (
          <img
            src={walkinUriQR}
            alt="WALK IN QR CODE"
            className={classes.qrCodeImg}
          />
        ) : null}

        {walkInFullLink ? (
          <Typography
            color="primary"
            className={classes.buttonContainer}
            variant="h6"
          >
            {walkInFullLink}
          </Typography>
        ) : null}

        {walkInFullLink ? (
          <Typography
            color="error"
            className={classes.buttonContainer}
            variant="h4"
          >
            Always make sure customer has pcworth.com account and that they get
            the proper items from their cart
          </Typography>
        ) : null}

        {walkInFullLink ? (
          <Button
            className={classes.buttonContainer}
            startIcon={<FileCopy />}
            onClick={() => copyToClipboard(walkInFullLink)}
            color="primary"
            variant="outlined"
          >
            Copy - {walkInFullLink}
          </Button>
        ) : null}

        <Button
          className={classes.buttonContainer}
          startIcon={<Link />}
          onClick={onGenerateLink}
          color="primary"
          variant="contained"
        >
          GENERATE QUOTATION LINK
        </Button>
      </div>
    </Card>
  );
};

export const QuotationFooterButtons = React.memo(FooterButtons);
