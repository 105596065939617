import React, { useMemo, useState } from 'react';
import { cloneDeep, mapValues } from 'lodash';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
  makeStyles,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { LoadingButton, Page } from 'src/components';
import { Customer, CustomInputEvent } from 'src/types';
import { toTitleCase } from 'src/utils';
import { useNavigate } from 'react-router-dom';
import { useSnackBar } from 'src/hooks';
import usePrompt from 'src/utils/navigation-prompt';
import { slices, useAppDispatch } from 'src/redux';
import { unwrapResult } from '@reduxjs/toolkit';

interface Props {
  className?: string;
}

const useStyles = makeStyles(() => ({
  root: {},
  contactCard: {
    marginTop: 20
  }
}));

const { actions: createCustomerServiceActions } = slices.customerService;

const AccountView = ({ className, ...rest }: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isCustomerLoading, setIsCustomerLoading] = useState<boolean>(false);
  const [addTransaction, setAddTransaction] = useState<boolean>(true);
  const [values, setValues] = useState({
    first_name: '',
    middle_name: '',
    last_name: '',
    email: '',
    address: '',
    social_media: '',
    contact_no: '',
    notes: ''
  });

  const isCustomerFieldClean = useMemo(
    () =>
      Object.values(values).every(
        (x) => x === undefined || x === null || x === ''
      ),
    [values]
  );

  const shouldPromptAppear = useMemo(() => {
    if (!isCustomerFieldClean || isCustomerLoading) {
      return true;
    }
    return false;
  }, [isCustomerFieldClean, isCustomerLoading]);

  const promptMessage = useMemo(() => {
    if (isCustomerLoading) {
      return 'Customer Information Saving. Are you sure you want to exit?';
    }
    if (!isCustomerFieldClean) {
      return 'There are some unsaved customer information. Are you sure you want to exit?';
    }
    return 'Are you sure you want to exit?';
  }, [isCustomerFieldClean, isCustomerLoading]);

  usePrompt(promptMessage, shouldPromptAppear);

  const handleChange = (event: CustomInputEvent) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };

  const clearAllFields = () => {
    let valuesCopy = cloneDeep(values);
    valuesCopy = mapValues(valuesCopy, () => '');
    setValues(valuesCopy);
  };

  const onCreateCustomer = async () => {
    setIsCustomerLoading(true);
    if (values.first_name === '' || values.last_name === '') {
      snackBar.show({
        severity: 'error',
        message: 'First name or last name is required.'
      });
      setIsCustomerLoading(false);
      return;
    }
    if (values.contact_no.length !== 11) {
      snackBar.show({
        severity: 'error',
        message: 'Please input valid contact number.'
      });
      setIsCustomerLoading(false);
      return;
    }

    values.first_name = toTitleCase(values.first_name) || values.first_name;
    values.middle_name = toTitleCase(values.middle_name) || values.middle_name;
    values.last_name = toTitleCase(values.last_name) || values.last_name;

    const response = unwrapResult(
      await dispatch(
        createCustomerServiceActions.createCustomerServiceThunk(values)
      )
    );

    setIsCustomerLoading(false);
    if (response?.success) {
      snackBar.show({ severity: 'success', message: response.message });
      clearAllFields();
      if (addTransaction && response?.originalData?.customer) {
        onAddTransaction(response.originalData.customer);
      }
    }
  };

  const saveButtonText = useMemo(() => {
    if (addTransaction) {
      return 'Add Customer and Create Transaction';
    }
    return 'Add Customer';
  }, [addTransaction]);

  const onAddTransaction = (customer: Customer) => {
    navigate('/app/transaction/add', { state: customer });
  };

  return (
    <Page className={classes.root} title="Customers">
      <Container maxWidth={false}>
        <Box mt={2}>
          <form
            autoComplete="off"
            noValidate
            className={clsx(classes.root, className)}
            {...rest}
          >
            <Card>
              <CardHeader title="General Information" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="First name"
                      name="first_name"
                      onChange={handleChange}
                      required
                      value={values.first_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Middle name"
                      name="middle_name"
                      onChange={handleChange}
                      value={values.middle_name}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Last name"
                      name="last_name"
                      onChange={handleChange}
                      required
                      value={values.last_name}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.contactCard}>
              <CardHeader title="Contact Details" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      inputProps={{ maxLength: 11 }}
                      type="tel"
                      label="Contact No."
                      name="contact_no"
                      onChange={handleChange}
                      value={values.contact_no}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Address"
                      name="address"
                      onChange={handleChange}
                      value={values.address}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Email"
                      name="email"
                      onChange={handleChange}
                      value={values.email}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      fullWidth
                      label="Social Media"
                      name="social_media"
                      onChange={handleChange}
                      value={values.social_media}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Card className={classes.contactCard}>
              <CardHeader title="Additional Information" />
              <Divider />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      multiline
                      rows={4}
                      name="notes"
                      onChange={handleChange}
                      value={values.notes}
                      variant="outlined"
                      label="Notes about customer"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
            <Box display="flex" justifyContent="flex-end" p={2}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={addTransaction}
                    name="addTransaction"
                    color="primary"
                    onChange={(_, s) => setAddTransaction(s)}
                  />
                }
                label="Add Transaction After Customer is Saved"
              />
              <LoadingButton
                title={saveButtonText}
                loading={isCustomerLoading}
                onClick={onCreateCustomer}
              />
            </Box>
          </form>
        </Box>
      </Container>
    </Page>
  );
};

export default AccountView;
