import React, { FC } from 'react';
import { Container, Typography, makeStyles } from '@material-ui/core';
import { Page } from 'src/components';
import { BlogToolbar } from './component';
import { BlogList } from './component/BlogList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  productCard: {
    height: '100%'
  }
}));

interface Props {}

export const BlogView: FC<Props> = () => {
  const classes = useStyles();

  return (
    <Page title="Blogs" className={classes.root}>
      <Container maxWidth={false}>
        <Typography color="textPrimary" gutterBottom variant="h3">
          Blog
        </Typography>
        <BlogToolbar />
        <BlogList />
      </Container>
    </Page>
  );
};
