import { createSelector } from 'reselect';
import { RootState } from 'src/redux/store';

const selectProductState = (state: RootState) => state.product;

const selectProductList = createSelector(
  selectProductState,
  (state) => state.products
);

const selectPriceList = createSelector(
  selectProductState,
  (state) => state.priceList
);

const selectPriceListDate = createSelector(
  selectProductState,
  (state) => state.priceListDate
);

const selectPriceListColumns = createSelector(
  selectProductState,
  (state) => state.productTableColumns
);

export const selectors = {
  selectProductList,
  selectPriceList,
  selectPriceListDate,
  selectPriceListColumns
};
