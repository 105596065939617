import Typography from '@material-ui/core/Typography';
import React from 'react';
import { appInformation } from 'src/constants';

export const SystemInfo = () => {
  return (
    <div>
      <Typography color="textSecondary" gutterBottom variant="body2">
        {JSON.stringify(appInformation)}
      </Typography>
    </div>
  );
};
