import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  SvgIcon,
  TextField,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';
import { Search as SearchIcon } from 'react-feather';
import { slices, useAppDispatch } from 'src/redux';
import { useSnackBar } from 'src/hooks';
import { unwrapResult } from '@reduxjs/toolkit';

import { CustomInputEvent, CustomKeyboardEvent } from 'src/types';
import BranchFormDialog from './FormDialog';

const { actions: branchActions } = slices.branch;

interface Props {
  searchBranch: (text: string) => void;
  getBranches: () => void;
  isLoading: boolean;
}

export const Toolbar = ({ searchBranch, getBranches, isLoading }: Props) => {
  const dispatch = useAppDispatch();
  const snackBar = useSnackBar();

  const [searchText, setSearchText] = useState<string>('');
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [isResetState, setIsResetState] = useState<boolean>(false);

  const openDialog = () => {
    setShowDialog(true);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      searchBranch(searchText);
    }
  };

  const onSearchClick = () => {
    searchBranch(searchText);
  };

  const handleChange = (e: CustomInputEvent) => {
    setSearchText(e.target.value);
  };

  const renderBranches = () => {
    getBranches();
  };

  const addNewBranch = async (
    branch_name: string,
    address: string,
    contact_no: string
  ) => {
    if (branch_name && address && contact_no) {
      const response = unwrapResult(
        await dispatch(
          branchActions.createBranchThunk({ branch_name, address, contact_no })
        )
      );

      if (response.success) {
        setIsResetState(true);
        snackBar.show({ severity: 'success', message: response.message });
        closeDialog();
        renderBranches();
      } else {
        snackBar.show({ severity: 'error', message: response.message });
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.'
      });
    }
  };

  return (
    <div>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography color="textPrimary" gutterBottom variant="h3">
          Branches
        </Typography>
        <Button onClick={openDialog} color="primary" variant="contained">
          add branch
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid container spacing={2} alignItems="center">
              <Grid item sm={4} md={3} xs={4}>
                <Box maxWidth={500}>
                  <TextField
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search category"
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>
              <Grid alignItems="center" item xs={1}>
                <Button
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <BranchFormDialog
          loading={isLoading}
          title="Create Branch"
          isVisible={showDialog}
          subTitle="Input Branch"
          onAddPress={addNewBranch}
          handleClose={closeDialog}
          resetForm={isResetState}
        />
      </Box>
    </div>
  );
};
