import React, { useCallback, useMemo } from 'react';
import { TextField, InputAdornment } from '@material-ui/core';

import { useProductPartDetailsLogic } from 'src/hooks';
import {
  CustomInputEvent,
  PartFields,
  ProductPartDetailsCategory
} from 'src/types';

interface Props {
  productCategory: ProductPartDetailsCategory;
  field: PartFields<any>;
}

const component = ({ productCategory, field }: Props) => {
  const {
    fieldError,
    getProductPartDetailsViaKey,
    setProductPartDetailsPropertyViaKey
  } = useProductPartDetailsLogic();

  const detailsValue = useMemo(
    () => getProductPartDetailsViaKey(productCategory) || {},
    [getProductPartDetailsViaKey, productCategory]
  );

  const onFieldChange = async (e: CustomInputEvent) => {
    const { value, name } = e.target;
    setProductPartDetailsPropertyViaKey(productCategory, name, value);
  };

  const fieldErr = useCallback(
    (fieldArg) => {
      return fieldError(productCategory, fieldArg) ? true : false;
    },
    [fieldError, productCategory]
  );

  return (
    <TextField
      fullWidth
      label={field?.label}
      name={field?.part_property}
      required={field?.required}
      variant="outlined"
      error={fieldErr(field?.part_property)}
      type={field?.type}
      helperText={
        fieldError(productCategory, field?.part_property)?.error || field?.hint
      }
      value={detailsValue[field?.part_property] || ''}
      InputProps={{
        endAdornment: field?.unit ? (
          <InputAdornment position="end">{field?.unit || ''}</InputAdornment>
        ) : null
      }}
      onChange={onFieldChange}
    />
  );
};

export const ProductPartFieldInput = React.memo(component);
