/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { Helmet } from 'react-helmet';
import { FixMeLater } from 'src/types';

interface Props {
  children: FixMeLater;
  title: string;
  ref: FixMeLater;
  className: string;
}

export const Page = forwardRef(
  ({ children, title = '', ref, className, ...rest }: Props) => {
    return (
      <div ref={ref} className={className} {...rest}>
        <Helmet>
          <title>{title}</title>
        </Helmet>
        {children}
      </div>
    );
  }
);
