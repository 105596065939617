import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { CustomInputEvent, CustomKeyboardEvent } from 'src/types';
import { unwrapResult } from '@reduxjs/toolkit';
import { slices, useAppDispatch } from 'src/redux';
import { useSnackBar } from 'src/hooks';
import {
  containsAlphabetsWithSpacesAndDashes,
  isEmptyOrSpaces
} from 'src/utils';
import { ManufacturerAddDialog } from './components/ManufacturerAddDialog';

const { actions: manufacturerActions } = slices.manufacturer;
interface Props {
  className?: string;
  getManufacturers: (keyword?: string) => void;
  onSearchManufacturer: (text: string) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  cardContent: {
    flexDirection: 'row'
  }
}));

const Toolbar = ({
  className,
  onSearchManufacturer,
  getManufacturers,
  ...rest
}: Props) => {
  const classes = useStyles();
  const snackBar = useSnackBar();
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState('');

  const [
    isCreateManufacturerVisible,
    setIsCreateManufacturerVisible
  ] = useState<boolean>(false);
  const [addManufacturerLoading, setAddManufacturerLoading] = useState<boolean>(
    false
  );

  const onOpenCreateManufacturerDialog = () => {
    setIsCreateManufacturerVisible(true);
  };

  const onCloseCreateManufacturerDialog = () => {
    setIsCreateManufacturerVisible(false);
    setAddManufacturerLoading(false);
  };

  const onAddManufacturerOnModalPress = async (
    name: string,
    icon_url: string
  ) => {
    // TODO: Add checker loader before close.
    setAddManufacturerLoading(true);
    if (
      !name ||
      isEmptyOrSpaces(name) ||
      !containsAlphabetsWithSpacesAndDashes(name)
    ) {
      snackBar.show({
        severity: 'error',
        message: 'Please provide proper Manufacturer name'
      });
      setAddManufacturerLoading(false);
      return;
    }
    if (name) {
      const newManufacturerData = { name, icon_url };
      const response = unwrapResult(
        await dispatch(
          manufacturerActions.createManufacturerThunk(newManufacturerData)
        )
      );
      if (response.success) {
        snackBar.show({ severity: 'success', message: response.message });
        getManufacturers();
        onCloseCreateManufacturerDialog();
      } else {
        snackBar.show({ severity: 'error', message: response.message });
        setAddManufacturerLoading(false);
      }
    } else {
      snackBar.show({
        severity: 'error',
        message: 'Please fill up all required information.'
      });
    }
  };

  const handleChange = (event: CustomInputEvent) => {
    setSearchText(event.target.value);
  };

  const onSearchClick = () => {
    onSearchManufacturer(searchText);
  };

  const onKeyPress = (e: CustomKeyboardEvent) => {
    if (e.key === 'Enter') {
      onSearchManufacturer(searchText);
    }
  };

  return (
    <div className={clsx(classes.root, className)} {...rest}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-end"
      >
        <Typography color="textPrimary" gutterBottom variant="h3">
          Manufacturers
        </Typography>
        <Button
          onClick={onOpenCreateManufacturerDialog}
          color="primary"
          variant="contained"
        >
          add manufacturer
        </Button>
      </Box>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Grid alignItems="center" container spacing={2}>
              <Grid item lg={4} md={6} xs={12}>
                <Box maxWidth={500}>
                  <TextField
                    fullWidth
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SvgIcon fontSize="small" color="action">
                            <SearchIcon />
                          </SvgIcon>
                        </InputAdornment>
                      )
                    }}
                    placeholder="Search manufacturer"
                    variant="outlined"
                    onKeyPress={onKeyPress}
                  />
                </Box>
              </Grid>
              <Grid alignItems="center" item xs={1}>
                <Button
                  onClick={onSearchClick}
                  color="primary"
                  variant="contained"
                >
                  Search
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <ManufacturerAddDialog
          isVisible={isCreateManufacturerVisible}
          loading={addManufacturerLoading}
          onAddPress={onAddManufacturerOnModalPress}
          handleClose={onCloseCreateManufacturerDialog}
        />
      </Box>
    </div>
  );
};

export default Toolbar;
