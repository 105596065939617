import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from 'src/redux/store';
import Api from 'src/services/ApiService';
import {
    CommonAxiosResponse,
    PcPackageListPayload,
    PcPackageListResponse
} from 'src/types';

export const getPcPackageListThunk = createAsyncThunk<
    CommonAxiosResponse<PcPackageListResponse>,
    PcPackageListPayload,
    { state: RootState }
>('pc-package/getPcPackageListThunk', async (data, api) => {

    const keyword = api?.getState()?.pcPackage?.packagePayload?.keyword || '';
    const response = await Api.get('/ecomm/internal/pc_package/list/get', { ...data, limit: 15, sort: 'DESC', keyword: keyword });
    if (!response.success) {
        throw new Error('Failed getPackageList');
    }
    return response;
});

