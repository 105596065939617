import { cloneDeep } from 'lodash';
import {
  EcomOrderDetails,
  EcomOrderProduct,
  EcomOrderProductUpdate
} from 'src/types';

const orderDetailsProductsToUpdate = (products: EcomOrderProduct[]) => {
  const clonedProducts = cloneDeep(products);
  const newProducts: EcomOrderProductUpdate[] = clonedProducts?.map((x) => ({
    product_id: x?.product_id,
    product_slug: x?.product_slug,
    amount: x?.checkout_unit_price,
    product_name: x?.product_name,
    img_thumbnail: x?.img_thumbnail,
    quantity: x?.quantity || 1
  }));

  return newProducts;
};

export const orderDetailsToUpdateTransformer = (
  orderDetails: EcomOrderDetails
) => {
  let clonedData = cloneDeep(orderDetails);

  if (orderDetails?.products && orderDetails?.products?.length > 0) {
    clonedData = {
      ...clonedData,
      products: orderDetailsProductsToUpdate(orderDetails?.products)
    };
  }

  return clonedData;
};
